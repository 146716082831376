import {useState} from "react";
import {useSnackbar} from "notistack";
import CreditsManagementService from "../services/api/credits";
import { log } from "../console-config.log";

const useCreditsManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isAddProjectCredits, setAddProjectCredits] = useState(false);
    const [isSubProjectCredits, setSubProjectCredits] = useState(false);
    const [isViewProjectCredits, setViewProjectCredits] = useState(false);
    const [isGetProjectCreditList, setGetProjectCreditList] = useState(false);
    const [projectCreditsData, setProjectCreditsData] = useState(null);
    const [projectCreditsList, setProjectCreditsList] = useState([]);

    const [creditsAdded, setCreditsAdded] = useState(false);
    const [creditsDeducted, setCreditsDeducted] = useState(false);
    const [addedCredits, setAddedCredits] = useState(0);
    const [deductedCredits, setDeductedCredits] = useState(0);

    const [businessCredits, setBusinesCredits] = useState(0);

    const [isSearchingBusinessCredits, setSearchingBusinessCredits] = useState(false);
    const [searchedBusinessCredits, setSearchedBusinessCredits] = useState([]);
    const [isSearchFilterToggle, setSearchFilterToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);
    const [pagination, setPagination] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [businessCreditsList, setBusinessCreditsList] = useState([]);
    const [error, setError] = useState('');

    var limit = 10;

    const addProjectCredits = (payload, setAddBtnClicked, setAddCreditAmount) => {
        setAddProjectCredits(true);
        setCreditsAdded(false);

        CreditsManagementService.addProjectCredits(payload).then((res) => {
            console.log("addProjectCredits data xxxx", res);

            if (res.data.success) {
                setAddProjectCredits(false);
                setAddBtnClicked(false);
                setAddCreditAmount(0);
                setCreditsAdded(true);
                setAddedCredits(res.data.data.credit);

                enqueueSnackbar("Successfully added credits", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            console.log("addProjectCredits error xxxx", error);

            setAddProjectCredits(false);
            setAddBtnClicked(false);
            setAddCreditAmount(0);
            setCreditsAdded(false);

            if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(error.response.data.message, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const subProjectCredits = (businessId, projectId, credit, setDeductBtnClicked, setSubCreditAmount) => {
        setSubProjectCredits(true);
        setCreditsDeducted(false);

        CreditsManagementService.subProjectCredits(businessId, projectId, credit).then((res) => {
            console.log("subProjectCredits data xxxx", res);

            if (res.data.success) {
                setSubProjectCredits(false);
                setDeductBtnClicked(false);
                setSubCreditAmount(0);
                setCreditsDeducted(true);
                setDeductedCredits(res.data.data.credit);

                enqueueSnackbar("Successfully deducted credits", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            console.log("subProjectCredits error xxxx", error);

            setSubProjectCredits(false);
            setDeductBtnClicked(false);
            setSubCreditAmount(0);
            setCreditsDeducted(false);

            if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(error.response.data.message, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const viewProjectCredits = (businessId, projectId) => {
        setViewProjectCredits(true);
    
        CreditsManagementService.viewProjectCredits(businessId, projectId).then((res) => {
            console.log("viewProjectCredits data xxxx", res);
            
            if (res.data.success) {
                setViewProjectCredits(false);
                setProjectCreditsData(res.data.data);
            }
    
        }).catch((error) => { 
            console.log("viewProjectCredits error xxxx", error);
            setViewProjectCredits(false);
        })
    }

    const getProjectCreditList = (businessId, page) => {
        setGetProjectCreditList(true);
    
        CreditsManagementService.getProjectCreditList(businessId, page, limit).then((res) => {
            console.log("getProjectCreditList data xxxx", res);
    
            if (res.data.success) {
                setGetProjectCreditList(false);
                setProjectCreditsList(res.data.data);
            }
    
        }).catch((error) => { 
            console.log("getProjectCreditList error xxxx", error);

            setGetProjectCreditList(false);
        })
    }

    const viewBusinessCredits = (businessId) => {

        CreditsManagementService.viewBusinessCredits(businessId).then((res) => {
            console.log("viewBusinessCredits data xxxx", res);
    
            if (res.data.success) {
                setBusinesCredits(res.data.data.credit);
            }
    
        }).catch((error) => { 
            console.log("viewBusinessCredits error xxxx", error);

            setBusinesCredits(0);
        })
    }

    const searchBusinessCredits = (filter, value, page) => {
        setSearchingBusinessCredits(true);
        setSearchFilterToggle(true);

        const search_credit_limit = 20;

        CreditsManagementService.searchBusinessCredits(filter, value, page, search_credit_limit).then((res) => {
            console.log("searchBusinessCredits data xxxx", res);
          
            if (res.data.success) {
                setSearchingBusinessCredits(false);
                setSearchedBusinessCredits(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_credit_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchBusinessCredits error xxxx", error);

            setSearchingBusinessCredits(false);
            setPagination(0);
            setSearchFilterToggle(false);
            setSearchedResultsEmpty(false);
        })
    }

    const getBusinessCredits = (page) => {
        setIsLoading(true);
        setSearchFilterToggle(false);

        var getBusinessCreditsLimit = 10;
    
        CreditsManagementService.getBusinessCredits(page, getBusinessCreditsLimit).then((res) => {
            setIsLoading(false);
            log('get business credits res aaa', res.data.data)
            if (res.data.success) {
                setBusinessCreditsList(res.data.data);

                if (res.data.data.length === 0) {
                    setPagination(0);
                    setSearchFilterToggle(true);

                } else {
                    var last_page = Math.ceil(res.data.data / getBusinessCreditsLimit); 
                    console.log("last_page", last_page);
                    setPagination(last_page);
                    setSearchFilterToggle(false);
                }
            }
    
        }).catch((error) => { 
            setIsLoading(false);
            setPagination(0);
            setError(error.response.data.error)
            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }

            setSearchFilterToggle(false);
        })
    }
    
    
   
    return {isAddProjectCredits, isSubProjectCredits, isViewProjectCredits, isGetProjectCreditList,
        projectCreditsData, addProjectCredits, projectCreditsList, subProjectCredits, viewProjectCredits, 
        getProjectCreditList, creditsAdded, creditsDeducted, addedCredits, deductedCredits, viewBusinessCredits,
        businessCredits, isSearchingBusinessCredits, searchedBusinessCredits, searchBusinessCredits,
        isSearchFilterToggle, isSearchedResultsEmpty, isLoading, businessCreditsList, getBusinessCredits, pagination,
        error
    }  
}

export default useCreditsManagement