import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Badge, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import QRCodeGenerator from 'qrcode';
import CloseIcon from '@mui/icons-material/Close';
import PDFBoxComponent from "../components/pdf-editor/pdf-box.component";
import PDFDragElementBoxComponent from '../components/pdf-editor/pdf-drag-element-box.component';
import { initialDragElements, elementTypes } from "../common/staticVariables";
import { getWidthByPercentage } from "../utils/ProportionDimenUtil";
import PDFDragSignatureBoxComponent from "../components/pdf-editor/pdf-drag-signature-box.component";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import copy from "copy-to-clipboard";
import ViewElementComponent from '../components/pdf-editor/view-element.component';
import ViewElementTabComponent from '../components/pdf-editor/view-element-tab.component';
import useTemplates from '../hooks/useTemplates';
import TemplateService from '../services/api/templates';
import useWidth from '../hooks/useWidth';
import useTemplateProperties from '../hooks/useTemplateProperties';
import useProjects from '../hooks/useProjects';
import noSignature from '../icons/noSignature.png'
import useSignature from '../hooks/useSignature';
import useUsers from '../hooks/useUsers';
import SavePDFTemplateDialog from '../components/pdf-editor/dialogs/save-pdf-template-dialog.component';
import CancelPDFTemplateDialog from '../components/pdf-editor/dialogs/cancel-pdf-template-confirmation-dialog.component';
import ManageTemplateSignatureDialog from "../components/pdf-editor/dialogs/template-signature-management/manage-template-signature.dialog";
import { log } from '../console-config.log';

const commonButtonStyle = {
    textTransform: 'none',
    // marginTop: 1,
    width: 150, // Set the common width here
  };

const PDFTemplateScreen = () => {

    const navigate = useNavigate();
    
    const pdfEditorRef = useRef()

    const { enqueueSnackbar } = useSnackbar();

    let template_code_queryParam = new URLSearchParams(window.location.search).get("template_code");
    let businessId = new URLSearchParams(window.location.search).get("businessId");
    // let templateCode = (new URLSearchParams(window.location.search)).get("template_code");

    let storedElements = JSON.parse(localStorage.getItem("template_elements"));
    let stored_deleted_elements = JSON.parse(localStorage.getItem("deleted_elements"));

    console.log("template_code_queryParamxxx", template_code_queryParam)

    const [page, setPage] = useState(1)
    const [elements, setElements] = useState(!storedElements || !storedElements[template_code_queryParam] ? [] : storedElements[template_code_queryParam])
    const [deletedElements, setDeletedElements] = useState(!stored_deleted_elements ? [] : stored_deleted_elements)
    const [adjustedPDFWidth, setAdjustedPDFWidth] = useState(null)
    const [templatePropertiesPayload, setTemplatePropertiesPayload] = useState([])
    const [deletedPropertyIdsPayload, setDeletedPropertyIdsPayload] = useState([])
    const [dragElements, setDragElements] = useState(initialDragElements)
    const [hasSeal, setHasSeal] = useState(false)
    const [isLoadingSaveTemplate, setIsLoadingSaveTemplate] = useState(false)
    const [isViewing, setIsViewing] = useState(false)
    const [base64ElementValues, setBase64ElementValues] = useState(null)
    const [inputValue] = useState(" ");

    const [openSaveTemplateConfirmation, setOpenSaveTemplateConfirmation] = React.useState(false);
    const theme = useTheme();

    const { breakpoints } = useWidth();

    const location = useLocation();

    const [isEsealLogoCreated, setIsEsealLogoCreated] = useState(false);

    const { viewAccountTemplate, template, error, fetchingAccountTemplate } = useTemplates();
    const { templateProperties, getTemplateProperties } = useTemplateProperties();
    const {templateSignatureList, getAssignedTemplateSignatureList, setIsRemovingSignature, removeTemplateSignature: removeTemplateSignatureMutation} = useSignature();
    const {projectDetails, viewProjectDetails} = useProjects();
    const { loading, admin, getAdmin } = useUsers();




    const [openManageSignature, setOpenManageSignature] = useState(false);
    const [isSignatureCreated, setIsSignatureCreated] = useState(false);
    const [signatureUpdated, setSignatureUpdated] = useState({isUpdated: false, data: null});
    const [signatureDeleted, setSignatureDeleted] = useState({isDeleted: false, id: null});
    const [userSignatureUpdated, setUserSignatureUpdated] = useState({isUpdated: false, data: null});
    const [newAssignedSignature, setNewAssignedSignature] = useState(null);
    const [removedAssignedSignature, setRemovedAssignedSignature] = useState(null);
    const [signatureListCount, setSignatureListCount] = useState(0);
    const [removeTemplateSignature, setRemoveTemplateSignature] = useState({isRemove: false, data: null});
    const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

    // For View Only
    useEffect(() => {
        if (location.pathname == '/view-template') {
            getTemplateProperties(template_code_queryParam);
            setIsViewing(true)
        }
    }, [location.pathname])

    ///// Drag Elements /////
    useEffect(() => {
        console.log("dragElements bbbb", dragElements);
    }, [JSON.stringify(dragElements)])

    // eSignature
    useEffect(() => {
        console.log("for esig aaa", currentSelectedUser);
        setDragElements((prev) => {
            let elements = prev.map((item) => {
                if (item.type == elementTypes.userSignature) {
                    let hasSignature = currentSelectedUser != null && currentSelectedUser.signature != null ? true : false;
                    item.value = hasSignature ? currentSelectedUser.signature : noSignature;
                }
                return item
            })
            return elements
        })
    }, [JSON.stringify(currentSelectedUser)])

    // eSeal
    useEffect(() => {
        setDragElements((prev) => {
            let elements = prev.map((item) => {
                if (item.type == elementTypes.companySeal) {
                    item.value = "logo";
                }
                return item
            })
            return elements
        })
    }, [])

    // Textfield
    useEffect(() => {
        setDragElements((prev) => {
            let elements = prev.map((item) => {
                if (item.type == elementTypes.textfield) {
                    item.value = " ";
                }
                return item
            })
            return elements
        })
    }, [])

    // Date
    useEffect(() => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '/' + mm + '/' + dd;

        const date = today

        setDragElements((prev) => {
            let elements = prev.map((item) => {
                if (item.type == elementTypes.date) {
                    item.value = date;
                }
                return item
            })
            return elements
        })
    }, [])

    // QRCode
    useEffect(() => {
        const generateQRCodeBase64 = async () => {
            let qrcodeBase64 = await generateQR("https://ovcode.com/v/f-0000000-000-0000-0000-000000000000");
            console.log('get QR here', qrcodeBase64)
            setDragElements((prev) => {
                let elements = prev.map((item) => {
                    if (item.type == elementTypes.qrcode) {
                        item.value = qrcodeBase64;
                    }
                    return item
                })
                return elements
            })
        }
        generateQRCodeBase64()
    }, [])

    // Newly Created eSeal Logo
    useEffect(()=>{
        if(isEsealLogoCreated) {
            viewProjectDetails(template.project_id)
        };
    },[isEsealLogoCreated, JSON.stringify(template)])

    // User eSignature Updated
    useEffect(() => {
        if (userSignatureUpdated.isUpdated) {
            console.log("userSignatureUpdated aa", userSignatureUpdated.data);
            setDragElements((prev) => {
                let elements = prev.map((item) => {
                    if (item.type == elementTypes.userSignature) {
                        item.value = userSignatureUpdated.data;
                    }
                    return item
                })
                return elements
            })
            // getTemplateProperties(template_code_queryParam)

            setUserSignatureUpdated({isUpdated: false, data: null})
        }
    }, [JSON.stringify(userSignatureUpdated)])

    // Set Deleted Property Ids Payload
    useEffect(() => {
        console.log("templatePropertiesPayload 000", templatePropertiesPayload);

        if (templatePropertiesPayload.length > 0) {
            console.log("templatePropertiesPayload 111", templatePropertiesPayload);
            templatePropertiesPayload.forEach((item) => {
                // add element to deleted properties payload if previously saved in DB
                let isSavedPreviously = typeof item.id === 'number' && isFinite(item.id) && Math.floor(item.id) === item.id;
                if (isSavedPreviously) setDeletedPropertyIdsPayload((prev) => [...new Set([...prev, item.id])])
            })
        }
    }, [JSON.stringify(templatePropertiesPayload)])

    // Save Deleted Properties Payload to local storage
    useEffect(() => {
        console.log("deletedPropertyIdsPayload 111", deletedPropertyIdsPayload);
        if (deletedPropertyIdsPayload.length > 0) localStorage.setItem("deletedPropertyIDs", JSON.stringify(deletedPropertyIdsPayload))
    }, [JSON.stringify(deletedPropertyIdsPayload)])

    // Remove cached template elements & Update Deleted Property Ids Payload for Create/Update Template Properties API
    useEffect(() => {
        if (deletedElements.length > 0) {
            console.log("deletedElements aa", deletedElements);
            deletedElements.forEach((item) => {
                // remove cached template elements
                removeCachedTemplateElement(item)

                // Update Deleted Property Ids Payload
                let isSavedPreviously = typeof item.id === 'number' && isFinite(item.id) && Math.floor(item.id) === item.id;
                if (isSavedPreviously) {
                    setDeletedPropertyIdsPayload((prev) => {
                        let updated_deleted_ids = [...new Set([...prev, item.id])]
                        return updated_deleted_ids
                    })
                }
            })
        }
    }, [JSON.stringify(deletedElements)])

    // Set Signature List Count
    useEffect(() => {
        if (templateSignatureList) {
            if (templateSignatureList.data) setSignatureListCount(templateSignatureList.data.length)
        }
    }, [JSON.stringify(templateSignatureList)])

    // Updated/Deleted Template Signature 
    useEffect(() => {
        // reset isSignatureCreated
        if (isSignatureCreated) setIsSignatureCreated(false);

        if (isSignatureCreated || signatureDeleted.isDeleted || signatureUpdated.isUpdated) {
            getAssignedTemplateSignatureList(template.id)
        };
    }, [isSignatureCreated, JSON.stringify(signatureDeleted), JSON.stringify(signatureUpdated)])

    // Add/Remove Assigned Signature
    useEffect(() => {
        if (newAssignedSignature || removedAssignedSignature) getAssignedTemplateSignatureList(template.id)
    }, [JSON.stringify(newAssignedSignature), JSON.stringify(removedAssignedSignature)])

    // useEffect(() => {
    //     if (signatureDeleted.isDeleted) getAssignedTemplateSignatureList(template.id)
    // }, [JSON.stringify(signatureDeleted)])

    // Load Template Account
    useEffect(() => {
        if (template_code_queryParam == null) return;
        viewAccountTemplate(template_code_queryParam) // sample - NC9KO9FQFI
    }, [template_code_queryParam])

    // Get Template Properties
    useEffect(() => {
        getTemplateProperties(template_code_queryParam);
    }, [])

    useEffect(()=>{
        if(template) {
            getAssignedTemplateSignatureList(template.id);
            viewProjectDetails(template.project_id)
        }
    },[JSON.stringify(template)])

    // Set Adjust Width for PDF Page
    useEffect(() => {
        let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth
        let percentage = 60
        let adjustedPDFWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)
        console.log("adjustedPDFWidth aaa", adjustedPDFWidth);
        setAdjustedPDFWidth(adjustedPDFWidth)
    }, [])

    useEffect(() => {
        console.log("templateProperties iiiiii", templateProperties);
    }, [JSON.stringify(templateProperties)])

    const removeCachedTemplateElement = (deleted_element) => {
        let cachedElements = JSON.parse(localStorage.getItem("template_elements"))
        if (cachedElements) {
            cachedElements = {
                [template_code_queryParam]: cachedElements[template_code_queryParam]?.filter((cachedEl) => cachedEl.id != deleted_element.id)
            }
            localStorage.setItem("template_elements", JSON.stringify(cachedElements))
        }
    }

    const saveTemplateElementToCache = (element) => {
        let cachedElements = JSON.parse(localStorage.getItem("template_elements"))
        let hasDocumentCached = !cachedElements || !cachedElements[template_code_queryParam] ? false : true;

        // create
        if (!hasDocumentCached) {
            cachedElements = {
                [template_code_queryParam]: [element]
            }
        } 
        else {
            // update
            let updated_elements;
            let isExists = cachedElements[template_code_queryParam].some((item) => item.id == element.id)
            if (isExists) {
                updated_elements = cachedElements[template_code_queryParam].map((item) => {
                    if (item.id == element.id) {
                        item = element
                    }
                    return item
                })
                
            } else {
                updated_elements = [...cachedElements[template_code_queryParam], element]
            }

            cachedElements = {
                [template_code_queryParam]: updated_elements
            }
        }
        localStorage.setItem("template_elements", JSON.stringify(cachedElements))
    }

    const handleAddSignatureClick = () => {
        setOpenManageSignature(true);
    }

    const generateQR = async (value) => {
        let generatedQR;

        generatedQR = await QRCodeGenerator.toDataURL(value);

        return generatedQR.split(',')[1]
    }

    const handleRemoveTemplateSignature = (templateId, signatureId, setIsRemovingSignature, removeTemplateSignature) => {
        console.log("templateId aaaa", templateId);
        console.log("signatureId aaaa", signatureId);

        let elements_to_remove = elements.filter((item) => typeof item.id === 'number' && isFinite(item.id) 
                && Math.floor(item.id) === item.id && item.signature_id == signatureId)

        let element_ids_to_remove = elements_to_remove.map((item) => item.id)
        console.log("element_ids_to_remove aaaa", element_ids_to_remove);

        if (element_ids_to_remove.length == 0) {
            removeTemplateSignature(templateId, signatureId);
            return;
        }

        // If elements to be remove has previously saved in DB call Update Template Properties API First before calling Remove Template Signature API
        setIsRemovingSignature(true)
        TemplateService.createUpdateTemplateProperties([], element_ids_to_remove, template_code_queryParam).then((res) => {
            console.log("res lll", res);
            removeTemplateSignature(templateId, signatureId);
        }).catch((err)=>{

            console.log("err lll", err);

            enqueueSnackbar(err.response.data.error, {
                variant: "error",
                autoHideDuration: 2000
            });
            setIsRemovingSignature(false)
        })
    }

    const saveTemplate = () => {
        
        console.log("templatePropertiesPayload aa", templatePropertiesPayload);
        let deletedPropertyIDs = JSON.parse(localStorage.getItem("deletedPropertyIDs"))
        let deletedPropertyIdsPayload = deletedPropertyIDs == null ? [] :  deletedPropertyIDs
        console.log("deletedPropertyIdsPayload aa", deletedPropertyIdsPayload);

        if (templatePropertiesPayload.length > 0 || deletedPropertyIdsPayload.length > 0) {
            setIsLoadingSaveTemplate(true)
            TemplateService.createUpdateTemplateProperties(templatePropertiesPayload, deletedPropertyIdsPayload, template_code_queryParam).then(({ data }) => {
                setIsLoadingSaveTemplate(false);
                log('GET UPDATE TEMPLATE PROPERTEIES AAAAAAAAAA', data)
                localStorage.removeItem("template_elements")
                localStorage.removeItem("deleted_elements")
                localStorage.removeItem("deletedPropertyIDs")
                
                if(data.success){
                    window.location.href="/view-template?template_code=" + template_code_queryParam;
                }

            }).catch((err)=>{
                enqueueSnackbar(err.response.data.error, {
                    variant: "error",
                    autoHideDuration: 2000
                });
                setIsLoadingSaveTemplate(false)
            })
        } else {
            window.location.href="/view-template?template_code=" + template_code_queryParam;
        }
    }

    const handleClickOpenSaveTemplateConfirmation = () => {
        setOpenSaveTemplateConfirmation(true);
    };

    const handleCloseSaveTemplateConfirmation = (actionType) => {
        setOpenSaveTemplateConfirmation(false);
        if (actionType == 'yes') saveTemplate()
    };

    const [openUnsaveTemplate, setUnsaveTemplate] = useState(false)

    const handleOpenUnsaveTemplateDialog = () => {
        setUnsaveTemplate(true)
    }

    const handleCloseUnsaveTemplateDialog = () => {
        setUnsaveTemplate(false)
    }

    const copyToClipboard = (template) => {
        copy(template.template_code);

        enqueueSnackbar("Successfully copied template code to clipboard", {
            variant: "success",
            autoHideDuration: 2000
        });
    }


    const [numPages, setNumPages] = useState(null);

    const handleCloseUpdate = (e) => {
        window.location.href = '/template-list'
        // navigate("/template-list");
    }



    // if (template_code_queryParam == null) return "No template code found.";
     if (fetchingAccountTemplate) return "Loading . . .";
    // if (error != null) return <>{error}</>;

    console.log("fetchingAccountTemplatexxx", fetchingAccountTemplate)
    console.log("isViewingxxx", isViewing)
    console.log("templatePropertiesxxx", templateProperties)



    return (
        <Box sx={{ display: "flex", height: '10vh' }}>
        <Box sx={{ flex: "1 1 auto" }}>
            <Box sx={{ height: "100vh", overflow: "auto", bgcolor: '#efedec' }}>
                <Box sx={{ width: '100%' }}>
                    <IconButton onClick={() => window.location.href = '/view?id='  + businessId} >
                        <CloseIcon sx={{ color: '#0178f2' }} />
                    </IconButton>
                </Box>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={3}>
                        <br />

                        <br />

                        <PDFBoxComponent file_url={template?.file_url} template_code_queryParam={template_code_queryParam} adjustedPDFWidth={adjustedPDFWidth} templateProperties={templateProperties}
                            elements={elements} setElements={setElements} setTemplatePropertiesPayload={setTemplatePropertiesPayload}
                            setDeletedPropertyIdsPayload={setDeletedPropertyIdsPayload} isViewing={isViewing}
                            templatePropertiesPayload={templatePropertiesPayload} template={template} isLoading={fetchingAccountTemplate}
                            setNumPages={setNumPages} numPages={numPages}  setHasSeal={setHasSeal}
                            userSignatureUpdated={userSignatureUpdated} setUserSignatureUpdated={setUserSignatureUpdated} deletedElements={deletedElements} setDeletedElements={setDeletedElements} signatureUpdated={signatureUpdated}
                            setSignatureUpdated={setSignatureUpdated} signatureDeleted={signatureDeleted} setSignatureDeleted={setSignatureDeleted}
                            removeTemplateSignature={removeTemplateSignature} setRemoveTemplateSignature={setRemoveTemplateSignature}
                            saveTemplateElementToCache={saveTemplateElementToCache} currentSelectedUser={currentSelectedUser} setCurrentSelectedUser={setCurrentSelectedUser}
                        />

                    </Grid>
                </Grid>
            </Box>
        </Box>

        {console.log("templatePropertiesxxx",templateProperties)}

        {(!isViewing) ?
            <Box sx={{ width: "24%", height: "100vh", overflow: "auto" }}>
                <Box sx={{ p: 2 }}>
                    <Grid container direction="row" sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} >
                            <Typography sx={{ fontSize: 15, fontWeight: 550, pr: 1 }}>Title:</Typography>
                            <Typography variant="h5" sx={{ fontSize: 15, textTransform: "capitalize" }}>
                                {templateProperties?.template.template_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Typography sx={{ fontSize: 15, fontWeight: 550 }}>Template Code</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ border: "1px solid black", borderStyle: "dashed", padding: 1, mt: 1 }}>
                                <Grid container>
                                    <Grid item xs={10} sx={{ alignSelf: "center" }}>
                                        {templateProperties?.template.template_code}
                                    </Grid>

                                    <Grid item xs={2} sx={{ alignSelf: "center", textAlign: 'end' }}>
                                        <Tooltip title="Copy" placement="top" arrow>
                                            <IconButton onClick={(e) => copyToClipboard(templateProperties?.template)} sx={{ color: "#0178f2", cursor: "pointer", pl: 1, pr: 1 }}>
                                                <ContentCopyIcon sx={{ fontSize: "medium" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Elements
                        </Typography>

                        <br />
                    </>

                    <Grid container spacing={2} sx={{ overflow: "auto", mb: 4 }}>
                        {
                            dragElements.map((item, i) => (
                                <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {
                                        (item.value) &&
                                        <PDFDragElementBoxComponent element={item} setHasSeal={setHasSeal} template_code_queryParam={template_code_queryParam}
                                            hasSeal={hasSeal} template={template} setIsEsealLogoCreated={setIsEsealLogoCreated}
                                            setUserSignatureUpdated={setUserSignatureUpdated} userSignatureUpdated={userSignatureUpdated} currentSelectedUser={currentSelectedUser} 
                                            setCurrentSelectedUser={setCurrentSelectedUser}
                                        />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>

                    <Box sx={{ flex: 1 }} />

                   <Grid container sx={{ ml: (signatureListCount == 0) ? "0px !important" : {}, mb: 8 }}>
                        <Box sx={{ width: '100%' }}>
                            {(signatureListCount == 0) ?
                                <>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                            <Typography sx={{ cursor: "default", color: "gray", margin: "auto" }}>
                                                No available signatures to display.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                            <Typography sx={{ cursor: "pointer", color: "#0178f2", margin: "auto", pb: 1, pt: 1 }}
                                                onClick={(e) => handleAddSignatureClick()}
                                            >
                                                Click to add signature
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>

                                :
                                <>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography sx={{ fontWeight: "bold", cursor: "default", color: "inherit", pb: "10px" }}>
                                            Signatures
                                        </Typography>
                                        <Typography sx={{ cursor: "pointer", color: "#0178f2", ml: "auto", pb: "10px" }}
                                            onClick={(e) => handleAddSignatureClick()}
                                        >
                                            Add Signature
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2} sx={{ height: '30vh', overflow: "auto", display: 'contents' }}>
                                        {templateSignatureList?.data.map((item, i) => (
                                            <Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <PDFDragSignatureBoxComponent element={item} setSignatureUpdated={setSignatureUpdated}
                                                    setSignatureDeleted={setSignatureDeleted}
                                                    setRemoveTemplateSignature={setRemoveTemplateSignature}
                                                    removeTemplateSignature={handleRemoveTemplateSignature}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>

                            }
                        </Box>
                    </Grid>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ position: 'absolute', bottom: 10, alignItems: 'center', display: 'flex', width: '-webkit-fill-available' }}>
                        <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                            <Button variant="outlined" onClick={(e) => handleOpenUnsaveTemplateDialog(e)} sx={{ textTransform: 'none', '& button': { m: 1 }, width: 150, bgcolor: '#fff' }}>
                                Cancel
                            </Button>

                            <Button disabled={isLoadingSaveTemplate} startIcon={isLoadingSaveTemplate ? <CircularProgress size={20} /> : null}
                                onClick={handleClickOpenSaveTemplateConfirmation} variant="contained" sx={{ textTransform: 'capitalize', bgcolor: '#0178f2', '& button': { m: 1 }, width: 150 }}
                            >
                                Save Template
                            </Button>
                        </Stack>

                    </Box>
                </Box>
            </Box>
            :
            <Box sx={{ width: "24%", height: "100vh", overflow: 'auto' }}>
                <Box sx={{ p: 3, pb: 1 }}>
                    <Grid container direction="row" sx={{ display: "flex", alignItems: "center" }}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} >
                            <Typography sx={{ fontSize: 15, fontWeight: 550, pr: 1 }}>Title:</Typography>
                            <Typography variant="h5" sx={{ fontSize: 15, textTransform: "capitalize" }}>
                                {templateProperties?.template.template_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 2 }}>
                            <Typography sx={{ fontSize: 15, fontWeight: 550 }}>Template Code</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ border: "1px solid black", borderStyle: "dashed", padding: 1, mt: 1 }}>
                                <Grid container>
                                    <Grid item xs={10} sx={{ alignSelf: "center" }}>
                                        {templateProperties?.template.template_code}
                                    </Grid>

                                    <Grid item xs={2} sx={{ alignSelf: "center", textAlign: 'end' }}>
                                        <Tooltip title="Copy" placement="top" arrow>
                                            <IconButton onClick={(e) => copyToClipboard(templateProperties?.template)} sx={{ color: "#0178f2", cursor: "pointer", pl: 1, pr: 1 }}>
                                                <ContentCopyIcon sx={{ fontSize: "medium" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <br />

                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '75vh' }}>
                        {
                            templateProperties?.templateProperties?.length === 0 ? (
                                <Typography sx={{ fontSize: 15 }}>No elements are currently configured.</Typography>
                            ) : (
                                <>

                                    <Typography>Elements</Typography>
                                    <br />
                                    {numPages && numPages === 1 ? (
                                        <Grid container spacing={2} sx={{ height: "100%", overflow: "auto" }}>
                                            {templateProperties?.templateProperties.map((item, i) => (
                                                <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    {item.value && (
                                                        <ViewElementComponent element={item} />
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <ViewElementTabComponent templateProperties={templateProperties} />
                                    )}
                                </>
                            )
                        }

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ position: 'absolute', bottom: 10, mr: '35px', alignItems: 'center', display: 'flex', width: '-webkit-fill-available' }}>
                            <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                                {/* <Button variant="outlined" onClick={() => window.location.href = '/template-list'} sx={{ textTransform: 'none', marginTop: 1, width: 150, bgcolor: '#fff' }}>Close</Button>
                                <Button variant="contained"
                                    onClick={() => window.location.href = '/update-template?template_code=' + templateProperties?.template.template_code}
                                    sx={{ textTransform: 'none', marginTop: 1, width: 150, bgcolor: '#0178f2' , textTransform: 'capitalize'}}
                                >
                                    Edit
                                </Button> */}
                                <Button
                                    variant="outlined"
                                    onClick={() => navigate(-1)}
                                    sx={{ ...commonButtonStyle, bgcolor: '#fff' }}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => (window.location.href = '/update-template?template_code=' + templateProperties?.template.template_code)}
                                    sx={{ ...commonButtonStyle, bgcolor: '#0178f2', textTransform: 'capitalize' }}
                                >
                                    Edit
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }

            <SavePDFTemplateDialog breakpoints={breakpoints} openSaveTemplateConfirmation={openSaveTemplateConfirmation}
                template={template} handleCloseSaveTemplateConfirmation={handleCloseSaveTemplateConfirmation} isLoadingSaveTemplate={isLoadingSaveTemplate}
            />

            <CancelPDFTemplateDialog openUnsaveTemplate={openUnsaveTemplate}
                breakpoints={breakpoints} handleCloseUnsaveTemplateDialog={handleCloseUnsaveTemplateDialog}
            />

            {openManageSignature &&
                <ManageTemplateSignatureDialog template={template} breakpoints={breakpoints} 
                    openManageSignature={openManageSignature} 
                    setOpenManageSignature={setOpenManageSignature} 
                    isSignatureCreated={isSignatureCreated}
                    setIsSignatureCreated={setIsSignatureCreated}
                    signatureUpdated={signatureUpdated}
                    setSignatureUpdated={setSignatureUpdated}
                    signatureDeleted={signatureDeleted}
                    setSignatureDeleted={setSignatureDeleted}
                    setNewAssignedSignature={setNewAssignedSignature}
                    setRemovedAssignedSignature={setRemovedAssignedSignature}
                />
            }
    </Box>
    )
}

export default PDFTemplateScreen