import { Box, Grid, IconButton, InputAdornment, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DeletedProjectSkeleton from "../components/skeletons/deleted-project-skeleton.component";
import useProjects from "../hooks/useProjects";
import DeletedProjectListComponent from "../components/project/deleted-project-list.component";
import ForbiddenScreen from "./ForbiddenScreen";


function TableHeader() {
    const tablecell = [
        { label: 'Logo' },
        { label: 'Name' },
        { label: 'Description' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ];

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value, index) => (
                    <TableCell key={index} sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}



const DeletedProjectListScreen = (props) => {

    // let businessId = (new URLSearchParams(window.location.search)).get("id");
    let businessId = (new URLSearchParams(window.location.search)).get("id");


    const { retrievingDeletedProjects, deletedProjects, getAllDeletedProjects, isSearchReferencesDeletedProject, getDeletedProjectByProjectName, error } = useProjects();


    const [isDelete, setDelete] = useState(false);
    const [isRestore, setIsRestore] = useState(false)
    const [searchInput, setSearchInput] = useState("");
    const [searchInputDeletedProject, setSearchInputDeletedProject] = useState("");
    const [page, setPage] = useState(1);



    useEffect(() => {
        getAllDeletedProjects(businessId, page);
    }, [businessId])

    // useEffect(() => {

    //     if(isDelete || isRestore ) {
    //         getAllDeletedProjects();
    //     }

    // },[isDelete, isRestore])

    console.log("retrievingDeletedProjectxxx", retrievingDeletedProjects)

    console.log("deletedProjectsxxx", deletedProjects)


    return (
        <Box sx={{ overflow: 'hidden' }}>

            {error === "Forbidden" ?
                <ForbiddenScreen />
                :
                <Box sx={{ width: '99%', margin: 'auto' }}>
                    <Grid container sx={{ padding: 1 }}>

                        <TableContainer component={Paper} sx={{ border: '1px solid #8080807a', borderRadius: 2, width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />
                                {retrievingDeletedProjects ?

                                    <TableBody>
                                        <DeletedProjectSkeleton />
                                    </TableBody>

                                    :
                                    <TableBody>
                                        {deletedProjects?.map((projects, index) => {
                                            return (
                                                <DeletedProjectListComponent
                                                    index={index}
                                                    projects={projects}
                                                    setDelete={setDelete}
                                                    setIsRestore={setIsRestore}
                                                />
                                            )
                                        })}
                                    </TableBody>
                                }
                            </Table>

                            {!retrievingDeletedProjects &&
                                <>
                                    {deletedProjects?.length === 0 &&
                                        <Box sx={{ textAlign: 'center', padding: 2 }}>
                                            No deleted projects found.
                                        </Box>
                                    }
                                </>
                            }
                        </TableContainer>
                    </Grid>
                </Box>
            }

        </Box>
    )
}

export default DeletedProjectListScreen;
