import { Box, Grid, Skeleton, Paper, Typography, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import {Masonry} from '@mui/lab';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.caption,
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function ProjectListSkeletonComponent ({breakpoints}){

    const heights = breakpoints == 'xs' || breakpoints == 'sm' ? [230, 230, 230, 230, 230, 230, 230, 230, 230] : [230, 230, 230, 230, 230, 230, 230, 230, 230];

    return(

        <Box sx={{padding: 0}}>
            <Masonry columns={{ xs: 1, sm: 3 }} spacing={2} sx={{margin:'auto', width: '100%', padding: 0}}>
                {heights.map((height, index) => (
                    <Item key={index} sx={{height, boxShadow: 'rgba(200, 200, 200) 0px 7px 29px 0px !important', '&.MuiPaper-root' : {borderRadius: 2}}}>
                        <Grid container spacing={2} sx={{padding: 2, display:'inline-flex', alignItems:'center'}}>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Box sx={{ padding: '5px'}}>
                                    <Typography sx={{textAlign:'start', fontWeight:'bold',}}>
                                        <Skeleton variant="circular" style={{backgroundColor: 'rgb(108 139 222 / 30%)', height: 100, width: 100}} />
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{textAlign:'end'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                    </Grid> 
                                    <Grid item xs={12} sm={12}>
                                        <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container sx={{p: 2, pt: 1}} spacing={2} >
                            <Grid item xs={6} sm={6} >
                                <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                            </Grid>
                        </Grid>
                    </Item>
                ))}
            </Masonry>
        </Box>
       
      
    )
}

export default ProjectListSkeletonComponent;