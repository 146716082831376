import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ApprovalIcon from '@mui/icons-material/Approval';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ptToPx, pxToPt } from "../utils/UnitConverterUtil";

export const elementTypes = {
    userSignature: 'userSignature',
    companySeal: 'companySeal',
    qrcode: 'qrFld',
    textfield: 'txtFld',
    date: 'date',
    signature: "signature"
}

export const pdfPageSizes = {
    A4: {
        width: 595.5,
        height: 842.25,
        unit: "pt"
    }
}

export const initialDragElements = [
    {
        label: "E-Signature",
        type: elementTypes.userSignature,
        icon: <DriveFileRenameOutlineIcon />,
        width: 200,
        height: 100
    },

    {
        label: "E-seal",
        type: elementTypes.companySeal,
        icon: <ApprovalIcon />,
        width: ptToPx(100),
        height: ptToPx(50)
    },

    {
        label: "Text field",
        type: elementTypes.textfield,
        icon: <TextFieldsIcon />,
        width: 200,
        // width: 165,
        height: 50,
        font_size: 12
    },

    {
        label: "Date",
        type: elementTypes.date,
        icon: <CalendarMonthIcon />,
        width: 80,
        height: 23,
        font_size: 12
    },

    {
        label: "QR Code",
        type: elementTypes.qrcode,
        icon: <QrCodeIcon />,
        width: 100,
        height: 100
    },
]

export const initialFillableDragElements = [
    {
        label: "Signatory",
        type: elementTypes.signature,
        width: 200,
        height: 100
    },
]

export const minElementWidth = 40 
export const minElementHeight = 20 

export const dragSourceType = 'element_fields'