import AccessControlService from "../services/api/access-control";
import { useState } from "react";
import { useSnackbar } from "notistack";


const useAccessControl = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [accessControl, setAccessControl] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [retrieved, setRetrieved] = useState(false);
    const [total_access_control, setTotalAccessControl] = useState(0);
    const [deletingControl, setDeletingControl] = useState(false);
    const [updatingControl, setUpdatingControl] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [added, setAdded] = useState(false);

    var limit = 500;

    const getAllAccessControl = () => {
        setIsLoading(true);
    
        AccessControlService.getAllAccessControl().then(res => {
    
          console.log("res herexxxx:", res)
    
          setIsLoading(false);
    
          if (res.data.success) {
            setRetrieved(true);
            setTotalAccessControl(res.data.data.length);
            
          }
    
        }).catch((err)=>{ 
            setIsLoading(false);
    
        })
      }

    
    const getAccessControl = (page, roleId) => {
        setIsLoading(true)
        AccessControlService.getAccessControl(page, limit, roleId).then((res)=>{

            console.log('getAccessControlxxx', res)
            setIsLoading(false)

            if(res.data.success){
                setAccessControl(res.data.data)
            }

           
        }).catch(()=>{
            setIsLoading(false)
        })
    };


    const addAccessControl = (payload) => {
        setIsLoading(true)
        AccessControlService.addAccessControl(payload).then((res)=>{

            console.log("addAccessControlxxx", res )

            setIsLoading(false)

            if(res.data.success){
                setAdded(true)
                enqueueSnackbar('Permission successfully added.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                // window.location.reload();
            }

        }).catch((error) => {

            setIsLoading(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const removeAccessControl = (roleId, permissionId) =>{
        setDeletingControl(true)
        AccessControlService.removeAccessControl(roleId, permissionId).then((res)=>{

            console.log("resxxx", res)

            setDeletingControl(false)

            if(res.data.success){
                setUpdated(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                // window.location.reload();
            }


        }).catch((error)=>{

            setDeletingControl(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const updateAccessControl = (id, isAllowed) => {
        setUpdatingControl(true)
        AccessControlService.updateAccessControl(id, isAllowed).then((res)=>{

            console.log("updateAccessControlxxx", res )

            setUpdatingControl(false)

            if(res.data.success){
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
               

        }).catch((error) => {

            setUpdatingControl(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }



    

    return{ isLoading, getAllAccessControl, total_access_control, retrieved, getAccessControl, accessControl, 
        pagination, addAccessControl, removeAccessControl, deletingControl, updatingControl, updateAccessControl,
        updated, added, setIsLoading
    };
};

export default useAccessControl