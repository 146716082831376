const getAdjustedWidth = (chosenHeight, origWidth, origHeight) => {
    let width = (origWidth / origHeight) * chosenHeight
    return width
}

const getAdjustedHeight = (chosenWidth, origWidth, origHeight) => {
    let height = (origHeight / origWidth) * chosenWidth
    return height
}

const resizeImageWithAspectRatio = (imgWidth, imgHeight, maxWidth, maxHeight) => {
    // Get the current width and height of the image
    var width = imgWidth;
    var height = imgHeight;
    
    // Calculate the new width and height based on the given maximums
    if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
    }
    if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
    }
    return {width, height}
}

export {getAdjustedWidth, getAdjustedHeight, resizeImageWithAspectRatio}