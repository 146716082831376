import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import TransactionComponent from "./transactions.component";
import DashboardComponent from "./dashboard.component";




function useAnalyticsTabs(props){

  const [ analytics_tab, setAnalyticsTab ] = useState(null);
  const currentYear = new Date().getFullYear()

  function setupTabs(){

    var tabs_default = [
      {name: 'Dashboard', component: <DashboardComponent currentYear={currentYear} /> },
      {name: 'Authentication', component: <TransactionComponent /> },
    ]

    setAnalyticsTab(tabs_default);
  }

  useEffect(()=>{ setupTabs(); },[])

  return analytics_tab;

}


function AnalyticsTabComponent(props){


  const tabs = useAnalyticsTabs();

  const [ value, setValue ] = useState(0);

  function TabPanel(props) {
      
      const { children, value, index, ...other } = props;
      
    
      return (
        <div
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue){
    setValue(newValue);
  }

  return(
    <Box sx={{width: '100%'}}>
        <Box>
            <CustomTabs
                variant= "scrollable"
                value={value} 
                onChange={handleChange} 
                sx={{marginTop: 2}} 
                indicatorColor="transparent"
                centered
            >
                { tabs?.map((tab, index)=>{
                    return(
                        <CustomTab label={tab.name} disableRipple {...a11yProps(index)}   />
                    )}) 
                }
          </CustomTabs>
            { tabs?.map((tab, index)=>{
                return(
                    <TabPanel value={value} index={index} style={{padding: "0px"}}>
                        {tab.component}
                    </TabPanel> 
                )})
            }
        </Box>
    </Box>
  )
}

export default AnalyticsTabComponent;