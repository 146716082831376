import { Grid, Typography, Button, ButtonGroup, TextField, InputAdornment, IconButton, Box, Avatar, Card, FormControlLabel, Switch, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import useApim from "../hooks/useApim";
import useUsers from "../hooks/useUsers";
import copy from "copy-to-clipboard";
import useBusiness from "../hooks/useBusiness";
import useWidth from "../hooks/useWidth";
import ViewBusinessAccountSkeletonComponent from "../components/skeletons/view-business-account-skeleton.component";
import EditIcon from '../icons/edit.png'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import BusinessService from "../services/api/business";
import ProductSelectComponent from "../components/account/view-business-account-components/product-select.component";

function BusinessDetailsLabel() {
    const view_labels = [
        { label: 'Company' },
        { label: 'Description' },
        { label: 'Website' },
        { label: 'Contact Number' },
        { label: 'Status' },
    ];

    return (
        <>
            {view_labels.map((value) => {
                return (
                    <Typography variant="subtitle2" sx={{ mb: 2 }}>{value.label}:</Typography>
                )
            })}
        </>
    )
}

function BusinessDetailsData({ businessDetails }) {

    return (
        <>
            <Typography variant="caption" sx={{ mb: 2 }}>{businessDetails?.business_name}</Typography>
            <Typography variant="caption" sx={{ mb: 2 }}>{businessDetails?.description}</Typography>
            <Typography variant="caption" sx={{ mb: 2 }}>{businessDetails?.website ? businessDetails?.website : <p style={{ color: 'gray', margin: 0 }}>No website added.</p>}</Typography>
            <Typography variant="caption" sx={{ mb: 2 }}>{businessDetails?.contact ? businessDetails?.contact : <p style={{ color: 'gray', margin: 0 }}>No contact number added.</p>}</Typography>
            <Typography variant="caption" sx={{ mb: 2, color: businessDetails?.status == 1 ? "green" : "red" }}>{businessDetails?.status == 1 ? "Active" : "Deactivate"}</Typography>
        </>
    )
}

function ViewBusinessDetailsScreen(props) {

    const { getBusinessById, businessDetails, fetchingBusinessDetails } = useBusiness();
    const breakpoints = useWidth();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { isRegeneratingSubKey, regenerateSubscriptionKey } = useUsers();
    const { changeProduct, isUpdatePlan, getProducts, getProduct } = useApim();
    const [showApiKey, setApiKey] = useState(false);
    const [product, setProduct] = useState(null);
    const [dob, setDob] = useState("");
    const [loading, setLoading] = useState(false)
    const [removeExpiration, setRemoveExpiration] = useState(false)

    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';

    useEffect(() => { getProducts() }, [])

    useEffect(() => {
        let id = (new URLSearchParams(window.location.search)).get("id");
        getBusinessById(id);
    }, [])

    const handleRegenerate = (e) => {  let id = (new URLSearchParams(window.location.search)).get("id"); regenerateSubscriptionKey(id); }
    const handleClickShow = (e) => { setApiKey(!showApiKey) }
    const sendDataToParent = (child) => { setProduct(child) }

    function goToPath() { navigate('/edit?business_id=' + businessDetails?.id) }

    const handleChange = (event) => { setProduct(event.target.value); sendDataToParent(event.target.value) }
    const changeProductPlan = (e) => {
        let payload = {
            data: { scope: product ? product : businessDetails?.plan }
        };
        changeProduct(businessDetails?.id, payload)
    }

    const copyToClipboard = (copyText) => {
        copy(copyText);

        enqueueSnackbar("Successfully copied subscription key to clipboard", {
            variant: "success",
            autoHideDuration: 2000
        });
    }

    const updateBusinessExpiration = () => {

        let payload = {
            id: businessDetails?.id,
            expiration: formattedDob
        }
        setLoading(true)
        BusinessService.updateBusinessExpiration(payload).then((res) => {
            console.log('update business expiration res here', res)
            if (res.data.success) {
                setLoading(false)
                enqueueSnackbar("Successfully setting an expiration date.", {
                    variant: "success",
                    autoHideDuration: 2000
                });
                window.location.reload()
            }

        }).catch((err) => {
            setLoading(false)
        })
    }

    const removeBusinessExpiration = () => {
        setRemoveExpiration(true)
        BusinessService.removeBusinessExpiration(businessDetails?.id).then((res) => {
            console.log('get remove business expiration here', res)
            if (res.data.success) {
                setRemoveExpiration(false)
                window.location.reload()
            }
        }).catch(() => {
            setRemoveExpiration(false)
        })
    }

    // const dateObj = new Date(businessDetails?.account_expiration);
    // const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    // const day = dateObj.getDate().toString().padStart(2, '0');
    // const year = dateObj.getFullYear();

    // const formattedDate = `${month}/${day}/${year}`;

    // console.log('dob', formattedDate)

    return (
        <Box >
            {fetchingBusinessDetails ?
                <ViewBusinessAccountSkeletonComponent />
                :
                <Card sx={{ padding: 4, borderRadius: 2 }}>
                    <Grid container spacing={2} sx={{ textAlign: "start" }}>
                        <Grid container>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ textAlign: "start", paddingLeft: 0 }}>
                                <Button>
                                    <Avatar variant="square" src={businessDetails?.logo}
                                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 100, height: 'auto', objectFit: 'contain' }}
                                    />
                                </Button>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ textAlign: "end" }}>
                                <IconButton color="primary" component="label" onClick={() => goToPath()}>
                                    <img alt="edit-icon" src={EditIcon} style={{ height: 24 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} sx={{ textAlign: 'start', alignSelf: 'center' }}>
                                <BusinessDetailsLabel />
                            </Grid>

                            <Grid item xs={12} sm={9} sx={{ textAlign: 'start', alignSelf: 'center' }}>
                                <BusinessDetailsData businessDetails={businessDetails} />
                            </Grid>

                            <Grid item xs={12} sm={3} sx={{ alignSelf: 'center' }}>
                                <Typography variant="subtitle2">
                                    Plan:
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={7} sx={{ alignSelf: 'center' }}>
                                <ProductSelectComponent businessDetails={businessDetails} product={product} handleChange={handleChange}
                                    isUpdatePlan={isUpdatePlan} breakpoints={breakpoints} getProduct={getProduct}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2} sx={{ alignSelf: "center" }}>
                                <Button sx={{ display: (isUpdatePlan) ? "none" : "visible", textTransform: "none" }} onClick={(e) => changeProductPlan(e)}>
                                    Update
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={3} sx={{ alignSelf: 'center' }}>
                                <Typography variant="subtitle2">
                                    Subscription Key:
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={7} sx={{ alignSelf: "center" }}>
                                {(isRegeneratingSubKey) ?
                                    <Grid sx={{ display: "flex", width: "100%", height: "54px", borderRadius: "4px", padding: "14px 14px 14px 0px" }}>
                                        <span style={{ color: "#0178f2", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "12px" : "14px" }}>
                                            Regenerating
                                        </span>

                                        <div style={{ paddingLeft: "3px", paddingTop: "1px" }}>
                                            <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                                        </div>
                                    </Grid>
                                    :
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        value={businessDetails?.subscription_key}
                                        placeholder="Subscription Key"
                                        type={(showApiKey) ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" style={{ marginLeft: -29 }}>
                                                    <IconButton onClick={(e) => handleClickShow(e)} aria-label="toggle password visibility" edge="end">
                                                        {(showApiKey) ?
                                                            <Visibility fontSize="small" />
                                                            :
                                                            <VisibilityOff fontSize="small" />
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item xs={12} sm={2} sx={{ alignSelf: "center", display: (isRegeneratingSubKey) ? "none" : "visible" }}>
                                <ButtonGroup variant="text" aria-label="text button group">
                                    <Button sx={{ textTransform: "none", color: "#0178f2" }} onClick={(e) => copyToClipboard(businessDetails?.subscription_key)}>
                                        Copy
                                    </Button>

                                    <Button sx={{ textTransform: "none", color: "#0178f2" }} onClick={(e) => handleRegenerate(e)}>
                                        Regenerate
                                    </Button>
                                </ButtonGroup>
                            </Grid>

                            <Grid item xs={12} sm={3} sx={{ alignSelf: 'center' }}>
                                <Typography variant="subtitle2">
                                    Expiration Date:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} sx={{ alignSelf: "center" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            // defaultValue={dob}
                                            label={businessDetails?.account_expiration ? businessDetails?.account_expiration : "NA"}
                                            required
                                            disablePast={true}
                                            views={['year', 'month', 'day']}
                                            inputProps={{ style: { color: '#1f4380', fontSize: 16 } }}
                                            sx={{
                                                "& .MuiInputLabel-root": { color: '#1f4380' },
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#1f4380 !important" },
                                                },
                                                fontSize: 16
                                            }}
                                            onChange={(newValue) => {
                                                setDob(newValue);
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{ alignSelf: "center" }}>
                                <ButtonGroup variant="text" aria-label="text button group">
                                    <Button sx={{ textTransform: "none" }} onClick={(e) => updateBusinessExpiration(e)}
                                        startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {loading ? "Updating" : "Update"}
                                    </Button>
                                    <Button sx={{ textTransform: "none", color: "#0178f2" }} onClick={(e) => removeBusinessExpiration(e)}
                                        startIcon={removeExpiration && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {removeExpiration ? "Removing" : "Remove"}
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            
                            <Grid container spacing={2} sx={{ width: "95%", ml: "auto", mt: 5, mb: 0, textAlign: 'end', mr: 5 }}>
                                <Grid item xs={12} >
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                                        onClick={() => window.location.href = "/account-management"}
                                    >
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            }
        </Box>
    )
}

export default ViewBusinessDetailsScreen;