import React from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import usePackageManagement from "../../../hooks/usePackageManagement";
import {ThreeDots} from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";

const PackageSelectionOptionsDialog = (props) => {

    const {breakpoints, openSelectionOptions, setOpenSelectionOptions, selectedBusiness, name, packageId, setOpenCustomizedPlanDialog} = props;

    const {isAssigningPackageToBusiness, assignPackageToBusiness} = usePackageManagement();

    const handleCloseSelectionOptions = (e) => {
        setOpenSelectionOptions(false);
    }

    const handleAssignPackageToBusiness = () => {
        let isSelectOptionsDialog = true;
        let isSelectAndCustomize = false;

        assignPackageToBusiness(selectedBusiness, packageId, isSelectOptionsDialog, setOpenSelectionOptions, isSelectAndCustomize);
    }

    const handleOpenCustomizePlan = (e) => {
        let isSelectOptionsDialog = false;
        let isSelectAndCustomize = true;

        assignPackageToBusiness(selectedBusiness, packageId, isSelectOptionsDialog, setOpenSelectionOptions, isSelectAndCustomize);

        setOpenSelectionOptions(false);
        setOpenCustomizedPlanDialog(true); 
    }

    return (
        <Dialog open={openSelectionOptions} onClose={(e) => handleCloseSelectionOptions(e)} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle sx={{display: "flex", padding: "11px 24px 4px 24px"}}>
                {name} Plan

                <Box sx={{ml: "auto"}}>
                    <CloseIcon size="small" onClick={(e) => handleCloseSelectionOptions(e)} 
                        style={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 20, 
                        color: "#808080a8", marginTop: "5px", cursor: "pointer"}} 
                    />
                </Box>
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "20px 48px 28px"}}>
                <Typography>
                    What do you want to do with the plan?
                </Typography>
                
                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item xs={12} sx={{pt: "0px !important"}}>
                        <Button disableElevation variant="contained" fullWidth sx={{border: "0.5px solid #0178f245", backgroundColor: "#0178f245", color: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f245", color: "#0178f2", boxShadow: "#0178f261 0px 2px 10px 0px"}}}
                            startIcon={isAssigningPackageToBusiness && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                            onClick={(e) => (isAssigningPackageToBusiness) ? null : handleAssignPackageToBusiness(e)}
                        >
                            {(isAssigningPackageToBusiness) ? "Select Plan" : "Select Plan"}
                        </Button>
                    </Grid>

                    <Typography sx={{textAlign: "center", margin: "8px auto"}}>
                        or
                    </Typography>

                    <Grid item xs={12} sx={{pt: "0px !important"}}>
                        <Button disableElevation variant="contained" fullWidth sx={{boxShadow: "#0178f261 0px 2px 10px 0px", backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white", boxShadow: "#0178f261 0px 2px 10px 0px"}}}
                            onClick={(e) => handleOpenCustomizePlan(e)}
                        >
                            Select & Customize Plan
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PackageSelectionOptionsDialog