import React, {useEffect,useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import useTemplates from "../../../hooks/useTemplates";

const DeleteTemplateDialog = (props) => {

    const {openDeleteTemplate, handleCloseDeleteTemplateDialog, templateDetails, templateNameState, setIsDeleted, businessIdFromURL} = props;

    const { deleteAccountTemplate, deleted, deleting, getEnterpriseAccountTemplates } = useTemplates();

    const [page, setPage] = useState(false);

    const handleDeleteAccountTemplate = (e) => {
        let formData = new FormData();

        formData.append("template_code", templateDetails?.template_code);
        formData.append("deleted", true);

        deleteAccountTemplate(formData);

    }


    useEffect(() => {
        if (deleted) {

            getEnterpriseAccountTemplates(businessIdFromURL,page);
            
            setIsDeleted(true);
            handleCloseDeleteTemplateDialog();
        }
    }, [deleted]);
    


    return (
        <Dialog open={openDeleteTemplate} onClose={handleCloseDeleteTemplateDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Delete Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
               <Typography sx={{fontSize: "16px"}}>
                    Are you sure you want to delete <strong>{templateNameState}</strong>?
               </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleCloseDeleteTemplateDialog}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', fontSize: 14, '&:hover': {backgroundColor:'#0178f2', color: 'white'}}}
                    onClick={(e) => handleDeleteAccountTemplate(e)}
                    startIcon={deleting && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {deleting ? 'Deleting' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteTemplateDialog