import { Button, ButtonGroup, Divider, FormGroup, Stack, Switch, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useCredits from "../../hooks/useCredits";

const CreditsComponent = (props) => {

    const { data, index, setView, setBusinessName, setBusinessId } = props;

    const { addBusinessCredits, addingBusinessCredits, businessCreditsAdded, subBusinessCredits, subtructingBusinessCredits,
        businessCreditsSub, setBusinessCreditsUnlimited, settingBusinessCreditUnlimited } = useCredits();

    const [isAddBtnClicked, setAddBtnClicked] = useState(false);
    const [isDeductBtnClicked, setDeductBtnClicked] = useState(false);

    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [subCreditAmount, setSubCreditAmount] = useState(0);

    const [selectedIndex] = useState(index);
    const [inActive, setInActive] = useState(false);
    const [active, setActive] = useState(true);
    const [page, setPage] = useState(1);

    const handleAddClicked = (e) => {
        setAddBtnClicked(true);
    }

    const handleDeductClicked = (e) => {
        setDeductBtnClicked(true);
    }

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }

    const handleSubChange = (e) => {
        if (e.target.value < 0) {
            setSubCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setSubCreditAmount(1000000);

        } else {
            setSubCreditAmount(e.target.value);
        }
    }


    const handleAddCredits = () => {

        let payload = {
            business_id: data?.businessAccount?.id,
            credit: addCreditAmount
        }

        addBusinessCredits(payload);
    }

    const handleDeductCredits = () => {
        subBusinessCredits(data?.businessAccount?.id, subCreditAmount);
    }

    const handleCancelAddCredits = (e) => {
        setAddBtnClicked(false);
        setAddCreditAmount(0);

    }

    const handleCancelSubCredits = (e) => {
        setDeductBtnClicked(false);
        setSubCreditAmount(0);

    }


    const handleLimited = (e) => {
        setActive(e.target.checked);

        let unlimited = 'false';

        setBusinessCreditsUnlimited(data?.businessAccount?.id, unlimited);

    }

    const handleUnlimited = (e) => {
        setInActive(e.target.checked);

        let unlimited = 'true';

        setBusinessCreditsUnlimited(data?.businessAccount?.id, unlimited);

    }

    useEffect(() => {
        if(businessCreditsSub){
            setDeductBtnClicked(false);
            // window.location.reload();
            props.getBusinessCredits(page);
        }

    },[businessCreditsSub])

    useEffect(() => {
        if(businessCreditsAdded){
            setAddBtnClicked(false);
            // window.location.reload();
            props.getBusinessCredits(page);
        }

    },[businessCreditsAdded])

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" >
                <img src={data?.businessAccount?.logo} style={{ width: 80 }} />
            </TableCell>
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer', color: "#0178f2" }} onClick={() => [setView(true), setBusinessName(data?.businessAccount?.business_name), setBusinessId(data?.businessAccount?.id)]} >
                {data?.businessAccount?.business_name}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.credit}
            </TableCell>
            <TableCell component="th" scope="row">
                {(isAddBtnClicked && !isDeductBtnClicked) ?
                    <>
                        <TextField
                            size="small"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            InputProps={{
                                inputProps: {
                                    max: 1000000, min: 0
                                }
                            }}
                            value={addCreditAmount}
                            onChange={(e) => handleAddChange(e)}
                            sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                        />

                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                onClick={(e) => ((addCreditAmount == 0) || addingBusinessCredits) ? null : handleAddCredits(e)}
                                startIcon={addingBusinessCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                            >
                                {addingBusinessCredits ? 'Saving' : 'Save'}
                            </Button>

                            <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                            <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                onClick={(e) => handleCancelAddCredits(e)}
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </>

                :
                    (!isAddBtnClicked && isDeductBtnClicked) ?
                        <>
                            <TextField
                                size="small"
                                type="number"
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                InputProps={{
                                    inputProps: {
                                        max: 1000000, min: 0
                                    }
                                }}
                                value={subCreditAmount}
                                onChange={(e) => handleSubChange(e)}
                                sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                            />

                            <ButtonGroup variant="text" aria-label="text button group">
                                <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                    onClick={(e) => ((subCreditAmount == 0) || subtructingBusinessCredits) ? null : handleDeductCredits(e)}
                                    startIcon={subtructingBusinessCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    {subtructingBusinessCredits ? 'Saving' : 'Save'}
                                </Button>

                                <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                                <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                    onClick={(e) => handleCancelSubCredits(e)}

                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </>

                :
                    <>
                        <Button variant="outlined" sx={{
                            width: "auto", borderRadius: 1, textTransform: "none",
                            backgroundColor: "transparent", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                        }}
                            onClick={(e) => handleAddClicked(e)}
                            startIcon={<AddIcon />}
                        >
                            Add Credits
                        </Button>

                        &nbsp; &nbsp; &nbsp;

                        <Button variant="outlined" sx={{
                            width: "auto", textTransform: "none", borderRadius: 1,
                            backgroundColor: "#transparent", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                        }}
                            onClick={(e) => handleDeductClicked(e)}
                            startIcon={<RemoveIcon />}
                        >
                            Deduct Credits
                        </Button>

                    </>
                }
            </TableCell>
        </TableRow>
    )
}

export default CreditsComponent