import environment from "../../../../environment/environment";
import routes from "../../common/api-routes";
import {HttpGet, HttpPost, HttpDelete, HttpPut} from "../../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getList = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.ADMIN_LIST_ROLE, query_params, subscriptionKey);

    return result;
}

const viewRole = (role) => {
    let query_params = {
        role: role
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.ADMIN_VIEW_ROLE, query_params, subscriptionKey);
    return result;
}

const deleteRole = (id) => {
    let query_params = {
        id: id
    };
    let result = HttpDelete(routes.ADMIN_DELETE_ROLE, query_params);
    return result;
}

const updateRole = (id, role, description) => {
    let payload = {
        role: role,
        description: description
    };
    let result = HttpPut(routes.ADMIN_UPDATE_ROLE + "?id=" + id, payload);
    return result;
}

const updateRoleStatus = (id, active) => {
    let result = HttpPut(routes.ADMIN_UPDATE_ROLE_STATUS + "?id=" + id + "&active=" + active, null);
    return result;
}

const createRole = (role, description, parent_role_id) => {
    let payload = {
        role: role,
        description: description,
        parent_role_id: parent_role_id
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADMIN_CREATE_ROLE, payload, subscriptionKey);
    return result;
}

const AdminRbacRolesManagementService = {
    getList: getList,
    viewRole: viewRole,
    deleteRole: deleteRole,
    updateRole: updateRole,
    updateRoleStatus: updateRoleStatus,
    createRole: createRole
}

export default AdminRbacRolesManagementService;