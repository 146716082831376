import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, Typography } from "@mui/material";
import QRCodeGenerator from 'qrcode';
import { getResponsiveFontSize } from "../../utils/FontUtil";
import OVCodeSealImg from "../../images/ovcode-seal.png";



const MultiplePageViewElementComponent = ({ element }) => {
    console.log('ssssssssssssssssssss', element)

    const textParentRef = useRef();
    const [qrPreview, setQRPreview] = useState(null);

    useEffect(() => {
        generateQR("https://ovcode.com/v/f-0000000-000-0000-0000-000000000000");
    }, []);

    const generateQR = async (value) => {
        const generatedQR = await QRCodeGenerator.toDataURL(value);
        setQRPreview(generatedQR);
        return generatedQR;
    };

    const handleResponsiveFontSize = () => {
        const defaultFontSize = 6.5;
        const defaultParentWidth = 99;
        const parentCurrentWidth = textParentRef.current?.clientWidth;
        if (parentCurrentWidth) {
            return getResponsiveFontSize(defaultFontSize, defaultParentWidth, parentCurrentWidth);
        }
        return defaultFontSize;
    };

    return (
        <Box
            data-testid="box"
            sx={{
                height: 80,
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                borderStyle: 'dotted !important',
                bgcolor: '#e7e7e7',
                border: '1px solid',
                borderColor: '#bfbfbf',
                cursor: 'no-drop',
                p: '0px 10px 0px 0px',
                position: 'relative',
                textTransform: 'none',
                lineHeight: 1.2,
                transition: 'all 0.1s ease 0s',
                borderRadius: 1,
            }}
        >
            <Grid container sx={{ justifyContent: 'center', textAlign: 'center', alignSelf: 'center' }}>
                <Grid item xs={12} >
                    <Box sx={{ flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: 13, color: 'rgba(24, 24, 24, 0.85)' }}>
                            {element?.type === 'signature'
                                ? 'eSignature'
                                : element?.type === 'qrFld'
                                    ? 'QR Code'
                                    : element?.type === 'txtFld'
                                        ? 'Text Field'
                                        : element?.type === 'date'
                                            ? 'Date'
                                            : element?.type === 'companySeal'
                                                ? 'E-seal'
                                                : element?.type === 'userSignature'
                                                    ? 'eSignature'
                                                        : null}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {element?.type === 'userSignature' ? (
                        <img alt="" src={element?.value} style={{ objectFit: 'contain', height: 40 }} />
                    ) : element?.type === 'qrFld' ? (
                        <img alt="" src={qrPreview} style={{ objectFit: 'contain', height: 40 }} />
                    ) : element?.type === 'companySeal' ? (
                        <img alt="" src={OVCodeSealImg} style={{ objectFit: 'contain', height: 40}} />
                    ) : element?.type === 'txtFld' ?(
                        <Typography sx={{ color: '#0178f2', padding: 0, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{element?.value}</Typography>
                    ) : element?.type === 'signature' ? (
                        <img alt="" src={element?.value} style={{ objectFit: 'contain', height: 40, width:'100%' }} />
                     ) :
                     element?.type === 'date' ? (
                        <Typography sx={{ color: '#0178f2', padding: 0 }}>{element?.value}</Typography>
                     ) :
                        null
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

export default MultiplePageViewElementComponent;
