import React, {useState, useEffect} from 'react';
import {useDrop, DndProvider } from 'react-dnd'
import {v4 as uuidv4} from 'uuid';

import {Page} from 'react-pdf';
import { useRef } from 'react';
import PDFElementComponent from './pdf-element.component';

import {initialDragElements, elementTypes, dragSourceType} from "../../common/staticVariables";
import {getProportionalCoords, getReverseProportionCoords, getProportionalDimens, getReverseProportionDimens} from "../../utils/ProportionDimenUtil";
import {useReactDnDHelper} from "../../hooks/react-dnd";
import {useDraggedItemStore} from "../../zustand/useDraggedItemStore";
import useWidth from '../../hooks/useWidth';
import {useDropHook} from "../../hooks/react-dnd/useDropHook";
import MsalUtil from "../../services/utils/msals";
import { isOutsideParentBoundingBox, isOutsideParentBoundingBoxV2 } from '../../utils/BoundingBoxUtil';
import { ptToPx, pxToPt } from "../../utils/UnitConverterUtil";
import ChangeElementSignatureDialogComponent from './signature-dialog/change-signature-dialog.component';


/**
 * Draw Text on PDF Page
 * @param {Number} page_number 
 * @param {PDFPage} pdf_page 
*/


let pagesArr = []
let template_props_from_api = []

function PDFPageComponent({pdfPageOrigWidth, pdfPageOrigHeight, adjustedPDFWidth, template_code_queryParam, numPages, page_number, templateProperties, elements, setElements, setTemplatePropertiesPayload, 
    setDeletedPropertyIdsPayload, isViewing, templatePropertiesPayload, canConfigTemplate, setHasSeal, setUserSignatureUpdated, deletedElements, setDeletedElements,
    signatureUpdated, setSignatureUpdated, signatureDeleted, setSignatureDeleted,
    saveTemplateElementToCache, userSignatureUpdated, currentSelectedUser, setCurrentSelectedUser}){

    const breakpoints = useWidth();

    const [currentElement, setCurrentElement] = useState(null)
    const [currentPage, setCurrentPage] = useState({originalWidth: 0, originalHeight: 0})
    const [hasElDropped, setHasElDropped] = useState(false)
    const [hasElResizeStop, setHasElResizeStop] = useState(false)
    const [hasElInputStop, setHasElInputStop] = useState(false)
    const [parentRef, setParentRef] = useState(null)

    const {setDraggedElement, draggedElement} = useDraggedItemStore();


    const pdfPageRef = useRef()
    // const userLoggedInData = MsalUtil.getFirstAccount()
    const B2CUsers = templateProperties?.users

    console.log("B2CUsers 11111", B2CUsers);

    const {dndRef, dropResult, getCoords} = useDropHook(pdfPageRef, dragSourceType, page_number)

    console.log("dropResult bbb", dropResult);
    console.log("dndRef ccc", dndRef);
    


    useEffect(() => {
        if (dndRef != null) setParentRef(dndRef)
    }, [dndRef])
    
    const {getCorrectDroppedOffsetValue} = useReactDnDHelper(dndRef)

    const [openChangeSignature, setOpenChangeSignature] = useState(false);
    const [openAddSignature, setOpenAddSignature] = useState(false);
    const [tempSignatureElement, setTempSignatureElement] = useState(null);
    const [pdfPages, setPdfPages] = useState([])
    const [elementResizedData, setElementResizedData] = useState(null)
    const [elementUpdateValueData, setElementUpdateValueData] = useState(null)
    const [elementDeletedData, setElementDeletedData] = useState(null)
    const [duplicateElementData, setDuplicateElementData] = useState({isDuplicate: false, data: null})
    const [textElementFontSize, setTextElementFontSize] = useState({id: null, font_size: null})
    const [elementRef, setElementRef] = useState(null)
    const [testState, setTestState] = useState([])
    const [currentESignatureUserId, setCurrentESignatureUserId] = useState(null)
    const [currentESignatureB2CId, setCurrentESignatureB2CId] = useState(null)

    useEffect(() => {
        console.log("currentESignatureUserId 1111", currentESignatureUserId);
    }, [currentESignatureUserId])

    // Load pdf pages and plot elements 
    useEffect(() => {
        console.log("templateProperties aa111 ", templateProperties);
        if (pdfPages?.length > 0) {
            let elements_from_api = []
            templateProperties?.templateProperties.forEach((element) => {
                console.log("templateProperties aa11", templateProperties);
                elements_from_api = getElementsFromTemplateProperties(element, pdfPages)
            })
            handlePlotElements(elements_from_api)
        }
    }, [JSON.stringify(templateProperties), JSON.stringify(pdfPages)])

    // Handle drop element
    useEffect(() => {
        console.log("drop result aaaa", dropResult);
        if (dropResult) handleDropElement(dropResult)
    }, [JSON.stringify(dropResult)])

    // Handle resize element
    useEffect(() => {
        if (elementResizedData) handleResizeElement(elementResizedData)
    }, [JSON.stringify(elementResizedData)])

    // Handle update element
    useEffect(() => {
        if (elementUpdateValueData) handleUpdateValueElement(elementUpdateValueData)
    }, [JSON.stringify(elementUpdateValueData)])

    // Handle remove element
    useEffect(() => {
        if (elementDeletedData) handleRemoveElement(elementDeletedData)
    }, [JSON.stringify(elementDeletedData)])

    // Handle duplicate element
    useEffect(()=> {
        if (duplicateElementData.isDuplicate) {
            handleDuplicateElement(duplicateElementData.data)

            // reset
            setDuplicateElementData({isDuplicate: false, data: null})
        }
    }, [JSON.stringify(duplicateElementData)])

    // Handle text element font size
    useEffect(()=> {
        handleTextElementFontSize(textElementFontSize)
    }, [JSON.stringify(textElementFontSize)])

    // save deleted elements to cache
    useEffect(() => {
        console.log("deleted Elemetns aaa", deletedElements);
        localStorage.setItem("deleted_elements", JSON.stringify(deletedElements))
    }, [JSON.stringify(deletedElements)])

    // save elements to cache after plotting elements
    useEffect(() => {
        console.log("elements fff", elements);
        // save elements to local storage
        // let storedElements = JSON.parse(localStorage.getItem("template_elements"));
        // let cachedElements = !storedElements || !storedElements[template_code_queryParam] ? null : storedElements[template_code_queryParam];
        // if (JSON.stringify(cachedElements) != JSON.stringify(elements)) {
        //     // saveTemplateElementsToCache(elements)
        // }

        // check has plotted eSeal
        let hasPlottedCompanySeal = elements.some((item) => item.type == elementTypes.companySeal)
        setHasSeal(hasPlottedCompanySeal)
    }, [JSON.stringify(elements)])

    useEffect(() => {
        if (signatureUpdated.isUpdated) {
            let updated_signature_id = signatureUpdated.data.id;
            let updated_signature_url = signatureUpdated.data.signature_url;

            setElements((prev) => {
                prev.map((item) => {
                    if (item.signature_id == updated_signature_id) {
                        item.value = updated_signature_url

                        handleTemplatePropsPayload(item.id, item, item.signature_id)
                    }
                })
                return prev
            })

            // reset signatureUpdated
            setSignatureUpdated({isUpdated: false, data: null})
        }
    }, [JSON.stringify(signatureUpdated)])

    useEffect(() => {
        if (userSignatureUpdated.isUpdated) {
            console.log("currentSelectedUser mmmm", currentSelectedUser);
            let signature = userSignatureUpdated.data
            console.log("userSignatureUpdated sig 1111", signature);

            let hasESignaturePlotted = elements.some((item) => item.type == elementTypes.userSignature)
            console.log("hasESignaturePlotted sig 1111", hasESignaturePlotted);

            if (hasESignaturePlotted) {
                setElements((prev) => {
                    prev.map((item) => {
                        if (item.userId == currentSelectedUser?.user_id) {
                            console.log("update sig bbb", item);
                            console.log("userSignatureUpdated sig bbb", signature);
                            item.value = signature;

                            // update cached elements
                            saveTemplateElementToCache(item)

                            // update template pros payload
                            handleTemplatePropsPayload(item.id, item, item.signature_id)
                        }
                    })
                    return prev
                })
            }

            // reset userSignatureUpdated
            setUserSignatureUpdated({isUpdated: false, data: null})
        }
    }, [JSON.stringify(userSignatureUpdated), JSON.stringify(currentSelectedUser), JSON.stringify(B2CUsers)])

    useEffect(() => {
        if (signatureDeleted.isDeleted) {
            let remaining_elements = elements.filter((item) => item.signature_id != signatureDeleted.id)
            let remaining_props = templatePropertiesPayload.filter((prop) => prop.signature_id !== signatureDeleted.id);
            // let elements_to_remove = elements.filter((item) => item.signature_id == signatureDeleted.id)

            console.log("remaining_props aa11111", remaining_props);
            // console.log("elements_to_remove aa11111", elements_to_remove);

            setElements(remaining_elements)
            setTemplatePropertiesPayload(remaining_props)
            // setDeletedElements((prev) => [...new Set([...prev, ...elements_to_remove])])

            // reset signatureDeleted
            setSignatureDeleted({isDeleted: false, id: null})
        }
    }, [JSON.stringify(signatureDeleted), JSON.stringify(elements)])

    const getElementsFromTemplateProperties = (element, pdfPages) => {
        if (templateProperties?.templateProperties.length == 0) return []

        let page = pdfPages.find((item) => item.pageNumber == element.page_no)
        if (page.pageNumber == element.page_no) {
            let x = parseFloat(element.x_coordinate)
            let y = parseFloat(element.y_coordinate)
            let w = parseFloat(element.width)
            let h = parseFloat(element.height)

            console.log("w bbb", w);
            console.log("h bbb", h);

            let signatureValueId;
            let signatureValueUrl;
            
            if (templateProperties?.signatures.length > 0) {
                let matchedSignature = templateProperties?.signatures?.find((signature) => signature?.signature_url === element.value);
                
                if (matchedSignature) {
                    signatureValueId = matchedSignature?.id;
                    signatureValueUrl = matchedSignature?.signature_url;
                }
            }

            let dropRefDimens = page.rotation == 90 || page.rotation == 270 ? ({width: page.dropRefCurrentHeight, height: page.dropRefCurrentWidth}) : ({width: page.dropRefCurrentWidth, height: page.dropRefCurrentHeight})
            let dropRefCurrentWidth = dropRefDimens.width
            let dropRefCurrentHeight = dropRefDimens.height

            let pageOrigWidth = page.pageOrigWidth
            let pageOrigHeight = page.pageOrigHeight

            let b2c_user = elementTypes.userSignature ? B2CUsers.find((item) => item.signature == element.value) : null
            const {w: adjustedlElWidth, h: adjustedElHeight} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, w, h)
            let {x: reversedX, y: reversedY} = getReverseProportionCoords(page.rotation, page.dropRefCurrentWidth, page.dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, x, y)

            let propObj = {
                id: element.id,
                signature_id: (element.type === "signature" && signatureValueUrl === element.value) && signatureValueId,
                page_no: element.page_no,
                type: element.type,
                value: element.value,
                font_size: parseInt(element.font_size),
                userId: element.type == elementTypes.userSignature ? b2c_user?.uuid : null,
                b2c_id: element.type == elementTypes.userSignature ? b2c_user?.id : null,
                x: reversedX,
                y: reversedY,
                // ...getReverseProportionCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, x, y), 
                // ...getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, w, h)
                w: element.type == elementTypes.companySeal ? w : adjustedlElWidth,
                h: element.type == elementTypes.companySeal ? h : adjustedElHeight
            }

            template_props_from_api.push(propObj)
        }

        let removed_duplicates_from_template_props_api = Array.from(new Set(template_props_from_api.map(obj => JSON.stringify(obj))), JSON.parse);
        console.log("unique array aaaa", removed_duplicates_from_template_props_api);
        return removed_duplicates_from_template_props_api;
    }
 
    const handlePlotElements = (elements_from_api) => {
        // View Template
        if (isViewing) {
            setElements(elements_from_api)
            return;
        }

        let cachedElements = JSON.parse(localStorage.getItem("template_elements"));
        let stored_deleted_elements = JSON.parse(localStorage.getItem("deleted_elements"));
        let deletedElements = !stored_deleted_elements ? [] : stored_deleted_elements

        let hasDocumentCached = !cachedElements || !cachedElements[template_code_queryParam] ? false : true;

        // Edit Template
        let cache_elements = [];

        if (!hasDocumentCached) {
            // Remove elements from API res if has previously deleted elements stored in cache
            let filtered_elements = elements_from_api
            if (deletedElements.length > 0) {
                filtered_elements = elements_from_api.filter((item) => item.id != deletedElements.find((el) => item.id == el.id)?.id)
            }
            setElements(filtered_elements)
            return
        }
        
        if (hasDocumentCached) {
            cache_elements = cachedElements[template_code_queryParam]

            // For Handle API Payloads
            cache_elements.forEach((item) => {
                handleTemplatePropsPayload(item.id, item, item.signature_id)
            })

            // for sync cache and api data if cache elements is empty
            if (cache_elements.length == 0) {
                let filtered_elements = elements_from_api.filter((item) => item.id != deletedElements.find((el) => item.id == el.id)?.id)
                console.log("filtered_elements aaa", filtered_elements);
                setElements(filtered_elements)
                return;
            }

            // for sync cache and api data if cache elements is not empty
            if (cache_elements.length > 0) {
                let filtered_elements = elements_from_api.filter((item) => item.id != cache_elements.find((el) => item.id == el.id)?.id)
                console.log("filtered_elements bbb", filtered_elements);
                
                if (filtered_elements.length > 0) {
                    cache_elements = [...cache_elements, ...filtered_elements]
                }

                console.log("cache_elements bbb", cache_elements);

                setElements(cache_elements)
                return;
            }
        }
    }

    const handleDropElement = (currentElement) => {
        console.log("currentElement aaa", currentElement);

        let isUpdate = currentElement.id ? true : false;
        console.log("isUpdate aaa", isUpdate);

        // Add New Element
        if (!isUpdate) {
            handleAddNewElement(uuidv4(), currentElement)
            return;
        }

        // Update Element
        handleUpdateElement(currentElement)
    }

    const handleResizeElement = (currentElement) => {
        // const parentRect = parentRef.current.getBoundingClientRect();
        // console.log("elementRef bbbbbb", elementRef.current);
        // if (elementRef.current) {
        //     const childRect = elementRef.current.getBoundingClientRect();
        //     const isOutsideBox = isOutsideParentBoundingBox(parentRect, childRect)
        //     console.log("parentRect ttt", parentRect);
        //     console.log("childRect ttt", childRect);
        //     console.log("isOutsideBox ttt", isOutsideBox);
    
        //     // Update element dimens and coords if element is outside of parent
        //     if (isOutsideBox) {
        //         setElements((prev) => {
        //             let el = prev.find((item) => item.id == currentElement.id)
        //             if (el) {
        //                 let updated_coords = getCoords(dndRef, el.initialPosition, el.afterDropPosition, currentElement.width, currentElement.height)
        //                 el.page_no = page_number
        //                 el.x = updated_coords.x
        //                 el.y = updated_coords.y
        //                 el.w = currentElement.w
        //                 el.h = currentElement.h
    
        //                 // save element to cache
        //                 saveTemplateElementToCache(el)
    
        //                 // For Handle API Payloads
        //                 handleTemplatePropsPayload(el.id, el, el.signature_id)
        //             }
    
    
        //             return [...prev]
        //         })
        //         return;
        //     }
        // }

        // Update element dimens
        setElements((prev) => {
            let el = prev.find((item) => item.id == currentElement.id)
            if (el) {
                el.page_no = page_number
                el.w = currentElement.w
                el.h = currentElement.h

                // save element to cache
                saveTemplateElementToCache(el)

                // For Handle API Payloads
                handleTemplatePropsPayload(el.id, el, el.signature_id)
            }

            return [...prev]
        })
    }

    const handleUpdateValueElement = (currentElement) => {
        console.log("currentElement aaa11", currentElement);
        setElements((prev) => {
            let el = prev.find((item) => item.id == currentElement.id)
            console.log("el aaa11", el);

            if (el) {
                el.page_no = page_number
                el.value = currentElement.value

                // save element to cache
                saveTemplateElementToCache(el)

                // For Handle API Payloads
                handleTemplatePropsPayload(el.id, el, el.signature_id)
            }
            return [...prev]
        })

    }

    const handleRemoveElement = (currentElement) => {
        // update cached elements
        let cachedElements = JSON.parse(localStorage.getItem("template_elements"))
        if (cachedElements) {
            cachedElements = {
                [template_code_queryParam]: cachedElements[template_code_queryParam].filter((element) => element.id != currentElement.id)
            }
            localStorage.setItem("template_elements", JSON.stringify(cachedElements))
        }

        const elements_to_remove = elements.filter((element) => element.id == currentElement.id);
        const remaining_elements = elements.filter((element) => element.id !== currentElement.id);
        const remaining_props = templatePropertiesPayload.filter((prop) => prop.id !== currentElement.id);

        //add to deleted elements array
        setDeletedElements((prev) => [...prev, ...elements_to_remove])

        setElements(remaining_elements);
        setTemplatePropertiesPayload(remaining_props)
        handleDeletedPropertyIds(currentElement.id)
        setHasSeal(false)
    }

    const handleDuplicateElement = (currentElement) => {
        let id = uuidv4()
        let x = currentElement.x + 20
        let y = currentElement.y + 20
        let type = currentElement.type
        let width = currentElement.w
        let height = currentElement.h
        let value = currentElement.value
        let signature_id = currentElement.signature_id
        let userId = currentElement.userId
        let b2c_id = currentElement.b2c_id
        let initialPosition = currentElement.initialPosition
        let afterDropPosition = currentElement.afterDropPosition
        let font_size = currentElement.font_size

        console.log("handleDuplicateElement aa", currentElement);
        let newElement = addElement(id, type, width, height, x, y, value, signature_id, userId, b2c_id, initialPosition, afterDropPosition, font_size)
        const parentRect = parentRef.current.getBoundingClientRect();
        const isOutsideBox = isOutsideParentBoundingBoxV2(parentRect, newElement.x, newElement.y, newElement.w, newElement.h)

        if (isOutsideBox) {
            newElement.x = newElement.x - 20
            newElement.y = newElement.y - 20
        }

        // setElements(newElements)
        setElements((prev) => [...prev, newElement])

        // save element to cache
        saveTemplateElementToCache(newElement)
        
        // For Handle API Payloads
        handleTemplatePropsPayload(id, currentElement, currentElement.signature_id)
    }

    const handleTextElementFontSize = (textElementFontSizeData) => {
        setElements((prev) => {
            let el = prev.find((item) => item.id == textElementFontSizeData.id)
            console.log("el aaa2222", el);

            if (el) {
                el.font_size = textElementFontSizeData.font_size

                // save element to cache
                saveTemplateElementToCache(el)

                // For Handle API Payloads
                handleTemplatePropsPayload(el.id, el, el.signature_id)
            }
            return [...prev]
        })
    }

    const handleAddNewElement = (id, currentElement) => {
        let x = currentElement.x
        let y = currentElement.y
        let type = currentElement.type
        let width = currentElement.w
        let height = currentElement.h
        let value = currentElement.value
        let signature_id = currentElement.signature_id
        let userId = currentElement.userId
        let b2c_id = currentElement.b2c_id
        let initialPosition = currentElement.initialPosition
        let afterDropPosition = currentElement.afterDropPosition
        let font_size = currentElement.font_size
        let newElement = addElement(id, type, width, height, x, y, value, signature_id, userId, b2c_id, initialPosition, afterDropPosition, font_size)
        console.log("newElement 111", newElement);

        setElements((prev) => [...prev, newElement])

        // save element to cache
        saveTemplateElementToCache(newElement)
        
        // For Handle API Payloads
        handleTemplatePropsPayload(id, currentElement, currentElement.signature_id)
    }

    const handleUpdateElement = (currentElement) => {
        setElements((prev) => {
            let el = prev.find((item) => item.id == currentElement.id)
            console.log("el aaa", el);
            if (el) {
                el.page_no = page_number
                el.initialPosition = currentElement.initialPosition
                el.afterDropPosition = currentElement.afterDropPosition
                el.x = currentElement.x
                el.y = currentElement.y

                // save element to cache
                saveTemplateElementToCache(el)
            }

            return [...prev]
        })

        // For Handle API Payloads
        handleTemplatePropsPayload(currentElement.id, currentElement, currentElement.signature_id)
    }

    const handleTemplatePropsPayload = (id, currentElement, signature_id) => {
        
        
        // let userId = userLoggedInData.idTokenClaims.oid
        let el = elements.find((item) => item.id == id) || currentElement

        console.log("el lll", el);
        let currentPage = pagesArr.find((item) => item.pageNumber == el.page_no)

        let dropRefCurrentWidth = currentPage?.dropRefCurrentWidth
        let dropRefCurrentHeight = currentPage?.dropRefCurrentHeight
        let pageRotation = currentPage?.rotation
        let pageOrigWidth = currentPage?.pageOrigWidth
        let pageOrigHeight = currentPage?.pageOrigHeight

        let elOrigWidth = el.w - 2 // which -2 is the margin from resizable box library
        let elOrigHeight = el.h - 2 // which -2 is the margin from resizable box library
        // let x = el.x
        // let y = el.y

        let {x, y} = getProportionalCoords(pageRotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, el.x, el.y)
        let {w, h} = getProportionalDimens(pageRotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, elOrigWidth, elOrigHeight)
        let prop_id = id

        let b2c_user = el.type == elementTypes.userSignature ? B2CUsers.find((item) => item.signature == el.value) : null
        let userId = currentSelectedUser?.user_id || b2c_user?.uuid

        console.log("userId aaa", userId);

        let prop_value = el.type == elementTypes.qrcode ? 
                    "https://ovcode.com/v/f-0000000-000-0000-0000-000000000000" 
                : el.type == "signature" ? 
                    el.signature_id.toString() 
                : el.type == elementTypes.userSignature ? 
                    userId
                : el.value
        let companySealDimens = initialDragElements.find((el) => el.type == elementTypes.companySeal)
        let propX = x.toString()
        let propY = y.toString()
        let propW = w.toString()
        let propH = h.toString()

        setTemplatePropertiesPayload((prev) => {
            let prop = prev.find((item) => item.id == el.id)

            console.log("prev lll", prev);
            console.log("prop lll", prop);

            if(!prop) return [...prev, addTemplatePropertyPayload(prop_id, el.page_no, el, propX, propY, propW, propH, prop_value, signature_id)]

            prop.page_no = el.page_no
            prop.type = el.type
            prop.value = el.type == elementTypes.qrcode ? 
                    "https://ovcode.com/v/f-0000000-000-0000-0000-000000000000" 
                : 
                    el.type == elementTypes.signature ? 
                    signature_id.toString()
                : 
                    el.type == elementTypes.userSignature ? 
                    userId
                :
                    elementTypes.textfield ? el.value 
                :
                    elementTypes.companySeal ? "logo" 
                : 
                    prop.value
            prop.x_coordinate = propX
            prop.y_coordinate = propY
            prop.width = el.type == elementTypes.companySeal ? el.w.toString() : propW
            prop.height = el.type == elementTypes.companySeal ? el.h.toString() : propH

            if (prop.type == elementTypes.signature) {
                prop.signature_url = el.value
            }

            if (prop.type == elementTypes.textfield || prop.type == elementTypes.date) {
                prop.font_size = el.font_size
            }

            console.log("prop 222", prev);

            return [...prev]
           
        })
    }

    const handleDeletedPropertyIds = (id) => {
        console.log("handleDeletedPropertyIds aa", id);
        setDeletedPropertyIdsPayload((prev) => templateProperties?.templateProperties.some((item) => item.id == id) && !prev.some((prevId) => prevId == id) ? [...prev, id] : [...prev])
    }

    const addElement = (id, type, width, height, x, y, value, signature_id, userId, b2c_id, initialPosition, afterDropPosition, font_size) => {
        
        let dropRefCurrentWidth = dndRef.current.clientWidth
        let dropRefCurrentHeight = dndRef.current.clientHeight
        let pageOrigWidth = currentPage?.originalWidth
        let pageOrigHeight = currentPage?.originalHeight

        let adjustedElDimens = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height)
        return {id, page_no: page_number, type, x, y, userId, b2c_id, initialPosition, afterDropPosition, w: width, h: height, value, ...(signature_id ? { signature_id: signature_id } : {}),
            ...((type == elementTypes.textfield || type == elementTypes.date) ? { font_size } : {})}
    }

    const addTemplatePropertyPayload = (id, page_number, element, propX, propY, propW, propH, value, signature_id) => {
        
        let payload = {
            id,
            signature_id: signature_id,
            page_no: page_number,
            type: element.type,
            x_coordinate: propX,
            y_coordinate: propY,
            value: (element.type == "signature") ? signature_id.toString() : value,
            ...((element.type == elementTypes.textfield || element.type == elementTypes.date) && {font_size: element.font_size}),  
            ...(element.type == elementTypes.signature && {signature_url: element.value}),  
            ...(element.type == elementTypes.companySeal ? {width: element.w.toString(), height: element.h.toString()} : {width: propW, height: propH}),  
        }

        console.log("payload 1111", payload);
        return payload
    }

    function handleOnLoadSuccessPDFPage(page) {
        console.log("page aaaa", page);
        setCurrentPage(page)
        handleOnLoadPlotTemplateFields(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    const handleOnLoadPlotTemplateFields = (page) => {
        let dropRefCurrentWidth = page?.width
        let dropRefCurrentHeight = page?.height
        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNumber = page?.pageNumber
        let rotation = page?.rotate

        let pdf_page = {dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, pageNumber, rotation}
        pagesArr.push(pdf_page)

        if (pagesArr.length == numPages) setPdfPages(pagesArr)
    }

    const openChangeSignatureFromParent = (child) => {
        console.log("open signature aaa");
        setOpenChangeSignature(child);
    }

    const handleCloseChangeSignature = () => {
        setOpenChangeSignature(false);
    }

    const sendTempSignatureToParent = (child) => {
        setTempSignatureElement(child);
    }

    
    return(
        <>
            <p style={{color: '#5e5d5d'}}>Page {currentPage?.pageNumber} / {numPages}</p>
            <Page inputRef={dndRef} className={'pdf_page_custom'} pageNumber={page_number} 
                // width={adjustedPDFWidth} 
                scale={1.3} 
                onLoadSuccess={handleOnLoadSuccessPDFPage}>
                {
                    elements.map((el) => (
                        <>
                            {(el.page_no == page_number) &&
                                <PDFElementComponent element={el} setElementDeletedData={setElementDeletedData} setCurrentElement={setCurrentElement} 
                                setHasElResizeStop={setHasElResizeStop} setHasElInputStop={setHasElInputStop} isViewing={isViewing} 
                                openChangeSignatureFromParent={openChangeSignatureFromParent} 
                                openChangeSignature={openChangeSignature}
                                templateProperties={templateProperties}
                                setOpenAddSignature={setOpenAddSignature} sendTempSignatureToParent={sendTempSignatureToParent} canConfigTemplate={canConfigTemplate}
                                setElementResizedData={setElementResizedData} elementRef={elementRef} setElementRef={setElementRef} parentRef={parentRef} setElementUpdateValueData={setElementUpdateValueData}
                                setDuplicateElementData={setDuplicateElementData} setTextElementFontSize={setTextElementFontSize}
                                setCurrentESignatureUserId={setCurrentESignatureUserId}
                                setCurrentESignatureB2CId={setCurrentESignatureB2CId}
                            />
                            }
                        </>
                    ))
                }
            </Page>
            {openChangeSignature &&
                <ChangeElementSignatureDialogComponent openChangeSignature={openChangeSignature}
                    openChangeSignatureFromParent={openChangeSignatureFromParent} handleClose={handleCloseChangeSignature}  
                    setUserSignatureUpdated={setUserSignatureUpdated} currentSelectedUserId={currentESignatureUserId} currentESignatureB2CId={currentESignatureB2CId} onSelectUser={setCurrentSelectedUser}
                />
            }

            {/* {openAddSignature &&
                <EditTemplateSignatureDialog tempSignatureElement={tempSignatureElement} breakpoints={breakpoints}
                    openAddSignature={openAddSignature} setOpenAddSignature={setOpenAddSignature}
                />
            } */}
        </>
    )
}

export default PDFPageComponent