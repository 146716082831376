import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, Typography, Dialog, DialogTitle, Divider, DialogContent, DialogActions, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {ThreeDots} from 'react-loader-spinner';
import useEsignProjectWhitelistDomains from "../../hooks/useEsignProjectWhitelistDomains";
import { useSnackbar } from "notistack";

function SignatoryDomainComponent({projectId, businessId, onError}){
    
    const { whitelist_domains, loading, error, getSignatoryWhitelistDomains } = useEsignProjectWhitelistDomains();
    const { enqueueSnackbar } = useSnackbar();
    const [ open_addDomainDialog, setOpen_addDomainDialog ] = useState(null)
    const [ open_deleteDomainDialog, setOpen_deleteDomainDialog ] = useState(null)
    const [ selectedDomain, setSelectedDomain ] = useState(null)

    useEffect(()=>{
        if(error == null) return;
        onError(error)
    }, [error])

    useEffect(()=>{
        getSignatoryWhitelistDomains(projectId, businessId, 1, 10);
    }, [])

    function handleOnClickDelete(domain){
        setSelectedDomain(domain)
        setOpen_deleteDomainDialog(true)
    }

    function handleOnAddDomain(data){
        enqueueSnackbar(data.data, {
            variant: "success",
            autoHideDuration: 2000
        });
        setOpen_addDomainDialog(false);
        getSignatoryWhitelistDomains(projectId, businessId, 1, 10);
    }

    function handleOnDeleteDomain(data){
        enqueueSnackbar(data.data, {
            variant: "success",
            autoHideDuration: 2000
        });
        setOpen_deleteDomainDialog(false);
        setSelectedDomain(null)
        getSignatoryWhitelistDomains(projectId, businessId, 1, 10);
    }
   
    if(loading) return(<>loading . . .</>);
    if(error != null) return null;
    
    return(
        <>
            <Box textAlign='center' sx={{mb: 1}}>
                <Button 
                    variant="text" 
                    startIcon={<AddIcon />}
                    sx={{ textTransform: 'none', fontWeight: 'bold', borderColor: '#0178f2', borderRadius: 1 }}
                    onClick={()=>setOpen_addDomainDialog(true)}>
                    Add Domain
                </Button>
            </Box>
            <SignatoryDomainList whitelist_domains={whitelist_domains} onClickDeleteIcon={(domain)=>handleOnClickDelete(domain)} />
            <AddDomainDialog 
                open={open_addDomainDialog} 
                onClickCancel={() => setOpen_addDomainDialog(false)} 
                projectId={projectId}
                businessId={businessId}
                onAddDomain={(data) => handleOnAddDomain(data) }
            />
            <DeleteDomainDialog
                domain={selectedDomain}
                open={open_deleteDomainDialog} 
                onClickCancel={() => setOpen_deleteDomainDialog(false) }
                onDeleteDomain={(data) => handleOnDeleteDomain(data)}
            />
        </>
    )
}

function SignatoryDomainList({whitelist_domains, onClickDeleteIcon}){
    return(
        <Box sx={{ border: '1px solid #0178f2', borderRadius: 1 }}>
            { whitelist_domains.length == 0 && <Typography sx={{p: 2}} textAlign='center'>No domain found.</Typography> }
            <List sx={{ p: '0px' }}>
                { whitelist_domains.map((domain, index)=> {
                    return(
                        <SignatoryDomainListItem key={index} domain={domain.domain} onClickDelete={()=>onClickDeleteIcon(domain)}/>
                    )
                })
                }
            </List>
        </Box>
    )
}

function SignatoryDomainListItem({domain, onClickDelete}){
    return(
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => onClickDelete(domain)} >
                    <CloseIcon sx={{ color: 'red' }} />
                </IconButton>
            }
        >
        <ListItemText primary={domain}/>
    </ListItem>
    )
}

function AddDomainDialog({projectId, businessId, open, onClickCancel, onAddDomain}){

    const [ domain, setDomain ] = useState("")
    const { data, loading, error, createSignatoryWhitelistDomains } = useEsignProjectWhitelistDomains();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(data == null) return;
        onAddDomain(data);
    }, [data]);

    useEffect(()=>{
        if(error == null) return;
        enqueueSnackbar(error.error, {
            variant: "error",
            autoHideDuration: 2000
        });
    }, [error])

    const handleOnClickAdd = (projectId, businessId, domain) => {
        var domain_to_create = [];
        domain_to_create.push({name: domain});
        createSignatoryWhitelistDomains(projectId, businessId, domain_to_create)
    }

    const handleOnClickCancel = () => {
        setDomain("");
        onClickCancel();
    }

    const handleTextChange = (e) => {
        setDomain(e.target.value);
    }

    return(
        <Dialog 
            open={open} 
            onClose={null}  
            maxWidth={'sm'} 
            fullWidth={'sm'} 
            PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}
            >
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Add Signatory Domain
            </DialogTitle>
            <Divider />
            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Grid item xs={12} sx={{alignSelf: "center"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  sx={{alignSelf: "center", fontWeight:'bold'}}>
                            Domain
                        </Grid>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                value={domain}
                                onChange={(e) => handleTextChange(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10 }} onClick={handleOnClickCancel}>
                    Cancel
                </Button>
                <Button 
                    disabled={domain == ""}
                    variant="contained" 
                    sx={{ width: 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleOnClickAdd(projectId, businessId, domain)}
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}>
                        { loading ? "Adding" : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function DeleteDomainDialog({open, onClickCancel, domain, onDeleteDomain}){

    const { data, loading, error, deleteSignatoryWhitelistDomain } = useEsignProjectWhitelistDomains();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(data == null) return;
        onDeleteDomain(data);
    }, [data]);

    useEffect(()=>{
        if(error == null) return;
        enqueueSnackbar(error.error, {
            variant: "error",
            autoHideDuration: 2000
        });
    }, [error])

    const handleOnClickDelete = (idDomain) => {
        deleteSignatoryWhitelistDomain(idDomain)
    }

    return(
        <Dialog 
            open={open} 
            onClose={null}  
            maxWidth={'sm'} 
            fullWidth={'sm'} 
            PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}
            >
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Delete Signatory Domain
            </DialogTitle>
            <Divider />
            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete permanently <strong>{domain?.domain}</strong>?
                </Typography>
            </DialogContent>
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={onClickCancel}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={()=>handleOnClickDelete(domain.id)} 
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}>
                    {loading ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default SignatoryDomainComponent;