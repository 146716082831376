import React from "react";
import {routes} from "./routes";
import {Routes, Route} from "react-router-dom";
import {PrivateRoute} from "./private-routes";

const Router = function(){

    return (
        <Routes>
            {routes
                .filter((route) => !route.isPrivate)
                .map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                    />
                ))
            }

            {routes
                .filter((route) => route.isPrivate)
                .map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <PrivateRoute>
                                {route.element}
                            </PrivateRoute>
                        }
                    />
                ))
            }
        </Routes>
    );
    
}

export default Router