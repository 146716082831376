import {useState} from "react";
import TemplateService from "../services/api/templates";
import { useSnackbar } from "notistack";

const useTemplates = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isFetchingCountTemplate, setFetchingCountTemplate] = useState(false);
    const [countTemplateData, setCountTemplateData] = useState(null);
    const [isFetchingEnterpiseAccountTemplate, setFetchingEnterpiseAccountTemplate] = useState(false);
    const [enterpriseAccountTemplates, setEnterpriseAccountTemplates] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [updatingTemplateName, setUpdatingTemplateName] = useState(false);
    const [updatingTemplateStatus, setUpdatingTemplateStatus] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [restored, setRestored] = useState(false);
    const [restoring, setRestoring] = useState(false);
    const [creating, setCreating] = useState(false);
    const [created, setCreated] = useState(false);
    const [template, setTemplate] = useState(null);
    const [template_code, setTemplateCode] = useState('');
    const [fetchingAccountTemplate, setFetchingAccountTemplate] = useState(false);
    const [error, setError] = useState('');
    const [isLoadingCreateSignature, setIsLoadingCreateSignature] = useState(false);
    const [createSignatureData, setCreateSignatureData] = useState(null);


    var limit = 10;


    const createAccountTemplate = (formData) => {

        setCreating(true);

        TemplateService.createAccountTemplate(formData).then(res => {

            setCreating(false);

            if(res.data.success){
                setCreated(true);
                enqueueSnackbar(res.statusText, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setTemplateCode(res.data.data.template_code);
            }
        }).catch((error) => {
            setCreating(false);

            if (error.response.data == "") {
                enqueueSnackbar("No project selected, please select project", { 
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
           
            } else {
                if(error.response.statusText === 'Forbidden') {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.statusText, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }
        })

    }



    const getCountAccountTemplates = (businessId, projectId) => {
        setFetchingCountTemplate(true);

        TemplateService.getCountAccountTemplates(businessId, projectId).then((res) => {
            console.log("getCountAccountTemplates data xxxx", res);

            if (res.data.success) {
                setFetchingCountTemplate(false);
                setCountTemplateData(res.data.data);
            }

        }).catch((error) => {
            console.log("getCountAccountTemplates error xxxx", error);

            setFetchingCountTemplate(false);
        })
    }


    const getEnterpriseAccountTemplates = (businessId, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.getEnterpriseAccountTemplates(businessId, page, limit).then((res) => {
            console.log("getEnterpriseAccountTemplates data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.data?.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("getEnterpriseAccountTemplates error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === 'Forbidden.') {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
               
            }
        })
    }


    const getDeletedEnterpriseAccountTemplates = (businessId, deleted, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.getDeletedEnterpriseAccountTemplates(businessId, deleted, page, limit).then((res) => {
            console.log("getDeletedEnterpriseAccountTemplates data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("getDeletedEnterpriseAccountTemplates error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === 'Forbidden.') {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }
        })
    }


    const getDeletedEnterpriseProjectAccountTemplates = (projectId, deleted, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.getDeletedEnterpriseProjectAccountTemplates(projectId, deleted, page, limit).then((res) => {
            console.log("getDeletedEnterpriseProjectAccountTemplates data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("getDeletedEnterpriseProjectAccountTemplates error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const getEnterpriseProjectAccountTemplates = (projectId, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.getEnterpriseProjectAccountTemplates(projectId, page, limit).then((res) => {
            console.log("getEnterpriseProjectAccountTemplates data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("getEnterpriseProjectAccountTemplates error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const filterEnterpriseTemplate = (searchFor, searchVal, businessId, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.filterEnterpriseTemplate(searchFor, searchVal, businessId, page, limit).then((res) => {
            console.log("filterEnterpriseTemplate data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("filterEnterpriseTemplate error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const filterEnterpriseDeletedTemplate = (searchFor, searchVal, businessId, deleted, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.filterEnterpriseDeletedTemplate(searchFor, searchVal, businessId, deleted, page, limit).then((res) => {
            console.log("filterEnterpriseDeletedTemplate data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("filterEnterpriseDeletedTemplate error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const filterEnterpriseProjectTemplate = (searchFor, searchVal, projectId, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.filterEnterpriseProjectTemplate(searchFor, searchVal, projectId, page, limit).then((res) => {
            console.log("filterEnterpriseProjectTemplate data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("filterEnterpriseProjectTemplate error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const filterEnterpriseProjectDeletedTemplate = (searchFor, searchVal, projectId, deleted, page) => {
        setFetchingEnterpiseAccountTemplate(true);

        TemplateService.filterEnterpriseProjectDeletedTemplate(searchFor, searchVal, projectId, deleted, page, limit).then((res) => {
            console.log("filterEnterpriseProjectDeletedTemplate data xxxx", res);

            if (res.data.success) {
                setFetchingEnterpiseAccountTemplate(false);
                setEnterpriseAccountTemplates(res.data.data);
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error) => {
            console.log("filterEnterpriseProjectDeletedTemplate error xxxx", error);

            setFetchingEnterpiseAccountTemplate(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const updateTemplateName = (formData) => {

        setUpdatingTemplateName(true);
        setIsUpdated(false);

        TemplateService.updateTemplateName(formData).then(res => {
            console.log("updateTemplateName res here:", res)

            setUpdatingTemplateName(false);
            if (res.data.success) {
                enqueueSnackbar(res.data.data, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setIsUpdated(true);
            }

        }).catch((err) => {
            console.log("updateTemplateName error :", err)
            setUpdatingTemplateName(false);

            if (err.response.data.error_message) {
                enqueueSnackbar(err.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (err.response.data.error) {
                enqueueSnackbar(err.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(err.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }


        })
    }

    const deleteAccountTemplate = (payload) => {

        setDeleting(true);
        setDeleted(false);

        TemplateService.deleteAccountTemplate(payload).then((res) => {

            console.log("deleteAccountTemplate data xxxx", res.data);

            setDeleting(false);

            if (res.data.success) {
                setDeleted(true);
                enqueueSnackbar(res.data.data, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((err) => {

            console.log("deleteAccountTemplate error xxxx", err);
            setDeleting(false);
            setDeleted(false)

            if (err.response.data.error_message) {
                enqueueSnackbar(err.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (err.response.data.error) {
                enqueueSnackbar(err.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(err.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }


    const updateAccountTemplateStatus = async (formData) => {
        setUpdatingTemplateStatus(true);
        setIsUpdated(false);

        try {
            const res = await TemplateService.updateTemplateStatus(formData);
            console.log("updateTemplateStatus data xxxxx", res);

            setUpdatingTemplateStatus(false);

            if (res.data.success) {
                enqueueSnackbar("Successfully updated account template status", {
                    variant: "success",
                    autoHideDuration: 3000
                });

                setIsUpdated(true);
            }

        } catch (error) {
            setUpdatingTemplateStatus(false);
            setIsUpdated(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
                
            } else {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        }
    }


    const deleteTemplatePermanently = (template_code) => {

        setDeleting(true);
        setDeleted(false);

        TemplateService.deleteTemplatePermanently(template_code).then((res) => {

            setDeleting(false);

            if (res.data.success) {
                setDeleted(true);
                enqueueSnackbar(res.data.data, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                
            }

        }).catch((error) => {

            console.log("deleteAccountTemplate error xxxx", error);
            setDeleting(false);
            setDeleted(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

           

        })
    }


    const restoreDeletedTemplate = (formData, handleClose) => {

        setRestoring(true);

        TemplateService.restoreDeletedTemplate(formData).then(res => {
            setRestoring(false);

            if (res.data.success) {
                setRestored(true);
                enqueueSnackbar(res.data.data, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();

                // if (url == "/template-list") {
                //     handleClose();
                // }
            }

        }).catch((error) => {
            setRestoring(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    };

    const viewAccountTemplate = (template_code) => {
        setFetchingAccountTemplate(true);
        TemplateService.viewAccountTemplate(template_code).then(res=>{
            console.log("view acount template here:", res)
            setFetchingAccountTemplate(false);

            if(res.data.success){
                setTemplate(res.data.data)
            }
        }).catch((err) => {
            setFetchingAccountTemplate(false);
            setError(err.response?.data?.error)
            if (err.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (err.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
        })
    }

    
    const createSignatureTemplate = (signatureFile, email, projectId) => {
        setIsLoadingCreateSignature(true);
        TemplateService.createSignature(signatureFile, email, projectId).then((res) => {
            setIsLoadingCreateSignature(false);
            console.log("res create sig aa", res.data);
            if (res.data.success) {
                setCreateSignatureData(res.data.data)
                enqueueSnackbar("Created Signature Successfully", {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
        }).catch((error) => {
            console.log("create sig error xxxx", error);
            setIsLoadingCreateSignature(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    return {isFetchingCountTemplate, countTemplateData, getCountAccountTemplates, getEnterpriseAccountTemplates, isFetchingEnterpiseAccountTemplate,
        enterpriseAccountTemplates, pagination, getEnterpriseProjectAccountTemplates, filterEnterpriseTemplate, filterEnterpriseProjectTemplate,
        updateTemplateName, updatingTemplateName, isUpdated, deleteAccountTemplate, deleted, deleting, setDeleted, updateAccountTemplateStatus, updatingTemplateStatus,
        getDeletedEnterpriseAccountTemplates, deleteTemplatePermanently, restoreDeletedTemplate, restored, restoring, getDeletedEnterpriseProjectAccountTemplates,
        filterEnterpriseDeletedTemplate, filterEnterpriseProjectDeletedTemplate, createAccountTemplate, creating, created, viewAccountTemplate, template_code, template,
        fetchingAccountTemplate, error, createSignatureTemplate, isLoadingCreateSignature, createSignatureData

    }
}

export default useTemplates