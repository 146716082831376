import { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, InputAdornment } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import BusinessAppConfigService from "../../services/api/business-app-config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CreateSecretConfigDialog from "./dialog/create-secret-config-dialog.component";
import ConfigPopperComponent from "./config-actions.component";
import { useSnackbar } from "notistack";
import ConfigListSkeletonComponent from "../skeletons/config-list-skeleton.component";
import ReactSelect from "react-select";
import ForbiddenScreen from '../../screens/ForbiddenScreen';

function TableHeader() {

    const tablecell = [
        { label: 'ID' },
        { label: 'Config Key' },
        { label: 'Config Value' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
                <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }} align="center">Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}

function TableContent(props) {
    const { list, getListConfig, page } = props;

    const [showApiKey, setApiKey] = useState(false);

    const handleClickShow = (e) => { setApiKey(!showApiKey) }

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" sx={{ color: '#0178f2' }} >
                {list?.id}
            </TableCell>
            <TableCell >
                {list?.config_key}
            </TableCell>
            <TableCell align="left">
                <TextField
                    fullWidth
                    variant="outlined"
                    disabled
                    autoComplete="off"
                    value={list?.config_value}
                    placeholder="Config Value"
                    type={(showApiKey) ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={(e) => handleClickShow(e)} aria-label="toggle password visibility" edge="end">
                                    {(showApiKey) ?
                                        <Visibility fontSize="small" />
                                        :
                                        <VisibilityOff fontSize="small" />
                                    }
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </TableCell>
            <TableCell align="center">
                <ConfigPopperComponent list={list} getListConfig={getListConfig} age={page} />
            </TableCell>
        </TableRow>
    )
}

function SecretsListComponent() {

    const breakpoints = useWidth()
    const { enqueueSnackbar } = useSnackbar();

    const [pagination, setPagination] = useState(1);
    const [page, setPage] = useState(1);

    const [configList, setConfigList] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false);

    const [isSearchingSecretConfig, setSearchingSecretConfig] = useState(false);
    const [searchedSecretConfig, setSearchedSecretConfig] = useState([]);
    const [isSearchSecretConfigToggle, setSearchSecretConfigToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState("config_key");
    const [configKeyOptions, setConfigKeyOptions] = useState([]);
    const [configKeyFilterValue, setConfigKeyFilterValue] = useState(null);
    const [configKeyFilterLabel, setConfigKeyFilterLabel] = useState(null);
    const [error_error, setErrorError] = useState(false);

    var limit = 10

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
        getListConfig(page);
    }

    const getListConfig = (page) => {
        setLoading(true);
        setSearchSecretConfigToggle(false);

        BusinessAppConfigService.configList(page, limit).then((res) => {
            setConfigList(res.data.data)
            setLoading(false)
            console.log('get config list res herexxx', res)
        }).catch((error) => {
            console.log("get config list error xxxx", error);
            setLoading(false);
            setErrorError(error.response.statusText)
            setPagination(0)
        })
    }



    useEffect(() => {
        getListConfig(page)
    }, [])

    const searchSecretConfig = (filter, value, page) => {
        setSearchingSecretConfig(true);
        setSearchSecretConfigToggle(true);

        const search_secrets_limit = 10;

        BusinessAppConfigService.searchSecretConfig(filter, value, page, search_secrets_limit).then((res) => {
            console.log("searchSecretConfig data xxxx", res);

            if (res.data.success) {
                setSearchingSecretConfig(false);
                setSearchedSecretConfig(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_secrets_limit);
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            }

        }).catch((error) => {
            console.log("searchSecretConfig error xxxx", error);

            setPagination(0);
            setSearchSecretConfigToggle(false);
            setSearchedResultsEmpty(false);
        })
    }

    useEffect(() => {
        getConfigKeyOptions();
    }, [JSON.stringify(configList)])

    useEffect(() => {
        if (filter && configKeyFilterValue) {
            searchSecretConfig(filter, configKeyFilterValue, page);
        }
    }, [configKeyFilterValue])

    const getConfigKeyOptions = () => {
        if (configList) {
            const config_key_options = [...configList?.map((config) => ({
                value: config?.config_key,
                label: config?.config_key,
            }))];

            setConfigKeyOptions(config_key_options);
        }
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            minHeight: "20px",
            width: 270,
            fontSize: 13,
        })
    }

    const filterOptions = [
        { value: "config_key", label: "Config Key" },
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setConfigKeyFilterValue(null);
        setConfigKeyFilterLabel(null);

        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {
            setFilter(null);
            setFilterLabel(null);

            handleClearFilter();
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        getListConfig(page);
    }

    const handleSelectConfigKeyValue = (selectedOption) => {
        if (selectedOption) {
            setConfigKeyFilterValue(selectedOption.value);
            setConfigKeyFilterLabel(selectedOption.label);
        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && configKeyFilterValue) {
            searchSecretConfig(filter, configKeyFilterValue, page);
        }
    }


    if (!loading && configList?.length == 0) return (
        <Box sx={{ padding: 2, width: '100%', borderRadius: 0, overflow: 'hidden' }}>
            <Grid container sx={{ mt: "20px" }}>
                <Grid item xs={12} sm={6} sx={{ textAlign: "end", ml: 'auto' }}>
                    <Button onClick={handleOpenDialog} variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>Create Confi</Button>
                </Grid>
            </Grid>
            <Box sx={{ textAlign: 'center', margin: 'auto', mt: 5 }}>
                <Typography>No available list to display.</Typography>
            </Box>
        </Box>
    )

    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            <Grid container>
                <Grid item xs={4} sm={4}>
                    <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                        Manage Configuration Keys for environment variables
                    </Typography>
                </Grid>
            </Grid>

            {error_error ?
                <ForbiddenScreen />
            :
                <Grid container sx={{mt: 3}}>
                    <Grid item xs={8} sm={8} sx={{ mb: 1 }}>
                        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                            <ReactSelect
                                className="basic-single"
                                placeholder={<>
                                    <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        Filter
                                    </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                        {
                                            (filterLabel === "Config Key") ?
                                                <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                    Config Key
                                                </Typography>
                                                :
                                                "Select Filter"

                                        }</i>
                                </>}
                                isSearchable={true}
                                isRtl={false}
                                options={filterOptions}
                                onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 250,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                                isClearable={true}
                            />

                            {(filterLabel === "Config Key") &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "Config Key" && configKeyFilterLabel) ?
                                            <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                {configKeyFilterLabel}
                                            </Typography>
                                            :
                                            "Search Config Key"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={configKeyOptions}
                                    value={configKeyFilterValue}
                                    onChange={selectedOption => handleSelectConfigKeyValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={4} sm={4} sx={{ textAlign: "end", mb: 2, marginLeft: -2 }}>
                        <Button onClick={handleOpenDialog} variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>Create Secrets</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(loading || isSearchingSecretConfig) ?
                                    <TableBody>
                                        <ConfigListSkeletonComponent />
                                    </TableBody>

                                    :
                                    (isSearchSecretConfigToggle && !isSearchedResultsEmpty) ?
                                        <TableBody sx={{ width: "100%" }}>
                                            {searchedSecretConfig?.data.map((data, index) => (
                                                <TableContent
                                                    list={data}
                                                    getListConfig={getListConfig}
                                                    page={page}
                                                />
                                            ))}
                                        </TableBody>
                                        :
                                        (isSearchSecretConfigToggle && isSearchedResultsEmpty) ?
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" sx={{ fontSize: 14, textAlign: "center", fontWeight: 500 }}>
                                                        No searched result(s) found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            (!isSearchSecretConfigToggle) &&
                                            <TableBody>
                                                {(configList && configList)?.map((list, index) => (
                                                    <TableContent
                                                        list={list}
                                                        getListConfig={getListConfig}
                                                        page={page}
                                                    />
                                                ))}
                                            </TableBody>
                                }
                            </Table>

                            {!loading && configList && configList?.length > 0 && !isSearchingSecretConfig && !isSearchSecretConfigToggle &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePagination}
                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                    disabled={configList?.length === 0}
                                // hideNextButton={configList?.length < 10}
                                />
                            }

                            {/* pagination for secret config */}
                            {(isSearchSecretConfigToggle && !isSearchedResultsEmpty && !loading) &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePaginationForSearch}
                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                />
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
            }

            {/* CREATE SECRETS MODAL */}
            <CreateSecretConfigDialog openDialog={openDialog} handleClose={handleClose} breakpoints={breakpoints} getListConfig={getListConfig} page={page} />
        </Box>
    )
}

export default SecretsListComponent