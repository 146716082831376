import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch, HttpPut1, HttpPut} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getUsers = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("query_paramsxxxxxx", query_params)
    
    let result = HttpGet(routes.GET_USERS, query_params, subscriptionKey);
    
    return result;
}

const addUser = (businessID, data) => {

    let payload = {
        data : data
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_USER + "?businessID=" + businessID , payload , subscriptionKey);

    return result;
    
}


const createUser = (businessID, data) => {

    let payload = {
        data : data
    }

    console.log("createUser params xxxx",  payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_USER + "?businessID=" + businessID , payload , subscriptionKey);

    return result;
    
}

const regenerateSubscriptionKey = (id) => {

    console.log("id here : " , id)

    let query_params = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.REGENERATE_SUB_KEY, query_params,  subscriptionKey);
    
    return result;
}

const getUserByBusinessAccountID = (businessId,page,limit, type) => {

    let parameters = {
        businessId: businessId,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS_BY_BUSINESS, parameters, subscriptionKey);
    
    return result;
}


const updateUserStatus = (id, payload) => {

    let result = HttpPatch(routes.UPDATE_USER_STATUS  + "?id=" + id, payload, SUBSCRIPTION_KEY);
    
    return result;

}

const updatePrincipalName = (id, payload) => {


    console.log("updateUserStatus api payload xxxx", payload);

    let result = HttpPatch(routes.UPDATE_USER_PRINCIPAL_NAME  + "?id=" + id, payload, SUBSCRIPTION_KEY);
    
    return result;

}

const getAdmin = () => {

    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN, query_params, subscriptionKey);
    
    return result;
}

const searchPrincipalName = (principal_name, page, limit) => {

    let parameters = {
        principal_name: principal_name,
        page: page,
        limit: limit
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_PRINCIPAL_NAME, parameters, subscriptionKey);
    
    return result;
}

const getUserByID = (id) => {

    let query_params = {
        id: id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USER_BY_ID, query_params, subscriptionKey);
    
    return result;
}


const updateUserRole = (id, role, projectId) => {

    let query_params = {
        id: id,
        role: role,
        projectId: projectId
    };

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPut1(routes.UPDATE_USER_ROLE, query_params, payload, subscriptionKey);
    
    return result;
}

const filterUserByStatus = (business_id, status, page, limit, type) => {

    let parameters = {
        businessId: business_id,
        status: status,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const filterUserByRole = (business_id, role, page, limit, type) => {

    let parameters = {
        businessId: business_id, 
        role: role,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}


const filterUserByStatusAndRole = (businessId, status, role, page, limit, type) => {

    let parameters = {
        businessId, businessId,
        status: status,
        role: role,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const getUsersFilterByStatusAndRole = (status, role, page, limit, type) => {

    let parameters = {
        status: status,
        role: role,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}



const getUsersFilterByEmail = (email, page, limit, type) => {

    let parameters = {
        email: email,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const getUsersUnderEnterpriseProject = (businessId, projectId, page, limit, type, email) => {
    let parameters = {
        businessId: businessId,
        projectId: projectId,
        page: page,
        limit: limit,
        type: type,
        email
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const getUsersEnterprise = (businessId, projectId) => {
    let parameters = {
        businessId: businessId,
        projectId: projectId,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ALL_USERS_BY_BUSINESS, parameters, subscriptionKey);

    return result;
}

const filterProjectUserByStatus = (businessId, projectId, status, page, limit, type) => {
    let parameters = {
        businessId: businessId,
        projectId: projectId,
        status: status,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const filterUsersFilterByEmail = (businessId, projectId, email, page, limit, type) => {

    let parameters = {
        businessId: businessId,
        projectId: projectId,
        email: email,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const filterByEmail = (email,businessId,  page, limit, type) => {

    let parameters = {
        email: email,
        businessId: businessId,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const filterProjectUserByRole = (businessId, projectId, role, page, limit, type) => {
    let parameters = {
        businessId: businessId,
        projectId: projectId,
        role: role,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;
}

const getUsersFilterByStatus = (status, page, limit, type) => {

    let parameters = {
        status: status,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}

const filterProjectUserByStatusAndRole = (businessId, projectId, status, role, page, limit, type) => {

    let parameters = {
        businessId: businessId,
        projectId: projectId,
        role: role,
        status: status,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}

const getUsersFilterByRole = (role, page, limit, type) => {

    let parameters = {
        role: role,
        page: page,
        limit: limit,
        type: type
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}

const getUsersFilterByEnterprise = (businessId, page, limit, type) => {

    let parameters = {
        businessId: businessId,
        page: page,
        limit: limit,
        type: type
    };

    console.log("getUsersFilterByEnterprise query paramsxxxx", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}
const getEnterpriseUserFilter = (businessId, projectId, role, status, page, type, limit) => {

    let parameters = {
        businessId: businessId,
        projectId: projectId,
        role: role,
        status: status,
        page: page,
        type: type,
        limit: limit,
        
    };

    console.log("getUsersFilterByEnterpriseAndProject query paramsxxxx", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}

const getUsersFilterByEnterpriseAndProject = (businessId, projectId, page, limit, type) => {

    let parameters = {
        businessId: businessId,
        projectId: projectId,
        page: page,
        limit: limit,
        type: type
    };

    console.log("getUsersFilterByEnterpriseAndProject query paramsxxxx", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_USERS, parameters, subscriptionKey);

    return result;

}


const getAllAdminUsers = (limit) => {

    let query_params = {
        limit: limit
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_ADMIN_USERS, query_params, subscriptionKey);
    
    return result;
}

const getAdminUsers = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    }
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("query_paramsxxxxxx", query_params)
    
    let result = HttpGet(routes.GET_ADMIN_USERS, query_params, subscriptionKey);
    
    return result;
}

const getAdminRoles = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_ROLES, query_params , subscriptionKey);

    return result;
    
}


const getAllAdminRoles = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_ROLES, query_params , subscriptionKey);

    return result;
    
}

const filterAdminUserByStatus = (status, page, limit) => {

    let parameters = {
        status: status,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_USERS, parameters, subscriptionKey);

    return result;
}

const filterAdminUserByRole = (role, page, limit) => {

    let parameters = {
        role: role,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_USERS, parameters, subscriptionKey);

    return result;
}

const viewAdminUser = (id) => {

    let query_params = {
        oid: id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.VIEW_ADMIN_USER, query_params, subscriptionKey);
    
    return result;
}

const updateAdminPrincipalName = (id, payload) => {

    let result = HttpPut(routes.UPDATE_ADMIN_USER_EMAIL  + "?id=" + id, payload, SUBSCRIPTION_KEY);
    
    return result;

}

const updateAdminUserStatus = (id, payload) => {

    let result = HttpPut(routes.UPDATE_ADMIN_USER_STATUS  + "?id=" + id, payload, SUBSCRIPTION_KEY);
    
    return result;

}

const addAdminUser = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_ADMIN_USER , payload , subscriptionKey);

    return result;
    
}


const updateAdminUserRole = (id, role) => {

    let query_params = {
        id: id,
        role: role
    };

    let payload = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPut1(routes.UPDATE_ADMIN_USER_ROLE, query_params, payload, subscriptionKey);
    
    return result;
}


const UserService = {
    getUsers,
    addUser ,
    regenerateSubscriptionKey ,
    getUserByBusinessAccountID,
    updateUserStatus,
    updatePrincipalName,
    getAdmin,
    searchPrincipalName,
    getUserByID,
    updateUserRole,
    filterUserByStatus,
    filterUserByRole,
    getUsersUnderEnterpriseProject,
    filterProjectUserByStatus,
    filterProjectUserByRole,
    getUsersFilterByStatus,
    getUsersFilterByRole,
    getAdminUsers,
    getAllAdminUsers,
    getAdminRoles,
    getAllAdminRoles,
    filterAdminUserByStatus,
    filterAdminUserByRole,
    viewAdminUser,
    updateAdminPrincipalName,
    updateAdminUserStatus,
    addAdminUser,
    updateAdminUserRole,
    createUser,
    getUsersFilterByStatusAndRole,
    filterUserByStatusAndRole,
    filterProjectUserByStatusAndRole,
    getUsersFilterByEmail,
    filterUsersFilterByEmail,
    filterByEmail,
    getUsersEnterprise,
    getUsersFilterByEnterprise,
    getUsersFilterByEnterpriseAndProject,
    getEnterpriseUserFilter
}

export default UserService