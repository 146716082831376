import {useEffect, useState} from "react";
import {Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import { ThreeDots } from 'react-loader-spinner';
import {convertFileSizeToBytes} from "../../../utils/fileSizeToBytesUtil";
import useProjects from "../../../hooks/useProjects";

const UpdateProjectLogoDialogComponent = (props) => {

    const {project, openUpdateImage, handleCloseDialog, logo_image, fileSizes, file, validateLogoType, setIsLogoisUpdated, setUpdatedLogo, setAnchorEl} = props;

    const {enqueueSnackbar} = useSnackbar();

    const { updatingProjectLogo, uploadProjectLogo, updatedLogo, logoUpdated } = useProjects();


    const [exactFileSize, setExactFileSize] = useState(0);
    const [loading, setLoading] = useState(false);

    let project_id = project?.id ? project?.id : project;

    useEffect(() => {
        if (fileSizes) {
            convertFileSizeToBytes(fileSizes);
            setExactFileSize(convertFileSizeToBytes(fileSizes));
        }
    }, [fileSizes])

    const uploadLogo = (e) => {

        if (exactFileSize < 100000 || exactFileSize == 100000) {

            let formData = new FormData();

            formData.append("logo", file);
            formData.append("project_id", project_id);

            setLoading(true);

            uploadProjectLogo(formData);

        } else {
            enqueueSnackbar("File size exceeds maximum file size limit, please choose another file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }
    
    useEffect(() => {
        if (logoUpdated) {
          handleCloseDialog();
          setIsLogoisUpdated(true); 
          setUpdatedLogo(updatedLogo);
          setAnchorEl(null);
        }
      }, [logoUpdated]);


    return (
        <Dialog open={openUpdateImage} onClose={handleCloseDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Update Logo
            </DialogTitle>

            <Divider />

            <DialogContent sx={{textAlign: 'center'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{mb: "12px"}}>
                        <img alt="logo-img" src={logo_image} style={{height: 200, borderRadius: 5, width: '100%', objectFit: 'contain'}} />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography sx={{fontSize: 10, textAlign: "start", color: "#000"}}>
                            Maximum upload file size: 100 KB
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography sx={{fontSize: 10, textAlign: "end", color: "#000"}}>
                            File size: {fileSizes}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: "5px"}}>
                        <Button sx={{textTransform: 'none', width: '100%', borderRadius: 1}} variant="outlined">
                            <input 
                                type="file" 
                                accept="image/*" 
                                id="upload-logo"
                                onChange= {(e) => validateLogoType(e) }
                                hidden
                            />

                            <label style={{width:'100%'}} htmlFor="upload-logo" >
                                Change Logo
                            </label>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{padding: "16px 24px 16px 0px"}}>
                <Button onClick={handleCloseDialog} sx={{textTransform:'none', fontSize: 14}}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: 'none', borderRadius: 1, fontSize: 14}}
                onClick={(e) => uploadLogo(e)} 
                startIcon={ updatingProjectLogo ? <ThreeDots  height="20"  width="20"   radius="9" color="#ffff"  ariaLabel="three-dots-loading"  visible={true} /> : null } >
                    {updatingProjectLogo ? 'Uploading' : 'Upload'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UpdateProjectLogoDialogComponent