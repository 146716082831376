import { Box, Button, Card, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import UserListComponent from "../components/users/user-list.component";
import useWidth from "../hooks/useWidth";
import useUsers from "../hooks/useUsers";
import useRolesManagement from "../hooks/useRolesManagement";
import CachedIcon from '@mui/icons-material/Cached';
import UserRowComponent from "../components/users/user-row.component";
import UserListSkeletonComponent from "../components/skeletons/user-list-skeleton.component";
import AddUserDialog from "../components/account/dialogs/add-user.dialog";
import CreateUserDialog from "../components/account/dialogs/create_user.dialog";
import ReactSelect from "react-select";
import useBusiness from "../hooks/useBusiness";
import useProjects from "../hooks/useProjects";
import { filter } from "lodash";
import ForbiddenScreen from "./ForbiddenScreen";
import AddBoxIcon from '@mui/icons-material/AddBox';




const statusOption = [
    { value: 'true', label: "Active" },
    { value: 'false', label: "Inactive" }
]

function TableHeader() {
    const tablecell = [
        { label: 'ID' },
        { label: 'Email' },
        { label: 'Role' },
        { label: 'Enterprise Name' },
        { label: 'Project Name' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Actions' }
    ];

    return (
        <TableHead>
            <TableRow sx={{ position: "sticky" }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{
                            fontWeight: 700, backgroundColor: "#0178f2", color: "#fff",
                            textAlign: (value.label == "Signature") ? "center" : {}
                        }}
                        >
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const UserListScreen = () => {

    const breakpoints = useWidth();

    const { loading, pagination, users, setLoading, isCreatingUser, paginationEmail, created, user_password, createUser, getUsersFilterByEnterprise, error,
        getUsers, searchPrincipalNames, isSearchPrincipalName, searchPagination, getUsersFilterByStatus, getUsersFilterByRole, getUsersFilterByStatusAndRole,
        getUsersFilterByEmail, getUsersFilterByEnterpriseAndProject } = useUsers();
    const { rolesList, getAllRoles } = useRolesManagement();
    const { fetchingBusinessDetails, businessDetails, getBusinessById, getAllBusinessList, businessList } = useBusiness();
    const { getAllProjectList, projects, getProjectList, projectsFilter, allProjects } = useProjects();


    const [filteredResults, setFilteredResults] = useState([]);
    const [page, setPage] = useState(1);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_role, setFilterRole] = useState("");
    const [filter_enterprise, setFilterEnterprise] = useState("");
    const [filter_project, setFilterProject] = useState("");
    const [filter_allproject, setFilterAllProject] = useState("");
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const [searchInput, setSearchInput] = useState('');
    const [isSelectedLabel, setIsSelectedLabel] = useState(null);
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [updated_users, setUpdatedUsers] = useState([]);
    const [updated_principal_name, setUpdatedPrincipalName] = useState([]);
    const [searchInputEnterprise, setSearchInputEnterprise] = useState('');
    const [business_account_id, setBusinessAccountId] = useState('');
    const [project_name, setProjectName] = useState('');
    const [projectOptions, setProjectOptions] = useState([]);

    const searchForOptions = [
        { value: "status", label: "Status" },
        { value: "role", label: "Role" },
        { value: "status-role", label: "Status and Role" },
        { value: "enterprise", label: "Enterprise Name" },
        { value: "project", label: "Project" },
    ]

    // console.log("filter_enterprisexxx", filter_enterprise)
    // console.log("filter_projectxxx", filter_project)
    // console.log("all projects here", allProjects)

    const handleClearFilter = (e) => {
        setSearchStatusVal(null);
        setSearchFor(null);
        setSearchForLabel(null);
        setFilterProject(null);
        setProjectName(null);
        setFilterEnterprise(null);
        setFilterRole(null);
        setFilterStatus(null);
        getUsers(page);
    }

    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessFilterValue, setBusinessFilterValue] = useState(null);

    useEffect(() => {
        let business_options = [];

        businessList?.map((business) => {
            let business_options_data = {
                value: business?.id,
                label: business?.business_name
            }

            business_options.push(business_options_data);
        })

        setBusinessOptions(business_options);
    }, [JSON.stringify(businessList)])


    useEffect(() => {
        let project_options = [];

        allProjects?.map((project) => {
            let project_options_data = {
                value: project?.id,
                label: project?.application_name,
                business_id: project?.business_account_id
            }

            project_options.push(project_options_data);
        })

        setProjectOptions(project_options);
    }, [JSON.stringify(allProjects)])

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            console.log("selectedOption here", selectedOption)
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
            if(!selectedOption.label === "Enterprise Name"){
                setFilterEnterprise("");
            }
            getUsers(page);

        } else {
            setSearchFor(null);
            setSearchForLabel(null);
            setSearchStatusVal(null);
            setFilterProject(null);

            handleClearFilter();
        }
    }

    useEffect(() => { getAllRoles(); }, [])


    useEffect(() => {
        getUsers(page);
        getAllBusinessList(page);
        getAllProjectList(page);
    }, [page]);

    useEffect(() => {
        if (filter_enterprise) {
            getProjectList(filter_enterprise);
        }
    }, [filter_enterprise])

    useEffect(() => {
        if (users?.length > 0 && businessList && allProjects) {

            // Ensure businessDetails is in array format
            const businessDetailsArray = Array.isArray(businessList) ? businessList : [businessList];
            const projectsArray = Array.isArray(allProjects) ? allProjects : [allProjects];

            // console.log("businessDetailsArrayxxx", businessDetailsArray)
            // console.log("projectsArrayxxx", projectsArray)

            // Map business account IDs to their corresponding names for faster lookup
            const businessNameMap = {};
            businessDetailsArray.forEach(detail => {
                businessNameMap[detail?.id] = detail?.business_name;
            });

            const projectNameMap = {};
            projectsArray.forEach(detail => {
                projectNameMap[detail?.id] = detail?.application_name;
            });

            // console.log("businessNameMapxxx", businessNameMap)
            // console.log("projectNameMapxxxxx", projectNameMap)
            // Match user data with business details
            const updatedUsers = users.map(user => {
                // Assuming each user has a business_account_id property
                const businessName = businessNameMap[user?.business_account_id] || "N/A";
                const projectName = projectNameMap[user?.project_id] || "N/A";
                return {
                    ...user,
                    business_name: businessName,
                    project_name: projectName,
                    // Add other business details you need similarly
                };
            });
            setUpdatedUsers(updatedUsers);
        } else {
            // Handle the case when users.length is 0
            setUpdatedUsers([]);
        }
    }, [users, businessList, allProjects]);


    useEffect(() => {
        if (isSearchPrincipalName?.length > 0 && businessList && allProjects) {

            // Ensure businessDetails is in array format
            const businessDetailsArray = Array.isArray(businessList) ? businessList : [businessList];
            const projectsArray = Array.isArray(allProjects) ? allProjects : [allProjects];

            // console.log("businessDetailsArrayxxx", businessDetailsArray)
            // console.log("projectsArrayxxx", projectsArray)

            // Map business account IDs to their corresponding names for faster lookup
            const businessNameMap = {};
            businessDetailsArray.forEach(detail => {
                businessNameMap[detail?.id] = detail?.business_name;
            });

            const projectNameMap = {};
            projectsArray.forEach(detail => {
                projectNameMap[detail?.id] = detail?.application_name;
            });

            // console.log("businessNameMapxxx", businessNameMap)
            // console.log("projectNameMapxxxxx", projectNameMap)
            // Match user data with business details
            const updatedUsers = isSearchPrincipalName.map(user => {
                // Assuming each user has a business_account_id property
                const businessName = businessNameMap[user?.business_account_id] || "N/A";
                const projectName = projectNameMap[user?.project_id] || "N/A";
                return {
                    ...user,
                    business_name: businessName,
                    project_name: projectName,
                    // Add other business details you need similarly
                };
            });
            setUpdatedPrincipalName(updatedUsers);
        } else {
            // Handle the case when users.length is 0
            setUpdatedPrincipalName(isSearchPrincipalName);
        }
    }, [isSearchPrincipalName, businessList, allProjects]);




    useEffect(() => {
        if (created) {
            getUsers(page);
        }
    }, [created, page])

    const handleOpenAddDialog = (e) => {
        setOpenAddDialog(true);
    }

    const handleCloseAddDialog = (e) => {
        setOpenAddDialog(false);
    }

    const searchItems = (e) => {
        setSearchInput(e.target.value);
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchInput) {
                getUsersFilterByEmail(searchInput, page);
            } else {
                getUsers(page);
            }
        }
    };

    useEffect(() => {
        if (filter_status && filter_role) {
            getUsersFilterByStatusAndRole(filter_status, filter_role, page);
        } else if (filter_enterprise && filter_project) {
            getUsersFilterByEnterpriseAndProject(filter_enterprise, filter_project, page);
        } else if (filter_allproject && business_account_id) {
            getUsersFilterByEnterpriseAndProject(business_account_id, filter_allproject, page);
        } else if (filter_status) {
            getUsersFilterByStatus(filter_status, page);
        } else if (filter_role) {
            getUsersFilterByRole(filter_role, page);
        } else if (filter_enterprise) {
            getUsersFilterByEnterprise(filter_enterprise, page);
        } else {
            getUsers(page);
        }
    }, [filter_status, filter_role, filter_enterprise, filter_project, filter_allproject, business_account_id, page]);


    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleChangeRole = (event) => {
        setFilterRole(event.target.value);
    }

    const handleChangeEnterpriseName = (selectedOption) => {
        if (selectedOption) {
            setBusinessFilterValue(selectedOption.label);
            setFilterEnterprise(selectedOption.value);
        }
    }

    const handleChangeProject = (selectedOption) => {
        if (selectedOption) {
            setFilterAllProject(selectedOption?.value);
            setBusinessAccountId(selectedOption?.business_id);
            setProjectName(selectedOption?.label);
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchInputEnterprise(event.target.value);
    };

    const filteredBusinessList = businessList?.filter(business =>
        business?.business_name?.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleChangeProjectName = (event) => {
        setFilterProject(event.target.value);
    }




    const handleChangePagination = (event, page) => {
        console.log("user page here :", page);
        setPage(page);
        getUsers(page);
        setLoading(true);
    }


    const handleStatusChangePagination = (event, page) => {
        setPage(page);
        getUsersFilterByStatus(filter_status, page);
        setLoading(true);
    }

    const handleRoleChangePagination = (event, page) => {
        setPage(page);
        getUsersFilterByRole(filter_role, page);
        setLoading(true);
    }

    const handleChangeRoleAndStatusPagination = (event, page) => {
        setPage(page);
        getUsersFilterByStatusAndRole(filter_status, filter_role, page);
        setLoading(true);
    }


    const handleChangeEmailPagination = (event, page) => {
        setPage(page);
        console.log("searchInput here :", searchInput)
        console.log("searchInput page here :", page)
        getUsersFilterByEmail(searchInput, page);
        setLoading(true);
    }

    const handleChangeEnterprisePagination = (event, page) => {
        setPage(page);
        getUsersFilterByEnterprise(filter_enterprise, page);
        setLoading(true);
    }

    const handleChangeEnterpriseProjectPagination = (event, page) => {
        setPage(page);
        getUsersFilterByEnterpriseAndProject(filter_enterprise, filter_project, page);
        setLoading(true);
    }

    const reload = () => {
        setFilterStatus('');
        setFilterRole('');
        setSearchInput('');
        setFilterEnterprise('');
        setFilterProject('');
        setProjectName(null);
        setFilterAllProject('');

        handleClearFilter();

        getUsers(page);

    }





    return (
        <Box sx={{ mb: 2 }}>
            {error === "Forbidden" ?
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} sx={{ textAlign: 'start', mb: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, marginRight: 2 }}>Users</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ForbiddenScreen />
                    </Grid>
                </Grid>
                :

                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'start', mb: 1 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600, marginRight: 2 }}>Users</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} sx={{ textAlign: 'start' }}>

                            <Box>
                                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>

                                    <ReactSelect
                                        className="basic-single"
                                        placeholder={
                                            <div>
                                                <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter:</b>&nbsp;
                                                <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                    {searchForLabel === "Status" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Status</Typography>
                                                    )}
                                                    {searchForLabel === "Role" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Role</Typography>
                                                    )}
                                                    {searchForLabel === "Status and Role" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Status and Role</Typography>
                                                    )}
                                                    {searchForLabel === "Enterprise Name" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Enterprise Name</Typography>
                                                    )}
                                                    {searchForLabel === "Project" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Project</Typography>
                                                    )}
                                                    {searchForLabel === null  && (
                                                        "Select Filter"
                                                    )}
                                                </i>
                                            </div>
                                        }
                                        isSearchable={true}
                                        isRtl={false}
                                        options={searchForOptions}
                                        onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                width: 200,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                                fontSize: 12
                                            }),
                                        }}
                                        isClearable={true}
                                    />


                                    &nbsp;

                                    {(searchForLabel === "Status" || searchForLabel === "Status and Role") &&
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={filter_status}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleStatusChange}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {statusOption?.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }} >
                                                            {status.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    }

                                    {(searchForLabel === "Role" || searchForLabel === "Status and Role") &&
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Role</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={filter_role}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleChangeRole}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {rolesList?.map((data, index) => (
                                                    <MenuItem key={data?.id} value={data?.role} sx={{ fontSize: 12 }}>
                                                        {data?.role}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }


                                    {(searchForLabel === "Enterprise Name") &&
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={
                                                (searchForLabel === "Enterprise Name" && businessFilterValue) ?
                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                        {businessFilterValue}
                                                    </Typography>
                                                    :
                                                    "Search Enterprise"
                                            }
                                            isSearchable={true}
                                            isRtl={false}
                                            options={businessOptions}
                                            value={filter_enterprise}
                                            onChange={selectedOption => handleChangeEnterpriseName(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    fontSize: 12
                                                }),
                                            }}
                                        />

                                    }

                                    &nbsp;

                                    {searchForLabel === "Enterprise Name" && filter_enterprise && 
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Project Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={filter_project}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleChangeProjectName}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {projectsFilter.length === 0 ?
                                                    <MenuItem sx={{ color: 'gray', fontSize: 12 }}>No available project</MenuItem>
                                                    :
                                                    projectsFilter.map((project) => (
                                                        <MenuItem key={project.id} value={project.id} sx={{ fontSize: 12 }}>
                                                            {project.application_name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    }

                                    &nbsp;

                                    {searchForLabel === "Project" &&
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={
                                                (searchForLabel === "Project" && project_name) ?
                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                        {project_name}
                                                    </Typography>
                                                    :
                                                    "Search Project"
                                            }
                                            isSearchable={true}
                                            isRtl={false}
                                            options={projectOptions}
                                            value={project_name}
                                            onChange={selectedOption => handleChangeProject(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    fontSize: 12
                                                }),
                                            }}
                                        />
                                    }


                                    &nbsp;&nbsp;

                                    <TextField
                                        value={searchInput}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Email..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={searchItems}
                                        onKeyDown={keyPress}
                                    />


                                    <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'end' }}>
                            <Button
                                variant="text"
                                sx={{
                                    fontSize: 14,
                                    p: '4px 5px',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#0178f2',
                                    textTransform: 'none',
                                }}
                                startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                onClick={(e) => handleOpenAddDialog(e)}
                            >
                                Add User
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : "16px 0px 24px 0px", mt: 1 }}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }} className="card-list" >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />
                                {(loading) ?
                                    <TableBody>
                                        <UserListSkeletonComponent />
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {(searchInput && updated_principal_name?.length > 0) ? (
                                            updated_principal_name.map((content, index) => (
                                                <UserRowComponent
                                                    key={index}
                                                    index={index}
                                                    user={content}
                                                    breakpoints={breakpoints}
                                                    getUsers={getUsers}
                                                />
                                            ))
                                        ) : (
                                            (updated_users?.length > 0) ? (
                                                updated_users.map((content, index) => (
                                                    <UserRowComponent
                                                        key={index}
                                                        index={index}
                                                        user={content}
                                                        breakpoints={breakpoints}
                                                        getUsers={getUsers}
                                                    />
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                                            <Grid container sx={{ padding: 3 }}>
                                                                <Box sx={{ textAlign: "center", margin: "auto" }}>
                                                                    <Typography>
                                                                        No available user(s) to display yet.
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                }
                            </Table>

                            {!loading && (
                                (updated_principal_name?.length > 0 || updated_users?.length > 0) &&
                                <>
                                    {(filter_status) ?
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleStatusChangePagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />
                                        :
                                        (filter_role) ?
                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleRoleChangePagination}
                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                            />
                                            :
                                            (filter_role && filter_status) ?
                                                <Pagination
                                                    count={pagination}
                                                    page={page}
                                                    onChange={handleChangeRoleAndStatusPagination}
                                                    style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                />
                                                :
                                                (filter_enterprise) ?
                                                    <Pagination
                                                        count={pagination}
                                                        page={page}
                                                        onChange={handleChangeEnterprisePagination}
                                                        style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                    />
                                                    :
                                                    (filter_enterprise && filter_project) ?
                                                        <Pagination
                                                            count={pagination}
                                                            page={page}
                                                            onChange={handleChangeEnterpriseProjectPagination}
                                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                        />
                                                        :

                                                        (searchInput) ?
                                                            <Pagination
                                                                count={paginationEmail}
                                                                page={page}
                                                                onChange={handleChangeEmailPagination}
                                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                            />

                                                            :
                                                            <Pagination
                                                                count={pagination}
                                                                page={page}
                                                                onChange={handleChangePagination}
                                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                            />
                                    }
                                </>

                            )}

                        </TableContainer>
                    </Grid>
                </Box>

            }


            {openAddDialog &&
                <CreateUserDialog
                    openAddDialog={openAddDialog}
                    handleCloseAddDialog={handleCloseAddDialog}
                    getUsers={getUsers}
                />
            }

        </Box>
    )
}

export default UserListScreen