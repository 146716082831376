import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from "react-loader-spinner";
import usePermissionManagement from "../../../hooks/usePermissionManagement";

const DeletePermissionDialog = (props) => {

    const { openDeletePermission, handleClose, data } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;


    const { deletePermission,  deleteAdminPermission, deletingPermission, deletedPermission } = usePermissionManagement();


    const handleDeletePermission = () => {

        let permission_id = data?.id

        if(url === "/admin-settings") {
            deleteAdminPermission(permission_id);
        } else {
            deletePermission(permission_id);
        }

    }


    useEffect(() => {

        if(deletedPermission){
            handleClose()
            window.location.reload();
        }

    },[deletedPermission])



    return (
        <Dialog open={openDeletePermission} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                Delete Permission
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: 3, paddingTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                    Are you sure you want to delete <strong>{data?.fn}</strong> permission from <strong>{data?.module?.module_name}</strong> ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{  padding: 3, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={() => handleDeletePermission() }
                    startIcon={deletingPermission &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {deletingPermission ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeletePermissionDialog