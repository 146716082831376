import { Avatar, Box, Button, Grid, MenuItem, Pagination, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../hooks/useBusiness";
import ReactSelect from 'react-select';
import useReports from "../../hooks/useReports";
import useProjects from "../../hooks/useProjects";
import ReportTwoSkeletonComponent from "../skeletons/report-two-skeleton.component";
import ReportThreeSkeletonComponent from "../skeletons/report-three-skeleton.component";
import { timeAgo } from "../../utils/dateFormatterUtil";
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import dayjs from "dayjs";


function decrypt(text, key) {
    text = atob(text);
    let result = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        result += String.fromCharCode(charCode);
    }
    return result;
}


const ReportThreeTableHeader = () => {

    const tablecell = [
        { label: 'Reference No.' },
        { label: 'Type' },
        { label: 'Pipeline' },
        { label: 'Hash' },
        { label: 'Date' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportThreeTableContent = (props) => {

    const { data, breakpoints } = props;


    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" >
                {data && data?.reference}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data && data?.type}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data && data?.pipeline}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >
                {data && data?.hash}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(data && data?.updated_time), 'PP')}
            </TableCell>
        </TableRow>
    )
}



const ReportsThreeComponent = (props) => {

    const projectIdFromURL = (new URLSearchParams(window.location.search)).get("projectId");
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("businessId");
    const formattedDateRange = (new URLSearchParams(window.location.search)).get("data");


    const { selectedProject, selected_business } = props;

    const { loading, generateReportThree, reportDataThree, total_authentication, pagination, generateReportAllThree, all_transactions } = useReports();
    const { viewProjectDetails, projectDetails } = useProjects();


    const [page, setPage] = useState(1);

    useEffect(() => {

        viewProjectDetails(projectIdFromURL);

    }, [projectIdFromURL])

    const decryptedFormattedDateRange = decrypt(formattedDateRange, 'ovcode');

    // Function to decrypt the formatted date range
    const decryptDateRange = (formattedDateRange, decryptionKey) => {
        // Assuming decrypt function decrypts the string correctly
        return decrypt(formattedDateRange, decryptionKey);
    };

    // Function to format the date
    const formatDate = (dateString) => {
        const options = { month: 'long', day: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    // Function to parse and format the date range
    const formatRange = (decryptedFormattedDateRange) => {
        console.log("Decrypted formatted date range:", decryptedFormattedDateRange);
        let startDateString, endDateString;

        if (decryptedFormattedDateRange.includes('-')) {
            // If the range is in the format "April 01-30, 2024"
            const [startDatePart, endDatePart] = decryptedFormattedDateRange.split('-').map(part => part.trim());

            console.log(endDatePart);

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' }); // Get the full month name

            console.log(startDatePart, endDatePart);

            // startDateString = startDatePart + ', ' + new Date().getFullYear();

            const [startDateMonth, startDateDay, startDateYear] = startDatePart.split(/[ ,]+/);
            console.log("startDateYear", startDateYear)
            if (!startDateYear) {
                startDateString = startDatePart + ', ' + new Date().getFullYear();
            } else {
                startDateString = startDatePart;
            }

            const [endDateDay, endDateYear] = endDatePart.split(',').map(part => part.trim());
            if (endDatePart && endDatePart.includes(startDateMonth)) {
                endDateString = endDateDay + ', ' + endDateYear;
            } else {
                endDateString = currentMonth + ' ' + endDateDay + ', ' + endDateYear;
            }

        } else {
            // If the range is in the format "April 01, 2023 - April 30, 2024"
            const parts = decryptedFormattedDateRange.split('-').map(part => part.trim());
            startDateString = parts[0];
            endDateString = parts[1];
        }


        console.log("Start Date String:", startDateString);
        console.log("End Date String:", endDateString);

        return { formattedStartDate: startDateString, formattedEndDate: endDateString };
    };


    const { formattedStartDate, formattedEndDate } = formatRange(decryptedFormattedDateRange);

    console.log("Formatted report Start Date:", formattedStartDate);
    console.log("Formatted report End Date:", formattedEndDate);

    useEffect(() => {
        if (businessIdFromURL && projectIdFromURL && decryptedFormattedDateRange) {

            let date_from = dayjs(formattedStartDate).format("YYYY-MM-DD");
            let date_to = dayjs(formattedEndDate).format("YYYY-MM-DD");

            generateReportThree(businessIdFromURL, projectIdFromURL, date_from, date_to, page);
            generateReportAllThree(businessIdFromURL, projectIdFromURL, date_from, date_to);
        }
    }, [businessIdFromURL, projectIdFromURL, decryptedFormattedDateRange]);


    const handleChangePagination = (event, page) => {
        setPage(page);
        if (businessIdFromURL && projectIdFromURL && formattedStartDate && formattedEndDate) {

            let date_from = dayjs(formattedStartDate).format("YYYY-MM-DD");
            let date_to = dayjs(formattedEndDate).format("YYYY-MM-DD");

            generateReportThree(businessIdFromURL, projectIdFromURL, date_from, date_to, page);
        }
    };

    console.log("all_transactionsxxx", all_transactions)

    const generatePDF = () => {
        const doc = new jsPDF();

        const business = projectDetails?.application_name;


        const title = `${business} Report as of ${decryptedFormattedDateRange}`;

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = pageWidth / 100;
        const titleY = 10;

        doc.text(title, titleX, titleY);

        const columns = ['Reference No.', 'Type', 'Pipeline', 'Hash', 'Date'];

        const formattedData = all_transactions?.map(item => [
            item?.reference,
            item?.type,
            item?.pipeline,
            item?.hash,
            format(new Date(item?.updated_time), 'PP'),
        ]);

        doc.autoTableSetDefaults({
            columnStyles: {
                0: { columnWidth: 35 },
                1: { columnWidth: 25 },
                2: { columnWidth: 40 },
                3: { columnWidth: 45 },
                4: { columnWidth: 25 },
            },
        });

        doc.autoTable({
            head: [columns],
            body: formattedData,
            startY: 20,
        });

        doc.setFontSize(10);

        const overallTotal = total_authentication ? total_authentication : 0;
        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 10;
        const textX = pageWidth - textWidth - 10;
        const textY = doc.autoTable.previous.finalY + 10;

        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

        doc.save(`${business}-Document-Report.pdf`);
    };




    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>


            <Grid container sx={{ position: 'relative', zIndex: 2 }}>
                <Grid item xs={12} sm={3} sx={{ textAlign: 'center', maxWidth: '15% !important', flexBasis: '2% !important', marginLeft: 'auto' }}>
                    <Button
                        variant="contained"
                        onClick={generatePDF}
                        sx={{
                            width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                            ":hover": { background: "#0178f2" }
                        }}
                    >
                        Download
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                            <ReportThreeTableHeader />

                            {loading ?
                                <ReportThreeSkeletonComponent />
                                :
                                <TableBody>
                                    {(reportDataThree && reportDataThree)?.map((data, index) => (
                                        <ReportThreeTableContent key={index} data={data} />
                                    ))}
                                </TableBody>
                            }

                        </Table>
                        {!loading && (reportDataThree?.length === 0 || reportDataThree === undefined) &&
                            <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                                {selectedProject && selected_business &&
                                    <Typography><b>{selectedProject}</b> in <b>{selected_business}</b> has no available authentications for {decryptedFormattedDateRange}.</Typography>
                                }
                            </Box>
                        }
                        {!loading && reportDataThree?.length !== 0 && reportDataThree !== undefined && (
                            <Pagination
                                count={pagination}
                                page={page}
                                onChange={handleChangePagination}
                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                            />
                        )}
                    </TableContainer>
                </Grid>
                <br />
                <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReportsThreeComponent;