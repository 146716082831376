import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import BusinessListSkeletonComponent from "../skeletons/business-list-skeleton.component";
import useBusiness from "../../hooks/useBusiness";
import { format } from 'date-fns';
import useApim from "../../hooks/useApim";
import CachedIcon from '@mui/icons-material/Cached';
import ForbiddenScreen from "../../screens/ForbiddenScreen";
import usePackageManagement from "../../hooks/usePackageManagement";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useCredits from "../../hooks/useCredits";


const statusOption = [
    { value: 'true', label: "Active" },
    { value: 'false', label: "Inactive" }
]



function TableHeader(props) {

    const {handleSortByNameDesc, handleSortByNameAsc, handleSortByDateAsc, handleSortByDateDesc, handleSortByUpdatedDateAsc, handleSortByUpdatedDateDesc} = props;

    const tablecell = [
        { label: 'Logo' },
        { label: 'Enterprise Name' },
        { label: 'Website' },
        { label: 'Plan' },
        { label: 'Credits' },
        { label: 'Status' },
        { label: 'Created Time' },
        { label: 'Last Date Modified' }
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2', alignItems:'center',
                           }}
                        >
                            
                            <div  style={{display:'flex', alignItems:'center'}}>
                                {value.label} 

                                {value.label === "Enterprise Name" && 
                                <div style={{display:'inline-grid', marginLeft:'10px'}}>
                                    <ArrowDropUpIcon onClick={(e) => handleSortByNameAsc('business_name', 'ASC')} sx={{color:'white', cursor:'pointer'}} />
                                    <ArrowDropDownIcon onClick={(e) => handleSortByNameDesc('business_name','DESC')} sx={{color:'white', marginTop: '-5px', cursor:'pointer'}} />
                                </div>
                                }
                                {value.label === "Created Time" && 
                                <div style={{display:'inline-grid', marginLeft:'10px'}}>  
                                <ArrowDropUpIcon onClick={(e) => handleSortByDateAsc('registered_date','ASC')} sx={{color:'white', cursor:'pointer'}} />
                                    <ArrowDropDownIcon onClick={(e) => handleSortByUpdatedDateDesc('registered_date', 'DESC')} sx={{color:'white', marginTop: '-5px', cursor:'pointer'}} />
                                </div>
                                }
                                {value.label === "Last Date Modified" && 
                                <div style={{display:'inline-grid', marginLeft:'10px'}}>
                                    <ArrowDropUpIcon onClick={(e) => handleSortByUpdatedDateAsc('updated_date','ASC')} sx={{color:'white', cursor:'pointer'}} />
                                    <ArrowDropDownIcon onClick={(e) => handleSortByDateDesc('updated_date', 'DESC')} sx={{color:'white', marginTop: '-5px', cursor:'pointer'}} />
                                </div>
                                }
                            </div>
                        </TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


function TableContent(props) {

    const { business, breakpoints } = props;
    const navigate = useNavigate();

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" onClick={(e) => navigate('/view?id=' + business.id)} sx={{ cursor: 'pointer' }}>
                {(business.logo) ?
                    <Avatar variant="square" src={business.logo ? business.logo : null}
                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 50, height: 50, objectFit: "contain" }}
                        imgProps={{ sx: { objectFit: "contain" } }}
                    />
                    :
                    <Typography>
                        N/A
                    </Typography>
                }
            </TableCell>
            <TableCell component="th" scope="row" onClick={(e) => navigate('/view?id=' + business.id)} sx={{ cursor: 'pointer', color: "#0178f2" }} >
                {(business.business_name) ? business.business_name : "N/A"}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {(business.website) ? business.website : "N/A"}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {business?.business_plan}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {business?.credit}
            </TableCell>
            <TableCell>
                <Typography variant="caption" sx={{ color: business?.status == 1 ? "green" : "red" }}>{business?.status == 1 ? "Active" : "Inactive"}</Typography>
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(business?.registered_date), 'PPpp')}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(business?.updated_date), 'PPpp')}
            </TableCell>
        </TableRow>
    )
}

const ListOfBusinessAccountComponent = (props) => {

    const { breakpoints, setForbidden } = props;

    const navigate = useNavigate();

    const { businessList, pagination, isLoadingBusinessList, setLoadingBusinessList, getBusinessList, searchByBusinessName,
        getSearchByBusinessName, searchPagination, getBusinessListStatus, getBusinessListPlan, getBusinessListPlanWithStatus,
        getBusinessListNameWithStatus, getBusinessListWebsite, getBusinessListWebsiteWithStatus, error_error, getSortBusinessList
    } = useBusiness();
    const { getProducts, getProduct } = useApim();
    const { isFetchingBusinessPackageFeatures, viewPackage, getPackages, packageList,
        getBusinessPackageResponse, isRetrievingPackageSummary, packageSummaryList, getPackageSummary, planPackage
    } = usePackageManagement();
    const { getAllBusinessCredits, businessCredits } = useCredits();


    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [searchKeypress, setSearchKeyPress] = useState(null);
    const [plan, setPlan] = useState(null);
    const [options, setOption] = useState([]);
    const [status, setStatus] = useState(""); // Initial state as an empty string
    const [searchInputWebsite, setSearchInputWebsite] = useState('');
    const [searchKeypressWebsite, setSearchKeyPressWebsite] = useState(null);
    const [updatedBusinessList, setBusinessList] = useState([]);
    const [sort, setSort] = useState('')
    const [order, setOrder] = useState('')

    useEffect(() => {
        getPackageSummary();
        getPackages();
        getAllBusinessCredits(page);
    }, [])

    console.log("businessCredits here", businessCredits)
    console.log("businessPlanxxx", updatedBusinessList)
    console.log("businessListxxx", businessList)
    console.log("packageListxxx", packageList)

    useEffect(() => { if (error_error) { setForbidden(true); } }, [error_error])


    // useEffect(() => {
    //     if (businessList?.length > 0 && businessList && packageList) {

    //         // Ensure businessDetails is in array format
    //         const packageListArray = Array.isArray(packageList) ? packageList : [packageList];

    //         console.log("projectsArrayxxx", packageListArray)

    //         const packageNameMap = {};
    //         packageListArray.forEach(detail => {
    //             packageNameMap[detail?.id] = detail?.package_name;
    //         });

    //         console.log("packageNameMapxxxxx", packageNameMap)
    //         // Match user data with business details
    //         const updatedBusinessList = businessList.map(business => {
    //             // Assuming each user has a business_account_id property
    //             const businessPlan = packageNameMap[business?.package_id] || "N/A";
    //             return {
    //                 ...business,
    //                 business_plan: businessPlan,
    //                 // Add other business details you need similarly
    //             };
    //         });
    //         setBusinessList(updatedBusinessList);
    //     } else {
    //         // Handle the case when users.length is 0
    //         setBusinessList([]);
    //     }
    // }, [packageList, businessList]);

    useEffect(() => {
        if (businessList?.length > 0 && packageList?.length > 0 && businessCredits?.length > 0) {
            // Ensure packageList is in array format
            const packageListArray = Array.isArray(packageList) ? packageList : [packageList];

            // Create a mapping of package_id to package_name
            const packageNameMap = {};
            packageListArray.forEach(detail => {
                packageNameMap[detail?.id] = detail?.package_name;
            });

            // Create a mapping of business account id to credit
            const businessCreditMap = {};
            businessCredits.forEach(credit => {
                businessCreditMap[credit.businessAccount.id] = credit.credit;
            });

            // Match businessList with packageList and businessCredits
            const updatedBusinessList = businessList.map(business => {
                const businessPlan = packageNameMap[business?.package_id] || "N/A";
                const businessCredit = businessCreditMap[business?.id] || 0; // Default credit to 0 if not found

                return {
                    ...business,
                    business_plan: businessPlan,
                    credit: businessCredit,
                    // Add other business details you need similarly
                };
            });

            setBusinessList(updatedBusinessList);
        } else {
            // Handle the case when businessList, packageList, or businessCredits are empty
            setBusinessList([]);
        }
    }, [businessList, packageList, businessCredits]);


    const reload = () => {
        setSearchInput('');
        setSearchKeyPress(null);
        setPlan(null);
        setStatus("");
        setSearchInputWebsite('');
        setSearchKeyPressWebsite(null);
        getSortBusinessList("", "", "");
        setPage(1)
    }


    useEffect(() => { getProducts() }, [])

   

    useEffect(() => {

        let options = []

        packageSummaryList?.map((data) => {

            console.log("data herexx", data)

            let options_data = { value: data?.package?.id, label: data?.package?.package_name }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(packageSummaryList)])

    console.log("option here", options)


    const handlePlanChange = (event) => {
        setPlan(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    const handleRemoveStatus = () => {
        setStatus('');
    };

    const searchItems = (e) => {
        setSearchInput(e.target.value)
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            if (status && searchInput) {
                getBusinessListNameWithStatus(status, searchInput, page);
            } else {
                getSearchByBusinessName(e.target.value, page)
            }
            setSearchKeyPress(e.target.value)
        } else {
            setSearchKeyPress(null)
            setSearchInput(null)
        }
    }

    const handleSortByNameAsc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const handleSortByNameDesc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const handleSortByDateAsc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const handleSortByDateDesc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const handleSortByUpdatedDateAsc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const handleSortByUpdatedDateDesc = (sort, order, page) => {
        setOrder(order)
        setSort(sort)
        getSortBusinessList(sort, order, page)
        setPage(1)
    }

    const searchWebsite = (e) => {
        setSearchInputWebsite(e.target.value)
    }

    const keyPressWebsite = (e) => {
        if (e.keyCode == 13) {
            if (status && searchInputWebsite) {
                getBusinessListWebsiteWithStatus(status, searchInputWebsite, page)
            } else {
                getBusinessListWebsite(e.target.value, page);
            }
            setSearchInputWebsite(e.target.value)
        } else {
            setSearchInputWebsite(null)
        }
    }

    const handleChangePagination = (e, page) => {
        setPage(page);
        setLoadingBusinessList(true);
        if(sort != null){
            getSortBusinessList(sort, order, page);
        }else{
            getSortBusinessList("", "", page);
        }
        
    }

    // const handleChangeSortPagination = (e, page) => {
    //     setPage(page);
    //     setLoadingBusinessList(true);
    //     getSortBusinessList(sort, order, page);
    // }

    const handleChangeSearchPagination = (e, page) => {
        setPage(page);
        setLoadingBusinessList(true);
        getSearchByBusinessName(searchInput, page)
    }

    const handleChangeStatusPagination = (e, page) => {
        setPage(page);
        setLoadingBusinessList(true);
        getBusinessListStatus(status, page)
    }

    const handleChangePlanPagination = (e, page) => {
        setPage(page);
        setLoadingBusinessList(true);
        getBusinessListPlan(plan, page)
    }

    const handleChangeWebsitePagination = (e, page) => {
        setPage(page);
        setLoadingBusinessList(true);
        getBusinessListWebsite(searchInputWebsite, page)
    }

    // useEffect(() => {
    //     if (status && plan) {
    //       getBusinessListPlanWithStatus(status, plan, page);
    //     } else if (status !== "" && (plan === null || plan === undefined)) {
    //       getBusinessListStatus(status, page);
    //     } else if ((status === "" || status === undefined) && (plan !== null && plan !== undefined)) {
    //       getBusinessListPlan(plan, page);
    //     } else if ((sort !== "" || sort === undefined)) {
    //         getSortBusinessList(sort, order, page)
    //     } else {
    //       getSortBusinessList("", "", page);
    //     }
    // }, [status, plan, sort]);

    useEffect(() => {
        if (sort || order) {
            getSortBusinessList(sort, order, page);
        } else if (status || plan) {
            if (status && plan) {
                getBusinessListPlanWithStatus(status, plan, page);
            } else if (status) {
                getBusinessListStatus(status, page);
            } else {
                getBusinessListPlan(plan, page);
            }
        } else {
            getSortBusinessList("", "", page);
        }
    }, [status, plan, sort, order, page]);

    return (
        <Box>
            
            <Grid container sx={{ mt: "20px" }}>
                <Grid item xs={12} sm={10} sx={{ mb: 2, textAlign: breakpoints == 'xs' ? 'start' : 'start', mr: 'auto' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>
                                <Box>
                                    <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>

                                        <FormControl>
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={status}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleStatusChange}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {statusOption?.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }}>
                                                            {status.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        &nbsp;

                                        <TextField
                                            variant="outlined"
                                            autoComplete="off"
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 285 } }}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder="Search Enterprise Name..."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Enterprise Name:</p></InputAdornment>,
                                            }}
                                            onChange={searchItems}
                                            onKeyDown={keyPress}
                                            value={searchInput}
                                        />

                                        &nbsp;

                                        <TextField
                                            variant="outlined"
                                            autoComplete="off"
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 200 } }}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder="Search Website..."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Website:</p></InputAdornment>,
                                            }}
                                            value={searchInputWebsite}
                                            onChange={searchWebsite}
                                            onKeyDown={keyPressWebsite}
                                        />
                                        &nbsp;

                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Plan</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={plan}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handlePlanChange}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {options?.map((option) => {
                                                    return (
                                                        <MenuItem key={option.value} value={option.value} sx={{ fontSize: 12 }}>
                                                            {option.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        &nbsp;
                                        <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>


                                    </Stack>

                                </Box>


                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={2} sx={{ textAlign: "end", mb: 2, marginLeft: -2 }}>
                    <Button variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }} onClick={(e) => navigate('/create')}>Create</Button>
                </Grid>
                <Grid item xs={12} >
                    <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>

                            <TableHeader handleSortByNameAsc={handleSortByNameAsc} handleSortByNameDesc={handleSortByNameDesc} 
                            handleSortByDateAsc={handleSortByDateAsc} handleSortByDateDesc={handleSortByDateDesc}
                            handleSortByUpdatedDateAsc={handleSortByUpdatedDateAsc} handleSortByUpdatedDateDesc={handleSortByUpdatedDateDesc} />
                            
                            {isLoadingBusinessList ?
                                <TableBody ><BusinessListSkeletonComponent /></TableBody>
                                :
                                <TableBody sx={{ width: "100%" }}>
                                    {searchKeypress ? (
                                        updatedBusinessList?.map((item, index) => {
                                            return (
                                                <TableContent key={index}
                                                    business={item} breakpoints={breakpoints}
                                                />
                                            )
                                        })
                                    ) : (
                                        <>
                                            {(updatedBusinessList && updatedBusinessList)?.map((business, index) => { 
                                                return (
                                                <TableContent key={index}
                                                    business={business} breakpoints={breakpoints}
                                                />
                                            )})}
                                        </>
                                    )}
                                </TableBody>
                            }
                        </Table>

                        {isLoadingBusinessList ? (
                            null
                        ) : (
                            (searchByBusinessName?.length === 0 || updatedBusinessList?.length === 0) && (
                                <Box sx={{ textAlign: 'center', padding: 10 }}>
                                    No results found.
                                </Box>
                            )
                        )}


                    <Pagination
                        count={searchInput ? searchPagination : pagination}
                        page={page}
                        onChange={
                            searchInput ? handleChangeSearchPagination :
                            status ? handleChangeStatusPagination :
                            plan ? handleChangePlanPagination :
                            handleChangePagination
                        }
                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />

                    </TableContainer>
                </Grid>
            </Grid>

        </Box>
    )
}

export default ListOfBusinessAccountComponent