import { useState, useEffect } from "react";
import { Stack, Chip, Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import Avatar from "react-avatar";
import { timeAgo } from "../../utils/dateFormatterUtil";
import ProjectPopperComponent from "./project-popper.component";

function CardProjectMenu(props) {

    const { breakpoints, 
            project, 
            projectCreditState, 
            setProjectCreditState, 
            project_is_updated, 
            updatedApplicationName, 
            setUpdatedApplicationName, 
            setProjectIsUpdated, 
            setIsDeleted,
            setIsLogoisUpdated,
            setUpdatedLogo,
    } = props;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <ProjectPopperComponent
                    breakpoints={breakpoints}
                    project={project}
                    projectCreditState={projectCreditState}
                    setProjectCreditState={setProjectCreditState}
                    project_is_updated={project_is_updated}
                    updatedApplicationName={updatedApplicationName}
                    setUpdatedApplicationName={setUpdatedApplicationName}
                    setProjectIsUpdated={setProjectIsUpdated}
                    setIsDeleted={setIsDeleted}
                    setIsLogoisUpdated={setIsLogoisUpdated}
                    setUpdatedLogo={setUpdatedLogo}
                />
            </Stack>
        </Stack>
    )
}

const ProjectListComponent = (props) => {

    const { project, transactionCount, transactionByMonthCount, handleClickViewProject, breakpoints, transactionError } = props;

    const [projectCreditState, setProjectCreditState] = useState(project?.credit);
    const [updatedApplicationName, setUpdatedApplicationName] = useState();
    const [project_is_updated, setProjectIsUpdated] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isUpdated, setIsLogoisUpdated] = useState(false);
    const [updatedLogo, setUpdatedLogo] = useState(null);

    console.log("updatedLogoxxx",updatedLogo)
    console.log("isUpdatedxxx",isUpdated)

    useEffect(() => {
        setProjectCreditState(project?.credit);
    }, [project])


    console.log("isDeletedxxx", isDeleted)

    return (

        <>
            {!isDeleted &&
                <Box sx={{ width: "100%" }}>
                    <Card sx={{ borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', cursor: "" }}>
                        <CardHeader sx={{ paddingBottom: 0 }}
                            title={
                                <div style={{ display: "flex" }}>
                                    {project_is_updated ?
                                        <Typography className="card-project-name"
                                            sx={{ alignSelf: 'center', cursor: "pointer", fontWeight: 'bold', textAlign: 'start', paddingBottom: 0, mr: 1 }}
                                            onClick={(e) => handleClickViewProject(project.id, projectCreditState, updatedApplicationName)}
                                        >
                                            {updatedApplicationName}
                                        </Typography>
                                        :
                                        <Typography className="card-project-name"
                                            sx={{ alignSelf: 'center', cursor: "pointer", fontWeight: 'bold', textAlign: 'start', paddingBottom: 0, mr: 1 }}
                                            onClick={(e) => handleClickViewProject(project.id, projectCreditState, project.application_name)}
                                        >
                                            {project.application_name}
                                        </Typography>
                                    }

                                    <Chip color="success" variant="outlined" size="small" label={
                                        <Typography sx={{ fontSize: "13px" }}>
                                            {(projectCreditState > 0) ? projectCreditState + " Credits" : "0 Credit"}
                                        </Typography>}
                                    />
                                </div>
                            }
                            action={
                                <CardProjectMenu
                                    breakpoints={breakpoints}
                                    project={project}
                                    projectCreditState={projectCreditState}
                                    setProjectCreditState={setProjectCreditState}
                                    project_is_updated={project_is_updated}
                                    updatedApplicationName={updatedApplicationName}
                                    setUpdatedApplicationName={setUpdatedApplicationName}
                                    setProjectIsUpdated={setProjectIsUpdated}
                                    setIsDeleted={setIsDeleted}
                                    setIsLogoisUpdated={setIsLogoisUpdated}
                                    setUpdatedLogo={setUpdatedLogo}
                                />
                            }
                        />

                        {(project?.logo) ?
                            <CardHeader sx={{ paddingBottom: 0, textAlign: "left" }}
                                avatar={
                                    <img alt="" className="card-logo" src={isUpdated ? updatedLogo : project?.logo} style={{ height: 100, width: 100, objectFit: 'contain', marginRight: 60 }} />
                                }
                                title={
                                    (transactionError?.response?.data?.error === "Forbidden.") ? ( null 
                                    ) :(
                                        <Typography sx={{ cursor: "pointer", fontWeight: "bold", fontSize: 35, marginLeft: "-12px !important" }}>
                                            {transactionCount[project?.id] || 0}
                                        </Typography>
                                    )
                                }
                                
                                subheader={ (transactionError?.response?.data?.error === "Forbidden.") ? 
                                    <Grid container spacing={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                            <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                You are not authorized.
                                            </Typography>
                                            <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                You tried to access a page you did not have prior authorization for.
                                            </Typography>
                                        </Grid>
                                    
                                    </Grid>
                                    :
                                    <Typography sx={{ fontWeight: "bold", marginLeft: "-12px !important" }}>
                                        Authentication
                                    </Typography>
                                }
                            />

                            :
                            <CardHeader sx={{ paddingBottom: 0, textAlign: "left" }}
                                avatar={ isUpdated ?
                                        <img alt="" className="card-logo" src={updatedLogo} style={{ height: 100, width: 100, objectFit: 'contain', marginRight: 60 }} />
                                    :
                                    <Avatar
                                        round={true}
                                        name={project?.application_name}
                                        style={{ marginRight: 55 }}
                                        maxInitials={2}
                                    />
                                }
                                title={
                                    (transactionError?.response?.data?.error === "Forbidden.") ? ( null 
                                    ) :(
                                        <Typography sx={{ cursor: "pointer", fontWeight: "bold", fontSize: 35, marginLeft: "-12px !important" }}>
                                            {transactionCount[project?.id] || 0}
                                        </Typography>
                                    )
                                }
                                subheader={ (transactionError?.response?.data?.error === "Forbidden") ? 
                                    <Grid container spacing={0} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                            <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                You are not authorized.
                                            </Typography>
                                            <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                You tried to access a page you did not have prior authorization for.
                                            </Typography>
                                        </Grid>
                                    
                                    </Grid>
                                    :
                                    <Typography sx={{ fontWeight: "bold", marginLeft: "-12px !important" }}>
                                        Authentication
                                    </Typography>
                                }
                            />
                        }

                        <br />

                        <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontSize: 9, textAlign: 'start' }}>
                                        Created Time: {timeAgo(new Date(project.created_date))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontSize: 9, textAlign: 'end', paddingRight: 1 }}>
                                        {transactionByMonthCount[project?.id]?.count ? transactionByMonthCount[project?.id]?.count : 0} this Month
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            }
        </>
    )
}
export default ProjectListComponent