import { Box, ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns';
import UpdatePermissionDialog from "../rbac/dialogs/update-permission.dialog";
import DeletePermissionDialog from "../rbac/dialogs/delete-permission.dialog";
import ViewPermissionListSkeleton from "../skeletons/view-permission-list-skeleton.component";



function PermissionActionPopper (props) {

    const { data } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdatePermission, setOpenUpdatePermission] = useState(false);
    const [openDeletePermission, setOpenDeletePermission] = useState(false);

    const handleOpenUpdatePermission = () => {
        setOpenUpdatePermission(true);
    }

    const handleCloseUpdatePermission = () => {
        setOpenUpdatePermission(false);
    }

    const handleOpenDeletePermission = () => {
        setOpenDeletePermission(true);
    }

    const handleCloseDeletePermission = () => {
        setOpenDeletePermission(false);
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreHorizIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleOpenUpdatePermission} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeletePermission} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Delete</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

            {openUpdatePermission &&
                <UpdatePermissionDialog 
                    openUpdatePermission={openUpdatePermission}
                    handleClose={handleCloseUpdatePermission}
                    data={data}
                />
            }

            {openDeletePermission &&
                <DeletePermissionDialog 
                    openDeletePermission={openDeletePermission}
                    handleClose={handleCloseDeletePermission}
                    data={data}
                />
            }

        </Stack>
    )
}

const TableHeader = () => {

    const tablecell = [ 
        {label : 'Function'},
        {label : 'Description'},
        {label : 'Last Date Modified'},
        {label : 'Action'}
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#0178f2'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const PermissionContent = (props) => {

    const { data, index } = props;

    return(
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell component="th" scope="row" sx={{cursor:'pointer',color: "#0178f2"}} >
                {data?.fn}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.description}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(data?.updated_at), 'PPpp')}
            </TableCell>
            <TableCell>
                <PermissionActionPopper data={data} />
            </TableCell>
        </TableRow>
    )
}


const ViewAdminPermissionContentComponent = (props) => {

    const { functions, loading } = props;

    return (

        <Box sx={{width: '95%', margin: 'auto'}}>
            <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%"}}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 650}}>
                    <TableHeader />
    
                    {loading ?
                        <TableBody><ViewPermissionListSkeleton /></TableBody>
                    :    
                        <TableBody sx={{width: "100%"}}>
                            {functions && functions?.map((func, index) => ( 
                                <PermissionContent data={func} index={index} />
                            ))}
                        </TableBody>
                    }
                   
                    
                </Table>

                {(!loading && functions?.length === 0 || functions === undefined) &&
                    <Box sx={{margin: 5, textAlign:'center', ml: 'auto', mr: 'auto', color: 'gray'}}>
                        <Typography>No permission has yet been created.</Typography>
                    </Box>
                }
            </TableContainer>

            
        </Box>
    )
}

export default ViewAdminPermissionContentComponent