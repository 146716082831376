const convertFileSizeToBytes = (size) => {

    const parts = size.split(/([KMGT])/i);
    const number = parseFloat(parts[0]);
    const unit = parts[1].toLowerCase();

    let bytes;

    switch (unit) {
        case "k":
            bytes = number * 1000;
        break;

        case "m":
            bytes = number * 1000 * 1000;
        break;

        case "g":
            bytes = number * 1000 * 1000 * 1000;
        break;

        case "t":
            bytes = number * 1000 * 1000 * 1000 * 1000;
        break;

        default:
            bytes = number;
        break;
    }
    
    return bytes;
}

export {
    convertFileSizeToBytes
}
