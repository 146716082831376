import { Box, Fab, Typography, TextField, InputAdornment, Grid, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import ReactSelect from "react-select";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import CachedIcon from '@mui/icons-material/Cached';
import useWidth from "../hooks/useWidth";
import useProjects from "../hooks/useProjects"
import VaultListComponent from "../components/enterprise/vault/vault-list.component";
import useVault from "../hooks/useVault";
import ForbiddenScreen from "./ForbiddenScreen";


const VaultScreen = () => {

    const { getFiles, fileList, getFilesByProjectId, fileByProjectId, loading, downloadFile, error,
        previewFile, pagination, limit, openDocxPreview, isLoading, isPreviewLoading, getFilesByDateRange, getFileNameByProjectId } = useVault();
    const { getProjectList, projects, loadingProjects } = useProjects();

    const { breakpoints } = useWidth();

    let businessId = (new URLSearchParams(window.location.search)).get("id");

    const [project_id, setProjectName] = useState('');
    const [page, setPage] = useState(1);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [filename, setFilename] = useState('')
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);


    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])

    useEffect(() => {
        getProjectList(businessId);
    }, [businessId])

    useEffect(() => {
        let options = [];

        projects?.map((project) => {
            let options_data = { value: project?.id, label: project?.application_name }

            options.push(options_data);
        })

        setProjectOptions(options);

    }, [JSON.stringify(projects)])


    useEffect(() => {

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        console.log("dd dateFromxxx dd", date_from)
        console.log("dd dateToxxx dd", date_to)

        getFiles(page, date_from, date_to, businessId)

    }, [dateFrom, dateTo, page, businessId])


    const handleSelectProject = (selectedOption) => {
        const selectedValue = selectedOption?.value;

        if (selectedValue !== undefined && selectedValue !== null) {
            setSelectedProject(selectedValue);
        } else {

            setSelectedProject('');

            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            console.log("dateFromxxx", date_from)
            console.log("dateToxxx", date_to)

            getFiles(page, date_from, date_to, businessId);
        }
    };

    useEffect(() => {
        if (selectedProject === null || selectedProject === undefined || selectedProject === '') {
            if (dateFrom && dateTo) {
                let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
                let date_to = dayjs(dateTo).format("YYYY-MM-DD");

                console.log("cc dateFromxxx cc", date_from)
                console.log("cc dateToxxx cc", date_to)

                getFiles(page, date_from, date_to, businessId);
            }
        } else if (selectedProject) {
            console.log("selectedProject xxx", selectedProject)
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            console.log("aa dateFromxxx bb", date_from)
            console.log("aa dateToxxx bb", date_to)
            console.log("aa business id bb", businessId)
            console.log("aa project id bb", selectedProject)

            getFilesByProjectId(selectedProject, page, date_from, date_to, businessId);
        }
    }, [selectedProject, dateFrom, dateTo, page, businessId]);


    const handleChangePagination = (event, page) => {

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        console.log("dateFromxxx", date_from)
        console.log("dateToxxx", date_to)

        setPage(page);
        getFiles(page, date_from, date_to, businessId);
    }

    const reload = () => {
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        getFiles(page, reload_dateFrom, reload_dateTo, businessId);
    }




    return (
        <Box>
            {error === "Forbidden" ?
                <Box sx={{ pl: 3, pr: 3, pt: 1, pb: 1, width: '100%', borderRadius: 0, display: 'flex' }}>

                    <Typography variant='h6'>Vault</Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <ForbiddenScreen />
                </Box>
                :
                <>
                    <Box sx={{ pl: 3, pr: 3, pt: 1, pb: 1, width: '100%', borderRadius: 0, display: 'flex' }}>

                        <Typography variant='h6'>Vault</Typography>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <ReactSelect
                                className="basic-single"
                                isSearchable={true}
                                isRtl={false}
                                isClearable={true}
                                options={projectOptions}
                                onChange={selectedOption => handleSelectProject(selectedOption)}
                                placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Project:</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Search Project...</i></>} // Set the placeholder here
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 220,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                            />


                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Typography>From&nbsp;&nbsp;</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                    <DatePicker
                                        value={dateFrom}
                                        required
                                        views={["year", "month", "day"]}
                                        inputProps={{ style: { fontSize: 12, height: 38 } }}
                                        sx={{
                                            fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                            "& .MuiInputLabel-root": { fontSize: 12 },
                                            "& .MuiOutlinedInput-root": {
                                                height: 38,
                                                borderRadius: 10,
                                                fontSize: 12,
                                                "& > fieldset": {
                                                },
                                            },
                                        }}
                                        onChange={(newValue) => { setDateFrom(newValue) }}
                                        maxDate={dateTo}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                            &nbsp; &nbsp;

                            <Typography>To&nbsp;&nbsp;</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                    <DatePicker
                                        value={dateTo}
                                        required
                                        views={["year", "month", "day"]}
                                        inputProps={{ style: { fontSize: 12, height: 38 } }}
                                        sx={{
                                            fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                            "& .MuiInputLabel-root": { fontSize: 12 },
                                            "& .MuiOutlinedInput-root": {
                                                height: 38,
                                                borderRadius: 10,
                                                fontSize: 12,
                                                "& > fieldset": {
                                                },
                                            },
                                        }}
                                        onChange={(newValue) => { setDateTo(newValue) }}
                                        minDate={dateFrom}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                            &nbsp; &nbsp;

                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                        </Box>

                    </Box>
                    <VaultListComponent
                        previewFile={previewFile}
                        downloadFile={downloadFile}
                        fileList={fileList}
                        project_id={selectedProject}
                        fileByProjectId={fileByProjectId}
                        openDocxPreview={openDocxPreview}
                        isPreviewLoading={isPreviewLoading}
                        loading={loading}
                        page={page}
                        pagination={pagination}
                        handleChangePagination={handleChangePagination}
                        isLoading={isLoading}
                        breakpoints={breakpoints}
                        businessId={businessId}
                    />
                </>

            }

        </Box>

    )
}

export default VaultScreen