import React, { useEffect } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import { ThreeDots } from 'react-loader-spinner';
import useWebhook from '../../../hooks/useWebhook';

const DeleteWebhookDialog = (props) => {

    const {webhook, openDelete, handleCloseDeleteDialog, sendDataToParentFromChild, sendDataToParent, projectId, businessId} = props;

    // let projectId = webhook?.project_id;

    const { loading, deleted, deleteWebhook } = useWebhook();

    const handleDeleteWebhook = () => {
        deleteWebhook(projectId, businessId)
    }

    useEffect(() => {

        if(deleted){
            sendDataToParentFromChild(true);
            sendDataToParent(false);

            handleCloseDeleteDialog();

        }

    },[deleted])


    return (
        <Dialog open={openDelete} onClose={handleCloseDeleteDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Delete Webhook ?
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 8}}>
               <Typography sx={{color: 'red', fontStyle: 'italic'}}>
                This action canot be undone. Future events will no longer be delivered to this webhook (https://ovcode.com/testapi/test).
               </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleCloseDeleteDialog}>
                    Back
                </Button>

                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', fontSize: 14, '&:hover': {backgroundColor:'#0178f2', color: 'white'}}}
                  onClick={(e) => handleDeleteWebhook()}
                  startIcon={ loading ? <ThreeDots  height="20"  width="20"   radius="9" color="#ffff"  ariaLabel="three-dots-loading"  visible={true} /> : null}
                >
                    {loading ? 'Deleting' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteWebhookDialog