import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import { HttpDelete, HttpGet, HttpPost, HttpPostMultipart, HttpPut1 } from "../common/HttpRequestBuilder";


const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getWebhook = (projectId, businessId) => {

    let query_params = {
        projectId: projectId,
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_WEBHOOK, query_params, subscriptionKey);
    
    return result;
}

const addWebhook = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    return HttpPost(routes.ADD_WEBHOOK, payload, subscriptionKey);
    
}

const deleteWebhook = (projectId, businessId) => {

    let parameters = {
        projectId: projectId,
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_WEBHOOK, parameters, subscriptionKey);

    return result;
}

const updateWebhook = (projectId, businessId, payload) => {

    let parameters = {
        projectId: projectId,
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    return HttpPut1(routes.UPDATE_WEBHOOK, parameters, payload , subscriptionKey);

}

const getWebhookTriggers = (projectId, page, limit) => {

    let query_params = {
        projectId: projectId,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_WEBHOOK_TRIGGERS, query_params, subscriptionKey);
    
    return result;
}


const WebhookService = {
    getWebhook,
    addWebhook,
    deleteWebhook,
    updateWebhook,
    getWebhookTriggers
}

export default WebhookService