import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import useProjects from "../hooks/useProjects";
import { useSnackbar } from "notistack";
import useTemplates from "../hooks/useTemplates";
import { ThreeDots } from 'react-loader-spinner';
import { FileUploader } from "react-drag-drop-files";
import useWidth from "../hooks/useWidth";
import { Document, Page } from 'react-pdf';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { convertFileSizeToBytes } from "../utils/fileSizeToBytesUtil";
import SelectBusinessUserDropdownComponent from "../components/common/select-business-user-dropdown.component";






function TemplateHeader() {

    return (
        <Box sx={{ padding: 1, width: '100%', borderRadius: 0 }}>
            <Grid container sx={{ textAlign: 'start', marginLeft: 2 }}>
                <Grid item xs={8} >
                    <Typography variant='h6' >Template Management</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const AddTemplateScreen = () => {

    let projectIdFromUrl = (new URLSearchParams(window.location.search)).get("projectId");
    // let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");
    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    console.log("projectIdFromUrl aaa", projectIdFromUrl);
    console.log("businessIdFromURL aaa", businessIdFromURL);


    const { getProjectList, projects, loadingProjects } = useProjects()

    const { enqueueSnackbar } = useSnackbar();
    const { createAccountTemplate, creating, created, template_code } = useTemplates();
    const breakpoints = useWidth();
    const navigate = useNavigate();

    useEffect(() => {
        getProjectList(businessIdFromURL);
    }, [])

    const fileTypes = ["pdf", "docx", "xlsx", "doc", "xls"];

    const [file, setFile] = useState(null);
    const [file_name, setFileName] = useState("");
    const [project_id, setProjectName] = useState(null);
    const [disableSelect, setDisableSelect] = useState(false);
    const [pdfPreview, setPDFPreview] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [project_name, setProject] = useState("");

    const [exactFileSize, setExactFileSize] = useState(0);
    const [currentSelectedUser, setCurrentSelectedUser] = useState(null);

    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    }

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();

        if (mimetype == "pdf" || mimetype == "docx" || mimetype == "xlsx" || mimetype == "doc" || mimetype == "xls") {
            const fileNameWithoutExtension = removeFileExtension(fileName);

            setFile(file);
            setPDFPreview(URL.createObjectURL(file));
            setFileName(fileNameWithoutExtension);

        } else {
            enqueueSnackbar("File type not supported", {
                variant: "error",
                autoHideDuration: 3000
            });
        }
    };

    const handleResetFile = () => {
        setFile(null);
        setPDFPreview(null);
        setFileName("");
        setProjectName(null);
        setProject(null);
    }

    const handleTemplateNameChange = (e) => {
        const { value } = e.target;
        setFileName(value);
    }

    useEffect(() => {
        if (projectIdFromUrl) {
            setDisableSelect(true);
        }
    }, [projectIdFromUrl]);

    useEffect(() => {
        let projectId = projectIdFromUrl;

        projects?.map((project) => {

            if (project?.id == projectId) {
                setProject(project?.application_name)
            }

        })

    }, [projectIdFromUrl, JSON.stringify(projects)])

    const handleProjectChange = (event) => {
        setProjectName(event.target.value);
    };

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

     let sizeFile = file?.size;

     let fileSizes = formatBytes(sizeFile);


     useEffect(() => {
        if (fileSizes) {
            convertFileSizeToBytes(fileSizes);
            setExactFileSize(convertFileSizeToBytes(fileSizes));
        }
    }, [fileSizes])


    const handleCreateTemplate = () => {

        if (exactFileSize < 900000 || exactFileSize == 900000) {

            let formData = new FormData();
            formData.append('file', file);
            formData.append('template_name', file_name);
            formData.append('project_id', projectIdFromUrl ? projectIdFromUrl : project_id);
            formData.append('user_id', currentSelectedUser.user_id);

            createAccountTemplate(formData)

        } else {
            enqueueSnackbar("Uploaded file size exceeds 2MB, please choose another file", {
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }


    useEffect(() => {
        if (created) {
            window.location.href = '/update-template?template_code=' + template_code;
        }
    },[created])

    const handleOnSelectUser = (user) => {
        console.log("user bbbb", user);
        setCurrentSelectedUser(user)
    }


    return (
        <Box sx={{ overflow: 'hidden' }}>

            <TemplateHeader />

            <br />



            <Card sx={{ width: '95%', margin: 'auto' }}>


                <Grid item xs={12} sx={{ textAlign: 'start', padding: 4, pb: 1 }}>
                    <Button startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
                        style={{ color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight: 600 }}
                        onClick={() => navigate(-1)} >
                        Back
                    </Button>
                </Grid>


                <CardContent >
                    <Grid container spacing={2} sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 18, pt: 0, pb: 5 }}>
                        {pdfPreview &&
                            <Grid item xs={12} sx={{ textAlign: "end" }}>
                                <Button onClick={(e) => handleResetFile(e)} sx={{ cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14 }}>
                                    Clear
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FileUploader
                                multiple={false}
                                handleChange={handleChange}
                                name="file"
                                label="Drag and Drop file here or click here to upload"
                                children={<>
                                    {(file) ?
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2px #b2d6f9",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "400px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear",
                                                backgroundColor: '#8080802e'
                                            }}
                                        >
                                            <Document file={file}>
                                                <Page pageNumber={pageNumber} height={300} />
                                            </Document>
                                        </div>
                                        :
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2px #0178f2",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "280px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear",
                                            }}
                                        >
                                            <p style={{ color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : 16 }}>
                                                Drag and Drop file here or click here to upload
                                            </p>
                                        </div>
                                    }
                                </>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} sx={{ alignSelf: 'center', mt: 2 }}>
                            {projectIdFromUrl ?
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={project_name}
                                        value={projectIdFromUrl}
                                        label="ovcode plan label"
                                    >
                                        <MenuItem value={projectIdFromUrl} >
                                            {project_name}
                                        </MenuItem>
                                    </Select>

                                </FormControl>
                            :
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={project_id}
                                        label="ovcode plan label"
                                        onChange={handleProjectChange}
                                    >
                                        {projects?.map((project, index) => {
                                            return (
                                                <MenuItem value={project?.id} >
                                                    {project?.application_name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ alignSelf: 'center', mt: 2 }}>
                            <SelectBusinessUserDropdownComponent businessId={businessIdFromURL} projectId={projectIdFromUrl || project_id} onSelectUser={handleOnSelectUser} />
                        </Grid>

                        <Grid item xs={12} sm={12} sx={{ alignSelf: 'center', mt: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                placeholder="Template Name"
                                value={file_name}
                                onChange={handleTemplateNameChange}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'end' }}>
                            <Button variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                                onClick={(e) => handleCreateTemplate()}
                                startIcon={creating ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                            >
                                {creating ? 'Creating' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>

        </Box>

    )
}

export default AddTemplateScreen;