
import './App.css';
import { history } from "./router/history";
import { BrowserRouter } from "react-router-dom";
import Routes from './router';
import AuthService from "./services/auth";
import LandingScreen from './screens/Landing.screen';
import Navbar from './layout/navbar';
import { ThemeProvider, createTheme } from '@mui/material';
import theme from './custom-theme/custom-theme'
import { SnackbarProvider } from "notistack";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

var customTheme = createTheme(theme());

function App() {

  const url = window.location.pathname;
  var isUserLoggedIn = AuthService.isUserLoggedIn();

  console.log("userLoggedIn xxxx", isUserLoggedIn);

  return (
    <DndProvider backend={HTML5Backend}>
      <SnackbarProvider>
        <ThemeProvider theme={customTheme}>
          {(isUserLoggedIn) ?
            <BrowserRouter history={history}>
              {(url == '/view-template' || url === '/save-template' || url === '/update-template') ?
                <Routes />
              :
                (url !== "/") ?
                  <Navbar>
                    <Routes />
                  </Navbar>
                :
                  <LandingScreen />
              }
            </BrowserRouter>
          :
            <LandingScreen />
          }
        </ThemeProvider>
      </SnackbarProvider>
    </DndProvider>
  );

}

export default App;