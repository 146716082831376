import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import useWidth from "../../hooks/useWidth";
import AdminRolesManagementComponent from "./admin-roles-management.component";
import AdminPermissionManagementComponent from "./admin-permission-management.component";
import AdminUserManagementComponent from "./admin-user-management.component";
import AdminAccessControlManagementScreenComponent from "./access-control/admin-access-control-management-screen.component";
import AdminActivityLogsScreen from "../../screens/AdminActivityLogsScreen";

function useSettingsTab(props) {

  const [settings_tab, setSettingsTab] = useState(null);
  const currentYear = new Date().getFullYear()

  function setupTabs() {

    var tabs_default = [
      { name: 'Users', component: <AdminUserManagementComponent /> },
      { name: 'Roles', component: <AdminRolesManagementComponent /> },
      { name: 'Permission', component: <AdminPermissionManagementComponent /> },
      { name: 'Access Control', component: <AdminAccessControlManagementScreenComponent />},
      { name: 'Activity Logs', component: <AdminActivityLogsScreen />},
    ]

    setSettingsTab(tabs_default);
  }

  useEffect(() => { setupTabs(); }, [])

  return settings_tab;

}


function SettingsTabsComponent(props) {

  const tabs = useSettingsTab();

  const breakpoints = useWidth();

  const storedTab = localStorage.getItem('selectedTab');
  const initialTabValue = storedTab ? parseInt(storedTab) : 0;
  const [value, setValue] = useState(initialTabValue);

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedTab');
    };
  }, []);

  function TabPanel(props) {

    const { children, value, index, ...other } = props;


    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  // function handleChange(event, newValue) {
  //   setValue(newValue);
  // }
  function handleChange(event, newValue) {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue); // Store selected tab index
  }
  

 

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ marginTop: 2 }}
          indicatorColor="transparent"
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })
          }
        </CustomTabs>
      </Box>


      {tabs?.map((tab, index) => {
        return (
          <TabPanel value={value} index={index} style={{ padding: "0px" }}>
            {tab.component}
          </TabPanel>
        )
      })
      }

      
    </Box>
  )
}

export default SettingsTabsComponent;