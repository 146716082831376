import { Box, Button, Grid, Skeleton, Typography } from "@mui/material"

function DashboardFabComponent(props) {

    const {item, loadingProjects} = props;

    return(
        <Box sx={{p:2, pt:1, pb:1}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {loadingProjects ? 
                        <Skeleton variant="circular" width={70} height={70} sx={{backgroundColor: "rgb(108 139 222 / 30%)", margin: 'auto'}} />
                    :
                        <Button sx={{width: 70, height: 70, backgroundColor: '#0178f2', borderRadius: '50%', color: '#fff', fontSize: 16, fontWeight: 600, ':hover' : {backgroundColor: 'transparent',  border: '1px solid #0178f2', color: '#0178f2'}}}>
                            {item?.[1]}
                        </Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    {loadingProjects ?
                        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                    :
                        <Typography sx={{fontSize: 13, fontWeight: 600, color: '#000'}}>{item?.[0]}</Typography>
                    }
                </Grid>
            </Grid>
        </Box>
    )
    
}

export default DashboardFabComponent