import { TableContainer, Table, TableHead, TableRow, Paper, TableBody, Typography, Pagination, Box,} from "@mui/material"
import { styled, useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useEffect, useState } from "react";
import WebhookService from "../../services/api/webhook";
import { format } from 'date-fns';
import WebhookTableSkeletonComponent from "../skeletons/webhook-table-skeleton.component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#0178f2',
      color: 'white',
    }
}));

function TableHeader(){
    const tablecell = [
        {label : 'Reference'},
        {label : 'Status'},
        {label : 'State'},
        {label : 'Timestamp'},
    ]

    return(
        <TableHead>
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <StyledTableCell sx={{fontWeight: 700}}>{value.label}</StyledTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TableContent (props) {

    const {logs} = props;
    
    const getColor = (webhook_state) => {
        if (webhook_state === "Failed") return 'red';
        if (webhook_state === "Sent") return 'green';
        if (webhook_state === "Pending") return 'black';
        
        return '';
    };

    return(
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
            <TableCell component="th" scope="row" sx={{color: '#0178f2'}} >
                {logs.reference}
            </TableCell>
            <TableCell component="th" scope="row" sx={{color: '#0178f2'}} >
                {logs.status === "COMPLETED" ?<Typography sx={{color:'green'}}>{logs.status}</Typography>:<Typography sx={{color:'red'}}>{logs.status}</Typography>}
            </TableCell>
            <TableCell component="th" scope="row" sx={{color: getColor(logs.webhook_state) }} >
                <Typography>{logs.webhook_state}</Typography>
            </TableCell>
            <TableCell component="th" scope="row" sx={{color: '#0178f2'}} >
                {format(new Date(logs?.updated_time), 'PPpp')}
            </TableCell>
        </TableRow> 
    )
}

function WebhookLogsComponent (props) {

    const {projectId} = props;

    const [webhookLogs, setWebhookLogs] = useState(null);
    const [pagination, setPagination] = useState(0);
    const [limit] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setLoading] = useState(false)

    const getWebhookLogs = (projectId, page, limit) => {
        setLoading(true)
        WebhookService.getWebhookTriggers(projectId, page, limit).then((res)=>{
            if(res.data.success){
                setWebhookLogs(res.data.data.data)
                setLoading(false)
            }
            if(res.data.data.length === 0){
                setPagination(0)
              }else{
                // var last_page = Math.ceil(res.data.data / 10); //set it depending on ui return - 18
                // log("last_page", last_page);
                setPagination(last_page)
                var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                
              }
        }).catch(err => {
            setLoading(false);
            setPagination(0)
        });
    }

    const handleChangePagination = (event,page) => {
        setPage(page);
        getWebhookLogs(projectId,page);
    }

    useEffect(()=>{
        getWebhookLogs(projectId, page, limit);
    },[])

    return(
        <TableContainer component={Paper} sx={{border: '1px solid #0178f2', borderRadius: 0, width: '100%',  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: 2}}>
            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 600}}>
                {/* TABLE HEADER */}
                <TableHeader/>
                {isLoading ?
                <TableBody><WebhookTableSkeletonComponent/> </TableBody>
                :
                <TableBody>
                    {webhookLogs?.map((logs)=>{
                        return(
                            <TableContent logs={logs}/>   
                            )
                        })}
                </TableBody>
            }
            </Table>
            
                {webhookLogs?.length === 0 && 
                <Box sx={{textAlign:'center', padding: 2}}>No logs are created.</Box>
                }

                <Pagination
                    count={pagination} 
                    page={page} 
                    onChange={handleChangePagination} 
                    style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    // disabled={webhookLogs?.length === 0}
                    hideNextButton={webhookLogs?.length < 10}
                />
            
        </TableContainer>
    )
}
export default WebhookLogsComponent