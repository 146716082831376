import React, {useEffect, useState} from "react";
import {BottomNavigation, BottomNavigationAction, Toolbar} from "@mui/material";
import {Box} from "@mui/system";
import {useNavigate} from "react-router-dom";
import DashboardIcon from "../icons/dashboard.png";
import AccountManagementIcon from "../icons/acnt-mgt.png";
import UsersIcon from '../icons/users.png';
import AnalyticsIcon from "../icons/analytics.png";
import ServiceMonitoringIcon from "../icons/srvc-mntrg.png";

const TopNavigationComponent = ({breakpoints}) => {

    const url = window.location.pathname;
    const [value, setValue] = useState(0);
  
    const navigate = useNavigate();

    useEffect(() => {
        if (url == "/dashboard") {
            setValue(0);

        } else if (url == "/account-management") {
            setValue(1);

        } else if (url == "/user-list") {
            setValue(2);

        } else if (url == "/analytics") {
            setValue(3);

        }
        //  else if (url == "/service-monitoring") {
        //     setValue(4);
        // }      
    },[])
   
    const handleTabChange = (event, newValue) => {
        setValue(newValue);

        let route;
      
        switch (newValue) {
            case 0:
                route = '/dashboard';
                break;
    
            case 1:
                route = '/account-management';
                break;
    
            case 2:
                route = '/user-list';
                break;

            case 3:
                route = '/analytics';
                break;
            
    
            // case 4:
            //     route = '/service-monitoring';
            //     break;
        
            default:
                route = '/dashboard';
        }

        navigate(route);
    }


    return (
        <Box sx={{zIndex: (theme) => theme.zIndex.drawer - 1, width: "100%", position: "fixed", top: 12, boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}>
            <Toolbar />
            
            <BottomNavigation showLabels value={value} onChange={handleTabChange}
                sx={{mt: "-5px", boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}
            >
                <BottomNavigationAction sx={{backgroundColor: (value == 0) ? "#0178f212" : {}}}
                    icon={<img alt="dashboard-icon" src={DashboardIcon} style={{height: 25}} />}  
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 1) ? "#0178f212" : {}}}
                    icon={<img alt="account-management-icon" src={AccountManagementIcon} style={{height: 25}} />}
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 2) ? "#0178f212" : {}}}
                    icon={<img alt="users-icon" src={UsersIcon} style={{height: 25}} />}
                />

                <BottomNavigationAction sx={{backgroundColor: (value == 3) ? "#0178f212" : {}}}
                    icon={<img alt="analytics-icon" src={AnalyticsIcon} style={{height: 25}} />} 
                />
                
                {/* <BottomNavigationAction sx={{backgroundColor: (value == 4) ? "#0178f212" : {}}}
                    icon={<img alt="service-monitoring-icon" src={ServiceMonitoringIcon} style={{height: 25}} />} 
                /> */}
            </BottomNavigation>
        </Box>
    )  
}
  
export default TopNavigationComponent