import { ListItemAvatar, Avatar, Box, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, IconButton, Stack, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import useReports from "../../../hooks/useReports";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { format } from 'date-fns';

import CachedIcon from '@mui/icons-material/Cached';
import EnterpriseProjectReportSkeletonComponent from "../../skeletons/enterprise-project-reports-skeleton.component";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useNavigate } from "react-router-dom";
import ViewProjectReportsPipelinesComponent from "./view-project-report-pipelines.component";



const ReportOneTableHeader = () => {

    const tablecell = [
        { label: 'No. of Authentication' },
        { label: 'Last Date Modified' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportOneTableContent = (props) => {

    const { project, breakpoints, setIsView, setDate } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer', color: "#0178f2" }} onClick={() => [setIsView(true), setDate(project && project?.created_day)]}  >
                {project && project?.total_authenticated_count}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(project && project?.created_day), 'PP')}
            </TableCell>
        </TableRow>
    )
}



const EnterpriseProjectReportsComponent = (props) => {

    const { projectId, project_name } = props;

    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    const { getBusinessById, businessDetails } = useBusiness();
    const { loading, total_authentication, pagination, setLoading, generateReportByEnterpriseProject, enterprise_project_reports } = useReports();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);
    const [is_view, setIsView] = useState(false);
    const [date, setDate] = useState('');


    useEffect(() => {

        if (businessIdFromURL) {
            getBusinessById(businessIdFromURL);
        }

    }, [businessIdFromURL])

    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    useEffect(() => {
        if ((businessIdFromURL) && dateFrom && dateTo) {
            const businessToUse = businessIdFromURL;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");
            generateReportByEnterpriseProject(businessToUse, projectId, date_from, date_to, page);
        }
    }, [businessIdFromURL, dateFrom, dateTo, page]);


    const reload = () => {
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        generateReportByEnterpriseProject(businessToUse, projectId, date_from, date_to, page);
    }


    const handleChangePagination = (event, page) => {
        setPage(page);

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        generateReportByEnterpriseProject(businessToUse, projectId, date_from, date_to, page);
        setLoading(true);
    }

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }



    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);

        const business = project_name;
        const title = `${business} Report as of ${formattedDateRange}`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth) / 100;
        const titleY = 10;

        doc.text(title, titleX, titleY);

        const columns = ["No. of Authentication", "Last Date Modified"];

        const data = enterprise_project_reports.map(item => {
            return [item.total_authenticated_count, item.created_day];
        });

        doc.setFontSize(12);

        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20,
        });

        const overallTotal = total_authentication ? total_authentication : 0;

        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 12;
        const textX = pageWidth - textWidth - 10;
        const textY = doc.autoTable.previous.finalY + 10;

        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

        doc.save(`${project_name}-Document-Report.pdf`);
    };

    const businessToUse = businessIdFromURL;

    return (
        <Box sx={{ width: "93.5%", margin: "auto", mt: 0, mb: 1.5 }}>

            {is_view ?

                <ViewProjectReportsPipelinesComponent passedDataFrom={dateFrom} passedDateTo={dateTo} project_id={projectId} project_name={project_name}  date={date}
                    onClickBackButton={()=>{
                        setIsView(false);
                        reload();
                    }}/>

                :

                <Box>

                    <Grid container sx={{padding: 1, pt: 0}}>
                        <Grid item xs={12} sx={{mt: 0}} >
                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                <Box>
                                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>

                                        <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                <DatePicker
                                                    value={dateFrom}
                                                    required
                                                    views={["year", "month", "day"]}
                                                    inputProps={{ style: { fontSize: 12, height: 38 } }}
                                                    sx={{
                                                        fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                        "& .MuiInputLabel-root": { fontSize: 12 },
                                                        "& .MuiOutlinedInput-root": {
                                                            height: 38,
                                                            borderRadius: 10,
                                                            fontSize: 12,
                                                            "& > fieldset": {
                                                            },
                                                        },
                                                    }}
                                                    onChange={(newValue) => { setDateFrom(newValue) }}
                                                    maxDate={dateTo}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                        &nbsp; &nbsp;

                                        <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                <DatePicker
                                                    value={dateTo}
                                                    required
                                                    views={["year", "month", "day"]}
                                                    inputProps={{ style: { fontSize: 12 } }}
                                                    sx={{
                                                        fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                        "& .MuiInputLabel-root": { fontSize: 12 },
                                                        "& .MuiOutlinedInput-root": {
                                                            height: 38,
                                                            fontSize: 12,
                                                            borderRadius: 10,
                                                            "& > fieldset": {
                                                            },
                                                        },
                                                    }}

                                                    onChange={(newValue) => { setDateTo(newValue) }}
                                                    minDate={dateFrom}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                        &nbsp; &nbsp;

                                        <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                                    </Stack>

                                </Box>


                                <Box sx={{ flexGrow: 1 }} />

                                <Button
                                    variant="contained"
                                    onClick={generatePDF}
                                    sx={{
                                        width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                                        ":hover": { background: "#0178f2" }
                                    }}
                                    disabled={enterprise_project_reports?.length === 0 || enterprise_project_reports === undefined ? true : false}
                                >
                                    Download
                                </Button>
                            </Stack>


                        </Grid>

                    </Grid>

                    <Box sx={{ mt: 1 }} />



                    <Grid container sx={{ padding: 1, mt: 1 }}>
                        <Box sx={{ width: '100%', borderRadius: 0, overflow: 'hidden' }}>

                            <Grid item xs={12}>
                                <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                                        <ReportOneTableHeader />

                                        {loading ?
                                            <EnterpriseProjectReportSkeletonComponent />
                                            :
                                            <TableBody>
                                                {(enterprise_project_reports && enterprise_project_reports)?.map((project, index) => (
                                                    <ReportOneTableContent key={index} project={project} setIsView={setIsView} setDate={setDate} />
                                                ))}


                                            </TableBody>
                                        }
                                    </Table>

                                    {(!loading && (enterprise_project_reports?.length === 0 || enterprise_project_reports === undefined)) ? (

                                        <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                            <Grid container sx={{ padding: 3 }}>
                                                <Box sx={{ textAlign: "center", margin: "auto" }}>
                                                    <Typography sx={{ textAlign: "center" }}>
                                                        No authentication record found for {formattedDateRange}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    ) : (!loading &&
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangePagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />
                                    )}

                                </TableContainer>
                            </Grid>
                        </Box>
                        <br />
                        <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }

        </Box>
    );
};

export default EnterpriseProjectReportsComponent;
