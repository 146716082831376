
import { Box, Card } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import useWidth from "../../hooks/useWidth";



function DashboardGraphComponent(props) {

    const breakpoints = useWidth();

    const { data, names } = props;

    function random_color() {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
      
        for (let i = 0; i < 6; i++) {
          color += letters[Math.round(Math.random() * 15)];
        }
        
        return color;
      }



    return(
        <Box sx={{ p: 2, margin: 'auto', borderRadius: 2, bgcolor:'white', width:'99%'}}>
            <h3 className="section-title">Authentication</h3>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 5, right: 20, left: 20, bottom: 5}} style={{margin: 'auto'}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={'month'} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {
                        names?.map((name) => {
                            return(
                                <Line  type="monotone" dataKey={name} stroke={random_color()} activeDot={{ r: 8 }} />
                            )
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </Box>
    )
    
}

export default DashboardGraphComponent