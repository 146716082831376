import {Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth"
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useState} from "react";
import RolesScreen from "./RolesScreen";
import CreateRoleDialog from "../components/rbac/dialogs/create-role.dialog";
import ViewRoleScreen from "./ViewRoleScreen";
import useRolesManagement from "../hooks/useRolesManagement";
import ForbiddenScreen from "./ForbiddenScreen";


const RolesManagementScreen = () => {

    const breakpoints = useWidth();
    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles, isFetchingRoles,
        enterpriseRoleList, searchRoles, isSearchingRoles, searchedRoles, isSearchRolesToggle, isSearchedResultsEmpty,
        getInheritance, inheritanceChildren, inheritanceRole, error
    } = useRolesManagement();

    const [isView, setView] = useState(false);
    const [openCreateRole, setOpenCreateRole] = useState(false);
    const [role_name, setRoleName] = useState('');
    const [role_id, setRoleId] = useState(null);
    const [dataRole, setDataRole] = useState('');

    const handleOpenCreateRole = () => {
        setOpenCreateRole(true);
    }

    const handleCloseCreateRole = () => {
        setOpenCreateRole(false);
    }


    return (
        <Box sx={{ width:'97%', margin: 'auto' }}>
            { error ?
                <ForbiddenScreen />
            :
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 14 }}>Process of defining, assigning, and regulating roles to control user access and permissions effectively.</Typography>
                </Grid>

                    <Grid item xs={12} sx={{textAlign: 'end', mt: "17px"}}>
                        <Button
                            variant="text"
                            sx={{
                                fontSize: 14,
                                p: '4px 5px',
                                borderRadius: 10,
                                boxShadow: 'none',
                                color: '#0178f2',
                                textTransform: 'none',
                            }}
                            startIcon={<AddBoxIcon sx={{color: '#0178f2' }} />}
                            onClick={handleOpenCreateRole}
                        >
                            Create Role
                        </Button>
                    </Grid>

                <br />
                <br />
                <Grid item xs={12}>
                    {isView ?
                        <ViewRoleScreen 
                            setView={setView} 
                            dataRole={dataRole} 
                            getInheritance={getInheritance}
                            inheritanceChildren={inheritanceChildren}
                            inheritanceRole={inheritanceRole}
                            isLoading={isLoading}
                        />
                    :
                        <RolesScreen 
                            setView={setView} 
                            setDataRole={setDataRole}
                            isLoading={isLoading}
                            getRoles={getRoles} 
                            rolesList={rolesList} 
                            pagination={pagination} 
                            retrieved={retrieved} 
                            getAllRoles={getAllRoles}  
                            isFetchingRoles={isFetchingRoles} 
                            enterpriseRoleList={enterpriseRoleList}  
                            searchRoles={searchRoles} 
                            isSearchingRoles={isSearchingRoles} 
                            searchedRoles={searchedRoles} 
                            isSearchRolesToggle={isSearchRolesToggle} 
                            isSearchedResultsEmpty={isSearchedResultsEmpty} 
                        />
                    }
                </Grid>
            </Grid>
            }

            {openCreateRole &&
                <CreateRoleDialog 
                    openCreateRole={openCreateRole}
                    handleClose={handleCloseCreateRole}
                    getRoles={getRoles}
                    getInheritance={getInheritance}
                    isView={isView}
                    dataRole={dataRole} 
                    retrieved={retrieved} 
                    getAllRoles={getAllRoles} 
                />
            }

        </Box>
    )
}

export default RolesManagementScreen
