import * as React from 'react';
import { Skeleton } from "@mui/material";

function CountsSkeletonComponent(){

    return(
        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: 50, height: 55, margin:'auto'}} />
    )
}

export default CountsSkeletonComponent