import {useState} from "react";
import AdminAccessControlService from "../services/api/admin-rbac";
import {useSnackbar} from "notistack";

const useAdminRBAC = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isFetchingAccessControl, setFetchingAccessControl] = useState(false);
    const [accessControlList, setAccessControlList] = useState([]);
    const [isAddingAccessControl, setAddingAccessControl] = useState(false);
    const [isAccessControlAdded, setAccessControlAdded] = useState(false);
    const [isDeletingAccessControl, setDeletingAccessControl] = useState(false);
    const [isAccessControlRemoved, setAccessControlRemoved] = useState(false);

    var limit = 500;

    const getAdminAccessControl = (page, roleId) => {
        setFetchingAccessControl(true);

        AdminAccessControlService.getAdminAccessControl(roleId, page, limit).then((res) => {
            console.log("getAdminAccessControl data xxxx", res);

            if (res.data.success) {
                setFetchingAccessControl(false);
                setAccessControlList(res.data.data);
            }

        }).catch((error) => {
            console.log("getAdminAccessControl error xxxx", error);
            setFetchingAccessControl(false);
        })
    }

    const addAdminAccessControl = (payload) => {
        setAddingAccessControl(true);
        
        AdminAccessControlService.addAdminAccessControl(payload).then((res) => {
            console.log("addAdminAccessControl data xxxx", res);

            if (res.data.success) {
                setAccessControlAdded(true);
                setAddingAccessControl(false);

                enqueueSnackbar("Successfully added permission", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            setAddingAccessControl(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const removeAdminAccessControl = (roleId, permissionId) => {
        setDeletingAccessControl(true);
        
        AdminAccessControlService.removeAdminAccessControl(roleId, permissionId).then((res) => {
            console.log("removeAdminAccessControl data xxxx", res);

            if (res.data.success) {
                setAccessControlRemoved(true);
                setDeletingAccessControl(false);

                enqueueSnackbar(res.data.data, { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            setDeletingAccessControl(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    return { 
        isFetchingAccessControl, accessControlList, getAdminAccessControl,
        isAddingAccessControl, isAccessControlAdded, addAdminAccessControl,
        isDeletingAccessControl, isAccessControlRemoved, removeAdminAccessControl,
    }
}

export default useAdminRBAC