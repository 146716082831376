import { useEffect, useState } from "react";
import {Grid, Typography, Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useRolesManagement from "../../../hooks/useRolesManagement";
import AccessControlListSkeleton from "../../skeletons/access-control-list-skeleton.component";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";

const TableHeader = () => {
    const tablecell = [
        { label: "Role Name" },
        { label: "Description" },
    ]

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: "#fff", backgroundColor: "#0178f2" }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const PermissionContent = (props) => {
    const { data, setViewAccessControl, setRoleId, setRoleName } = props;

    return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" sx={{ cursor: "pointer", color: "#0178f2" }} onClick={() => [setViewAccessControl(true), setRoleId(data?.id), setRoleName(data?.role)]}>
                {data?.role}
            </TableCell>

            <TableCell component="th" scope="row">
                {data?.description}
            </TableCell>
        </TableRow>
    )
}

const AdminAccessControlComponent = (props) => {

    const { setRoleId, setRoleName, setViewAccessControl } = props;

    const { isLoading, getAdminRoles, rolesList, pagination, retrieved, getAllAdminRoles, error } = useRolesManagement();

    const [page, setPage] = useState(1);

    useEffect(() => {
        getAllAdminRoles();
    }, [])

    useEffect(() => {
        if (retrieved) {
            getAdminRoles(page);
        }
    }, [retrieved, page])

    const handleChangePagination = (event, page) => {
        setPage(page);
        getAdminRoles(page);
    }

    console.log("mayee rolesList xxxx", rolesList)

    return (
        <Box sx={{ width: "100%", margin: "auto" }}>
            {error === "Forbidden" ?
                <ForbiddenScreen />
                :

                <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 2 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                        <TableHeader />

                        {(isLoading) ?
                            <TableBody>
                                <AccessControlListSkeleton />
                            </TableBody>
                        :
                            <TableBody sx={{width: "100%"}}>
                                {(rolesList?.length > 0) ?
                                    (rolesList.map((data, index) => (
                                        <PermissionContent
                                            data={data}
                                            index={index}
                                            setViewAccessControl={setViewAccessControl}
                                            setRoleId={setRoleId}
                                            setRoleName={setRoleName}
                                        />
                                    )))
                                :
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Box sx={{padding: 2, width: "100%", borderRadius: 0, overflow: "hidden"}}>
                                                <Grid container sx={{ padding: 3 }}>
                                                    <Box sx={{textAlign: "center", margin: "auto"}}>
                                                        <Typography sx={{fontSize: "14px"}}>
                                                            No available access control record(s) to display yet
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>   
                        }
                    </Table>

                    {!isLoading && rolesList?.length !== 0 &&
                        <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                        />
                    }
                </TableContainer>
            }
        </Box>
    )
}

export default AdminAccessControlComponent