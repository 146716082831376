import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Grid, Button, ListItem, ListItemText, IconButton, CssBaseline, Toolbar, Avatar, Box, ListItemAvatar, Typography, Skeleton} from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import AdminProfilePhoto from "../icons/admin-profile.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppLogo from '../icons/new-ovcode-logo.png';
import Sidebar from "./sidebar";
import DrawerHeader from './drawer-header';
import AccountPopperComponent from "./account-popper.component";
import useUsers from "../hooks/useUsers";
import useWidth from "../hooks/useWidth";
import TopNavigationComponent from './top-navigation.component';
import { useEffect } from 'react';

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const Navbar = ({children}) => {

  const breakpoints = useWidth();

  const { loading, admin, getAdmin , error} = useUsers();

  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerClose = () => { setOpen(false); };

  const handleOpenMenu = (event) => { setAnchorEl(event.currentTarget); };

  const handleCloseMenu = () => { setAnchorEl(null); };

  useEffect(()=>{ getAdmin(); },[])

  function stringAvatar(name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return {
        children: `${( (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '') ).toUpperCase()}`,
      };
  }



  // Check if admin object and identities array exist
  const email = admin && admin?.identities[0]?.issuerAssignedId || '';

  // Extract the part before '@' symbol
  const displayName = email.split('@')[0];


  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />

      <AppBar position="fixed" open={open} sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? "100%" : {}, backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
        <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}, paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}>
          
          {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
            <IconButton edge="start" color="inherit" aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{mr: 5, ...(open && {display: 'none'})}}
            >
              <MenuIcon sx={{color: "black"}} />
            </IconButton>
          }

          <Button sx={{cursor: "default", ...(open && {display: 'none'}), ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"})}}>
            <img alt="ovcode-admin-logo" src={AppLogo} style={{cursor: "default", height: (breakpoints == 'xs' || breakpoints == 'sm') ? 70 : 60}} />
          </Button>

          <ListItem sx={{ml: "auto", width: "auto"}}>
            <ListItemAvatar sx={{minWidth: 0}}>
              {(breakpoints == 'xs' || breakpoints == 'sm') ? 
                <Grid>
                  <IconButton
                    aria-label="dropdown-menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                  >
                    {loading ? 
                      <Skeleton variant="circular" width={40} height={40}  sx={{backgroundColor: 'rgb(108 139 222 / 30%)'}}  />
                    :
                    <>
                    {error === 'Forbidden' ?
                      <Avatar style={{width: 40, height: 40, backgroundColor: '#007fff', fontSize: 16}} />
                      :
                      <Avatar {...stringAvatar(admin && displayName)} style={{cursor: "pointer", width: 40, height: 40, backgroundColor: '#007fff', fontSize: 16}} />
                    }
                    </>
                    }
                    </IconButton>
                  
                  {/* for account popper menus */}
                  <AccountPopperComponent anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} /> 
                </Grid>

              :
                <>
                  {loading ? 
                    <Skeleton variant="circular" width={40} height={40} sx={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                  :
                    <>
                      {error === 'Forbidden' ?
                        <Avatar style={{width: 40, height: 40, backgroundColor: '#007fff', fontSize: 16}} />
                      :
                        <Avatar {...stringAvatar(admin && displayName)} style={{width: 40, height: 40, backgroundColor: '#007fff', fontSize: 16}} />
                      }
                    </>
                  }
                </>
                
              }
            </ListItemAvatar>

            {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
              <ListItemText 
                secondary={
                  loading ? 
                    null
                  :
                    <Typography variant="caption" sx={{color: "#000", margin: "3px 0px 0px 8px", textAlign: "justify", fontSize: 14}}>
                      {admin && displayName}
                    </Typography>
                }
              />
            }
             
            {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
              <Grid>
                <IconButton
                  aria-label="dropdown-menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenMenu}
                >
                  <ExpandMoreIcon fontSize="large" />
                </IconButton>

                {/* for account popper menus */}
                <AccountPopperComponent anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} /> 
              </Grid> 
            } 
          </ListItem>
        </Toolbar>
      </AppBar>

      {/* for left drawer */}
      {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      }

      {(breakpoints == 'xs' || breakpoints == 'sm') && 
        <TopNavigationComponent breakpoints={breakpoints} />
      }
      
      <Box component="main" sx={{flexGrow: 1, p: breakpoints == 'xs' ? 1 : 3 , pt: 3, width: '100%'}}>
        <DrawerHeader /> 
        {(breakpoints == 'xs' || breakpoints == 'sm') &&  <DrawerHeader /> }  
        {children}   
      </Box>
    </Box>
  )

}

export default Navbar

