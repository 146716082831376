import environment from './environment/environment'

export const msalconfig={
    auth:{
        clientId:environment.msal_authConfig.clientId,
        redirectUri: environment.msal_url.url,
        postLogoutRedirectUri: environment.msal_url.url,
        knownAuthorities: environment.msal_authConfig.knownAuthorities,
        authority: environment.msal_authConfig.authority,
        changePasswordUri: environment.msal_change_password_url
    }
};
export const loginRequest = {
    scopes:environment.b2c_scope.scopes
};