import {useState, useEffect} from "react";
import {Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from '@mui/icons-material';
import useWebhook from "../../hooks/useWebhook";
import {ThreeDots } from 'react-loader-spinner';
import InfoIcon from '@mui/icons-material/Info';
import DeleteWebhookDialog from "./dialog/delete-webhook.dialog";

const WebhooksComponent = (props) => {

    const {webhook, sendDataToParent, projectId, sendDataToParentFromChild} = props;

    const {created, loading, addWebhook, updated, updateWebhook} = useWebhook();

    const businessId = (new URLSearchParams(window.location.search)).get("id");
    let id = +businessId;


    const [showSecretKey, setSecretKey] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [callback_url, setCallbackUrl] = useState(webhook?.callback_url);
    const [content_type, setContentType] = useState("");
    const [secret, setSecret] = useState(webhook?.secret);

    const [isUrlRequired, setUrlRequired] = useState(false);
    const [isSecretRequired, setSecretRequired] = useState(false);

    const handleClickShow = (e) => {setSecretKey(!showSecretKey);} 

    const handleOpenDeleteDialog = (e) => {setOpenDelete(true);}

    const handleCloseDeleteDialog = () => {setOpenDelete(false);}

    const handleChangeCallbackUrl = (event) => {
        setCallbackUrl(event.target.value);
        setUrlRequired(false);
    }

    const handleChangeContentType = (event) => {setContentType(event.target.value)}

    const handleChangeSecret = (event) => {
        setSecret(event.target.value);
        setSecretRequired(false);
    }

    const handleAddWebhook = () => {

        let payload = {
            project_id: projectId,
            business_id: id,
            callback_url: callback_url,
            secret: secret
        }

        if (!callback_url) {
            setUrlRequired(true);

        } else if (!secret) {
            setSecretRequired(true);

        } else {
            setUrlRequired(false);
            setSecretRequired(false);

            addWebhook(payload);
        }
    }

    const handleUpdatedWebhook = () => {

        let payload = {
            callback_url: callback_url,
            secret: secret
        }

        updateWebhook(projectId, businessId, payload);
    }

    useEffect(() => { 
        if (created) {
            sendDataToParent(true);
        } 
    }, [created])

    useEffect(() => {
        if (updated) {
            sendDataToParent(true);
        }
    }, [updated])


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography>
                    Callback URL:
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{mb: "5px"}}>
                <TextField  
                    fullWidth 
                    variant="outlined"  
                    autoComplete="off" 
                    placeholder="URL"  
                    value={callback_url}
                    onChange={handleChangeCallbackUrl}
                    InputProps={{style: {height: "45px"}}}
                    helperText={
                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                            {(isUrlRequired) && "URL is required"}
                        </p>
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Typography>
                    Content Type:
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{mb: "5px"}}>
                <FormControl fullWidth style={{height: 45}}>
                    <InputLabel id="demo-simple-select-label">
                        Select
                    </InputLabel>

                    <Select
                        sx={{height: "45px"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="ovcode plan label"
                        value={webhook?.content_type ? webhook?.content_type : "application/json"}
                        onChange={handleChangeContentType}
                    >
                        <MenuItem value="application/json">
                            application/json
                        </MenuItem>   
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography>
                    Request Header (<strong>x-api-secret</strong>) 
                    <Tooltip title={
                        <Typography sx={{fontSize: 12}} >
                            Warning: This field contains a sensitive X API Secret key for your webhook. Please ensure that you do not share this key with anyone who is not authorized to access your webhook. This key should be kept confidential and secure at all times.
                        </Typography>
                    } placement="top-start" sx={{color:'#0178f2'}} >
                        <IconButton>
                            <InfoIcon sx={{alignSelf:'center', color:'#0178f2'}} />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{mb: "5px"}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Secret Key"
                    type={(showSecretKey) ? "text" : "password"}
                    value={secret}
                    onChange={handleChangeSecret}
                    InputProps={{
                        style: {height: "45px"},
                        endAdornment: (
                            <InputAdornment position="end" style={{marginLeft: -29}}>
                                <IconButton onClick={(e) => handleClickShow(e)} edge="end" >
                                    {(showSecretKey) ? 
                                        <Tooltip title="Hide" placement="top" arrow>
                                            <Visibility fontSize="small" /> 
                                        </Tooltip>
                                    :
                                        <Tooltip title="Show" placement="top" arrow>
                                            <VisibilityOff fontSize="small" />
                                        </Tooltip>
                                    } 
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    helperText={
                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                            {(isSecretRequired) && "Secret Key is required"}
                        </p>
                    } 
                />
            </Grid>

            {webhook || created ?
                <Grid item xs={12} sx={{textAlign: "end", alignSelf: "center"}}>
                        <Button sx={{color: "red", textTransform: "none", mr: 1}} onClick={(e) => handleOpenDeleteDialog(e)}>
                            Delete
                        </Button>

                        <Button  
                            variant="contained"  
                            sx={{ml: 1, backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", "&:hover": {backgroundColor: "#0178f2", color: "white"}}} 
                            onClick={(e) => handleUpdatedWebhook()}
                            startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                        >
                            {loading ? 'Updating' : 'Update Webhook'}
                        </Button>
                </Grid>
            
            :
                <Grid item xs={12} sx={{textAlign: "end", alignSelf: "center"}}>
                    <Button  
                        variant="contained"  
                        sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", "&:hover": {backgroundColor: "#0178f2", color: "white"}}} 
                        onClick={(e) => handleAddWebhook()}
                        startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                    >
                        {loading ? 'Creating' : 'Add Webhook'}
                    </Button>
                </Grid>
            }

            {openDelete &&
                <DeleteWebhookDialog 
                    openDelete={openDelete} 
                    webhook={webhook} 
                    handleCloseDeleteDialog={handleCloseDeleteDialog} 
                    sendDataToParentFromChild={sendDataToParentFromChild} 
                    sendDataToParent={sendDataToParent}
                    projectId={projectId}
                    businessId={id}
                />
            } 
        </Grid>
    ) 
}

export default WebhooksComponent