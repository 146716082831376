import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch, HttpPut1, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getPermissions = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const getPermissionsFunction = (page, limit, moduleId) => {

    let query_params = {
        page : page,
        limit : limit,
        moduleId : moduleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const getAllPermissionsFunction = (limit, moduleId) => {

    let query_params = {
        limit: limit,
        moduleId : moduleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const createPermission = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_PERMISSION, payload , subscriptionKey);

    return result;
    
}

const updatePermission = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_PERMISSION, query_params, payload , subscriptionKey);


    return result;
    
}

const deletePermission = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_PERMISSION, query_params, subscriptionKey);
    
    return result;
}

const getModules = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_MODULES, query_params , subscriptionKey);

    return result;
    
}

const createModule = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_MODULE, payload , subscriptionKey);
  
    return result;
}

const updateModule = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_MODULE, query_params, payload , subscriptionKey);


    return result;
    
}

const deleteModule = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_MODULE, query_params, subscriptionKey);
    
    return result;
}

const searchPermissions = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_PERMISSIONS, query_params, subscriptionKey);

    return result;
}

const getAdminPermissions = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const getAdminPermissionsFunction = (page, limit, moduleId) => {

    let query_params = {
        page : page,
        limit : limit,
        moduleId : moduleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const getAllAdminPermissionsFunction = (limit, moduleId) => {

    let query_params = {
        limit: limit,
        moduleId : moduleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_PERMISSIONS, query_params , subscriptionKey);

    return result;
    
}

const createAdminPermission = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_ADMIN_PERMISSION, payload , subscriptionKey);

    return result;
    
}

const updateAdminPermission = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ADMIN_PERMISSION, query_params, payload , subscriptionKey);


    return result;
    
}

const deleteAdminPermission = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_ADMIN_PERMISSION, query_params, subscriptionKey);
    
    return result;
}

const getAdminModules = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_MODULES, query_params , subscriptionKey);

    return result;
    
}

const createAdminModule = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_ADMIN_MODULE, payload , subscriptionKey);
  
    return result;
}

const updateAdminModule = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ADMIN_MODULE, query_params, payload , subscriptionKey);


    return result;
    
}

const deleteAdminModule = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_ADMIN_MODULE, query_params, subscriptionKey);
    
    return result;
}



const PermissionManagementService = {
    getPermissions,
    getPermissionsFunction,
    getAllPermissionsFunction,
    createPermission,
    updatePermission,
    deletePermission,
    getModules,
    createModule,
    updateModule,
    deleteModule,
    searchPermissions,
    getAdminPermissions,
    getAdminPermissionsFunction,
    getAllAdminPermissionsFunction,
    createAdminPermission,
    updateAdminPermission,
    deleteAdminPermission,
    getAdminModules,
    createAdminModule,
    updateAdminModule,
    deleteAdminModule,
}

export default PermissionManagementService