import React, { useEffect, useState } from "react";
import {
  Skeleton,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccessControlService from "../../../services/api/admin-rbac";
import useAdminRBAC from "../../../hooks/useAdminRBAC";
import usePermissionManagement from "../../../hooks/usePermissionManagement";

const AdminViewAccessControlScreen = (props) => {

  const { setViewAccessControl, roleId, roleName } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { accessControlList, getAdminAccessControl } = useAdminRBAC();
  const { loading, getAdminModules, modules, functions, getAllAdminPermissionsFunction } = usePermissionManagement();

  useEffect(() => {
    getAdminAccessControl(1, roleId);
    getAdminModules();
  }, [roleId]);

  useEffect(() => {
    getAllAdminPermissionsFunction(modules?.id);
  }, [modules]);

  const handlePermissionChange = async (moduleId, functionId, newIsAllowed, currentIsAllowed, accessControlId) => {
    const existingAccess = accessControlList.find(
      (ac) =>
        ac.permission.module.id === moduleId &&
        ac.permission.id === functionId
    );

    try {
      if (existingAccess) {
        removeAdminAccessControl(roleId, existingAccess?.permission?.id);
      } else {
        let payload = {
          role_id: roleId,
          permission_id: functionId,
          is_allowed: 'true'
        };

        await addAdminAccessControl(payload);
      }

      // Refresh data after update
      getAdminAccessControl(1, roleId);

    } catch (error) {
      console.error("Error managing access control:", error);
    }
  }

  const addAdminAccessControl = (payload) => {
    AccessControlService.addAdminAccessControl(payload)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Successfully added role access permission", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.error, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  }

  const removeAdminAccessControl = (roleId, permissionId) => {
    AccessControlService.removeAdminAccessControl(roleId, permissionId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Successfully removed role access permission", {
            variant: "success",
            autoHideDuration: 3000
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  }

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <Button
        startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
        style={{ color: '#000', fontSize: 18, textTransform: 'none', fontWeight: 600 }}
        onClick={() => setViewAccessControl(false)}
      >
        {roleName}
      </Button>

      <Box sx={{ mt: 2 }} />

      <Grid container>
        <Grid item xs={12}>
          {modules.map((moduleData, moduleIndex) => (
            <div key={moduleIndex} style={{ padding: 2 }}>
              <Card sx={{ padding: 3, borderRadius: 2, mb: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                <CardHeader
                  title={loading ?
                    <Skeleton variant="text" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={28} />
                    :
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {moduleData?.module_name}
                    </Typography>
                  }
                />

                <CardContent>
                  {functions
                    .filter(functionData => functionData.module.id === moduleData.id)
                    .map((functionData) => (
                      <FormControlLabel
                        key={functionData.id}
                        control={
                          <Checkbox
                            checked={
                              accessControlList.some(
                                (ac) =>
                                  ac.permission.module.id === moduleData.id &&
                                  ac.permission.id === functionData.id &&
                                  ac.is_allowed
                              )
                            }
                            onChange={(event) => {
                              const newIsAllowed = !event.target.checked;
                              const existingAccess = accessControlList.find(
                                (ac) =>
                                  ac.permission.module.id === moduleData.id &&
                                  ac.permission.id === functionData.id &&
                                  ac.is_allowed === !newIsAllowed
                              );

                              handlePermissionChange(moduleData.id, functionData.id, newIsAllowed, existingAccess?.is_allowed, existingAccess?.id);
                            }}
                            disabled={roleId === 1 && (functionData.fn === 'LIST_ACCESS_CONTROL' || functionData.fn === 'ADMIN_LIST_ACCESS_CONTROL')}
                          />
                        }
                        label={functionData?.fn}
                      />
                    ))}
                </CardContent>
              </Card>
            </div>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminViewAccessControlScreen;
