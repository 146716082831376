import routes from "../common/api-routes";
import environment from "../../../environment/environment";
import {HttpGet, HttpCustomPost, HttpPost} from "../common/HttpRequestBuilder";


const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const retrieveAdminSigninLogs = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SIGN_IN_LOGS_ADMIN, query_params, subscriptionKey);
    
    return result;
}

const retrieveUserSigninLogs = (oid) => {

    let query_params = {
        oid: oid
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.USER_SIGN_IN_LOGS, query_params, subscriptionKey);
    
    return result;
}

const retrieveSigninLogs = (email,limit) => {

    let query_params = {
        email: email,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SIGN_IN_LOGS, query_params, subscriptionKey);
    
    return result;
}

const retrieveAllSigninLogs = (email) => {

    let query_params = {
        email: email
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SIGN_IN_LOGS, query_params, subscriptionKey);
    
    return result;
}

const retrieveSigninLogsPagination = (email,limit, nextToken) => {

    let query_params = {
        email: email,
        limit: limit,
        nextToken: nextToken
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SIGN_IN_LOGS, query_params, subscriptionKey);
    
    return result;
}

const retrieveAdminSigninLogsPagination = (limit, nextToken) => {

    let query_params = {
        limit: limit,
        nextToken: nextToken
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SIGN_IN_LOGS_ADMIN, query_params, subscriptionKey);
    
    return result;
}

const retrieveUserSigninLogsPagination = (oid,limit, nextToken) => {

    let query_params = {
        oid: oid,
        limit: limit,
        nextToken: nextToken
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.USER_SIGN_IN_LOGS, query_params, subscriptionKey);
    
    return result;
}

const getBusinessAuditsLogs = (businessId, page, limit) => {

    let query_params = {
        businessId: businessId,
        page: page, 
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}


const getAdminActivityLogs = (page, limit) => {

    let query_params = {
        page: page, 
        limit : limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_ACTIVIY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const filterAdminLogsByBusiness = (businessId, page) => {

    let query_params = {
        businessId: businessId,
        page: page, 
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const filterAdminLogsByBusinessAndEmail = (businessId, email, page) => {

    let query_params = {
        businessId: businessId,
        email: email,
        page: page, 
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost(routes.GET_ADMIN_BUSINESS_USER_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const filterAdminLogsByProject = (businessId, projectId, page) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        page: page, 
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_PROJECT_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const filterAdminLogsByProjectAndEmail = (businessId, projectId, email, page) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        email: email,
        page: page, 
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost(routes.GET_ADMIN_PROJECT_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}
const filterAdminLogsByUser = (businessId, userId, page) => {

    let query_params = {
        businessId: businessId,
        userId: userId,
        page: page, 
    };

    console.log("filterAdminLogsByUser query_params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_USER_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}


const businessSearchActivityLogsFilters = (businessId, month, year, module, action, page) => {
    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        page: page
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const searchBusinessAuditLogs = (searchLogs) => {

    let payload = searchLogs;


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost(routes.SEARCH_BUSINESS_ACTIVITY_LOGS, payload, subscriptionKey);

    return result;
}

const searchBusinessActivityLogsList = (businessId, month, year, module, action, page, searchLogs) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        page: page, 
    };

    let payload = searchLogs;


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpCustomPost(routes.SEARCH_BUSINESS_ACTIVITY_LOGS, query_params, payload, subscriptionKey);

    return result;
}

const businessSearchEmailFilters = (businessId, userId, page) => {
    
    let query_params = {
        businessId: businessId,
        userId: userId,
        page: page,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_BUSINESS_USER_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const getEnterpriseProjectAuditLogs = (businessId, projectId, page) => {
    
    let query_params = {
        businessId: businessId,
        projectId: projectId,
        page: page,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ENTERPRISE_PROJECT_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const enterpriseProjectSearchActivityLogsFilters = (businessId, projectId, month, year, module, action, page) => {
    let query_params = {
        businessId: businessId,
        projectId: projectId,
        month: month,
        year: year,
        module: module,
        action: action, 
        page: page, 
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ENTERPRISE_PROJECT_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const searchEnterpriseProjectActivityLogsList = (businessId, projectId, month, year, module, action, page, searchLogs) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        month: month,
        year: year,
        module: module,
        action: action, 
        page: page, 
    };

    let payload = searchLogs;


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpCustomPost(routes.SEARCH_ENTERPRISE_PROJECT_ACTIVITY_LOGS, query_params, payload, subscriptionKey);

    return result;
}

const adminSearchEmailFilters = (userId, page) => {
    
    let query_params = {
        userId: userId,
        page: page,
    };

    console.log("query param here :", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const adminSearchActivityLogsFilters = (month, year, module, action, page) => {
    let query_params = {
        // businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        page: page
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}

const AuditService = {
    retrieveSigninLogs,
    retrieveSigninLogsPagination,
    retrieveAllSigninLogs,
    getBusinessAuditsLogs,
    searchBusinessAuditLogs,
    searchBusinessActivityLogsList,
    businessSearchActivityLogsFilters,
    businessSearchEmailFilters,
    getEnterpriseProjectAuditLogs,
    enterpriseProjectSearchActivityLogsFilters,
    searchEnterpriseProjectActivityLogsList,
    filterAdminLogsByBusiness,
    filterAdminLogsByBusinessAndEmail,
    filterAdminLogsByProject,
    filterAdminLogsByProjectAndEmail,
    filterAdminLogsByUser,
    getAdminActivityLogs,
    retrieveAdminSigninLogs,
    retrieveUserSigninLogs,
    retrieveAdminSigninLogsPagination,
    retrieveUserSigninLogsPagination,
    adminSearchEmailFilters,
    adminSearchActivityLogsFilters
}

export default AuditService