import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth"
import useUsers from "../hooks/useUsers";
import { ThreeDots } from 'react-loader-spinner';
import useRolesManagement from "../hooks/useRolesManagement";
import useProjects from "../hooks/useProjects";
import UserPasswordDialog from "../components/account/dialogs/user-password.dialog";
import ViewBusinessAccountUsersComponent from "../components/account/view-business-account-users.component";


const AddEnterpriseUserScreen = () => {

    const breakpoints = useWidth();

    const { isCreatingUser, created, user_password, addUser, getUsersByBusinessAccountID, userAccounts, loadingUserList } = useUsers();
    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles } = useRolesManagement();
    const { getProjectList, projects, loadingProjects } = useProjects();

    let businessID = (new URLSearchParams(window.location.search)).get("id");


    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [project_id, setProjectId] = useState("");
    const [open, setOpen] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [isNewUser, setIsNewUser] = useState(userAccounts?.length !== null ? false : true);

    console.log("projectxxx", projects)

    useEffect(() => {
        getAllRoles();
    }, [])

    useEffect(() => {
        getProjectList(businessID);
        getUsersByBusinessAccountID(businessID);
    }, [businessID])


    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(true);
        setEmptyEmail(false);
    }

    const handleSubmit = (e) => {

        let payload = {
            email: email,
            role: role,
            project_id: project_id
        }

        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            setEmptyEmail(false);
            setValidEmail(true);
            addUser(businessID, payload);
        }
    }

    const handleCloseUserPassword = (e) => {
        setOpen(false);
    }

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    }

    const handleChangeProject = (event) => {
        setProjectId(event.target.value);
    }

    const handleCloseAllDialogs = (e) => {
        handleCloseUserPassword();
        // window.location.reload();
        // window.location.href = "/view?id=" + businessID
    }

    useEffect(() => {
        if (created) {
            setOpen(true);
            setIsNewUser(false);
        }
    }, [created])


    return (
        <Box>
            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 18 }}>
                        Add User and Role
                    </Typography>

                </Grid>
            </Grid>

            {isNewUser ? (
                <Card sx={{ width: "75%", border: "none", borderRadius: 2, margin: "15px auto", mt: 5, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <CardContent>
                        <Grid container spacing={2} sx={{ width: "90%", margin: "auto", mt: 3, mb: 1 }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Email :</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            autoComplete="off"
                                            placeholder="Email"
                                            value={email}
                                            onChange={handleChangeEmail}
                                            helperText={
                                                <p style={{ fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px" }}>
                                                    {
                                                        (emptyEmail) ? "Email is required, please input email"
                                                            :
                                                            (!validEmail) ? "Email is invalid, please input valid email"
                                                                :
                                                                ""
                                                    }
                                                </p>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Select Role :</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={role}
                                                onChange={handleChangeRole}
                                            >
                                                {rolesList?.map((data, index) => (
                                                    <MenuItem key={data?.id} value={data?.role}>
                                                        {data?.role}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {[
                                "ProjectManager",
                                "ProjectQALead",
                                "ProjectAdministrator",
                                "ProjectOfficer",
                                "ProjectSupport",
                                "ProjectBillingSpecialist",
                                "ProjectAuditor"
                            ].includes(role) && (
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{ fontWeight: 'bold' }}>Select Project :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={project_id}
                                                        onChange={handleChangeProject}
                                                    >
                                                        {projects?.length === 0 && (
                                                            <MenuItem value={0} disabled>
                                                                No available projects
                                                            </MenuItem>
                                                        )}
                                                        {projects?.map((data, index) => (
                                                            <MenuItem key={data?.id} value={data?.id}>
                                                                {data?.application_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                            <Grid item xs={12} sx={{ mt: 4, mb: 4, ml: 'auto', textAlign: 'end' }}>
                                <Button variant="contained" onClick={(e) => handleSubmit(e)}
                                    startIcon={isCreatingUser &&
                                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                                    }
                                >
                                    {isCreatingUser ? "Saving" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Box sx={{ width: "90%", margin: 'auto' }}>
                    <ViewBusinessAccountUsersComponent />
                </Box>
            )}

            {userAccounts && !isNewUser &&
                <Grid container spacing={2} sx={{ width: "95%", ml: "auto", mt: 2, mb: 0, textAlign: 'end', mr: 30 }}>
                    <Grid item xs={12} >
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                            onClick={() => window.location.href = "/view-enterprise-detials?id=" + businessID}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            }


            {open &&
                <UserPasswordDialog password={user_password} openDialog={open} handleClose={handleCloseAllDialogs} />
            }

        </Box>
    )
}

export default AddEnterpriseUserScreen