import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import useWidth from "../hooks/useWidth";
import PackageManagementTabsComponent from "../components/package-management/package-management-tabs.component";

const PackageManagementScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            <Grid container>
                <Grid item xs={4} sm={4}>
                    <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                        Manage Plans for Enterprise Accounts
                    </Typography>
                </Grid>
            </Grid>
            <Card sx={{ borderRadius: 2, padding: "8px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: "25px auto", width: "95%" }}>
                <PackageManagementTabsComponent breakpoints={breakpoints} />
            </Card>
        </Box>
    )
}

export default PackageManagementScreen