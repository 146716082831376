import { useState } from 'react';
import AdminRbacRolesManagementInheritanceService from '../services/api/admin-rbac/roles-management/inheritance';

function useAdminRbacRolesManagementInheritance() {
  
  const [data, setData] = useState();
  const [roles, setRoles] = useState([]);

  const getInheritanceList = (roleId) => {
    AdminRbacRolesManagementInheritanceService.getInheritanceList(roleId).then(res=> {
      console.log("res of getInheritanceList: ", res)
        setRoles(res);
    });
  };

  const removeRoleInheritance = (parentRoleId, roleId) => {
    AdminRbacRolesManagementInheritanceService.removeRoleInheritance(parentRoleId, roleId).then(res=> {
        console.log("res of removeRoleInheritance: ", res)
        setData(res);
    });
  };

  const addInheritance = (parent_role_id, role_id) => {
    AdminRbacRolesManagementInheritanceService.addInheritance(parent_role_id, role_id).then(res=> {
        console.log("res of addInheritance: ", res)
        setData(res);
    });
  };

  return {roles, data, getInheritanceList, removeRoleInheritance, addInheritance} ;

}

export default useAdminRbacRolesManagementInheritance;