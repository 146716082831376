import {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Grid} from "@mui/material";
import ChangeSignatureTabs from "./change-element-signature-tabs.component";
import useWidth from "../../../hooks/useWidth";
import CloseIcon from '@mui/icons-material/Close';
import ChangeElementSignatureTabs from "./change-element-signature-tabs.component";

const ChangeSignatureDialogComponent = (props) => {

    const {openChangeSignature, handleClose, sendDataToParent, setIsSignatureUpdatedToParent, setNewSignatureSendToParent,
        setUserSignatureUpdated, currentSelectedUserId, currentESignatureB2CId, onSelectUser} = props;

    const breakpoints = useWidth();

    console.log("currentSelectedUserId aa111", currentSelectedUserId);

    const [isLinkTab, setLinkTab] = useState(false);


    const sendTabLabelToParent = (child) => {
        setLinkTab(child);
    } 

    return (
        <Dialog open={openChangeSignature} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5} }}>
            <DialogTitle style={{display: "flex"}}>
                Change Electronic Signature

                <Grid sx={{ml: "auto"}}>
                    {isLinkTab && <CloseIcon fontSize="small" onClick={handleClose} sx={{cursor: "pointer"}} />}
                </Grid>
            </DialogTitle>

            <Divider />
            
            <DialogContent sx={{padding: 3, paddingTop: 2}}>
               <ChangeSignatureTabs
                    handleClose={handleClose} 
                    sendDataToParent={sendDataToParent} 
                    breakpoints={breakpoints} 
                    sendTabLabelToParent={sendTabLabelToParent}
                    setIsSignatureUpdatedToParent={setIsSignatureUpdatedToParent}
                    setNewSignatureSendToParent={setNewSignatureSendToParent}
                    setUserSignatureUpdated={setUserSignatureUpdated}
                    currentSelectedUserId={currentSelectedUserId}
                    currentESignatureB2CId={currentESignatureB2CId}
                    onSelectUser={onSelectUser}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ChangeSignatureDialogComponent