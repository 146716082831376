import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, InputAdornment, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import TemplateListSkeletonComponent from "../components/skeletons/template-list-skeleton.component";
import TemplateListComponent from "../components/template-management/template-list.component";
import useTemplates from "../hooks/useTemplates";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import EnterpriseTemplateListSkeletonComponent from "../components/skeletons/enterprise-templates-skeleton.component";
import { useNavigate } from "react-router-dom";

const searchForOptions = [
    { value: "title", label: "Title" },
    { value: "status", label: "Status" },
    { value: "templateCode", label: "Template Code" }
]

const statusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "DRAFT", label: "DRAFT" }
]


function TemplateHeader(props) {
    return (
        <Box sx={{ padding: 1, width: '100%', borderRadius: 0 }}>
            <Grid container sx={{ textAlign: 'start', marginLeft: 2 }}>
                <Grid item xs={8} sm={8}>
                    <Typography variant='h6'>
                        Templates
                    </Typography>
                </Grid>

                {/* {(listTemplate?.length !== 0 && canCreateTemplate) ?
                    <Grid item xs={4}  sm={4} sx={{textAlign: 'end', marginLeft: -5}}>
                        <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} onClick={() => window.location.href = '/add-project-template?projectId=' + projectId }>Add Template</Button> 
                    </Grid>
                :
                    <></>
                } */}

            </Grid>
        </Box>
    )
}

function TableHeader() {

    const tablecell = [
        { label: 'Title' },
        { label: 'Template Code' },
        { label: 'Status' },
        { label: 'Total Page' },
        { label: 'With Certificate' },
        { label: 'Last Date Modified' },
        { label: 'Action' },
    ]

    return (
        <TableHead >
            <TableRow >
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const TemplateListScreen = (props) => {

    const { projectId, project_name } = props;

    console.log("projectIdxxxx", projectId)
    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    const { isFetchingEnterpiseAccountTemplate, getEnterpriseProjectAccountTemplates, enterpriseAccountTemplates, pagination, setFetchingEnterpriseAccountTemplate, filterEnterpriseProjectTemplate } = useTemplates();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [searchTemplateVal, setSearchTemplateVal] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isStatusDropdownCleared, setStatusDropdownCleared] = useState(false);

    useEffect(() => {
        getEnterpriseProjectAccountTemplates(projectId, page);
    }, [])

    const handleChangePagination = (event, page) => {
        setPage(page);
        setFetchingEnterpriseAccountTemplate(true);
        getEnterpriseProjectAccountTemplates(projectId, page);
    }


    const reload = () => {
        setSearchFor('title')
        setSearchForLabel(null)
        getEnterpriseProjectAccountTemplates(projectId, page);
        setSearchTemplateVal('')
        setSearchStatusVal(null)
        setSelectedProject(null)
        setStatusDropdownCleared(true);
    }

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
            setStatusDropdownCleared(false);

        }
        else {
            setSearchForLabel(null);
            setSearchFor(null);
            setSelectedProject(null);
            setSearchTemplateVal(null);
            setSearchStatusVal(null);
            setStatusDropdownCleared(true);
            getEnterpriseProjectAccountTemplates(projectId, page);

        }
    }

    const handleTextfield = (e) => {
        setSearchTemplateVal(e.target.value);
        setSearchStatusVal(null);
    }

    const handleSelectStatusValue = async (selectedOption) => {
        setSearchStatusVal(selectedOption.label);
        setPage(1)
        if (selectedOption) {
            await filterEnterpriseProjectTemplate(searchFor, selectedOption.value, projectId, page);
        }
    }


    const handleKeyPress = (e) => {
        console.log('keeeeeeeeeeeey', e)
        if (e.keyCode === 13) {
            if (selectedProject) {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseProjectTemplate(searchFor, searchVal, projectId, page);
                }

            } else {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseProjectTemplate(searchFor, searchVal, projectId, page);
                } else {
                    getEnterpriseProjectAccountTemplates(projectId, page);
                }
            }
        } else if (e.key === 'Backspace' && e.target.value === ' ') {
            getEnterpriseProjectAccountTemplates(projectId, page);
        }
    }

    console.log("enterpriseAccountTemplates here:", enterpriseAccountTemplates)

    return (
        <Box sx={{ pt: "8px !important", width: '100%', borderRadius: 0, overflow: 'hidden', pb: "8px !important" }}>

            {/* <TemplateHeader /> */}

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Box className="card-search" sx={{ textAlign: "flex-start", paddingLeft: 2, alignSelf: "center", mr: "6px" }}>
                        <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>
                            {(isStatusDropdownCleared) ?
                                <>
                                    <ReactSelect
                                        className="basic-single"
                                        value={"Select Filter"}
                                        placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Select Filter</i></>}
                                        isSearchable={true}
                                        isRtl={false}
                                        options={searchForOptions}
                                        onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                width: 250,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                            }),
                                        }}
                                        isClearable
                                    />
                                </>

                                :
                                <>
                                    <ReactSelect
                                        className="basic-single"
                                        placeholder={<>
                                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                Filter
                                            </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                {
                                                    (searchForLabel === "Status" && !isStatusDropdownCleared) ?
                                                        <Typography sx={{ color: "#000" }}>
                                                            Status
                                                        </Typography>
                                                        :
                                                        (searchForLabel === "Template Code" && !isStatusDropdownCleared) ?
                                                            <Typography sx={{ color: "#000" }}>
                                                                Template Code
                                                            </Typography>
                                                            :
                                                            (searchForLabel === "Title" && !isStatusDropdownCleared) ?
                                                                <Typography sx={{ color: "#000" }}>
                                                                    Title
                                                                </Typography>
                                                                :
                                                                (searchForLabel === null && !isStatusDropdownCleared) ?
                                                                    "Select Filter"
                                                                    :
                                                                    null
                                                }</i>
                                        </>}

                                        isSearchable={true}
                                        isRtl={false}
                                        options={searchForOptions}
                                        onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                width: 250,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                            }),
                                        }}
                                        isClearable
                                    />
                                </>
                            }

                            {searchForLabel === "Status" &&
                                <ReactSelect
                                    className="basic-single"
                                    isSearchable={true}
                                    isRtl={false}
                                    options={statusOptions}
                                    defaultValue={searchStatusVal}
                                    onChange={selectedOption => handleSelectStatusValue(selectedOption)}
                                    placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Status:</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Select Status...</i></>} // Set the placeholder here
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                        }),
                                    }}
                                />
                            }

                            {(searchForLabel === "Title" || searchForLabel === "Template Code") &&
                                <TextField
                                    value={searchTemplateVal}
                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={handleTextfield}
                                    onKeyDown={handleKeyPress}
                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                    placeholder={searchForLabel === "Title" ? "Search Title..." : "Template Code"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{searchForLabel === "Title" ? 'Title' : 'Template Code'}:</p></InputAdornment>,
                                    }}
                                />
                            }
                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                        </Stack>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'end', justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', mr: '10px' }}>
                        <Grid item sx={{ textAlign: 'center', mr: 2 }}>
                            <Button
                                className="card-add"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#0178f2',
                                    borderRadius: 1,
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    fontSize: 12,
                                    padding: '5px 15px 5px 15px',
                                    minHeight: '5px',
                                    height: "37px"

                                }}
                                onClick={() => navigate('/add-template?projectId=' + projectId + '&id=' + businessIdFromURL)}
                            >
                                Add Template
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>




            <Grid container sx={{ padding: 3, pt: "10px !important" }}>
                <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 1, width: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader />

                        {(isFetchingEnterpiseAccountTemplate) ?
                            <TableBody>
                                <EnterpriseTemplateListSkeletonComponent />
                            </TableBody>
                            :

                            ((!isFetchingEnterpiseAccountTemplate) &&
                                <TableBody>
                                    <TemplateListComponent
                                        enterpriseAccountTemplates={enterpriseAccountTemplates}
                                        getEnterpriseProjectAccountTemplates={getEnterpriseProjectAccountTemplates}
                                        projectId={projectId}
                                    />
                                </TableBody>
                            )
                        }
                    </Table>

                    {(!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length == 0) &&
                        <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                            <Grid container sx={{ padding: 3 }}>
                                <Box sx={{ textAlign: "center", margin: "auto" }}>
                                    <Typography>
                                        No available template(s) to display yet.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    }

                    {!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length !== 0 &&
                        <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                        />
                    }
                </TableContainer>
            </Grid>
        </Box>
    )

}

export default TemplateListScreen