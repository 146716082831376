import environment from "../../../../environment/environment";
import routes from "../../common/api-routes";
import { HttpGet, HttpPost, HttpDelete, HttpPut } from "../../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getAccessControlList = (page, limit, roleId) => {
    let query_params = {
        page: page,
        limit: limit,
        roleId: roleId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.ADMIN_LIST_ACCESS_CONTROL, query_params, subscriptionKey);
    return result;
}

const addAccessControl = (role_id, permission_id, is_allowed) => {
    let payload = {
        role_id: role_id,
        permission_id: permission_id,
        is_allowed: is_allowed
    };
    let result = HttpPost(routes.ADMIN_ADD_ACCESS_CONTROL, payload);
    return result;
}

const removeAccessControl = (roleId, permissionId) => {
    let query_params = {
        roleId: roleId,
        permissionId: permissionId
    };
    let result = HttpDelete(routes.ADMIN_REMOVE_ACCESS_CONTROL, query_params);
    return result;
}

const updateIs_Allowed = (id, isAllowed) => {
    let result = HttpPut(routes.UPDATE_ACCESS_CONTROL + "?id=" + id + "&isAllowed=" + isAllowed, null);
    return result;
}

const AdminRbacAccessControlService = {
    getAccessControlList: getAccessControlList,
    addAccessControl: addAccessControl,
    removeAccessControl: removeAccessControl,
    updateIs_Allowed: updateIs_Allowed
}

export default AdminRbacAccessControlService;