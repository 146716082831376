import { TableRow, TableCell, Skeleton } from "@mui/material";

function EmailAuthenticationSkeletonComponent (){

    const url = window.location.pathname;

    let items = [];

    for (let i = 0; i < 3; i++) {
        items.push(
            <TableRow key={i} sx={{'&:hover': {backgroundColor: "#fff"}}}>
                {url == "/view" && <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>}
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>
                {url !== "/view" && <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>}
                {url !== "/view" && <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/></TableCell>}
            </TableRow>
        )
    }

    return(
       <>{items}</>
    )
}
export default EmailAuthenticationSkeletonComponent