import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";


function SignInLogsComponent(props) {

    const { breakpoints, data } = props;


    return(
        
           <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                {/* <TableCell  component="th" scope="row"> 
                    {data?.userId}
                </TableCell> */}

                <TableCell align="left">
                    {data?.userPrincipalName}
                </TableCell>

                <TableCell align="left"> 
                    <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                        <ListItem>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 14}}>
                                    {data?.deviceDetail.operatingSystem}
                                </Typography>
                            </React.Fragment>} 
                            secondary={<React.Fragment>
                                <Typography sx={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 14}}>
                                    {data?.deviceDetail.browser}
                                </Typography>
                            </React.Fragment>}/>
                        </ListItem>
                    </List>
                </TableCell>

                <TableCell align="left"> 
                   {data?.location.city} , {data?.location.countryOrRegion}
                </TableCell>

                <TableCell align="left"> 
                    <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                        <ListItem>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 14}}>
                                Lat: {data?.location.geoCoordinates.latitude}
                                </Typography>
                            </React.Fragment>} 
                            secondary={<React.Fragment>
                                <Typography sx={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 14}}>
                                Long: {data?.location.geoCoordinates.longitude}
                                </Typography>
                            </React.Fragment>}/>
                        </ListItem>
                    </List>
                </TableCell>

               </TableRow>

       
    )
    
}

export default SignInLogsComponent


       
          