import { useEffect, useState } from "react";
import useTemplates from "../../../hooks/useTemplates"
import { Box, Button, FormGroup, Grid, IconButton, InputAdornment, Pagination, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import TemplateListSkeletonComponent from "../../skeletons/template-list-skeleton.component";
import TemplateRowContent from "../../template-management/template-row.component";
import { useSnackbar } from "notistack";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { format } from 'date-fns';
import useProjects from "../../../hooks/useProjects";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import TemplatesActionsPopperComponent from "./templates-action-popper.component";
import UpdateTemplateNameDialog from "./update-template-name.dialog";
import DeleteTemplateDialog from "./delete-template.dialog";
import EnterpriseTemplateListSkeletonComponent from "../../skeletons/enterprise-templates-skeleton.component";
import { useNavigate } from "react-router-dom";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";

const searchForOptions = [
    { value: "title", label: "Title" },
    { value: "status", label: "Status" },
    { value: "templateCode", label: "Template Code" }
]

const statusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "DRAFT", label: "DRAFT" }
]




function TableHeader() {

    const tablecell = [
        { label: 'Title' },
        { label: 'Template Code' },
        { label: 'Status' },
        { label: 'Project' },
        { label: 'Total Page' },
        { label: 'Last Date Modified' },
        { label: 'Action' },
    ]

    return (
        <TableHead >
            <TableRow >
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const TemplateTableContent = (props) => {

    // let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    const { index, template, setFetchingEnterpriseAccountTemplate } = props;

    const { enqueueSnackbar } = useSnackbar();


    const [selectedIndex] = useState(index);
    const [noCertificate, setNoCertificate] = useState(false);
    const [withCertificate, setWithCertificate] = useState(true);
    const [templateDetails, setTemplateDetails] = useState(null);
    const [openEditTemplate, setOpenEditTemplate] = useState(false);
    const [isTemplateNameUpdatedToParent, setTemplateNameUpdatedToParent] = useState(false);
    const [templateNameSendToParent, setTemplateNameSendToParent] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
    const [templateNameState, setTemplateNameState] = useState(template?.template_name);
    const [templateStatusStateFromParent, setTemplateStateStatusFromParent] = useState(template?.status);


    useEffect(() => {
        const fetchTemplateNameAfterNameUpdate = async () => {
            if (isTemplateNameUpdatedToParent) {
                setTemplateNameState(templateNameSendToParent);
            }
        }

        fetchTemplateNameAfterNameUpdate();
    }, [isTemplateNameUpdatedToParent, templateNameSendToParent]);


    const handleOpenEditDialog = () => {
        setOpenEditTemplate(true);
        setTemplateDetails(template);
    }

    const handleCloseEditTemplateDialog = () => {
        setOpenEditTemplate(false);
    }

    const handleOpenDeleteTemplateDialog = (e) => {
        setOpenDeleteTemplate(true);
        setTemplateDetails(template);
    }

    const handleCloseDeleteTemplateDialog = () => {
        setOpenDeleteTemplate(false);
    }


    const copyToClipboard = (template) => {
        copy(template.template_code);

        enqueueSnackbar("Successfully copied template code to clipboard", {
            variant: "success",
            autoHideDuration: 2000
        });
    }

    console.log("templatexx", template)
    return (

        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                        className="card-view"
                        sx={{
                            color: "#0178f2",
                            fontWeight: 500,
                            cursor: "pointer",
                        }}
                        component="th"
                        scope="row"
                        onClick={() => window.location.href = '/view-template?template_code=' + template?.template_code + '&businessId=' + businessIdFromURL }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <Typography
                                style={{ alignSelf: "flex-start", cursor: "default" }}
                            >
                                {templateNameState}
                            </Typography>
                        </div>
                    </TableCell>


                    <TableCell align="left" sx={{ fontWeight: 600, fontSize: "13px !important" }}>
                        <Box sx={{ border: "1px solid black", borderStyle: "dashed", padding: 1 }}>
                            <Grid container>
                                <Grid item xs={10} sx={{ alignSelf: "center" }}>
                                    {template?.template_code}
                                </Grid>

                                <Grid item xs={2} sx={{ alignSelf: "center" }}>
                                    <Tooltip title="Copy" placement="top" arrow>
                                        <IconButton className="card-copy" onClick={(e) => copyToClipboard(template)} sx={{ color: "#0178f2", cursor: "pointer", pl: 1, pr: 0 }}>
                                            <ContentCopyIcon sx={{ fontSize: "medium" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </TableCell>

                    <TableCell align="left" sx={{ color: (templateStatusStateFromParent === "ACTIVE") ? "green" : (templateStatusStateFromParent === "INACTIVE") ? "red" : "inherit", fontWeight: 530 }}>
                        {templateStatusStateFromParent}
                    </TableCell>

                    <TableCell align="left"
                        sx={{
                            fontWeight: 500, color: '#0178f2',
                            cursor: (template?.project_name == null) ? "default" : "pointer"
                        }}
                    >
                        {template?.project_name}
                    </TableCell>

                    <TableCell align="center">
                        {template?.total_page}
                    </TableCell>

                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        {format(new Date(template?.updated_at), 'PPpp')}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        <TemplatesActionsPopperComponent
                            template={template}
                            handleOpenEditDialog={handleOpenEditDialog}
                            setTemplateStateStatusFromParent={setTemplateStateStatusFromParent}
                            handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
                            businessIdFromURL={businessIdFromURL}
                        />
                    </TableCell>
                </TableRow>
            }

            {openDeleteTemplate &&
                <DeleteTemplateDialog
                    openDeleteTemplate={openDeleteTemplate}
                    templateDetails={templateDetails}
                    handleCloseDeleteTemplateDialog={handleCloseDeleteTemplateDialog}
                    setIsDeleted={setIsDeleted}
                    setLoading={setFetchingEnterpriseAccountTemplate}
                    templateNameState={templateNameState}
                    businessIdFromURL={businessIdFromURL}
                />
            }

            {openEditTemplate &&
                <UpdateTemplateNameDialog
                    openEditTemplate={openEditTemplate}
                    handleCloseEditTemplateDialog={handleCloseEditTemplateDialog}
                    templateDetails={templateDetails}
                    setTemplateNameUpdatedToParent={setTemplateNameUpdatedToParent}
                    setTemplateNameSendToParent={setTemplateNameSendToParent}
                    templateNameState={templateNameState}
                />
            }

        </>
    )
}



const EnterpriseTemplatesComponent = () => {

    const { isFetchingEnterpiseAccountTemplate, error, getEnterpriseAccountTemplates, enterpriseAccountTemplates, pagination, setFetchingEnterpriseAccountTemplate, filterEnterpriseTemplate } = useTemplates();
    const { getProjectList, projects } = useProjects();
    const navigate = useNavigate();
    // let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");
    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    const [page, setPage] = useState(1);
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [searchTemplateVal, setSearchTemplateVal] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isStatusDropdownCleared, setStatusDropdownCleared] = useState(false);

    const handleChangePagination = (event, page) => {
        setPage(page);
        getEnterpriseAccountTemplates(businessIdFromURL, page);
        setFetchingEnterpriseAccountTemplate(true);
    }

    const reload = () => {
        setSearchFor('title')
        setSearchForLabel(null)
        getEnterpriseAccountTemplates(businessIdFromURL, page);
        setSearchTemplateVal('')
        setSearchStatusVal(null)
        setSelectedProject(null)
        setStatusDropdownCleared(true);
    }

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
            setStatusDropdownCleared(false);

        }
        else {
            setSearchForLabel(null);
            setSearchFor(null);
            setSelectedProject(null);
            setSearchTemplateVal(null);
            setSearchStatusVal(null);
            setStatusDropdownCleared(true);
            getEnterpriseAccountTemplates(businessIdFromURL, page);

        }
    }

    const handleTextfield = (e) => {
        setSearchTemplateVal(e.target.value);
        setSearchStatusVal(null);
    }
    const [selectStatusVal, setSelectStatusVal] = useState(null)

    const handleSelectStatusValue = async (selectedOption) => {
        setSearchStatusVal(selectedOption.label);
        setSelectStatusVal(selectedOption.value)
        setPage(1)
        if (selectedOption) {
            filterEnterpriseTemplate(searchFor, selectedOption.value, businessIdFromURL, page);
        }
    }

    const filterEnterprisePagination = (page) => {
        setPage(page)
        filterEnterpriseTemplate(searchFor, selectStatusVal, businessIdFromURL, page);
    }


    const handleKeyPress = (e) => {
        console.log('keeeeeeeeeeeey', e)
        if (e.keyCode === 13) {
            if (selectedProject) {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseTemplate(searchFor, searchVal, businessIdFromURL, page);
                }

            } else {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseTemplate(searchFor, searchVal, businessIdFromURL, page);
                } else {
                    getEnterpriseAccountTemplates(businessIdFromURL, page);
                }
            }
        } else if (e.key === 'Backspace' && e.target.value === ' ') {
            getEnterpriseAccountTemplates(businessIdFromURL, page);
        }
    }

    useEffect(() => {

        if (businessIdFromURL) {
            getEnterpriseAccountTemplates(businessIdFromURL, page);
        }

    }, [businessIdFromURL, page])
    
    useEffect(() => {

        getProjectList(businessIdFromURL);


        if (enterpriseAccountTemplates?.length > 0) {

            enterpriseAccountTemplates?.map((template) => {

                let project_id = template?.project_id;

                projects?.map((project) => {

                    if (project?.id == project_id) {
                        template.project_name = project?.application_name
                    }

                })

            })

        }

    }, [JSON.stringify(enterpriseAccountTemplates), JSON.stringify(projects)])

    return (

        <Box sx={{ pt: "8px !important", width: '100%', borderRadius: 0, overflow: 'hidden', pb: "8px !important" }}>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    <Grid container>
                        <Grid item xs={12} sm={8}>
                            <Box className="card-search" sx={{ textAlign: "flex-start", paddingLeft: 1, alignSelf: "center", mr: "6px" }}>
                                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>
                                    {(isStatusDropdownCleared) ?
                                        <>
                                            <ReactSelect
                                                className="basic-single"
                                                value={"Select Filter"}
                                                placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Select Filter</i></>}
                                                isSearchable={true}
                                                isRtl={false}
                                                options={searchForOptions}
                                                onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        height: 38,
                                                        borderRadius: 100,
                                                        backgroundColor: 'transparent',
                                                        width: 250,
                                                        fontSize: 12
                                                    }),
                                                    placeholder: base => ({
                                                        ...base,
                                                        fontSize: 12
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 99999,
                                                        fontSize: 12
                                                    }),
                                                }}
                                                isClearable
                                            />
                                        </>

                                        :
                                        <>
                                            <ReactSelect
                                                className="basic-single"
                                                placeholder={<>
                                                    <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                        Filter
                                                    </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                        {
                                                            (searchForLabel === "Status" && !isStatusDropdownCleared) ?
                                                                <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                                    Status
                                                                </Typography>
                                                                :
                                                                (searchForLabel === "Template Code" && !isStatusDropdownCleared) ?
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                                        Template Code
                                                                    </Typography>
                                                                    :
                                                                    (searchForLabel === "Title" && !isStatusDropdownCleared) ?
                                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                                            Title
                                                                        </Typography>
                                                                        :
                                                                        (searchForLabel === null && !isStatusDropdownCleared) ?
                                                                            "Select Filter"
                                                                            :
                                                                            null
                                                        }</i>
                                                </>}

                                                isSearchable={true}
                                                isRtl={false}
                                                options={searchForOptions}
                                                onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        height: 38,
                                                        borderRadius: 100,
                                                        backgroundColor: 'transparent',
                                                        width: 250,
                                                        fontSize: 12
                                                    }),
                                                    placeholder: base => ({
                                                        ...base,
                                                        fontSize: 12
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 99999,
                                                        fontSize: 12
                                                    }),
                                                }}
                                                isClearable
                                            />
                                        </>
                                    }

                                    {searchForLabel === "Status" &&
                                        <ReactSelect
                                            className="basic-single"
                                            isSearchable={true}
                                            isRtl={false}
                                            options={statusOptions}
                                            // defaultValue={searchStatusVal}
                                            onChange={selectedOption => handleSelectStatusValue(selectedOption)}
                                            placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Status:</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Select Status...</i></>} // Set the placeholder here
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    fontSize: 12
                                                }),
                                            }}
                                        />
                                    }

                                    {(searchForLabel === "Title" || searchForLabel === "Template Code") &&
                                        <TextField
                                            value={searchTemplateVal}
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                            variant="outlined"
                                            autoComplete="off"
                                            onChange={handleTextfield}
                                            onKeyDown={handleKeyPress}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder={searchForLabel === "Title" ? "Search Title..." : "Template Code"}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{searchForLabel === "Title" ? 'Title' : 'Template Code'}:</p></InputAdornment>,
                                            }}
                                        />
                                    }
                                    <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ textAlign: 'end', justifyContent: 'flex-end' }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', mr: '10px' }}>
                                <Grid item sx={{ textAlign: 'center', mr: 2 }}>
                                    <Button
                                        className="card-add"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#0178f2',
                                            borderRadius: 1,
                                            textTransform: 'none',
                                            boxShadow: 'none',
                                            fontSize: 12,
                                            padding: '5px 15px 5px 15px',
                                            minHeight: '5px',
                                            height: "37px"

                                        }}
                                        onClick={() => navigate("/add-template?id=" + businessIdFromURL)}
                                    >
                                        Add Template
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ padding: 2, pt: "10px !important" }}>
                        <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 1, width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(isFetchingEnterpiseAccountTemplate) ?
                                    <TableBody>
                                        <EnterpriseTemplateListSkeletonComponent />
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <>
                                            {(enterpriseAccountTemplates && enterpriseAccountTemplates)?.map((content, index) => (
                                                <TemplateTableContent
                                                    index={index}
                                                    template={content}
                                                    setFetchingEnterpriseAccountTemplate={setFetchingEnterpriseAccountTemplate}
                                                    businessIdFromURL={businessIdFromURL}
                                                />
                                            ))}
                                        </>
                                    </TableBody>
                                }
                            </Table>

                            {(!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length == 0) &&
                                <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                    <Grid container sx={{ padding: 3 }}>
                                        <Box sx={{ textAlign: "center", margin: "auto" }}>
                                            <Typography>
                                                No available template(s) to display yet.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Box>
                            }

                            {!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length !== 0 &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={searchFor && selectStatusVal ? 
                                    filterEnterprisePagination   
                                    :handleChangePagination}
                                    disabled={enterpriseAccountTemplates?.length === 0}
                                    hideNextButton={enterpriseAccountTemplates?.length < 10}
                                    style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                />
                            }
                        </TableContainer>
                    </Grid>
                </>
            }

        </Box>
    )
}

export default EnterpriseTemplatesComponent