import {useEffect} from "react";
import {Box, Skeleton, Grid, Card, CardContent, Typography, CardActionArea} from "@mui/material";
import useProjects from "../../hooks/useProjects";
import useTemplates from "../../hooks/useTemplates";

const ProjectOverviewComponent = (props) => {

    const {breakpoints, projectId} = props;

    const {isFetchingProjectOverviewStats, projectOverviewStats, getProjectOverviewStats} = useProjects();
    const {isFetchingCountTemplate, countTemplateData, getCountAccountTemplates} = useTemplates();

    const businessId = (new URLSearchParams(window.location.search)).get("id");


    useEffect(() => {
        getProjectOverviewStats(projectId);
        getCountAccountTemplates(businessId, projectId);
    }, [])


    const projectUsers_overview_stats = [
        {label: "Active Users", value: projectOverviewStats?.active_users},
        {label: "Inactive Users", value: projectOverviewStats?.inactive_users},
        {label: "Total Users", value: projectOverviewStats?.total_users},   
    ]

    const projectTemplates_overview_stats = [
        {label: "Active Templates", value: countTemplateData?.active},
        {label: "Inactive Templates", value: countTemplateData?.inactive},    
        {label: "Total Credits", value: projectOverviewStats?.total_credits}, 
    ]


    return (
        <Box sx={{padding: "18px 26px"}}>
            <Grid sx={{mb: "14px"}}>
                <Typography sx={{fontWeight: "bold", fontSize: 19}}>
                    Project Stats
                </Typography>
            </Grid>

            <Grid container spacing={2}>
                {projectUsers_overview_stats.map((stats) => {
                    return (
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Card sx={{cursor: "default", margin: "auto", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"}}>
                                <CardActionArea sx={{cursor: "default", minHeight: 126, color: "#0178f2"}}>
                                    <CardContent sx={{textAlign: "center"}}>
                                        {isFetchingProjectOverviewStats ?
                                            <Skeleton variant="rectangular" 
                                                sx={{margin: "auto", borderRadius: "4px", mb: "3px", 
                                                    height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"
                                                }} 
                                            />                     
                                        :                                                     
                                            <Typography variant="h2" sx={{margin: "auto", fontSize: 32}}>
                                                {stats.value}
                                            </Typography>
                                        }
                                                                        
                                        <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                            {stats.label}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container spacing={2} sx={{mt: "12px"}}>
                {projectTemplates_overview_stats.map((stats) => {
                    return (
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Card sx={{cursor: "default", margin: "auto", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"}}>
                                <CardActionArea sx={{cursor: "default", minHeight: 126, color: "#0178f2"}}>
                                    <CardContent sx={{textAlign: "center"}}>
                                        {isFetchingCountTemplate ?
                                            <Skeleton variant="rectangular" 
                                                sx={{margin: "auto", borderRadius: "4px", mb: "3px", 
                                                    height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"
                                                }} 
                                            />                     
                                        :                                                     
                                            <Typography variant="h2" sx={{margin: "auto", fontSize: 32}}>
                                                {stats.value}
                                            </Typography>
                                        }
                                                                        
                                        <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                            {stats.label}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ProjectOverviewComponent
