import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useState } from "react";
import { useSnackbar } from "notistack";
import { ThreeDots } from 'react-loader-spinner';
import useWidth from "../../../hooks/useWidth";
import AdminProjectService from "../../../services/api/projects";

function UpdateExpirationDateDialog(props) {

    const { openUpdateExpirationDate, project, setOpenUpdateExpirationDate, setExpirationDate, setExpirationDateUpdated, updatedExpirationDate, expirationDateUpdate } = props
    const breakpoints = useWidth();
    const [dob, setDob] = useState("");
    const [loading, setLoading] = useState(false);
    const [removeExpiration, setRemoveExpiration] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';

    const formattedDobTest = project?.project_expiration ? dayjs(dob).format('MM-DD-YYYY') : '';

    const handleClose = () => {
        setOpenUpdateExpirationDate(false)
    }

    const handleUpdateExpirationDate = () => {
        let id = project?.id;

        AdminProjectService.updateExpirationDate(id, formattedDob).then((res) => {
            setExpirationDateUpdated(true);
            setExpirationDate(res.data.data.project_expiration);
            enqueueSnackbar("Updated project expiration date", {
                variant: 'success',
                autoHideDuration: 3000
            });
            // window.location.reload();
            setLoading(false);
            setRemoveExpiration(false);
            handleClose();
        }).catch((err) => {
            enqueueSnackbar(err.response.data.error, {
                variant: 'error',
                autoHideDuration: 3000
            });
            setLoading(false)
            setRemoveExpiration(false)
            setExpirationDateUpdated(false);
        })
    }

    const handleSubmit = () => {
        setLoading(true)
        handleUpdateExpirationDate()
    }

    const handleRemoveExpiration = () => {
        setRemoveExpiration(true)
        handleUpdateExpirationDate()
    }



    return (
        <Dialog open={openUpdateExpirationDate} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                    Set Expiration Date
                </Typography>
            </DialogTitle>

            <Divider />

            <DialogContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Select Date:</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {!project?.project_expiration ?
                        null
                    :
                        <Button sx={{ textTransform: 'none', color: 'red' }}
                            startIcon={removeExpiration ? <ThreeDots height="20" width="20" radius="9" color="red" ariaLabel="three-dots-loading" visible={true} /> : null}
                            onClick={handleRemoveExpiration}
                        >
                            {removeExpiration ? "Removing" : "Remove Expiration"}
                        </Button>
                    }

                    {updatedExpirationDate &&
                        <Button sx={{ textTransform: 'none', color: 'red' }}
                            startIcon={removeExpiration ? <ThreeDots height="20" width="20" radius="9" color="red" ariaLabel="three-dots-loading" visible={true} /> : null}
                            onClick={handleRemoveExpiration}
                        >
                            {removeExpiration ? "Removing" : "Remove Expiration"}
                        </Button>
                    }

                </Box>
                {expirationDateUpdate ?

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                            <DatePicker
                                required
                                disablePast={true}
                                defaultValue={dayjs(JSON.stringify(updatedExpirationDate))}
                                views={['year', 'month', 'day']}
                                sx={{
                                    "& .MuiInputLabel-root": { color: '#1f4380', },
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "#1f4380 !important" },
                                        "& .MuiInputBase-input": { padding: 1, },
                                    },
                                    fontSize: 16,
                                    overflow: 'hidden'
                                }}
                                onChange={(newValue) => {
                                    setDob(newValue);
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    :

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                            <DatePicker
                                required
                                disablePast={true}
                                defaultValue={dayjs(JSON.stringify(project?.project_expiration))}
                                views={['year', 'month', 'day']}
                                sx={{
                                    "& .MuiInputLabel-root": { color: '#1f4380', },
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "#1f4380 !important" },
                                        "& .MuiInputBase-input": { padding: 1, },
                                    },
                                    fontSize: 16,
                                    overflow: 'hidden'
                                }}
                                onChange={(newValue) => {
                                    setDob(newValue);
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                }

            </DialogContent>

            <DialogActions sx={{ mr: 2, mb: 1 }}>
                <Button onClick={handleClose} sx={{ textTransform: 'none', fontSize: 14 }}>
                    Cancel
                </Button>

                {dob ?
                    <Button variant="contained" sx={{ textTransform: 'none', borderRadius: 1, fontSize: 14 }}
                        onClick={handleSubmit}
                        startIcon={loading ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                    >
                        {loading ? "Updating" : "Update"}
                    </Button>
                :
                    <Button variant="contained" sx={{ textTransform: 'none', borderRadius: 1, fontSize: 14 }} disabled>
                        Update
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
}
export default UpdateExpirationDateDialog