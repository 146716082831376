import {useEffect, useState} from "react";
import {Skeleton, Grid, Card, CardContent, Typography, CardActionArea, Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import useWidth from "../../../hooks/useWidth";
import DashboardAuthenticationGraphComponent from "./dashboard-authentication-graph.component";
import DashboardProjectCreditComponent from "./dashboard-project-credits.component";
import DashboardAuthenticationCardComponent from "./dashboard-authentication-card.component";
import DashboardProjectCreditSkeletonComponent from "./dashboard-project-credit-skeleton.component";
import useCreditsManagement from "../../../hooks/useCreditsManagement";
import useBusiness from "../../../hooks/useBusiness";
import EnterpriseOverviewComponent from "./enterprise-overview.component";
import useDashboard from '../../../hooks/useDashboard';
import useProjects from "../../../hooks/useProjects";
import DashboardAuthenticationCardSkeletonComponent from "./dashboard-authentication-card-skeleton.component";

const EnterpriseDashboardComponent = () => {

    const navigate = useNavigate();
    const breakpoints = useWidth(); 
    const {businessCredits, viewBusinessCredits, isGetProjectCreditList, projectCreditsList, getProjectCreditList} = useCreditsManagement();
    const {businessOverviewStats, isFetchingBusinessOverviewStats, getBusinessOverviewStats} = useBusiness();
    const {isFetchingDashboardCount, dashboardCount, getEnterpriseDashboardCount, isFetchingRecentActivity, hasNoRecentActivity, recentActivityData, getRecentActivity} = useDashboard();
    const {projects, getProjectList} = useProjects();

    const currentYear = new Date().getFullYear();
    const [currentMonth, setCurrentMonth] = useState(null);
    const [modifiedTransactions, setModifiedTransactions] = useState(null);

    const businessId = (new URLSearchParams(window.location.search)).get("id");

    console.log("businessIdxxxx",businessId)



    useEffect(() => {
        viewBusinessCredits(businessId);
        getProjectCreditList(businessId);
        getBusinessOverviewStats(businessId);
    }, [])

    useEffect(() => {
        getEnterpriseDashboardCount(businessId);
        getProjectList(businessId);
        getCurrentMonth();
        getRecentActivity(businessId);
    }, [])


    useEffect(() => {
        if (recentActivityData?.length > 0) {
            recentActivityData?.map((transaction) => {
                let project_id = transaction?.project_id;

                projects?.map((project) => {
                    if (project?.id == project_id) {
                        transaction.project_name = project?.application_name;
                    }
                })
            })

            setModifiedTransactions(recentActivityData);
        }
    }, [JSON.stringify(recentActivityData), JSON.stringify(projects)])


    const getCurrentMonth = () => {
        const months = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];
      
        const currentDate = new Date();
        const currentMonth = months[currentDate.getMonth()];

        setCurrentMonth(currentMonth);
    }

    console.log("dashboardCountxxxx", dashboardCount)
    
    return (
        <>
            <Box sx={{width: "100%", margin: "auto", mb: "16px !important", mt: "-20px !important"}}>
                <Grid container spacing={2} direction="row" sx={{mt: -1}}>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={9}>
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Card sx={{cursor: "default", margin: "auto"}}>
                                        <CardActionArea sx={{cursor: "default", minHeight: 150, color: "#0178f2"}}>
                                            <CardContent sx={{textAlign: "center", mt: "20px"}}>  
                                                {(isFetchingDashboardCount) ?
                                                    <Skeleton variant="rectangular" sx={{margin: "auto", borderRadius: "4px", mb: "3px", height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"}} />                     
                                                  
                                                :                                                   
                                                    <Typography variant="h2" sx={{margin: "auto", fontSize: 32}}>
                                                        {dashboardCount?.month.toLocaleString()}
                                                    </Typography>
                                                }
                                                           
                                                <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                                    Authentication
                                                </Typography>
                                            </CardContent>

                                            <Box sx={{display: "flex", padding: "0px 10px"}}>
                                                <Typography sx={{ml: "auto", color: "#000", fontSize: 14}}>
                                                    {currentMonth}
                                                </Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                    
                                <Grid item xs={12} sm={3}>
                                    <Card sx={{cursor: "default"}}>
                                        <CardActionArea sx={{cursor: "default", minHeight: 150, color: '#0178f2'}}>
                                            <CardContent sx={{textAlign: "center", mt: "20px"}}>
                                                {(isFetchingDashboardCount) ?
                                                    <Skeleton variant="rectangular" sx={{margin: "auto", borderRadius: "4px", mb: "3px", height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"}} />                     
                                                  
                                                :    
                                                    <Typography variant="h3" sx={{margin: "auto", fontSize: 32}}>
                                                        {dashboardCount?.day.toLocaleString()}
                                                    </Typography>
                                                }
     
                                                <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                                    Authentication
                                                </Typography>
                                            </CardContent>

                                            <Box sx={{display: "flex", padding: "0px 10px"}}>
                                                <Typography sx={{color: "#000", ml: "auto", visibility: "visible", fontSize: 14}}>
                                                    Today
                                                </Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Card sx={{cursor: "default"}}>
                                        <CardActionArea sx={{cursor: "default", minHeight: 150, color: '#0178f2'}}>
                                            <CardContent sx={{textAlign: "center", mt: "20px"}}>
                                                {(isFetchingDashboardCount) ?
                                                    <Skeleton variant="rectangular" sx={{margin: "auto", borderRadius: "4px", mb: "3px", height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"}} />                     
                                                  
                                                :    
                                                    <Typography variant="h3" sx={{margin: "auto", fontSize: 32}}>
                                                        {dashboardCount?.year.toLocaleString()}
                                                    </Typography>
                                                }

                                                <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                                    Authentication
                                                </Typography>
                                            </CardContent>

                                            <Box sx={{display: "flex", padding: "0px 10px"}}>
                                                <Typography sx={{color: "#000", ml: "auto", fontSize: 14}}>
                                                    {currentYear}
                                                </Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Card sx={{cursor: "default"}}>
                                            <CardActionArea sx={{cursor: "default", minHeight: 150, color: '#0178f2'}}>
                                                <CardContent sx={{textAlign: "center", mt: "20px"}}>
                                                    <Typography variant="h3" sx={{margin: "auto", fontSize: 32}}>
                                                        {(businessCredits > 0) ? businessCredits : "0"}
                                                    </Typography>

                                                    <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 16}}>
                                                        {(businessCredits > 0) ? " Credits" : " Credit"}
                                                    </Typography>
                                                </CardContent>

                                                <Box sx={{display: "flex", padding: "0px 10px"}}>
                                                    <Typography sx={{color: "#000", ml: "auto", fontSize: 14}}>                                                      
                                                        Balance
                                                    </Typography>
                                                </Box>
                                            </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm={6} sx={{mt: 2}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14}}>
                                    Overview
                                </Typography>
                            </Grid>
                           
                            <Grid item xs={12} sm={12} sx={{mt: 1}}>
                                <EnterpriseOverviewComponent 
                                    businessOverviewStats={businessOverviewStats} 
                                    isFetchingBusinessOverviewStats={isFetchingBusinessOverviewStats}
                                />
                            </Grid>

                            <Box sx={{display: "flex", mt: 3}}>
                                <Typography sx={{fontSize: 14}}>
                                    Overall Authentication:
                                </Typography> &nbsp;

                                <Typography style={{fontWeight: "bold", letterSpacing: "0.5px", fontSize: 14}}>
                                    {dashboardCount?.total.toLocaleString()}
                                </Typography>
                            </Box>
                            
                           
                            {/* dashboard authentication graph component */}
                            <Grid item xs={12} sm={12} sx={{mt: 1}}>
                                <DashboardAuthenticationGraphComponent 
                                    breakpoints={breakpoints} 
                                    projects={projects}
                                    businessId={businessId}
                                />
                            </Grid>
                        </>
                    </Grid>

                    {/* dashboard credit summary && authentication cards component */}
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                        <Grid container>
                            {/* <Grid item xs={6} sm={6} sx={{mt: "-5px"}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14}}>
                                    Overview
                                </Typography>
                            </Grid>
                           
                            <Grid item xs={12} sm={12} sx={{mt: 1}}>
                                <EnterpriseOverviewComponent 
                                    businessOverviewStats={businessOverviewStats} 
                                    isFetchingBusinessOverviewStats={isFetchingBusinessOverviewStats}
                                />
                            </Grid> */}

                            <Grid item xs={6} sm={6} sx={{mt: 1.5}}>
                                <Typography sx={{fontWeight: "bold", fontSize: 14}}>
                                    OVI Credits
                                </Typography>
                            </Grid>
                           
                            <Grid item xs={12} sm={12} 
                                sx={{
                                    mt: (isGetProjectCreditList) ? 0.5 : 1.5, 
                                    height: (isGetProjectCreditList) ? {} : projectCreditsList?.length > 4 ? 385 : "",
                                    overflow: "hidden", 
                                    overflowY: (isGetProjectCreditList) ? {} : "auto", 
                                    mb: 3
                                }}
                            >
                                {(isGetProjectCreditList) ?
                                    [0, 1].map((authentication) => {
                                        return (
                                            <DashboardProjectCreditSkeletonComponent breakpoints={breakpoints} />
                                        )
                                    })
                                                
                                : 
                                    (projectCreditsList?.length === 0) ?
                                        <Box sx={{textAlign: "start"}}>
                                            <Typography sx={{fontSize: 14}}>
                                                No Available Project Credits
                                            </Typography>
                                        </Box>
                                :
                                    projectCreditsList?.length > 0 && projectCreditsList?.map((project, index) => {
                                        return ( 
                                            <Box>
                                                <DashboardProjectCreditComponent 
                                                    index={index}
                                                    breakpoints={breakpoints} 
                                                    project={project}
                                                    isLast={index === projectCreditsList?.length - 1}
                                                />
                                            </Box>
                                        )     
                                    }) 
                                }
                            </Grid>
                            <Grid container sx={{borderTop:"1px solid lightgray", paddingTop: "15px"}}>
                                    <Grid item xs={12} sm={12}>
                                        <Box sx={{display: "flex"}}>
                                            <Typography sx={{fontWeight: "bold", fontSize: 14}}>
                                                Recent Activity
                                            </Typography>

                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} sx={{
                                        mt: (hasNoRecentActivity) ? 0.5 : 1.5, 
                                        height: (hasNoRecentActivity) ? {} : recentActivityData?.length > 4 ? 390 : {},
                                        overflow: "hidden", 
                                        overflowY: (hasNoRecentActivity) ? {} : "auto", 
                                        }}>
                                        {isFetchingRecentActivity ?
                                            [0, 1, 2].map((authentication) => {
                                                return (
                                                    <DashboardAuthenticationCardSkeletonComponent 
                                                        breakpoints={breakpoints} 
                                                    />
                                                )
                                            })
                                                    
                                        :
                                            ((!hasNoRecentActivity) ?
                                                recentActivityData?.map((authentication) => {
                                                    return (
                                                        <Box>
                                                            <DashboardAuthenticationCardComponent  
                                                                breakpoints={breakpoints} 
                                                                authentication={authentication} 
                                                            />
                                                        </Box>
                                                        
                                                    )
                                                }) 
                                            :
                                                <Typography>
                                                    No Recent Activity
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default EnterpriseDashboardComponent