import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";


function ApproveConfirmationDialogComponent (props){

    const {openApproveConfirmationDialog, handleCloseApproveConfirmationDialog, authenticationDetails} = props
    const breakpoints = useWidth()
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState(null);

    const handleApproveEmail = (id) => {
        EmailAuthenticationService.approveEmailSender(id).then((res)=>{
            console.log('approve email res', res)
            if(res.data.success){
                enqueueSnackbar("Successful email approval.", { 
                    variant: 'success',
                    autoHideDuration: 3000
                }); 
                handleCloseApproveConfirmationDialog()
                window.location.reload();
            }
        }).catch((err)=>{
            setError(err.response.statusText);
            enqueueSnackbar(err.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            }); 
        })
    }

    return(
        <Dialog open={openApproveConfirmationDialog} onClose={handleCloseApproveConfirmationDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Approve
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center'}}>
                    You're about to approve this email <strong>{authenticationDetails?.email}</strong>. Please confirm.
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleCloseApproveConfirmationDialog} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button variant="contained" onClick={()=> handleApproveEmail(authenticationDetails.id)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
export default ApproveConfirmationDialogComponent