import {useState} from "react";
import { useSnackbar } from "notistack";
import BusinessAppConfigService from "../services/api/business-app-config";
import { log } from "../console-config.log";


const useAppConfig = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading , setLoading] = useState(false);
    const [appList, setAppList] = useState(null)
    const [searchApp, setSearchApp] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [appConfigDetails, setAppConfigDetalis] = useState(null)
    const [pagination, setPagination] = useState(0);
    const [businessList, setBusinessAppList] = useState([]);
    const [availableBusiness, setAvailableBusiness] = useState([]);
    const [addingBusinessApp, setAddingBusinessApp] = useState(false);
    const [added, setAdded] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const [isSearchingAppMiddleware, setSearchingAppMiddleware] = useState(false);
    const [searchedAppMiddleware, setSearchedAppMiddleware] = useState([]);
    const [isSearchFilterToggle, setSearchFilterToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);

    const [searchedBusinessApp, setSearchedBusinessApp] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState('');

    const limit = 10;

    const appConfigurationList = (page) => {
        setLoading(true);
        setSearchFilterToggle(false);

        BusinessAppConfigService.appConfigList(page).then((res)=>{
            
            setLoading(false)
            if (res.data.success) {
                setAppList(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((error) => {
            setLoading(false)
            setError(error.response.data.error);
            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
        })

    }

    const searchByAppCode = (page, appCode) => {
        setLoading(true)

        BusinessAppConfigService.searchByAppCode(page, appCode).then((res)=>{
            
            setLoading(false)
            if (res.data.success) {
                setSearchApp(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((error) => {
            setLoading(false)
        })

    }

    const createAppConfig = (payload) => {
        setLoading(true)
        BusinessAppConfigService.createAppConfig(payload).then((res)=>{
            setLoading(false)

            enqueueSnackbar('Successfully created ' + res?.data?.data?.app_name + ' App Middleware', { 
                variant: 'success',
                autoHideDuration: 3000
            });

            window.location.reload()

        }).catch((error) => {
            setLoading(false)
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
              });
        })
    }

    const deleteAppConfig = (app_code, handleClose) =>{
        setIsDeleting(true)
        BusinessAppConfigService.deleteAppConfig(app_code).then((res)=>{

            setIsDeleting(false)
            setDeleted(true);
            handleClose();
            enqueueSnackbar(res.data.data, { 
                variant: 'success',
                autoHideDuration: 3000
            });

        }).catch((error)=>{
            setIsDeleting(false)
            setDeleted(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const viewAppConfig = (app_code) => {
        BusinessAppConfigService.viewAppConfig(app_code).then((res)=>{
            console.log('get app config detail view', res)
            setAppConfigDetalis(res.data.data)
        })
    }

    const updateAppConfig = (payload) => {
            setLoading(true)
        BusinessAppConfigService.updateAppConfig(payload).then((res)=>{
            setLoading(false)
            // window.location.reload()
            console.log('update app config res here', res)
            if(res.data.success){
                setAppList(res.data.data)
                setUpdated(true);
                
                enqueueSnackbar('Successfully updated config list.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            setLoading(false)
            setUpdated(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
              });
        })
    }


    const businessAppList = (page) => {
        setLoading(true)
        setSearchFilterToggle(false);

        BusinessAppConfigService.businessAppList(page, limit).then((res)=>{
            log('get business app config list zzzzzzzzzzzz', res)
            setLoading(false)
            if (res.data.success) {
                setBusinessAppList(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res.data.data/ limit);
                    setPagination(last_page);
                }
            }
        }).catch((error) => {
            setLoading(false)

            setError(error.response.data.error);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
        })

    }


    const availableBusinessApp = () => {
        setLoading(true)

        BusinessAppConfigService.availableBusinessApp().then((res)=>{
            
            setLoading(false)
            if (res.data.success) {
                setAvailableBusiness(res.data.data)
            }
        }).catch((error) => {
            setLoading(false)

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })

    }


    const addBusinessApp = (payload) => {

        setAddingBusinessApp(true)

        BusinessAppConfigService.addBusinessApp(payload).then((res)=>{

            console.log('addBusinessApp res here', res)
            setAddingBusinessApp(false)

            if(res.data.success){
                setAdded(true);
                enqueueSnackbar('Successfully added Business App.', {
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setAddingBusinessApp(false)

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }


    const deleteBusinessApp = (businessId,appName) =>{

        setIsDeleting(true)

        BusinessAppConfigService.deleteBusinessApp(businessId,appName).then((res)=>{

            console.log('delete config res', res)

            setIsDeleting(false)
            if(res.data.success){
                setDeleted(true);
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setIsDeleting(false)

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const searchAppMiddleware = (filter, value, page) => {
        setSearchingAppMiddleware(true);
        setSearchFilterToggle(true);

        const search_app_middleware_limit = 10;

        BusinessAppConfigService.searchAppMiddleware(filter, value, page, search_app_middleware_limit).then((res) => {
            console.log("searchAppMiddleware data xxxx", res);
          
            if (res.data.success) {
                setSearchingAppMiddleware(false);
                setSearchedAppMiddleware(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_app_middleware_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchAppMiddleware error xxxx", error);

            setSearchingAppMiddleware(false);
            setPagination(0);
            setSearchFilterToggle(false);
            setSearchedResultsEmpty(false);
        })
    }

    const searchBusinessApp = (filter, value, page) => {
        setSearchingAppMiddleware(true);
        setSearchFilterToggle(true);

        const search_business_app_limit = 10;

        BusinessAppConfigService.searchBusinessApp(filter, value, page, search_business_app_limit).then((res) => {
            console.log("searchBusinessApp data xxxx", res);
          
            if (res.data.success) {
                setSearchingAppMiddleware(false);
                setSearchedBusinessApp(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_business_app_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchBusinessApp error xxxx", error);

            setSearchingAppMiddleware(false);
            setPagination(0);
            setSearchFilterToggle(false);
            setSearchedResultsEmpty(false);
        })
    }



    return {loading, appList, pagination, appConfigurationList, createAppConfig, deleteAppConfig, isDeleting, 
        viewAppConfig, appConfigDetails, updateAppConfig, searchApp, searchByAppCode , businessAppList, businessList,
        availableBusinessApp, availableBusiness, addBusinessApp, addingBusinessApp, added, deleted, deleteBusinessApp,
        searchAppMiddleware, isSearchingAppMiddleware, searchedAppMiddleware, isSearchFilterToggle, isSearchedResultsEmpty,
        searchBusinessApp, searchedBusinessApp, updated, error
    }
}

export default useAppConfig
