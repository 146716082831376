import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import usePackageManagement from "../../../hooks/usePackageManagement";
import PackageCardScreenComponent from "../../package-management/package-card-screen.component";
import PackageSkeletonComponent from "../../package-management/package-skeleton.component";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";

const EnterprisePlanComponent = (props) => {

    const { breakpoints } = props;

    const { isFetchingBusinessPackageFeatures, getBusinessPackageFeatures, error,
        getBusinessPackageResponse, isRetrievingPackageSummary, packageSummaryList, getPackageSummary,
    } = usePackageManagement();

    const [modifiedPackageList, setModifiedPackageList] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState(null);

    useEffect(() => {
        // let businessId = (new URLSearchParams(window.location.search)).get("id");
        let businessId = (new URLSearchParams(window.location.search)).get("id");


        getBusinessPackageFeatures(businessId);
        setSelectedBusiness(businessId);
    }, [])


    useEffect(() => {
        getPackageSummary();
    }, [])

    useEffect(() => {
        if (packageSummaryList) {
            setModifiedPackageList(packageSummaryList);
        }
    }, [packageSummaryList])

    useEffect(() => {
        if (!getBusinessPackageResponse && packageSummaryList) {
            setModifiedPackageList(packageSummaryList);

        } else if (getBusinessPackageResponse) {
            if (packageSummaryList) {
                setModifiedPackageList((prevPackageList) => {
                    return prevPackageList.map((plan) => {
                        if (plan.package.id === getBusinessPackageResponse.data.business_package.packageRecord.id) {
                            return {
                                ...plan,
                                features: getBusinessPackageResponse.data.feature_quota,
                                is_current_plan: true
                            }

                        } else {
                            const originalPlan = packageSummaryList.find(original => original.package.id === plan.package.id);

                            if (originalPlan) {
                                return {
                                    ...plan,
                                    features: originalPlan.features,
                                    is_current_plan: false
                                }
                            }

                            return plan;
                        }
                    });
                });
            }
        }
    }, [getBusinessPackageResponse, packageSummaryList]);


    return (
        <Box sx={{ width: "99%", margin: "auto", mt: "17px !important", mb: "24px !important" }}>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 18 }}>
                                Select Enterprise Plan
                            </Typography>

                            <Typography variant="subtitle2" sx={{ color: "rgb(0 0 0 / 45%)" }}>
                                Select a plan that works best for you and your team
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {(isRetrievingPackageSummary || isFetchingBusinessPackageFeatures) ?
                            <Grid container spacing={2} sx={{ width: "95%", margin: "auto", mt: 3, mb: 1 }}>
                                {[0, 1, 2].map((index) => (
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                                        <PackageSkeletonComponent breakpoints={breakpoints} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <PackageCardScreenComponent
                                breakpoints={breakpoints}
                                modifiedPackageList={modifiedPackageList}
                                selectedBusiness={selectedBusiness}
                            />
                        }
                    </Grid>
                </>
            }

        </Box>
    )
}

export default EnterprisePlanComponent