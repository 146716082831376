import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpActualPostMultipart, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const generateReportOne = (business_id,date_from, date_to, type) => {

    let query_params = {
        business_id: business_id,
        date_from: date_from,
        date_to: date_to,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.REPORT_ONE, query_params, subscriptionKey);
    
    return result;
}

const generateReportTwo = (business_id,project_id,date_from, date_to, type) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.REPORT_ONE, query_params, subscriptionKey);
    
    return result;
}


const generateReportThree = (business_id,project_id,date_from, date_to, type, page, limit) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        type: type,
        page: page,
        limit: limit
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.REPORT_ONE, query_params, subscriptionKey);
    
    return result;
}


const generateReportAllThree = (business_id,project_id,date_from, date_to, type, limit) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        type: type,
        limit: limit
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.REPORT_ONE, query_params, subscriptionKey);
    
    return result;
}


const generateReportFour = (business_id,project_id,date_from, date_to, type, page, limit) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        type: type,
        page: page,
        limit: limit
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.REPORT_ONE, query_params, subscriptionKey);
    
    return result;
}

const generateReportByEnterprise = (business_id, date_from, date_to, page, limit,type) => {

    let query_params = {
        business_id: business_id,
        date_from: date_from,
        date_to: date_to,
        page: page,
        limit: limit,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}

const generateReportByEnterpriseAll = (business_id, date_from, date_to, page,type) => {

    let query_params = {
        business_id: business_id,
        date_from: date_from,
        date_to: date_to,
        page: page,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}

const generateReportByEnterpriseProject = (business_id, project_id, date_from, date_to, page, limit,type) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        page: page,
        limit: limit,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}


const viewGenerateReportByEnterpriseProject = (business_id, project_id, date_from, date_to, page, limit,type) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        page: page,
        limit: limit,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}

const viewGenerateReportByEnterpriseProjectAll = (business_id, project_id, date_from, date_to, limit,type) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        limit: limit,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("query param here :", query_params)
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}

const viewGenerateReportByProject = (business_id, project_id, date_from, date_to, page, limit,type) => {

    let query_params = {
        business_id: business_id,
        project_id: project_id,
        date_from: date_from,
        date_to: date_to,
        page: page,
        limit: limit,
        type: type
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_REPORTS, query_params, subscriptionKey);
    
    return result;
}


const uploadSOA = (formData) => {

    let payload = formData

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPostMultipart(routes.UPLOAD_SOA, payload, subscriptionKey);

    return result;

}

const retrieveProjectSOA = (projectId, businessId, filename, limit, page) => {

    let query_params = {
        projectId: projectId,
        businessId: businessId,
        filename: filename,
        limit: limit,
        page: page
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_PROJECT_SOA, query_params, subscriptionKey);

    return result;

}

const retrieveSOA = (businessId, filename, limit, page) => {

    let query_params = {
        businessId: businessId,
        filename: filename,
        limit: limit,
        page: page
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_SOA, query_params, subscriptionKey);

    return result;

}

const deleteProjectSOA = (soaId) => {

    let query_params = {
        soaId: soaId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_SOA, query_params, subscriptionKey);

    return result;

}

const getStorageUsage = (businessId, date_from, date_to) => {

    let query_params = {
        businessId: businessId,
        date_from: date_from,
        date_to: date_to
    }


    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.REPORT_STORAGE, query_params, subscriptionKey);

    return result;

}


const getProjectStorageUsage = (projectId, businessId, date_from, date_to) => {

    let query_params = {
        projectId: projectId,
        businessId: businessId,
        date_from: date_from,
        date_to: date_to
    }


    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.REPORT_STORAGE, query_params, subscriptionKey);

    return result;

}


const AdminReportsService = {
    generateReportOne,
    generateReportTwo,
    generateReportThree,
    generateReportFour,
    generateReportAllThree,
    generateReportByEnterprise,
    generateReportByEnterpriseProject,
    viewGenerateReportByEnterpriseProject,
    viewGenerateReportByProject,
    uploadSOA,
    retrieveProjectSOA,
    retrieveSOA,
    deleteProjectSOA,
    generateReportByEnterpriseAll,
    viewGenerateReportByEnterpriseProjectAll,
    getStorageUsage,
    getProjectStorageUsage
}

export default AdminReportsService