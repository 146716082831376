import {useEffect, useState} from "react";
import {Box, Button, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import EmailSenderListComponent from "../components/enterprise/email-sender-list.component";
import useEmailSender from "../hooks/useEmailSender";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';

import AddEmailSenderDialogComponent from "../components/email-authentication/email-authentication-dialog/add-email-sender-dialog.component";

function EmailAuthenticationHeader ({project_name}) {

    const navigate = useNavigate();

    return (
        <>
            <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
                <Grid container sx={{textAlign: "start", ml: 2, mt: 1}}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6">
                            Email Sender
                        </Typography>
                        <Typography sx={{color: "gray", mt: "5px"}}>
                            Your email sender's settings are configurable. You can integrate your email sender with your system to automate email sending once it has been configured. 
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const EmailSenderScreen = (props) => {

    const {breakpoints, projectId, project_name} = props;

    const {isFetchingEmailSender, emailSenderList, isSearchingEmailSender, isSearchToggle, getListEmailSender, 
        searchEmailSenderByEmail, isSearchedResultsEmpty, isEmailSenderListEmpty, searchedEmailSender, pagination
    } = useEmailSender();

    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [viewEmailAuthentication, setViewEmailAuthentication] = useState(false)

    const [openAddEmailSenderDialog, setOpenAddEmailSenderDialog] = useState(false);
    const [authenticationId, setAuthenticationId] = useState(null)

    let businessId = (new URLSearchParams(window.location.search)).get("id");

    const handleChangePagination = () => {
        setPage(page)
    }

    console.log("businessId aabb", businessId);

    useEffect(() => {
        getListEmailSender(projectId, page);
    }, [projectId, page])


    const handleReload = (e) => {
        setSearchInput("");
        getListEmailSender(projectId, page);
    }

    const handleTextFieldChange = (e) => {
        setSearchInput(e.target.value)
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            searchEmailSenderByEmail(searchInput);
        }
     }

    const handleClickViewAuthentication = (id) => {
        setAuthenticationId(id);
        setViewEmailAuthentication(true)
    }

    const handleAddEmailSender = () => {
        setOpenAddEmailSenderDialog(true)
    }

    const handleCloseAddEmailSender = () => { setOpenAddEmailSenderDialog(false) }

    const onCreateEmailSender = () => {
        getListEmailSender(projectId, page);
    }

    return (
        <Box>

            {viewEmailAuthentication ? 
                null

            :
                <>
                    <EmailAuthenticationHeader project_name={project_name} />
                    <br />
                    <Grid container spacing={3} sx={{textAlign: "end", padding: "16px 20px 0px 20px", justifyContent: "flex-end"}}>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 200 } }}
                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                            placeholder="Search Email..."
                            InputProps={{
                                startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                            }}
                            value={searchInput}
                            onChange={handleTextFieldChange}
                            onKeyDown={keyPress}
                        />

                        &nbsp; &nbsp;

                        <IconButton onClick={(e) => handleReload(e)}>
                            <CachedIcon sx={{height: 20, width: 20, color: "rgb(25, 118, 210)"}} />
                        </IconButton>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="contained" onClick={handleAddEmailSender}>Configure Email Sender</Button>
                    </Grid>
                </>
            }

            <EmailSenderListComponent 
                isFetchingEmailSender={isFetchingEmailSender}
                isSearchingEmailSender={isSearchingEmailSender}
                emailSenderList={emailSenderList}
                isSearchToggle={isSearchToggle}
                isSearchedResultsEmpty={isSearchedResultsEmpty}
                isEmailSenderListEmpty={isEmailSenderListEmpty}
                searchedEmailSender={searchedEmailSender}
                viewEmailAuthentication={viewEmailAuthentication} 
                handleClickViewAuthentication={handleClickViewAuthentication}
                authenticationId={authenticationId}
                getListEmailSender={getListEmailSender}
                projectId={projectId} page={page} handleChangePagination={handleChangePagination} pagination={pagination}

            />

            {openAddEmailSenderDialog &&
                <AddEmailSenderDialogComponent 
                    businessId={businessId}
                    projectId={projectId}
                    hasBusinessAndProjectIds={true}
                    openAddEmailSenderDialog={openAddEmailSenderDialog} 
                    handleClose={handleCloseAddEmailSender}
                    onCreateEmailSender={onCreateEmailSender}
                    getListEmailSender={getListEmailSender}
                />
            }
        </Box>
    )
}
export default EmailSenderScreen