import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, Typography, Dialog, DialogTitle, Divider, DialogContent, DialogActions, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {ThreeDots} from 'react-loader-spinner';
import useEsignProjectWhitelistEmails from "../../hooks/useEsignProjectWhitelistEmails";
import { useSnackbar } from "notistack";

function SignatoryEmailComponent({projectId, businessId, onError}){
    
    const { whitelist_emails, loading, error, getSignatoryWhitelistEmails } = useEsignProjectWhitelistEmails();
    const { enqueueSnackbar } = useSnackbar();
    const [ open_addEmailDialog, setOpen_addEmailDialog ] = useState(null)
    const [ open_deleteEmailDialog, setOpen_deleteEmailDialog ] = useState(null)
    const [ selectedEmail, setSelectedEmail ] = useState("")

    useEffect(()=>{
        if(error == null) return;
        onError(error)
    }, [error])

    useEffect(()=>{
        getSignatoryWhitelistEmails(projectId, businessId, 1, 10);
    }, [])

    function handleOnClickDelete(email){
        setSelectedEmail(email)
        setOpen_deleteEmailDialog(true)
    }

    function handleOnAddEmail(data){
        enqueueSnackbar(data.data, {
            variant: "success",
            autoHideDuration: 2000
        });
        setOpen_addEmailDialog(false);
        getSignatoryWhitelistEmails(projectId, businessId, 1, 10);
    }

    function handleOnDeleteEmail(data){
        enqueueSnackbar(data.data, {
            variant: "success",
            autoHideDuration: 2000
        });
        setOpen_deleteEmailDialog(false);
        setSelectedEmail(null);
        getSignatoryWhitelistEmails(projectId, businessId, 1, 10);
    }

    if(loading) return(<>loading . . .</>);
    if(error != null) return null;

    return(
        <>
            <Box textAlign='center' sx={{mb: 1}}>
                <Button 
                    variant="text" 
                    startIcon={<AddIcon />}
                    sx={{ textTransform: 'none', fontWeight: 'bold', borderColor: '#0178f2', borderRadius: 1 }}
                    onClick={()=>setOpen_addEmailDialog(true)}>
                    Add Email
                </Button>
            </Box>
            <SignatoryEmailList whitelist_emails={whitelist_emails} error={error} onClickDeleteIcon={(email)=>handleOnClickDelete(email)} />
            <AddEmailDialog 
                open={open_addEmailDialog}
                onClickCancel={() => setOpen_addEmailDialog(false)}
                projectId={projectId}
                businessId={businessId}
                onAddEmail={(data) => handleOnAddEmail(data) }
            />
            <DeleteEmailDialog
                email={selectedEmail}
                open={open_deleteEmailDialog} 
                onClickCancel={() => setOpen_deleteEmailDialog(false) }
                onDeleteEmail={(data) => handleOnDeleteEmail(data)}
            />
        </>
    )
}

function SignatoryEmailList({whitelist_emails, onClickDeleteIcon}){
    return(
        <Box sx={{ border: '1px solid #0178f2', borderRadius: 1 }}>
            { whitelist_emails.length == 0 && <Typography sx={{p: 2}} textAlign='center'>No email found.</Typography> }
            <List sx={{ p: '0px' }}>
                { whitelist_emails.map((email, index)=> {
                    return(
                        <SignatoryEmailListItem key={index} email={email.email} onClickDelete={()=>onClickDeleteIcon(email)}/>
                    )
                })
                }
            </List>
        </Box>
    )
}

function SignatoryEmailListItem({email, onClickDelete}){
    return(
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => onClickDelete(email)} >
                    <CloseIcon sx={{ color: 'red' }} />
                </IconButton>
            }
        >
        <ListItemText primary={email}/>
    </ListItem>
    )
}

function AddEmailDialog({projectId, businessId, open, onClickCancel, onAddEmail}){

    const [ email, setEmail ] = useState("")
    const { data, loading, error, createSignatoryWhitelistEmails } = useEsignProjectWhitelistEmails();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(data == null) return;
        onAddEmail(data);
    }, [data]);

    useEffect(()=>{
        if(error == null) return;
        enqueueSnackbar(error.error, {
            variant: "error",
            autoHideDuration: 2000
        });
    }, [error])

    const handleOnClickAdd = (projectId, businessId, email) => {
        var email_to_create = [];
        email_to_create.push({email: email});
        createSignatoryWhitelistEmails(projectId, businessId, email_to_create)
    }

    const handleOnClickCancel = () => {
        setEmail("");
        onClickCancel();
    }

    const handleTextChange = (e) => {
        setEmail(e.target.value);
    }

    return(
        <Dialog 
            open={open} 
            onClose={null}  
            maxWidth={'sm'} 
            fullWidth={'sm'} 
            PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}
            >
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Add Signatory Email
            </DialogTitle>
            <Divider />
            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Grid item xs={12} sx={{alignSelf: "center"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  sx={{alignSelf: "center", fontWeight:'bold'}}>
                            Email
                        </Grid>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                value={email}
                                onChange={(e) => handleTextChange(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10 }} onClick={handleOnClickCancel}>
                    Cancel
                </Button>
                <Button
                    disabled={email == ""}
                    variant="contained" 
                    sx={{ width: 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleOnClickAdd(projectId, businessId, email)}
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}>
                        { loading ? "Adding" : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function DeleteEmailDialog({open, onClickCancel, email, onDeleteEmail}){

    const { data, loading, error, deleteSignatoryWhitelistEmails } = useEsignProjectWhitelistEmails();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(data == null) return;
        onDeleteEmail(data);
    }, [data]);

    useEffect(()=>{
        if(error == null) return;
        enqueueSnackbar(error.error, {
            variant: "error",
            autoHideDuration: 2000
        });
    }, [error])

    const handleOnClickDelete = (idEmail) => {
        deleteSignatoryWhitelistEmails(idEmail)
    }

    return(
        <Dialog 
            open={open} 
            onClose={null}  
            maxWidth={'sm'} 
            fullWidth={'sm'} 
            PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}
            >
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Delete Signatory Email
            </DialogTitle>
            <Divider />
            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete permanently <strong>{email?.email}</strong>?
                </Typography>
            </DialogContent>
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={onClickCancel}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={()=>handleOnClickDelete(email.id)} 
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}>
                    {loading ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default SignatoryEmailComponent;