import React, {useState, useRef, useEffect} from "react";
import {TextField, Box, Grid, Checkbox, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AddIcon from '@mui/icons-material/Add';

import {FileUploader } from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import {ThreeDots} from "react-loader-spinner";
import AddImgIcon from "../../../../icons/add-img.png";
import useSignature from "../../../../hooks/useSignature";
import { convertUrlToFile } from "../../../../utils/convertUrlToFileUtil";
import { log } from "../../../../console-config.log";
import SignatureActionsPopperComponent from "./signature-actions-popper.component";
import EditTemplateSignatureDialog from "./edit-template-signature.dialog";
import DeleteTemplateSignatureDialog from "./delete-project-signature.dialog";
import AddSignatureConfirmationDialog from "./add-signature-confirmation.dialog";

// import {convertUrlToFile} from "../../utils/convertUrlToFileUtil";

const AddTemplateSignatureDialog = (props) => {

    const {template, openAddSignature, setOpenAddSignature, breakpoints, setIsSignatureCreated, openSignatureConfirmation, setOpenSignatureConfirmation} = props;

    const {enqueueSnackbar} = useSnackbar();

    const {isAddingSignature, createSignature} = useSignature();

    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [isFullnameRequired, setFullnameRequired] = useState(false);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);
    

    const [imageURL, setImageURL] = useState(null);
    const [resultURL, setResultURL] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    const handleChange = (file) => {
        setHasChanges(true)

        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "svg" || mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            if (file.size <= 1 * 1024 * 1024) {
                // const reader = new FileReader();

                // reader.onload = (e) => {
                //     setImageURL(e.target.result);
                // }

                // reader.readAsDataURL(file);

                setSignatureFile(file);
                setSignaturePreview(URL.createObjectURL(file));

                // removeWhiteBackground();

            } else {
                enqueueSnackbar("File exceeds 1MB, please choose another image file", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
            
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/;

        return re.test(email);
    }

    const handleTextFieldChange = (e, name) => {
        setHasChanges(true)

        if (name == "fullname") {
            setFullname(e.target.value);
            setFullnameRequired(false);

        } else if (name == "email") {
            let user_email = e.target.value;

            setEmail(user_email);
            setEmailRequired(false);

            if (user_email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }
        }
    }

    const handleResetFile = (error) => {      
        setImageURL(null);
        setResultURL(null);
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const handleCloseDialog = (e) => {
        setOpenAddSignature(false);
        setSignatureFile(null);
        setImageURL(null);
        setResultURL(null);
    }

    const handleCancel = (e) => {
        handleCloseDialog();
    }

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
      
              // Draw the image on the canvas
              ctx.drawImage(image, 0, 0);
      
              // Get the image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
      
              // Remove the background (assuming light background and dark signature lines)
              const tolerance = 30; // Adjust this value to set the tolerance for background removal
              for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];
                const alpha = data[i + 3];
            
                // Check if the pixel color is close to red, green, yellow, blue, or white
                if (
                    // Red
                    (red >= 200 && green <= 100 && blue <= 100) ||
                    // Green
                    (red <= 100 && green >= 150 && blue <= 100) || // Adjusted the green range
                    // Blue
                    (red <= 100 && green <= 100 && blue >= 200) ||
                    // Yellow
                    (red >= 200 && green >= 200 && blue <= 100) ||
                    // White
                    (red >= 200 && green >= 200 && blue >= 200)
                ) {
                    data[i + 3] = 0; // Set alpha to 0 (transparent)
                }
            }


      
              // Put the modified image data back to the canvas
              ctx.putImageData(imageData, 0, 0);
      
              // Convert the canvas content back to base64 string
              const modifiedImage = canvas.toDataURL();
      
              resolve(modifiedImage);
            };
      
            image.src = event.target.result;
          };
      
          reader.readAsDataURL(imageFile);
        });
    };

    const handleOpenSignatureConfirmation = () => {
        setOpenSignatureConfirmation(true);
        // setOpenAddSignature(false)
    }

    const handleCloseConfirmation = () => {
        setOpenSignatureConfirmation(false);
        setOpenAddSignature(true)
    }

    const handleCreateSignature = (e) => {
        if (email && signatureFile && isValidEmail) {
            // let formData = new FormData();

            // formData.append("signature", signatureFile);
            // formData.append("fullName", fullname);
            // formData.append("email", email);

            // createSignature(formData, setOpenAddSignature, setIsSignatureCreated);
            removeWhiteBackground(signatureFile)
            .then((modifiedImage) => {
                // Use the modified image as needed (e.g., display or upload)
                log(modifiedImage);
                log("create signature aaa", template);
                convertUrlToFile(modifiedImage, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {
                    createSignature(editedSignatureFile, email, template.project_id, setOpenAddSignature, setIsSignatureCreated);
                });

            })
            
        } else if (!signatureFile) {
            enqueueSnackbar("Signature File is required", {
                variant: "error",
                autoHideDuration: 3000
            });

        } 
        
        // else if (!fullname) {
        //     setFullnameRequired(true);

        // } 
        
        else if (!email) {
            setEmailRequired(true);

        } else if (!isValidEmail) {
            enqueueSnackbar("Invalid email address format", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

  
    return (
        <>
        <Dialog open={openAddSignature}  hideBackdrop={true} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10, display: openSignatureConfirmation == true ? 'none' : ''}}}>
                <DialogTitle sx={{fontWeight: "bold"}}>
                Add Signature
                </DialogTitle>

                <Divider/>
                
                <DialogContent sx={{padding: (signaturePreview) ? "1px 24px 10px" : "24px 24px 10px"}}>
                    
                    {signaturePreview &&
                        <Grid sx={{textAlign: "end"}}>
                            <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                                Clear
                            </Button>
                        </Grid>
                    }

                    <FileUploader
                        fileOrFiles={signatureFile}
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Select an image file to upload or drag and drop it here"
                        children={
                            <>
                                {(signatureFile) ?
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            border: "dashed 2.2px #0178f2",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "210px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear"
                                        }}
                                    >
                                        <img alt="" src={signaturePreview}
                                            style={{
                                                height: (breakpoints == 'xs' || breakpoints == 'sm') ? 130 : 160,
                                                width: "75%", objectFit: "contain"
                                            }}
                                        />
                                    </div>
                                :

                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            border: "dashed 2.2px #0178f2",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "210px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear",
                                        }}
                                    >
                                        <Grid>
                                            <Grid sx={{ textAlign: "center" }}>
                                                <img alt="" src={AddImgIcon} style={{width: 50, height: 50, objectFit: "contain"}} />
                                            </Grid>

                                            <Typography variant="subtitle1" style={{color: "#0178f2", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 16}}>
                                                Select an image file to upload
                                            </Typography>

                                            <Typography variant="subtitle2" style={{textAlign: "center", color: "rgb(0 0 0 / 33%)", fontWeight: 500, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 14}}>
                                                or drag and drop it here
                                            </Typography>
                                        </Grid>
                                    </div>
                                }
                            </>
                        }        
                    />

                    <Grid container spacing={2} sx={{width: "100%", display: "flex", mt: "16px !important", margin: "auto"}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => handleTextFieldChange(e, "email")}
                            inputProps={{style: {padding: "10px", fontSize: 14}}}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {borderColor: "#0178f2 !important"}
                                }
                            }}
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -12px"}}>
                                    {(isEmailRequired) ? "Email is required" : (!isValidEmail) ? "Email Address is invalid" : ""}
                                </p>
                            }
                        />
                    </Grid>

                </DialogContent>
    
                <DialogActions sx={{padding: "8px 22px 20px 0px"}}>
                    <Button sx={{width: "15%", borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14}} variant="outlined"
                        onClick={(e) => handleCancel(e)}
                    >
                        Cancel
                    </Button>

                    {/* <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2",
                        width: (isAddingSignature) ? "auto" : "15%", '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                        startIcon={(isAddingSignature) && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                        onClick={(e) => handleCreateSignature(e)}
                        disabled={!hasChanges || isAddingSignature}
                    >
                        {(isAddingSignature) ? "Saving" : "Save"}
                    </Button> */}
                    <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2",
                        width: (isAddingSignature) ? "auto" : "15%", '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                        onClick={handleOpenSignatureConfirmation}
                        disabled={!hasChanges || isAddingSignature}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        <AddSignatureConfirmationDialog openSignatureConfirmation={openSignatureConfirmation} handleCloseConfirmation={handleCloseConfirmation} isAddingSignature={isAddingSignature}
            setOpenAddSignature={setOpenAddSignature} handleCreateSignature={handleCreateSignature} signaturePreview={signaturePreview} email={email} hasChanges={hasChanges} />
        </>
    )
}

export default AddTemplateSignatureDialog