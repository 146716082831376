import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch, HttpPost1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getAdminActivityLogs = (month, year, module, action, email, page, limit) => {

    let query_params = {
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_ADMIN_ACTIVIY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const searchAdminActivityLogs = (month, year, module, action, email, page, limit, searchLogs) => {

    let query_params = {
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page, 
        limit: limit
    };

    let payload = searchLogs;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost1(routes.SEARCH_ADMIN_ACTIVIY_LOGS, query_params, payload, subscriptionKey);
    
    return result;
}

const getBusinessActivityLogs = (businessId, month, year, module, action, email, page, limit) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_BUSINESS_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const searchBusinessActivityLogs = (businessId, month, year, module, action, email, page, limit, searchLogs) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page, 
        limit: limit
    };

    let payload = searchLogs;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost1(routes.SEARCH_ADMIN_BUSINESS_ACTIVITY_LOGS, query_params, payload, subscriptionKey);
    
    return result;
}


const getProjectActivityLogs = (businessId, projectId, month, year, module, action, email, page, limit) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_PROJECT_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}


const searchProjectActivityLogs = (businessId, projectId, month, year, module, action, email, page, limit, searchLogs) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page, 
        limit: limit
    };

    let payload = searchLogs;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost1(routes.SEARCH_ADMIN_PROJECT_ACTIVITY_LOGS, query_params, payload, subscriptionKey);
    
    return result;
}


const getUserActivityLogs = (businessId, userId, month, year, module, action, email, page, limit ) => {

    let query_params = {
        businessId: businessId,
        userId: userId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page, 
        limit: limit
    };

    console.log("getUserActivityLogs query_params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ADMIN_USER_ACTIVITY_LOGS, query_params, subscriptionKey);
    
    return result;
}

const searchUserActivityLogs = (businessId,  month, year, module, action, email, page, limit, searchLogs) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        module: module,
        action: action, 
        email: email,
        page: page, 
        limit: limit
    };

    let payload = searchLogs;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost1(routes.SEARCH_ADMIN_USER_ACTIVITY_LOGS, query_params, payload, subscriptionKey);
    
    return result;
}

const AdminActivityLogsService = {
    getAdminActivityLogs,
    searchAdminActivityLogs,
    getBusinessActivityLogs,
    searchBusinessActivityLogs,
    getProjectActivityLogs,
    searchProjectActivityLogs,
    getUserActivityLogs,
    searchUserActivityLogs

}

export default AdminActivityLogsService