import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {ThreeDots} from 'react-loader-spinner';
import useProjects from "../../../hooks/useProjects";

const UpdateProjectDialog = (props) => {

    const {openUpdateProject, handleClose, project, setUpdatedApplicationName,  setProjectIsUpdated, project_is_updated,  updatedApplicationName} = props;

    const {updateProjects, updatingProject, dataUpdated, updatedData} = useProjects();

    const [applicationName, setApplicationName] = useState(project_is_updated ? updatedApplicationName : project?.application_name);
    const [descriptions, setDescription] = useState(project?.description);
    const [isProjectNameRequired, setProjectNameRequired] = useState(false);
    const [isDescriptionRequired, setDescriptionRequired] = useState(false);

  
    const handleChangeApplicationName = (e) => {
        setApplicationName(e.target.value);
        setProjectNameRequired(false);
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
        setDescriptionRequired(false);
    }

    const updateProjectDetails = (e) => {
        let id = project?.id;

        if (!applicationName) {
            setProjectNameRequired(true);

        } else if (!descriptions) {
            setDescriptionRequired(true);

        } else {
            let application_name =  applicationName;
            let description = descriptions;
            let deleted = project?.deleted;

            setProjectNameRequired(false);
            setDescriptionRequired(false);

            updateProjects(id, application_name, description, deleted);
        }
    }

    const handleCloseDialog = (e) => {
        setProjectNameRequired(false);
        setDescriptionRequired(false);
        setApplicationName(project?.application_name);
        setDescription(project?.description);

        handleClose();
    }

    useEffect(() => {

        if(dataUpdated){
            setUpdatedApplicationName(updatedData?.application_name);
            setProjectIsUpdated(true);

            handleClose();

        }

    },[dataUpdated])


    return (
        <Dialog open={openUpdateProject} onClose={(e) => handleCloseDialog(e)}  maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle >
                Update Project
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined" 
                            onChange={handleChangeApplicationName}
                            value={applicationName}
                            autoComplete="off"  
                            placeholder="Project Name"
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(isProjectNameRequired) && "Project Name is required"}
                                </p>
                            }
                        />
                    </Grid>   

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            multiline 
                            rows="5" 
                            variant="outlined"
                            onChange={handleChangeDescription} 
                            value={descriptions} 
                            autoComplete="off"  
                            placeholder="Description"
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(isDescriptionRequired) && "Description is required"}
                                </p>
                            }
                        />
                    </Grid> 
                </Grid>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={(e) => handleCloseDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', fontSize: 14, '&:hover': {backgroundColor: '#0178f2', color: 'white'}}}
                    onClick={(e) => updateProjectDetails(e)} startIcon={updatingProject && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {updatingProject ? 'Updating' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateProjectDialog