import { Table, TextField, Stack, InputAdornment, Box, Button, ClickAwayListener, Grid, IconButton, ListItemIcon, Pagination, MenuItem, MenuList, Paper, Popper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Masonry } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import React, { useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThreeDots } from "react-loader-spinner";
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import useRolesManagement from "../../hooks/useRolesManagement";
import UpdateRoleDialog from "../rbac/dialogs/update-role.dialog";
import DeleteRoleDialog from "../rbac/dialogs/delete-role.dialog";
import RolesListSkeleton from "../skeletons/roles-list-skeleton.component";

function CardRolesHeader(props) {

    const { data, setStatus, status } = props;

    const { updateAdminRoleStatus, updatingRoleStatus, updatedRoleStatus, active } = useRolesManagement();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdateRole, setOpenUpdateRole] = useState(false);
    const [openDeleteRole, setOpenDeleteRole] = useState(false);

    const handleOpenUpdateRole = () => {
        setOpenUpdateRole(true);
    }

    const handleClosenUpdateRole = () => {
        setOpenUpdateRole(false);
    }

    const handleOpenDeleteRole = () => {
        setOpenDeleteRole(true);
    }

    const handleCloseDeleteRole = () => {
        setOpenDeleteRole(false);
    }


    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const handleUpdateRoleStatus = () => {
        const newStatus = data?.active === true ? 'false' : 'true';
        updateAdminRoleStatus(data?.id, newStatus);
    }


    useEffect(() => {
        if (updatedRoleStatus) {
            handleClick();
            if (data?.id === data?.id) {
                setStatus(active);
            }
        }
    }, [updatedRoleStatus]);


    console.log("status aaa", status);
    console.log("updatedRoleStatus bbb", updatedRoleStatus);




    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleUpdateRoleStatus} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >

                                        {updatingRoleStatus ?
                                            <Button variant="text"
                                                startIcon={
                                                    <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                                                }
                                                sx={{ color: '#0178f2' }}
                                            >
                                                Updating
                                            </Button>
                                        :
                                            <>
                                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: status === true  ? 'red' : 'green' }}>
                                                        {status === true ? (
                                                            <NotInterestedIcon style={{ width: 16, height: 16 }} />
                                                        ) : (
                                                            <CheckCircleOutlineIcon style={{ width: 16, height: 16 }} />
                                                        )}
                                                    </IconButton>
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                    {status === true  ? 'DEACTIVATE' : 'ACTIVATE'}
                                                </Typography>
                                            </>
                                        }
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenUpdateRole} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update Role</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeleteRole} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Delete Role</Typography>
                                    </MenuItem>

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

            {openUpdateRole &&
                <UpdateRoleDialog
                    openUpdateRole={openUpdateRole}
                    handleClose={handleClosenUpdateRole}
                    data={data}
                    getAdminRoles={props.getAdminRoles}
                />
            }

            {openDeleteRole &&
                <DeleteRoleDialog
                    openDeleteRole={openDeleteRole}
                    handleClose={handleCloseDeleteRole}
                    data={data}
                    getAdminRoles={props.getAdminRoles}
                />
            }

        </Stack>
    )
}



const TableHeader = () => {

    const tablecell = [
        { label: 'Role Name' },
        { label: 'Description' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const RolesContent = (props) => {

    const { setView, data, setDataRole } = props;

    const [status, setStatus] = useState(data?.active);

    console.log("status", status)
    console.log("data", data)

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer', color: "#0178f2" }} onClick={() => [setView(true), setDataRole(data)]} >
                {data?.role}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.description}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ color: status === true ? 'green' : 'red' }}>
                {status === true ? 'ACTIVE' : 'INACTIVE'}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(data?.updated_at), 'PP')}
            </TableCell>
            <TableCell>
                <CardRolesHeader data={data} setStatus={setStatus} status={status} getAdminRoles={props.getAdminRoles} />
            </TableCell>
        </TableRow>
    )
}

const AdminRolesComponent = (props) => {

    const { setView, setDataRole, isLoading, rolesList, pagination, isFetchingRoles } = props;

    const [page, setPage] = useState(1);

    const handleChangePagination = (event, page) => {
        setPage(page);
        props.getAdminRoles(page);
    }
    

    return (

        <Box sx={{ width: '100%', margin: 'auto' }}>

            <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 2 }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHeader />

                    {(isLoading) ?
                        <TableBody><RolesListSkeleton /></TableBody>

                        :

                        <TableBody sx={{ width: "100%" }}>
                            {(rolesList?.length > 0) ?
                                (rolesList.map((data, index) => (
                                    <RolesContent
                                        setView={setView}
                                        data={data}
                                        setDataRole={setDataRole}
                                        getAdminRoles={props.getAdminRoles}
                                    />
                                )))
                            :
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Box sx={{padding: 2, width: "100%", borderRadius: 0, overflow: "hidden"}}>
                                            <Grid container sx={{ padding: 3 }}>
                                                <Box sx={{textAlign: "center", margin: "auto"}}>
                                                    <Typography sx={{fontSize: "14px"}}>
                                                        No available role(s) to display yet
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    }
                </Table>

                {!isLoading && rolesList?.length !== 0 && isFetchingRoles &&
                    <Pagination
                        count={pagination}
                        page={page}
                        onChange={handleChangePagination}
                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                }

            </TableContainer>
        </Box>

    )
}

export default AdminRolesComponent