import React, {useState} from "react";
import {log} from "../console-config.log";

const useCacheStorage = () => {

    const [storedCacheData, setStoredCacheData] = useState(null);
    const [isFetchingCache, setFetchingCache] = useState(false);

    // CREATE
    const storeDataInCacheStorage = async (cacheName, key, data) => {
        try {
            const cache = await caches.open(cacheName);

            const response = new Response(JSON.stringify(data), {
                headers: {'Content-Type': 'application/json'},
            });

            await cache.put(key, response);
            log(`Data stored in cache with key: ${key}`);

        } catch (error) {
            log("Error storing data in cache:", error);
            setFetchingCache(false);
        }
    }

    const storeDataInCacheStorageWithExpiration = async (cacheName, key, data, expirationSeconds) => {
        try {
            const cache = await caches.open(cacheName);
      
            const expirationTimestamp = Date.now() + (expirationSeconds * 1000);
      
            const response = new Response(JSON.stringify({
                data: data,
                expiration: expirationTimestamp,
            }), {
                headers: { 'Content-Type': 'application/json' },
            });
      
            await cache.put(key, response);
            log(`Data stored in cache with key: ${key}`);
      
        } catch (error) {
          log("Error storing data in cache:", error);
          setFetchingCache(false);
        }
    }
      

    // READ
    const retrieveDataFromCacheStorage = async (cacheName, key) => {
        setFetchingCache(true);
           
        const cache = await caches.open(cacheName);
        const cachedResponse = await cache.match(key);
    
        if (cachedResponse) {
            const jsonData = await cachedResponse.json();

            setStoredCacheData(jsonData);
            setFetchingCache(false);

            return jsonData;

        } else {
            setFetchingCache(false);

            return null;
        }
    }

    const retrieveDataFromCacheStorageWithExpiration = async (cacheName, key) => {
        try {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(key);
      
            if (cachedResponse) {
                const cachedItem = await cachedResponse.json();
                const expirationTimestamp = cachedItem.expiration;
      
                if (expirationTimestamp > Date.now()) {
                    setStoredCacheData(cachedItem.data);
                    setFetchingCache(false);

                    return cachedItem.data;

                } else {
                    // await cache.delete(cacheName);

                    setStoredCacheData(null);
                    await deleteDataByCacheKeyFromCacheStorage(cacheName, key);

                    return null;    
                }
            } else {
                //
            }
        } catch (error) {
            log("Error retrieving data from cache xxxx", error);
        }
    }

    const retrieveDataFromCacheStorageWithExpiration2 = async (cacheName, key) => {
        setFetchingCache(true);
           
        const cache = await caches.open(cacheName);
        const cachedResponse = await cache.match(key);
    
        if (cachedResponse) {
            const jsonData = await cachedResponse.json();

            setStoredCacheData(jsonData);
            setFetchingCache(false);

            return jsonData;

        } else {
            setFetchingCache(false);

            return null;
        }
    }


    // UPDATE
    const updateDataInCacheStorage = async (cacheName, key, newData) => {
        try {

            const cache = await caches.open(cacheName);
      
            const cachedResponse = await cache.match(key);
      
            if (cachedResponse) {
                const updatedResponse = new Response(JSON.stringify(newData), {
                    headers: {'Content-Type': 'application/json'},
                });
      
                await cache.put(key, updatedResponse);
                log(`Data updated in cache with key: ${key}`);
                log("updatedResponse xxx", updatedResponse);

            } else {
                log(`Data with key ${key} not found in cache. You can use storeDataInCache to add it.`);
            }

        } catch (error) {
            log("Error updating data in cache xxxx", error);
        }
    }

    // DELETE by cache name
    const deleteDataByCacheNameFromCacheStorage = async (cacheName) => {
        try {
           
            await caches.delete(cacheName);
            log(`Cache "${cacheName}" deleted.`);
        
        } catch (error) {
           log(`Error deleting cache "${cacheName}":`, error);
        }
    }

    // DELETE by cache name & key
    const deleteDataByCacheKeyFromCacheStorage = async (cacheName, key) => {
        try {

            const cache = await caches.open(cacheName);
      
            await cache.delete(key);
            log(`Data deleted from cache with key: ${key}`);
        
        } catch (error) {
            log("Error deleting data from cache xxxx", error);
        }
    }


    return {storeDataInCacheStorage, retrieveDataFromCacheStorage, storedCacheData, isFetchingCache,
        updateDataInCacheStorage, deleteDataByCacheNameFromCacheStorage, deleteDataByCacheKeyFromCacheStorage,
        storeDataInCacheStorageWithExpiration, retrieveDataFromCacheStorageWithExpiration
    }
}

export default useCacheStorage