import { Avatar, Box, Button, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../hooks/useBusiness";
import useProjects from "../../hooks/useProjects";
import ReportsTabsComponent from "./reports-tab.component";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';


import CachedIcon from '@mui/icons-material/Cached';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";


function decrypt(text, key) {
    text = atob(text);
    let result = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        result += String.fromCharCode(charCode);
    }
    return result;
}


const AllReportsComponent = () => {

    const projectId = (new URLSearchParams(window.location.search)).get("projectId");
    const businessId = (new URLSearchParams(window.location.search)).get("businessId");
    const formattedDateRange = (new URLSearchParams(window.location.search)).get("data");

    const decryptedFormattedDateRange = decrypt(formattedDateRange, 'ovcode');

    const { getBusinessListNames, business_names, isLoadingBusinessList } = useBusiness();
    const {getProjectList, projects, loadingProjects} = useProjects();


    useEffect(() => {
        getBusinessListNames();
        getProjectList(businessId);
    }, []);

    useEffect(() => {

        if(projects){
                // Parse the projectId from the URL to an integer
                const parsedProjectId = parseInt(projectId);
                        
                // Find the project with the matching projectId
                const matchedProject = projects.find((project) => project.id === parsedProjectId);

                // If a matching project is found, set the selectedProject to its application_name
                if (matchedProject) {
                    setSelectedProject(matchedProject?.application_name);
                }
        }

    },[projects])

    useEffect(() => {

        if(business_names){
                // Parse the projectId from the URL to an integer
                const parsedBusinessId = parseInt(businessId);
                        
                // Find the project with the matching projectId
                const matchedBusiness = business_names.find((business) => business.id === parsedBusinessId);

                // If a matching project is found, set the selectedProject to its application_name
                if (matchedBusiness) {
                    setSelectedBusiness(matchedBusiness?.business_name);
                }
        }

    },[business_names])

  

    
    const [options, setOption] = useState([]);
    const [business_id, setBusinessId] = useState(businessId);
    const [selected_business, setSelectedBusiness] = useState('');
    const [projectOptions, setProjectOptions] = useState([]);
    const [project_id, setProjectId] = useState(projectId);
    const [selectedProject, setSelectedProject] = useState('');


    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };
    const dataStylesTwo = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };

   
    
    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id, label: business?.business_name }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(business_names)])


    useEffect(() => {

        let options = []

        projects?.map((project) => {

            let options_data = { value: project?.id, label: project?.application_name }

            options.push(options_data)
        })

        setProjectOptions(options)

    }, [JSON.stringify(projects)])


    
    const getBusiness = () => value => {
        setBusinessId(value.value);
        setSelectedBusiness(value.label);
    };

    const getProject = () => value => {
        setProjectId(value.value);
        setSelectedProject(value.label);
    };




    return (
        <Box>
            <Box sx={{ paddingBottom: 2, display:'flex', alignItems:'center'}}>
                <Typography variant="h6" sx={{fontWeight: 600, marginRight: 2}}>
                    OVCODE Report 
                </Typography>
            </Box>

            <Grid container sx={{ position: 'relative', zIndex: 2 }}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sx={{alignSelf: 'center'}}>
                            <Button
                                startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
                                style={{ color: "#000",  textTransform: 'none', fontWeight: 600, display: 'flex', alignItems: 'center' }}
                                onClick={() => window.history.back()}
                            >
                                <Typography sx={{ fontWeight: 'bold', cursor: 'pointer', fontSize: 14 }}>
                                    {selectedProject && selectedProject}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{justifyContent: 'flex-end', textAlign: 'end', marginLeft: -2, alignSelf: 'center'}}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                Report as of {decryptedFormattedDateRange}
                            </Typography>
                        </Grid>
                    </Grid>
                   
                </Grid>
                
                <br />

                <Grid item xs={12}>
                    <ReportsTabsComponent 
                        business_id={business_id} 
                        project_id={project_id}
                        selectedProject={selectedProject}
                        selected_business={selected_business}
                    />
                </Grid>
                
            </Grid>
        </Box>
    );
};

export default AllReportsComponent;
