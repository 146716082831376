import {Skeleton, TableRow, Table, TableCell} from "@mui/material";

const AccessControlListSkeleton = () => {

    let items = [];

    for (let i = 0; i < 5; i++) {
        items.push(
            <TableRow key={i} sx={{'&:hover': {backgroundColor: "#fff"}}}>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={28}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={28}/></TableCell>
            </TableRow>
        )
    }

    return <>{items}</>

}

export default AccessControlListSkeleton