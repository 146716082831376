import { Stack, Pagination, Box, Button, Card, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import CachedIcon from '@mui/icons-material/Cached';
import CreditsComponent from "../components/credits/credits.component"
import useCredits from "../hooks/useCredits"
import { useEffect, useState, useRef  } from "react"
import BusinessCreditsListSkeleton from "../components/skeletons/business-credits-list-skeleton.component"
import ProjectCreditsManagementScreen from "./ProjectCreditsManagementScreen"
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCreditDialog from "../components/credits/dialogs/add-credit-dialog.component"
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import useCreditsManagement from "../hooks/useCreditsManagement"
import ForbiddenScreen from "./ForbiddenScreen"

const TableHeader = () => {

    const tablecell = [
        { label: 'Logo' },
        { label: 'Business Name' },
        { label: 'Credits' },
        // { label: 'Unlimited' },
        { label: 'Actions' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}



const CreditsManagementScreen = () => {

    const selectRef = useRef();

    const { isSearchingBusinessCredits, searchedBusinessCredits, searchBusinessCredits, isSearchFilterToggle, isSearchedResultsEmpty, isFetchBusinessCredits,
        isLoading, getBusinessCredits, businessCreditsList, pagination, error
    } = useCreditsManagement();

    const [page, setPage] = useState(1);
    const [isView, setView] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [businessId, setBusinessId] = useState(null);
    const [openAddCredit, setOpenAddCredit] = useState(false);

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState(null);
    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessFilterValue, setBusinessFilterValue] = useState(null);
    const [stateFilterValue, setStateFilterValue] = useState(null);
    const [stateFilterLabel, setStateFilterLabel] = useState(null);
    const [creditFilterValue, setCreditFilterValue] = useState("");


    // useEffect(() => {
    //     getBusinessCredits(page);
    // }, [page])

    useEffect(() => {
        getBusinessOptions(page);
    }, [businessCreditsList])

    console.log("filter value:", filter)

    useEffect(() => {
        if (filter && businessFilterValue) {
            searchBusinessCredits(filter, businessFilterValue, page);

        } else if (filter && stateFilterValue) {
            searchBusinessCredits(filter, stateFilterValue, page);
        } else {
            getBusinessCredits(page);
        }
    }, [businessFilterValue, stateFilterValue, page])


    const handleOpenAddCredit = () => {
        setOpenAddCredit(true);
    }

    const handleCloseAddCredit = () => {
        setOpenAddCredit(false);
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            minHeight: "20px",
            width: 250,
            fontSize: 13,
        })
    }

    const filterOptions = [
        { value: "business_name", label: "Business" },
        { value: "credits", label: "Credits" },
        { value: "is_unlimited", label: "State" }
    ]

    const getBusinessOptions = () => {
        const business_options = [...businessCreditsList?.map((business) => ({
            value: business?.businessAccount?.business_name,
            label: business?.businessAccount?.business_name,
        }))];

        setBusinessOptions(business_options);
    }

    const handleSelectBusinessValue = (selectedOption) => {
        if (selectedOption) {
            setBusinessFilterValue(selectedOption.value);
            setStateFilterValue(null);
        }
    }

    const stateOptions = [
        { value: "1", label: "Unlimited Credits" },
        { value: "0", label: "Limited Credits" },
    ]

    const handleSelectStateValue = (selectedOption) => {
        if (selectedOption) {
            setStateFilterValue(selectedOption.value);
            setStateFilterLabel(selectedOption.label);
            setBusinessFilterValue(null);
            setPage(1)
        }
    }

    const handleSelectFilterOption = (selectedOption) => {
        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {
            setFilter(null);
            setFilterLabel(null);

            handleClearFilter();
        }
    }

    const handleClearFilter = (e) => {
        setPage(1)
        setFilter(null);
        setFilterLabel(null);
        getBusinessCredits(page);
    }

    const handleTextfield = (e) => {
        setBusinessFilterValue(null);
        setStateFilterValue(null);
        setCreditFilterValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (filter && creditFilterValue) {
                searchBusinessCredits(filter, creditFilterValue, page);
            }
        }
    }

    const handleChangePagination = (event, page) => {
        setPage(page);

        if (filter && businessFilterValue) {
            searchBusinessCredits(filter, businessFilterValue, page);

        } else if (filter && stateFilterValue) {
            searchBusinessCredits(filter, stateFilterValue, page);

        } else if (filter && creditFilterValue) {
            searchBusinessCredits(filter, creditFilterValue, page);
        } else {
            getBusinessCredits(page);
        }
    }


    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            {error ?

                <ForbiddenScreen />

                :
                <>
                    {!isView &&
                        <Grid container>
                            <Grid item xs={4} sm={4}>
                                <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                                    Manage Credits
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} sx={{ mt: -1, alignSelf: 'center' }}>
                                <Stack direction="row" spacing={1} sx={{ width: "100%", justifyContent: 'flex-end' }}>
                                    <ReactSelect
                                        ref={selectRef}
                                        className="basic-single"
                                        placeholder={<>
                                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                Filter
                                            </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                {
                                                    (filterLabel === "Status") ?
                                                        <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                            Business
                                                        </Typography>
                                                        :
                                                        (filterLabel === "Template Code") ?
                                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                                Credits
                                                            </Typography>
                                                            :
                                                            (filterLabel === "Title") ?
                                                                <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                                    State
                                                                </Typography>
                                                                :
                                                                (filterLabel === null) ?
                                                                    "Select Filter"
                                                                    :
                                                                    null
                                                }</i>
                                        </>}
                                        isSearchable={true}
                                        isRtl={false}
                                        options={filterOptions}
                                        onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                width: 250,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                                fontSize: 12
                                            }),
                                        }}
                                        isClearable
                                    />

                                    &nbsp;

                                    {(filterLabel === "Business") &&
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={<><i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>{
                                                (filterLabel === "Business" && businessFilterValue) ?
                                                    <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                        {businessFilterValue}
                                                    </Typography>
                                                    :
                                                    "Search Business"
                                            }</i></>}
                                            isSearchable={true}
                                            isRtl={false}
                                            options={businessOptions}
                                            value={businessFilterValue}
                                            onChange={selectedOption => handleSelectBusinessValue(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999, 
                                                    fontSize: 12
                                                }),
                                            }}
                                        />
                                    }

                                    {filterLabel === "State" &&
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={
                                                (filterLabel === "State" && stateFilterValue) ?
                                                    <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                        {stateFilterLabel}
                                                    </Typography>
                                                    :
                                                    "Search"
                                            }
                                            isSearchable={true}
                                            isRtl={false}
                                            options={stateOptions}
                                            value={stateFilterLabel}
                                            onChange={selectedOption => handleSelectStateValue(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    fontSize: 12
                                                }),
                                            }}
                                        />
                                    }


                                    {filterLabel === "Credits" &&
                                        <TextField
                                            value={creditFilterValue}
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                            variant="outlined"
                                            autoComplete="off"
                                            onChange={handleTextfield}
                                            onKeyDown={handleKeyPress}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder={"Input Credits..."}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{'Credits'}:</p></InputAdornment>,
                                            }}
                                        />
                                    }

                                    &nbsp;&nbsp;

                                    <IconButton onClick={()=>{
                                        if (selectRef.current) {
                                            selectRef.current.clearValue();
                                        }
                                    }}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: 14,
                                            p: '4px 5px',
                                            borderRadius: 10,
                                            boxShadow: 'none',
                                            color: '#0178f2',
                                            textTransform: 'none',
                                        }}
                                        startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                        onClick={handleOpenAddCredit}
                                    >
                                        Add Credit
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    }

                    {
                        isView ?
                            <ProjectCreditsManagementScreen setView={setView} businessName={businessName} businessId={businessId} />
                            :
                            <Box sx={{ width: '100%', margin: 'auto', mt: 2 }}>

                                <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                                        <TableHeader />

                                        {(isLoading || isSearchingBusinessCredits) ?
                                            <TableBody><BusinessCreditsListSkeleton /></TableBody>
                                            :
                                            (isSearchFilterToggle && !isSearchedResultsEmpty) ?
                                                <TableBody sx={{ width: "100%" }}>
                                                    {searchedBusinessCredits?.data?.map((credits, index) => (
                                                        <CreditsComponent
                                                            data={credits}
                                                            index={index}
                                                            setView={setView}
                                                            setBusinessName={setBusinessName}
                                                            setBusinessId={setBusinessId}
                                                            getBusinessCredits={getBusinessCredits}
                                                        />
                                                    ))}
                                                </TableBody>
                                                :

                                                (isSearchFilterToggle && isSearchedResultsEmpty) ?
                                                    <TableRow>
                                                        <TableCell colSpan={5}>
                                                            <Typography variant="subtitle2" sx={{ fontSize: 14, textAlign: "center", fontWeight: 500 }}>
                                                                No searched result(s) found.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    (!isSearchFilterToggle) &&
                                                    <TableBody sx={{ width: "100%" }}>
                                                        {businessCreditsList?.map((credits, index) => (
                                                            <CreditsComponent
                                                                data={credits}
                                                                index={index}
                                                                setView={setView}
                                                                setBusinessName={setBusinessName}
                                                                setBusinessId={setBusinessId}
                                                                getBusinessCredits={getBusinessCredits}
                                                            />
                                                        ))}
                                                    </TableBody>

                                        }
                                    </Table>

                                    {(!isLoading && businessCreditsList?.length !== 0 || searchedBusinessCredits?.length !== 0) &&
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangePagination}
                                            hideNextButton={businessCreditsList?.length < 10}
                                            disabled={businessCreditsList?.length === 0}
                                            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                        />
                                    }
                                </TableContainer>
                            </Box>
                    }

                    {
                        openAddCredit &&
                        <AddCreditDialog
                            openAddCredit={openAddCredit}
                            handleClose={handleCloseAddCredit}
                            getBusinessCredits={getBusinessCredits}
                        />
                    }
                </>
            }
        </Box >
    )
}

export default CreditsManagementScreen;