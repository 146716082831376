import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from "react-loader-spinner";
import useRolesManagement from "../../../hooks/useRolesManagement";

const DeleteRoleDialog = (props) => {

    const { openDeleteRole, handleClose, data, retrieved } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const { deletingRole, deleteRole, deleteAdminRole, deletedRole } = useRolesManagement();
    const [page, setPage] = useState(1);

    const handleDeleteRole = () => {

        let role_id = data?.id

        if(url === "/admin-settings") {
            deleteAdminRole(role_id)
        } else {
            deleteRole(role_id)
        }

    }


    useEffect(() => {

        if(deletedRole){
            handleClose()
            if(url === "/admin-settings") {
                props.getAdminRoles(page);
            } else {
                props.getAllRoles();

                if(retrieved){
                    props.getRoles(page);
                }
            }
        }

    },[deletedRole, retrieved, page, url])



    return (
        <Dialog open={openDeleteRole} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                Delete Permission
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                    Are you sure you want to delete <strong>{data?.role}</strong> Role ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={() => handleDeleteRole() }
                    startIcon={deletingRole &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {deletingRole ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteRoleDialog