import {useState} from "react";
import {useSnackbar} from "notistack";
import WebhookService from "../services/api/webhook";

const useWebhook = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [webhook, setWebhook] = useState("");
  const [created, setCreated] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [pagination, setPagination] = useState(0);

  const getWebhook = (productId, businessId) => {

    setLoading(true);

    WebhookService.getWebhook(productId, businessId).then(res=>{

      setLoading(false);

      if(res.data.success){
        setWebhook(res.data.data);

        if (res?.data?.data?.length === 0) {
            setPagination(0);
        } else {
            var last_page = Math.ceil(res?.data?.limit);
            setPagination(last_page);
        }
      }

      

    }).catch((err) => {

      setLoading(false);

    })
  }

  const addWebhook = (payload) => {

    setLoading(true);

    WebhookService.addWebhook(payload).then(res => {
      setLoading(false);

      if (res.data.success) {
        setCreated(true);
        
        enqueueSnackbar("Successfully created webhook", { 
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {

      setLoading(false);

      enqueueSnackbar(err.response.data.error, { 
        variant: 'error',
        autoHideDuration: 3000
      });

    })
  }

  const deleteWebhook = (projectId, businessId) => {

    setLoading(true);

    WebhookService.deleteWebhook(projectId, businessId).then((res) => {

      setLoading(false);

      if(res.data.success){
        setDeleted(true);
        enqueueSnackbar("Successfully deleted webhook", { 
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {

      setLoading(false)

      console.log("err here", err)

      enqueueSnackbar(err.response.data.error, { 
        variant: 'error',
        autoHideDuration: 3000
      });
    })

  }

  const updateWebhook = (projectId, businessId, payload) => {

    setLoading(true);
        
    WebhookService.updateWebhook(projectId, businessId, payload).then((res) => {

      setLoading(false);

      if(res.data.success){
        setUpdated(true);
        enqueueSnackbar("Successfully updated webhook", { 
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {

      setLoading(false);

      enqueueSnackbar(err.response.data.error, { 
        variant: 'error',
        autoHideDuration: 3000
      });
  
    })

  }
 

  return {setLoading, loading , webhook, getWebhook, created, addWebhook, deleted, deleteWebhook, updated, updateWebhook, pagination}

}

export default useWebhook;