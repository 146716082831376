import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";

const PermissionSkeleton = () => {

    return(
        <Card sx={{ borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
            <CardHeader sx={{ paddingBottom: 0 }}
                action={
                    <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                }
            />
            <CardHeader sx={{ paddingBottom: 0, textAlign: 'left' }}
                avatar={
                    <Skeleton variant="circular" style={{backgroundColor: 'rgb(108 139 222 / 30%)', height: 65, width: 65}} />
                }
                title={
                    <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                }
            />
            <br />
            <CardContent sx={{ '&.MuiCardContent-root': { padding: '16px 0px', pt: '0px', width: '100%' } }}>
                <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
            </CardContent>
        </Card>
    )
}

export default PermissionSkeleton;