import {useState} from "react";
import {useSnackbar} from "notistack";
import AuditService from "../services/api/audit";
import AdminActivityLogsService from "../services/api/admin-activity-logs";

const useAdminActivityLogs = () => {

  const {enqueueSnackbar} = useSnackbar();

  const [isFetchingActivityLogs, setFetchingActivityLogs] = useState(false);
  const [isFetchingAllActivityLogs, setFetchingAllActivityLogs] = useState(false);
  const [auditList, setAuditList] = useState([])
  const [auditError, setAuditError] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [error,setError] = useState(false);

  const limit = 10000;
  
    const getAdminActivityLogs = (month, year, module, action, email, page) => {
        // setFetchingAllActivityLogs(true);
        setFetchingActivityLogs(true);

        AdminActivityLogsService.getAdminActivityLogs(month, year, module, action, email, page,limit).then((res) => {
            console.log("getAdminActivityLogs data xxxx", res);

            if (res.data.success) {
                // setFetchingAllActivityLogs(false);
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("getAdminActivityLogs error xxxx", error);

            // setFetchingAllActivityLogs(false);
            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const searchAdminActivityLogs = (month, year, module, action, email, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.searchAdminActivityLogs(month, year, module, action, email, page, limit, searchLogs).then((res) => {
            console.log("searchAdminActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("searchAdminActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const getBusinessActivityLogs = (businessId, month, year, module, action, email, page) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.getBusinessActivityLogs(businessId, month, year, module, action, email, page,limit).then((res) => {
            console.log("getBusinessActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("getBusinessActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const searchBusinessActivityLogs = (businessId, month, year, module, action, email, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.searchBusinessActivityLogs(businessId, month, year, module, action, email, page, limit, searchLogs).then((res) => {
            console.log("searchBusinessActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("searchBusinessActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const getProjectActivityLogs = (businessId, projectId, month, year, module, action, email, page) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.getProjectActivityLogs(businessId, projectId, month, year, module, action, email, page,limit).then((res) => {
            console.log("getProjectActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("getProjectActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }
   

    const searchProjectActivityLogs = (businessId, projectId, month, year, module, action, email, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.searchProjectActivityLogs(businessId, projectId, month, year, module, action, email, page, limit, searchLogs).then((res) => {
            console.log("searchProjectActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("searchProjectActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }
 

    const getUserActivityLogs = (businessId, userId, month, year, module, action, email, page) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.getUserActivityLogs(businessId, userId, month, year, module, action, email, page,limit).then((res) => {
            console.log("getUserActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("getUserActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }
   

    const searchUserActivityLogs = (businessId, userId, month, year, module, action, email, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AdminActivityLogsService.searchUserActivityLogs(businessId, userId, month, year, module, action, email, page, limit, searchLogs).then((res) => {
            console.log("searchUserActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("searchUserActivityLogs error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);
            setError(error.response.data.error);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    return {isFetchingActivityLogs,  isFetchingAllActivityLogs, error, auditList, getAdminActivityLogs, searchAdminActivityLogs, getBusinessActivityLogs, searchBusinessActivityLogs,
        getProjectActivityLogs, searchProjectActivityLogs, getUserActivityLogs, searchUserActivityLogs }

}

export default useAdminActivityLogs