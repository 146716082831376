import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpDelete1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getTransactionsByBusinessId = (businessId,page,limit) => {

    let query_params = {
        businessId: businessId,
        page: page,
        limit: limit
    };
    console.log("getTransactions parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TRANSACTIONS_BY_BUSINESS_ID, query_params, subscriptionKey);
    
    return result;
}

const getTransactionsUnderProject = (businessId,projectId,page,limit) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        page: page,
        limit: limit
    };
    console.log("getTransactionsUnderProject parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TRANSACTIONS_BY_BUSINESS_ID, query_params, subscriptionKey);
    
    return result;
}

const getTransactionsByStatus = (businessId,projectId, status,page,limit) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        status: status,
        page: page,
        limit: limit
    };
    console.log("getTransactionsByStatus parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TRANSACTIONS_BY_BUSINESS_ID, query_params, subscriptionKey);
    
    return result;
}

const filterAuthenticationByReference = (businessId, reference, page, limit) => {

    let query_params = {
        businessId: businessId,
        reference: reference,
        page: page,
        limit: limit
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("filterAuthenticationByReference query_params:", filterAuthenticationByReference)
    
    let result = HttpGet(routes.GET_AUTHENTICATION_BY_REFERENCE, query_params, subscriptionKey);
    
    return result;
}

const getTopBusiness = (page,limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TOP_BUSINESS, query_params, subscriptionKey);
    
    return result;

}

const getCountTransactionByYearAndId = (businessId, date_from, date_to) => {

    let query_params = {
        businessId: businessId,
        date_from: date_from,
        date_to: date_to
    };

    console.log("getCountTransactionByYearAndId params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION_v2, query_params, subscriptionKey)

    return result;
}

const getCountTransactionPerYear = () => {

    let parameters = "";

    console.log("getCountTransactionPerYear params", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION_PER_YEAR, parameters, subscriptionKey)

    return result;
}

const getCountTransactionPerMonth = (year) => {

    let query_params = {
        year: year,
    };

    console.log("getCountTransactionPerMonth params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION_PER_MONTH, query_params, subscriptionKey)

    return result;
}

const retrieveReferenceDetails = (reference,projectId) => {

    let query_params = {
        reference : reference,
        projectId : projectId
    }

    console.log("retrieveReferenceDetails parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_REFERENCE_DETAILS, query_params, subscriptionKey);

    return result;
}

const getCountTransactionPipelinePerYear = (year) => {

    let parameters = "";

    console.log("getCountTransactionPipelinePerYear params", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION_PIPELINE_PER_YEAR, parameters, subscriptionKey)
    return result;
}

const countTransactionsByPipeline = (businessId, date_from, date_to ) => {

    let query_params = {
        businessId: businessId,
        date_from: date_from,
        date_to: date_to
    }
    
    console.log("countTransactionsByPipeline parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_COUNT_TRANSACTION, query_params, subscriptionKey);
    
    return result;
}

const getCountTransactionByProject = (businessId) => {

    let query_params = {
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.COUNT_TRANSACTION_BY_PROJECT_ALL, query_params, subscriptionKey)
    return result;
}

const getTransactionsByReference = (businessId, reference, page,limit) => {

    let query_params = {
        businessId: businessId,
        reference: reference,
        page: page,
        limit: limit
    };
    console.log("getTransactions parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TRANSACTIONS_BY_BUSINESS_ID, query_params, subscriptionKey);
    
    return result;
}

const filterAuthenticationUnderProjectByReference = (businessId, projectId, reference, page,limit) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        reference: reference,
        page: page,
        limit: limit
    };
    console.log("filterAuthenticationUnderProjectByReference parameters:", query_params);
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TRANSACTIONS_BY_BUSINESS_ID, query_params, subscriptionKey);
    
    return result;
}

const getCountPipelineByEnterpriseAndDateRange = (businessId, date_from, date_to) => {

    let query_params = {
        businessId: businessId,
        date_from: date_from,
        date_to: date_to
    };

    console.log("getCountTransactionByYearAndId params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION, query_params, subscriptionKey)

    return result;
}

const getCountPipelineByProjectEnterpriseAndDateRange = (businessId, projectId, date_from, date_to) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        date_from: date_from,
        date_to: date_to
    };

    console.log("getCountPipelineByProjectEnterpriseAndDateRange params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_COUNT_TRANSACTION, query_params, subscriptionKey)

    return result;
}

const getDashboardCount = () => {

    let query_params = null;
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DASHBOARD_COUNT, query_params, subscriptionKey)
    
    return result;
}  

const getEnterpriseDashboardCount = (businessId) => {

    let query_params = {
        businessId: businessId
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DASHBOARD_COUNT, query_params, subscriptionKey)
    
    return result;
}  

const getEnterpriseDashboardCountFilter = (businessId, month, year) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DASHBOARD_COUNT_FILTER, query_params, subscriptionKey)
    
    return result;
} 

const getDashboardCountFilter = (businessId, month, year, projectId) => {

    let query_params = {
        businessId: businessId,
        month: month,
        year: year,
        projectId: projectId
    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DASHBOARD_COUNT_FILTER, query_params, subscriptionKey)
    
    return result;
} 

const getRecentActivity = (businessId) => {

    let query_params = {
        businessId: businessId,

    };
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_RECENT_ACTIVITY, query_params, subscriptionKey);
    
    return result;
} 


const deleteTransaction = (businessId, projectId, reference) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId,
        reference: reference
    };

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete1(routes.DELETE_TRANSACTION, query_params, subscriptionKey);

    return result;
}


const AdminAnalyticsService = {
    getTransactionsByBusinessId,
    getTopBusiness,
    getCountTransactionByYearAndId,
    getCountTransactionPerYear,
    getCountTransactionPerMonth,
    retrieveReferenceDetails,
    getCountTransactionPipelinePerYear,
    countTransactionsByPipeline,
    getCountTransactionByProject,
    getTransactionsByReference,
    filterAuthenticationByReference,
    getTransactionsUnderProject,
    filterAuthenticationUnderProjectByReference,
    getCountPipelineByEnterpriseAndDateRange,
    getCountPipelineByProjectEnterpriseAndDateRange,
    getDashboardCount,
    getDashboardCountFilter,
    getRecentActivity,
    getEnterpriseDashboardCountFilter,
    getEnterpriseDashboardCount,
    getTransactionsByStatus,
    deleteTransaction
}

export default AdminAnalyticsService