import {useState} from "react";
import {useSnackbar} from "notistack";
import AuditService from "../services/api/audit";

const useActivityLogs = () => {

  const {enqueueSnackbar} = useSnackbar();

  const [isFetchingActivityLogs, setFetchingActivityLogs] = useState(false);
  const [auditList, setAuditList] = useState([])
  const [auditError, setAuditError] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [error,setError] = useState(false);

  const limit = 10;
  
    const getBusinessAuditsLogs = (businessId, page) => {
        setFetchingActivityLogs(true);

        AuditService.getBusinessAuditsLogs(businessId, page, 10000).then((res) => {
            console.log("getBusinessAuditsLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit); 

                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);
            
            if(error.response.data.error === "Forbidden."){
                setError(true)
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            }
            })
           
    }

    const searchBusinessActivityLogsList = (businessId, month, year, module, action, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AuditService.searchBusinessActivityLogsList(businessId, month, year, module, action, page, searchLogs).then((res) => {
            console.log("searchBusinessActivityLogsList data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit);

                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const businessSearchActivityLogsFilters = (businessId, month, year, module, action, page) => {
        setFetchingActivityLogs(true);

        AuditService.businessSearchActivityLogsFilters(businessId, month, year, module, action, page).then((res) => {
            console.log("businessSearchActivityLogsFilters data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                if (res.data.data.length === 0) {
                    setPagination(0);

                } else {
                    var last_page = Math.ceil(res.data.length / limit);

                    setPagination(last_page);
                }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const searchBusinessAuditLogs = (searchLogs, page) => {
        setFetchingActivityLogs(true);

        AuditService.searchBusinessAuditLogs(searchLogs, page).then((res) => {
            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit);
                    
                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const businessSearchEmailFilters = (businessId, userId, page) => {
        setFetchingActivityLogs(true);

        AuditService.businessSearchEmailFilters(businessId, userId, page).then((res) => {
            console.log("businessSearchEmailFilters data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit);
                    
                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            console.log("businessSearchEmailFilters error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const getEnterpriseProjectAuditLogs = (businessId, projectId, page) => {
        setFetchingActivityLogs(true);

        AuditService.getEnterpriseProjectAuditLogs(businessId, projectId, page).then((res) => {
            console.log("getEnterpriseProjectAuditLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit); 

                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const enterpriseProjectSearchActivityLogsFilters = (businessId, projectId, month, year, module, action, page) => {
        setFetchingActivityLogs(true);

        AuditService.enterpriseProjectSearchActivityLogsFilters(businessId, projectId, month, year, module, action, page).then((res) => {
            console.log("enterpriseProjectSearchActivityLogsFilters data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit);

                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const searchEnterpriseProjectActivityLogsList = (businessId, projectId, month, year, module, action, page, searchLogs) => {
        setFetchingActivityLogs(true);

        AuditService.searchEnterpriseProjectActivityLogsList(businessId, projectId, month, year, module, action, page, searchLogs).then((res) => {
            console.log("searchEnterpriseProjectActivityLogsList data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                // if (res.data.data.length === 0) {
                //     setPagination(0);

                // } else {
                //     var last_page = Math.ceil(res.data.length / limit);

                //     setPagination(last_page);
                // }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const filterAdminLogsByBusiness = (businessId, page) => {
        setFetchingActivityLogs(true);

        AuditService.filterAdminLogsByBusiness(businessId, page).then((res) => {
            console.log("filterAdminLogsByBusiness data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {

            setFetchingActivityLogs(false);
            setAuditError(error);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const filterAdminLogsByBusinessAndEmail = (businessId, email, page) => {
        setFetchingActivityLogs(true);

        AuditService.filterAdminLogsByBusinessAndEmail(businessId, email, page).then((res) => {
            console.log("filterAdminLogsByBusinessAndEmail data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {
            
            setFetchingActivityLogs(false);
            setAuditError(error);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const filterAdminLogsByProject = (businessId, projectId, page) => {
        setFetchingActivityLogs(true);

        AuditService.filterAdminLogsByProject(businessId, projectId, page).then((res) => {
            console.log("filterAdminLogsByProject data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {
            
            setFetchingActivityLogs(false);
            setAuditError(error);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const filterAdminLogsByProjectAndEmail = (businessId, projectId, email, page) => {
        setFetchingActivityLogs(true);

        AuditService.filterAdminLogsByProjectAndEmail(businessId, projectId, email, page).then((res) => {
            console.log("filterAdminLogsByProjectAndEmail data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {
            
            setFetchingActivityLogs(false);
            setAuditError(error);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const filterAdminLogsByUser = (businessId, userId, page) => {
        setFetchingActivityLogs(true);

        AuditService.filterAdminLogsByUser(businessId, userId, page).then((res) => {
            console.log("filterAdminLogsByUser data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {
            
            setFetchingActivityLogs(false);
            setAuditError(error);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const getAdminActivityLogs = (page) => {
        setFetchingActivityLogs(true);

        AuditService.getAdminActivityLogs(page, 10000).then((res) => {
            console.log("getAdminActivityLogs data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }

        }).catch((error) => {
            
            setFetchingActivityLogs(false);
            setAuditError(error);

            if(error.response.data.error === "Forbidden.") {
                setError(true);
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const adminSearchEmailFilters = (userId, page) => {
        setFetchingActivityLogs(true);

        AuditService.adminSearchEmailFilters(userId, page).then((res) => {
            console.log("adminSearchEmailFilters data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);
            }
        }).catch((error) => {
            console.log("adminSearchEmailFilters error xxxx", error);

            setFetchingActivityLogs(false);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const adminSearchActivityLogsFilters = (businessId, month, year, module, action, page) => {
        setFetchingActivityLogs(true);

        AuditService.adminSearchActivityLogsFilters(businessId, month, year, module, action, page).then((res) => {
            console.log("adminSearchActivityLogsFilters data xxxx", res);

            if (res.data.success) {
                setFetchingActivityLogs(false);
                setAuditList(res.data.data);

                if (res.data.data.length === 0) {
                    setPagination(0);

                } else {
                    var last_page = Math.ceil(res.data.length / limit);

                    setPagination(last_page);
                }
            }
        }).catch((error) => {
            setFetchingActivityLogs(false);
            setAuditError(error);
            setPagination(0);

            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

 
    return {isFetchingActivityLogs, getBusinessAuditsLogs, businessSearchActivityLogsFilters,
        pagination, auditList, auditError, searchBusinessAuditLogs, searchBusinessActivityLogsList, 
        businessSearchEmailFilters, getEnterpriseProjectAuditLogs, enterpriseProjectSearchActivityLogsFilters,
        searchEnterpriseProjectActivityLogsList, filterAdminLogsByBusiness, filterAdminLogsByBusinessAndEmail,
        filterAdminLogsByProject,  filterAdminLogsByProjectAndEmail, filterAdminLogsByUser, getAdminActivityLogs,
        error, adminSearchEmailFilters, adminSearchActivityLogsFilters
    }

}

export default useActivityLogs