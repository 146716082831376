import { useState, useEffect } from "react";
import { Button, Grid, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, InputAdornment, Box } from "@mui/material";
import { format } from "date-fns";
import ActionsPopperComponent from "./actions-popper.component";
import BusinessListSkeletonComponent from "../skeletons/business-list-skeleton.component";
import CreateAppConfigDialog from "./dialog/create-app-config-dialog.component";
import useWidth from "../../hooks/useWidth";
import useAppConfig from "../../hooks/useAppConfig";
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import ForbiddenScreen from "../../screens/ForbiddenScreen";
import WhiteListConfigSkeletonLoader from "../skeletons/white-config-list-skeleton.component";

function TableHeader() {

    const tablecell = [
        { label: 'App Code' },
        { label: 'App Name' },
        { label: 'API Endpoint' },
        { label: 'Business Name' },
        { label: 'Last Date Modified' },
        { label: 'Actions' }
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TableContent(props) {
    const { list, setUpdatedList } = props;

    const [deletedList, setDeletedList] = useState(false);

    return (
        <>
            {!deletedList && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                    <TableCell component="th" scope="row" sx={{ color: '#0178f2' }} onClick={() => window.location.href = "/app-configuration-details?appCode=" + list?.app_code}>
                        {list?.app_code}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {list?.app_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {list?.api_endpoint}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {list?.businessAccount?.business_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {format(new Date(list?.updated_date), 'PPpp')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ActionsPopperComponent list={list} setDeletedList={setDeletedList} setUpdatedList={setUpdatedList} />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}



function WhiteLabelConfigListComponent(props) {

    const breakpoints = useWidth();

    const { loading, appList, appConfigurationList, pagination, searchApp, searchByAppCode, error,
        searchAppMiddleware, isSearchingAppMiddleware, searchedAppMiddleware, isSearchFilterToggle, isSearchedResultsEmpty
    } = useAppConfig();

    const [openDialog, setOpenDialog] = useState(false)
    const [page, setPage] = useState(1);

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState(null);
    const [appCodeOptions, setAppCodeOptions] = useState([]);
    const [appCodeFilterValue, setAppCodeFilterValue] = useState(null);
    const [appNameOptions, setAppNameOptions] = useState([]);
    const [appNameFilterValue, setAppNameFilterValue] = useState(null);
    const [apiEndpointFilterValue, setApiEndpointFilterValue] = useState("");
    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessFilterValue, setBusinessFilterValue] = useState(null);

    const [isUpdated, setUpdatedList] = useState(false);



    const handleChangePagination = (event, page) => {
        setPage(page);
        appConfigurationList(page);
    }

    useEffect(() => {
        appConfigurationList()
    }, [])

    const handleOpenCreateAppConfig = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    useEffect(() => {
        if (isUpdated) {
            appConfigurationList();
        }
    }, [isUpdated])

    useEffect(() => {
        if (filter && appCodeFilterValue) {
            searchAppMiddleware(filter, appCodeFilterValue, page);

        } else if (filter && appNameFilterValue) {
            searchAppMiddleware(filter, appNameFilterValue, page);

        } else if (filter && businessFilterValue) {
            searchAppMiddleware(filter, businessFilterValue, page);
        }
    }, [appCodeFilterValue, appNameFilterValue, businessFilterValue])

    useEffect(() => {
        getAppCodeOptions();
        getAppNameOptions();
        getBusinessOptions();
    }, [JSON.stringify(appList)])


    const getAppCodeOptions = () => {
        if (appList) {
            const app_code_options = [...appList?.map((app) => ({
                value: app?.app_code,
                label: app?.app_code,
            }))];

            setAppCodeOptions(app_code_options);
        }
    }

    const getAppNameOptions = () => {
        if (appList) {
            const app_name_options = [...appList?.map((app) => ({
                value: app?.app_name,
                label: app?.app_name,
            }))];

            setAppNameOptions(app_name_options);
        }
    }

    const getBusinessOptions = () => {
        if (appList) {
            const business_options = [...appList?.map((app) => ({
                value: app?.businessAccount?.business_name,
                label: app?.businessAccount?.business_name,
            }))];

            setBusinessOptions(business_options);
        }
    }


    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            minHeight: "20px",
            width: 250,
            fontSize: 13,
        })
    }

    const filterOptions = [
        { value: "app_code", label: "App Code" },
        { value: "app_name", label: "App Name" },
        { value: "api_endpoint", label: "API Endpoint" },
        { value: "business_name", label: "Business" }
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setAppCodeFilterValue(null);
        setAppNameFilterValue(null);
        setApiEndpointFilterValue("");
        setBusinessFilterValue(null);

        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {
            setFilter(null);
            setFilterLabel(null);

            handleClearFilter();
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        appConfigurationList();
    }

    const handleSelectAppCodeValue = (selectedOption) => {
        if (selectedOption) {
            setAppCodeFilterValue(selectedOption.value);
            setAppNameFilterValue(null);
            setApiEndpointFilterValue("");
            setBusinessFilterValue(null);
        }
    }

    const handleSelectAppNameValue = (selectedOption) => {
        if (selectedOption) {
            setAppNameFilterValue(selectedOption.value);
            setAppCodeFilterValue(null);
            setApiEndpointFilterValue("");
            setBusinessFilterValue(null);
        }
    }

    const handleSelectBusinessValue = (selectedOption) => {
        if (selectedOption) {
            setBusinessFilterValue(selectedOption.value);
            setAppNameFilterValue(null);
            setAppCodeFilterValue(null);
            setApiEndpointFilterValue("");
        }
    }

    const handleTextfield = (e) => {
        setApiEndpointFilterValue(e.target.value);
        setAppNameFilterValue(null);
        setAppCodeFilterValue(null);
        setBusinessFilterValue(null);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (filter && apiEndpointFilterValue) {
                searchAppMiddleware(filter, apiEndpointFilterValue, page);
            }
        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && appCodeFilterValue) {
            searchAppMiddleware(filter, appCodeFilterValue, page);

        } else if (filter && appNameFilterValue) {
            searchAppMiddleware(filter, appNameFilterValue, page);

        } else if (filter && apiEndpointFilterValue) {
            searchAppMiddleware(filter, apiEndpointFilterValue, page);

        } else if (filter && businessFilterValue) {
            searchAppMiddleware(filter, businessFilterValue, page);
        }
    }


    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            <Grid container>
                <Grid item xs={4} sm={4}>
                    <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                        Manage API Endpoints and Credentials
                    </Typography>
                </Grid>
            </Grid>


            {error ?
                <ForbiddenScreen />
                :
                <Grid container sx={{ mt: "20px" }}>
                    <Grid item xs={8} sm={8}>
                        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                            <ReactSelect
                                className="basic-single"
                                placeholder={<>
                                    <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        Filter
                                    </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                        {
                                            (filterLabel === "App Code") ?
                                                <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                    App Code
                                                </Typography>
                                                :
                                                (filterLabel === "App Name") ?
                                                    <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                        App Name
                                                    </Typography>
                                                    :
                                                    (filterLabel === "API Endpoint") ?
                                                        <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                            API Endpoint
                                                        </Typography>
                                                        :
                                                        (filterLabel === "Business") ?
                                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                                Business
                                                            </Typography>
                                                            :
                                                            (filterLabel === null) ?
                                                                "Select Filter"
                                                                :
                                                                null

                                        }</i>
                                </>}
                                isSearchable={true}
                                isRtl={false}
                                options={filterOptions}
                                onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 250,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                                isClearable
                            />

                            {(filterLabel === null || filterLabel === "App Code") &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "App Code" && appCodeFilterValue) ?
                                            <Typography sx={{ color: "#000" ,  fontSize: 12}}>
                                                {appCodeFilterValue}
                                            </Typography>
                                            :
                                            "Search"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={appCodeOptions}
                                    value={appCodeFilterValue}
                                    onChange={selectedOption => handleSelectAppCodeValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }

                            {filterLabel === "App Name" &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "App Name" && appNameFilterValue) ?
                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                {appNameFilterValue}
                                            </Typography>
                                            :
                                            "Search App Name"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={appNameOptions}
                                    value={appNameFilterValue}
                                    onChange={selectedOption => handleSelectAppNameValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }

                            {filterLabel === "Business" &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "Business" && businessFilterValue) ?
                                            <Typography sx={{ color: "#000" ,  fontSize: 12}}>
                                                {businessFilterValue}
                                            </Typography>
                                            :
                                            "Search Business"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={businessOptions}
                                    value={businessFilterValue}
                                    onChange={selectedOption => handleSelectBusinessValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }

                            {filterLabel === "API Endpoint" &&
                                <TextField
                                    value={apiEndpointFilterValue}
                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 380 } }}
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={handleTextfield}
                                    onKeyDown={handleKeyPress}
                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                    placeholder={"Input API Endpoint..."}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{'API Endpoint'}:</p></InputAdornment>,
                                    }}
                                />
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={4} sm={4} sx={{ textAlign: "end", mb: 2, marginLeft: -2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }} onClick={handleOpenCreateAppConfig}>Create App Middleware</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(loading || isSearchingAppMiddleware) ?
                                    <TableBody>
                                        <WhiteListConfigSkeletonLoader />
                                    </TableBody>
                                    :
                                    (isSearchFilterToggle && !isSearchedResultsEmpty) ?
                                        <TableBody sx={{ width: "100%" }}>
                                            {searchedAppMiddleware?.data.map((data, index) => (
                                                <TableContent
                                                    list={data}
                                                    setUpdatedList={setUpdatedList}
                                                />
                                            ))}
                                        </TableBody>
                                        :
                                        (isSearchFilterToggle && isSearchedResultsEmpty) ?
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" sx={{ fontSize: 14, textAlign: "center", fontWeight: 500 }}>
                                                        No searched result(s) found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            (!isSearchFilterToggle) &&
                                            <TableBody sx={{ width: "100%" }}>
                                                {(appList && appList)?.map((list, index) => (
                                                    <TableContent
                                                        list={list}
                                                        setUpdatedList={setUpdatedList}
                                                    />
                                                ))}
                                            </TableBody>
                                }
                            </Table>

                            {!isSearchFilterToggle && !loading && appList && appList?.length > 0 &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePagination}
                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                    disabled={appList?.length === 0}
                                    hideNextButton={appList?.length < 10}
                                />
                            }

                            {/* pagination for search app middleware */}
                            {(isSearchFilterToggle && !isSearchedResultsEmpty && !loading) &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePaginationForSearch}
                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                />
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
            }

            {/* Create App Config Dialog */}
            <CreateAppConfigDialog openDialog={openDialog} handleClose={handleClose} />
        </Box>
    )
}

export default WhiteLabelConfigListComponent;