import { Box, Grid, Skeleton, Paper, Typography, Divider, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Card } from "@mui/material";
import { styled } from '@mui/material/styles';


const PackageSettingSkeletonComponent = (props) => {

    const { breakpoints } = props;

    return (
        <Grid container sx={{ mt: 1 }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ minWidth: "98%" }}>
                <Card sx={{ padding: 0, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "8px" }}>
                    <ListItem sx={{ backgroundColor: "#fff", padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 16px" : "10px 16px" }}>
                        <ListItemAvatar>
                            <Skeleton variant="circular" style={{ height: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 50, width: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 50 }}
                                sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={30}
                            />
                        </ListItemAvatar>

                        <ListItemText sx={{ ml: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 1 }}
                            primary={
                                <Skeleton variant="text" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={30} />
                            }
                            secondary={
                                <Skeleton variant="text" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={30} />
                            }
                        />
                    </ListItem>

                    <Box sx={{ ml: "31px", mb: 3, mr: "31px" }}>
                        {[0, 1, 2, 3, 4].map((planFeature, index) => (
                            <List key={index} sx={{ pt: "4px", pb: "4px" }}>
                                <ListItem disablePadding>
                                    <ListItemText sx={{ ml: -1 }}
                                        primary={
                                            <Skeleton variant="text" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={30} />
                                        }
                                    />
                                </ListItem>
                            </List>
                        ))}
                    </Box>
                </Card>
            </Grid>
        </Grid>

    )
}

export default PackageSettingSkeletonComponent;