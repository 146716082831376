import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";


function DisapproveConfirmationDialogComponent (props){

    const {openDisapproveConfirmationDialog, handleCloseDisapproveConfirmationDialog, authenticationDetails} = props
    const breakpoints = useWidth()
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState(null);
    const [disapproval_reason, setDisapprovalReason] = useState("");
    const [disapprovalReasonRequired, setDisapprovalReasonRequired] = useState(false);

    const handleChangeDisapproveReason = (event) => {
        setDisapprovalReason(event.target.value);
        setDisapprovalReasonRequired(false);
    }

    const handleDenyEmail = (id) => {

        let payload = {
            disapproval_reason: disapproval_reason,
        }

        if (disapproval_reason) {
            setDisapprovalReasonRequired(false);

            EmailAuthenticationService.deniedEmailSender(id, payload).then((res)=>{
                console.log('denied email res', res)
                if(res.data.success){
                    enqueueSnackbar("Disapproved.", { 
                        variant: 'success',
                        autoHideDuration: 3000
                    }); 
                    handleCloseDisapproveConfirmationDialog()
                    window.location.reload();
                }
            }).catch((err)=>{
                setError(err.response.statusText);
                enqueueSnackbar(err.response.data.error, { 
                    variant: 'error',
                    autoHideDuration: 3000
                }); 
            })

        } else if (!disapproval_reason) {
            setDisapprovalReasonRequired(true);
        }
        
    }

    return(
        <Dialog open={openDisapproveConfirmationDialog} onClose={handleCloseDisapproveConfirmationDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', margin: 'auto'}}}>
            <DialogTitle>
                Disapprove
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 1, paddingTop: 2, margin: 5}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    You're about to disapprove this email <strong>{authenticationDetails.email}</strong>. Please provide reason and confirm.
                </Typography>
                <br/>
                <TextField multiline fullWidth label="Reason" id="fullWidth" 
                value={disapproval_reason}
                onChange={handleChangeDisapproveReason}
                helperText={
                    <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                        {(disapprovalReasonRequired) && "Disapproval reason is required."}
                    </p>
                }
                />
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleCloseDisapproveConfirmationDialog} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button variant="contained" onClick={()=> handleDenyEmail(authenticationDetails.id)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DisapproveConfirmationDialogComponent