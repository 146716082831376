import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import useReports from "../../../../hooks/useReports";

function DeleteBillingDialogComponent (props){

    const {openDeleteConfirmation, setOpenDeleteConfirmation, list, projectId, businessId, page} = props

    const { deleteSOA, deletingSoa, deleted } = useReports();

    const handleClose = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleSubmit = () => {
        deleteSOA(list?.id)
    }

    useEffect(() => {

        if(deleted){
            handleClose();
            props.retrieveProjectSOA(projectId, businessId, '', page)
        }

    },[deleted])


    return(
        <Dialog open={openDeleteConfirmation} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                <Typography sx={{fontSize: 16, fontWeight:'bold', color:'red'}}>
                    Delete Confirmation
                </Typography>
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{fontStyle:'italic', fontSize: 13, color: 'gray', textAlign:'center', p: 6}}>
                Are you sure you want to delete <strong style={{color:'black'}}>{list?.filename}</strong>?
            </DialogContent>

            <DialogActions sx={{mr: 2, mb: 1}}>
                <Button onClick={handleClose} sx={{textTransform:'none', fontSize: 14}}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "red", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", '&:hover': {backgroundColor: "red", color: "white"}}}
                    onClick={handleSubmit} 
                    startIcon={deletingSoa && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> }
                >
                    {deletingSoa ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteBillingDialogComponent