import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import ReactSelect from 'react-select';
import { useEffect } from "react";
import { useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import BusinessAppConfigService from "../../../services/api/business-app-config";
import useAppConfig from "../../../hooks/useAppConfig";
import {ThreeDots} from 'react-loader-spinner';

const UpdateAppConfigDialog = (props) => {

    const {openDialog, handleClose, appConfigDetails, setUpdatedList} = props

    const dataStyles = {  control: (styles) => ({ ...styles, backgroundColor: 'transparent', padding: 10, marginLeft: 16}) };
    const breakpoints = useWidth()
    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();
    const {loading, updateAppConfig, updated} = useAppConfig()

    const [options, setOption] = useState([]);
    const [businessId, setBusinessId] = useState(null);
    const [selected_business, setSelectedBusiness] = useState(null);
    const [app_name, setAppName] = useState(appConfigDetails?.app_name);
    const [app_code, setAppCode] = useState(appConfigDetails?.app_code);
    const [api_endpoint, setApiEndpoint] = useState(appConfigDetails?.api_endpoint);
    const [subscription_key, setSubKey] = useState(appConfigDetails?.subscription_key);
    const [secret_key, setSecretKey] = useState(appConfigDetails?.api_secret_key);

    const handleChangeAppName = (e) => {
        setAppName(e.target.value)
    }

    const handleChangeAPIEndpoint = (e) => {
        setApiEndpoint(e.target.value)
    }

    const handleChangeSubKey = (e) => {
        setSubKey(e.target.value)
    }

    const handleChangeSecretKey = (e) => {
        setSecretKey(e.target.value)
    }

    console.log('appConfigDetailsxx', appConfigDetails)

    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id , label : business?.business_name  }

            options.push(options_data)
        })

        setOption(options)

    },[JSON.stringify(business_names)])

    const getBusiness = () => value => {
        setBusinessId(value.value)
        setSelectedBusiness(value.label)
    };

    const handleUpdateAppConfig = () => {
        let payload = {
            app_name: app_name,
            app_code: appConfigDetails?.app_code,
            api_endpoint: api_endpoint ? api_endpoint : appConfigDetails?.api_endpoint,
            subscription_key: subscription_key ? subscription_key : appConfigDetails?.subscription_key,
            secret_key: secret_key ? secret_key : appConfigDetails?.api_secret_key ,
            business_id: businessId,
        }
        updateAppConfig(payload)
    }

    const handleTextFieldChange = (e, name) => {
        if (name == "app_code") {
            setAppCode(e.target.value);

        } else if (name == "app_name") {
            setAppName(e.target.value);

        } else if (name == "api_endpoint") {
            setApiEndpoint(e.target.value);
        } else if (name == "subscription_key") {
            setSubKey(e.target.value);
        } else if (name == "secret_key") {
            setSecretKey(e.target.value);
        }
    }

    useEffect(()=>{
        getBusinessListNames()
    },[])

    console.log("selectedBusinessxxx", selected_business)


    useEffect(() => {
        // Update state when appConfigDetails changes
        if (appConfigDetails && appConfigDetails.businessAccount) {
            setBusinessId(appConfigDetails.businessAccount.id);
            setSelectedBusiness(appConfigDetails.businessAccount.business_name);
        }
    }, [appConfigDetails]);

    useEffect(() => {
        if(updated){
            handleClose();
            setUpdatedList(true);
        }
    },[updated])

    return(
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'lg'} fullWidth={'lg'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Update App Middleware 
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                        <Typography sx={{fontWeight:'bold'}}>Enterprise :</Typography>
                    </Grid>
                    <Grid xs={12} sm={9}>
                        <ReactSelect
                            className="basic-single"
                            placeholder={"Select"}
                            defaultValue={selected_business ? { value: businessId, label: selected_business } : null}
                            isRtl={false}
                            isSearchable={true}
                            options={options}
                            styles={dataStyles}
                            onChange={getBusiness}
                        />
                    </Grid>
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold'}}>App Code:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                disabled
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="App Code"
                                value={appConfigDetails?.app_code ? appConfigDetails?.app_code: app_code}
                                onChange={(e) => handleTextFieldChange(e, "app_code")}  
                            />
                        </Grid>
                        
                    
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold'}}>App Name:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="App Name"
                                defaultValue={appConfigDetails?.app_name? appConfigDetails?.app_name : app_name}
                                onChange={handleChangeAppName}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold'}}>API Endpoint:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="API Endpoint"
                                defaultValue={appConfigDetails?.api_endpoint ? appConfigDetails?.api_endpoint : api_endpoint}
                                onChange={handleChangeAPIEndpoint}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold'}}>Subscription Key:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="Subscription Key"
                                defaultValue={appConfigDetails?.subscription_key ? appConfigDetails?.subscription_key : subscription_key}
                                onChange={handleChangeSubKey}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold'}}>Secret Key:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="Secret Key"
                                defaultValue={appConfigDetails?.api_secret_key ? appConfigDetails?.api_secret_key : secret_key}
                                onChange={handleChangeSecretKey}
                            />
                        </Grid>
                        
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: "black", textTransform: "none", fontSize: 14}} >
                    Cancel
                </Button>

                <Button onClick={handleUpdateAppConfig} variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UpdateAppConfigDialog