import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';
import usePackageManagement from "../../../hooks/usePackageManagement";


const UpdateFeatureDialog = (props) => {

    const { openUpdateFeature, handleClose, getPackageSummary } = props;


    const breakpoints = useWidth();

    const { getFeatureList, isFetchingFeatures, feature_list, updateFeature, updatedFeature, updatingFeature } = usePackageManagement();

    useEffect(() => { getFeatureList(); }, [])

    const [feature_id, setFeatureId] = useState(null);
    const [feat_id, setFeatId] = useState(null);
    const [feature_name, setFeatureName] = useState('');
    const [feature_description, setFeatureDescription] = useState('');

    const handleChangeFeature = (event) => {
        const selectedFeatureId = event.target.value;
        const selectedFeature = feature_list.find(data => data.id === selectedFeatureId);

        if (selectedFeature) {
            setFeatureId(selectedFeatureId);
            setFeatureName(selectedFeature.feature_title);
            setFeatureDescription(selectedFeature.description);

            // Only set the feat_id if it hasn't been manually changed
            if (!feat_id) {
                setFeatId(selectedFeature.feat_id);
            }
        }
    }


    const handleChangeFeatID = (event) => {
        const selectedFeatId = event.target.value;

        if (selectedFeatId) {
            setFeatId(selectedFeatId);
        }
    }

    const handleTextFieldChange = (e, name) => {
        if (name == "feature_title") {
            setFeatureName(e.target.value);

        } else if (name == "feature_description") {
            setFeatureDescription(e.target.value);

        }
    }


    const handleUpdateFeature = () => {

        let payload = {
            feature_title: feature_name,
            description: feature_description,
            feat_id: feat_id
        }

        updateFeature(feature_id, payload);
    }


    useEffect(() => {

        if (updatedFeature) {
            handleClose()
            // window.location.reload();
            getPackageSummary();
        }

    }, [updatedFeature])



    return (
        <Dialog open={openUpdateFeature} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Edit Feature
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Select Feature :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={feature_id}
                                onChange={handleChangeFeature}
                            >
                                {feature_list?.map((data, index) => (
                                    <MenuItem value={data?.id} key={data?.id} data-feature-title={data?.feature_title}>
                                        {data?.feature_title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature Title :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={feature_name}
                            onChange={(e) => handleTextFieldChange(e, "feature_title")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={feature_description}
                            onChange={(e) => handleTextFieldChange(e, "feature_description")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature ID :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={feat_id}
                                onChange={handleChangeFeatID}
                            >
                                {feature_list?.map((data, index) => (
                                    <MenuItem value={data.feat_id} key={data.feat_id}>
                                        {data.feat_id}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleUpdateFeature()}
                    startIcon={updatingFeature &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {updatingFeature ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateFeatureDialog