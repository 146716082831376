import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import DeleteConfigDialog from "./dialog/delete-config-dialog.component";
import UpdateSecretConfigDialog from "./dialog/update-secret-config-dialog.component";



function ConfigPopperComponent(props){

    const {page, list, getListConfig} = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteConfig, setOpenDeleteConfig] = useState(false);
    const [openUpdateConfig, setOpenUpdateConfig] = useState(false);

    const handleClose = () => {
        setOpenDeleteConfig(false)
    }

    const handleOpenDialog = () =>{
        setOpenDeleteConfig(true)
    }

    const handleOpenUpdateConfig = () =>{
        setOpenUpdateConfig(true)
    }

    const handleCloseUpdateConfig = () => {
        setOpenUpdateConfig(false)
    }

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


  
    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem  onClick={handleOpenUpdateConfig} style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} >
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                        <EditIcon style={{width: 16, height: 16}}/>
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Edit</Typography>
                            </MenuItem>
                            <MenuItem  onClick={handleOpenDialog} style={{color: 'red', fontSize: 13, fonWeight: 500}} >
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                        <DeleteIcon style={{width: 16, height: 16}}/>
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Delete</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {/* DIALOG */}
            <DeleteConfigDialog list={list} openDeleteConfig={openDeleteConfig} handleClose={handleClose}  getListConfig={getListConfig} page={page} />

            { openUpdateConfig &&
                <UpdateSecretConfigDialog 
                    openUpdateConfig={openUpdateConfig}
                    list={list} 
                    handleClose={handleCloseUpdateConfig}
                    getListConfig={getListConfig} 
                    page={page}
                />
            }

        </div>
    )
}

export default ConfigPopperComponent
