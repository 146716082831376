import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";



export default function AddSignatureConfirmationDialog (props) {

    const {openSignatureConfirmation, handleCloseConfirmation, handleCreateSignature, signaturePreview, email, isAddingSignature, hasChanges} = props;

    return(
        <Dialog open={openSignatureConfirmation} onClose={handleCloseConfirmation} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                Signature Confirmation
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{textAlign:'center'}}>
                <Grid container>
                    <Grid item xs={4}>
                    <img alt="" src={signaturePreview}
                        style={{
                            height: 160,
                            width: "100%", objectFit: "contain",
                            border:'1px solid gray',
                            borderRadius: 5
                        }}
                    />
                    </Grid>
                    <Grid item xs={8} sx={{mt: 3}}>
                    <Typography>
                        Are you sure to create eSignature using the email:
                    </Typography>
                    <br/>
                    <Typography sx={{fontSize: 16, fontWeight:'bold', mb: 2}}>
                        {email}
                    </Typography>
                    <Button sx={{ borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14, width: 100, mr: 1}} variant="outlined"
                        onClick={(e) => handleCloseConfirmation(e)}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2", width: 100, ml: 1}} 
                        startIcon={(isAddingSignature) && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                        onClick={(e) => handleCreateSignature(e)}
                        disabled={!hasChanges || isAddingSignature}
                    >
                        {(isAddingSignature) ? "Confirming" : "Confirm"}
                    </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        
    )
}