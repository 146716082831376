import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ReactSelect from "react-select";
import useBusiness from "../../hooks/useBusiness";
import usePackageManagement from "../../hooks/usePackageManagement";
import PackageCardScreenComponent from "./package-card-screen.component";
import PackageSkeletonComponent from "./package-skeleton.component";
import ForbiddenScreen from "../../screens/ForbiddenScreen";

const PackageComponent = (props) => {

    const { breakpoints } = props;

    const { business_names, getBusinessListNames } = useBusiness();
    const { isFetchingBusinessPackageFeatures, getBusinessPackageFeatures, error,
        getBusinessPackageResponse, isRetrievingPackageSummary, packageSummaryList, getPackageSummary,
    } = usePackageManagement();

    const [modifiedPackageList, setModifiedPackageList] = useState([]);
    const [businessOptions, setBusinessOptions] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState(null);

    console.log("packageSummaryList && modifiedPackageList && getBusinessPackageResponse in PackageComponent xxxx", packageSummaryList, modifiedPackageList, getBusinessPackageResponse);

    useEffect(() => {
        getPackageSummary();
    }, [])

    useEffect(() => {
        if (packageSummaryList) {
            setModifiedPackageList(packageSummaryList);
        }
    }, [packageSummaryList])

    useEffect(() => {
        if (!getBusinessPackageResponse && packageSummaryList) {
            setModifiedPackageList(packageSummaryList);

        } else if (getBusinessPackageResponse) {
            if (packageSummaryList) {
                setModifiedPackageList((prevPackageList) => {
                    return prevPackageList.map((plan) => {
                        if (plan.package.id === getBusinessPackageResponse.data.business_package.packageRecord.id) {
                            return {
                                ...plan,
                                features: getBusinessPackageResponse.data.feature_quota,
                                is_current_plan: true
                            }

                        } else {
                            const originalPlan = packageSummaryList.find(original => original.package.id === plan.package.id);
                            if (originalPlan) {
                                return {
                                    ...plan,
                                    features: originalPlan.features,
                                    is_current_plan: false
                                }
                            }

                            return plan;
                        }
                    });
                });
            }
        }
    }, [getBusinessPackageResponse, packageSummaryList]);
    useEffect(() => {
        getBusinessListNames();
    }, [])

    useEffect(() => {
        let options = []

        business_names?.map((business) => {
            let options_data = { value: business?.id, label: business?.business_name }

            options.push(options_data);
        })

        setBusinessOptions(options);

    }, [JSON.stringify(business_names)])


    const handleSelectBusiness = (selectedOption) => {
        setSelectedBusiness(selectedOption.value);

        getBusinessPackageFeatures(selectedOption.value);
    }


    return (
        <Box sx={{ width: "95%", margin: "auto", mt: "17px !important", mb: "24px !important" }}>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 18 }}>
                                Select Enterprise Plan
                            </Typography>

                            <Typography variant="subtitle2" sx={{ color: "rgb(0 0 0 / 45%)" }}>
                                Select a plan that works best for you and your team
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            {(isRetrievingPackageSummary) ?
                                null
                                :
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={"Select Enterprise"}
                                    isSearchable={true}
                                    isRtl={false}
                                    options={businessOptions}
                                    onChange={selectedOption => handleSelectBusiness(selectedOption)}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        {(isRetrievingPackageSummary || isFetchingBusinessPackageFeatures) ?
                            <Grid container spacing={2} sx={{ width: "100%", margin: "auto", mt: 3, mb: 1 }}>
                                {[0, 1, 2].map((index) => (
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                                        <PackageSkeletonComponent breakpoints={breakpoints} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <PackageCardScreenComponent breakpoints={breakpoints}
                                modifiedPackageList={modifiedPackageList} selectedBusiness={selectedBusiness}
                            />
                        }
                    </Grid>
                </>
            }
        </Box>
    )
}

export default PackageComponent