import { Box, Button, Grid, IconButton, InputAdornment, Pagination, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAnalytics from "../../hooks/useAnalytics";
import useProjects from "../../hooks/useProjects";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { ThreeDots } from 'react-loader-spinner';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CachedIcon from '@mui/icons-material/Cached';
import { styled } from '@mui/material/styles';
import PipelinePerProjectComponent from "../analytics/pipeline-per-project.component";
import DashboardGraphSkeletonComponent from "../skeletons/dashboard-graph-skeleton.component";
import ProjectPerPipelineComponent from "../analytics/project-per-pipeline.component";
import { Masonry } from "@mui/lab";
import DashboardFabComponent from "../analytics/dashboard-fab.component";
import DashboardSkeletonComponent from "../skeletons/dashboard-skeleton.component";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}));


function EnterpriseProjectTransactionComponent(props) {

    const { projectId, project_name } = props;
    const navigate = useNavigate();

    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    const { getCountPipelineByProjectEnterpriseAndDateRange, business_counts, per_month, project_pipeline, pipeline_data, loading } = useAnalytics();
    const { loadingProjects, projects, getProjectList } = useProjects();


    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);
    const [data, setData] = useState([]);
    const [data_pipeline, setDataPipeline] = useState([]);
    const [pipeline_names, setPipelineName] = useState([]);
    const [data_project, setDataProject] = useState([]);
    const [project_names, setProjectNames] = useState([]);
    const [exporting, setExporting] = useState(false);


    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    useEffect(() => {
        if ((businessIdFromURL) && dateFrom && dateTo) {
            const businessToUse = businessIdFromURL;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");
            getCountPipelineByProjectEnterpriseAndDateRange(businessToUse, projectId, date_from, date_to);
        }
    }, [businessIdFromURL, dateFrom, dateTo]);


    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }


    const reload = () => {
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        getCountPipelineByProjectEnterpriseAndDateRange(businessIdFromURL, projectId, date_from, date_to);
    }


    useEffect(() => { getProjectList(businessIdFromURL) }, [businessIdFromURL])


    useEffect(() => {

        if (business_counts) {

            var result = Object.entries(business_counts);
            console.log("resultxx", result)
            setData(result);

            if (result?.length > 0) {

                result?.map((data) => {

                    let project_id = data[0];

                    projects?.map((project) => {

                        if (project?.id == project_id) {
                            data[0] = project?.application_name
                        }

                    })

                })

            }

        }

    }, [JSON.stringify(business_counts), JSON.stringify(projects)])


    // PIPELINE PER PROJECT
    useEffect(() => {
        let pipelines = []
        let pipeline_names = []

        Object.keys(pipeline_data).forEach(key => {

            let project_id = key;

            projects?.map((project) => {

                if (project?.id == project_id) {

                    let pipeline_obj = {}

                    pipeline_obj.name = project?.application_name

                    let pipeline_array = pipeline_data[key];

                    pipeline_array.map((pipeline) => {

                        let pipeline_name = pipeline?.pipeline;
                        let pipeline_count = pipeline?.count;

                        pipeline_obj[pipeline_name] = pipeline_count;

                        if (!pipeline_names.includes(pipeline_name)) {
                            pipeline_names.push(pipeline_name)
                        }

                    })

                    pipelines.push(pipeline_obj);

                }

            })

            console.log("pipelines", pipelines)
            setDataPipeline(pipelines);
            setPipelineName(pipeline_names);

        })


    }, [JSON.stringify(pipeline_data), JSON.stringify(projects)])



    //  PROJECT PER PIPELINE
    useEffect(() => {
        let project_pipelines = []
        let pipeline_names = []

        Object.keys(project_pipeline).forEach(key => {

            let pipeline_obj = {}

            pipeline_obj.pipeline = key

            let pipeline_array = project_pipeline[key]

            pipeline_array.map((pipe) => {

                let project_id = pipe?.project

                projects?.map((project) => {


                    if (project?.id == project_id) {

                        let project_name = project?.application_name

                        pipeline_obj[project_name] = pipe.count


                        if (!pipeline_names.includes(project_name)) {
                            pipeline_names.push(project_name)
                        }

                    }

                })

            })

            project_pipelines.push(pipeline_obj);

        })

        console.log("projecthereeeee", project_pipelines)

        setDataProject(project_pipelines)
        setProjectNames(pipeline_names)


    }, [JSON.stringify(project_pipeline), JSON.stringify(projects)])


    async function generatePDF() {
        try {
            console.log('Generating PDF...');
    
            // Create a new jsPDF instance
            const pdf = new jsPDF();
    
            // Add title to the PDF
            const title = `${project_name} Analytics as of ${formattedDateRange}`;
            pdf.text(title, 15, 15);
    
            // Use html2canvas to capture the chart container
            const chartContainer = document.getElementById('chartContainer');
            const canvas = await html2canvas(chartContainer);
    
            // Get the dimensions of the chart container
            const chartRect = chartContainer.getBoundingClientRect();
    
            // Calculate the aspect ratio to maintain proportions
            const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Adjust margin as needed
            const imgHeight = (chartRect.height * imgWidth) / chartRect.width;
    
            // Add the canvas content to the PDF
            pdf.addImage(canvas, 'PNG', 10, 30, imgWidth, imgHeight);
    
            // Save the PDF with a filename
            pdf.save(`${project_name}_Analytics_${formattedDateRange}.pdf`);
            console.log('PDF generated successfully');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    }
    
      

    return (
        <Box sx={{ width: "93.5%", margin: "auto", mt: 2, mb: 1.5 }}>

            <Grid container sx={{padding: 1, pt: 0}}>
                <Grid item xs={12} >
                    <Typography variant='h6'>
                        Analytics
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 2}} >
                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateFrom}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12, height: 38 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    borderRadius: 10,
                                                    fontSize: 12,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => { setDateFrom(newValue) }}
                                            maxDate={dateTo}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

                                &nbsp; &nbsp;

                                <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateTo}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    fontSize: 12,
                                                    borderRadius: 10,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}

                                            onChange={(newValue) => { setDateTo(newValue) }}
                                            minDate={dateFrom}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>


                                &nbsp; &nbsp;

                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                            </Stack>

                        </Box>


                        <Box sx={{ flexGrow: 1 }} />

                        <Button
                            variant="contained"
                            onClick={generatePDF}
                            sx={{
                                width: exporting ? 150 : 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                                ":hover": { background: "#0178f2" }
                            }}
                            disabled={data?.length === 0 || data === undefined ? true : false}
                            startIcon={exporting ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                        >
                            {exporting ? 'Exporting' : 'Export to PDF'}
                        </Button>
                    </Stack>


                </Grid>

            </Grid>

            {!loading && data?.length == 0 &&
                <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                    <Typography><b>{project_name}</b> has no available transactions.</Typography>
                </Box>
            }

            <Box id="chartContainer">
                {loading ?
                    <DashboardSkeletonComponent />
                    :
                    <Masonry id="cardGrid" columns={{ xs: 1, sm: 5 }} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0 }}>
                        {data?.map((item) => {
                            return (
                                <Item sx={{ '&.MuiPaper-root': { borderRadius: 2 } }}>
                                    <DashboardFabComponent item={item} loadingProjects={loadingProjects} />
                                </Item>
                            )
                        })}
                    </Masonry>
                }

                {data_pipeline?.length !== 0 &&
                    <Box sx={{ margin: 'auto' }} id="cardGridTableOne">
                        {loading ?
                            <DashboardGraphSkeletonComponent />
                            :
                            <PipelinePerProjectComponent data={data_pipeline} names={pipeline_names} />
                        }
                    </Box>
                }

                {data_project?.length !== 0 &&
                    <Box sx={{ margin: 'auto' }} id="cardGridTableTwo">
                        {loading ?
                            <DashboardGraphSkeletonComponent />
                            :
                            <ProjectPerPipelineComponent data={data_project} names={project_names} />
                        }
                    </Box>
                }
            </Box>
        </Box>
    )

}

export default EnterpriseProjectTransactionComponent