import { Box, Card, Grid, MenuItem, Paper, Select, Typography, InputLabel  } from "@mui/material";
import { useState, useEffect } from "react";
import AdminAnalyticsService from "../../services/api/analytics";
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, } from 'recharts';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import PipelinePerYearComponent from "./pipeline-per-year.component";
import AuthenticationPerMonth from "./authentication-per-month.component";
import AuthenticationPerYear from "./authentication-per-year.component";


function DashboardGraphComponent({}){

    

    const tabs = useAnalyticsTabs();
    const [ value, setValue ] = useState(0);

    function TabPanel(props) {
        
        const { children, value, index, ...other } = props;
        
        
        return (
            <div
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box>
                <Typography>{children}</Typography>
                </Box>
            )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    function handleChange(event, newValue){
        setValue(newValue);
    }

    function useAnalyticsTabs(props){

      const [ analytics_tab, setAnalyticsTab ] = useState(null);
      const currentYear = new Date().getFullYear()
    
      function setupTabs(){
    
        var tabs_default = [
          {name: 'Per Month', component: <AuthenticationPerMonth currentYear={currentYear}/>,},
          {name: 'Per Year' , component: <AuthenticationPerYear/>},
          {name: 'Pipeline Per Year', component: <PipelinePerYearComponent/>},
        ]
    
        setAnalyticsTab(tabs_default);
      }
    
      useEffect(()=>{ setupTabs(); },[])
    
      return analytics_tab;
    
    }

    return(
        <>
            <CustomTabs
                variant= "scrollable"
                value={value} 
                onChange={handleChange} 
                sx={{marginTop: 2}} 
                indicatorColor="transparent"
                centered
            >
                { tabs?.map((tab, index)=>{
                    return(
                        <CustomTab label={tab.name} disableRipple {...a11yProps(index)}   />
                    )}) 
                }
            </CustomTabs>
              { tabs?.map((tab, index)=>{
                  return(
                      <TabPanel value={value} index={index} style={{padding: "0px"}}>
                          {tab.component}
                      </TabPanel> 
                  )})
              }
        </>
    )
}

export default DashboardGraphComponent