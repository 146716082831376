import environment from "../../../../environment/environment";
import routes from "../../common/api-routes";
import { HttpGet, HttpPost, HttpDelete } from "../../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getSignatoryWhitelistDomains = (projectId, page, limit, businessId) => {
    let query_params = {
        projectId: projectId,
        page: page,
        limit: limit,
        businessId: businessId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_SIGNATORY_WHITELIST_DOMAINS, query_params, subscriptionKey);
    return result;
}

const viewSignatoryWhitelistDomain = (id, projectId, businessId) => {
    let query_params = {
        id: id,
        projectId: projectId,
        businessId: businessId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_SIGNATORY_WHITELIST_DOMAIN, query_params, subscriptionKey);
    return result;
}

const deleteSignatoryWhitelistDomain = (id) => {
    let query_params = {
        id: id
    };
    let result = HttpDelete(routes.DELETE_SIGNATORY_WHITELIST_DOMAIN, query_params);
    return result;
}

const createSignatoryWhitelistDomains = (project_id, business_id, domains) => {
    let payload = {
        project_id: project_id,
        business_id: business_id,
        domains: domains
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_SIGNATORY_WHITELIST_DOMAINS, payload, subscriptionKey);
    return result;
}

const EsignProjectWhitelistDomainsService = {
    getSignatoryWhitelistDomains: getSignatoryWhitelistDomains,
    viewSignatoryWhitelistDomain: viewSignatoryWhitelistDomain,
    deleteSignatoryWhitelistDomain: deleteSignatoryWhitelistDomain,
    createSignatoryWhitelistDomains: createSignatoryWhitelistDomains
}

export default EsignProjectWhitelistDomainsService;