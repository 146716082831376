import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import useRolesManagement from "../../../hooks/useRolesManagement";
import { ThreeDots } from 'react-loader-spinner';
import usePackageManagement from "../../../hooks/usePackageManagement";


const UpdatePackageDialog = (props) => {

    const { openUpdatePackage, handleClose, plan, getPackageSummary } = props;

    const breakpoints = useWidth();
    const { updatePackage, updatingPackage, updatedPackage } = usePackageManagement();

    const [package_name, setPackageName] = useState(plan?.package_name);
    const [package_description, setPackageDescription] = useState(plan?.description);

    const handleTextFieldChange = (e, name) => {
        if (name == "package_name") {
            setPackageName(e.target.value);

        } else if (name == "package_description") {
            setPackageDescription(e.target.value);

        }
    }

    const handeleUpdatePackage = () => {

        let payload = {
            package_name : package_name,
            description : package_description,
        }

        updatePackage(plan?.id, payload)
    }

    useEffect(() => {

        if(updatedPackage){
            handleClose()
            // window.location.reload();
            getPackageSummary();
        }

    },[updatedPackage])

    return (
        <Dialog open={openUpdatePackage} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Update Package
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Package Name :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={package_name}
                            onChange={(e) => handleTextFieldChange(e, "package_name")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Package Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={package_description}
                            onChange={(e) => handleTextFieldChange(e, "package_description")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                   onClick={() => handeleUpdatePackage() }
                   startIcon={updatingPackage && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {updatingPackage ? "Updating" : "Update"}  
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdatePackageDialog