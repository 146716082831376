import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import { useSnackbar } from "notistack";
import copy from "copy-to-clipboard"; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const UserPasswordDialog = (props) => {

    const breakpoints = useWidth();
    const { enqueueSnackbar } = useSnackbar();

    const {password, openDialog,  handleClose} = props;

    let copyText =  password;

    const copyToClipboard = () => {
        console.log("copy text here :", copyText)
        copy(copyText);
        enqueueSnackbar("Successfully copied to clipboard.", { 
            variant: "success",
            autoHideDuration: 2000
        });
    }


    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2, margin: 'auto'}}}>
            <DialogTitle>
                User Password
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField  
                            fullWidth  
                            variant="outlined"  
                            autoComplete="off"  
                            value={password}
                        InputProps={{endAdornment: (
                            <InputAdornment position="end" style={{marginLeft: -29}}>
                                <Tooltip title="Copy" placement="top" arrow>
                                    <IconButton onClick={(e) => copyToClipboard(e)} sx={{color: '#0178f2'}}  edge="end">
                                        <ContentCopyIcon /> 
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                        readOnly: true
                        }} 
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: 'black', textTransform: 'none', fontSize: 18}} onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserPasswordDialog