import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useRef, useState } from "react";
import ReactSelect from 'react-select';
import useBusiness from "../../../hooks/useBusiness";
import useCredits from "../../../hooks/useCredits";
import { ThreeDots } from 'react-loader-spinner';


const AddCreditDialog = (props) => {

    const { openAddCredit, handleClose } = props;

    const menuPortalTargetRef = useRef(null);

    const breakpoints = useWidth();
    const { getBusinessListNames, business_names, isFetchingBusinessNames } = useBusiness();
    const { addBusinessCredits, addingBusinessCredits, businessCreditsAdded } = useCredits();

    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };

    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [options, setOption] = useState([]);
    const [business_id, setBusinessId] = useState(options[0]?.value);
    const [selected_business, setSelectedBusiness] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [page, setPage] = useState(1);

   
    useEffect(() => {
        getBusinessListNames();
    }, []);

    const handleChangeBusiness = (selectedOption) => {
        setBusinessId(selectedOption?.value);
        setSelectedBusiness(selectedOption);
    };

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }


    const handleAddCredits = () => {

        let payload = {
            business_id: business_id,
            credit: addCreditAmount
        }

        addBusinessCredits(payload);
    }


    useEffect(() => {
        if(businessCreditsAdded){
           handleClose()
            // window.location.reload();
            props.getBusinessCredits(page);
        }

    },[businessCreditsAdded])


    return (
        <Dialog open={openAddCredit} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Add Credit
            </DialogTitle>

            <Divider />

            <DialogContent 
                sx={{ 
                    padding: (breakpoints == "xs" || breakpoints == "sm") ? {} :  8, 
                    paddingTop: 4,
                    paddingBottom: isDropdownOpen ? 0 : 8,
                    height: isDropdownOpen ? 360 : undefined
                 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={8} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>Enterprise</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                {isFetchingBusinessNames ?
                                    <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                                :
                                    // <ReactSelect
                                    //     className="basic-single"
                                    //     placeholder={'Select Enterprise'}
                                    //     defaultValue={selected_business ? selected_business : options[0]?.label}
                                    //     isRtl={false}
                                    //     isSearchable={true}
                                    //     options={options}
                                    //     styles={dataStyles}
                                    //     onMenuOpen={() => setIsDropdownOpen(true)} 
                                    //     onMenuClose={() => setIsDropdownOpen(false)}
                                    //     onChange={(selectedOption) => {
                                    //         getBusiness()(selectedOption); 
                                    //     }}
                                    // />
                                    <ReactSelect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selected_business}
                                        onChange={handleChangeBusiness}
                                        options={business_names.map(business => ({ value: business.id, label: business.business_name }))}
                                        isSearchable
                                        isClearable
                                        menuPortalTarget={menuPortalTargetRef.current}
                                        menuPosition="fixed"
                                        placeholder={
                                            <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                Select Enterprise
                                            </Typography>
                                        }
                                        styles={{
                                            menuPortal: provided => ({
                                                ...provided,
                                                zIndex: 9999
                                            }),
                                            menu: provided => ({
                                                ...provided,
                                                zIndex: 9999,
                                                fontSize: 12
                                            }),
                                            control: base => ({
                                                ...base,
                                                height: 56,
                                                backgroundColor: 'transparent',
                                                fontSize: 12
                                            }),
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Credits</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                <TextField
                                    size="small"
                                    type="number"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    InputProps={{
                                        inputProps: {
                                            max: 1000000, min: 0
                                        }
                                    }}
                                    value={addCreditAmount}
                                    onChange={(e) => handleAddChange(e)}
                                    sx={{ "& .MuiInputBase-root": { height: 56, width: 120 } }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 3, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleAddCredits()}
                    startIcon={addingBusinessCredits && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {addingBusinessCredits ? "Adding" : "Add"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCreditDialog