import { Box, Card, Grid, MenuItem, Paper, Select, Typography, InputLabel, Divider, CardContent, CardActionArea } from "@mui/material";
import { useState, useEffect } from "react";
import AdminAnalyticsService from "../../services/api/analytics";
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, } from 'recharts';
import DashboardGraphSkeletonComponent from "../skeletons/dashboard-graph-skeleton.component";
import DashboardPerMonthSkeletonComponent from "../skeletons/DashboardPerMonthSkeletonComponent";
import ForbiddenImage from "../../images/forbidden.png";


function AuthenticationPerMonth({ currentYear }) {

  const [monthlyCounts, setMonthlyCounts] = useState([]);
  const [year, setYear] = useState(currentYear);
  const [loading, setLoading] = useState(false);
  const [error_data, setErrorData] = useState(false);

  function generateArrayOfYears() {

    var current_year = new Date().getFullYear()
    var years = []

    for (var i = 2022; i <= current_year; i++) {
      let year = { value: i }
      years.push(year)
    }

    return years
  }

  var years = generateArrayOfYears();

  years.sort(function (a, b) {
    return b.value - a.value;
  });

  console.log('GET YEARSSSSSSSSSSS', years)

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const getTransactionCountPerMonth = (year) => {
    setLoading(true);
    AdminAnalyticsService.getCountTransactionPerMonth(year).then((res) => {
      console.log('get transaction count per month res ', res)
      setLoading(false);
      if (res.data.success) {
        var monthCount = res.data.data.data;
        monthCount?.map((monthData) => {
          if (monthData.month == '01') {
            monthData.month = "January";
          } else if (monthData.month == '02') {
            monthData.month = "February";
          } else if (monthData.month == '03') {
            monthData.month = "March";
          } else if (monthData.month == '04') {
            monthData.month = "April";
          } else if (monthData.month == '05') {
            monthData.month = "May";
          } else if (monthData.month == '06') {
            monthData.month = "June";
          } else if (monthData.month == '07') {
            monthData.month = "July";
          } else if (monthData.month == '08') {
            monthData.month = "August";
          } else if (monthData.month == '09') {
            monthData.month = "September";
          } else if (monthData.month == '10') {
            monthData.month = "October";
          } else if (monthData.month == '11') {
            monthData.month = "November";
          } else if (monthData.month == '12') {
            monthData.month = "December";
          }
        });

        setMonthlyCounts(monthCount);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err here", err)
      setErrorData(err.response.statusText);

    })
  }

  useEffect(() => { getTransactionCountPerMonth(year) }, [year])

  function random_color() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    return color;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      {error_data === 'Forbidden' ?


        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
            <Typography textAlign="center" mb={2} sx={{ fontSize: 18, color: 'black' }}>
              You are not authorized.
            </Typography>
            <Typography variant="body1" component="div" textAlign="center" fontSize={14}>
              You tried to access a page you did not have prior authorization for.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center">
            <img alt="" src={ForbiddenImage} style={{ height: 250 }} />
          </Grid>
        </Grid>

        :


        <div className="row">
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Total Per Month</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'end' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={year}
                placeholder={years[0]?.value}
                defaultValue={year ? year : years[0]?.value}
                sx={{ width: 150 }}
                onChange={handleYearChange}
                inputProps={{ sx: { mr: "28px", padding: 1, fontSize: 12, textAlign: 'center' } }}
                InputProps={{
                  startAdornment: <Typography>Year:</Typography>,
                }}
              >
                {years?.map((year) => {
                  return (
                    <MenuItem value={year.value}  >
                      {year.value}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: 2 }} />

          <Box sx={{ margin: 'auto' }}>
            {loading ?
              <DashboardPerMonthSkeletonComponent />
              :
              <>
                {monthlyCounts.length !== 0 &&
                  <div className="section col-md-6">
                    <h5 className="section-title" >Authentication</h5>
                    <div className="section-content">
                      <ResponsiveContainer width="100%" height={395}>
                        <BarChart data={monthlyCounts} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
                          <XAxis dataKey="month" />
                          <YAxis />
                          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" fill={'rgb(59, 130, 230)'}  />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                }
              </>
            }
          </Box>


          {!loading && monthlyCounts.length == 0 &&
            <Typography sx={{ textAlign: 'center', padding: 10 }}>No data</Typography>
          }
        </div>
      }
    </Paper>
  )
}
export default AuthenticationPerMonth