import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";
import { ThreeDots } from 'react-loader-spinner';



function RevokeConfirmationDialogComponent (props){

    const {openRevokeConfirmationDialog, handleCloseRevokeConfirmationDialog, authenticationDetails, projectId} = props
    const breakpoints = useWidth()
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);



    const handleRevokeEmail = (id) => {
        setLoading(true);
        EmailAuthenticationService.revokeEmailSender(id).then((res)=>{
            if(res.data.success){
                setLoading(false);
                enqueueSnackbar("Email has been revoked.", { 
                    variant: 'success',
                    autoHideDuration: 3000
                }); 
                handleCloseRevokeConfirmationDialog()
                // window.location.reload();
                props.getListEmailSender(projectId, page);
            }
        }).catch((err)=>{
            setLoading(false);
            setError(err.response.statusText);
            enqueueSnackbar(err.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            }); 
        })
    }

    return(
        <Dialog open={openRevokeConfirmationDialog} onClose={handleCloseRevokeConfirmationDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Revoke
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    Revoke approved email <strong>{authenticationDetails?.email}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleCloseRevokeConfirmationDialog} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button variant="contained" 
                    onClick={()=> handleRevokeEmail(authenticationDetails?.id)}
                    startIcon={loading ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                >
                    { loading ? 'Revoking' : 'Confirm' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default RevokeConfirmationDialogComponent