import { Box, Card } from "@mui/material";
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Cell, Toolip } from 'recharts';
import useWidth from "../../hooks/useWidth";



function reformatString(inputString) {
    // Remove square brackets from the input string
    const cleanedString = inputString.replace(/[[\]]/g, '');
  
    // Convert the cleaned string to uppercase and split into an array
    const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());
  
    // Filter out "ENCRYPTION" from the array
    const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');
  
    if (filteredArray.length === 1) {
      // If there is only one element, return it as is
      return filteredArray[0];
    } else if (filteredArray.length > 1) {
      // If there are multiple elements, join them with commas and "and"
      const lastIndex = filteredArray.length - 1;
      return (
        filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
      );
    } else {
      // If no elements are left after filtering, return an empty string
      return '';
    }
  }


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const payloadTotal = payload.reduce((total, data) => total + data.value, 0);
        return (
        <div style={{ background: '#fff', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', height: 'auto',  overflowY: 'auto', fontSize: 12 }}>
            {label}
            <br />
            {payload.map((data, index) => (
            <p key={index} style={{ color: data?.color }}>
                {`${reformatString(data?.name.replace(/[[\]]/g, ''))}: ${data.value}`}
            </p>
            ))}
            <b>Total: {payloadTotal}</b>
        </div>
        );
    }

    return null;
};
    


function PipelinePerProjectComponent(props){

    const breakpoints = useWidth();

    const { data , names} = props;


    function random_color() {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
      
        for (let i = 0; i < 6; i++) {
          color += letters[Math.round(Math.random() * 15)];
        }
        
        return color;
    }



    return(
        <Box sx={{ p: 2, margin: 'auto', borderRadius: 2, bgcolor:'white', width:'99%'}}>
            <h3 className="section-title">Authentication Per Project</h3>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data} margin={{top: 20, right: 20,left: 20, bottom: 5}}style={{margin: 'auto'}} >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey={"name"}  />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip /> */}
                    {/* <Legend /> */}
                    {
                        names?.map((name) => {
                            return(
                                <Bar dataKey={name} stackId="a" fill={random_color()} />
                            )
                        }
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    
    )

}

export default PipelinePerProjectComponent