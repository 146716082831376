import React, {useEffect, useState} from "react";
import {Avatar, Button, Card, CardContent, FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import useBusiness from "../../hooks/useBusiness";
import {ThreeDots} from 'react-loader-spinner';

const UpdateBusinessAccountComponent = (props) => {

    const {breakpoints, businessDetails} = props;
   
    const {updateBusiness, isUpdatingBusiness} = useBusiness();

    const [business_name, setBusinessName] = useState(businessDetails?.business_name);
    const [description, setDescription] = useState(businessDetails?.description);
    const [website, setWebsite] = useState(businessDetails?.website);
    const [contact, setContact] = useState(businessDetails?.contact);
    const [logo, setLogo] = useState(businessDetails?.logo);
    const [logoPreview, setLogoPreview] = useState(null);
    const [logoFile, setLogoFile] = useState(null);

    const [isBusinessNameRequired, setBusinessNameRequired] = useState(false);
    const [isDescriptionRequired, setDescriptionRequired] = useState(false);
    const [status, setStatus] = useState(businessDetails?.status);

    const handleToggle = () => {
        let newPrivacy = status === 0 ? 1 : 0;
        setStatus(newPrivacy);
    };

    const validateFileType = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "jfif" || extFile == "gif") {
            const [target_file] = e.target.files;

            setLogoPreview(URL.createObjectURL(target_file));
            setLogoFile(e.target.files[0]);

        } else {
          console.log("Only image files are supported as of now");
        }
    }

    const handleTextfieldChange = (e, name) => {
        if (name == "companyName") {
            setBusinessName(e.target.value);
            setBusinessNameRequired(false);

        } else if (name == "description") {
            setDescription(e.target.value);
            setDescriptionRequired(false);

        } else if (name == "website") {
            setWebsite(e.target.value);

        } else if (name == "contactNo") {
            setContact(e.target.value);
        }
    }

    const handleUpdateBusiness = (e) => {
        let formData = new FormData();

        formData.append("id", businessDetails?.id);
        formData.append("business_name", business_name);
        formData.append("description", description);
        formData.append("website", website);
        formData.append("contact", contact);
        formData.append("file", logoFile);
        formData.append("status", status);

        if (!business_name) {
            setBusinessNameRequired(true);

        } else if (!description) {
            setDescriptionRequired(true);

        } else {
            setBusinessNameRequired(false);
            setDescriptionRequired(false);
            
            updateBusiness(formData, businessDetails?.id);
        }
    }
          
    useEffect(() => {
        if (businessDetails) {
            setBusinessName(businessDetails.business_name);
            setDescription(businessDetails.description);
            setWebsite(businessDetails.website);
            setContact(businessDetails.contact);
            setLogo(businessDetails.logo);
        }
    }, [businessDetails])

    
    return (
        <Grid container spacing={2} sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "24px 16px" : "24px 64px 16px 64px"}}>
            <Grid item xs={12}>
                <TextField 
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Company Name"
                    value={business_name}
                    onChange={(e) => handleTextfieldChange(e, "companyName")}
                    helperText={
                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                            {(isBusinessNameRequired) && "Company Name is required"}
                        </p>
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField 
                    fullWidth 
                    multiline
                    rows={5}
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => handleTextfieldChange(e, "description")}
                    helperText={
                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                            {(isDescriptionRequired) && "Description is required"}
                        </p>
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Website (Optional)"
                    value={website}
                    onChange={(e) => handleTextfieldChange(e, "website")}
                />
            </Grid>

            <Grid item xs={12} sx={{mb: 1}}>
                <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    placeholder="Contact Number (Optional)"
                    value={contact}
                    onChange={(e) => handleTextfieldChange(e, "contactNo")}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" sx={{mb: 2}}>
                    Company Logo
                </Typography>

                <Grid container>
                    <Grid item xs={6}>
                        <Card sx={{border: 1, borderColor:'#8080807a', marginBottom: 1}}>
                            <CardContent style={{textAlign:'center'}}>
                                <Avatar imgProps={{sx: {objectFit: "contain"}}} variant="square" 
                                    src={(logoPreview) ? logoPreview : logo} 
                                    sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? {} : 100, objectFit: "contain", margin: "auto", '&.MuiAvatar-img': {objectFit: "contain"}}}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6} sx={{alignSelf: 'center'}}>
                        <Button sx={{textTransform: 'none', borderRadius: 0, fontSize: 14, ml: 1, borderRadius: 1}}>
                            <input 
                                type="file" 
                                accept="image/*" 
                                id="upload-logo"
                                onChange= {(e) => validateFileType(e)}
                            />

                            <label htmlFor="upload-logo" className="upload-button-text">
                                Upload
                            </label>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{alignSelf:'center', marginTop: 1}}>
                        <Typography variant="subtitle2">
                            Status:   <FormControlLabel
                            control={<Switch checked={status === 1} onChange={handleToggle} />}
                            label={status == 1 ? "Active" : "Deactivate"}
                            sx={{color: status == 1 ? "green" : "red"}}
                        />
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={6} sx={{alignSelf:'center', marginTop: 1}}>
                        <FormControlLabel
                            control={<Switch checked={status === 1} onChange={handleToggle} />}
                            label={status == 1 ? "Active" : "Deactivate"}
                            sx={{color: status == 1 ? "green" : "red"}}
                        />
                    </Grid> */}
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign: "end", mt: 2}}>
                <Button variant="contained" onClick={(e) => handleUpdateBusiness(e)} 
                    startIcon={isUpdatingBusiness && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {isUpdatingBusiness ? "Updating" : "Update"}
                </Button>
            </Grid>
        </Grid>
    )
}

export default UpdateBusinessAccountComponent