import React, { useEffect } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";
import useSignature from "../../../../hooks/useSignature";

const DeleteProjectSignatureDialog = (props) => {

    const {openDeleteTempSignatureDialog, setOpenDeleteTempSignatureDialog, tempSignatureDetails, setSignatureDeleted} = props;

    const {isDeletingTemplateSignature, deleteTemplateSignature} = useSignature();

    console.log("tempSignatureDetails abb", tempSignatureDetails);

    const handleCloseDeleteDialog = (e) => {
        setOpenDeleteTempSignatureDialog(false);
    }

    const handleDeleteTemplateSignature = () => {
        deleteTemplateSignature(tempSignatureDetails.id, setOpenDeleteTempSignatureDialog, setSignatureDeleted);
    }


    return (
        <Dialog open={openDeleteTempSignatureDialog} onClose={(e) => handleCloseDeleteDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Delete Signature Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
               <Typography sx={{fontSize: "16px"}}>
                    Are you sure you want to delete <strong>{tempSignatureDetails.email}</strong> signature?
               </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "#000", textTransform: "none", fontSize: 14}} onClick={(e) => handleCloseDeleteDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: 'none', fontSize: 14, '&:hover': {backgroundColor:'#0178f2', color: 'white'}}}
                    onClick={(e) => handleDeleteTemplateSignature(e)}
                    startIcon={isDeletingTemplateSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isDeletingTemplateSignature) ? "Deleting" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteProjectSignatureDialog