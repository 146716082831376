import {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import useProjects from "../../../hooks/useProjects";
import ReactSelect from "react-select";
import useUsers from "../../../hooks/useUsers";


const CreateProjectDialog = (props) => {

    const {openCreateProject, handleClose, breakpoints, businessId} = props;
    const menuPortalTargetRef = useRef(null);

    const {isCreatingProject, createProject, created} = useProjects();
    const { pagination, getUsersByBusinessAccountID, userAccounts, loadingUserList } = useUsers();


    const [application_name, setApplicationName] = useState("");
    const [description, setDescription] = useState("");
    const [isProjectNameRequired, setProjectNameRequired] = useState(false);
    const [isDescriptionRequired, setDescriptionRequired] = useState(false);
    const [page, setPage] = useState(1);
    const [user_id, setUserId] = useState("");
    const [user_name, setUserName] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        if (businessId) {
            getUsersByBusinessAccountID(businessId, page);
        }
    }, [businessId, page])

    const handleChangeUser = (selectedOption) => {
        setUser(selectedOption);
        setUserId(selectedOption?.value);
        setUserName(selectedOption?.label);
    }

    const handleChangeProjectName = (event) => {
        setApplicationName(event.target.value);
        setProjectNameRequired(false);
    }

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
        setDescriptionRequired(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let payload = {
            businessId: businessId,
            application_name: application_name,
            description: description,
            requested_by: user_id,
            requested_by_email: user_name
        }
    
        if (application_name && description) {
            setProjectNameRequired(false);
            setDescriptionRequired(false);

            createProject(payload);

        } else if (!application_name) {
            setProjectNameRequired(true);
    
        } else if (!description) {
            setDescriptionRequired(true);
        }
    }

    const handleCloseDialog = (e) => {
        setApplicationName("");
        setDescription("");
        setProjectNameRequired(false);
        setDescriptionRequired(false);
        
        handleClose();
    }

    console.log("userAccountsxxx",userAccounts)
    console.log("createdxxx",created)

    useEffect(() => {
        if(created){
            props.getProjectList(businessId);
            handleClose();
        }
    },[created])


    return (
        <Dialog open={openCreateProject} onClose={(e) => handleCloseDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Add Project
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Project Name :</Typography>
                        </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField  
                            fullWidth  
                            variant="outlined"  
                            autoComplete="off"  
                            placeholder="Project Name" 
                            value={application_name}
                            onChange={handleChangeProjectName}
                            InputProps={{ fontSize: 12 }}
                            inputProps={{ sx: { fontSize: 12} }}
                            sx={{fontSize: 12}}
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(isProjectNameRequired) && "Project Name is required"}
                                </p>
                            }
                        />
                    </Grid>   

                    <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Project Description :</Typography>
                        </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField  
                            fullWidth 
                            multiline 
                            rows="5"
                            variant="outlined"  
                            autoComplete="off"  
                            placeholder="Description" 
                            value={description}
                            onChange={handleChangeDescription}
                            sx={{fontSize: 12}}
                            InputProps={{ fontSize: 12 }}
                            inputProps={{ sx: { fontSize: 12} }}
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(isDescriptionRequired) && "Description is required"}
                                </p>
                            }
                          />
                    </Grid> 
                    <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Requested By :</Typography>
                        </Grid>
                        <Grid item xs={12} ref={menuPortalTargetRef}>
                            <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={user}
                                onChange={handleChangeUser}
                                options={userAccounts?.map(user => ({ value: user.user_id, label: user.user_principal_name }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select User
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={(e) => handleCloseDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    onClick={handleSubmit} startIcon={isCreatingProject && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {isCreatingProject ? 'Creating' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateProjectDialog