import React from "react";
import {Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const SavePDFTemplateDialog = (props) => {

    const {openSaveTemplateConfirmation, handleCloseSaveTemplateConfirmation, template, breakpoints} = props;

    return (
        <Dialog
            maxWidth={"sm"} fullWidth={"sm"}
            open={openSaveTemplateConfirmation}
            onClose={handleCloseSaveTemplateConfirmation}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}
        >
            <DialogTitle sx={{fontSize: 14}} >Save Changes</DialogTitle>
            <DialogContent sx={{m: 5, mb: 0, mt: 2}}>
                <Typography sx={{fontSize: 13, textAlign:'center'}}>
                    Are you sure you want to save <strong>{template?.template_name}</strong> template?
                </Typography>
            </DialogContent>

            <DialogActions sx={{justifyContent:'center', mb: 2}} >
                <Button variant="contained" color="error" sx={{textTransform: "none", fontSize: 14}} autoFocus 
                    onClick={() => handleCloseSaveTemplateConfirmation('no')}
                >
                    <CloseIcon/>
                </Button>
                &nbsp; &nbsp;
                <Button variant="contained" color="success" autoFocus sx={{borderRadius: 1, textTransform: "none", fontSize: 14}} 
                    onClick={() => handleCloseSaveTemplateConfirmation('yes')}
                >
                    <CheckIcon/>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SavePDFTemplateDialog