import { Button, ButtonGroup, ClickAwayListener, Divider, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import RevokeConfirmationDialogComponent from "./email-authentication-dialog/revoke-confirmation-dialog.component";
import RemoveCancelConfirmationDialogComponent from "./email-authentication-dialog/enterprise/remove-cancel-confirmation-dialog.component";

// import CancelConfirmationDialog from "./dialog/cancel-confirmation-dialog.component";
// import RevokeConfirmationDialogComponent from "./dialog/revoke-confirmation-dialog.component";
// import RemoveConfirmationDialog from "./dialog/remove-confirmation-dialog.component";

const EmailSenderEnterprisePopperComponent = (props) => {

    const { authentication, projectId } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openRevokeConfirmationDialog, setOpenRevokeConfirmationDialog] = useState(false);
    const [openRemoveConfirmationDialog, setOpenRemoveConfirmationDialog] = useState(false);
    const [removeContentDescription, setRemoveContentDescription] = useState("");
    const [removeHeaderTitle, setRemoveHeaderTitle] = useState("");

    const handleOpenRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(true); }

    const handleCloseRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(false); }

    const handleOpenRemoveConfirmationDialog = (title, desc) => { 
        setRemoveHeaderTitle(title)
        setRemoveContentDescription(desc)
        setOpenRemoveConfirmationDialog(true);
    }

    const handleCloseRemoveConfirmationDialog = (e) => { setOpenRemoveConfirmationDialog(false); }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <IconButton sx={{ color: 'black' }}>
                <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{ borderRadius: 2 }}>
                    <ClickAwayListener onClickAway={handleClick}>
                        {authentication.status_code === "PA" ?
                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'red' }} 
                                    onClick={() => handleOpenRemoveConfirmationDialog(
                                        "Cancel",
                                        "A request cancellation requires your confirmation.")} >
                                        Cancel
                                    </Typography>
                                </MenuItem>
                            </MenuList>

                        : (authentication.status_code === "A") ?

                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: '#0178f2' }} onClick={handleOpenRevokeConfirmationDialog} >
                                        Revoke
                                    </Typography>
                                </MenuItem>
                            </MenuList>
                        :
                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'red' }} 
                                    onClick={() => handleOpenRemoveConfirmationDialog(
                                        "Remove",
                                        "Are you sure you want to remove this record?")} >
                                        Remove
                                    </Typography>
                                </MenuItem>
                            </MenuList>
                        }
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openRevokeConfirmationDialog &&
                <RevokeConfirmationDialogComponent
                    handleCloseRevokeConfirmationDialog={handleCloseRevokeConfirmationDialog}
                    authenticationDetails={authentication}
                    openRevokeConfirmationDialog={openRevokeConfirmationDialog}
                    getListEmailSender={props.getListEmailSender}
                    projectId={projectId}
                />
            }

            {
                openRemoveConfirmationDialog &&
                <RemoveCancelConfirmationDialogComponent
                    headerTitle={removeHeaderTitle}
                    contentDescription={removeContentDescription}
                    handleCloseRemoveConfirmationDialog={handleCloseRemoveConfirmationDialog}
                    authenticationDetails={authentication}
                    openRemoveConfirmationDialog={openRemoveConfirmationDialog}
                    getListEmailSender={props.getListEmailSender}
                    projectId={projectId}
                />
            }

        </div>
    )
}

export default EmailSenderEnterprisePopperComponent
