import { Card, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";
import React from "react";

const InheritanceSkeleton = () => {

    return(
        <Card sx={{ padding: 2, pt: 1, pb: 2, borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="center" sx={{ cursor: 'pointer' }} > {/* Use alignItems="center" to vertically center the content */}
                    <ListItemAvatar sx={{ minWidth: '80px !important' }}>
                        <Skeleton variant="circular" style={{backgroundColor: 'rgb(108 139 222 / 30%)', height: 65, width: 65}} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <React.Fragment>
                               <Skeleton variant="rectangular" height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                               <Skeleton variant="rectangular" height={10} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} /> 
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7} sx={{ textAlign: 'end', ml: 'auto' }}>
                    <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                </Grid>
            </Grid>
        </Card>
    )
}

export default InheritanceSkeleton;