import {useState} from "react";
import { useSnackbar } from "notistack";
import EmailAuthenticationService from "../services/api/email-authentication";



const useEmailAuthentication = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [authenticationList, setAuthenticatinList] = useState(null);
    const [emailAuthenticationDetails, setEmailAuthenticationDetails] = useState(null)
    const [emailAuthenticationRecords, setEmailAuthenticationRecords] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isDetailsLoading, setIsDetailsLoading] = useState(false)
    const [isSearchEmail, setIsSearchByEmail] = useState(null)
    const [searchPagination, setSearchPagination] = useState(0);
    const [isLoadingCreateEmailSender, setIsLoadingCreateEmailSender] = useState(false)
    const [createEmailSenderData, setCreateEmailSenderData] = useState(null)
    const [error, setError]= useState("");
    const [email_sender_created, setEmailSenderCreated] = useState(false);
    const [pagination, setPagination] = useState(0)

    var limit = 10;
    
    const EmailAUthenticationList = (page) => {
        setIsLoading(true)
        EmailAuthenticationService.listEmailSender(page, limit).then((res)=>{
            console.log('get list of email authenticated', res)

            setIsLoading(false)
            if(res.data.success){
                setAuthenticatinList(res.data.data)
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error)=>{
            setIsLoading(false)
            setError(error.response.data.error)
            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
        })
    };

    const createEmailSender = (email_address, project_id, user_id) => {
        setIsLoadingCreateEmailSender(true)
        EmailAuthenticationService.createEmailSender(email_address, project_id, user_id).then((res)=>{
            console.log('createEmailSender aa', res)
            setIsLoadingCreateEmailSender(false)
            setCreateEmailSenderData(res.data.data)
            setEmailSenderCreated(true);
        }).catch((error)=>{
            setIsLoadingCreateEmailSender(false)
            setEmailSenderCreated(false);
            if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                  variant: "error",
                  autoHideDuration: 3000
                });
                return
            }
        })
    };

    const viewEmailAuthentication = (id) =>{
        setIsDetailsLoading(true)
        EmailAuthenticationService.viewEmailSender(id).then((res)=>{
            console.log("view email authentication details res", res)
            setEmailAuthenticationDetails(res.data.data);
            setEmailAuthenticationRecords(res.data.data.record)
            setIsDetailsLoading(false)
        }).catch((err)=>{
            setIsDetailsLoading(false)
            if (err.response.data.error) {
                enqueueSnackbar(err.response.data.error, {
                  variant: "error",
                  autoHideDuration: 3000
                });
                return
            }
        })
    }

    const searchByEmail = (email, page) => {
        EmailAuthenticationService.searchByEmail(email, page, limit).then((res)=>{
            console.log('search email by email', res)
            setIsSearchByEmail(res.data.data)
            if(res.data.data.length === 0){
                setSearchPagination(0)
              }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setSearchPagination(last_page)
              }
        })
    }

    return{
        authenticationList, EmailAUthenticationList, emailAuthenticationDetails, emailAuthenticationRecords, 
        viewEmailAuthentication, isLoading, isDetailsLoading, searchByEmail, isSearchEmail, searchPagination, createEmailSender, 
        isLoadingCreateEmailSender, createEmailSenderData,error, email_sender_created, pagination
    }
}
export default useEmailAuthentication