import { Box, Grid, Skeleton, Paper, Typography, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import {Masonry} from '@mui/lab';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.caption,
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function DashboardSkeletonComponent (){

    const heights = [121, 121, 121, 121, 121];

    return(

        <Box sx={{padding: 0}}>
            <Masonry columns={{ xs: 1, sm: 5 }} spacing={2} sx={{margin:'auto', width: '100%', padding: 0}}>
                {heights.map((height, index) => (
                    <Item key={index} sx={{height, boxShadow: 'rgba(200, 200, 200) 0px 7px 29px 0px !important', '&.MuiPaper-root' : {borderRadius: 2}}}>
                       <Box sx={{p:2, pt:1, pb:1}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Skeleton variant="circular" width={70} height={70} sx={{backgroundColor: "rgb(108 139 222 / 30%)", margin: 'auto'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Item>
                ))}
            </Masonry>
        </Box>
       
      
    )
}

export default DashboardSkeletonComponent;