import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth"
import PermissionsScreen from "./PermissionsScreen";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from "react";
import CreatePermissionDialog from "../components/rbac/dialogs/create-permission.dialog";
import CreateModuleDialog from "../components/rbac/dialogs/create-module.dialog";
import ViewPermissionScreen from "./ViewPermissionScreen";
import usePermissionManagement from "../hooks/usePermissionManagement";
import ForbiddenScreen from "./ForbiddenScreen";


const PermissionManagementScreen = () => {

    const breakpoints = useWidth();
    const {isLoading, getModules, modules, pagination, searchPermissions, isSearchingPermission, searchedPermission, 
        isSearchPermissionToggle, isSearchedResultsEmpty, getPermissionsFunction, functions, error
    } = usePermissionManagement();

    const [openCreatePermission, setOpenCreatePermission] = useState(false);
    const [openCreateModule, setOpenCreateModule] = useState(false);
    const [isView, setView] = useState(false);
    const [permission_id, setPermissionId] = useState(false);
    const [module_data, setModuleData] = useState(false);

    const handleOpenCreatePermission = () => {
        setOpenCreatePermission(true);
    }

    const handleCloseCreatePermission = () => {
        setOpenCreatePermission(false);
    }

    const handleOpenCreateModule = () => {
        setOpenCreateModule(true);
    }
    
    const handleCloseCreateModule = () => {
        setOpenCreateModule(false);
    }



    return (
        <Box sx={{ width:'97%', margin: 'auto' }}>
            {error ?
                <ForbiddenScreen />
            :
            <Grid container>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: 14 }}>Controlling access to resources and data, ensuring security and privacy.</Typography>
                </Grid>
                <Grid item xs={4} sx={{mt: "45px"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sx={{paddingTop: '1px !important', textAlign: 'end'}}>
                            <Button
                                variant="text"
                                sx={{
                                    fontSize: 14,
                                    p: '4px 5px',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#0178f2',
                                    textTransform: 'none',
                                }}
                                startIcon={<AddBoxIcon sx={{color: '#0178f2' }} />}
                                onClick={handleOpenCreatePermission}
                            >
                                Create Permission
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{paddingTop: '1px !important', textAlign: 'center'}}>
                            <Button
                                variant="text"
                                sx={{
                                    fontSize: 14,
                                    p: '4px 5px',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#0178f2',
                                    textTransform: 'none',
                                }}
                                startIcon={<AddBoxIcon sx={{color: '#0178f2' }} />}
                                onClick={handleOpenCreateModule}
                            >
                                Create Module
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid item xs={12}>
                    {isView ?
                        <ViewPermissionScreen 
                            permissionId={permission_id} 
                            setView={setView} 
                            data={module_data} 
                            isLoading={isLoading} 
                            getPermissionsFunction={getPermissionsFunction}
                            functions={functions} 
                        />
                    :
                        <PermissionsScreen 
                            setView={setView} 
                            setPermissionId={setPermissionId} 
                            setModuleData={setModuleData} 
                            getModules={getModules}
                            searchPermissions={searchPermissions}
                            getPermissionsFunction={getPermissionsFunction}
                            isLoading={isLoading}
                            modules={modules}
                            pagination={pagination} 
                            isSearchingPermission={isSearchingPermission} 
                            searchedPermission={searchedPermission} 
                            isSearchPermissionToggle={isSearchPermissionToggle} 
                            isSearchedResultsEmpty={isSearchedResultsEmpty}   
                        />
                    }
                </Grid>
            </Grid>
            }


            {openCreatePermission &&
                <CreatePermissionDialog 
                    openCreatePermission={openCreatePermission}
                    handleClose={handleCloseCreatePermission}
                    data={module_data}
                    getPermissionsFunction={getPermissionsFunction}
                    getModules={getModules}
                    modules={modules}
                    permissionId={permission_id} 
                    isView={isView}
                /> 
            }

            {openCreateModule &&
                <CreateModuleDialog 
                    openCreateModule={openCreateModule}
                    handleClose={handleCloseCreateModule}
                    data={module_data}
                    getModules={getModules}
                    getPermissionsFunction={getPermissionsFunction}
                    modules={modules}
                    permissionId={permission_id} 
                    isView={isView}
                />
            }

        </Box>
    )
}

export default PermissionManagementScreen
