import { Avatar, Box, Button, Card, CardHeader, Grid, List, ListItem, ListItemAvatar, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ViewPermissionComponent from "../components/rbac/view-permission.component";
import usePermissionManagement from "../hooks/usePermissionManagement";
import { format } from 'date-fns';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";



function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    const words = name.split('_'); // Split the name by underscores
    let initials = '';

    if (words.length > 0) {
        initials += words[0][0]; // First letter of the first word

        if (words.length > 1) {
            initials += words[1][0]; // First letter of the second word (after underscore)
        }
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}


const ViewPermissionScreen = (props) => {

    const { permissionId, setView, data,  isLoading, functions } = props;


    const navigate = useNavigate();


    const [page, setPage] = useState(1);

    useEffect(() => {

        props.getPermissionsFunction(page, permissionId);

    }, [page, permissionId])


    return (
        <Box sx={{ width: '100%', marginTop: 1 }}>

            <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight : 600  }}
                onClick={() => setView(false) } >
                    Back
            </Button>

            <Box sx={{mt: 2}} />

            <Grid container>
                <Grid item xs={12}>
                    <Card sx={{ padding: 2, pt: 1, pb: 2, borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                        {/* {functions.map((func, index) => (
                            // Check if this module_name has been displayed before
                            index === 0 || func?.module?.module_name !== functions[index - 1]?.module?.module_name ? ( */}
                                <ListItem alignItems="center">
                                    <ListItemAvatar sx={{ minWidth: '100px' }}>
                                        {data?.module_name ? (
                                            <Avatar {...stringAvatar(data?.module_name)} style={{ width: 80, height: 80 }} />
                                        ) : null}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    {data?.module_name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography sx={{ fontSize: 11 }}>
                                                    Last Date Modified : {format(new Date(data?.updated_at), 'PPpp')}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            {/* ) : null
                        ))} */}
                        <br />
                        <Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ViewPermissionComponent functions={functions} loading={isLoading} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>

                </Grid>
            </Grid>

        </Box>

    )
}

export default ViewPermissionScreen;



