import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import useRolesManagement from "../../../hooks/useRolesManagement";
import { ThreeDots } from 'react-loader-spinner';


const UpdateRoleDialog = (props) => {

    const { openUpdateRole, handleClose, data, retrieved } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const { updateRole, updateAdminRole, updatingRole, updatedRole } = useRolesManagement();

    const [roleName, setRoleName] = useState(data?.role);
    const [roleDescription, setRoleDescription] = useState(data?.description);
    const [page, setPage] = useState(1);


    const handleTextFieldChange = (e, name) => {
        if (name == "role_name") {
            setRoleName(e.target.value);

        } else if (name == "role_description") {
            setRoleDescription(e.target.value);

        }
    }

    const handeleUpdateRole = () => {

        let payload = {
            role : roleName,
            description : roleDescription,
        }

        if(url === "/admin-settings") {
            updateAdminRole(data?.id, payload);
        } else {
            updateRole(data?.id, payload);
        }

        
    }

    useEffect(() => {

        if(updatedRole){
            handleClose()
            if(url === "/admin-settings") {
                props.getAdminRoles(page);
            } else {
                props.getAllRoles();

                if(retrieved){
                    props.getRoles(page);
                }
            }
        }

    },[updatedRole])

    return (
        <Dialog open={openUpdateRole} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Update Role
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Role :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={roleName}
                            onChange={(e) => handleTextFieldChange(e, "role_name")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={roleDescription}
                            onChange={(e) => handleTextFieldChange(e, "role_description")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                   onClick={() => handeleUpdateRole() }
                   startIcon={updatingRole && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {updatingRole ? "Updating" : "Update"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateRoleDialog