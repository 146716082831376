import React, {useEffect, useState} from "react";
import {ButtonGroup, List, ListItem, ListItemText, TextField, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography, DialogActions} from "@mui/material";
import usePackageManagement from "../../../hooks/usePackageManagement";
import {ThreeDots} from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import CustomizePlanSkeletonComponent from "../customize-plan-skeleton.component";
import {useSnackbar} from "notistack";

const CustomizePlanDialog = (props) => {

    const {breakpoints, description, openCustomizedPlanDialog,defaultPackageFeatures, setOpenCustomizedPlanDialog, selectedBusiness, name, packageId} = props;

    const {enqueueSnackbar} = useSnackbar();
    const {defaultFeatures, isFetchingDefaultPackageFeatures, viewPackage, createUpdateCustomPackage, customPlanUpdated, assignedPackageToBusiness} = usePackageManagement();

    // const [quotaAmounts, setQuotaAmounts] = useState(Array(defaultFeatures.length).fill(0));
                                
    const [quotaAmounts, setQuotaAmounts] = useState(
        defaultPackageFeatures.map((feature) => feature.quota)
      );                     

    const [savingIndex, setSavingIndex] = useState(null);

    useEffect(() => {
        viewPackage(packageId);
    }, [])

    const handleCloseSelectionOptions = (e) => {
        setOpenCustomizedPlanDialog(false);

        if (customPlanUpdated) {
            enqueueSnackbar("Successfully customized plan", { 
                variant: "success",
                autoHideDuration: 3000
            }); 

            window.location.reload();

        } else if (assignedPackageToBusiness) {
            if (customPlanUpdated) {
                enqueueSnackbar("Successfully assigned and customized plan", { 
                    variant: "success",
                    autoHideDuration: 3000
                }); 
    
                window.location.reload();
            } else {
                enqueueSnackbar("Successfully assigned plan to enterprise", { 
                    variant: "success",
                    autoHideDuration: 3000
                });

                window.location.reload();
            }  
        }

        window.location.reload();
    }

    const handleQuotaChange = (e, index) => {
        const newQuotaAmounts = [...quotaAmounts];
        newQuotaAmounts[index] = parseInt(e.target.value);

        setQuotaAmounts(newQuotaAmounts);
    }

    const handleCreateUpdateCustomPackage = (e, index, featureId, setSavingIndex) => {
        const updatedQuota = quotaAmounts[index];
        

        if (updatedQuota) {
            createUpdateCustomPackage(selectedBusiness, featureId, updatedQuota, setSavingIndex);
        }
    }


    return (
        <Dialog open={openCustomizedPlanDialog} maxWidth={"sm"} fullWidth={"sm"} onClose={(e) => handleCloseSelectionOptions(e)} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle sx={{display: "flex", padding: "11px 24px 8px 24px"}}>
                Customize Plan

                <Box sx={{ml: "auto"}}>
                    <CloseIcon size="small" onClick={(e) => handleCloseSelectionOptions(e)} 
                        style={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 20, 
                        color: "#808080a8", marginTop: "5px", cursor: "pointer"}} 
                    />
                </Box>
            </DialogTitle>

            <Divider />

            {(isFetchingDefaultPackageFeatures) ?
                <CustomizePlanSkeletonComponent breakpoints={breakpoints} />
            :

                <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "20px 48px 28px"}}>
                    <Box>
                        <ListItem sx={{mt: "-8px", pl: "0px !important", pt: "0px !important", textAlign: "center"}}>
                            <ListItemText
                                primary={
                                    <Typography sx={{color: "#000", fontWeight: "bold", fontSize: 18}}>
                                        {name} Plan
                                    </Typography>
                                }
                                secondary={
                                    <Typography sx={{color: "#000"}}>
                                        {description}
                                    </Typography>
                                }
                            />
                        </ListItem>   
                    </Box>
                    
                    {defaultFeatures?.map((planFeature, index) => (
                        <List key={index} sx={{ml: 1}}>
                            <>
                                <Typography>
                                    • {planFeature?.featureRecord.description}
                                </Typography>

                                <TextField
                                    key={planFeature?.featureRecord.id}
                                    size="small"
                                    type="number"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    InputProps={{
                                        inputProps: {
                                        max: 1000000,
                                        min: 0,
                                        },
                                    }}
                                    defaultValue={planFeature?.quota} 
                                    value={quotaAmounts[index]}
                                    onChange={(e) => handleQuotaChange(e, index)}
                                    sx={{ width: "50%", "& .MuiInputBase-root": { height: 35 } }}
                                />


                                <ButtonGroup variant="text" aria-label="text button group">
                                    <Button key={planFeature?.featureRecord.id} variant="text" sx={{textTransform: "none", ml: "10px"}}
                                        onClick={(e) => {
                                            // if (!isCreateUpdatingCustomPackage && savingIndex !== index) {
                                                setSavingIndex(index);
                                                handleCreateUpdateCustomPackage(e, index, planFeature?.featureRecord.id, setSavingIndex);
                                            // }
                                        }}
                                        // onClick={(e) => (isCreateUpdatingCustomPackage[index]) ? null : handleCreateUpdateCustomPackage(e, index, planFeature?.featureRecord.id)}
                                        startIcon={(savingIndex === index) && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {/* {(isCreateUpdatingCustomPackage[index]) ? "Saving" : "Save"} */}
                                        {(savingIndex === index) ? "Saving" : "Save"}
                                    </Button>
                                        
                                    <Divider orientation="vertical" sx={{height: "auto", visibility: "hidden"}} />
                                </ButtonGroup>
                            </>
                        </List>
                    ))}           
                </DialogContent>
            }

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                  onClick={(e) => handleCloseSelectionOptions(e)}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomizePlanDialog