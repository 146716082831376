import { Box, Fab, Typography, TextField, InputAdornment, Grid, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import ReactSelect from "react-select";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import CachedIcon from '@mui/icons-material/Cached';
import useWidth from "../hooks/useWidth";
import useProjects from "../hooks/useProjects"
import VaultListComponent from "../components/enterprise/vault/vault-list.component";
import useVault from "../hooks/useVault";
import ProjectVaultListComponent from "../components/enterprise/vault/project-vault-list.component";


const ProjectVaultScreen = (props) => {

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const { projectId } = props;

    const {  getFilesByProjectId, fileList, loading, downloadFile,
        previewFile, pagination, limit, openDocxPreview, isLoading, isPreviewLoading, getFilesByDateRange } = useVault();
    const { getProjectList, projects, loadingProjects } = useProjects();

    let businessId = (new URLSearchParams(window.location.search)).get("id");


    let id = +businessId;

    console.log("businessIdxxx", businessId)

    const [project_id, setProjectName] = useState('');
    const [page, setPage] = useState(1);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [filename, setFilename] = useState('')

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);

    const { breakpoints } = useWidth();

    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: "transparent", minHeight: "30px", width: 300 }) };

    useEffect(() => {
        if (dateFrom && dateTo) {
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            console.log("dateFromxxx", date_from)
            console.log("dateToxxx", date_to)

            getFilesByProjectId(projectId, page, date_from, date_to, id);
        }
    }, [dateFrom, dateTo, page]);


    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    const reload = () => {
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        
        console.log("dateFromxxx", reload_dateFrom)
        console.log("dateToxxx", reload_dateTo)

        getFilesByProjectId(projectId, page, reload_dateFrom, reload_dateTo, id);
    }



    const handleChangePagination = (event, page) => {

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        console.log("dateFromxxx", date_from)
        console.log("dateToxxx", date_to)

        setPage(page);
        getFilesByProjectId(projectId, page, date_from, date_to, id);
    }

    

    useEffect(() => {

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        console.log("dateFromxxx", date_from)
        console.log("dateToxxx", date_to)

        getFilesByProjectId(projectId, page, date_from, date_to, id);

    }, [])



    return (
        <Box>
            <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 3, width: '100%', borderRadius: 0, display: 'flex' }}>

                <Typography variant='h6'>Vault</Typography>

                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <Typography>From&nbsp;&nbsp;</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                            <DatePicker
                                value={dateFrom}
                                required
                                views={["year", "month", "day"]}
                                inputProps={{ style: { fontSize: 12, height: 38 } }}
                                sx={{
                                    fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                    "& .MuiInputLabel-root": { fontSize: 12 },
                                    "& .MuiOutlinedInput-root": {
                                        height: 38,
                                        borderRadius: 10,
                                        fontSize: 12,
                                        "& > fieldset": {
                                        },
                                    },
                                }}
                                onChange={(newValue) => { setDateFrom(newValue) }}
                                maxDate={dateTo}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    &nbsp; &nbsp;

                    <Typography>To&nbsp;&nbsp;</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                            <DatePicker
                                value={dateTo}
                                required
                                views={["year", "month", "day"]}
                                inputProps={{ style: { fontSize: 12, height: 38 } }}
                                sx={{
                                    fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                    "& .MuiInputLabel-root": { fontSize: 12 },
                                    "& .MuiOutlinedInput-root": {
                                        height: 38,
                                        borderRadius: 10,
                                        fontSize: 12,
                                        "& > fieldset": {
                                        },
                                    },
                                }}
                                onChange={(newValue) => { setDateTo(newValue) }}
                                minDate={dateFrom}
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    &nbsp; &nbsp;

                    <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                </Box>

            </Box>

            <ProjectVaultListComponent 
                previewFile={previewFile} 
                downloadFile={downloadFile}
                project_id={selectedProject} 
                fileList={fileList} 
                openDocxPreview={openDocxPreview} 
                isPreviewLoading={isPreviewLoading}
                loading={loading} 
                page={page} 
                pagination={pagination} 
                handleChangePagination={handleChangePagination} 
                isLoading={isLoading}
                breakpoints={breakpoints} 
            />
        </Box>



    )
}

export default ProjectVaultScreen