import {useState} from "react";
import AdminAnalyticsService from "../services/api/analytics";

const useDashboard = () => {

    const [isFetchingDashboardCount, setFetchingDashboardCount] = useState(true);
    const [dashboardCount, setDashboardCount] = useState(null);
    const [isFetchingDashboardCountFilter, setFetchingDashboardCountFilter] = useState(false);
    const [dashboardCountFilter, setDashboardCountFilter] = useState(null);

    const [isFetchingRecentActivity, setFetchingRecentActivity] = useState(false);
    const [recentActivityData, setRecentActivityData] = useState([]);
    const [hasNoRecentActivity, setHasNoRecentActivity] = useState(false);
    
    const getDashboardCount = () => {
        setFetchingDashboardCount(true);

        AdminAnalyticsService.getDashboardCount().then((res) => {
            console.log("getDashboardCount data xxxx", res);
            
            if (res.data.success) {
                setFetchingDashboardCount(false);
                setDashboardCount(res.data.data);
            }

        }).catch((error) => {
            console.log("getDashboardCount error xxxx", error);

            setFetchingDashboardCount(false);
        })
    }

    const getEnterpriseDashboardCount = (businessId) => {
        setFetchingDashboardCount(true);

        AdminAnalyticsService.getEnterpriseDashboardCount(businessId).then((res) => {
            console.log("getEnterpriseDashboardCount data xxxx", res);
            
            if (res.data.success) {
                setFetchingDashboardCount(false);
                setDashboardCount(res.data.data);
            }

        }).catch((error) => {
            console.log("getEnterpriseDashboardCount error xxxx", error);

            setFetchingDashboardCount(false);
        })
    }


    const getEnterpriseDashboardCountFilter = (businessId, month, year) => {
        setFetchingDashboardCountFilter(true);

        AdminAnalyticsService.getEnterpriseDashboardCountFilter(businessId, month, year).then((res) => {
            console.log("getEnterpriseDashboardCountFilter data xxxx", res);
            
            if (res.data.success) {
                setFetchingDashboardCountFilter(false);
                setDashboardCountFilter(res.data.data);
            }

        }).catch((error) => {
            console.log("getEnterpriseDashboardCountFilter error xxxx", error);

            setFetchingDashboardCountFilter(false);
        })
    }

    const getDashboardCountFilter = (businessId, month, year, projectId) => {
        setFetchingDashboardCountFilter(true);

        AdminAnalyticsService.getDashboardCountFilter(businessId, month, year, projectId).then((res) => {
            console.log("getDashboardCountFilter data xxxx", res);
            
            if (res.data.success) {
                setFetchingDashboardCountFilter(false);
                setDashboardCountFilter(res.data.data);
            }

        }).catch((error) => {
            console.log("getDashboardCountFilter error xxxx", error);

            setFetchingDashboardCountFilter(false);
        })
    }

    const getRecentActivity = (businessId) => {
        setFetchingRecentActivity(true);

        AdminAnalyticsService.getRecentActivity(businessId).then((res) => {
            console.log("getRecentActivity data xxxx", res);
            
            if (res.data.success) {
                setFetchingRecentActivity(false);

                if (res.data.data.length === 0) {
                    setHasNoRecentActivity(true);

                } else {
                    setRecentActivityData(res.data.data);
                    setHasNoRecentActivity(false);
                }
                
            }

        }).catch((error) => {
            console.log("getRecentActivity error xxxx", error);

            setFetchingRecentActivity(false);
            setHasNoRecentActivity(false);
        })
    }

    
    return {isFetchingDashboardCount, dashboardCount, getDashboardCount, isFetchingDashboardCountFilter, 
        dashboardCountFilter, getDashboardCountFilter, isFetchingRecentActivity, recentActivityData, getRecentActivity,
        hasNoRecentActivity, getEnterpriseDashboardCountFilter, getEnterpriseDashboardCount
    }
}

export default useDashboard