import React, { useEffect, useState } from "react";
import { Skeleton, Box, Button, Paper, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography, IconButton, Tooltip, InputAdornment, TextField, Pagination, FormGroup, Stack, Switch, FormControlLabel, InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ActivateDeactivateUser from "./dialogs/activate-deactivate-user.dialog";
import ViewAllUsersSkeletonComponent from "../skeletons/view-all-users-skeleton.component.jsx"
import SearchIcon from '@mui/icons-material/Search';
import useUsers from "../../hooks/useUsers";
import useBusiness from "../../hooks/useBusiness";
import { format } from 'date-fns';
import useWidth from "../../hooks/useWidth";
import AddUserDialog from "./dialogs/add-user.dialog";
import ActionsPopperComponent from "../users/actions-popper.component";
import EditUserEmailDialog from "../users/dialogs/edit-user-email.dialog";
import ViewUserDialog from "../users/dialogs/view-user.dialog";
import useRolesManagement from "../../hooks/useRolesManagement.jsx";
import ReactSelect from "react-select";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CachedIcon from '@mui/icons-material/Cached';
import useProjects from "../../hooks/useProjects.jsx";
import ForbiddenScreen from "../../screens/ForbiddenScreen.jsx";
import { log } from "../../console-config.log.js";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0178f2',
        color: 'white',
    }
}));

const statusOption = [
    { value: 'true', label: "Active" },
    { value: 'false', label: "Inactive" }
]



function TableHeader() {
    const tablecell = [
        { label: 'ID' },
        { label: 'Email' },
        { label: 'Role' },
        // {label: 'Enterprise Name'},
        { label: 'Project Name' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Actions' }
    ];

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <StyledTableCell sx={{ fontWeight: 700 }}>{value.label}</StyledTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


function TableContent(props) {
    const { breakpoints, user, index, getUsersByBusinessAccountID } = props;

    // let projectId = (new URLSearchParams(window.location.search)).get("id");
    let businessId = (new URLSearchParams(window.location.search)).get("id");

    const { fetchingBusinessDetails, businessDetails, getBusinessById } = useBusiness();

    const [openViewUser, setViewUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const [enterpriseName, setEnterpriseName] = useState(null);
    const [openEditEmail, setOpenEditEmail] = useState(false);

    // console.log("user xx",user)

    useEffect(() => {
        getBusinessById(user?.business_account_id);
    }, [])

    const handleViewUser = (e, id, project_name) => {
        setViewUser(true);
        setUserId(id);
        setEnterpriseName(project_name);
    }

    return (
        <>
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ color: "#0178f2", cursor: "pointer" }}
                    onClick={(e) =>
                        (user?.business_account_id == businessDetails?.id) ?
                            handleViewUser(e, user?.id, (businessDetails?.business_name) ? businessDetails?.business_name : "N/A")
                            :
                            null
                    }
                >
                    {user?.user_id}
                </TableCell>

                <TableCell align="left" sx={{ maxWidth: 200 }}>
                    {(user?.user_principal_name.length > 23) ?
                        <Tooltip arrow title={user?.user_principal_name} placement="top">
                            <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: 300, fontSize: '14px' }}>
                                {user?.user_principal_name}
                            </Typography>
                        </Tooltip>
                        :
                        <Typography sx={{ fontSize: '14px' }}>
                            {user?.user_principal_name}
                        </Typography>
                    }
                </TableCell>

                <TableCell component="th" scope="row" sx={{ color: "#0178f2", cursor: "pointer" }} onClick={() => window.location.href = '/user-list/role-permission?role=' + user?.user_role} >
                    {user?.user_role}
                </TableCell>

                <TableCell component="th" scope="row">
                    {user?.project_name}
                </TableCell>

                <TableCell align="left" sx={{ color: (user?.status == 1) ? "green" : "red" }}>
                    {user?.status == 1 ? "ACTIVE" : "INACTIVE"}
                </TableCell>

                <TableCell align="left">
                    {format(new Date(user?.updated_date), "PP")}
                </TableCell>

                <TableCell align="left">
                    <ActionsPopperComponent breakpoints={breakpoints}
                        userId={user?.id} status={user?.status}
                        setOpenEditEmail={setOpenEditEmail}
                        userEmail={user?.user_principal_name}
                        userRole={user?.user_role}
                        projectId={user?.project_id}
                        getUsersByBusinessAccountID={getUsersByBusinessAccountID}
                        businessId={businessId}
                    />
                </TableCell>
            </TableRow>

            {openEditEmail &&
                <EditUserEmailDialog breakpoints={breakpoints}
                    openEditEmail={openEditEmail} setOpenEditEmail={setOpenEditEmail}
                    userId={user?.id} userEmail={user?.user_principal_name}
                    getUsersByBusinessAccountID={getUsersByBusinessAccountID}
                    businessId={businessId}
                />
            }

            {openViewUser &&
                <ViewUserDialog breakpoints={breakpoints} userId={userId}
                    openViewUser={openViewUser} setViewUser={setViewUser}
                    enterpriseName={enterpriseName}
                />
            }
        </>
    )
}

const ViewBusinessAccountUsersComponent = (props) => {

    const breakpoints = useWidth();
    const { searchPrincipalNameById, isSearchPrincipalNameById } = useBusiness();
    const { pagination, getUsersByBusinessAccountID, userAccounts, error, loadingUserList, setLoading, filterUserByStatus, filterByEmail, filterUserByRole, filterUserByStatusAndRole, getUsersFilterByEnterpriseAndProject, enterpriseUserFilter } = useUsers();
    const { rolesList, getAllRoles } = useRolesManagement();
    const { getProjectList, projects } = useProjects();

    const [status, setStatus] = useState(null);
    const [user, setUser] = useState("");
    const [user_id, setUserId] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_role, setFilterRole] = useState("");
    const [filter_project, setFilterProject] = useState("");

    const [searchInput, setSearchInput] = useState('');
    const [isSelectedLabel, setIsSelectedLabel] = useState(null);
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [updated_users, setUpdatedUsers] = useState([]);

    // const businessId = (new URLSearchParams(window.location.search)).get("id");
    const businessId = (new URLSearchParams(window.location.search)).get("id");

    const searchForOptions = [
        { value: "status", label: "Status" },
        { value: "role", label: "Role" },
        // { value: "email", label: "Email" },
        { value: "status-role", label: "Status and Role" },
        { value: "project", label: "Project Name" }
    ]

    const handleClearFilter = (e) => {
        setSearchStatusVal(null);
        setSearchFor(null);
        setSearchForLabel(null);
        setIsSelectedLabel(null)
    }

    const reload = () => {
        setFilterRole(null);
        setFilterStatus(null);
        setSearchInput('');
        setFilterProject(null);
        handleClearFilter();

        getUsersByBusinessAccountID(businessId, page);
    }

    const handleSelectStatusOption = (selectedOption) => {
        setFilterProject('');
        // getUsersByBusinessAccountID(businessId, page);
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
        }
        // } else {
        //     setSearchFor(null);
        //     setSearchForLabel(null);
        //     setSearchStatusVal(null);

        //     handleClearFilter();
        // }
    }

    const searchItems = (e) => {
        setSearchInput(e.target.value);
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchInput) {
                filterByEmail(searchInput, businessId, page);
            } else {
                getUsersByBusinessAccountID(businessId, page);
            }
        }
    };

    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
        setFilterProject(null)
        setFilterRole(null);
    };

    const handleChangeRole = (event) => {
        setFilterRole(event.target.value);
        // setFilterStatus(null)
        setFilterProject(null)
    }

    const handleChangeProjectName = (event) => {
        setFilterProject(event.target.value);
        setFilterRole(null);
        setFilterStatus(null)
    }

    const handleOpenAddDialog = (e) => {
        setOpenAddDialog(true);
    }

    const handleCloseAddDialog = (e) => {
        setOpenAddDialog(false);
    }

    const handleOpenDialog = (e) => {
        setOpenDialog(true);
    }

    const handleCloseDialog = (e) => {
        setOpenDialog(false);
    }

    const handleChangeEmailPagination = (page) => {
        setPage(page);
        filterByEmail(searchInput, businessId, page);
        setLoading(true);
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
        getUsersByBusinessAccountID(businessId, page);
        setLoading(true);
    }


    const handleStatusChangePagination = (event, page) => {
        setPage(page);
        filterUserByStatus(businessId, filter_status, page);
        setLoading(true);
    }


    const handleRoleChangePagination = (event, page) => {
        setPage(page);
        filterUserByRole(filter_role, page);
        setLoading(true);
    }

    const handleChangeRoleAndStatusPagination = (page) => {
        setPage(page);
        filterUserByStatusAndRole(businessId, filter_status, filter_role, page);
        setLoading(true);
    }

    const handleChangeProjectPagination = (page) => {
        setPage(page);
        getUsersFilterByEnterpriseAndProject(businessId, filter_project, page);
        setLoading(true);
    }

    useEffect(() => { getAllRoles(); }, [])

    useEffect(() => {
        if (userAccounts && projects) {

            // Ensure businessDetails is in array format
            const projectsArray = Array.isArray(projects) ? projects : [projects];

            const projectNameMap = {};
            projectsArray.forEach(detail => {
                projectNameMap[detail?.id] = detail?.application_name;
            });

            // Match user data with business details
            const updatedUsers = userAccounts.map(user => {
                // Assuming each user has a business_account_id property
                const projectName = projectNameMap[user?.project_id] || "N/A";
                return {
                    ...user,
                    project_name: projectName,
                    // Add other business details you need similarly
                };
            });
            setUpdatedUsers(updatedUsers);
        } else {
            setUpdatedUsers(userAccounts);
        }
    }, [JSON.stringify(userAccounts)]);

    useEffect(() => {
        if (businessId) {
            getUsersByBusinessAccountID(businessId, page);
            getProjectList(businessId)
        }
    }, []);

    // useEffect(()=>{
    //     if(filter_status || filter_role || filter_project){
    //         if (filter_status && filter_role) {
    //             filterUserByStatusAndRole(businessId, filter_status, filter_role, page);
    //         } else if (filter_status) {
    //             filterUserByStatus(businessId, filter_status, page);
    //         } else if (filter_role) {
    //             filterUserByRole(businessId, filter_role, page);
    //         } else if (filter_project) {
    //             getUsersFilterByEnterpriseAndProject(businessId, filter_project, page);
    //         } else {
    //             log('No Filter Selected')
    //         }
    //     }
    // },[filter_status, filter_role, filter_project])

    const type2 = 2;
    const type8 = 8;

    useEffect(() => {
        if (filter_status || filter_role ) {
            enterpriseUserFilter(businessId, '', filter_role, filter_status, page, type2);
        } else {
            if(filter_project){
                enterpriseUserFilter(businessId, filter_project, filter_role, filter_status, page, type8);
            }
        }
    }, [filter_status, filter_role, filter_project, page]);

    return (
        <Box>
            {error === "Forbidden" ?
                <ForbiddenScreen />
                :
                <>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12} sx={{ mb: (breakpoints == 'xs') ? 2 : 0, textAlign: "start" }}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>
                                        <Box>
                                            <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>

                                                <ReactSelect
                                                    className="basic-single"
                                                    placeholder={
                                                        <div>
                                                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter:</b>&nbsp;
                                                            <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                                {searchForLabel === "Status" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Status</Typography>
                                                                )}
                                                                {searchForLabel === "Role" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Role</Typography>
                                                                )}
                                                                {searchForLabel === "Status and Role" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Status and Role</Typography>
                                                                )}
                                                                {searchForLabel === "Project Name" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Project Name</Typography>
                                                                )}
                                                                {searchForLabel === null && (
                                                                    "Select Filter"
                                                                )}
                                                            </i>
                                                        </div>
                                                    }
                                                    isSearchable={true}
                                                    isRtl={false}
                                                    options={searchForOptions}
                                                    value={isSelectedLabel}
                                                    onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 38,
                                                            borderRadius: 100,
                                                            backgroundColor: 'transparent',
                                                            width: 200,
                                                            fontSize: 12
                                                        }),
                                                        placeholder: base => ({
                                                            ...base,
                                                            fontSize: 12
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 99999,
                                                            fontSize: 12
                                                        }),
                                                    }}
                                                    isClearable={true}
                                                />

                                                {(searchForLabel === "Status" || searchForLabel === "Status and Role") &&
                                                    <FormControl>
                                                        <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            value={filter_status}
                                                            sx={{
                                                                width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                                '&.Mui-focused': {
                                                                    border: 'none',
                                                                },
                                                            }}
                                                            onChange={handleStatusChange}
                                                            inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                        >
                                                            {statusOption?.map((status) => {
                                                                return (
                                                                    <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }}>
                                                                        {status.label}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                }

                                                {(searchForLabel === "Role" || searchForLabel === "Status and Role") &&
                                                    <FormControl >
                                                        <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Role</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            value={filter_role}
                                                            sx={{
                                                                width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                                '&.Mui-focused': {
                                                                    border: 'none',
                                                                },
                                                            }}
                                                            onChange={handleChangeRole}
                                                            inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                        >
                                                            {rolesList?.map((data, index) => (
                                                                <MenuItem key={data?.id} value={data?.role} sx={{ fontSize: 12 }}>
                                                                    {data?.role}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }

                                                {(searchForLabel === "Project Name") &&
                                                    <FormControl >
                                                        <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Project Name</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            value={filter_project}
                                                            sx={{
                                                                width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent', fontSize: 12,
                                                                '&.Mui-focused': {
                                                                    border: 'none',
                                                                },
                                                            }}
                                                            onChange={handleChangeProjectName}
                                                            inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                        >
                                                            {projects?.map((data, index) => (
                                                                <MenuItem key={data?.id} value={data?.id} sx={{ fontSize: 12 }}>
                                                                    {data?.application_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                }

                                                <TextField
                                                    value={searchInput}
                                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                                    placeholder="Search Email..."
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                                                    }}
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    onChange={searchItems}
                                                    onKeyDown={keyPress}
                                                />

                                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                                            </Stack>

                                        </Box>
                                        <Box sx={{ flexGrow: 1 }}></Box>
                                        {/* <Button variant="contained" sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                                    onClick={(e) => handleOpenAddDialog(e)}
                                >
                                    Add User
                                </Button> */}
                                        <Button
                                            variant="text"
                                            sx={{
                                                fontSize: 14,
                                                p: '4px 5px',
                                                borderRadius: 10,
                                                boxShadow: 'none',
                                                color: '#0178f2',
                                                textTransform: 'none',
                                            }}
                                            startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                            onClick={(e) => handleOpenAddDialog(e)}
                                        >
                                            Add User
                                        </Button>

                                    </Stack>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} sx={{ borderRadius: 2, width: '100%', boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginTop: 2 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 600 }}>
                            <TableHeader />

                            {(loadingUserList) ?
                                <TableBody>
                                    <ViewAllUsersSkeletonComponent />
                                </TableBody>
                                :
                                <TableBody>
                                    {(updated_users)?.map((content, index) => (
                                        <TableContent
                                            index={index}
                                            user={content}
                                            handleOpenDialog={handleOpenDialog}
                                            setStatus={setStatus}
                                            setUser={setUser}
                                            setUserId={setUserId}
                                            breakpoints={breakpoints}
                                            getUsersByBusinessAccountID={getUsersByBusinessAccountID}
                                        />
                                    ))}
                                </TableBody>
                            }
                        </Table>

                        {!loadingUserList && isSearchPrincipalNameById?.length === 0 && searchInput && 
                            <Box sx={{ textAlign: 'center', padding: 5, color: 'gray' }}>
                                No results found.
                            </Box>
                        }

                        {(loadingUserList) ?
                            null
                            :
                            ((!loadingUserList && userAccounts?.length === 0 && updated_users) ?
                                <Box sx={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    No user has been created.
                                </Box>
                                :
                                filter_status ?
                                    <Pagination
                                        count={pagination}
                                        page={page}
                                        onChange={handleStatusChangePagination}
                                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                    />

                                    :

                                    filter_role ?
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleRoleChangePagination}
                                            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                        />

                                        : filter_project ?

                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleChangeProjectPagination}
                                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                            />



                                            : (filter_role && filter_status) ?

                                                <Pagination
                                                    count={pagination}
                                                    page={page}
                                                    onChange={handleChangeRoleAndStatusPagination}
                                                    style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                />

                                                : (searchInput) ?

                                                    <Pagination
                                                        count={pagination}
                                                        page={page}
                                                        onChange={handleChangeEmailPagination}
                                                        style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                                    />

                                                    :

                                                    <Pagination
                                                        count={pagination}
                                                        page={page}
                                                        onChange={handleChangePagination}
                                                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                                    />
                            )
                        }
                    </TableContainer>
                </>
            }


            {openDialog &&
                <ActivateDeactivateUser user={user} status={status} userID={user_id} openDialog={openDialog} handleClose={handleCloseDialog} />
            }

            {openAddDialog &&
                <AddUserDialog
                    openAddDialog={openAddDialog}
                    handleCloseAddDialog={handleCloseAddDialog}
                    getUsersByBusinessAccountID={getUsersByBusinessAccountID}
                />
            }
        </Box>
    )
}

export default ViewBusinessAccountUsersComponent