import React, {useEffect} from "react";
import { log } from "../../../console-config.log";

const SignatureTextToImage = (props) => {
    
    const {signatureText, parentFontSize, parentFontStyle, parentFontFamily, sendDataToParent} = props;

    useEffect(() => {
        
        // let canvasTxt = document?.getElementById("canvasComponent").getContext("2d");

        // canvasTxt.canvas.width = canvasTxt.measureText(signatureText).width;
        // canvasTxt.font = fontStyle;
        // canvasTxt.fillText(signatureText, x, y);

        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        var textMetrics = canvasTxt.measureText(signatureText);

        let fontSize;

        // if (signatureText.length <= 7) {
        //     fontSize = 50;
        // } else {
            fontSize = parentFontSize;
        // }
        
        // canvasTxt.canvas.width = textMetrics.width;
        canvasTxt.canvas.width = textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft;
        canvasTxt.canvas.height = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
        // canvasTxt.canvas.height = fontSize;


        console.log("fontSize aaaa11", fontSize);

        let fontStyle = parentFontStyle;
        let fontFamily = parentFontFamily;

        canvasTxt.font = `${fontStyle} ${fontSize}px ${fontFamily}`;

        console.log("canvasTxt aaaa11", canvasTxt);

        canvasTxt.fillText(signatureText, textMetrics.actualBoundingBoxLeft, textMetrics.actualBoundingBoxAscent);
        // canvasTxt.fillText(signatureText, 0, textMetrics.fontBoundingBoxDescent);
        log("signature textMetrics xxxx", textMetrics);
        log("text image xxxx", canvasTxt.canvas.toDataURL());

        sendDataToParent(canvasTxt.canvas.toDataURL());

    }, [sendDataToParent])


    
    return (
        <div>
            <canvas id="canvasComponent" style={{display: "none"}} />
        </div>
    )
}
    
export default SignatureTextToImage
