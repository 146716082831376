import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getAdminAccessControl = (roleId, page, limit) => {

    let query_params = {
        roleId: roleId,
        page: page,
        limit: limit,  
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.ADMIN_GET_ACCESS_CONTROL_LIST, query_params, subscriptionKey);

    return result;
}


const addAdminAccessControl = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADMIN_ADD_ACCESS_CONTROL, payload, subscriptionKey);

    return result;  
}

const removeAdminAccessControl = (roleId, permissionId) => {

    let query_params = {
        roleId: roleId,
        permissionId: permissionId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.ADMIN_REMOVE_ACCESS_CONTROL, query_params, subscriptionKey);

    return result;
}


const AdminAccessControlService = {
    getAdminAccessControl: getAdminAccessControl,
    addAdminAccessControl: addAdminAccessControl,
    removeAdminAccessControl: removeAdminAccessControl
}

export default AdminAccessControlService