import { Box, Button, Card, Chip, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import useCredits from "../hooks/useCredits"
import { useEffect, useState } from "react"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProjectCreditsListSkeleton from "../components/skeletons/project-credits-list-skeleton.component";
import ProjectCreditsComponent from "../components/credits/project-credits.component";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddProjectCreditDialog from "../components/credits/dialogs/add-project-credit-dialog.component";
import useCreditsManagement from "../hooks/useCreditsManagement";



const TableHeader = () => {

    const tablecell = [
        { label: 'Project Name' },
        { label: 'OVI Credits' },
        { label: 'Actions' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}



const ProjectCreditsManagementScreen = (props) => {

    const { setView, businessName, businessId } = props;

    const { isLoading, getProjectCredits, projectCredits } = useCredits();
    const { businessCredits, viewBusinessCredits } = useCreditsManagement();

    const [page, setPage] = useState(1);
    const [openAddCredit, setOpenAddCredit] = useState(false);


    useEffect(() => {

        if (businessId) {
            getProjectCredits(businessId, page);
            viewBusinessCredits(businessId);
        }

    }, [page, businessId])


    const handleOpenAddCredit = () => {
        setOpenAddCredit(true);
    }

    const handleCloseAddCredit = () => {
        setOpenAddCredit(false);
    }

    const totalProjectCredits = projectCredits?.reduce((total, credit) => total + credit.credit, 0);
    const remainingCredits = businessCredits - totalProjectCredits;

    // console.log("remainingCreditsxx", remainingCredits)

    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            <Grid container>
                <Grid item xs={6}>
                    <Button startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
                        style={{ color: "#000", fontSize: 18, textTransform: 'none', fontWeight: 600 }}
                        onClick={() => setView(false)} >
                        {businessName && businessName}
                    </Button>
                    &nbsp;
                    <Chip color="success" variant="outlined" size="small" label={
                        <Typography sx={{ fontSize: "13px" }}>
                            {(remainingCredits > 0) ? remainingCredits + " Credits" : "0 Credit"}
                        </Typography>}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                    <Button
                        variant="text"
                        sx={{
                            fontSize: 14,
                            p: '4px 5px',
                            borderRadius: 10,
                            boxShadow: 'none',
                            color: '#0178f2',
                            textTransform: 'none',
                        }}
                        startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                        onClick={handleOpenAddCredit}
                    >
                        Add Credit
                    </Button>
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', margin: 'auto', mt: 2 }}>
                <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                        <TableHeader />

                        {isLoading ?
                            <TableBody><ProjectCreditsListSkeleton /></TableBody>
                        :
                            <TableBody sx={{ width: "100%" }}>
                                {projectCredits.map((credits, index) => (
                                    <ProjectCreditsComponent
                                        data={credits}
                                        index={index}
                                        getProjectCredits={getProjectCredits}
                                        businessId={businessId}
                                        page={page}
                                    />
                                ))}

                            </TableBody>
                        }
                    </Table>
                    {!isLoading && projectCredits.length === 0 &&
                    <Typography sx={{textAlign:'center', p: 3}} >No Credit Records.</Typography>
                    }
                </TableContainer>
            </Box>

            {openAddCredit &&
                <AddProjectCreditDialog
                    openAddCredit={openAddCredit}
                    handleClose={handleCloseAddCredit}
                    businessId={businessId}
                    businessName={businessName}
                />
            }

        </Box>
    )
}

export default ProjectCreditsManagementScreen;