import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth"
import PermissionsScreen from "./PermissionsScreen";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import AccessControlComponent from "../components/rbac/access-control.component";
import AddAccessDialog from "../components/rbac/dialogs/create-access.dialog";
import ViewAccessControlScreen from "./ViewAccessControlScreen";
import ForbiddenScreen from "./ForbiddenScreen";

const AccessControlManagementScreen = () => {

    const [openAddAccess, setOpenAddAccess] = useState(false);
    const [isView, setView] = useState(false);
    const [role_id, setRoleId] = useState(null);
    const [role_name, setRoleName] = useState('');
    const [error, setError] = useState('');


    const handleOpenAddAccess = () => {
        setOpenAddAccess(true);
    }

    const handleCloseAddAccess = () => {
        setOpenAddAccess(false);
    }

    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
             <Grid container>
                <Grid item xs={4} sm={4}>
                    <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                    Manage Enterprise Roles and their corresponding access to features
                    </Typography>
                </Grid>
                </Grid>
           
           {error ?
                <ForbiddenScreen />
            :
                <Box sx={{mt: 6}}>
                    {isView ?
                        <ViewAccessControlScreen setView={setView} roleId={role_id} roleName={role_name} />
                    :    
                        <AccessControlComponent setView={setView} setRoleId={setRoleId} setRoleName={setRoleName} setError={setError} />
                    }
                </Box>
           }

            {openAddAccess &&
                <AddAccessDialog 
                    openAddAccess={openAddAccess}
                    handleClose={handleCloseAddAccess}
                />
            }

        </Box>
    )
}

export default AccessControlManagementScreen