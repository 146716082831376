import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {useSnackbar} from "notistack";
import { ThreeDots } from 'react-loader-spinner';
import {FileUploader} from "react-drag-drop-files";
import { Document, Page } from 'react-pdf';
import {convertFileSizeToBytes} from "../../../../utils/fileSizeToBytesUtil";
import useReports from "../../../../hooks/useReports";
import useWidth from "../../../../hooks/useWidth";

function UploadSoaDialogComponent (props){

    const {openUploadSoa, setOpenUploadSoa, list, projectId, businessId, page} = props;

    const {uploadSOA, created, uploadingSoa} = useReports();
    const breakpoints = useWidth();

    const {enqueueSnackbar} = useSnackbar();

    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [reference_no, setReferenceNo] = useState("");
    const [notes, setNotes] = useState("");
    const [exactFileSize, setExactFileSize] = useState(0);

    var pageNumber = 1;

    const handleClose = () => {
        setOpenUploadSoa(false)
    }

    const handleChangeReference = (e) => {
        setReferenceNo(e.target.value)
    }

    const handleChangeNotes = (e) => {
        setNotes(e.target.value)
    }

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    let sizeFile = file?.size;

    let fileSizes = formatBytes(sizeFile);

    const handleSubmit = () => {
        if (exactFileSize <= 2000000 ) {
            let formData = new FormData();
                formData.append('soa_file', file);
                formData.append('reference_no', reference_no ? reference_no : '');
                formData.append('notes', notes ? notes : '');
                formData.append('project_id', projectId);
                formData.append('business_id', businessId);
                uploadSOA(formData)
        }else {
            enqueueSnackbar("Uploaded file size exceeds 5MB, please choose another file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();

        if (mimetype == "pdf" || mimetype == "docx" || mimetype == "xlsx" || mimetype == "doc" || mimetype == "xls") {
            setFile(file);
            setFilePreview(URL.createObjectURL(file));

        } else {
            enqueueSnackbar("File type not supported", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    };

    useEffect(() => {
        if (fileSizes) {
            convertFileSizeToBytes(fileSizes);
            setExactFileSize(convertFileSizeToBytes(fileSizes));
        }
    }, [fileSizes])


    useEffect(() => {

        if(created){
            handleClose();
            props.retrieveProjectSOA(projectId, businessId, '', page);
        }

    },[created])


    return(
        <Dialog open={openUploadSoa} onClose={handleClose} maxWidth={'md'} fullWidth={'md'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                <Typography sx={{fontSize: 16, fontWeight:'bold'}}>
                    Upload SOA
                </Typography>
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{p: 5, pt: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="warning">"Please ensure your Statement of Account is in the correct file format and contains accurate data before proceeding with the upload."</Alert>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{fontWeight:'bold'}}>SOA</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end'}}>
                        <Typography sx={{color: 'red', fontSize: 13}}>Accept PDF file only.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            hoverTitle=""
                            multiple={false}
                            handleChange={handleChange}
                            name="file"
                            label="Drag and Drop to upload"
                            children={
                                (file) ?
                                    <div 
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            border: "dashed 2px #0178f2",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "280px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear"
                                        }} 
                                    >
                                        <Document file={file}>
                                            <Page pageNumber={pageNumber} height={100} />
                                        </Document>
                                    </div>
                                :
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100% !important",
                                            maxWidth: "100% !important",
                                            border: "dashed 2px #0178f2",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            flexGrow: 0,
                                            height: "210px",
                                            justifyContent: "center",
                                            margin: "auto",
                                            transition: "border-color .15s linear",
                                        }}
                                    >
                                        <p style={{color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : "13px"}}>
                                            Drag and Drop to upload
                                        </p>
                                    </div>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className="card-search"
                            fullWidth
                            value={reference_no ? reference_no : ""}
                            variant="outlined"
                            autoComplete="off"
                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 1, backgroundColor:'white' } }}
                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle:'italic' } }}
                            placeholder="Reference No. (Optional)"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><p style={{fontWeight:'bold', fontSize: 12}}>Reference No:</p></InputAdornment>,
                            }}
                            onChange={handleChangeReference}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight:'bold', fontSize: 13}}>Notes</Typography>
                        <TextField
                            id="outlined-multiline-flexible"
                            multiline
                            maxRows={5}
                            value={notes ? notes : ""}
                            inputProps={{ style: { fontSize: 12, fontStyle:'italic' } }}
                            fullWidth
                            placeholder="Leave a notes"
                            onChange={handleChangeNotes}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{mr: 3, mb: 1}}>
                <Button onClick={handleClose} sx={{textTransform:'none', fontSize: 14}}>
                    Cancel
                </Button>

                {file ? 
                    <Button variant="contained" sx={{textTransform:'none'}} onClick={handleSubmit}
                        startIcon={uploadingSoa && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> }
                    >
                        {uploadingSoa ? "Submitting" : "Submit"} 
                    </Button>
                :
                    <Button disabled variant="contained" sx={{textTransform:'none'}} onClick={handleSubmit}
                        startIcon={uploadingSoa && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> }
                    >
                        {uploadingSoa ? "Submitting" : "Submit"} 
                    </Button>
                }
               
            </DialogActions>
        </Dialog>
    )
}
export default UploadSoaDialogComponent