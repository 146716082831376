import React, {useState, useRef, useEffect} from "react";
import {Box, Grid, Checkbox, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, CircularProgress} from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AddIcon from '@mui/icons-material/Add';

import {FileUploader } from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import {ThreeDots} from "react-loader-spinner";
import useSignature from "../../../../hooks/useSignature";
import { convertUrlToFile } from "../../../../utils/convertUrlToFileUtil";
import { log } from "../../../../console-config.log";
import SignatureActionsPopperComponent from "./signature-actions-popper.component";
import EditTemplateSignatureDialog from "./edit-template-signature.dialog";
import DeleteProjectSignatureDialog from "./delete-project-signature.dialog";
import AddTemplateSignatureDialog from "./add-template-signature.dialog";
import AddSignatureSkeletonComponent from "../../../skeletons/add-signature-skeleton.component";

// import {convertUrlToFile} from "../../utils/convertUrlToFileUtil";

const ManageTemplateSignatureDialog = (props) => {

    const {template, openManageSignature, setOpenManageSignature, breakpoints, isSignatureCreated, setIsSignatureCreated, signatureUpdated, 
        setSignatureUpdated, signatureDeleted, setSignatureDeleted, setNewAssignedSignature, setRemovedAssignedSignature} = props;

    const {enqueueSnackbar} = useSnackbar();

    const {getSignatureList, signatureList, isAddingSignature, isAssigningSignature, newAssignedSignature, assignTemplateSignature,
    isRemovingSignature, removedAssignedSignature, removeTemplateSignature, templateSignatureList, getAssignedTemplateSignatureList,
    getTemplateSignatureLimit, templateSignatureLimit} = useSignature();

    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [isFullnameRequired, setFullnameRequired] = useState(false);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);

    const [imageURL, setImageURL] = useState(null);
    const [resultURL, setResultURL] = useState(null);

    const [openDeleteTempSignatureDialog, setOpenDeleteTempSignatureDialog] = useState(false);
    const [openEditSignature, setOpenEditSignature] = useState(false);
    const [openAddSignature, setOpenAddSignature] = useState(false);

    const [projectSignatureList, setProjectSignatureList] = useState([]);
    const [isDoneLoadingSignatures, setIsDoneLoadingSignatures] = useState(false);
    const [currentSignature, setCurrentSignature] = useState(null);
    const [signatureLimit, setSignatureLimit] = useState(0);
    const [signatureListCount, setSignatureListCount] = useState(0);
    const [openSignatureConfirmation, setOpenSignatureConfirmation] = useState(false);

    useEffect(() => {
        if (templateSignatureLimit) {
            if (templateSignatureLimit.data) setSignatureLimit(templateSignatureLimit.data)
        }
    }, [JSON.stringify(templateSignatureLimit)])

    useEffect(() => {
        if (template) {
            let projectId = template.project_id
            // setIsDoneLoadingSignatures(true)
            getSignatureList(projectId)
            getTemplateSignatureLimit(projectId);
        }
    }, [JSON.stringify(template)])

    useEffect(() => {
        if (currentSignature) {
            console.log("currentSignature aaa", currentSignature);
        }
    }, [JSON.stringify(currentSignature)])

    useEffect(() => {
        if (isSignatureCreated || signatureDeleted.isDeleted || signatureUpdated.isUpdated) {
            console.log("signatureDeleted.isDeleted 11", signatureDeleted.isDeleted);
            getSignatureList(template.project_id)
        };
    }, [isSignatureCreated, JSON.stringify(signatureDeleted), JSON.stringify(signatureUpdated)])

    useEffect(() => {
        if (signatureList) {
            console.log("signatureList 222", signatureList);
            setSignatureListCount(signatureList.data.length)
            getAssignedTemplateSignatureList(template.id)
        }
    }, [JSON.stringify(signatureList)])

    useEffect(() => {
        if (templateSignatureList) {
            console.log("signatureList 5555", signatureList);
            let project_signature_list = [];
            
            if (signatureList.data.length > 0) {
                let assignedSignatureList = templateSignatureList.data

                project_signature_list = signatureList.data.map((item) => {
                    item.template_id = template.id
                    
                    if (item.id == assignedSignatureList.find((sig) => sig.signature.id == item.id)?.signature.id) {
                        item.isAssigned = true
                    } 
                    else item.isAssigned = false
                    return item;
                })
                console.log("project_signature_list aa", project_signature_list);
            }

            setProjectSignatureList(project_signature_list)
            setIsDoneLoadingSignatures(false)
        }
    }, [JSON.stringify(templateSignatureList), JSON.stringify(signatureList)])

    useEffect(() => {
        if (newAssignedSignature) setNewAssignedSignature(newAssignedSignature)
        if (removedAssignedSignature) setRemovedAssignedSignature(removedAssignedSignature)

        if (newAssignedSignature || removedAssignedSignature) getAssignedTemplateSignatureList(template.id)
    }, [JSON.stringify(newAssignedSignature), JSON.stringify(removedAssignedSignature)])

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "svg" || mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            if (file.size <= 1 * 1024 * 1024) {
                // const reader = new FileReader();

                // reader.onload = (e) => {
                //     setImageURL(e.target.result);
                // }

                // reader.readAsDataURL(file);

                setSignatureFile(file);
                setSignaturePreview(URL.createObjectURL(file));

                // removeWhiteBackground();

            } else {
                enqueueSnackbar("File exceeds 1MB, please choose another image file", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
            
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/;

        return re.test(email);
    }

    const handleTextFieldChange = (e, name) => {
        if (name == "fullname") {
            setFullname(e.target.value);
            setFullnameRequired(false);

        } else if (name == "email") {
            let user_email = e.target.value;

            setEmail(user_email);
            setEmailRequired(false);

            if (user_email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }
        }
    }

    // const removeBackground = (imageUrl, callback) => {
    //     const image = new Image();

    //     image.crossOrigin = 'anonymous';
    //     image.src = imageUrl;
      
    //     image.onload = function () {
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');
    //         canvas.width = image.width;
    //         canvas.height = image.height;
      
    //         ctx.drawImage(image, 0, 0);
      
    //         const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //         const data = imageData.data;
      
    //         for (let i = 0; i < data.length; i += 4) {
    //             const red = data[i];
    //             const green = data[i + 1];
    //             const blue = data[i + 2];
      
    //             // check if the pixel is close to white
    //             if (red >= 200 && green >= 200 && blue >= 200) {
    //                 data[i + 3] = 0;
    //             }
    //         }
      
    //         ctx.putImageData(imageData, 0, 0);
      
    //         // convert canvas image to data URL
    //         const whitebgImageUrl = canvas.toDataURL('image/png');
      
    //         // invoke the callback function with the edited image URL
    //         callback(whitebgImageUrl);
    //     }

    //     image.onerror = function () {
    //         throw new Error("failed to load image");
    //     }
    // }

    const handleResetFile = (error) => {      
        setImageURL(null);
        setResultURL(null);
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const handleCloseDialog = (e) => {
        setOpenManageSignature(false);
        setSignatureFile(null);
        setImageURL(null);
        setResultURL(null);
    }

    const handleCancel = (e) => {
        handleCloseDialog();
    }

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
      
              // Draw the image on the canvas
              ctx.drawImage(image, 0, 0);
      
              // Get the image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
      
              // Remove the background (assuming light background and dark signature lines)
              const tolerance = 245; // Adjust this value to set the tolerance for background removal
              for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];
                const alpha = data[i + 3];
      
                // Check if the pixel color is close to the background color
                if (Math.abs(red - 255) <= tolerance &&
                    Math.abs(green - 255) <= tolerance &&
                    Math.abs(blue - 255) <= tolerance &&
                    alpha > 0) {
                  data[i + 3] = 0; // Set alpha to 0 (transparent)
                }
              }
      
              // Put the modified image data back to the canvas
              ctx.putImageData(imageData, 0, 0);
      
              // Convert the canvas content back to base64 string
              const modifiedImage = canvas.toDataURL();
      
              resolve(modifiedImage);
            };
      
            image.src = event.target.result;
          };
      
          reader.readAsDataURL(imageFile);
        });
    };

    const handleCreateSignature = (e) => {
        if (fullname && email && signatureFile && isValidEmail) {
            // let formData = new FormData();

            // formData.append("signature", signatureFile);
            // formData.append("fullName", fullname);
            // formData.append("email", email);

            // createSignature(formData, setOpenAddSignature, setIsSignatureCreated);
            removeWhiteBackground(signatureFile)
            .then((modifiedImage) => {
                // Use the modified image as needed (e.g., display or upload)
                log(modifiedImage);
                convertUrlToFile(modifiedImage, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {
                    // createSignature(editedSignatureFile, email, project_id, setOpenAddSignature, setIsSignatureCreated);
                });

            })
            
        } else if (!signatureFile) {
            enqueueSnackbar("Signature File is required", {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!fullname) {
            setFullnameRequired(true);

        } else if (!email) {
            setEmailRequired(true);

        } else if (!isValidEmail) {
            enqueueSnackbar("Invalid email address format", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleSelectSignature = (signature) => {
        setCurrentSignature(signature)
        let isSelected = projectSignatureList.find((item) => item.id == signature.id).isAssigned
        if (isSelected) removeTemplateSignature(template.id, signature.id)
        else assignTemplateSignature(template.id, signature.id)
    }

    const handleAddSignature = () => {
        setOpenAddSignature(true);
    }

    const handleDone = () => {
        handleCloseDialog()
    }

  
    return (
        <Dialog hideBackdrop={openSignatureConfirmation && true} open={openManageSignature} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                Add Signatures ({signatureListCount + "/" + signatureLimit})
            </DialogTitle>

            <Divider/>
            
            <DialogContent sx={{padding: (signaturePreview) ? "1px 24px 10px" : "24px 24px 10px"}}>
                {
                    isDoneLoadingSignatures ? 
                    <AddSignatureSkeletonComponent/>
                    :
                    <>
                        {
                            projectSignatureList.length == 0 &&
                            <Grid container spacing={2} sx={{overflow: "auto"}}>
                                <Grid item xs={12} sx={{textAlign:'center'}}>
                                    <Typography sx={{cursor: "default", color: "gray", margin: "auto"}}>
                                        No available signatures to yet.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign:'center'}}>
                                    <Button startIcon={<AddIcon />} onClick={handleAddSignature} sx={{textTransform: "none", fontSize: 14}} variant="text"
                                        >
                                        Add Signature
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {
                            projectSignatureList.length > 0 &&
                            <>
                                {
                                    signatureListCount < signatureLimit &&
                                    <>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button startIcon={<AddIcon />} onClick={handleAddSignature} sx={{textTransform: "none", fontSize: 14}} variant="text"
                                                >
                                                Add Signature
                                            </Button>
                                        </div>
                                        <br />
                                    </>
                                }
                                <Typography sx={{cursor: "default", color: "gray", margin: "auto"}}>
                                    Select signature to add
                                </Typography>
                                <br />
                                {
                                    projectSignatureList.map((item) => (
                                        <Grid container direction="row" sx={{display: "flex", mb: 1}}>
                                            <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                                                <Box 
                                                    onClick={() => handleSelectSignature(item)}
                                                    data-testid={`box`} 
                                                    sx={{height: 50, 
                                                        display: 'flex', 
                                                        alignContent: 'center',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderStyle: 'dotted !important', 
                                                        bgcolor: '#0178f20f',
                                                        border: '1px solid', 
                                                        borderColor: '#0178f20f',
                                                        p: '0px 10px 0px 0px', 
                                                        position: 'relative',
                                                        textTransform: 'none',
                                                        lineHeight: 1.2,
                                                        transition: 'all 0.1s ease 0s',
                                                        borderRadius: 1,
                                                        cursor: 'pointer',
                                                    }}  
                                                >
                                                    { (isAssigningSignature || isRemovingSignature) && currentSignature.id == item.id ? <CircularProgress size={24} sx={{ml: 1}} /> : <Checkbox checked={item.isAssigned} inputProps={{ 'aria-label': 'controlled' }} /> }
                                                    <Box sx={{flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s'}}>
                                                        <Typography sx={{fontWeight: 600, fontSize: 14, color: 'rgba(24, 24, 24, 0.85)'}}>
                                                            {item.email}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {
                                                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                                                    <SignatureActionsPopperComponent 
                                                        tempSignatureElement={item}
                                                        setCurrentSignature={setCurrentSignature}
                                                        setOpenDeleteTempSignatureDialog={setOpenDeleteTempSignatureDialog}
                                                        setOpenEditSignature={setOpenEditSignature}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    ))
                                }
                            </>
                        }
                    </>
                }

                    {openAddSignature &&
                        <AddTemplateSignatureDialog 
                        template={template}
                        breakpoints={breakpoints}
                        openAddSignature={openAddSignature} setOpenAddSignature={setOpenAddSignature} 
                        setIsSignatureCreated={setIsSignatureCreated} setOpenSignatureConfirmation={setOpenSignatureConfirmation}
                        openSignatureConfirmation={openSignatureConfirmation}
                        />
                    }

                    {
                        openEditSignature &&
                        <EditTemplateSignatureDialog 
                            tempSignatureElement={currentSignature} 
                            breakpoints={breakpoints}
                            openEditSignature={openEditSignature} 
                            setOpenEditSignature={setOpenEditSignature} 
                            setSignatureUpdated={setSignatureUpdated}
                        />
                    }

                    {
                        openDeleteTempSignatureDialog &&
                        <DeleteProjectSignatureDialog 
                            tempSignatureDetails={currentSignature}
                            openDeleteTempSignatureDialog={openDeleteTempSignatureDialog} 
                            setOpenDeleteTempSignatureDialog={setOpenDeleteTempSignatureDialog} 
                            setSignatureDeleted={setSignatureDeleted}
                        />
                    }


            </DialogContent>
   
            <DialogActions sx={{padding: "8px 22px 20px 0px"}}>
                <Button sx={{width: "15%", borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14}} variant="outlined"
                    onClick={(e) => handleCancel(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2",
                    width: (isAddingSignature) ? "auto" : "15%", '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={handleDone}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ManageTemplateSignatureDialog