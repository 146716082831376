import React, {useState} from "react";
import {Box, Typography, IconButton, Grid} from "@mui/material";
import {useDrag} from "react-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {useDraggedItemStore} from "../../zustand/useDraggedItemStore";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SignatureActionsPopperComponent from "./signature-actions-popper.component";
import useWidth from "../../hooks/useWidth";
import { dragSourceType } from "../../common/staticVariables";
import RemoveAssignedTemplateSignatureDialog from "./dialogs/template-signature-management/remove-assigned-template-signature";

const PDFDragSignatureBoxComponent = ({element, setSignatureUpdated, setSignatureDeleted, setRemoveTemplateSignature, removeTemplateSignature}) => {

    const breakpoints = useWidth();
    const {draggedElement} = useDraggedItemStore();
    console.log("PDFDragSignatureBoxComponent element aa", element);

    const [{isDragging}, dragRef] = useDrag(() => ({
        type: dragSourceType,
        item: {
            type: "signature", 
            value: element?.signature.signature_url,
            w: 100, h: 100, 
            email: element?.signature.email, 
            signature_id: element?.signature.id, 
            fullName: element?.signature.full_name
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [JSON.stringify(element)])

    const opacity = (isDragging) ? 0.4 : 1

    const [isHovered, setIsHovered] = useState(false);
    const [openDeleteTempSignatureDialog, setOpenDeleteTempSignatureDialog] = useState(false);
    const [openAddSignature, setOpenAddSignature] = useState(false);

    const onMouseEnter = () => {
        setIsHovered(true)
    }
    
    const onMouseLeave = () => {
        setIsHovered(false)
    }

            
    return (
        <>
            <Grid container direction="row" sx={{display: "flex"}}>
                
                <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                    <Box 
                        ref={dragRef} 
                        data-testid={`box`} 
                        sx={{height: 50, 
                            display: 'flex', 
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dotted !important', 
                            opacity: opacity, 
                            bgcolor: '#0178f20f',
                            border: '1px solid', 
                            borderColor: '#0178f20f',
                            cursor: 'all-scroll',
                            p: '0px 10px 0px 0px', 
                            position: 'relative',
                            textTransform: 'none',
                            lineHeight: 1.2,
                            transition: 'all 0.1s ease 0s',
                            borderRadius: 1
                        }}  
                        onMouseEnter={(e) => onMouseEnter()} 
                        onMouseLeave={(e) => onMouseLeave()}
                    >
                        {isHovered && 
                            <DragIndicatorIcon sx={{ml: "3px", alignSelf: 'center', color: "#0178f2", transition: 'opacity 0.15s ease 0s, visibility 0.15s ease 0s'}} /> }
                                
                        <Box sx={{flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s'}}>
                            {/* <Typography sx={{fontWeight: 600, fontSize: 12, color: 'rgba(24, 24, 24, 0.85)'}}>
                                {element?.full_name}
                            </Typography> */}
                            <Typography sx={{fontWeight: 600, fontSize: 14, color: 'rgba(24, 24, 24, 0.85)'}}>
                                {element?.signature.email}
                            </Typography>
                        </Box>
                                
                        {!isHovered && 
                            <IconButton sx={{color: '#0178f2', padding: 0}}>
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        }
                    </Box>
                </Grid>
                {
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <SignatureActionsPopperComponent element={element} 
                            setOpenDeleteTempSignatureDialog={setOpenDeleteTempSignatureDialog}
                            // setOpenAddSignature={setOpenAddSignature}
                        />
                    </Grid>
                }

            </Grid>

            {openDeleteTempSignatureDialog &&
                <RemoveAssignedTemplateSignatureDialog 
                    tempSignatureDetails={element}
                    openDeleteTempSignatureDialog={openDeleteTempSignatureDialog} setOpenDeleteTempSignatureDialog={setOpenDeleteTempSignatureDialog} 
                    setSignatureDeleted={setSignatureDeleted} removeTemplateSignature={removeTemplateSignature}
                />
            }

            {/* {openAddSignature &&
                <EditTemplateSignatureDialog tempSignatureElement={element} breakpoints={breakpoints}
                    openAddSignature={openAddSignature} setOpenAddSignature={setOpenAddSignature} setSignatureUpdated={setSignatureUpdated}
                />
            } */}
        </>
    )
}

export default PDFDragSignatureBoxComponent