import { Box, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Button, Stack, IconButton, Card, CardContent, Tooltip, Menu } from "@mui/material";
import { useEffect, useState } from "react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Masonry } from "@mui/lab";

import { PieChart, Pie, Legend, Tooltip as RechartsTooltip, ResponsiveContainer, Cell } from 'recharts';
import { mbConverter } from "../../../utils/MBConverter";
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useReports from "../../../hooks/useReports";
import useProjects from "../../../hooks/useProjects";
import ReportStorageSkeletonComponent from "../../skeletons/reports-storage-skeleton.component";


const ReportsContentComponent = ({ item, formattedDateRange }) => {

    const data = [
        { name: 'Micro', value: item?.sizesCapacity?.micro, size: mbConverter(item?.sizesCapacity?.micro) },
        { name: 'Small', value: item?.sizesCapacity?.small, size: mbConverter(item?.sizesCapacity?.small) },
        { name: 'Regular', value: item?.sizesCapacity?.regular, size: mbConverter(item?.sizesCapacity?.regular) },
        { name: 'Large', value: item?.sizesCapacity?.large, size: mbConverter(item?.sizesCapacity?.large) },
    ];

    const storageCountData = [
        { name: 'Micro', count: item?.sizesCount?.micro, size: mbConverter(item?.sizesCapacity?.micro) },
        { name: 'Small', count: item?.sizesCount?.small, size: mbConverter(item?.sizesCapacity?.small) },
        { name: 'Regular', count: item?.sizesCount?.regular, size: mbConverter(item?.sizesCapacity?.regular) },
        { name: 'Large', count: item?.sizesCount?.large, size: mbConverter(item?.sizesCapacity?.large) },
    ];


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const filteredData = data.filter(item => item.value > 0 || null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        const text = `OVCODE Vault Usage Report as of ${formattedDateRange}`;
        const pageWidths = doc.internal.pageSize.getWidth();

        doc.setFontSize(12);

        const textWidths = doc.getTextWidth(text);
        const xPosition = (pageWidths - textWidths) / 2;

        doc.text(text, xPosition, 10);

        const columns = ["Level", "Count", "Total"];
        const data = storageCountData?.map(item => [item.name, item.count, item.size]);

        const overallTotal = mbConverter(item?.totalSize);

        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20
        });
        doc.setFontSize(12); // Set the font size to 12


        // Calculate the position to display the "Overall Total" text below the right side of the table
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth(`Overall Total: ${overallTotal}`) * 12; // 12 is the font size
        const textX = pageWidth - textWidth - -35; // Adjust the position as needed
        const textY = doc.autoTable.previous.finalY + 10; // Adjust the vertical position as needed

        const totalCount = Object.values(storageCountData).reduce((acc, count) => acc + count.count, 0);

        const totalUsage = `Total Usage: ${totalCount} Documents (${mbConverter(item?.totalSize)})`;

        doc.text(totalUsage, textX, textY);


        doc.save(`OVCODE Vault Usage Report as of ${formattedDateRange}.pdf`);
        handleClose()
    };


    return (
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                    <Typography sx={{ fontWeight: 'bold' }} >
                        {item.project_name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={2} sx={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }} >
                    <Tooltip title={<Typography>micro(1kb-10kb)<br />small(10kb-100kb)<br />regular(100kb-1mb)<br />large(1MB-10MB)</Typography>} placement="top">
                        <InfoIcon sx={{ color: '#0178f2' }} />
                    </Tooltip>
                    <IconButton onClick={handleClick}><MoreVertIcon sx={{ color: '#0178f2' }} /></IconButton>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={generatePDF} sx={{ fontSize: 12 }} >Download</MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs={12} sm={7} sx={{ textAlign: 'center' }}>
                    <ResponsiveContainer width="100%" height={250}>
                        <PieChart margin={{ top: 5, right: 5, left: 5, bottom: 5 }} style={{ margin: "auto" }}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={true}
                                data={filteredData}
                                cx="50%"
                                cy="50%"
                                outerRadius={75}
                                label={({ index }) => filteredData[index].name + ": " + filteredData[index].size}
                                fill={(entry, index) => COLORS[index % COLORS.length]}
                            >
                                {
                                    data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                        {mbConverter(item?.totalSize)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} sx={{ display: 'grid' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {storageCountData.map((row) => (
                                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell sx={{ width: '10%', fontWeight: 'bold' }} component="th" scope="row"> {row.name} </TableCell>
                                        <TableCell sx={{ width: '10%' }}>{row.count}</TableCell>
                                        <TableCell sx={{ width: '30%' }} align="start">{row.size}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </CardContent>
    )
}


const EnterpriseStorageComponent = (props) => {

    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    const { getStorageUsage, storageData, loading } = useReports();
    const { getProjectList, projects } = useProjects();

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [updatedStorageData, setUpdateStorageData] = useState([]);


    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const optionsDate = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        if ((businessIdFromURL) && dateFrom && dateTo) {

            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            getStorageUsage(businessIdFromURL, date_from, date_to);
        }
    }, [businessIdFromURL, dateFrom, dateTo]);


    useEffect(() => {
        getProjectList(businessIdFromURL)
    }, [businessIdFromURL])

    useEffect(() => {
        if (storageData && projects) {

            // Ensure businessDetails is in array format
            const projectsArray = Array.isArray(projects) ? projects : [projects];

            const projectNameMap = {};
            projectsArray.forEach(detail => {
                projectNameMap[detail?.id] = detail?.application_name;
            });

            console.log('ssssssssssssssssssss', projectNameMap)

            // Match user data with business details
            const updatedData = storageData.map(user => {
                // Assuming each user has a business_account_id property
                const projectName = projectNameMap[user?.project_id] || "N/A";
                return {
                    ...user,
                    project_name: projectName,
                    // Add other business details you need similarly
                };
            });
            setUpdateStorageData(updatedData);
        } else {
            setUpdateStorageData(storageData);
        }
    }, [JSON.stringify(storageData), projects]);


    return (
        <Box>
            <Grid container spacing={0} sx={{ zIndex: 4, position: 'relative' }}>
                <Grid item xs={12} sx={{ textAlign: "end", justifyContent: 'flex-end', ml: 'auto', paddingLeft: 2 }}>
                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateFrom}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12, height: 38 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    borderRadius: 10,
                                                    fontSize: 12,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => { setDateFrom(newValue) }}
                                            maxDate={dateTo}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

                                &nbsp; &nbsp;

                                <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateTo}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    fontSize: 12,
                                                    borderRadius: 10,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}

                                            onChange={(newValue) => { setDateTo(newValue) }}
                                            minDate={dateFrom}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>

            <Box sx={{ mt: 5 }} />

            <Box>
                {loading ?
                    <ReportStorageSkeletonComponent />
                :
                    <Masonry columns={{ xs: 1, sm: 2 }} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0 }}>
                        {updatedStorageData?.map((item, index) => (
                            <Card key={index}>
                                <ReportsContentComponent item={item} formattedDateRange={formattedDateRange} />
                            </Card>
                        ))}
                    </Masonry>
                }
                {updatedStorageData?.length === 0 &&
                    <Typography sx={{ display: 'grid', placeItems: 'center', mt: 30 }}>No Record found.</Typography>}
            </Box>



        </Box>
    );
};

export default EnterpriseStorageComponent;
