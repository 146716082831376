import PermissionManagementService from "../services/api/permission-management";
import { useState } from "react";
import { useSnackbar } from "notistack";


const usePermissionManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [modules, setModules] = useState([]);
    const [createdPermission, setCreatedPermission] = useState(false);
    const [createdModule, setCreatedModule] = useState(false);
    const [updatedPermission, setUpdatedPermission] = useState(false);
    const [deletingPermission, setDeletingPermission] = useState(false);
    const [deletedPermission, setDeletedPermission] = useState(false);
    const [updatedModule, setUpdatedModule] = useState(false);
    const [deletingModule, setDeletingModule] = useState(false);
    const [deletedModule, setDeletedModule] = useState(false);

    const [pagination, setPagination] = useState(0);
    const [isSearchingPermission, setSearchingPermission] = useState(false);
    const [searchedPermission, setSearchedPermission] = useState([]);
    const [isSearchPermissionToggle, setSearchPermissionToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);
    const [error, setError] = useState('');

    var limit = 10;
    var AllFunctionslimit = 500;
    var moduleLimit = 500;
    
    const getPermissionList = () => {
        setIsLoading(true)
        PermissionManagementService.getPermissions().then((res)=>{

            if(res.data.success){
                setPermissions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const getPermissionsFunction = (page, moduleId) => {
        setIsLoading(true)
        PermissionManagementService.getPermissionsFunction(page, AllFunctionslimit, moduleId).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setFunctions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const getAllPermissionsFunction = (moduleId) => {
        setIsLoading(true)
        PermissionManagementService.getAllPermissionsFunction(AllFunctionslimit, moduleId).then((res)=>{
            
            if(res.data.success){
                setFunctions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const createPermission = (payload) => {
        setLoading(true)
        PermissionManagementService.createPermission(payload).then((res)=>{

            setLoading(false)

            if(res.data.success){
                setCreatedPermission(true)
                enqueueSnackbar('The permission was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setLoading(false)
            setCreatedPermission(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const updatePermission = (id, payload) => {
        setLoading(true)
        PermissionManagementService.updatePermission(id, payload).then((res)=>{

            setLoading(false)
            if(res.data.success){
                setUpdatedPermission(true);
                enqueueSnackbar('The permission was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setLoading(false)
            setUpdatedPermission(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const deletePermission = (id) =>{
        setDeletingPermission(true)
        PermissionManagementService.deletePermission(id).then((res)=>{

            setDeletingPermission(false)

            if(res.data.success){

                setDeletedPermission(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingPermission(false)
            setDeletedPermission(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const getModules = () => {
        setIsLoading(true)
        setSearchPermissionToggle(false);

        PermissionManagementService.getModules().then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setModules(res.data.data);
            }
           
        }).catch((err)=>{
            console.log("error here", err)
            setError(err.response.statusText)
            if (err.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (err.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                //nothing
            }
            setIsLoading(false)
        })
    };


    const createModule = (payload) => {
        setLoading(true)
        PermissionManagementService.createModule(payload).then((res)=>{

            setLoading(false)

            if(res.data.success){
                setCreatedModule(true)
                enqueueSnackbar('The module was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setLoading(false)
            setCreatedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
          
          })
    }


    const updateModule = (id, payload) => {
        setLoading(true)
        PermissionManagementService.updateModule(id, payload).then((res)=>{

            setLoading(false)
            if(res.data.success){
                setUpdatedModule(true)
                enqueueSnackbar('The module was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setLoading(false)
            setUpdatedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const deleteModule = (id) =>{
        setDeletingModule(true)
        PermissionManagementService.deleteModule(id).then((res)=>{
            console.log('delete module res', res)

            setDeletingModule(false)

            if(res.data.success){

                setDeletedModule(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingModule(false)
            setDeletedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const searchPermissions = (filter, value, page) => {
        setSearchingPermission(true);
        setSearchPermissionToggle(true);

        const search_permission_limit = 20;

        PermissionManagementService.searchPermissions(filter, value, page, search_permission_limit).then((res) => {
            console.log("searchPermissions data xxxx", res);
          
            if (res.data.success) {
                setSearchingPermission(false);
                setSearchedPermission(res.data.data);

                if (res.data.data.data.length === 0) {
                    setPagination(0);
                    setSearchedResultsEmpty(true);

                } else {
                    var last_page = Math.ceil(res.data.data.total_count / search_permission_limit); 
                    console.log("last_page", last_page);

                    setPagination(last_page);
                    setSearchedResultsEmpty(false);
                }
            } 
    
        }).catch((error) => { 
            console.log("searchPermissions error xxxx", error);

            setPagination(0);
            setSearchPermissionToggle(false);
            setSearchedResultsEmpty(false);
        })
    }


    const getAdminPermissionList = () => {
        setIsLoading(true)
        PermissionManagementService.getAdminPermissions().then((res)=>{

            if(res.data.success){
                setPermissions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const getAdminPermissionsFunction = (page, moduleId) => {
        setIsLoading(true)
        PermissionManagementService.getAdminPermissionsFunction(page, AllFunctionslimit, moduleId).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setFunctions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const getAllAdminPermissionsFunction = (moduleId) => {
        setIsLoading(true)
        PermissionManagementService.getAllAdminPermissionsFunction(AllFunctionslimit, moduleId).then((res)=>{
            
            if(res.data.success){
                setFunctions(res.data.data);
            }
           
        }).catch(()=>{
            setIsLoading(false)
        })
    };

    const createAdminPermission = (payload) => {
        setLoading(true)
        PermissionManagementService.createAdminPermission(payload).then((res)=>{

            setLoading(false)

            if(res.data.success){
                setCreatedPermission(true)
                enqueueSnackbar('The permission was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setLoading(false)
            setCreatedPermission(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const updateAdminPermission = (id, payload) => {
        setLoading(true)
        PermissionManagementService.updateAdminPermission(id, payload).then((res)=>{

            setLoading(false)
            if(res.data.success){
                setUpdatedPermission(true);
                enqueueSnackbar('The permission was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setLoading(false)
            setUpdatedPermission(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const deleteAdminPermission = (id) =>{
        setDeletingPermission(true)
        PermissionManagementService.deleteAdminPermission(id).then((res)=>{

            setDeletingPermission(false)

            if(res.data.success){

                setDeletedPermission(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingPermission(false)
            setDeletedPermission(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const getAdminModules = (page) => {
        setIsLoading(true)
        setSearchPermissionToggle(false);

        PermissionManagementService.getAdminModules(page,moduleLimit).then((res)=>{

            setIsLoading(false)

            if(res.data.success){
                setModules(res.data.data);
            }
           
        }).catch((err)=>{
            setIsLoading(false)
            setError(err.response.statusText);
        })
    };


    const createAdminModule = (payload) => {
        setLoading(true)
        PermissionManagementService.createAdminModule(payload).then((res)=>{

            setLoading(false)

            if(res.data.success){
                setCreatedModule(true)
                enqueueSnackbar('The module was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setLoading(false)
            setCreatedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
          
          })
    }


    const updateAdminModule = (id, payload) => {
        setLoading(true)
        PermissionManagementService.updateAdminModule(id, payload).then((res)=>{

            setLoading(false)
            if(res.data.success){
                setUpdatedModule(true)
                enqueueSnackbar('The module was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setLoading(false)
            setUpdatedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }

    const deleteAdminModule = (id) =>{
        setDeletingModule(true)
        PermissionManagementService.deleteAdminModule(id).then((res)=>{

            setDeletingModule(false)

            if(res.data.success){

                setDeletedModule(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingModule(false)
            setDeletedModule(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    return{ isLoading, loading, getPermissionList, permissions, getPermissionsFunction, functions, getModules, modules, 
        createPermission, createdPermission, updatePermission, updatedPermission, createModule, createdModule, 
        deletePermission, deletingPermission, deletedPermission, updateModule, updatedModule, deleteModule, 
        deletedModule, deletingModule, getAllPermissionsFunction,
        pagination, searchPermissions, isSearchingPermission, searchedPermission, isSearchPermissionToggle, isSearchedResultsEmpty,
        getAdminPermissionsFunction, getAllAdminPermissionsFunction, createAdminPermission,  updateAdminPermission,
        deleteAdminPermission, getAdminModules, createAdminModule, updateAdminModule, deleteAdminModule, error
    }
}

export default usePermissionManagement