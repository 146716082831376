import { useEffect, useState } from "react";
import { useDrop, useDrag } from 'react-dnd'
import { ptToPx, pxToPt } from "../../utils/UnitConverterUtil";
import { isOutsideParentBoundingBoxV2 } from "../../utils/BoundingBoxUtil";


/**
 * useDndHook params
 * @param {Ref} ref // Required. Element where to drop drag sources
 * @param {String} accept // Required. A string, a symbol, or an array of either. This drop target will only react to the items produced by the drag sources of the specified type or types.
*/

export const useDropHook = (ref, accept, page_number) => {

    const [dropResult, setDropResult] = useState(null)
    const [hasDropped, setHasDropped] = useState(false)
    const [parentRef, setParentRef] = useState(null)

    const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
        accept: accept,
        drop: (item, monitor) => {
            const initialPosition = monitor.getInitialSourceClientOffset();
            let afterDropPosition = monitor.getSourceClientOffset();

            console.log("initialPosition aaa", initialPosition);
            console.log("afterDropPosition aaa", afterDropPosition);
            console.log("parentRef aaa", parentRef);
            console.log("item aaa111", item);

            const elementToolbarRef = item.elementToolbarRef;
            if (elementToolbarRef) {
                if (elementToolbarRef.current) {
                    console.log("elementToolbarRef aaa", elementToolbarRef);
                    const elementToolbarBoundingBox = elementToolbarRef.current.getBoundingClientRect();
                    afterDropPosition = {x: monitor.getSourceClientOffset().x, y: monitor.getSourceClientOffset().y + elementToolbarBoundingBox.height + 5}; // where 5 is the margin top of element toolbar
                }
            }

            delete item.elementToolbarRef;

            let coords = getCoords(parentRef, initialPosition, afterDropPosition, item.w, item.h);
            console.log("coords bnnn", coords);
            let element = {...item, initialPosition, afterDropPosition, x: coords.x, y: coords.y, page_no: page_number}

            setDropResult(element)

            // setDropResult({...item, initialPosition, afterDropPosition})
        },
        // canDrop: (item, monitor) => {
        //     const parentRect = parentRef.current.getBoundingClientRect();
        //     let afterDropPosition = monitor.getSourceClientOffset();
        //     console.log("afterDropPosition 111", afterDropPosition);

        //     console.log("isOutsideParentBoundingBoxV2 aaa", isOutsideParentBoundingBoxV2(parentRect, afterDropPosition.x, afterDropPosition.y, 100, 100));
        //     return true;
        // },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [parentRef])

    const dndRef = dropRef(ref)

    useEffect(() => {
        if (dndRef != null) {
            setParentRef(dndRef)
        }
    }, [dndRef])

    useEffect(() => {
        setHasDropped(dropResult ? true : false)
    }, [JSON.stringify(dropResult)])

    const getCoords = (parentRef, initialPosition, afterDropPosition, width, height) => {
        let coords;   
        const within_parent_box_coords = getWithinParentBoundingBoxCoords(parentRef, width, height, afterDropPosition)
        coords = getOnDropCoords(parentRef, initialPosition, within_parent_box_coords)
        return coords;
    }

    const getWithinParentBoundingBoxCoords = (parentRef, width, height, afterDropPosition) => {
        let elementX = afterDropPosition.x
        let elementY = afterDropPosition.y
        let elementWidth = width
        let elementHeight = height

        const parentRect = parentRef.current.getBoundingClientRect();

        let parentX = parentRect.left
        let parentY = parentRect.top;
        let parentWidth = parentRect.width;
        let parentHeight = parentRect.height;

        // let parentX = 415;
        // let parentY = 135;
        // let parentWidth = 612;
        // let parentHeight = 792;

        console.log("parentX aaa", parentX);
        console.log("parentY aaa", parentY);
        console.log("parentWidth aaa", parentWidth);
        console.log("parentHeight aaa", parentHeight);

        const relativeX = (elementX - parentX);
        const relativeY = (elementY - parentY);

        if (relativeX < 0) {
            elementX = parentX;
        } else if (relativeX > parentWidth - elementWidth) {
            elementX = parentX + parentWidth - elementWidth;
        }
        
        if (relativeY < 0) {
            elementY = parentY
        } else if (relativeY > parentHeight - elementHeight) {
            elementY = parentY + parentHeight - elementHeight;
        }

        return {x: elementX, y: elementY}
    }

    const getOnDropCoords = (parentRef, initialPosition, finalPosition) => {
        const dropTargetPosition = parentRef.current.getBoundingClientRect();
        let parentX = 415;
        let parentY = 135;

        let finalX = finalPosition?.x
        let finalY = finalPosition?.y

        let initialX = initialPosition?.x
        let initialY = initialPosition?.y
    
        const newYposition =
        finalY > initialY
            ? initialY + (finalY - initialY) - dropTargetPosition.top
            : initialY - (initialY - finalY) - dropTargetPosition.top

            // finalY > initialY
            // ? initialY + (finalY - initialY) - parentY
            // : initialY - (initialY - finalY) - parentY
    
        const newXposition =
        finalX > initialX
            ? initialX + (finalX - initialX) - dropTargetPosition.left
            : initialX - (initialX - finalX) - dropTargetPosition.left

            // finalX > initialX
            // ? initialX + (finalX - initialX) - parentX
            // : initialX - (initialX - finalX) - parentX
    
        return {x: newXposition, y: newYposition};
    };

    return {dndRef, hasDropped, dropResult, getCoords}
}