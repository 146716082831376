import { Box, Card } from "@mui/material";
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Cell, Toolip, LabelList } from 'recharts';
import { FunctionComponent } from "react";
import useWidth from "../../hooks/useWidth";


function reformatString(inputString) {
  // Remove square brackets from the input string
  const cleanedString = inputString.replace(/[[\]]/g, '');

  // Convert the cleaned string to uppercase and split into an array
  const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());

  // Filter out "ENCRYPTION" from the array
  const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');

  if (filteredArray.length === 1) {
    // If there is only one element, return it as is
    return filteredArray[0];
  } else if (filteredArray.length > 1) {
    // If there are multiple elements, join them with commas and "and"
    const lastIndex = filteredArray.length - 1;
    return (
      filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
    );
  } else {
    // If no elements are left after filtering, return an empty string
    return '';
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const payloadTotal = payload.reduce((total, data) => total + data.value, 0);
    return (
      <div style={{ background: '#fff', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', height: 'auto', overflowY: 'auto', fontSize: 12 }}>
        {label}
        <br />
        {payload.map((data, index) => (
          <p key={index} style={{ color: data?.color }}>
            {`${data.name}: ${data.value}`}
          </p>
        ))}
        <b>Total: {payloadTotal}</b>
      </div>
    );
  }

  return null;
};


const CustomizedAxisTick: FunctionComponent = (props) => {
  const { x, y, payload } = props;

  const breakpoints = useWidth();

  let fontSize = 11; // Default font size

  // Adjust font size based on label length and screen width
  if (payload.value.length > 10) {
    fontSize = breakpoints === 'xs' ? 8 : 10; // Adjust the font size for different breakpoints
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-15)" style={{ fontSize }}>
        {payload.value}
      </text>
    </g>
  );
};

function ProjectPerPipelineComponent(props) {

  const breakpoints = useWidth();

  const { data, names } = props;

  const formattedData = data.map(item => ({
    ...item,
    pipeline: reformatString(item.pipeline),
  }));


  function random_color() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    return color;
  }



  return (
    <Box sx={{ p: 2, margin: 'auto', borderRadius: 2, bgcolor:'white', width:'99%'}}>
      <h3 className="section-title">Authentication Per Pipeline</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={formattedData} margin={{ top: 20, right: 20, left: 20, bottom: 5 }} style={{ margin: 'auto' }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="pipeline" height={100} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {/* <Tooltip /> */}
          {
            names?.map((name) => {
              return (
                <Bar dataKey={name} stackId="a" fill={random_color()} />
              )
            }
            )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )

}

export default ProjectPerPipelineComponent