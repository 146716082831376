import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';


const CreateModuleDialog = (props) => {

    const { openCreateModule, handleClose, permissionId, isView } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;


    const { loading, createModule, createAdminModule, createdModule } = usePermissionManagement();

    const [moduleName, setModuleName] = useState('');
    const [page, setPage] = useState(1);



    const handleTextFieldChange = (e) => {

        setModuleName(e.target.value);
     
    }


    const handleCreateModule = () => {

        let payload = {
            module_name: moduleName 
        }

        if(url === "/admin-settings") {
            createAdminModule(payload);
        } else {
            createModule(payload);
        }

        
        
    }

    useEffect(() => {

        if(createdModule){
            handleClose()
            // window.location.reload();
            if (url === "/admin-settings") {
                console.log("url matches '/admin-settings'");
                
                if (isView) {
                    console.log("isView is true");
                    props.getAdminPermissionsFunction(page, permissionId);
                } else {
                    console.log("isView is false");
                    props.getAdminModules(page);
                }
            } else {
                console.log("url doesn't match '/admin-settings'");
                if (isView) {
                    console.log("isView is true");
                    props.getPermissionsFunction(page, permissionId);
                } else {
                    console.log("isView is false");
                    props.getModules();
                }
               

              
            }
        }

    },[createdModule, permissionId, page, isView, url])

    

    return (
        <Dialog open={openCreateModule} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Create Module
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Module Name :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={moduleName}
                            onChange={(e) => handleTextFieldChange(e)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleCreateModule() }
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Creating" : "Create"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateModuleDialog