import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import ForbiddenImage from "../images/forbidden.png";
import AuthService from '../services/auth';


const returnHome = () => { 
    AuthService.logout().then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    });
 }


const ForbiddenScreen = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', width: '100%', overflow: 'hidden'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                    <Typography variant="h3" component="div" textAlign="center" mb={2}>
                        You are not authorized.
                    </Typography>
                    <Typography variant="body1" component="div" textAlign="center" fontSize={18}>
                        You tried to access a page you did not have prior authorization for.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                    <img alt="" src={ForbiddenImage} style={{ height: 500 }} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ForbiddenScreen;
