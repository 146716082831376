import React, {useState} from "react";
import {Menu, MenuItem, Typography} from '@mui/material';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AuthService from "../services/auth";
import {useNavigate} from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddAdminDialogComponent from "././dialog/add-admin-dialog.component";
import CreateIcon from "../icons/tab.png"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


const AccountPopperComponent = (props) => {

  const {anchorEl, handleCloseMenu} = props;

  const navigate = useNavigate();

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleSignOut = () => {
    AuthService.logout().then((res) => {
      window.location.href = "/"
    });

    handleCloseMenu();
  }

  const handleProfile = () => {
    navigate('/view-profile');
    handleCloseMenu();
  }

  const handleOpenAddAdminDialog = () =>{
    setOpenAddDialog(true);
    handleCloseMenu()
  }

  const handleCloseAddDialog = () =>{
    setOpenAddDialog(false);
  }


  return (
    <Menu sx={{top: "35px"}}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleProfile} sx={{paddingRight: "25px"}}>
        <PersonOutlineOutlinedIcon sx={{color: "#0178f2"}} />

        <Typography sx={{ml: "5px", fontSize: 14}}>
          Profile
        </Typography>
      </MenuItem>

      {/* <MenuItem onClick={handleOpenAddAdminDialog} sx={{paddingRight: "25px"}}>
        <AddOutlinedIcon  sx={{color: "#0178f2"}} />

        <Typography sx={{ml: "5px", fontSize: 14}}> 
          Create Admin
        </Typography>
      </MenuItem> */}

      <MenuItem onClick={() => window.location.href = '/sign-in-logs'} sx={{paddingRight: "25px"}}>
        <ArticleOutlinedIcon sx={{color: "#0178f2"}} />
        <Typography sx={{ml: "5px",  fontSize: 14}}> 
          Sign in Logs
        </Typography>
      </MenuItem>

      <MenuItem onClick={() => window.location.href = '/admin-settings'} sx={{paddingRight: "25px"}}>
        <SettingsOutlinedIcon sx={{color: "#0178f2"}} />
        <Typography sx={{ml: "5px",  fontSize: 14}}> 
          Admin Settings
        </Typography>
      </MenuItem>

      <MenuItem onClick={handleSignOut} sx={{paddingRight: "25px"}}>
        <ExitToAppOutlinedIcon sx={{color: "#0178f2"}} />

        <Typography sx={{ml: "5px", fontSize: 14}}> 
          Sign Out
        </Typography>
      </MenuItem>
 
      {openAddDialog && <AddAdminDialogComponent openAddDialog={openAddDialog} handleCloseAddDialog={handleCloseAddDialog} />}
    </Menu>
  )
}

export default AccountPopperComponent