import {Avatar, Card, CardContent, Grid, IconButton, InputAdornment, TextField, ButtonGroup, Button, Typography, Divider, Tooltip, CardHeader, Box} from "@mui/material";
import {useState, useEffect} from "react";
import AuthenticationDetailsComponent from "./authentication-details.component";
import AuthenticationErrorComponent from "./authentication-error.component";
import useAnalytics from "../../hooks/useAnalytics";
import AuthenticationSkeletonComponent from "../skeletons/authentication-skeleton.component";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AuthenticationComponent = (props) => {

    const { reference, projectId, project_name } = props;

    const { loading, retrieveReferenceDetails, reference_details } = useAnalytics();
    const navigate = useNavigate();

    useEffect(() => {

        retrieveReferenceDetails(reference, projectId);

    },[])
   
    return(
        <Box sx={{width: '100%'}}>
            {loading ?
                <AuthenticationSkeletonComponent />
            :
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <AuthenticationDetailsComponent reference_details={reference_details} project_name={project_name} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AuthenticationErrorComponent reference_details={reference_details} />
                    </Grid>
                </Grid>
            }   
        </Box>
    )
    
}

export default AuthenticationComponent