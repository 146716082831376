import { useState } from "react";
import UserService from "../services/api/users";
import { useSnackbar } from "notistack";

const useUsers = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [created, setCreated] = useState(false);
  const [isCreatingUser, setCreatingUser] = useState(false);
  const [isRegeneratingSubKey, setRegeneratingSubKey] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);
  const [loadingUserList, setLoadingUserList] = useState(false);
  const [isUpdatingUserStatus, setUpdatingUserStatus] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [isUpdatingPrincipalName, setUpdatingPrincipalName] = useState(false);
  const [admin, setAdmin] = useState("");
  const [isSearchPrincipalName, setSearchPrincipalName] = useState(null);
  const [user_password, setUserPassword] = useState("");
  const [pagination, setPagination] = useState(0);
  const [error, setError] = useState(false);
  const [searchPagination, setSearchPagination] = useState(0);
  const [regenerated_key, setRegeneratedKey] = useState(false);

  const [isFetchingUser, setFetchingUser] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [deletingUser, setDeletingUser] = useState(false);
  const [deletedUser, setUserDeleted] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);

  const [all_list, setAllList] = useState(0);
  const [retrieved, setRetrieved] = useState(false);
  const [all_user_list, setAllUserList] = useState(0);
  const [retrieved_user, setRetrievedUser] = useState(false);
  const [searchUserByBusinessAndProjectData, setSearchUserByBusinessAndProjectData] = useState([]);
  const [paginationEmail, setPaginationEmail] = useState(0);


  const url = window.location.pathname;


  var limit = 10;
  var type = 2;
  var typeOne = 1;
  var typeThree = 3;
  var typeSix = 6;
  var typeFour = 4;
  var typeSeven = 7;
  var typeEight = 8;

  const getUsers = (page) => {
    setLoading(true);

    UserService.getUsers(page, limit).then(res => {
      console.log("res getUser here:", res)
      setLoading(false);

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      console.log("err here", err)
      setLoading(false);
      setPagination(0)
      setError(err.response.statusText)
    })
  }


  const addUser = (businessID, data) => {

    setCreatingUser(true);

    UserService.addUser(businessID, data).then(res => {

      console.log("res addUser here:", res)
      setCreatingUser(false);

      if (res.data.success) {
        setUserPassword(res.data.data.password);
        setCreated(true);
        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {
      enqueueSnackbar(err.response.data.error, { 
        variant: "error",
        autoHideDuration: 3000
      });
      setCreatingUser(false);
      console.log("error here:", err)

    })

  }


  const createUser = (businessID, data) => {

    setCreatingUser(true);

    UserService.createUser(businessID,data).then(res => {

      console.log("res createUser here:", res)
      setCreatingUser(false);

      if (res.data.success) {
        setUserPassword(res.data.data.password);
        setCreated(true);
        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {

      setCreatingUser(false);
      console.log("error here:", err)

    })

  }

  const regenerateSubscriptionKey = (id) => {
    setRegeneratingSubKey(true);

    UserService.regenerateSubscriptionKey(id).then((res) => {
      console.log("regenerateSubscriptionKey res xxxx", res);

      if (res.data.success) {
        setRegeneratingSubKey(false);
        // window.location.reload();
        setRegeneratedKey(true);

        enqueueSnackbar("Successfully regenerated subscription key", {
          variant: "success",
          autoHideDuration: 3000
        });
      }

    }).catch((error) => {
      setRegeneratingSubKey(false);
      setRegeneratedKey(false);
      console.log("regenerateSubscriptionKey error xxxx", error);

      enqueueSnackbar(error.response.statusText, {
        variant: 'error',
        autoHideDuration: 3000
      });
    })
  }

  const getUsersByBusinessAccountID = (businessId, page) => {
    setLoadingUserList(true);
    UserService.getUserByBusinessAccountID(businessId, page, limit, type).then(res => {
      console.log("getUserByBusinessAccountIDxxx", res)
      // setUserAccounts(res.data.data)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      setError(err.response.statusText);
      console.log("getUserByBusinessAccountIDxxx", err)

    })
  }

  const getUsersUnderEnterpriseProject = (businessId, projectId, page) => {
    setLoadingUserList(true);
    UserService.getUsersUnderEnterpriseProject(businessId, projectId, page, limit, typeThree).then(res => {
      console.log("getUsersUnderEnterpriseProject", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("getUsersUnderEnterpriseProject", err)

    })
  }

  const getUsersUnderEnterprise = (businessId, projectId) => {
    setLoadingUserList(true);
    UserService.getUsersEnterprise(businessId, projectId).then(res => {
      console.log("getUsersUnderEnterprise", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("getUsersUnderEnterprise", err)

    })
  }

  const searchUserByBusinessAndProject = (businessId, projectId, email) => {
    setLoadingUserList(true);
    UserService.getUsersUnderEnterpriseProject(businessId, projectId, null, null, 6, email).then(res => {
      console.log("searchUserByBusinessAndProject", res)
      if (res.data.success) {
        setSearchUserByBusinessAndProjectData(res.data.data);
        setLoadingUserList(false);
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("searchUserByBusinessAndProject", err)
    })
  }

  const updateUserStatus = (id, payload) => {
    setUpdatingUserStatus(true);

    UserService.updateUserStatus(id, payload).then(res => {
      console.log("updateUserStatus data xxxx", res);
      setUpdatingUserStatus(false);

      if (res.data.success) {
        setUpdated(true);
        enqueueSnackbar("Successfully updated user status", {
          variant: 'success',
          autoHideDuration: 3000
        });

        if (url == "/user-list" || url == "/view") {
          window.location.reload();
        }
      }

    }).catch((err) => {
      console.log("updateUserStatus error xxxx", err);
      setUpdatingUserStatus(false);
      setUpdated(false);
      enqueueSnackbar(err.response.statusText, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setError(true);
    })
  }

  const deleteUser = (id, payload) => {
    setDeletingUser(true);

    UserService.updateUserStatus(id, payload).then(res => {
      console.log("deleteUser data xxxx", res);
      setDeletingUser(false);

      if (res.data.success) {
        setUserDeleted(true);
        enqueueSnackbar("Successfully deleted user.", {
          variant: 'success',
          autoHideDuration: 3000
        });

        if (url == "/user-list" || url == "/view") {
          window.location.reload();
        }
      }

    }).catch((err) => {
      console.log("deleteUser error xxxx", err);
      setDeletingUser(false);
      setUserDeleted(false);
      enqueueSnackbar(err.response.statusText, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setError(true);
    })
  }

  const updatePrincipalName = (id, payload) => {

    setUpdatingPrincipalName(true)

    UserService.updatePrincipalName(id, payload).then(res => {
      if (res.data.success) {
        setUpdated(true)
        setUpdatingPrincipalName(false);

        enqueueSnackbar("Successfully updated user email", {
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((error) => {
      console.log("update principal name err:", error)
      enqueueSnackbar(error.response.data.error, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setUpdatingPrincipalName(false)
    })
  }

  const getAdmin = () => {
    setLoading(true);

    UserService.getAdmin().then(res => {
      console.log("res getAdmin here:", res)
      setLoading(false);

      if (res.data.success) {
        setAdmin(res.data.data);
      }

    }).catch((err) => {
      setLoading(false);
      setError(err.response.statusText);
    })
  }

  var limit = 10;

  const searchPrincipalNames = (principal_name, page) => {
    UserService.searchPrincipalName(principal_name, page, limit).then(res => {
      console.log("search for:", res)
      setSearchPrincipalName(res.data.data)
      if (res.data.data.length === 0) {
        setSearchPagination(0)
      } else {
        var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
        console.log("last_page", last_page);
        setSearchPagination(last_page)
      }
    }).catch((err) => {
    })
  }


  const getUserByID = (id) => {
    setFetchingUser(true);

    UserService.getUserByID(id).then((res) => {
      console.log("getUserByID data xxxx", res);

      if (res.data.success) {
        setUserDetails(res.data.data);
        setFetchingUser(false);
      }

    }).catch((error) => {
      console.log("getUserByID error xxxx", error);
      setFetchingUser(false);

      if (error.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (error.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: 3000
        });
      }
    })
  }

  const filterUserByStatus = (business_id, status, page) => {
    setLoadingUserList(true);
    UserService.filterUserByStatus(business_id, status, page, limit, type).then(res => {
      console.log("filterUserByStatusxx res here", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("filterUserByStatusxx err here", err)

    })
  }

  const enterpriseUserFilter = (businessId, projectId, role, status, page, type) => {
    setLoadingUserList(true);
    UserService.getEnterpriseUserFilter(businessId, projectId, role, status, page, type, limit).then(res => {
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);

    })
  }

  const filterUserByRole = (business_id, role, page) => {
    setLoadingUserList(true);
    UserService.filterUserByRole(business_id, role, page, limit, type).then(res => {
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);

    })
  }


  const filterUserByStatusAndRole = (businessId, status, role, page) => {
    setLoadingUserList(true);
    UserService.filterUserByStatusAndRole(businessId, status, role, page, limit, type).then(res => {
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);

    })
  }

  const filterByEmail = (email, businessId, page ) => {

    setLoadingUserList(true);
    
    UserService.filterByEmail(email, businessId, page, limit, 5).then((res) => {

      console.log("filterByEmail herexxxx", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
  

    }).catch((error) => {
        console.log("searchUserByEmail error xxxx", error);

        setLoadingUserList(false);
        setPagination(0);

        if (error.response.data.error_message) {
            enqueueSnackbar(error.response.data.error_message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.message) {
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        }

    })
}


  const filterProjectUserByStatus = (businessId, projectId, status, page) => {
    setLoadingUserList(true);
    UserService.filterProjectUserByStatus(businessId, projectId, status, page, limit, typeThree).then(res => {
      console.log("filterProjectUserByStatus res here", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("filterProjectUserByStatus err here", err)

    })
  }


  const filterProjectUserByRole = (businessId, projectId, role, page) => {
    setLoadingUserList(true);
    UserService.filterProjectUserByRole(businessId, projectId, role, page, limit, typeThree).then(res => {
      console.log("filterProjectUserByRole res here", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("filterProjectUserByRole err here", err)

    })
  }

  const filterUsersFilterByEmail = (businessId, projectId, email, page ) => {

    setLoadingUserList(true);
    
    UserService.filterUsersFilterByEmail(businessId, projectId, email, page, limit, 6).then((res) => {

      console.log("getUsersFilterByEmail herexxxx", res)
      if (res.data.success) {
        setUserAccounts(res.data.data);
        setLoadingUserList(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
  

    }).catch((error) => {
        console.log("searchUserByEmail error xxxx", error);

        setLoadingUserList(false);
        setPagination(0);

        if (error.response.data.error_message) {
            enqueueSnackbar(error.response.data.error_message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.message) {
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        }

    })
}


  const filterProjectUserByStatusAndRole = (businessId, projectId, status, role, page) => {
    setLoadingUserList(true);
    UserService.filterProjectUserByStatusAndRole(businessId, projectId, status, role, page, limit, typeThree).then(res => {
      console.log("filterProjectUserByStatusAndRole res here", res)

      if (res.data.success) {
        setLoadingUserList(false);
        setUserAccounts(res.data.data);
        
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoadingUserList(false);
      console.log("filterUserByRole err here", err)

    })
  }

  const getUsersFilterByStatus = (status, page) => {
    setLoading(true);

    UserService.getUsersFilterByStatus(status, page, limit, typeOne).then(res => {
      console.log("res getUsersFilterByStatus here:", res)
      setLoading(false);

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      setLoading(false);
      setPagination(0)
    })
  }

  const getUsersFilterByEmail = (email, page ) => {

    setLoading(true);
    
    UserService.getUsersFilterByEmail(email, page, limit, typeFour).then((res) => {

      console.log("res herexxxx", res)
      setLoading(false);

        if (res.data.success) {
        setSearchPrincipalName(res.data.data);
        if (res.data.data.length === 0) {
          setPaginationEmail(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPaginationEmail(last_page)
        }
      }
  

    }).catch((error) => {
        console.log("searchUserByEmail error xxxx", error);

        setLoading(false);
        setPagination(0);

        if (error.response.data.error_message) {
            enqueueSnackbar(error.response.data.error_message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.message) {
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.statusText) {
            enqueueSnackbar(error.response.statusText, {
                variant: "error",
                autoHideDuration: 3000
            });
        } else {
          enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: 3000
        });
        }

    })
}

  const getUsersFilterByStatusAndRole = (status, role, page) => {
    setLoading(true);

    UserService.getUsersFilterByStatusAndRole(status, role, page, limit, typeOne).then(res => {
      console.log("res filterUserByStatusAndRole here:", res)
      setLoading(false);

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      setLoading(false);
      setPagination(0)
    })
  }


  const getUsersFilterByRole = (role, page) => {
    setLoading(true);

    UserService.getUsersFilterByRole(role, page, limit, typeOne).then(res => {
      setLoading(false);

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      setLoading(false);
      setPagination(0)
    })
  }


  const getUsersFilterByEnterprise = (businessId, page) => {
    setLoading(true);

    UserService.getUsersFilterByEnterprise(businessId, page, limit, typeSeven).then(res => {
      setLoading(false);

      console.log("getUsersFilterByEnterprisexxx", res )

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      console.log("getUsersFilterByEnterprisexxx DC", err )
      setLoading(false);
      setPagination(0)
    })
  }


  const getUsersFilterByEnterpriseAndProject = (businessId, projectId, page) => {
    setLoading(true);
    setLoadingUserList(true);

    UserService.getUsersFilterByEnterpriseAndProject(businessId, projectId, page, limit, typeEight).then(res => {
      setLoading(false);
      setLoadingUserList(false);

      console.log("getUsersFilterByEnterpriseAndProject", res )

      if (res.data.success) {
        setUsers(res.data.data);
        setUserAccounts(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      console.log("getUsersFilterByEnterpriseAndProject DC", err )
      setLoading(false);
      setPagination(0)
      setLoadingUserList(false);
    })
  }

  const getAllAdminUsers = () => {
    setLoading(true);

    UserService.getAllAdminUsers(1000).then((res) => {

      setLoading(false)

      if (res.data.success) {
        setRetrievedUser(true);
        setUsers(res.data.data)
        setAllUserList(res.data.data.length);
      }

    }).catch(() => {
      setLoading(false)
    })
  };

  const getAdminUsers = (page) => {
    setLoading(true);

    UserService.getAdminUsers(page, limit).then(res => {
      setLoading(false);

      if (res.data.success) {
        setUsers(res.data.data);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(all_user_list / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((err) => {
      setLoading(false);
      setPagination(0)
      setError(err.response.statusText);
    })
  }


  const getAdminRoles = (page) => {
    setLoading(true)
    UserService.getAdminRoles(page, 100).then((res) => {

      setLoading(false)

      if (res.data.success) {
        setAdminRoles(res.data.data)
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil((all_list + 2) / 100);//set it depending on ui return - 18
          setPagination(last_page)
        }
      }

    }).catch(() => {
      setLoading(false)
    })
  };

  const getAllAdminRoles = () => {
    setLoading(true);

    UserService.getAllAdminRoles().then((res) => {

      setLoading(false)

      if (res.data.success) {
        setRetrieved(true);
        setAdminRoles(res.data.data)
        setAllList(res.data.data.length);
      }

    }).catch(() => {
      setLoading(false)
    })
  };

  const filterAdminUserByStatus = (status, page) => {
    setLoading(true);
    UserService.filterAdminUserByStatus(status, page, limit).then(res => {
      console.log("filterAdminUserByStatus res here", res)
      // setUserAccounts(res.data.data)
      if (res.data.success) {
        setUsers(res.data.data);
        setLoading(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoading(false);
      console.log("filterAdminUserByStatus err here", err)

    })
  }

  const filterAdminUserByRole = (role, page) => {
    setLoading(true);
    UserService.filterAdminUserByRole(role, page, limit).then(res => {
      console.log("filterAdminUserByRole res here", res)
      // setUserAccounts(res.data.data)
      if (res.data.success) {
        setUsers(res.data.data);
        setLoading(false);
        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }
    }).catch((err) => {
      setLoading(false);
      console.log("filterAdminUserByRole err here", err)

    })
  }

  const viewAdminUser = (id) => {
    setFetchingUser(true);

    UserService.viewAdminUser(id).then((res) => {
      console.log("viewAdminUser data xxxx", res);

      if (res.data.success) {
        setUserDetails(res.data.data);
        setFetchingUser(false);
      }

    }).catch((error) => {
      console.log("viewAdminUser error xxxx", error);
      setFetchingUser(false);

      if (error.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (error.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: 3000
        });
      }
    })
  }

  const updateAdminPrincipalName = (id, payload) => {

    setUpdatingPrincipalName(true)

    UserService.updateAdminPrincipalName(id, payload).then(res => {
      if (res.data.success) {
        setUpdated(true)
        setUpdatingPrincipalName(false);

        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((error) => {
      console.log("update principal name err:", error)
      enqueueSnackbar(error.response.data.error, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setUpdatingPrincipalName(false)
    })
  }


  const updateAdminUserStatus = (id, payload) => {
    setUpdatingUserStatus(true);

    UserService.updateAdminUserStatus(id, payload).then(res => {
      console.log("updateAdminUserStatus data xxxx", res);
      setUpdatingUserStatus(false);

      if (res.data.success) {
        setUpdated(true);
        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });

        if (url == "/admin-settings") {
          window.location.reload();
        }

        // if (url == "/user-list" || url == "/view") {
        //   window.location.reload();
        // }
      }

    }).catch((err) => {
      console.log("updateAdminUserStatus error xxxx", err);
      setUpdatingUserStatus(false);
      setUpdated(false);
      enqueueSnackbar(err.response.statusText, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setError(true);
    })
  }

  const deleteAdminUserStatus = (id, payload) => {
    setDeletingUser(true);

    UserService.updateAdminUserStatus(id, payload).then(res => {
      console.log("updateAdminUserStatus data xxxx", res);
      setDeletingUser(false);

      if (res.data.success) {
        setUpdated(true);
        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });

        if (url == "/admin-settings") {
          window.location.reload();
        }

      }

    }).catch((err) => {
      console.log("updateAdminUserStatus error xxxx", err);
      setDeletingUser(false);
      setUpdated(false);
      enqueueSnackbar(err.response.statusText, {
        variant: 'error',
        autoHideDuration: 3000
      });
      setError(true);
    })
  }


  const addAdminUser = (payload) => {

    setCreatingUser(true);

    UserService.addAdminUser(payload).then(res => {

      console.log("res addAdminUser here:", res)
      setCreatingUser(false);

      if (res.data.success) {
        setUserPassword(res.data.data.password);
        setCreated(true);
        enqueueSnackbar(res.data.message, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }

    }).catch((err) => {

      setCreatingUser(false);

      if (err.response.data.error) {
        enqueueSnackbar(err.response.data.error, {
          variant: "error",
          autoHideDuration: 3000
        });
        return
      }

      enqueueSnackbar(err.data.error, {
        variant: 'error',
        autoHideDuration: 3000
      });
      console.log("error here:", err)

    })

  }



  return {
    loading, users, pagination, getUsers, isCreatingUser, created, user_password, addUser, isRegeneratingSubKey,
    regenerateSubscriptionKey, getUsersByBusinessAccountID, userAccounts, isUpdatingUserStatus, updated,
    updateUserStatus, updatePrincipalName, isUpdatingPrincipalName, admin, getAdmin, loadingUserList,
    searchPrincipalNames, setUserAccounts, isSearchPrincipalName, error, setLoading, searchPagination,
    isFetchingUser, userDetails, getUserByID, regenerated_key, deleteUser, deletingUser, deletedUser, filterUserByStatus,
    filterUserByRole, getUsersUnderEnterpriseProject, filterProjectUserByStatus, filterProjectUserByRole,
    getUsersFilterByStatus, getUsersFilterByRole, getAdminUsers, getAdminRoles, adminRoles, filterAdminUserByStatus,
    filterAdminUserByRole, viewAdminUser, updateAdminPrincipalName, updateAdminUserStatus, addAdminUser, deleteAdminUserStatus,
    retrieved, getAllAdminRoles, getAllAdminUsers, retrieved_user, createUser, getUsersFilterByStatusAndRole, filterUserByStatusAndRole, 
    filterProjectUserByStatusAndRole, getUsersFilterByEmail, paginationEmail, filterUsersFilterByEmail, filterByEmail, 
    searchUserByBusinessAndProject, searchUserByBusinessAndProjectData, getUsersUnderEnterprise, getUsersFilterByEnterprise, getUsersFilterByEnterpriseAndProject, enterpriseUserFilter
  }

}

export default useUsers;