import { Button, Card, CardContent, CardHeader, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import {Visibility, VisibilityOff} from '@mui/icons-material';

import useProjects from "../../hooks/useProjects";
import ProjectDetailsSkeletonComponent from "../skeletons/project-details-skeleton.component";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function EmailAuthenticationProjectDetails (props){

    // hooks
    const {viewProjectDetails, projectDetails, isLoadingDetails} = useProjects()
    const [showApiKey, setApiKey] = useState(false);
    const navigate = useNavigate()
    const handleClickShow = (e) => {setApiKey(!showApiKey)}

    useEffect(()=>{
        let id = (new URLSearchParams(window.location.search)).get("id");
        viewProjectDetails(id)},[])

    const utcTime = new Date(projectDetails?.created_date);
    const options = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
    const localTime = utcTime.toLocaleString('en-US', options);

    const projectDetailsLabel = [ 
        {label : 'Name', value: projectDetails?.application_name},
        {label : 'Description', value: projectDetails?.description},
        {label : 'Last Date Modified', value: localTime},
        {label : 'API Key', 
        value: 
            <TextField
                fullWidth
                variant="outlined"
                autoComplete="off"
                value={projectDetails?.api_key}
                placeholder="API Key"
                type={(showApiKey) ? "text" : "password"}
                inputProps={{style: { fontSize: 14 }}}
                InputProps={{endAdornment: (
                    <InputAdornment position="end" style={{marginLeft: -29}}>
                        <IconButton onClick={(e) => handleClickShow(e)} aria-label="toggle password visibility" edge="end">
                            {(showApiKey) ? 
                                <Visibility fontSize="small" /> 
                            :
                                <VisibilityOff fontSize="small" />
                            } 
                        </IconButton>
                    </InputAdornment>
                )}} 
            />
        },
        {label : 'Verification Policy', value: <Typography sx={{color:'#0178f2'}}>{projectDetails?.privacy}</Typography>},
    ];

    return(
        <>
            <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "black", fontSize: 16, textTransform: 'none', fontWeight : 600  }}
                onClick={() => navigate(-1)} >
                Back
            </Button>
            
            <Card sx={{borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: 4}}>
                <CardContent>
                    <Grid container spacing={4} direction="row">
                        <Grid item xs={12} sm={12}>

                            { projectDetailsLabel.map((label)=>{
                            return(
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4} sx={{alignSelf:'center'}}>
                                        <Typography sx={{fontWeight:'bold', fontSize: 16, padding: "10px 0px 10px 0px"}}>
                                            {label.label}:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        {isLoadingDetails ?
                                            <ProjectDetailsSkeletonComponent/>
                                        :
                                            <Typography sx={{fontSize: 16, padding: "10px 0px 10px 0px"}}>
                                                {label.value}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            )})}
                            
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
        
    )
}
export default EmailAuthenticationProjectDetails