import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import {ThreeDots} from "react-loader-spinner";
import useAppConfig from "../../../hooks/useAppConfig";

function DeleteBusinessAppConfigDialog (props){

    const {openDeleteBusinessApp, handleClose, list} = props;

    const breakpoints = useWidth()
    const { deleted, deleteBusinessApp , isDeleting } = useAppConfig();


    const handleDeleteBusinessApp = () => {

        let businessId = list?.businessAccount?.id;
        let appName = list?.appName;

        deleteBusinessApp(businessId, appName);
    }

    useEffect(() => {
        if(deleted){
            handleClose();
            window.location.reload();
        }
    },[deleted])

    return(
        <Dialog open={openDeleteBusinessApp} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Delete Enterprise App
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    You are about to delete <strong>{list?.appName}</strong>&nbsp;<strong>{list?.businessAccount?.business_name}</strong>. Please confirm. 
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button  variant="contained" 
                    onClick={handleDeleteBusinessApp}
                    startIcon={isDeleting &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {isDeleting ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteBusinessAppConfigDialog