import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Stack, FormControl, Select, MenuItem, InputLabel, IconButton, TextField, InputAdornment } from "@mui/material";
import UsersEnterpriseSkeletonComponent from "../skeletons/users-enterprise-skeleton.component";
import UserRowComponent from "./user-row.component";
import useUsers from "../../hooks/useUsers";
import useRolesManagement from "../../hooks/useRolesManagement";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';
import ReactSelect from "react-select";
import useBusiness from "../../hooks/useBusiness";
import useProjects from "../../hooks/useProjects";
import CreateProjectUserDialog from "../project/dialog/create-project-user.dialog";
import AddBoxIcon from '@mui/icons-material/AddBox';



const statusOption = [
    { value: 'true', label: "Active" },
    { value: 'false', label: "Inactive" }
]



function TableHeader() {
    const tablecell = [
        { label: 'ID' },
        { label: 'Email' },
        { label: 'Role' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Action' },
    ];

    return (
        <TableHead>
            <TableRow sx={{ position: "sticky" }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: "#0178f2", color: "#fff" }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const UsersComponent = (props) => {

    const { breakpoints, projectId, project_name } = props;
    const navigate = useNavigate();

    let businessId = (new URLSearchParams(window.location.search)).get("id");

    // let businessId = parseInt(businessIdString, 10);

    const { loadingUserList, pagination, userAccounts, getUsersUnderEnterpriseProject, setLoadingUserList, filterUsersFilterByEmail, filterProjectUserByStatus, filterProjectUserByRole, filterProjectUserByStatusAndRole } = useUsers();
    const { rolesList, getAllRoles } = useRolesManagement();


    const [page, setPage] = useState(1);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_role, setFilterRole] = useState("");

    const [searchInput, setSearchInput] = useState('');
    const [isSelectedLabel, setIsSelectedLabel] = useState(null);
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);


    useEffect(() => { getAllRoles(); }, [])


    const searchForOptions = [
        { value: "status", label: "Status" },
        { value: "role", label: "Role" },
        // { value: "email", label: "Email" },
        { value: "status-role", label: "Status and Role" }
    ]

    const handleOpenAddDialog = (e) => {
        setOpenAddDialog(true);
    }

    const handleCloseAddDialog = (e) => {
        setOpenAddDialog(false);
    }

    const handleClearFilter = (e) => {
        setSearchStatusVal(null);
        setSearchFor(null);
        setSearchForLabel(null);
    }

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);

        } else {
            setSearchFor(null);
            setSearchForLabel(null);
            setSearchStatusVal(null);

            handleClearFilter();
        }
    }

    const searchItems = (e) => {
        setSearchInput(e.target.value);
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchInput) {
                filterUsersFilterByEmail(businessId, projectId, searchInput, page);
            } else {
                getUsersUnderEnterpriseProject(businessId, projectId, page);
            }
        }
    };


    useEffect(() => {
        if (businessId && projectId, filter_status && filter_role) {
            filterProjectUserByStatusAndRole(businessId, projectId, filter_status, filter_role, page);
        } else if (businessId && projectId, filter_status) {
            filterProjectUserByStatus(businessId, projectId, filter_status, page);
        } else if (businessId && projectId, filter_role) {
            filterProjectUserByRole(businessId, projectId, filter_role, page);
        } else {
            getUsersUnderEnterpriseProject(businessId, projectId, page);
        }
    }, [businessId, projectId, filter_status, filter_role, page]);


    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleChangeRole = (event) => {
        setFilterRole(event.target.value);
    }


    const handleChangePagination = (event, page) => {
        setPage(page);
        getUsersUnderEnterpriseProject(businessId, projectId, page);
        setLoadingUserList(true);
    }

    const handleStatusChangePagination = (event, page) => {
        setPage(page);
        filterProjectUserByStatus(businessId, projectId, filter_status, page);
        setLoadingUserList(true);
    }

    const handleRoleChangePagination = (event, page) => {
        setPage(page);
        filterProjectUserByRole(businessId, projectId, filter_role, page);
        setLoadingUserList(true);
    }


    const handleChangeRoleAndStatusPagination = (page) => {
        setPage(page);
        filterProjectUserByStatusAndRole(businessId, projectId, filter_status, filter_role, page);
        setLoadingUserList(true);
    }

    const handleChangeEmailPagination = (page) => {
        setPage(page);
        filterUsersFilterByEmail(businessId, projectId, searchInput, page);
        setLoadingUserList(true);
    }

    const reload = () => {
        setFilterRole("");
        setFilterStatus("");
        setSearchInput('');
        handleClearFilter();

        getUsersUnderEnterpriseProject(businessId, projectId, page);
    }





    return (
        <Box sx={{ width: "93.5%", margin: "auto", mt: 2, mb: 1.5 }}>

            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} sm={12} sx={{ mb: (breakpoints == 'xs') ? 2 : 0, display: 'flex' }}>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='h6'>
                                Users
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={10} sx={{justifyContent: 'flex-end'}}>
                            <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', justifyContent: 'flex-end' }}>
                                <Box sx={{justifyContent: 'flex-end'}}>
                                    <Stack direction="row" spacing={1} sx={{ width: '99%', marginRight: 'auto',  justifyContent: 'flex-end' }}>

                                        <ReactSelect
                                            className="basic-single"
                                            value={isSelectedLabel}
                                            placeholder={<>
                                                <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    Filter
                                                </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                    {
                                                        (searchForLabel === "Status") ?
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                            Status
                                                        </Typography>
                                                        :
                                                        (searchForLabel === "Role") ?
                                                            <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                                Role
                                                            </Typography>
                                                            :
                                                                (searchForLabel === "Status and Role") ?
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                                                        Status and Role
                                                                    </Typography>
                                                                    :
                                                                    (searchForLabel === null) ?
                                                                        "Select Filter"
                                                                        :
                                                                        null
                                                    }</i>
                                            </>}
                                            isSearchable={true}
                                            isRtl={false}
                                            isClearable={true}
                                            options={searchForOptions}
                                            onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 200,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                }),
                                            }}
                                        />

                                        {(searchForLabel === "Status" || searchForLabel === "Status and Role") &&
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    value={filter_status}
                                                    sx={{
                                                        width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                        '&.Mui-focused': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                    onChange={handleStatusChange}
                                                    inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                >
                                                    {statusOption?.map((status) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        }

                                        {(searchForLabel === "Role" || searchForLabel === "Status and Role") &&
                                            <FormControl >
                                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    value={filter_role}
                                                    sx={{
                                                        width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                        '&.Mui-focused': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                    onChange={handleChangeRole}
                                                    inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                >
                                                    {rolesList?.map((data, index) => (
                                                        <MenuItem key={data?.id} value={data?.role}>
                                                            {data?.role}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        }

                                            <TextField
                                                value={searchInput}
                                                sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                                inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                                placeholder="Search Email..."
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                                                }}
                                                variant="outlined"
                                                autoComplete="off"
                                                onChange={searchItems}
                                                onKeyDown={keyPress}
                                            />

                                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                                    </Stack>
                                </Box>
                                {/* <Button variant="contained" sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                                    onClick={(e) => handleOpenAddDialog(e)}
                                >
                                    Add User
                                </Button> */}
                                <Button
                                    variant="text"
                                    sx={{
                                        fontSize: 14,
                                        p: '4px 5px',
                                        borderRadius: 10,
                                        boxShadow: 'none',
                                        color: '#0178f2',
                                        textTransform: 'none',
                                    }}
                                    startIcon={<AddBoxIcon sx={{color: '#0178f2' }} />}
                                    onClick={(e) => handleOpenAddDialog(e)}
                                >
                                    Add User
                                </Button>
                            </Stack>

                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

            <br />

            <Grid item xs={12} sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : "0px 0px 24px 0px" }}>
                <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 2, width: "100%" }} className="card-list" >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader />

                        {(loadingUserList) ?
                            <TableBody>
                                <UsersEnterpriseSkeletonComponent breakpoints={breakpoints} />
                            </TableBody>
                            :
                            <TableBody>
                                <>
                                    {(userAccounts && userAccounts)?.map((content, index) => (
                                        <UserRowComponent
                                            breakpoints={breakpoints}
                                            index={index}
                                            user={content}
                                            projectId={projectId}
                                            businessId={businessId}
                                            getUsersUnderEnterpriseProject={getUsersUnderEnterpriseProject}
                                        />
                                    ))}
                                </>
                            </TableBody>
                        }
                    </Table>

                    {(!loadingUserList && userAccounts?.length == 0) &&
                        <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                            <Grid container sx={{ padding: 3 }}>
                                <Box sx={{ textAlign: "center", margin: "auto" }}>
                                    <Typography>
                                        No available user(s) to display yet.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    }

                    {!loadingUserList && userAccounts?.length !== 0 &&
                        <>
                            {(filter_status) ?
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleStatusChangePagination}
                                    style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                />
                                :
                                (filter_role) ?

                                    <Pagination
                                        count={pagination}
                                        page={page}
                                        onChange={handleRoleChangePagination}
                                        style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                    />

                                    : (filter_role && filter_status) ?

                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangeRoleAndStatusPagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />

                                        : (searchInput) ?

                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangeEmailPagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />

                                        :

                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangePagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />
                            }
                        </>
                    }
                </TableContainer>
            </Grid>
            {openAddDialog &&
                <CreateProjectUserDialog 
                    openAddDialog={openAddDialog}
                    handleCloseAddDialog={handleCloseAddDialog}
                    projectId={projectId}
                    page={page}
                    getUsersUnderEnterpriseProject={getUsersUnderEnterpriseProject}

                />
            }
        </Box>
    )
}

export default UsersComponent