import { Avatar, Box, Button, ButtonGroup, FormGroup, Grid, IconButton, Link, Pagination, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import EmailAuthenticationSkeletonComponent from "../skeletons/email-authentication-skeleton.component";
import EmailSenderPopperComponent from "../enterprise/email-sender-popper.component";
import { useNavigate } from "react-router-dom";

function TableHeader(){

    const tablecell = [ 
        {label : 'ID'},
        {label : 'Enterprise Name'},
        {label : 'Project Name'},
        {label : 'Email Address'},
        {label : 'Status'},
        {label : 'Date Requested'},
        {label : 'Action'}
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#0178f2'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function EmailAuthenticationListComponent(props) {

    const {breakpoints, authenticationList, isLoading, isSearchEmail, searchPagination, searchInput, handleChangePagination, page, pagination, handleChangeSearchEmailPagination, searchKeyPress} = props;

    const navigate = useNavigate();

    const statusMap = {
        PA: 'Pending for Approval',
        A: 'Approved',
        DA: 'Disapproved',
        R: 'Revoked',
      };

      authenticationList?.forEach(item => {
        item.status = statusMap[item.status_code];
      });

      console.log('get authentication status', authenticationList)

    return(
        <Grid item xs={12}>
            <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%"}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader/>

                    {isLoading ? 
                    <EmailAuthenticationSkeletonComponent/>
                    :
                    <TableBody>
                        
                        {searchKeyPress ? (
                            isSearchEmail?.map((authentication, index) => {
                                return (
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, cursor:'pointer'}} onClick={()=> window.location.href = "/authentication-details?id=" + authentication.id}>
                                        <TableCell component="th" scope="row" sx={{color: '#0178f2'}}>
                                            {authentication.id}
                                        </TableCell>

                                        <TableCell sx={{color:'#0178f2'}} onClick={(e) => navigate('/view?id=' + authentication.businessAccount.id)} >
                                            {authentication.businessAccount.business_name}
                                        </TableCell>

                                        <TableCell>
                                            {authentication.accountProject.application_name}
                                        </TableCell>

                                        <TableCell sx={{color:'#0178f2'}}>
                                            {authentication.email}
                                        </TableCell>

                                        <TableCell sx={{fontWeight: 600, color: authentication.status_code == "PA" ? 'orange' : authentication.status_code == "A" ? 'green' : 'red'}}>
                                            {authentication.status_code === "PA" ? "Pending for Approval" : authentication.status_code == "A" ? "Approved" : authentication.status_code == "DA" ? "Disapproved": authentication.status_code == "R" ? "Revoked" : null}
                                        </TableCell>

                                        <TableCell>
                                            {format(new Date(authentication.datetime_requested), 'PPpp')}
                                        </TableCell>

                                        <TableCell align="left">
                                            <EmailSenderPopperComponent 
                                                authentication={authentication}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <>
                                {(authenticationList && authenticationList)?.map((authentication, index) => (
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}, cursor:'pointer'}} >
                                        {console.log('ssssssssss', authentication)}
                                        <TableCell component="th" scope="row" sx={{color: '#0178f2'}} onClick={()=> window.location.href = "/authentication-details?id=" + authentication.id}>
                                            {authentication.id}
                                        </TableCell>

                                        <TableCell sx={{color: '#0178f2'}} onClick={(e) => navigate('/view?id=' + authentication.businessAccount.id)} >
                                            {authentication.businessAccount.business_name}
                                        </TableCell>

                                        <TableCell sx={{color: '#0178f2'}} onClick={(e) => navigate('/project-details?id=' + authentication.accountProject.id)} >
                                            {authentication.accountProject.application_name}
                                        </TableCell>

                                        <TableCell sx={{color:'#0178f2'}} onClick={()=> window.location.href = "/authentication-details?id=" + authentication.id} >
                                            {authentication.email}
                                        </TableCell>

                                        <TableCell sx={{fontWeight: 600, color: authentication.status_code == "PA" ? 'orange' : authentication.status_code == "A" ? 'green' : 'red'}}>
                                            {authentication.status_code === "PA" ? "PENDING FOR APPROVAL" : authentication.status_code == "A" ? "APPROVED" : authentication.status_code == "DA" ? "DISAPPROVED": authentication.status_code == "R" ? "REVOKED" : null}
                                        </TableCell>

                                        <TableCell>
                                            {format(new Date(authentication.datetime_requested), 'PPpp')}
                                        </TableCell>

                                        <TableCell align="left">
                                            <EmailSenderPopperComponent 
                                                authentication={authentication}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                        
                    </TableBody> 
                    }
                </Table>
                    {!isLoading && authenticationList?.length == 0 &&
                        <Typography sx={{fontSize: 14, color:'gray', padding: 4, textAlign:'center'}}>
                            There is no configured email address.
                        </Typography>
                    }

                {isSearchEmail?.length === 0 && searchInput &&
                    <Box sx={{textAlign:'center', padding: 10}}>
                        No results found.
                    </Box>
                }

                <Pagination
                    count={pagination}
                    page={page}
                    onChange={ isSearchEmail ? 
                        handleChangeSearchEmailPagination
                        :
                        handleChangePagination}
                    disabled={authenticationList?.length === 0 || searchKeyPress?.length === 0}
                    hideNextButton={authenticationList?.length < 10 || searchKeyPress?.length < 10}
                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                />
            </TableContainer>
        </Grid>
    )
}

export default EmailAuthenticationListComponent;