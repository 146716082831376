import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from "react-loader-spinner";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import usePackageManagement from "../../../hooks/usePackageManagement";

const DeletePackageDialog = (props) => {

    const { openDeletePackage, handleClose, plan, setDeleted } = props;

    const breakpoints = useWidth();

    const { deletePackage, deletingPackage, deletedPackage } = usePackageManagement();


    const handleDeletPackage = () => {

        deletePackage(plan?.id);

    }


    useEffect(() => {

        if(deletedPackage){
            handleClose()
            setDeleted(true)
        }

    },[deletedPackage])



    return (
        <Dialog open={openDeletePackage} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                Delete Package
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                    Are you sure you want to delete <strong>{plan?.package_name}</strong> Package ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={() => handleDeletPackage() }
                    startIcon={deletingPackage &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {deletingPackage ? 'Deleting' :  'Delete'} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeletePackageDialog