import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import ReactSelect from 'react-select';
import { useEffect } from "react";
import { useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import BusinessAppConfigService from "../../../services/api/business-app-config";
import useAppConfig from "../../../hooks/useAppConfig";
import {ThreeDots} from 'react-loader-spinner';
import { useSnackbar } from "notistack";

const UpdateSecretConfigDialog = (props) => {

    const {openUpdateConfig, handleClose, list, page} = props

    const breakpoints = useWidth();

    const [config_key, setConfigKey] = useState(list?.config_key)
    const [config_value, setConfigValue] = useState(list?.config_value)
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    const handleTextFieldChange = (e, name) => {
        if (name == "config_key") {
            setConfigKey(e.target.value);

        } else if (name == "config_value") {
            setConfigValue(e.target.value);
        }
    }

    const handleCreateConfig = () => {
        let payload = {
            key: config_key,
            value: config_value,
        }
        setLoading(true)
        BusinessAppConfigService.createConfig(payload).then((res)=>{
            setLoading(false)
            if(res.data.success){
                enqueueSnackbar('Successfully created secrets', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
                props.getListConfig(page);
            }
        }).catch((error)=>{
            setLoading(false)
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
              });
        })
    }

    return(
        <Dialog open={openUpdateConfig} onClose={handleClose} maxWidth={'md'} fullWidth={'md'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Edit Secrets
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                        <Typography sx={{fontWeight:'bold'}}>Config Key:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField 
                            fullWidth 
                            variant="outlined"
                            autoComplete="off" 
                            placeholder="Config Key"
                            value={config_key}
                            onChange={(e) => handleTextFieldChange(e, "config_key")}  
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                        <Typography sx={{fontWeight:'bold'}}>Config Value:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField 
                            fullWidth 
                            variant="outlined"
                            autoComplete="off" 
                            placeholder="Config Value"
                            value={config_value}
                            onChange={(e) => handleTextFieldChange(e, "config_value")}  
                        />
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions onClick={handleClose} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} >
                    Cancel
                </Button>

                <Button onClick={handleCreateConfig} variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UpdateSecretConfigDialog