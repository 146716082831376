//// LOCAL ////

// const website_URL = "http://localhost:4502"

// const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3001&scope=openid&response_type=id_token&prompt=login"

//// DEVELOPMENT INSTANCE ////
// const website_URL = "https://thankful-mud-0b8027d1e.2.azurestaticapps.net"
// const website_URL = "https://adminv2.ovcode.com"
// const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fthankful-mud-0b8027d1e.2.azurestaticapps.net&scope=openid&response_type=id_token&prompt=login"
// const msal_authConfig = {
//     clientId:'54d92333-55f6-45a8-b93a-3e1c0f8d4d34',
//     knownAuthorities: ["msovcode.b2clogin.com"],
//     authority:'https://msovcode.b2clogin.com/tfp/msovcode.onmicrosoft.com/B2C_1_ovcode_admin',
//     postLogoutRedirectUri: website_URL,
// }
// const b2c_scope = {
//     scopes:[
//         "https://msovcode.onmicrosoft.com/54d92333-55f6-45a8-b93a-3e1c0f8d4d34/Admin"
//     ]
// }
// const baseURL = "https://cybergem.azure-api.net"
// const subscriptionKey = "f3ee95c3f78e487abec52c745f991a48"

//// PRODUCTION INSTANCE ////
const website_URL = "https://management.ovcode.com"
const changePassword_URL = "https://ovcodeprod.b2clogin.com/ovcodeprod.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=bbc60678-118f-470d-bbf6-0e343fe9b6c9&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms%2F&scope=openid&response_type=id_token&prompt=login"
const msal_authConfig = {
    clientId:'bbc60678-118f-470d-bbf6-0e343fe9b6c9',
    knownAuthorities: ["ovcodeprod.b2clogin.com"],
    authority:'https://ovcodeprod.b2clogin.com/tfp/ovcodeprod.onmicrosoft.com/B2C_1_ovcode_admin',
    postLogoutRedirectUri: website_URL,
}
const b2c_scope = {
    scopes:[
        "https://ovcodeprod.onmicrosoft.com/bbc60678-118f-470d-bbf6-0e343fe9b6c9/Admin"
    ]
}
const baseURL = "https://ovcodeprodapim.azure-api.net"
const subscriptionKey = "4f9858aa7925466f823bb3b45f92982d"

export default {

    business_account_service : {
        url: baseURL ,
        subscriptionKey : subscriptionKey,
    },

    msal_url : {
        url: website_URL,
    },

    msal_change_password_url : {
        url: changePassword_URL,
    },

    msal_authConfig:{
        clientId: msal_authConfig.clientId,
        knownAuthorities: msal_authConfig.knownAuthorities,
        authority: msal_authConfig.authority,
        postLogoutRedirectUri: msal_authConfig.postLogoutRedirectUri,
    },
    b2c_scope:{
        scopes: b2c_scope.scopes
    }
};