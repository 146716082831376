const timeAgo = (date) => {
    
    var ms = (new Date()).getTime() - date.getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);

    if (ms === 0) {
        return 'Just now';

    } if (seconds < 5) {
        return 'Just now';

    } if (seconds < 60) {
        return seconds + ` second${seconds !== 1 ? "s" : ""} ago`;

    } if (minutes < 60) {
        return minutes +  ` minute${minutes !== 1 ? "s" : ""} ago`;

    } if (hours < 24) {
        return hours +  ` hour${hours !== 1 ? "s" : ""} ago`;

    } if (days < 30) {
        return days +  ` day${days !== 1 ? "s" : ""} ago`;

    } if (months < 12) {
        return months +  ` month${months !== 1 ? "s" : ""} ago`;

    } else {
        return years +  ` year${years !== 1 ? "s" : ""} ago`;
    }
}

const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]
  
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
  
    return formattedDate;
}

export {
    timeAgo, formatDate
}