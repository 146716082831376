import routes from "../common/api-routes";
import environment from "../../../environment/environment";
import { HttpDelete1, HttpGet, HttpPost, HttpPostMultipart, HttpPut1, HttpDownloadFile } from "../common/HttpRequestBuilder";


const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getFiles = (page, limit, date_from, date_to, business_id) => {

    let query_params = {
        page: page ,
        limit: limit,
        date_from: date_from,
        date_to: date_to,
        business_id: business_id
    }

    console.log("get files param", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_FILES, query_params, subscriptionKey);
    
    return result;
}

const getFilesByProjectId = (projectId, page, date_from, date_to, businessId) => {

    let query_params = {
        projectId: projectId,
        page: page ,
        date_from: date_from,
        date_to: date_to,
        businessId: businessId
    }

    console.log("get files by project param", query_params)


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_FILES_BY_PROJECT_ID, query_params, subscriptionKey);
    
    return result;
}

const downloadFile = (reference, businessId) => {

    let query_params = {
        reference: reference,
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpDownloadFile(routes.DOWNLOAD_FILE, query_params, subscriptionKey);
    
    return result;
}

const viewFile = (reference) => {

    let query_params = {
        reference: reference,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.DOWNLOAD_FILE, query_params, subscriptionKey);
    
    return result;
}

const getFilesByDateRange = (page, limit, date_from, date_to, business_id) => {

    let query_params = {
        page: page ,
        limit: limit,
        date_from: date_from,
        date_to: date_to,
        business_id: business_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_FILES, query_params, subscriptionKey);
    
    return result;
}

const getFileNameByProjectId = (projectId, page, date_from, date_to, business_id) => {

    let query_params = {
        projectId: projectId,
        page: page ,
        date_from: date_from,
        date_to: date_to,
        business_id: business_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_FILES_BY_PROJECT_ID, query_params, subscriptionKey);
    
    return result;
}

const VaultService = {
    getFiles,
    getFilesByProjectId,
    downloadFile,
    viewFile,
    getFilesByDateRange,
    getFileNameByProjectId

}

export default VaultService