import { useEffect } from 'react';
import useAdminRbacRolesManagement from "../hooks/useAdminRbacRolesManagement";
import useAdminRbacRolesManagementInheritance from "../hooks/useAdminRbacRolesManagementInheritance";
import useAdminRbacAccessControl from "../hooks/useAdminRbacAccessControl";
import useEsignProjectWhitelistDomainsAndEmailsCache from '../hooks/useEsignProjectWhitelistDomainsAndEmailsCache';
import useEsignProjectWhitelistDomains from '../hooks/useEsignProjectWhitelistDomains';
import useEsignProjectWhitelistEmails from '../hooks/useEsignProjectWhitelistEmails';

function TestScreen(){

    const {roles, role, getList, viewRole, deleteRole, updateRole, updateRoleStatus, createRole} = useAdminRbacRolesManagement();
    const {roles: roles_inheritance, role: role_inheritance, getInheritanceList, removeRoleInheritance, addInheritance} = useAdminRbacRolesManagementInheritance();
    const {access_control_list, data, getAccessControlList, addAccessControl, removeAccessControl, updateIs_Allowed} = useAdminRbacAccessControl();
    const { whitelist_domains_and_emails_cache, getWhitelistDomainsAndEmailsCache, whitelistForceCache } = useEsignProjectWhitelistDomainsAndEmailsCache();
    const { whitelist_domains, getSignatoryWhitelistDomains, viewSignatoryWhitelistDomain, deleteSignatoryWhitelistDomain, createSignatoryWhitelistDomains } = useEsignProjectWhitelistDomains();
    const { whitelist_emails, getSignatoryWhitelistEmails, viewSignatoryWhitelistEmails, deleteSignatoryWhitelistEmails, createSignatoryWhitelistEmails }= useEsignProjectWhitelistEmails();

    useEffect(()=>{
        
        // useAdminRbacRolesManagement()
        // getList(1, 10);
        // viewRole("SuperAdmin");
        // createRole("TestAdminRolePad", "test admin role");
        // updateRole(23, "TestAdminRolePadTwo", "edited test admin role");
        // updateRoleStatus(23, true);
        
        // useAdminRbacRolesManagementInheritance()
        // getInheritanceList(null);
        // removeRoleInheritance(23, 10);
        // addInheritance(23, 10);

        // useAdminRbacAccessControl()
        // getAccessControlList(1, 20, 1);
        // addAccessControl(23, 105, true);
        // removeAccessControl(23, 105);
        
        // useEsignProjectWhitelistDomainsAndEmailsCache()
        // getWhitelistDomainsAndEmailsCache(132, 1);
        // whitelistForceCache(132, 1);

        // useEsignProjectWhitelistDomains()
        // getSignatoryWhitelistDomains(132, 1, 10, 1);
        // viewSignatoryWhitelistDomain(53, 132, 1);
        // createSignatoryWhitelistDomains(132, 1, [{"name": "nextix9.co"}]);
        // deleteSignatoryWhitelistDomain(56, 132, 1)

        // useEsignProjectWhitelistEmails()
        // getSignatoryWhitelistEmails(132, 1, 10, 1);
        // viewSignatoryWhitelistEmails(25, 132, 1);
        // deleteSignatoryWhitelistDomain();
        // createSignatoryWhitelistEmails(132, 1, [{"email": "archiemorrxe123@sdsdsss.diii"}]);

    }, [])

    return (
        <h1>hello this is TestScreen</h1>
    );
}

export default TestScreen;