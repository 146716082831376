import {useState, useEffect} from "react";
import {Autocomplete, TextField} from "@mui/material";
import { debounce } from 'lodash';
import useUsers from "../../hooks/useUsers";
import useBusiness from "../../hooks/useBusiness";
import UserService from "../../services/api/users";

const SelectBusinessUserDropdownComponent = (props) => {

    const {businessId, projectId, onSelectUser} = props;

    const {userAccounts, pagination, loadingUserList, getUsersUnderEnterprise, searchUserByBusinessAndProject, searchUserByBusinessAndProjectData} = useUsers()

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    // const [currentPage, setCurrentPage] = useState(1);
    const [currentSelection, setCurrentSelection] = useState(null);

    useEffect(() => {
        getUsersUnderEnterprise(businessId, null)
        setCurrentSelection(null)
    }, [businessId, projectId])

    useEffect(() => {
        let users = userAccounts
        if (!projectId) {
            users = users.filter((item) => item.project_id == null)
        } else {
            let enterprise_users = users.filter((item) => item.project_id == null)
            let project_users = users.filter((item) => item.project_id == projectId)
            users = [...enterprise_users, ...project_users]
        }
        console.log("users bbbb", users);
        setOptions(users)
    }, [JSON.stringify(userAccounts), projectId])

    // useEffect(() => {
    //     if (searchUserByBusinessAndProjectData.length > 0) {
    //         setOptions((prevOptions) => {
    //             const mergedArr = [...prevOptions, ...searchUserByBusinessAndProjectData]
    //             const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
    //             return updatedOptions
    //         });
    //     }
    // }, [JSON.stringify(searchUserByBusinessAndProjectData)])

    // const removeDuplicatesOptionsArr = (arr) => {
    //     return Array.from(
    //         new Set(arr.map((obj) => JSON.stringify(obj)))
    //     ).map((jsonString) => JSON.parse(jsonString));
    // }

    // const debouncedFetchData = debounce(async (query) => {
    //     searchUserByBusinessAndProject(businessId, projectId, query)
    //   }, 1000);
    
    //   useEffect(() => {
    //     if (searchValue !== "" && !options.some((option) => option.user_principal_name.includes(searchValue))) {
    //         debouncedFetchData(searchValue);
    //     }
    
    //     // Cleanup debounced/throttled function on component unmount
    //     return () => {
    //       debouncedFetchData.cancel();
    //     };
    //   }, [searchValue]);

    // const fetchMoreData = async () => {
    //     if (currentPage < pagination) {
    //         let page = currentPage + 1
    //         setCurrentPage(page)
    //         const res = await UserService.getUsersUnderEnterpriseProject(businessId, projectId, page, 10, 3)
    //         if (res.data.success) {
    //             let users = res.data.data
    //             setOptions((prevOptions) => {
    //                 const mergedArr = [...prevOptions, ...users]
    //                 const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
    //                 return updatedOptions
    //             });
    //         }
    //     }
    // };

    const handleInputChange = (event, newInputValue) => {
        setSearchValue(newInputValue);
    };

    const handleOnChange = (event, selectedOption) => {
        console.log("selectedOption user aa", selectedOption);
        setCurrentSelection(selectedOption)
        onSelectUser(selectedOption)
    }

    return(
        <Autocomplete
            id="infinite-scroll-autocomplete"
            options={options}
            loading={loadingUserList}
            onInputChange={handleInputChange}
            onChange={handleOnChange}
            value={currentSelection}
            getOptionLabel={(option) => option.user_principal_name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField {...params} label="Select User" variant="outlined" fullWidth />
            )}
            ListboxProps={{
                onScroll : (event) => {
                    const listboxNode = event.currentTarget
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        // fetchMoreData()
                    }
                },
            }}
        />
    )
}

export default SelectBusinessUserDropdownComponent