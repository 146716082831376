import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";

const ChoosePlanConfirmationDialog = (props) => {

    const {breakpoints, openChoosePlanConfirmationDialog, setOpenChoosePlanConfirmationDialog, selectedBusiness,
        name, packageId, isAssigningPackageToBusiness, assignPackageToBusiness, setOpenSelectionOptions
    } = props;

    const handleCloseDialog = (e) => {
        setOpenChoosePlanConfirmationDialog(false);
    }

    const handleChoosePlan = (e) => {
        let isSelectOptionsDialog = false;
        let isSelectAndCustomize = false;
        
        assignPackageToBusiness(selectedBusiness, packageId, isSelectOptionsDialog, setOpenSelectionOptions, setOpenChoosePlanConfirmationDialog, isSelectAndCustomize);
    }


    return (
        <Dialog open={openChoosePlanConfirmationDialog} onClose={(e) => handleCloseDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Choose Plan
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography>
                    Are you sure you want to select <strong>{name} Plan</strong> as your enterprise plan?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{textTransform: "none", width: 100, borderRadius: "4px"}} 
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button> &nbsp;

                <Button variant="contained" sx={{textTransform: "none", 
                    width: (isAssigningPackageToBusiness) ? "auto" : 100, 
                    backgroundColor: '#0178f2', borderRadius: "4px", boxShadow: 'none'}}
                    onClick={(e) => handleChoosePlan(e)}
                    startIcon={isAssigningPackageToBusiness && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChoosePlanConfirmationDialog