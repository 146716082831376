import React, { useState } from "react";
import { Box, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography,ClickAwayListener } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteAuthenticationErrorDialog from "./delete-authentication-error.dialog";

function TransactionPopper({ viewDetails, transaction, projectId, reference, setDeleted, businessId }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteAuthenticationError, setOpenDeleteAuthenticationError] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleViewDetails = () => {
        viewDetails(transaction);
        setAnchorEl(null); // Close the popper after handling viewDetails
    };

    const handleOpenDeleteAuthenticationError = () => {
        setOpenDeleteAuthenticationError(true);
        console.log("test here")
    }

    const handleCloseDeleteAuthenticationError = () => {
        setOpenDeleteAuthenticationError(false);
    }

    console.log("openDeleteAuthenticationError xx", openDeleteAuthenticationError)
    console.log("another test here")



    return (
        <Box>
            <IconButton sx={{color: "#000"}} className="card-manage">
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={(e) => handleClick(e)} />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{zIndex: 9}}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                        <MenuItem onClick={handleViewDetails}>
                            <ListItemIcon>
                                <VisibilityIcon style={{ color: '#0178f2', width: 18, height: 18 }} />
                            </ListItemIcon>
                            <Typography variant="inherit" sx={{fontSize: 14}}>View</Typography>
                        </MenuItem>
                        {(transaction?.state === "ERROR" || transaction?.state === "Error") && (
                            <MenuItem onClick={handleOpenDeleteAuthenticationError}>
                                <ListItemIcon>
                                    <DeleteIcon style={{ color: 'red', width: 18, height: 18 }} />
                                </ListItemIcon>
                                <Typography variant="inherit" style={{ color: 'red', fontSize: 14 }}>Delete</Typography>
                            </MenuItem>
                        )}
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openDeleteAuthenticationError &&
                <DeleteAuthenticationErrorDialog
                    openDeleteAuthenticationError={openDeleteAuthenticationError}
                    handleClose={handleCloseDeleteAuthenticationError}
                    projectId={projectId}
                    reference={reference}
                    setDeleted={setDeleted}
                    transaction={transaction}
                    businessId={businessId}
                />
            }

        </Box>
    );
}

export default TransactionPopper;
