import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Stack, Select, MenuItem, TextField, InputAdornment, FormControl, InputLabel, Button, Pagination } from "@mui/material";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';


const AdminActivityLogsFilterComponent = (props) => {

    const {
        handleMonthChange, handleYearChange, handleReload, searchForOptions, handleSelectStatusOption, isMonthSelected,
        searchFor, searchStatusVal, searchForLabel, isSelectedLabel, monthValue, year, years, getMonthNames, filter_enterprise,
        businessList, projectsFilter, filter_project, auditList, filter_user, users, setFilterEnterprise, setFilterProject, setFilterUser,
        setBusinessFilterValue, businessFilterValue, businessOptions, userEmailOptions, selectedEmail, setSelectedEmail, searchModule,
        keyPressModule, action, searchAction, keyPressAction, searchAuditLogs, searchAudit, keyPressAudit, handleChangeEnterpriseName,
        handleChangeProjectName, handleChangeUser, module
    } = props;




    return (
        <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>
            <Box>
                <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', }}>
                    <ReactSelect
                        className="basic-single"
                        placeholder={
                            (searchForLabel === "Enterprise Name" && businessFilterValue) ?
                                <Typography sx={{ color: "#000", fontSize: 12 }}>
                                    {businessFilterValue}
                                </Typography>
                                :
                                "Search Enterprise"
                        }
                        isSearchable={true}
                        isRtl={false}
                        options={businessOptions}
                        value={businessFilterValue}
                        onChange={selectedOption => handleChangeEnterpriseName(selectedOption)}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 28,
                                borderRadius: 100,
                                backgroundColor: "transparent",
                                width: 250,
                                fontSize: 12,
                            }),
                            placeholder: base => ({
                                ...base,
                                fontSize: 12
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                maxHeight: '200px',
                                fontSize: 12
                            }),
                            menuList: base => ({
                                ...base,
                                maxHeight: '200px',
                                overflowY: 'auto',
                                fontSize: 12
                            })
                        }}
                    />


                    {(filter_enterprise) &&
                        <FormControl>
                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Project Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={filter_project}
                                sx={{
                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                    '&.Mui-focused': {
                                        border: 'none',
                                    },
                                }}
                                onChange={handleChangeProjectName}
                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                            >
                                {projectsFilter.length === 0 ?
                                    <MenuItem sx={{ color: 'gray', fontSize: 12 }}>No available project</MenuItem>
                                    :
                                    projectsFilter.map((project) => (
                                        <MenuItem key={project.id} value={project.id} sx={{ fontSize: 12 }}>
                                            {project.application_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }


                    <ReactSelect
                        options={userEmailOptions}
                        value={selectedEmail}
                        onChange={selectedOption => setSelectedEmail(selectedOption)}
                        isSearchable={true}
                        placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Email:</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Search Email...</i></>} // Set the placeholder here
                        styles={{
                            control: base => ({
                                ...base,
                                height: 28,
                                borderRadius: 100,
                                backgroundColor: "transparent",
                                width: 250,
                                fontSize: 12,
                            }),
                            placeholder: base => ({
                                ...base,
                                fontSize: 12
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                maxHeight: '200px',
                                fontSize: 12
                            }),
                            menuList: base => ({
                                ...base,
                                maxHeight: '200px',
                                overflowY: 'auto',
                                fontSize: 12
                            })
                        }}
                    />


                </Stack>
                <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto', mt: 2 }}>
                    <TextField
                        className="card-search"
                        variant="outlined"
                        autoComplete="off"
                        value={module}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                        placeholder="Search Module..."
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                    Module:
                                </p>
                            </InputAdornment>,
                        }}
                        onChange={searchModule}
                        onKeyDown={keyPressModule}
                    />

                    <TextField
                        className="card-search"
                        variant="outlined"
                        autoComplete="off"
                        value={action}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                        placeholder="Search Actions..."
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                    Actions:
                                </p>
                            </InputAdornment>,
                        }}
                        onChange={searchAction}
                        onKeyDown={keyPressAction}
                    />

                    <TextField
                        className="card-search"
                        variant="outlined"
                        autoComplete="off"
                        value={searchAuditLogs}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                        placeholder="Search Activity..."
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                    Activity:
                                </p>
                            </InputAdornment>,
                        }}
                        onChange={searchAudit}
                        onKeyDown={keyPressAudit}
                    />
                </Stack>
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <FormControl>
                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                    Month
                </InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Month"
                    value={monthValue}
                    sx={{ width: 150, mr: 2, height: 38, borderRadius: 10, backgroundColor: "transparent" }}
                    onChange={handleMonthChange}
                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                >
                    {getMonthNames().map((monthInfo, index) => (
                        <MenuItem key={monthInfo.value} value={monthInfo.value} sx={{ fontSize: 12 }} >
                            {monthInfo.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                    Year
                </InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Year"
                    value={year}
                    sx={{
                        width: 150, height: 38, borderRadius: 10, backgroundColor: "transparent",
                        "&.Mui-focused": { border: "none" },
                        border: isMonthSelected && year === "" ? '1px solid red' : "",
                    }}
                    onChange={handleYearChange}
                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                >
                    {years?.map((year) => {
                        return (
                            <MenuItem key={year.value} value={year.value} sx={{ fontSize: 12 }} >
                                {year.value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Stack>
    )
}

export default AdminActivityLogsFilterComponent