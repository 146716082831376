import { Box, Button, Grid, IconButton, InputLabel, Stack, Select, FormControl, MenuItem, InputAdornment, Pagination, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { timeAgo } from "../../../utils/dateFormatterUtil";
import useAnalytics from "../../../hooks/useAnalytics";
import useBusiness from "../../../hooks/useBusiness";
import TransactionSkeletonComponent from "../../skeletons/transaction-skeleton.component";
import useProjects from "../../../hooks/useProjects";
import { format } from 'date-fns';
import AuthenticationComponent from "../../analytics/authentication.component";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import CachedIcon from '@mui/icons-material/Cached';
import { CallToActionSharp } from "@mui/icons-material";
import TransactionPopper from "../../analytics/transaction-popper.component";
import ReactSelect from "react-select";
import AuthenticationEnterpriseSkeletonComponent from "../../skeletons/authentication-enterprise-skeleton.component";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";



const searchForOptions = [
    { value: "status", label: "Status" },
    { value: "project", label: "Project" },
]


const statusOption = [
    { value: "ONGOING", label: "ONGOING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "ERROR", label: "ERROR" },
]


function TableHeader() {

    const tablecell = [
        { label: 'Reference' },
        { label: 'Pipeline' },
        { label: 'Project' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ];

    return (
        <TableHead >
            <TableRow >
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TransactionContent(props) {

    const { index, transaction, setView, setReferenceId, setProjectId, setProjectName, businessId } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleted, setDeleted] = useState(false);

    let projectId = transaction?.project_id;
    let reference = transaction?.reference;

    const viewDetails = (transaction) => {
        console.log("clicked hereeee")
        setView(true)
        setReferenceId(transaction?.reference)
        setProjectId(transaction?.project_id)
        setProjectName(transaction?.project_name)
    }

    const handleCopyToClipboard = (e, name, key) => {
        navigator.clipboard.writeText(key);

        if (name == "apiKey") {
            enqueueSnackbar("Successfully copied API Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar("Successfully copied Subscription Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });
        }
    }

    function reformatString(inputString) {
        // Remove square brackets from the input string
        const cleanedString = inputString.replace(/[[\]]/g, '');

        // Convert the cleaned string to uppercase and split into an array
        const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());

        // Filter out "ENCRYPTION" from the array
        const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');

        if (filteredArray.length === 1) {
            // If there is only one element, return it as is
            return filteredArray[0];
        } else if (filteredArray.length > 1) {
            // If there are multiple elements, join them with commas and "and"
            const lastIndex = filteredArray.length - 1;
            return (
                filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
            );
        } else {
            // If no elements are left after filtering, return an empty string
            return '';
        }
    }

    return (
        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ color: "#0178f2", fontWeight: 500, cursor: "pointer", display: 'flex', alignItems: 'center' }} component="th" scope="row" >
                        <Box onClick={(e) => viewDetails(transaction)}>
                            {transaction?.reference}
                        </Box>
                        <IconButton sx={{ textTransform: "none", fontSize: 10 }}
                            onClick={(e) => handleCopyToClipboard(e, "reference", transaction?.reference)}
                        >
                            <ContentCopyIcon sx={{ width: 18, height: 18, color: "#0178f2" }} />
                        </IconButton>
                    </TableCell>

                    <TableCell align="left">
                        {reformatString(transaction?.pipeline.replace(/[[\]]/g, ''))}
                    </TableCell>

                    <TableCell align="left" sx={{ fontWeight: 600 }}>
                        {transaction?.project_name ? transaction?.project_name : 'N/A'}
                    </TableCell>

                    <TableCell align="left" sx={{ fontWeight: 600, color: transaction?.state == "COMPLETED" ? 'green' : transaction?.state == "ONGOING" ? '#0178f2' : transaction?.state == "PENDING" ? 'orange' : transaction?.state == "SUSPENDED" ? 'orange' : 'red' }} >
                        {transaction?.state}
                    </TableCell>

                    <TableCell align="left">
                        {format(new Date(transaction?.updated_time), 'PPpp')}
                    </TableCell>

                    <TableCell align="left">
                        <TransactionPopper
                            viewDetails={viewDetails}
                            projectId={projectId}
                            reference={reference}
                            transaction={transaction}
                            setDeleted={setDeleted}
                            businessId={businessId}
                        />
                    </TableCell>

                </TableRow>

            }
        </>
    )
}

function BusinessTransactionComponent() {

    const { businessDetails, getBusinessById } = useBusiness();
    const { loading, pagination, setLoading, transactions, error, getTransactionsByBusinessId, filterAuthenticationByReference, getTransactionsUnderProject, getTransactionsByStatus } = useAnalytics();
    const { loadingProjects, projects, getProjectList } = useProjects();


    const [page, setPage] = useState(1);
    const [options, setOption] = useState([]);
    const [selected_business, setSelectedBusiness] = useState('');

    const [view, setView] = useState(false);
    const [reference_id, setReferenceId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [project_name, setProjectName] = useState('');
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_project, setFilterProject] = useState("");


    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleChangeProject = (event) => {
        setFilterProject(event.target.value);
    }

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);

        } else {
            setSearchFor(null);
            setSearchForLabel(null);
            setSearchStatusVal(null);

        }
    }


    const business_id = businessDetails?.id;
    let id = (new URLSearchParams(window.location.search)).get("id");



    useEffect(() => {
        if (id) {
            getBusinessById(id);
            getProjectList(id);
        }
    }, [id])

    console.log("project aa", projects)
    console.log("filter_project aa", filter_project)

    useEffect(() => {
        if (filter_status) {
            getTransactionsByStatus(id, filter_project, filter_status, page);
        } else if (filter_project) {
            getTransactionsUnderProject(id, filter_project, page);
        } else {
            getTransactionsByBusinessId(id, page);
        }
    }, [filter_status, filter_project, id, page]);

    const handleChangePagination = (event, page) => {
        setPage(page);
        getTransactionsByBusinessId(business_id, page);
    }

    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };


    useEffect(() => {

        if (business_id) {
            getProjectList(business_id);
        }
        // append project_name per transaction by project_id
        if (transactions?.length > 0) {

            transactions?.map((transaction) => {

                let project_id = transaction?.project_id;

                projects?.map((project) => {

                    if (project?.id == project_id) {
                        transaction.project_name = project?.application_name
                    }

                })

            })

        }

    }, [JSON.stringify(transactions), JSON.stringify(projects), business_id,])

    const [searchInput, setSearchInput] = useState('');


    const searchItems = (e) => {
        setSearchInput(e.target.value);
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            filterAuthenticationByReference(id, e.target.value, page);
        }
    }


    const reload = () => {
        setSearchInput('');
        setSearchFor(null);
        setSearchForLabel(null);
        setSearchStatusVal(null);
        getTransactionsByBusinessId(id, page);
    }

    return (
        <Box sx={{ width: '95%', borderRadius: 0 }}>
            {error === "Forbidden" ?
                <ForbiddenScreen />
                :
                <>
                    <Grid container sx={{ justifyContent: 'flex-end', textAlign: 'end' }}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: 'start', mt: 1 }}>

                            <Box>
                                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>

                                    <ReactSelect
                                        className="basic-single"
                                        placeholder={
                                            <div>
                                                <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter:</b>&nbsp;
                                                <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                    {searchForLabel === "Status" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Status</Typography>
                                                    )}
                                                    {searchForLabel === "Project" && (
                                                        <Typography sx={{ color: "#000", fontSize: 12 }}>Project</Typography>
                                                    )}
                                                    {searchForLabel === null && (
                                                        "Select Filter"
                                                    )}
                                                </i>
                                            </div>
                                        }
                                        isSearchable={true}
                                        isRtl={false}
                                        options={searchForOptions}
                                        onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                width: 200,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                                fontSize: 12
                                            }),
                                        }}
                                        isClearable={true}
                                    />


                                    &nbsp; &nbsp;

                                    {(searchForLabel === "Status") &&
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={filter_status}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleStatusChange}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {statusOption?.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }} >
                                                            {status.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    }

                                    {(searchForLabel === "Project") &&
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Project</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                value={filter_project}
                                                sx={{
                                                    width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                    '&.Mui-focused': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={handleChangeProject}
                                                inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                            >
                                                {projects?.map((data, index) => (
                                                    <MenuItem key={data?.id} value={data?.id} sx={{ fontSize: 12 }}>
                                                        {data?.application_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }



                                    &nbsp;&nbsp;

                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 300 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Reference..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Reference:</p></InputAdornment>,
                                        }}
                                        value={searchInput}
                                        onChange={searchItems}
                                        onKeyDown={keyPress}
                                    />

                                    &nbsp; &nbsp;

                                    <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>

                    <br />

                    <Grid container>
                        {view ?
                            <AuthenticationComponent reference={reference_id} projectId={projectId} project_name={project_name} />
                            :
                            <>
                                <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 2, width: '100%' }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHeader />

                                        {loading ?

                                            <TransactionSkeletonComponent />


                                            :

                                            <TableBody>
                                                <>
                                                    {(transactions && transactions)?.map((transaction, index) => (
                                                        <TransactionContent
                                                            index={index}
                                                            transaction={transaction}
                                                            setView={setView}
                                                            setReferenceId={setReferenceId}
                                                            setProjectId={setProjectId}
                                                            setProjectName={setProjectName}
                                                            businessId={id}
                                                        />
                                                    ))}
                                                </>
                                            </TableBody>
                                        }
                                    </Table>

                                    {!loading &&
                                        <>
                                            {transactions?.length === 0 ?
                                                <Box sx={{ textAlign: 'center', padding: 2 }}>
                                                    No authentication record(s).
                                                </Box>
                                                :
                                                <Pagination
                                                    count={pagination}
                                                    page={page}
                                                    onChange={handleChangePagination}
                                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                                />
                                            }
                                        </>
                                    }
                                </TableContainer>

                            </>
                        }
                    </Grid>
                </>
            }

        </Box>
    )

}

export default BusinessTransactionComponent