import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getWhitelistDomainsAndEmailsCache = (projectId, businessId) => {
    let query_params = {
        projectId: projectId,
        businessId: businessId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.WHITELIST_DOMAINS_AND_EMAILS_CACHE, query_params, subscriptionKey);
    return result;
}
const whitelistForceCache = (projectId, businessId) => {
    let result = HttpPost(routes.WHITELIST_DOMAINS_AND_EMAILS_CACHE + "?projectId=" + projectId + "&businessId=" + businessId, null);
    return result;
}

const EsignProjecWhitelistDomainsAndEmailsCacheService = {
    getWhitelistDomainsAndEmailsCache: getWhitelistDomainsAndEmailsCache,
    whitelistForceCache: whitelistForceCache
}

export default EsignProjecWhitelistDomainsAndEmailsCacheService;