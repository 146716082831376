import React, {useState} from "react";
import {Avatar, Typography, Card, CardContent, Grid, TextField, Button} from "@mui/material";
import AdminProfile from '../../icons/admin-profile.png';

const UpdateProfileComponent = (props) => {

    const {breakpoints} = props;

    const [email, setEmail] = useState("ovcodeadmin@gmail.com");
    const [description, setDescription] = useState("NEXTIX is aligned around social innovation. It find practical solutions to social problems");
    const [contact_no, setContactNo] = useState("+639987765422");
    const [photoPreview, setPhotoPreview] = useState(null);

    const handleTextFieldChange = (e, name) => {
        if (name == "email") {
            setEmail(e.target.value);
        } else if (name == "description") {
            setDescription(e.target.value);
        } else {
            setContactNo(e.target.value);
        }
    }

    const validatePhoto = (e) => {
        var fileName = document.getElementById("upload-signature").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "jfif" || extFile == "gif") {
            
            setPhotoPreview(URL.createObjectURL(e.target.files[0]));
            
        } else {
          console.log("Only image files are supported as of now");
        }
    }


    return (
        <Card sx={{borderRadius: 0, padding: "8px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: "25px auto", width: breakpoints == 'xs' ? "100%" : "95%"}}>
            <CardContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "16px 0px 0px 0px" : "32px 60px 24px 60px"}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6">
                        Update Account
                    </Typography>
                </Grid>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{mt: 1}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Avatar variant="circle" src={(photoPreview)? photoPreview : AdminProfile} 
                            sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}, width: 100, height: 100, objectFit: 'contain'}} 
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}, display: "flex"}}>
                        <div className="upload" style={{marginLeft: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}}}>
                            <Button sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    id="upload-signature"
                                    onChange={(e) => validatePhoto(e)}
                                    hidden
                                />
                                <label style={{marginLeft: "-21px", cursor: "pointer", color: "#0178f2", backgroundColor: "#ffff"}} htmlFor="upload-signature" className="upload-button-text">
                                    Change Photo
                                </label>
                            </Button>
                        </div>

                        {(photoPreview) &&
                            <Button sx={{mr: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}, cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                                <label style={{cursor: "pointer", color: "#0178f2", backgroundColor: "#ffff"}} htmlFor="upload-signature" className="upload-button-text">
                                    Upload Photo
                                </label>
                            </Button>
                        }
                    </Grid>   
                </Grid>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{mt: 2}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                           Email:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={email} 
                            placeholder="Email"
                            onChange={(e) => handleTextFieldChange(e, "email")}   
                        />
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Description:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off" 
                            multiline
                            rows={2}
                            value={description}
                            placeholder="Description"
                            onChange={(e) => handleTextFieldChange(e, "description")}     
                        />
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Contact Number:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={contact_no} 
                            placeholder="Contact Number"
                            onChange={(e) => handleTextFieldChange(e, "contact_no")}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{textAlign: "end", mt: 1}}>
                        <Button variant="contained" >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UpdateProfileComponent