import React from "react";
import {Skeleton, Grid, DialogActions, DialogContent} from "@mui/material";

const EditTemplateSignatureSkeleton = (props) => {

    const {breakpoints} = props;

    return (
        <>
            <DialogContent sx={{padding: "24px 24px 10px"}}>
                <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", height: 210}} />
               
                <Grid container spacing={2} sx={{width: "100%", display: "flex", mt: "5px !important", margin: "auto"}}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{pl: "0px !important"}}>
                        <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", height: 40}} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", height: 40}} />   
                    </Grid>
                </Grid>
            </DialogContent>
   
            <DialogActions sx={{padding: "8px 22px 20px 0px"}}>
                <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", height: 5}} />

                <Skeleton variant="rectangular" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)", height: 5}} />     
            </DialogActions>
        </>
    )
}

export default EditTemplateSignatureSkeleton