import {ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useEffect, useState} from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UpdateExpirationDateDialog from "./dialogs/update-expiration.dialog";


function ProjectPopperComponent(props){
    const {project, 
        setExpirationDate,
        setExpirationDateUpdated,
        updatedExpirationDate,
        expirationDateUpdate,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdateExpirationDate, setOpenUpdateExpirationDate] = useState(false)

    const handleOpenExpirationDate = () => {
        setOpenUpdateExpirationDate(true)
    }

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <IconButton sx={{color: 'black'}} onClick={handleClick} className="card-menu" >
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem style={{color: '#0178f2', fonWeight: 500}} onClick={handleOpenExpirationDate}>
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                        <CalendarMonthIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit" sx={{fontSize: 13}}>Set Expiration Date</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            
           
            {openUpdateExpirationDate &&
                <UpdateExpirationDateDialog 
                    openUpdateExpirationDate={openUpdateExpirationDate} 
                    project={project} 
                    setOpenUpdateExpirationDate={setOpenUpdateExpirationDate} 
                    setExpirationDate={setExpirationDate}
                    updatedExpirationDate={updatedExpirationDate}
                    expirationDateUpdate={expirationDateUpdate}
                    setExpirationDateUpdated={setExpirationDateUpdated}
                />
            }

           
        </div>
    )
}

export default ProjectPopperComponent
