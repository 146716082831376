import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import useRolesManagement from "../../../hooks/useRolesManagement";
import { ThreeDots } from 'react-loader-spinner';


const AddInheritanceDialog = (props) => {

    const { openAddInheritance, handleClose, dataRole } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const { getAllRoles, getAllAdminRoles, rolesList, addInheritance, addAdminInheritance, addingInheritance, addedInheritance } = useRolesManagement();

    const [parentRole, setParentRole] = useState(dataRole?.role);
    const [roleId, setRoleId] = useState(null);


    const handleChangeRole = (event) => {
        setRoleId(event.target.value);
    }

    useEffect(() => {
        if(url === "/admin-settings"){
            getAllAdminRoles();
        } else {
            getAllRoles();
        }
    },[url])

    const handleAddInheritance = () => {

        let parentRoleId = dataRole?.id;

        let payload = {
            parent_role_id : parentRoleId,
            role_id : roleId
        }

        if(url === "/admin-settings"){
            addAdminInheritance(payload);
        } else {
            addInheritance(payload);
        }
    }

    useEffect(() => {

        if(addedInheritance){
            handleClose()
            window.location.reload();
        }

    },[addedInheritance])

    return (
        <Dialog open={openAddInheritance} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Add Inheritance
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Parent Role :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={parentRole}
                            disabled
                        />
                    </Grid>


                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Role :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={roleId}
                                onChange={handleChangeRole}
                            >
                                {rolesList?.map((data, index) => {
                                    if (parentRole !== data?.role) {
                                        return (
                                            <MenuItem value={data?.id} key={data?.id}>
                                                {data?.role}
                                            </MenuItem>
                                        );
                                    }
                                    return null; // Return null for items you want to exclude
                                })}
                            </Select>
                        
                        </FormControl>
                    </Grid>

                    
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleAddInheritance()}
                    startIcon={addingInheritance && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {addingInheritance ? "Adding" : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddInheritanceDialog