import React, { useState } from "react";
import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ManageProjectCreditsDialog from "./dialog/manage-project-credits.dialog";
import UpdateProjectDialog from "./dialog/update-project.dialog";
import DeleteProjectDialog from "./dialog/delete-project.dialog";
import UpdateProjectLogoDialogComponent from "./dialog/update-project-logo.dialog";

const ProjectPopperComponent = (props) => {

    const { breakpoints, 
            project, 
            projectCreditState, 
            setProjectCreditState, 
            project_is_updated, 
            updatedApplicationName, 
            setUpdatedApplicationName, 
            setProjectIsUpdated, 
            setIsDeleted,
            setIsLogoisUpdated,
            setUpdatedLogo,
     } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openManageCredits, setOpenManageCredits] = useState(false);
    const [openUpdateProject, setOpenUpdateProject] = useState(false);
    const [openDeleteProject, setOpenDeleteProject] = useState(false);
    const [file, setFileLogoUpload] = useState(null);
    const [logo_image, setLogoImage] = useState(null);
    const [openUpdateImage, setOpenUpdateImage] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const handleClickOpenUpdateDialog = () => {
        setOpenUpdateProject(true);
    };
  
    const handleCloseUpdateDialog = () => {
        setOpenUpdateProject(false);
    };

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteProject(true);
    };
  
    const handleCloseDeleteDialog = () => {
        setOpenDeleteProject(false);
    };
  

    const validateLogoType = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "jfif" || extFile == "gif") {
            const [target_file_logo] = e.target.files;
           
            setFileLogoUpload(e.target.files[0]);
            setLogoImage(URL.createObjectURL(e.target.files[0]));
            setOpenUpdateImage(true);
            
        } else {
          console.log("Only image files are supported as of now");
        }
       
    }

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

     let sizeFile = file?.size;


     let fileSizes = formatBytes(sizeFile);


     const handleCloseDialog = () => {
        setOpenUpdateImage(false)
    }


    return (
        <div>
            <IconButton sx={{ color: "#000" }} onClick={(e) => handleClick(e)}>
                <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{ borderRadius: 2 }}>
                    <ClickAwayListener onClick={(e) => handleClick(e)}>
                        <MenuList>
                            <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} onClick={(e) => handleClickOpenUpdateDialog()} >
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                        <EditIcon style={{ width: 16, height: 16 }} />
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Edit</Typography>
                            </MenuItem>
                            <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}}>
                                <div style={{display: 'flex', alignItems:'center'}}>
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                            <UploadIcon style={{width: 16, height: 16}}/>
                                        </IconButton>
                                    </ListItemIcon>
                                    <input 
                                        type="file" 
                                        accept="image/*" 
                                        id="upload-logo"
                                        onChange= {(e) => validateLogoType(e) }
                                        hidden
                                    />
                                    <label htmlFor="upload-logo" >Update Logo</label>
                                </div>
                            </MenuItem>
                            <MenuItem style={{ color: "#0178f2", fontSize: 13, fonWeight: 500 }} onClick={(e) => [setOpenManageCredits(true), setAnchorEl(null)]}>
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: "#0178f2" }}>
                                        <ManageAccountsOutlinedIcon style={{ width: 16, height: 16 }} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">
                                    Manage Credits
                                </Typography>
                            </MenuItem>
                            <MenuItem style={{ color: 'red', fontSize: 13, fonWeight: 500 }} onClick={(e) => handleClickOpenDeleteDialog()} >
                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                        <DeleteIcon style={{ width: 16, height: 16 }} />
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Delete</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openManageCredits &&
                <ManageProjectCreditsDialog
                    breakpoints={breakpoints}
                    openManageCredits={openManageCredits}
                    setOpenManageCredits={setOpenManageCredits}
                    project={project}
                    projectCreditState={projectCreditState}
                    setProjectCreditState={setProjectCreditState}
                />
            }

            {openUpdateProject &&
                <UpdateProjectDialog 
                    openUpdateProject={openUpdateProject} 
                    project={project} 
                    handleClose={handleCloseUpdateDialog}
                    setUpdatedApplicationName={setUpdatedApplicationName}
                    setProjectIsUpdated={setProjectIsUpdated}
                    project_is_updated={project_is_updated}
                    updatedApplicationName={updatedApplicationName}
                />
            }

            {openDeleteProject &&
                <DeleteProjectDialog 
                    project={project} 
                    openDeleteProject={openDeleteProject}  
                    handleClose={handleCloseDeleteDialog} 
                    setIsDeleted={setIsDeleted}
                />
            }

            {openUpdateImage &&
                <UpdateProjectLogoDialogComponent 
                    project={project} 
                    openUpdateImage={openUpdateImage} 
                    handleCloseDialog={handleCloseDialog}
                    fileSizes={fileSizes} 
                    validateLogoType={validateLogoType}
                    logo_image={logo_image} 
                    setLogoImage={setLogoImage}
                    file={file}
                    setIsLogoisUpdated={setIsLogoisUpdated}
                    setUpdatedLogo={setUpdatedLogo}
                    setAnchorEl={setAnchorEl}
                />
            }

        </div>
    )
}

export default ProjectPopperComponent
