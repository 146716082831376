import {useState} from "react";
import TemplateService from "../services/api/templates";

const useTemplateProperties = () => {

    const [templateProperties, setTemplateProperties] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const getTemplateProperties = (template_code) => {

        TemplateService.getTemplateProperties(template_code).then(({data}) => {
            console.log("getTemplateProperties data xxxx", data.data);

            setIsLoading(false)
            setIsSuccess(data?.success)
            setTemplateProperties(data?.data)

        }).catch((error) => {
            console.log("getTemplateProperties error xxxx", error);
        })
    }

    return {templateProperties, isLoading, isSuccess, getTemplateProperties, setTemplateProperties}
}

export default useTemplateProperties