import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth";
import AppConfigurationTabsComponent from "../components/app-configuration/app-configuration-tabs.component";



function AppConfigurationScreen () {

    const breakpoints = useWidth()

    return(
        <Box sx={{mb: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
            <Grid container spacing={3} sx={{textAlign: "start", mb: 2}}>
                <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6">
                       General
                    </Typography>
                </Grid>
                {/* <Grid item xs={4} sm={6} md={6} lg={6} xl={6} sx={{textAlign: "end", alignSelf: "center"}}>
                    <Button variant="contained" onClick={handleOpenCreateAppConfig}>
                        Create App Config
                    </Button>
                </Grid> */}
            </Grid>

            <AppConfigurationTabsComponent />

            
        </Box>
    )
}

export default AppConfigurationScreen