import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpPut, HttpPut1, HttpDelete, HttpDelete1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;


const getProjectList = (businessId) => {

    let query_params = {
        businessId: businessId
    };

    console.log("getProjectList params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_PROJECT_LIST, query_params, subscriptionKey)

    return result;
}

const getAllProjectList = (page) => {

    let query_params = {
        page: page
    };

    console.log("getProjectList params", query_params)

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_PROJECT_LIST, query_params, subscriptionKey)

    return result;
}

const viewProject = (projectId) => {

    let query_params = {
        projectId: projectId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.VIEW_PROJECT_BY_ID, query_params, subscriptionKey)

    return result;
}

const getProjectOverviewStats = (projectId) => {
    let query_params = {
        projectId: projectId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_PROJECT_OVERVIEW_STATS, query_params, subscriptionKey)

    return result;
}

const updateExpirationDate = (projectId, expiryDate) => {

    let parameters = {
        projectId: projectId,
        expiryDate: expiryDate
    }

    let payload = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.UPDATE_EXPIRATION_DATE, parameters, payload , subscriptionKey);
    return result;
    
}


const createProject = (data) => {

    let payload = {
        data : data
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost(routes.CREATE_PROJECT, payload, subscriptionKey);
    
    return result;
}


const updateProject = (id, application_name, description, deleted) => {

    let parameters = {
        id: id
    }

    let payload = {
        data : {
            application_name : application_name,
            description : description,
            deleted : deleted,
        }
    }


    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.UPDATE_PROJECT, parameters, payload , subscriptionKey);
    return result;
    
}


const deleteProject = (id) => {

    let parameters = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.DELETE_PROJECT, parameters, subscriptionKey);
    return result;
}

const getAllDeletedProjects = (businessId, page, limit) => {

    let query_params = {
        businessId: businessId,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DELETED_PROJECTS, query_params, subscriptionKey);
    
    return result;
}


const restoreDeletedProject = (id) => {

    let query_params = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPut1(routes.RESTORE_DELETED_PROJECTS, query_params, subscriptionKey);
    
    return result;
}


const uploadProjectLogo = (formData) => {

    let payload = formData;

    let result = HttpPostMultipart(routes.UPLOAD_PROJECT_LOGO, payload);
    
    return result;
}

const deleteProjectPermanently = (id) => {

    let parameters = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete1(routes.DELETE_PROJECT_PERMANENTLY, parameters, subscriptionKey);
    return result;
}


const regenerateAPIKey = (projectId) => {

    let query_params = {
        id: projectId
    }

    let payload = null;
    
    let result = HttpPut1(routes.REGENERATE_API_KEY, query_params, payload);
    
    return result;
}

const updateDownloadable = (parameters) => {


    let subscriptionKey = SUBSCRIPTION_KEY;

    let payload = null;

    let result = HttpPut1(routes.UPDATE_DOWNLOADABLE, parameters, payload, subscriptionKey);
    return result;
    
}

const updatePolicy = (id, payload) => {

    let parameters = {
        id: id
    }

    console.log("update policy params xxxx", parameters, payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.UPDATE_PRIVACY, parameters, payload , subscriptionKey);
    return result;
    
}


const AdminProjectService = {
    getProjectList,
    viewProject,
    getProjectOverviewStats,
    updateExpirationDate,
    createProject,
    updateProject,
    deleteProject,
    getAllDeletedProjects,
    restoreDeletedProject,
    uploadProjectLogo,
    deleteProjectPermanently,
    regenerateAPIKey,
    getAllProjectList,
    updateDownloadable,
    updatePolicy
}

export default AdminProjectService