import React, {useState, useEffect} from "react";
import {Box, Typography} from '@mui/material';
import {CustomTab, CustomTabs} from "../common/custom-tabs.component";
import PackageSettingsComponent from "./package-settings.component";
import PackageComponent from "./package.component";

function useProjectTabs (props) {
    const {breakpoints} = props;

    const [packageManagementTabs, setPackageManagementTabs] = useState(null);

    useEffect(() => { 
        const setupTabs = () => {
            const tabs_default = [
                {name: "Plans", component: <PackageComponent breakpoints={breakpoints} />},
                {name: "Settings", component: <PackageSettingsComponent breakpoints={breakpoints} />}
            ]
 
            setPackageManagementTabs(tabs_default);
        }
        
        setupTabs();
    }, [])

    return packageManagementTabs;
}

const PackageManagementTabsComponent = (props) => {
    
    const {breakpoints} = props;
    
    const tabs = useProjectTabs(props);

    const [value, setValue] = useState(0);

    const TabPanel = (props) => {
        const {children, value, index, ...other } = props;
        
        return (
            <div
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {(value === index) &&
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                }
            </div>
        )
    }

    const a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }


    return (
        <Box sx={{width: "98%", margin: "auto", marginRight: 0}}>
            <Box sx={(breakpoints == "xs" || breakpoints == "md") ? null : {flexGrow: 1, bgcolor: "background.paper", display: "flex"}}>
                <CustomTabs
                    orientation={(breakpoints == "xs" || breakpoints == "md") ? "horizontal" : "vertical"}
                    variant={(breakpoints == "xs" || breakpoints == "md") ? "scrollable" : ""}
                    value={value} 
                    onChange={handleChange} 
                    indicatorColor="transparent"
                    sx={(breakpoints == "xs" || breakpoints == "md") ? {borderColor: "divider"} : {marginTop: 2, borderRight: 2, borderColor: "divider", width: 200}}
                >
                    {tabs?.map((tab, index) => {
                        return (<CustomTab label={<Typography sx={{textAlign: "start"}}>{tab.name}</Typography>} disableRipple {...a11yProps(index)} sx={{alignItems: "flex-start !important", mb: 1}} />)
                    })}
                </CustomTabs>

                {tabs?.map((tab, index) => {
                    return  (
                        <TabPanel value={value} index={index} style={{padding: "0px", width: "100%"}}>
                            {tab.component}
                        </TabPanel> 
                    ) 
                })}
            </Box>
        </Box>
    )
}

export default PackageManagementTabsComponent