import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpPut, HttpPut1, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const appConfigList = (page) => {

    let query_params = {
        page: page ,
    }


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.APP_CONFIG_LIST, query_params, subscriptionKey);
    
    return result;
}

const searchByAppCode = (page, appCode) => {

    let query_params = {
        page: page ,
        appCode: appCode
    }


    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.APP_CONFIG_LIST, query_params, subscriptionKey);
    
    return result;
}

const createAppConfig = (payload) => {

    console.log("create app config payload xxxx",  payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_APP_CONFIG, payload , subscriptionKey);
    console.log("create app result", result);
    return result;
    
}

const deleteAppConfig = (app_code) => {

    let parameters = {
        appCode: app_code,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_APP_CONFIG, parameters, subscriptionKey);
    
    return result;
}

const viewAppConfig = (app_code) => {

    let parameters = {
        appCode: app_code,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.VIEW_APP_CONFIG, parameters, subscriptionKey);
    
    return result;
}

const updateAppConfig = (payload) => {

    let parameters = ""
    

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.UPDATE_APP_CONFIG, parameters, payload , subscriptionKey);
    return result;
    
}

const configList = (page) => {

    let query_params = {
        page: page,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.LIST_CONFIG, query_params, subscriptionKey);
    
    return result;
}

const createConfig = (payload) => {

    console.log("create config payload xxxx",  payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_CONFIG, payload , subscriptionKey);
    console.log("create config result", result);
    return result;
    
}

const deleteConfig = (key) => {

    let parameters = {
        key: key,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_CONFIG, parameters, subscriptionKey);
    
    return result;
}


const businessAppList = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.LIST_OF_ALL_BUSINESS_APP, query_params, subscriptionKey);
    
    return result;
}


const availableBusinessApp = () => {

    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.AVAILABLE_BUSINESS_APP, query_params, subscriptionKey);
    
    return result;
}

const addBusinessApp = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_BUSINESS_APP, payload , subscriptionKey);

    return result;
    
}


const deleteBusinessApp = (businessId,appName) => {

    let parameters = {
        businessId: businessId,
        appName: appName
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_BUSINESS_APP, parameters, subscriptionKey);
    
    return result;
}

const searchSecretConfig = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_SECRET_CONFIG, query_params, subscriptionKey);
    
    return result;
}

const searchAppMiddleware = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_APP_CONFIG, query_params, subscriptionKey);
    
    return result;
}

const searchBusinessApp = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_BUSINESS_APP, query_params, subscriptionKey);
    
    return result;
}


const BusinessAppConfigService = {
    appConfigList: appConfigList,
    createAppConfig:createAppConfig,
    deleteAppConfig: deleteAppConfig,
    viewAppConfig: viewAppConfig,
    updateAppConfig: updateAppConfig,
    searchByAppCode: searchByAppCode,
    configList: configList,
    createConfig: createConfig,
    deleteConfig: deleteConfig,
    businessAppList,
    availableBusinessApp,
    addBusinessApp,
    deleteBusinessApp,
    searchSecretConfig: searchSecretConfig,
    searchAppMiddleware: searchAppMiddleware,
    searchBusinessApp: searchBusinessApp,
}

export default BusinessAppConfigService