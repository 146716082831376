import {Grid, Skeleton} from "@mui/material";

function WebhookSkeletonComponent () {
    
    return (
        <Grid container spacing={2} sx={{pl: 3, pt: 1}}>
            <Skeleton variant="text" style={{width: "98%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            <Skeleton variant="text" style={{width: "98%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            <Skeleton variant="text" style={{width: "50%", backgroundColor: "rgb(108 139 222 / 30%)"}} />

            <br /> 

            <Skeleton variant="text" style={{marginTop: "30px", width: "98%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            <Skeleton variant="text" style={{width: "98%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            <Skeleton variant="text" style={{width: "98%", backgroundColor: "rgb(108 139 222 / 30%)"}} />
        </Grid>
    )
}

export default WebhookSkeletonComponent