import {useEffect, useState} from "react";
import UserService from "../services/api/users";
import {useSnackbar} from "notistack";
import AuditService from "../services/api/audit";

const useAudit = () => {

  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [Alllogs, setAllLogs] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [nextToken, setNextToken] = useState(null);
  const [retrieved, setRetrieved] = useState(false);



  var limit = 5;

  const retrieveAdminSigninLogs = () => {
    setLoading(true);

    AuditService.retrieveAdminSigninLogs().then(res => {
      console.log("res admin logs", res)
        setLoading(false);
      if (res.data.success) {
        setLogs(res.data.data.values);
        setNextToken(res.data.data.nextToken);
      }

    }).catch((err)=>{ 
      setLoading(false);
    })
  }

  const retrieveUserSigninLogs = (oid) => {
    setLoading(true);

    AuditService.retrieveUserSigninLogs(oid).then(res => {
      console.log("user logs", res )
        setLoading(false);
      if (res.data.success) {
        setLogs(res.data.data.values);
        setNextToken(res.data.data.nextToken);
      }

    }).catch((err)=>{ 
      setLoading(false);
    })
  }

  
  const retrieveSigninLogs = (email) => {
    setLoading(true);

    AuditService.retrieveSigninLogs(email,limit).then(res => {

        setLoading(false);

      if (res.data.success) {
        setLogs(res.data.data.values);
        setNextToken(res.data.data.nextToken);
        if(res.data.data.values.length  === 0){
          setPagination(0)
        }else{
          var last_page = Math.ceil(Alllogs / limit); //set it depending on ui return - 18
          setPagination(last_page)
        }
      }

    }).catch((err)=>{ 
      setLoading(false);
    })
  }


  const retrieveAllSigninLogs = (email) => {
    setLoading(true);

    AuditService.retrieveAllSigninLogs(email).then(res => {

      console.log("res herexxxx:", res)

        setLoading(false);

      if (res.data.success) {
        setRetrieved(true);
        setAllLogs(res.data.data.values.length);
        
      }

    }).catch((err)=>{ 
      setLoading(false);
      setPagination(0)

    })
  }



  const retrieveSigninLogsPagination = (email) => {
    setLoading(true);

    AuditService.retrieveSigninLogsPagination(email,limit,nextToken).then(res => {

      setLoading(false);

      if (res.data.success) {
        setLogs(res.data.data.values);
        if(res.data.data.values.length  === 0){
          setPagination(0)
        }else{
          var last_page = Math.ceil(Alllogs / limit); //set it depending on ui return - 18
          setPagination(last_page)
        }
      }

    }).catch((err)=>{ 
      setLoading(false);
      setPagination(0)
    })
  }

  const retrieveUserSigninLogsPagination = (oid) => {
    setLoading(true);

    AuditService.retrieveUserSigninLogsPagination(oid,limit,nextToken).then(res => {

      setLoading(false);

      if (res.data.success) {
        setLogs(res.data.data.values);
        if(res.data.data.values.length  === 0){
          setPagination(0)
        }else{
          var last_page = Math.ceil(Alllogs / limit); //set it depending on ui return - 18
          setPagination(last_page)
        }
      }

    }).catch((err)=>{ 
      setLoading(false);
      setPagination(0)
    })
  }

  const retrieveAdminSigninLogsPagination = () => {
    setLoading(true);

    AuditService.retrieveAdminSigninLogsPagination(limit,nextToken).then(res => {

      setLoading(false);

      if (res.data.success) {
        setLogs(res.data.data.values);
        if(res.data.data.values.length  === 0){
          setPagination(0)
        }else{
          var last_page = Math.ceil(Alllogs / limit); //set it depending on ui return - 18
          setPagination(last_page)
        }
      }

    }).catch((err)=>{ 
      setLoading(false);
      setPagination(0)
    })
  }

 
  return {loading, retrieved, retrieveSigninLogs, logs, pagination, setLoading, retrieveAdminSigninLogs,
    retrieveSigninLogsPagination, retrieveAllSigninLogs, Alllogs, retrieveUserSigninLogs, retrieveAdminSigninLogsPagination,
    retrieveUserSigninLogsPagination }

}

export default useAudit;