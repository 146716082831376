import { useState } from 'react';
import EsignProjecWhitelistDomainsAndEmailsCacheService from '../services/api/esign-project';

function useEsignProjectWhitelistDomainsAndEmailsCache() {
  
  const [whitelist_domains_and_emails_cache, setWhitelistDomainsAndEmailsCache] = useState();

  const getWhitelistDomainsAndEmailsCache = (projectId, businessId) => {
    EsignProjecWhitelistDomainsAndEmailsCacheService.getWhitelistDomainsAndEmailsCache(projectId, businessId).then(res=> {
        console.log("res of getWhitelistDomainsAndEmailsCache: ", res)
        setWhitelistDomainsAndEmailsCache(res);
    });
  };

  const whitelistForceCache = (projectId, businessId) => {
    EsignProjecWhitelistDomainsAndEmailsCacheService.whitelistForceCache(projectId, businessId).then(res=> {
        console.log("res of whitelistForceCache: ", res)
        setWhitelistDomainsAndEmailsCache(res);
    });
  };

  return {whitelist_domains_and_emails_cache, getWhitelistDomainsAndEmailsCache, whitelistForceCache} ;

}

export default useEsignProjectWhitelistDomainsAndEmailsCache;