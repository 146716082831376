import { Box, Card, Grid, Typography, Skeleton, CardContent, Divider, CardActions } from "@mui/material"
import { useEffect, useState } from "react";
import BackButton from "../components/common/back-button"
import ViewProfileComponent from "../components/profile/view-profile.component"
import ViewProfileSkeletonComponent from "../components/skeletons/view-profile-skeleton.component";
import useUsers from "../hooks/useUsers";
import useWidth from "../hooks/useWidth";
import ForbiddenScreen from "./ForbiddenScreen";

const ViewProfileScreen = () => {

    const breakpoints = useWidth();

    const { loading, admin, getAdmin, viewAdminUser, userDetails, error } = useUsers();

    useEffect(() => {
        getAdmin();
    }, [])

    useEffect(() => {
        if(admin == "") return;
        let userPrincipalName = admin.userPrincipalName;
        let oid = userPrincipalName.split('@')[0];
        viewAdminUser(oid);
    }, [admin])

    return (
        <Box>
            <Box sx={{ textAlign: 'start', pb: 2, mt: -1.5 }}>
                <BackButton />
            </Box>

            {error === "Forbidden" ?
                <ForbiddenScreen />
                :

                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ViewProfileComponent breakpoints={breakpoints} admin={admin} loading={loading} role={userDetails?.user_role}/>
                    </Grid>
                </Grid>
            }



        </Box>
    )
}

export default ViewProfileScreen