import { useState } from "react"
import { blobToBase64 } from "../utils/blobToBase64Utils";
import { useVerifiedDataStore } from "../zustand/useVerifiedDataStore";
import { useSnackbar } from "notistack";
import {useNavigate} from "react-router-dom";
import VaultService from "../services/api/vault";


const useVault = () => {

    const {setScannedReference, setScannedInfo, setDocPrivacy, setDownloadable, setPdfBaseUrlData} = useVerifiedDataStore();
    const [fileList, setFileList] = useState([]);
    const [fileByProjectId, setFileByProjectId] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)
    const [vaultPdfView, setVaultPdfView] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const [error,setError] = useState(false);

    const navigate = useNavigate();

    var limit = 12

    const getFiles = (page, date_from, date_to, business_id) => {
        setLoading(true)

        console.log("xx dateFromxxx xx", date_from)
        console.log("xx dateToxxx xx", date_to)
       
        VaultService.getFiles(page, limit, date_from, date_to, business_id).then((res)=>{
            console.log("getFiles herexx", res)
            setLoading(false)
            if (res.data.success) {
                setFileList(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((e)=>{
            setLoading(false)
            console.log("e xxx", e)
            setError(e.response.statusText);
        })
    }

    const getFilesByProjectId = (projectId, page, date_from, date_to, businessId) => {
        console.log("bb date_from here", date_from)
        console.log("bb date_to here", date_to)
        console.log("bb projectId here", projectId)
        console.log("bb businessId here", businessId)
        setLoading(true)
        VaultService.getFilesByProjectId(projectId, page, date_from, date_to, businessId).then((res)=>{

            console.log("getFilesByProjectIdxx", res)
            setLoading(false)
            if (res.data.success) {
                setFileList(res.data.data)
                setFileByProjectId(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((e)=>{
            setLoading(false)
        })
    }

    const downloadFile = (reference, filename, businessId) => {
        setIsLoading(true)
        VaultService.downloadFile(reference, businessId).then((res)=>{
            setIsLoading(false)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch((error)=>{
            setIsLoading(false)
            enqueueSnackbar('Unable to download', { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const previewFile = (reference, setOpenDocxPreview, setOpenBackdrop, e, businessId) => {
        setIsPreviewLoading(true)

        const pdfPart = e?.substring(e?.lastIndexOf("/") + 1);

        if(pdfPart === 'pdf.png'){
            let pdfUrl = "/file-preview?reference=" + reference + "&business_id=" + businessId;
            window.location.href=pdfUrl;
            setIsPreviewLoading(false)
        } else {

        VaultService.downloadFile(reference, businessId).then((res)=>{
            
            let mimetype = res.headers["content-type"];
            let splittedFilename = res.headers["content-disposition"].split(";").pop();
            let filename = splittedFilename.split("=").pop();
            let extension = filename.split(".").pop();

            console.log('extension here', extension)

            let blob = res.data;

            let jsonUrl = "/view/json?document=" + filename;
            let textUrl = "/text-preview?document=" + filename;

            if(extension == "json"){
                VaultService.viewFile(reference).then((res)=>{
                    const jsonString = JSON.stringify(res.data);
                    localStorage.setItem("jsonData", jsonString);

                    setIsPreviewLoading(false);
                    setOpenBackdrop(false);

                    navigate(jsonUrl);
                })
                    
            } else if(extension == "txt"){
                VaultService.viewFile(reference).then((res)=>{
                    const textString = JSON.stringify(res.data);
                    localStorage.setItem("textData", textString);

                    setIsPreviewLoading(false);
                    setOpenBackdrop(false);

                    navigate(textUrl);
                })
                    
            } else if(extension == "docx" || extension == "doc"){
                setOpenDocxPreview(true)
            }

        }).catch((e)=>{
            setIsPreviewLoading(false)
            enqueueSnackbar(e.response.statusText, { 
                variant: 'error',
                autoHideDuration: 3000
            });
            setOpenBackdrop(false)
        })

        }
    }


    const previewPdfFile = (reference, businessId, setOpenBackdrop) => {
        setIsPreviewLoading(true)
        VaultService.downloadFile(reference, businessId).then((res)=>{
            
            let mimetype = res.headers["content-type"];
            let splittedFilename = res.headers["content-disposition"].split(";").pop();
            let filename = splittedFilename.split("=").pop();
            let extension = filename.split(".").pop();

            console.log('extension here', extension)

            let blob = res.data;

            let pdfUrl = "/file-preview?document=" + filename;

            if (extension == "pdf") {
                blobToBase64(blob).then(res => {
                    setPdfBaseUrlData(res);
                    setIsPreviewLoading(false)

                    if (res) {
                        setOpenBackdrop(false);

                        console.log("res here", res)

                        setVaultPdfView(res)
                    }
                });
            } 

        }).catch((e)=>{
            console.log("error here", e)

            setIsPreviewLoading(false)
            enqueueSnackbar(e.response.statusText, { 
                variant: 'error',
                autoHideDuration: 3000
            });
            setOpenBackdrop(false)
        })
    }

    const getFilesByDateRange = (page, date_from, date_to, business_id ) => {
        setLoading(true)
        VaultService.getFilesByDateRange(page, limit, date_from, date_to, business_id).then((res)=>{
            setLoading(false)
            if (res.data.success) {
                setFileList(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((e)=>{
            setLoading(false)
        })
    }


    const getFileNameByProjectId = (projectId, page,  date_from, date_to, business_id) => {
        setLoading(true)
        VaultService.getFileNameByProjectId(projectId, page,  date_from, date_to, business_id).then((res)=>{
            setLoading(false)
            if (res.data.success) {
                setFileByProjectId(res.data.data)

                if (res?.data?.data?.length === 0) {
                    setPagination(0);
                } else {
                    var last_page = Math.ceil(res?.data?.limit);
                    setPagination(last_page);
                }
            }
        }).catch((e)=>{
            setLoading(false)
        })
    }

    return{
    getFiles, fileList, getFilesByProjectId, limit, fileByProjectId, loading, downloadFile, previewFile,error,
        pagination, isLoading, isPreviewLoading, getFilesByDateRange, getFileNameByProjectId, previewPdfFile, vaultPdfView
    }
}

export default useVault