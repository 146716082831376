import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPut1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const addProjectCredits = (payload) => {
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADD_PROJECT_CREDITS, payload, subscriptionKey);

    return result;
}

const subProjectCredits = (businessId, projectId, credit) => {
    let parameters = {
        businessId: businessId,
        projectId: projectId,
        credit: credit
    }

    let payload = "";

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.SUB_PROJECT_CREDITS, parameters, payload, subscriptionKey);

    return result;  
}

const viewProjectCredits = (businessId, projectId) => {
    let query_params = {
        businessId: businessId,
        projectId: projectId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_PROJECT_CREDITS, query_params, subscriptionKey);

    return result; 
}

const getProjectCreditList = (businessId, page, limit) => {
    let query_params = {
        businessId: businessId,
        page: page,
        limit: limit,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_PROJECT_CREDIT_LIST, query_params , subscriptionKey);

    return result; 
}

const getBusinessCredits = (page, limit) => {

    let query_params = {
        page : page,
        limit : limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_BUSINESS_CREDITS, query_params , subscriptionKey);

    return result;
    
}

const addBusinessCredits = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADD_BUSINESS_CREDITS, payload , subscriptionKey);

    return result;
    
}

const subBusinessCredits = (businessId, credit) => {

    let parameters = {
        businessId : businessId,
        credit: credit
    }

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.SUB_BUSINESS_CREDITS, parameters, payload , subscriptionKey);

    return result;
    
}

const setBusinessCreditsUnlimited = (businessId, unlimited) => {

    let parameters = {
        businessId : businessId,
        unlimited: unlimited
    }

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.SET_BUSINESS_CREDITS_UNLIMITED, parameters, payload , subscriptionKey);

    return result;
    
}

const viewBusinessCredits = (businessId) => {
    let query_params = {
        businessId: businessId,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_BUSINESS_CREDITS, query_params, subscriptionKey);

    return result; 
}

const searchBusinessCredits = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_BUSINESS_CREDITS, query_params, subscriptionKey);

    return result; 
}



const CreditsManagementService = {
    addProjectCredits,
    subProjectCredits,
    viewProjectCredits,
    getProjectCreditList,
    getBusinessCredits,
    addBusinessCredits,
    subBusinessCredits,
    setBusinessCreditsUnlimited,
    viewBusinessCredits,
    searchBusinessCredits,
}

export default CreditsManagementService