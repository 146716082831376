import { Button, Grid, Skeleton, Typography } from "@mui/material"



function AddSignatureSkeletonComponent () {

    return(
        <>
            <Grid container spacing={2} sx={{overflow: "auto", padding: "1px 24px 10px"}}>
                <Grid item xs={12} sx={{textAlign:'center'}}>
                    <Skeleton variant="rectangular" width={250} height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)', margin:'auto'}} />
                </Grid>
                
            </Grid>
            <Typography sx={{cursor: "default", color: "gray", margin: "auto"}}>
                Select signature to add
            </Typography>
            <br/>
            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)', margin:'auto'}} />
            <br/>
            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)', margin:'auto'}} />
        </>
    )
}

export default AddSignatureSkeletonComponent