import React, {useState, useEffect} from "react";
import {Box, Button, Grid, Typography} from '@mui/material';
import {CustomTab, CustomTabs} from "../common/custom-tabs.component";
import ProjectDetailsComponent from "./project-details.component";
import TemplateListScreen from "../../screens/TemplateListScreen";
import WebhookScreen from "../../screens/WebhooksScreen";
import EmailSenderScreen from "../../screens/EmailSenderScreen";
import AuthenticationScreen from "../../screens/AuthenticationScreen";
import UsersComponent from "../enterprise/users.component";
import ProjectOverviewComponent from "./project-overview.component";
import EnterpriseProjectReportsComponent from "./reports/enterprise-project-reports.component";
import EnterpriseProjectTransactionComponent from "./project-transactions.component";
import EnterpriseProjectActivityLogsScreen from "./enterprise-project-activity-logs.component";
import ProjectTemplateManagementComponent from "./templates/project-template-management.component";
import ProjectBillingScreenComponent from "./billing/project-billing-screen.component";
import ProjectVaultScreen from "../../screens/ProjectVaultScreen";

import AppConfigScreen from "../../screens/AppConfigScreen";

import useWidth from "../../hooks/useWidth";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AdminService from "../../services/api/admin";
import EnterpriseProjectTabsComponent from "./reports/enterprise-project-tabs.component";



function useProjectTabs(props){

  const {breakpoints, projectId, projectCredit, project_name, businessId} = props;

  const [ project_tabs, setProjectTabs ] = useState(null);
  const [appName, setAppName] = useState([]); 

  const handleRetrieveApp = () => {
    const intBusinessId = parseInt(businessId, 10); // Assuming businessId is a string
    AdminService.retrieveApps(intBusinessId, projectId).then((res) => {
        console.log('retrieve apps res here', res.data.data);
        setAppName(res.data.data);
    });
};

    useEffect(() => {
        handleRetrieveApp();
    },[]);

    useEffect(() => {
        setupTabs();
    }, [appName]); // Run setupTabs whenever appName changes
  
  console.log("appName here", appName);
  function setupTabs(){

    var tabs_default = [
      {name: "Overview", component: 
        <ProjectOverviewComponent 
          projectId={projectId} 
          breakpoints={breakpoints} 
          projectCredit={projectCredit}
        /> 
      },
      {name: 'Details', component: 
        <ProjectDetailsComponent 
          projectId={projectId} 
          breakpoints={breakpoints} 
          projectCredit={projectCredit}
          project_name={project_name}

        /> 
      },
      {name: 'Vault', component: 
       <ProjectVaultScreen 
       breakpoints={breakpoints} 
       projectId={projectId} 
       project_name={project_name}
       />
      },
      {name: 'Templates', component: 
        <ProjectTemplateManagementComponent
          breakpoints={breakpoints} 
          projectId={projectId} 
          project_name={project_name}
         />
      },
      {name: 'Webhooks', component: 
        <WebhookScreen
          breakpoints={breakpoints} 
          projectId={projectId} 
          project_name={project_name}
        /> 
      },
      {name: 'Email Sender', component: 
        <EmailSenderScreen 
          projectId={projectId} 
          breakpoints={breakpoints} 
          project_name={project_name}
        /> 
      },
      {name: 'Authentication', component: 
          <AuthenticationScreen 
            breakpoints={breakpoints}
            projectId={projectId} 
            project_name={project_name}
          /> 
      },
      // {name: 'App Config', component: 
      //     <AppConfigScreen 
      //       breakpoints={breakpoints}
      //       projectId={projectId} 
      //       project_name={project_name}
      //     /> 
      // },
      {name: 'Users', component: 
        <UsersComponent 
          projectId={projectId} 
          breakpoints={breakpoints}
          project_name={project_name} 
        /> 
      },
      {name: 'Billing', component: 
      <ProjectBillingScreenComponent 
          projectId={projectId} 
          breakpoints={breakpoints}
          project_name={project_name} 
        />
      },
      {name: 'Analytics', component: 
        <EnterpriseProjectTransactionComponent 
          projectId={projectId} 
          breakpoints={breakpoints}
          project_name={project_name}
        />
      },
      {name: 'Reports', component: 
        <EnterpriseProjectTabsComponent 
          projectId={projectId} 
          breakpoints={breakpoints}
          project_name={project_name}
        />
      },
      {name: "Activity Logs", component: 
        <EnterpriseProjectActivityLogsScreen 
          projectId={projectId} 
          breakpoints={breakpoints}
          project_name={project_name}
        />
      },
    ];

    if (appName && appName?.length > 0) {
      tabs_default.push({name: 'App Config', component: 
        <AppConfigScreen 
          breakpoints={breakpoints}
          projectId={projectId} 
          project_name={project_name}
        /> 
      });
    }
    
    setProjectTabs(tabs_default);
  }

  return project_tabs;

}


function ProjectTabsComponent(props){

  const {projectCredit, project_name, projectId, businessId} = props;

  console.log("projectIdxxx one", projectId)
  console.log("businessIdxxx one", businessId)

  const breakpoints = useWidth();

  const tabs = useProjectTabs(props);

  const [ value, setValue ] = useState(0);

  function TabPanel(props) {
      
      const { children, value, index, ...other } = props;
      
    
      return (
        <div
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue){
    setValue(newValue);
  }

  const navigate = useNavigate();

  const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

  return(
    <Box sx={{width: '98%', margin:'auto', marginRight: 0}}>
        {/* <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
            style={{color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight : 600  }}
            onClick={() => navigate(0)} >
                Back
        </Button> */}
        <Grid item xs={12} sx={{ textAlign: 'start' , ml: 1}}>
                <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight : 600  }}
                onClick={() => navigate('/view?id=' + businessIdFromURL )} >
                    {project_name}
                </Button>
            </Grid>
        <Box sx={ (breakpoints == 'xs' || breakpoints == 'md') ? null : {flexGrow: 1, mt: 1, bgcolor: 'background.paper', display: 'flex', borderRadius: '10px', paddingLeft: 2}}>
            <CustomTabs
                orientation={(breakpoints == 'xs' || breakpoints == 'md') ? "horizontal" : "vertical"}
                variant={(breakpoints == 'xs' || breakpoints == 'md') ? "scrollable" : ""}
                value={value} 
                onChange={handleChange} 
                indicatorColor="transparent"
                sx={ (breakpoints == 'xs' || breakpoints == 'md') ? { borderColor: 'divider'} : {marginTop: 2, borderRight: 2, borderColor: 'divider', width: 200}}
            >
                { tabs?.map((tab, index)=>{
                    return(<CustomTab label={<Typography sx={{textAlign:'start', fontSize: 14}}>{tab.name}</Typography>} disableRipple {...a11yProps(index)} sx={{alignItems: 'flex-start !important', mb: 1}}   />)
                }) }
            </CustomTabs>
            { tabs?.map((tab, index)=>{
                return(
                <TabPanel value={value} index={index} style={{padding: "0px", width: '100%'}}>
                    {tab.component}
                </TabPanel> 
                ) 
            })}
        </Box>
    </Box>
  )
}

export default ProjectTabsComponent