import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@mui/material";
import {timeAgo} from "../../utils/dateFormatterUtil";
import Avatar from 'react-avatar';
import ActionsPopperComponent from "./action-popper.component";

const DeletedProjectListComponent = (props) => {

    const {projects, index,
        setDelete,
        setIsRestore
    } = props;

    
    const [isDeleted, setIsDeleted] = useState(false);
    const [isRestored, setIsRestored] = useState(false);

    useEffect(() => {

        if(isDeleted) {
            setDelete(true);
        }

    },[isDeleted])

    useEffect(() => {

        if(isRestored) {
            setIsRestore(true);
        }

    },[isRestored])


    return (
        <>
            {!(isDeleted || isRestored) && (
                <TableRow  key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell align="left" > 
                        {projects?.logo ?
                            <img alt="" src={projects?.logo} style={{height: 80, width: 80, objectFit:'contain'}}/>
                        :
                            <Avatar round={true} name={projects?.application_name} size="80px" maxInitials={2}/>
                        }
                    </TableCell>

                    <TableCell align="left" > 
                        {projects?.application_name}
                    </TableCell>

                    <TableCell align="left" > 
                        {projects?.description}
                    </TableCell>
                
                    <TableCell align="left" sx={{fontSize: "13px !important"}}>
                        {timeAgo(new Date(projects?.updated_date))}
                    </TableCell>

                    <TableCell align="left">
                        <ActionsPopperComponent 
                            project={projects}
                            setIsDeleted={setIsDeleted}
                            setIsRestored={setIsRestored}
                        />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default DeletedProjectListComponent