import React, { useEffect, useState } from "react";
import { ListItemText, OutlinedInput, Select, InputLabel, FormControl, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, easing } from "@mui/material";
import { ThreeDots } from 'react-loader-spinner';
import { useSnackbar } from "notistack";
import useRolesManagement from "../../../hooks/useRolesManagement";
import UserService from "../../../services/api/users";
import useProjects from "../../../hooks/useProjects";

const UpdateUserRoleDialog = (props) => {

    const { breakpoints, openUpdateRole, handleCloseUpdateRole, userEmail, userId, userRole, projectId, getUsersByBusinessAccountID, businessId } = props;

    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles } = useRolesManagement();
    const { getProjectList, projects, loadingProjects } = useProjects();

    const defaultProjectId = projectId;

    const [loading, setLoading] = useState(false);
    const [userRoleUpdated, setUserRoleUpdated] = useState(false);
    const [role, setRole] = useState(userRole);
    const [project_id, setProjectId] = useState(defaultProjectId);

    // console.log("rolexx", role)


    useEffect(() => {
        if (businessId) {
            getProjectList(businessId);
        }
    }, [businessId])

    // console.log("businessId ccc", businessId)
    // console.log("projects aaaa", projects)
    // console.log("project_id aaaa", project_id)

    const selectedProject = projects.find(project => project.id === project_id);

    // console.log("selectedProject bbb", selectedProject)



    useEffect(() => {
        getAllRoles();
    }, [])


    const handleChangeRole = (event) => {
        setRole(event.target.value);
    }

    const handleCloseDialog = (e) => {
        handleCloseUpdateRole();
    }

    const handleChangeProject = (event) => {
        setProjectId(event.target.value);
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await UserService.updateUserRole(userId, role, project_id);
            // console.log('update user role res', res);
            setLoading(false);
            setUserRoleUpdated(true);
            enqueueSnackbar("User role updated successfully.", {
                variant: "success",
                autoHideDuration: 3000,
            });
        } catch (err) {
            setLoading(false);
            handleCloseDialog();
            // console.log('error', err);
        }
    };


    useEffect(() => {
        if (userRoleUpdated) {
            handleCloseDialog();
            window.location.reload();
        }
    }, [userRoleUpdated]);


    return (
        <Dialog open={openUpdateRole} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{ style: { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto" } }}>
            <DialogTitle>
                Edit User Role
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 3 : "24px 48px 28px 48px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ alignSelf: "center", fontWeight: 550 }}>
                        Email
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <TextField
                            fullWidth
                            value={userEmail}
                            disabled
                            sx={{ "& .MuiInputBase-root": { height: 48, borderRadius: 100, backgroundColor: 'white', width: '100%' } }}
                            inputProps={{
                                style: { padding: "10px", fontSize: 14, fontStyle: 'italic' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ alignSelf: "center", fontWeight: 550 }}>
                        Role
                    </Grid>
                    <Grid item xs={12} sx={{ pt: "4px !important" }}>

                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                value={role}
                                sx={{
                                    minWidth: '100%', width: '100%', height: 48, borderRadius: 10, backgroundColor: 'white',
                                    '&.Mui-focused': {
                                        border: 'none',
                                    },
                                }}
                                onChange={handleChangeRole}
                                inputProps={{ sx: { mr: "0px", fontSize: 14, textAlign: "center" } }}
                                MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 300 } } }}
                            >
                                {rolesList?.map((data, index) => (
                                    <MenuItem key={data?.id} value={data?.role}>
                                        {data?.role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {[
                        "ProjectManager",
                        "ProjectQALead",
                        "ProjectAdministrator",
                        "ProjectOfficer",
                        "ProjectSupport",
                        "ProjectBillingSpecialist",
                        "ProjectAuditor"
                    ].includes(role) && (
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 550 }}>Select Project:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={project_id}
                                            onChange={handleChangeProject}
                                            sx={{ fontSize: 12 }}
                                        >
                                            <MenuItem value={defaultProjectId} sx={{ fontSize: 12 }}>Default Project</MenuItem>
                                            {projects.map((project) => (
                                                <MenuItem key={project.id} value={project.id} sx={{ fontSize: 12 }}>
                                                    {project.application_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                            </>
                        )}
                    {[
                        "ProjectManager",
                        "ProjectQALead",
                        "ProjectAdministrator",
                        "ProjectOfficer",
                        "ProjectSupport",
                        "ProjectBillingSpecialist",
                        "ProjectAuditor"
                    ].includes(role) && !project_id && (
                            <Typography sx={{ color: "red", marginLeft: 2, fontSize: 12 }}>
                                Select a project to enable the "Add" button.
                            </Typography>
                        )}
                </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button sx={{ color: "black", textTransform: "none" }} onClick={(e) => handleCloseDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                    onClick={(e) => handleSubmit(e)}
                    disabled={
                        [
                            "ProjectManager",
                            "ProjectQALead",
                            "ProjectAdministrator",
                            "ProjectOfficer",
                            "ProjectSupport",
                            "ProjectBillingSpecialist",
                            "ProjectAuditor"
                        ].includes(role) && project_id ? false : true
                    }
                    startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {loading ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateUserRoleDialog