import { Box, Grid, IconButton, InputAdornment, TextField, Typography, Button } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import useWidth from "../hooks/useWidth";
import CachedIcon from '@mui/icons-material/Cached';

import EmailAuthenticationListComponent from "../components/email-authentication/email-authentication-list.component";
import { useEffect } from "react";
import { useState } from "react";
import useEmailAuthentication from "../hooks/useEmailAuthentication";

import AddEmailSenderDialogComponent from "../components/email-authentication/email-authentication-dialog/add-email-sender-dialog.component";
import ForbiddenScreen from "./ForbiddenScreen";



function EmailAuthenticationScreen() {

    const breakpoints = useWidth();
    const { authenticationList, EmailAUthenticationList, isLoading, searchByEmail, isSearchEmail, searchPagination, error, pagination } = useEmailAuthentication();
    const [searchInput, setSearchInput] = useState('');
    const [openAddEmailSenderDialog, setOpenAddEmailSenderDialog] = useState(false)
    const [page, setPage] = useState(1)
    const [searchKeyPress, setSearchKeyPress] = useState(null)

    const searchItems = (e) => {
        setSearchInput(e.target.value)
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            if(searchInput){
                searchByEmail(e.target.value, page)
            } else {
                EmailAUthenticationList(page)
            }
            setSearchKeyPress(e.target.value)
        } else {
            setSearchInput(null)
            setSearchKeyPress(null)
        }
    }

   
    const reload = () => {
        setSearchInput('');
        // setPage(1)
        EmailAUthenticationList(page)
    }

    const handleAddEmailSender = () => {
        setOpenAddEmailSenderDialog(true)
    }

    const handleChangePagination = (event, page) => {
        setPage(page)
        EmailAUthenticationList(page)
    }

    const handleChangeSearchEmailPagination = (event, page) => {
        setPage(page)
        searchByEmail(searchInput, page)
    }

    const handleCloseAddEmailSender = () => { setOpenAddEmailSenderDialog(false) }

    const onCreateEmailSender = () => {
        EmailAUthenticationList(page)
    }

    useEffect(() => {
        EmailAUthenticationList(page)
    }, [page])


    return (
        <Box sx={{ mb: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {} }}>
            {error ?
                <Grid container spacing={3} sx={{ textAlign: "start", mb: 2 }}>
                    <Grid item xs={12} ><Typography variant="h6">Email Sender</Typography></Grid>
                    <Grid item xs={12} ><ForbiddenScreen /></Grid>
                </Grid>
            :
                <>
                    <Grid container spacing={3} sx={{ textAlign: "start", mb: 2 }}>
                        <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="h6">
                                Email Sender
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: "end" }}>

                            <TextField
                                variant="outlined"
                                autoComplete="off"
                                sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                placeholder="Search Email..."
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                                }}
                                value={searchInput}
                                onChange={searchItems}
                                onKeyDown={keyPress}
                            />
                            &nbsp;
                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                        </Grid>

                    </Grid>
                    <EmailAuthenticationListComponent isSearchEmail={isSearchEmail} searchInput={searchInput} handleChangePagination={handleChangePagination} page={page} searchKeyPress={searchKeyPress}
                        searchPagination={searchPagination} breakpoints={breakpoints} isLoading={isLoading} authenticationList={authenticationList} pagination={pagination} handleChangeSearchEmailPagination={handleChangeSearchEmailPagination} />
                </>
            }

            {openAddEmailSenderDialog &&
                <AddEmailSenderDialogComponent
                    hasBusinessAndProjectIds={false}
                    openAddEmailSenderDialog={openAddEmailSenderDialog}
                    handleClose={handleCloseAddEmailSender}
                    onCreateEmailSender={onCreateEmailSender}
                />
            }
        </Box>
    )
}
export default EmailAuthenticationScreen