import React, {useState, useEffect} from "react";
import {Box, Button, Grid} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import useWidth from "../../../hooks/useWidth";
import useB2CAccount from "../../../hooks/useB2cAccount";
import useUsers from "../../../hooks/useUsers";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";
import { log } from "../../../console-config.log";
import { convertUrlToFile } from "../../../utils/convertUrlToFileUtil";
import useTemplates from "../../../hooks/useTemplates";

const ChangeElementSignatureByUploadComponent = (props) => {

    const {handleClose, setIsSignatureUpdatedToParent, setNewSignatureSendToParent, setUserSignatureUpdated, currentSelectedUserId, currentESignatureB2CId, onSelectUser} = props;

    const breakpoints = useWidth();
    const {enqueueSnackbar} = useSnackbar();
    const {isUploadingSignature, updateSignature, uploadedSignature, setUploadingSignature} = useB2CAccount();
    const {isFetchingUser, getUserByID, userDetails} = useUsers()

    const [modifiedImage, setModifiedImage] = useState(null);
    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);

    useEffect(() => {
        if (uploadedSignature) {
            console.log("uploadedSignature aa", uploadedSignature);
            setUploadingSignature(true)
            getUserByID(currentESignatureB2CId)
        }
    }, [uploadedSignature])

    useEffect(() => {
        if (userDetails) {
            setUploadingSignature(false)
            setUserSignatureUpdated({isUpdated: true, data: userDetails.signature})
            onSelectUser(userDetails)
            handleClose()
        }
    }, [userDetails])
    
    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            setSignatureFile(file);
            setSignaturePreview(URL.createObjectURL(file));

        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleResetFile = () => {
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
        
            reader.onload = (event) => {
                const image = new Image();
                image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
        
                // Draw the image on the canvas
                ctx.drawImage(image, 0, 0);
        
                // Get the image data
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;
        
                // Remove the background (assuming light background and dark signature lines)
                const tolerance = 30; // Adjust this value to set the tolerance for background removal
                for (let i = 0; i < data.length; i += 4) {
                    const red = data[i];
                    const green = data[i + 1];
                    const blue = data[i + 2];
                    const alpha = data[i + 3];
                
                    // Check if the pixel color is close to red, green, yellow, blue, or white
                    if (
                        // Red
                        (red >= 200 && green <= 100 && blue <= 100) ||
                        // Green
                        (red <= 100 && green >= 150 && blue <= 100) || // Adjusted the green range
                        // Blue
                        (red <= 100 && green <= 100 && blue >= 200) ||
                        // Yellow
                        (red >= 200 && green >= 200 && blue <= 100) ||
                        // White
                        (red >= 200 && green >= 200 && blue >= 200)
                    ) {
                        data[i + 3] = 0; // Set alpha to 0 (transparent)
                    }
                }


        
                // Put the modified image data back to the canvas
                ctx.putImageData(imageData, 0, 0);
        
                // Convert the canvas content back to base64 string
                const modifiedImage = canvas.toDataURL();
        
                resolve(modifiedImage);
                };
        
                image.src = event.target.result;
            };
        
            reader.readAsDataURL(imageFile);
        });
    };

    const handleUploadSignature = (e) => {
        console.log("signatureFile 111", signatureFile);

        removeWhiteBackground(signatureFile)
        .then((modifiedImage) => {
            // Use the modified image as needed (e.g., display or upload)
            log(modifiedImage);
            setModifiedImage(modifiedImage); // Store the modified image in state
            convertUrlToFile(modifiedImage, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {
                console.log("editedSignatureFile nnn", editedSignatureFile);
            
                // let formData = new FormData();

                // formData.append("signature", editedSignatureFile);
                // window.location.reload();
                // updateSignature(formData, handleClose, setUserSignatureUpdated);
                updateSignature(editedSignatureFile, currentSelectedUserId);
            });

        })
        .catch((error) => {
            log(error);
        });
        
    }


    return (
        <Box sx={{padding: (signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "0px 0px 16px 0px" 
            : (signaturePreview && (breakpoints == 'md' || breakpoints == 'lg' ||  breakpoints == 'xl')) ? "0px 40px 16px 40px" 
            : (!signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "23px 0px 16px 0px" : "23px 40px 16px 40px", 
            mt: (signaturePreview) ? "-13px" : {}}}
        >
            {signaturePreview &&
                <Grid sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                        Clear
                    </Button>
                </Grid>
            }

            <Grid item xs={12}>
                {(signatureFile) ? 
                    <div 
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100% !important",
                            maxWidth: "100% !important",
                            border: "dashed 2px #0178f2",
                            borderRadius: "8px",
                            cursor: "pointer",
                            flexGrow: 0,
                            height: "210px",
                            justifyContent: "center",
                            margin: "auto",
                            transition: "border-color .15s linear",
                            objectFit:'contain'
                        }}
                    >
                        <img alt="signature-preview" src={signaturePreview} style={{height: (breakpoints == 'xs' || breakpoints == 'sm') ? 55 : 150, objectFit:'contain', width:'100%'}} />
                    </div>

                :
                    <FileUploader
                        hoverTitle=""
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Drag and Drop file here or click here to upload"
                        children={
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #0178f2",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "210px",
                                    justifyContent: "center",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                }}
                            >
                                <p style={{color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
                                    Drag and Drop file here or click here to upload
                                </p>
                            </div>
                        }
                    />
                }
            </Grid>

            <Box sx={{paddingTop: 6, textAlign: "end"}}>
                <Button onClick={handleClose} sx={{color: "#000", textTransform: "none", fontSize: 16}}>
                    Close
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 16, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={(e) => (isUploadingSignature) ? null : handleUploadSignature(e)}
                    startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                >
                    {isUploadingSignature ? "Saving" : "Save"} 
                </Button>
            </Box>
        </Box>
    )
}

export default ChangeElementSignatureByUploadComponent