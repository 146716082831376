import environment from "../../../../../environment/environment";
import routes from "../../../common/api-routes";
import {HttpGet, HttpPost, HttpDelete} from "../../../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getInheritanceList = (roleId) => {
    let query_params = {
        roleId: roleId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.ADMIN_LIST_INHERITANCE, query_params, subscriptionKey);
    return result;
}

const removeRoleInheritance = (parentRoleId, roleId) => {
    let query_params = {
        parentRoleId: parentRoleId,
        roleId: roleId
    };
    let result = HttpDelete(routes.ADMIN_REMOVE_ROLE_INHERITANCE, query_params);
    return result;
}

const addInheritance = (parent_role_id, role_id) => {
    let payload = {
        parent_role_id: parent_role_id,
        role_id: role_id
    };
    let result = HttpPost(routes.ADMIN_ADD_INHERITANCE, payload);
    return result;
}

const AdminRbacRolesManagementInheritanceService = {
    getInheritanceList: getInheritanceList,
    removeRoleInheritance: removeRoleInheritance,
    addInheritance: addInheritance
}

export default AdminRbacRolesManagementInheritanceService;