import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../hooks/useWidth"
import SettingsTabsComponent from "../components/settings/settings-tab.component";

const AdminSettingsScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box sx={{mb: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
            <Grid container spacing={3} sx={{textAlign: "start", mb: 2}}>
                <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6">
                       Admin Settings
                    </Typography>
                </Grid>
            </Grid>

            <SettingsTabsComponent />

        </Box>
    )


}

export default AdminSettingsScreen