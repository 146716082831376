import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';
import usePackageManagement from "../../../hooks/usePackageManagement";


const UpdateFeaturesQuotaDialog = (props) => {

    const { openUpdateFeatureQuota, handleClose, features, plan, getPackageSummary } = props;

    const breakpoints = useWidth();

    const {  updateFeatureQuota, updatingFeatureQuota, updatedFeatureQuota } = usePackageManagement();

    const [feature_id, setFeatureId] = useState(null);
    const [quota, setQuota] = useState('');

    const handleTextFieldChange = (e) => {
        setQuota(e.target.value);
    }

    const handleChangeFeature = (event) => {
        setFeatureId(event.target.value);
    }


    const handleUpdateFeatureQuota = () => {

        console.log("feature_idxx", feature_id)

        let payload = {
            quota: quota
        }

        updateFeatureQuota(feature_id, payload);
    }


    useEffect(() => {

        if(updatedFeatureQuota){
            handleClose()
            // window.location.reload();
            getPackageSummary();
        }

    },[updatedFeatureQuota])
    

    

    return (
        <Dialog open={openUpdateFeatureQuota} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Update Feature Quota
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={feature_id}
                                onChange={handleChangeFeature}
                            >
                                {features && features.map((data, index) => { 
                                    return(
                                        <MenuItem value={data?.package_record_id}>
                                            {data?.feat?.feature_title}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Quota :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            type="number"
                            value={quota}
                            onChange={(e) => handleTextFieldChange(e)}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleUpdateFeatureQuota() }
                     startIcon={updatingFeatureQuota && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {updatingFeatureQuota ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateFeaturesQuotaDialog