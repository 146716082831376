import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useEffect} from "react";
import useTemplates from "../../../hooks/useTemplates";

const DeleteTemplatePermanentlyDialog = (props) => {

    const {openDeleteTemplate, handleClose, template, setIsDeleted, deleteTemplatePermanently, deleting, deleted, setDeleted, setDeleting} = props;

    const handleDeleteTemplatePermanently = (template_code) => {
        deleteTemplatePermanently(template_code)
    }

    useEffect(() => {
        if (deleted){
            console.log("deleted aaaa", deleted);
            // setIsDeleted(true);
            setDeleted(false)
            handleClose();
        }
    }, [deleted])


    return(
        <Dialog open={openDeleteTemplate} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Delete Permanently Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete permanently <strong>{template?.template_name}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleClose}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={() => handleDeleteTemplatePermanently(template?.template_code)} 
                    startIcon={deleting && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {deleting ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteTemplatePermanentlyDialog