import React, { useEffect, useState } from "react";
import { Card, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        const darkValue = Math.min(128, value);
        
        color += `00${darkValue.toString(16)}`.slice(-2);
    }

    return color;
}
      
function stringAvatar(name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()}`,
    };
}

const DashboardAuthenticationCardComponent = (props) => {

    const { breakpoints, authentication } = props;

    const navigate = useNavigate();

    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
        if (authentication && authentication?.updated_time) {
            const timestamp = authentication?.updated_time;
            const dateObject = new Date(timestamp);
            const formattedDate = dateObject.toISOString().slice(0, 10);

            setFormattedDate(formattedDate);
        }
    }, [authentication]);

    console.log("authentication here aaa", authentication)


    return (
        <Card sx={{mt: 1.5, mb: 1.5, cursor: "default"}}
            // onClick={(e) => navigate("/view/authentication?id=" + authentication?.project_id + "&authRef=" + authentication?.reference + "&proj=" + authentication?.project_name)}
        >
            <ListItem>
                {(authentication && authentication?.project_name) ?
                    (
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(authentication && authentication?.project_name)} style={{ height: 50, width: 50 }} />
                        </ListItemAvatar>
                    )

                    :
                    (
                        <ListItemAvatar>
                            <Avatar {...stringAvatar("Not Applicable")} style={{height: 50, width: 50}} />
                        </ListItemAvatar>
                    )
                }

                <ListItemText
                    primary={
                        <Typography sx={{ color: "#0178f2", fontSize: 12 }}>
                            {authentication?.reference}
                        </Typography>
                    }
                    secondary={
                        <Typography sx={{color: "rgb(0 0 0 / 51%)", fontSize: 14}}>
                            {authentication?.project_name}
                        </Typography>
                    }
                />
            </ListItem>

            <Typography sx={{fontSize: 14, color: "rgba(0,0,0,.3)", textAlign: "end", padding: "0px 8px 8px", mt: -2}}>
                {formattedDate}
            </Typography>
        </Card>
    )
}

export default DashboardAuthenticationCardComponent