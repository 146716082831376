import { Avatar, Box, Button, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../hooks/useBusiness";
import ReactSelect from 'react-select';
import useReports from "../../hooks/useReports";
import ReportOneSkeletonComponent from "../skeletons/report-one-skeleton-component";
import useProjects from "../../hooks/useProjects";
import ReportTwoSkeletonComponent from "../skeletons/report-two-skeleton.component";
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";


function decrypt(text, key) {
    text = atob(text);
    let result = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        result += String.fromCharCode(charCode);
    }
    return result;
}


const ReportTwoTableHeader = () => {

    const tablecell = [
        { label: 'Date' },
        { label: 'No. of Authentication' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportTwoTableContent = (props) => {

    const { data, breakpoints } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" >
                {format(new Date(data && data?.created_day), 'PP')}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data && data?.total_authenticated_count}
            </TableCell>

        </TableRow>
    )
}


const ReportsTwoComponent = (props) => {

    const projectIdFromURL = (new URLSearchParams(window.location.search)).get("projectId");
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("businessId");
    const formattedDateRange = (new URLSearchParams(window.location.search)).get("data");
    const { selectedProject, selected_business } = props;
    
    const { loading, generateReportTwo, reportDataTwo, total_authentication } = useReports();


    const decryptedFormattedDateRange = decrypt(formattedDateRange, 'ovcode');

    // Function to format the date
    const formatDate = (dateString) => {
        const options = { month: 'long', day: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

   
    // Function to parse and format the date range
    const formatRange = (decryptedFormattedDateRange) => {
        console.log("Decrypted formatted date range:", decryptedFormattedDateRange);
        let startDateString, endDateString;

        if (decryptedFormattedDateRange.includes('-')) {
            // If the range is in the format "April 01-30, 2024"
            const [startDatePart, endDatePart] = decryptedFormattedDateRange.split('-').map(part => part.trim());

            console.log(endDatePart);

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' }); // Get the full month name

            console.log(startDatePart, endDatePart);

            // startDateString = startDatePart + ', ' + new Date().getFullYear();

            const [startDateMonth, startDateDay, startDateYear] = startDatePart.split(/[ ,]+/);
            console.log("startDateYear", startDateYear)
            if (!startDateYear) {
                startDateString = startDatePart + ', ' + new Date().getFullYear();
            } else {
                startDateString = startDatePart;
            }

            const [endDateDay, endDateYear] = endDatePart.split(',').map(part => part.trim());
            if (endDatePart && endDatePart.includes(startDateMonth)) {
                endDateString = endDateDay + ', ' + endDateYear;
            } else {
                endDateString = currentMonth + ' ' + endDateDay + ', ' + endDateYear;
            }

        } else {
            // If the range is in the format "April 01, 2023 - April 30, 2024"
            const parts = decryptedFormattedDateRange.split('-').map(part => part.trim());
            startDateString = parts[0];
            endDateString = parts[1];
        }


        console.log("Start Date String:", startDateString);
        console.log("End Date String:", endDateString);

        return { formattedStartDate: startDateString, formattedEndDate: endDateString };
    };


    const { formattedStartDate, formattedEndDate } = formatRange(decryptedFormattedDateRange);

    console.log("Formatted report Start Date:", formattedStartDate);
    console.log("Formatted report End Date:", formattedEndDate);


    useEffect(() => {
        if (businessIdFromURL && projectIdFromURL && decryptedFormattedDateRange) {

            console.log("decryptedFormattedDateRange here", decryptedFormattedDateRange)

            let date_from = dayjs(formattedStartDate).format("YYYY-MM-DD");
            let date_to = dayjs(formattedEndDate).format("YYYY-MM-DD");

            console.log("date_fromxxx", date_from)
            console.log("date_toxxx", date_to)

            generateReportTwo(businessIdFromURL, projectIdFromURL, date_from, date_to);
        }
    }, [businessIdFromURL, projectIdFromURL, decryptedFormattedDateRange]);


    const generatePDF = () => {
        const doc = new jsPDF();


        const business = selectedProject && selectedProject


        const title = `${business} Report as of ${decryptedFormattedDateRange}`;

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth) / 100;
        const titleY = 10;

        doc.text(title, titleX, titleY);

        const columns = ["Date", "No. of Authentication"];
        const data = reportDataTwo.map(item => [item?.created_day, item?.total_authenticated_count]);

        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20
        });
        doc.setFontSize(12);

        const overallTotal = total_authentication ? total_authentication : 0;

        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 12;
        const textX = pageWidth - textWidth - 1;
        const textY = doc.autoTable.previous.finalY + 10;

        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

        doc.save(`Document-Report.pdf`);
    };


    return (
        <Box>
            <Grid container sx={{ position: 'relative', zIndex: 2 }}>
                <Grid item xs={12} sm={3} sx={{ textAlign: 'center', maxWidth: '15% !important', flexBasis: '2% !important', marginLeft: 'auto' }}>
                    <Button
                        variant="contained"
                        onClick={generatePDF}
                        sx={{
                            width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                            ":hover": { background: "#0178f2" }
                        }}
                    >
                        Download
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                            <ReportTwoTableHeader />

                            {loading ? (
                                <ReportTwoSkeletonComponent />
                            ) : (
                                <TableBody>
                                    {(reportDataTwo && reportDataTwo)?.map((data, index) => (
                                        <ReportTwoTableContent key={index} data={data} />
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                        {!loading && (reportDataTwo?.length === 0 || reportDataTwo === undefined) && (
                            <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                                {selectedProject && selected_business && (
                                    <Typography>
                                        <b>{selectedProject}</b> in <b>{selected_business}</b> has no available authentications for {decryptedFormattedDateRange}.
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </TableContainer>
                </Grid>
                <br />
                <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReportsTwoComponent;