import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const BackButton = () => {

    const navigate = useNavigate();

    const url = window.location.pathname;

    console.log("pathname here :" , url)

    return(
        <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
            style={{color: "black", fontSize: 16, textTransform: 'none', fontWeight : 600  }}
            onClick={() => navigate(-1)} >
            Back
        </Button>
    )
}

export default BackButton