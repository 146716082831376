import { Box, Button, Card, IconButton, Grid, Container, ListItem, ListItemText, Typography, Divider, InputAdornment, TextField} from "@mui/material"
import Avatar from "react-avatar"
import logo from "../../icons/logo1.jpg"
import EditIcon from '../../icons/edit.png'
import useWidth from "../../hooks/useWidth"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from "react"
import DeleteAppConfigDialog from "./dialog/delete-app-config-dialog.component"
import CreateAppConfigDialog from "./dialog/create-app-config-dialog.component"
import UpdateAppConfigDialog from "./dialog/update-app-config-dialog.component"

function AppConfigurationDetailsComponent (props) {

    const {isDeleting, deleteAppConfig, appConfigDetails, updateAppConfig} = props
    const breakpoints = useWidth()
    const [showApiKey, setApiKey] = useState(false);
    const [showSubKey, setSubKey] = useState(false);
    const [openDeleteAppConfig, setOpenDeleteAppConfig] = useState(false)
    

    const handleClose = () => {setOpenDeleteAppConfig(false)}

    const handleOpenDialog = () =>{setOpenDeleteAppConfig(true)}

    const handleClickShowAPIKey = (e) => { setApiKey(!showApiKey)}
    const handleClickShowSubKey = (e) => { setSubKey(!showSubKey)}

    const BusinessAccountStaticDetails = [
        {"label" : "Business Name:", value: appConfigDetails?.businessAccount?.business_name ? appConfigDetails?.businessAccount?.business_name : "NA"},
        {"label" : "Description:", value: appConfigDetails?.businessAccount?.description ? appConfigDetails?.businessAccount?.description : "NA"},
        {"label" : "Website:", value: appConfigDetails?.businessAccount?.website ? appConfigDetails?.businessAccount?.website : "NA"},
    ]

    const AppConfigStaticDetails = [
        {"label" : "App Code:", value: appConfigDetails?.app_code ? appConfigDetails?.app_code : "NA"},
        {"label" : "App Name:", value: appConfigDetails?.app_name ? appConfigDetails?.app_name: "NA"},
        {"label" : "API Endpoint:", value: appConfigDetails?.api_endpoint ? appConfigDetails?.api_endpoint : "NA"},
    ]

    

    return(
        <Grid container>
            <Grid item xs={12} sm={3}>
                {BusinessAccountStaticDetails?.map((label)=>{
                    return(
                        <ListItem>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                        {label.label}
                                    </Typography>
                                } 
                            />
                        </ListItem>
                    )
                })}
            </Grid>

            <Grid item xs={9} sm={9}>
                {BusinessAccountStaticDetails?.map((label)=>{
                    return(
                        <ListItem>
                        
                            <ListItemText
                                primary={
                                    <Typography sx={{fontSize: 14}}>
                                        {label.value}
                                    </Typography>
                                } 
                               
                            />
                            
                        </ListItem>
                    )
                })}
            </Grid>

            <Grid item xs={12} sm={3}>
                {AppConfigStaticDetails?.map((label)=>{
                    return(
                        <ListItem>
                        
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                        {label.label}
                                    </Typography>
                                } 
                               
                            />
                            
                        </ListItem>
                    )
                })}
            </Grid>

            <Grid item xs={12} sm={9}>
                {AppConfigStaticDetails?.map((label)=>{
                    return(
                        <ListItem>
                        
                            <ListItemText
                                primary={
                                    <Typography sx={{fontSize: 14}}>
                                        {label.value}
                                    </Typography>
                                } 
                               
                            />
                            
                        </ListItem>
                    )
                })}
            </Grid>
            <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                Subscription Key:
                            </Typography>
                        } 
                        
                    />
                </ListItem>
            </Grid>

            <Grid item xs={12} sm={9}>
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography sx={{fontSize: 14}}>
                               <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    value={appConfigDetails?.subscription_key}
                                    placeholder="Subscription Key"
                                    type={(showSubKey) ? "text" : "password"}
                                    InputProps={{endAdornment: (
                                        <InputAdornment position="end" style={{marginLeft: -29}}>
                                            <IconButton onClick={(e) => handleClickShowSubKey(e)} aria-label="toggle password visibility" edge="end">
                                                {(showSubKey) ? 
                                                    <Visibility fontSize="small" /> 
                                                :
                                                    <VisibilityOff fontSize="small" />
                                                } 
                                            </IconButton>
                                        </InputAdornment>
                                    )}} 
                                />
                            </Typography>
                        } 
                        
                    />
                </ListItem>
            </Grid>

            <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                API Key:
                            </Typography>
                        } 
                        
                    />
                </ListItem>
            </Grid>

            <Grid item xs={12} sm={9}>
                <ListItem>
                    <ListItemText
                        primary={
                            <Typography sx={{fontSize: 14}}>
                               <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    value={appConfigDetails?.api_secret_key}
                                    placeholder="API Key"
                                    type={(showApiKey) ? "text" : "password"}
                                    InputProps={{endAdornment: (
                                        <InputAdornment position="end" style={{marginLeft: -29}}>
                                            <IconButton onClick={(e) => handleClickShowAPIKey(e)} aria-label="toggle password visibility" edge="end">
                                                {(showApiKey) ? 
                                                    <Visibility fontSize="small" /> 
                                                :
                                                    <VisibilityOff fontSize="small" />
                                                } 
                                            </IconButton>
                                        </InputAdornment>
                                    )}} 
                                />
                            </Typography>
                        } 
                        
                    />
                </ListItem>
            </Grid>

            <Grid item xs={12} sx={{textAlign:"end", marginTop: 2}}>
                <Button variant="outlined" color="error" onClick={handleOpenDialog}>Delete</Button>
            </Grid>

            

            {/* DELETE DIALOG */}
            <DeleteAppConfigDialog isDeleting={isDeleting} list={appConfigDetails} openDeleteAppConfig={openDeleteAppConfig} deleteAppConfig={deleteAppConfig} handleClose={handleClose}/>
        </Grid>
    )
}

export default AppConfigurationDetailsComponent