import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../../common/custom-tabs.component.jsx";
import ChangeSignatureByLink from "../../account-profile/change-e-signature/change-signature-by-link.component.jsx";
import ChangeSignatureByUploadComponent from "../../account-profile/change-e-signature/change-signature-by-upload.component.jsx";
import ChangeSignatureByTypeComponent from "../../account-profile/change-e-signature/change-signature-by-type.component.jsx";
import ChangeSignatureByDrawComponent from "../../account-profile/change-e-signature/change-signature-by-draw.component.jsx";
import ChangeElementSignatureByDrawComponent from "./change-element-signature-by-draw.component.jsx";
import ChangeElementSignatureByUploadComponent from "./change-element-signature-by-upload.component.jsx";
import ChangeElementSignatureByTypeComponent from "./change-element-signature-by-type.component.jsx";
import ChangeElementSignatureByLink from "./change-element-signature-by-link.component.jsx";

function useSignatureTabs(props){

  const {handleClose, sendDataToParent, setIsSignatureUpdatedToParent, setNewSignatureSendToParent, setUserSignatureUpdated, currentSelectedUserId, currentESignatureB2CId, 
    onSelectUser} = props;

  const [ signature_tabs, setSignatureTabs ] = useState(null);

  function setupTabs(props) {

    var tabs_default = [
      // {name: 'Draw', component:  
      //   <ChangeElementSignatureByDrawComponent
      //     handleClose={handleClose} 
      //     sendDataToParent={sendDataToParent} 
      //     setIsSignatureUpdatedToParent={setIsSignatureUpdatedToParent}
      //     setNewSignatureSendToParent={setNewSignatureSendToParent}
      //     setUserSignatureUpdated={setUserSignatureUpdated}
      //     currentUser={currentUser}
      //   />
      // },
      {name: 'Upload', component: 
        <ChangeElementSignatureByUploadComponent
          handleClose={handleClose} 
          setIsSignatureUpdatedToParent={setIsSignatureUpdatedToParent}
          setNewSignatureSendToParent={setNewSignatureSendToParent}
          setUserSignatureUpdated={setUserSignatureUpdated}
          currentSelectedUserId={currentSelectedUserId}
          currentESignatureB2CId={currentESignatureB2CId}
          onSelectUser={onSelectUser}
        />
      },
      {name: 'Type', component: 
        <ChangeElementSignatureByTypeComponent
          handleClose={handleClose} 
          setIsSignatureUpdatedToParent={setIsSignatureUpdatedToParent}
          setNewSignatureSendToParent={setNewSignatureSendToParent}
          setUserSignatureUpdated={setUserSignatureUpdated}
          currentSelectedUserId={currentSelectedUserId}
          currentESignatureB2CId={currentESignatureB2CId}
          onSelectUser={onSelectUser}
        />
      },
      // {name: 'Link', component: 
      //   <ChangeElementSignatureByLink
      //     handleClose={handleClose}
      //     setIsSignatureUpdatedToParent={setIsSignatureUpdatedToParent}
      //     setNewSignatureSendToParent={setNewSignatureSendToParent}
      //     setUserSignatureUpdated={setUserSignatureUpdated}
      //     currentUser={currentUser}
      //   />
      // },
    ]

    setSignatureTabs(tabs_default);
  }

  useEffect(() => { 
    setupTabs(
      props
      ); 
  }, [])

  return signature_tabs;

}


function ChangeElementSignatureTabs(props){

  const {breakpoints, sendTabLabelToParent, setIsSignatureUpdatedToParent, setNewSignatureSendToParent, setUserSignatureUpdated} = props;

  const tabs = useSignatureTabs(props);

  const [ value, setValue ] = useState(0);

  function TabPanel(props) {
      
      const {children, value, index, ...other} = props;
    
      return (
        <div
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue){
    setValue(newValue);

    if (newValue == 3) {
      sendTabLabelToParent(true);
    } else {
      sendTabLabelToParent(false);
    }
  }

  return(
    <Box sx={{width: '100%'}}>
        <Box>
          <CustomTabs
            variant={(breakpoints == 'xs' || breakpoints == 'sm') ? "scrollable" : ""}
            value={value} 
            onChange={handleChange} 
            sx={{marginTop: 2}} 
            TabIndicatorProps={{sx: {top: "25px !important", height: "4px !important"}}}
            centered
            >
              { tabs?.map((tab, index)=>{
                return(<CustomTab label={tab.name} disableRipple {...a11yProps(index)}   />)
              }) }
          </CustomTabs>
          { tabs?.map((tab, index)=>{
            return(
              <TabPanel value={value} index={index} style={{padding: "0px"}}>
                {tab.component}
              </TabPanel> 
            ) 
          })}
        </Box>
    </Box>
  )
}

export default ChangeElementSignatureTabs;