import { Box, Button, Grid, List, ListItem, ListItemText, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useWidth from '../hooks/useWidth';
import useAudit from '../hooks/useAudit';
import SignInLogsSkeletonComponent from '../components/skeletons/sign-in-logs-skeleton.component';
import SignInLogsComponent from '../components/sign-in-logs/sign-in-logs.component';
import useUsers from '../hooks/useUsers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';





function TableHeader() {

    const tablecell = [
        // {label : 'User ID'},
        { label: 'Email' },
        { label: 'Device Details' },
        { label: 'Location' },
        { label: 'Coordinates' }
    ];

    return (
        <TableHead >
            <TableRow >
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const SigninLogsContent = (props) => {

    const { data } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left">
                {data?.userPrincipalName}
            </TableCell>

            <TableCell align="left">
                <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                    <ListItem>
                        <ListItemText primary={<React.Fragment>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 14 }}>
                                {data?.deviceDetail.operatingSystem}
                            </Typography>
                        </React.Fragment>}
                            secondary={<React.Fragment>
                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 14 }}>
                                    {data?.deviceDetail.browser}
                                </Typography>
                            </React.Fragment>} />
                    </ListItem>
                </List>
            </TableCell>

            <TableCell align="left">
                {data?.location.city} , {data?.location.countryOrRegion}
            </TableCell>

            <TableCell align="left">
                <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                    <ListItem>
                        <ListItemText primary={<React.Fragment>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 14 }}>
                                Lat: {data?.location.geoCoordinates.latitude}
                            </Typography>
                        </React.Fragment>}
                            secondary={<React.Fragment>
                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 14 }}>
                                    Long: {data?.location.geoCoordinates.longitude}
                                </Typography>
                            </React.Fragment>} />
                    </ListItem>
                </List>
            </TableCell>

        </TableRow>
    )
}


const UserSignInLogsScreen = () => {

    const breakpoints = useWidth();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    let user_id = (new URLSearchParams(window.location.search)).get("user_id");

    const { loading, retrieveUserSigninLogs, logs, pagination, setLoading, retrieveUserSigninLogsPagination } = useAudit();


    useEffect(() => {
        if(user_id){
            retrieveUserSigninLogs(user_id);
        }
    }, [user_id])


    const handleChangePagination = (event, page) => {
        setPage(page);
        setLoading(true);
        retrieveUserSigninLogsPagination(user_id);
    }


    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Box sx={{ padding: 1, width: '100%', borderRadius: 0 }}>
                <Grid container sx={{ textAlign: 'start', marginLeft: 2 }}>
                    <Grid item xs={12} >
                    <Button
                        startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
                        style={{ color: '#000', fontSize: 18, textTransform: 'none', fontWeight: 600 }}
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                        <Typography variant='h6'>User Sign in Logs</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={{ padding: 3 }}>
                <TableContainer component={Paper} sx={{ border: '1px solid #8080807a', borderRadius: 2, width: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader />

                        {loading ?
                            <TableBody>
                                <SignInLogsSkeletonComponent />
                            </TableBody>

                            :
                            <TableBody >
                                {logs?.map((logs, index) => {
                                    return (
                                        <SigninLogsContent data={logs} index={index} breakpoints={breakpoints} />
                                    )
                                })}
                            </TableBody>
                        }

                    </Table>

                    {!loading && logs?.length === 0 &&
                        <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                            <Grid container sx={{ padding: 3 }}>
                                <Box sx={{ textAlign: "center", margin: "auto" }}>
                                    <Typography>
                                        No available signin log(s) to display yet.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    }

                    {!loading && logs?.length !== 0 &&
                        <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                        />
                    }

                </TableContainer>
            </Grid>

        </Box>
    )
}

export default UserSignInLogsScreen