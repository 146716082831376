import {useState} from "react";
import {Box} from "@mui/material";
import AdminAccessControlComponent from "./admin-access-control.component";
import AdminViewAccessControlScreen from "./admin-view-access-control-screen.component";

const AdminAccessControlManagementScreen = () => {

    const [isViewAccessControl, setViewAccessControl] = useState(false);
    const [roleId, setRoleId] = useState(null);
    const [roleName, setRoleName] = useState("");

    return (
        <Box sx={{width: "97%", margin: "auto"}}>
            <Box sx={{mt: 3}}>
                {isViewAccessControl ? 
                    <AdminViewAccessControlScreen 
                        roleId={roleId} 
                        roleName={roleName} 
                        setViewAccessControl={setViewAccessControl}
                    />

                :   
                    <AdminAccessControlComponent
                        setRoleId={setRoleId} 
                        setRoleName={setRoleName} 
                        setViewAccessControl={setViewAccessControl}
                    />
                }
            </Box>
        </Box>
    )
}

export default AdminAccessControlManagementScreen