import React, {useEffect, useState} from "react";
import {Skeleton, Box, ListItem, ListItemText, Dialog, DialogContent, DialogTitle, Typography, Grid, ButtonGroup, Button, Divider, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ThreeDots} from "react-loader-spinner";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useCreditsManagement from "../../../hooks/useCreditsManagement";
import useBusiness from "../../../hooks/useBusiness";

const ManageProjectCreditsDialog = (props) => {

    const {breakpoints, openManageCredits, setOpenManageCredits, project, projectCreditState, setProjectCreditState} = props;

    let id = (new URLSearchParams(window.location.search)).get("id");

    
    const { getBusinessById, businessDetails } = useBusiness();
    const {isAddProjectCredits, isSubProjectCredits, addProjectCredits, subProjectCredits,
        creditsAdded, creditsDeducted, addedCredits, deductedCredits, businessCredits, viewBusinessCredits
    } = useCreditsManagement();

    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [subCreditAmount, setSubCreditAmount] = useState(0);
    const [isAddBtnClicked, setAddBtnClicked] = useState(false);
    const [isDeductBtnClicked, setDeductBtnClicked] = useState(false);

    const [creditState, setCreditState] = useState(0);


    useEffect(() => {
        if(id){
            getBusinessById(id);
            viewBusinessCredits(id);
        }
    }, [id])

    useEffect(() => {
        if (projectCreditState) {
            setCreditState(projectCreditState);
        }
    }, [projectCreditState])

    useEffect(() => {
        if (creditsAdded) {
            setCreditState(addedCredits);
            setProjectCreditState(addedCredits);
        }
    }, [creditsAdded])

    useEffect(() => {
        if (creditsDeducted) {
            setCreditState(deductedCredits);
            setProjectCreditState(deductedCredits);
        }
    }, [creditsDeducted])

    
    const handleAddClicked = (e) => {
        setAddBtnClicked(true);
    }

    const handleDeductClicked = (e) => {
        setDeductBtnClicked(true);
    }

    const handleCloseDialog = (e) => {
        setOpenManageCredits(false);
    }

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }

    const handleSubChange = (e) => {
        if (e.target.value < 0) {
            setSubCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setSubCreditAmount(1000000);

        } else {
            setSubCreditAmount(e.target.value);
        }
    }

    const handleAddCredits = async (e) => {
        let payload = {
            business_id: project?.business_account_id,
            project_id: project?.id,
            credit: addCreditAmount
        }

        await addProjectCredits(payload, setAddBtnClicked, setAddCreditAmount);
    }

    const handleDeductCredits = async (e) => {
        let businessId = project?.business_account_id;
        let projectId = project?.id;
        let credit = subCreditAmount;

        await subProjectCredits(businessId, projectId, credit, setDeductBtnClicked, setSubCreditAmount);
    }

    const handleCancelAddCredits = (e) => {
        setAddBtnClicked(false);
        setAddCreditAmount(0);
    }

    const handleCancelSubCredits = (e) => {
        setDeductBtnClicked(false);
        setSubCreditAmount(0);
    }


    return (
        <Dialog open={openManageCredits} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}>
            <DialogTitle sx={{ display: "flex", fontWeight: "bold", fontSize: "0.875rem", padding: "16px 24px 0px 24px"}}>
                Manage {project?.application_name} Credits

                <Box sx={{ml: "auto"}}>
                    <CloseIcon size="small" onClick={(e) => handleCloseDialog(e)}
                        style={{fontSize: 18, color: "#808080a8", marginTop: "0px", cursor: "pointer"}}
                    />
                </Box>
            </DialogTitle>

            <ListItem sx={{padding: "10px 24px 0px 24px", mb: "-10px"}}>
                <ListItemText sx={{display: "block", justifyContent: "center", textAlign: "center"}}
                    primary={
                        <Typography sx={{color: "#0178f2", fontSize: "18px"}}>
                            {creditState}
                        </Typography>
                    }
                    secondary={
                        <Typography sx={{ color: "rgb(0 0 0 / 51%)", fontSize: "0.875rem" }}>
                            OVI Credits
                        </Typography>
                    }
                />
            </ListItem>

            <DialogContent sx={{ padding: "0px", mt: 2 }}>
                <Grid item xs={12} sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : "16px 24px 26px", mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{textAlign: "center"}}>
                            {(isAddBtnClicked && !isDeductBtnClicked) ?
                                <>
                                    <TextField
                                        size="small"
                                        type="number"
                                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        InputProps={{
                                            inputProps: {
                                                max: 1000000, min: 0
                                            }
                                        }}
                                        value={addCreditAmount}
                                        onChange={(e) => handleAddChange(e)}
                                        sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                                    />

                                    <ButtonGroup variant="text" aria-label="text button group">
                                        <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                            onClick={(e) => ((addCreditAmount == 0) || isAddProjectCredits) ? null : handleAddCredits(e)}
                                            startIcon={isAddProjectCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                        >
                                            Save
                                        </Button>

                                        <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                                        <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                            onClick={(e) => handleCancelAddCredits(e)}
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                </>

                            :
                                (!isAddBtnClicked && isDeductBtnClicked) ?
                                    <>
                                        <TextField
                                            size="small"
                                            type="number"
                                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                            InputProps={{
                                                inputProps: {
                                                    max: 1000000, min: 0
                                                }
                                            }}
                                            value={subCreditAmount}
                                            onChange={(e) => handleSubChange(e)}
                                            sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                                        />

                                        <ButtonGroup variant="text" aria-label="text button group">
                                            <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                                onClick={(e) => ((subCreditAmount == 0) ||isSubProjectCredits) ? null : handleDeductCredits(e)}
                                                startIcon={isSubProjectCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                            >
                                                Save
                                            </Button>

                                            <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                                            <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                                onClick={(e) => handleCancelSubCredits(e)}
                                            >
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </>

                            :
                                <>
                                        <Button variant="outlined" sx={{
                                            width: "auto", borderRadius: 1, textTransform: "none",
                                            backgroundColor: "#eaf0ff", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                                        }}
                                            onClick={(e) => handleAddClicked(e)}
                                            startIcon={<AddIcon />}
                                        >
                                            Add Credits
                                        </Button>

                                        &nbsp; &nbsp; &nbsp;

                                        <Button variant="outlined" sx={{
                                            width: "auto", textTransform: "none", borderRadius: 1,
                                            backgroundColor: "#eaf0ff", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                                        }}
                                            onClick={(e) => handleDeductClicked(e)}
                                            startIcon={<RemoveIcon />}
                                        >
                                            Deduct Credits
                                        </Button>
                                </>
                            }
                        </Grid>
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Typography sx={{ fontWeight: "400", fontSize: "0.875rem" , textAlign:'center'}}>
                                Remaining {businessDetails?.business_name} Credits : {businessCredits}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ManageProjectCreditsDialog
