import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import WhiteLabelConfigListComponent from "./white-label-config-list.component";
import SecretsListComponent from "./secrets-list.component";
import BusinessAppListComponent from "./business-app-list.component";
import LearnMoreDialog from "./dialog/learn-more-dialog.component";
import useWidth from "../../hooks/useWidth";
import AccessControlManagementScreen from "../../screens/AccessControlManagementsScreen";
import PermissionManagementScreen from "../../screens/PermissionManagementScreen";
import RolesManagementScreen from "../../screens/RolesManagementScreen";
import CreditsManagementScreen from "../../screens/CreditsManagementScreen";
import PackageManagementScreen from "../../screens/PackageManagementScreen";

function useSettingsTab(props) {

  const [settings_tab, setSettingsTab] = useState(null);
  const currentYear = new Date().getFullYear()

  function setupTabs() {

    var tabs_default = [
      { name: 'Credits', component: <CreditsManagementScreen /> },
      { name: 'Plans', component: <PackageManagementScreen /> },
      { name: 'Roles', component: <RolesManagementScreen /> },
      { name: 'Permission', component: <PermissionManagementScreen /> },
      { name: 'Access Control', component: <AccessControlManagementScreen /> },
      { name: 'Business Application', component: <BusinessAppListComponent /> },
      { name: 'App Middleware', component: <WhiteLabelConfigListComponent /> },
      { name: 'Confi', component: <SecretsListComponent /> },
    ]

    setSettingsTab(tabs_default);
  }

  useEffect(() => { setupTabs(); }, [])

  return settings_tab;

}


function AppConfigurationTabsComponent(props) {

  const tabs = useSettingsTab();

  const breakpoints = useWidth();

  const storedTab = localStorage.getItem('selectedTab');
  const initialTabValue = storedTab ? parseInt(storedTab) : 0;
  const [value, setValue] = useState(initialTabValue);

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedTab');
    };
  }, []);

  function TabPanel(props) {

    const { children, value, index, ...other } = props;


    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  // function handleChange(event, newValue) {
  //   setValue(newValue);
  // }
  function handleChange(event, newValue) {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue); // Store selected tab index
  }
  

  function isAppMiddlewareTabSelected() {
    return value === 6; // 'App Middleware' tab index
  }

  const [openDialog, setOpenDialog] = useState(false)


  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ marginTop: 2 }}
          indicatorColor="transparent"
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })
          }
        </CustomTabs>
        {isAppMiddlewareTabSelected() &&
          <Box sx={{ mr: 0}}>
            <Button
              variant="text"
              sx={{
                fontSize: 12,
                p: '4px 5px',
                borderRadius: 10,
                boxShadow: 'none',
                color: '#0178f2',
                textTransform: 'none',
              }}
              onClick={handleOpenDialog}
            >
              Learn More
            </Button>
          </Box>
        }
      </Box>


      {tabs?.map((tab, index) => {
        return (
          <TabPanel value={value} index={index} style={{ padding: "0px" }}>
            {tab.component}
          </TabPanel>
        )
      })
      }

      {openDialog &&
        <LearnMoreDialog openDialog={openDialog} handleClose={handleClose} breakpoints={breakpoints} />
      }
    </Box>
  )
}

export default AppConfigurationTabsComponent;