import { Divider, ListItem, ListItemText, Skeleton, Button } from "@mui/material"

function ListSkeletonComponent(){
    return(
        <>
            <ListItem disableRipple  button>
                <ListItemText primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} 
                    primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>}
                />
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary={<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '50%'}}/>} />
                <Button>{<Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: '100%'}}/>}</Button>
            </ListItem>
            <Divider />
        </>
    )
}
export default ListSkeletonComponent