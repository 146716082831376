import {Skeleton,  Card, CardContent, Grid, ButtonGroup, Button} from "@mui/material";


function AuthenticationSkeletonComponent() {

    const authentication_details = [
        {label : 'Reference' },
        {label : 'Project'  },
        {label : 'Last Date Modified'},
        {label : 'Pipeline'  },
        {label : 'Authentication Hash' },
        {label : 'File URL'  },
        {label : 'CID'  },
        {label : 'Token ID'  }
    ]

    const authentication_error = [
        {label : 'Error Type'},
        {label : 'Error Message'},
    ]
    

    return(

        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <Card sx={{borderRadius: 2, padding: '8px 24px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: 490}} >
                    <CardContent>
                            <Grid itemm xs={12}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid>
                                {authentication_details.map((auth) => {
                                    return(
                                        <Grid container spacing={2} sx={{mb: 1, pt: 3}}>
                                            <Grid item xs={12} sm={4} sx={{alignSelf: 'center'}}>
                                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                                            </Grid>
                                        </Grid> 
                                    )
                                })}
                    </CardContent>
                </Card> 
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card sx={{borderRadius: 2, padding: '8px 24px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: 490}} >
                    <CardContent>
                                {authentication_error.map((err) => {
                                    return(
                                        <Grid container spacing={2} sx={{mb: 1, pt: 3}}>
                                            <Grid item xs={12} sm={4} sx={{alignSelf: 'center'}}>
                                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                                            </Grid>
                                        </Grid> 
                                    )
                                })}
                     </CardContent>
                </Card>
            </Grid>
        </Grid>
        
    )
    
}

export default AuthenticationSkeletonComponent