import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useEffect} from "react";
import useAnalytics from "../../hooks/useAnalytics";

const DeleteAuthenticationErrorDialog = (props) => {

    const {openDeleteAuthenticationError, handleClose, projectId, reference, setDeleted, transaction, businessId} = props;

    console.log("testxxxxxx")

    const { deleting, deleteTransaction, deleted } = useAnalytics();


    const deleteAuthentication = () => {
        deleteTransaction(businessId, projectId, reference)
    }

    useEffect(() => {
        if (deleted){
            setDeleted(true);
            handleClose();
        }
    }, [deleted])

    return(
        <Dialog open={openDeleteAuthenticationError} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, zIndex: 5}}}>
            <DialogTitle  sx={{color:'red'}}>
                Delete Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete <strong>{transaction?.reference}</strong> under <strong>{(transaction?.project_name) ? transaction?.project_name : "N/A"}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleClose}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "red", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", '&:hover': {backgroundColor: "red", color: "white"}}}
                    onClick={() => deleteAuthentication()} 
                    startIcon={deleting && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {deleting ? "Deleting" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAuthenticationErrorDialog