import { log } from "../../../console-config.log";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPostMultipart, CustomHttpPostMultipart} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const uploadSignature = (file, userId) => {

    let formData = new FormData();
    formData.append("signature", file);
    formData.append("userId", userId);

    log("createProject payload xxxx", formData);

    let result = HttpPostMultipart(routes.UPLOAD_SIGNATURE, formData);
    
    return result;
}

const uploadSignatureFromAndroid = (formData, accessToken) => {

    let payload = formData;

    log("uploadSignatureFromAndroid payload xxxx", payload, accessToken);

    let result = CustomHttpPostMultipart(routes.UPDATE_ESIGNATURE, payload, accessToken);
    
    return result;
}

const getAccountProfile = () => {

    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_MY_PROFILE, query_params, subscriptionKey);
    
    return result;
}

const getAccess = (module) => {

    let query_params = {
        module: module
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ACCESS, query_params, subscriptionKey);
    
    return result;
}

const B2CAccountService = {
    
    uploadSignature: uploadSignature,
    getAccountProfile: getAccountProfile,
    uploadSignatureFromAndroid: uploadSignatureFromAndroid,
    getAccess: getAccess
}

export default B2CAccountService