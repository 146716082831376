import { log } from "../../../console-config.log";
import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpDelete1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const retrieveAutoRevoke = (projectId) => {

    let query_params = {
        projectId: projectId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_AUTO_REVOKE, query_params, subscriptionKey);
    
    return result;
}

const configAutoRevoke = (projectId, revokeDuration) => {

    let payload = {
        project_id: projectId,
        revoke_duration: revokeDuration
    }

    console.log("configAutoRevoke payload xxxx", payload);

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpPost(routes.CONFIG_AUTO_REVOKE, payload, subscriptionKey);
    
    return result;
}

const deleteAutoRevoke = (projectId) => {

    let query_params = {
        projectId: projectId
    }

    console.log("deleteAutoRevoke query_params xxxx", query_params);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete1(routes.DELETE_AUTO_REVOKE, query_params, subscriptionKey);

    return result;
}



const OVCodeAutoRevokedService = {
    retrieveAutoRevoke,
    configAutoRevoke,
    deleteAutoRevoke
}

export default OVCodeAutoRevokedService