import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const changeProduct = (id, payload) => {

    let result = HttpPost(routes.CHANGE_PRODUCT  + "?id=" + id, payload, SUBSCRIPTION_KEY);

    return result;
}

const getProducts = () => {

    let parameters = "";

    let result = HttpGet(routes.GET_PRODUCTS, parameters, SUBSCRIPTION_KEY);

    return result;
}

const APIMService = {
    changeProduct: changeProduct,
    getProducts: getProducts
}

export default APIMService