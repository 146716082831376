import {useEffect, useState } from "react";
import {Pagination, Typography, Stack, Grid} from "@mui/material"
import {Masonry} from '@mui/lab';
import usePermissionManagement from "../hooks/usePermissionManagement";
import PermissionsComponent from "../components/rbac/permissions.component";
import PermissionListSkeleton from "../components/skeletons/permission-list-skeleton.component";
import ReactSelect from "react-select";

const PermissionsScreen = (props) => {

    const { setView, setPermissionId, setModuleData, isLoading, modules, pagination, isSearchingPermission, searchedPermission, isSearchPermissionToggle, isSearchedResultsEmpty } = props;

 
    const [page, setPage] = useState(1);
    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState("permission");
    const [moduleOptions, setModuleOptions] = useState([]);
    const [moduleFilterValue, setModuleFilterValue] = useState(null);
    const [moduleFilterLabel, setModuleFilterLabel] = useState(null);

    useEffect(() => {
        props.getModules();
    }, [])

    useEffect(() => {
        getModuleOptions();
    }, [JSON.stringify(modules)])

    useEffect(() => {
        if (filter && moduleFilterValue) {
            props.searchPermissions(filter, moduleFilterValue, page);
        } 
    }, [moduleFilterValue])


    const getModuleOptions = () => {
        if (modules) {
            const module_options = [...modules?.map((module) => ({
                value: module?.module_name,
                label: module?.module_name,
            }))];

            setModuleOptions(module_options);
        }
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999, 
        }),
        control: (styles) => ({
            ...styles, 
            backgroundColor: "#fff", 
            minHeight: "20px", 
            width: 250, 
            fontSize: 13,
        })
    }

    const filterOptions = [
        {value: "permission", label: "Module"},
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setModuleFilterValue(null);
        setModuleFilterLabel(null);

        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {    
            setFilter(null);
            setFilterLabel(null);
            
            handleClearFilter();
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        props.getModules();
    }

    const handleSelectModuleValue = (selectedOption) => {
        if (selectedOption) {
            setModuleFilterValue(selectedOption.value);
            setModuleFilterLabel(selectedOption.label);

        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && moduleFilterValue) {
            props.searchPermissions(filter, moduleFilterValue, page);
        }
    }


    return (
        <>
            <Grid item xs={6} sm={6} sx={{mt: "-38px", mb: 1}}>
                <Stack direction="row" spacing={1} sx={{width: "100%"}}>
                    <ReactSelect
                        className="basic-single"
                        placeholder={<>
                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                Filter
                            </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                {
                                    (filterLabel === "Module") ? 
                                    <Typography sx={{color: "#000",  fontSize: 12}}>
                                        Module
                                    </Typography>
                                :
                                    "Select Filter"
                                                   
                                }</i>
                        </>}
                        isSearchable={true}
                        isRtl={false}
                        options={filterOptions}
                        onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 38,
                                borderRadius: 100,
                                backgroundColor: 'transparent',
                                width: 250,
                                fontSize: 12
                            }),
                            placeholder: base => ({
                                ...base,
                                fontSize: 12
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                fontSize: 12
                            }),
                        }}
                        isClearable={true}
                    />

                    {(filterLabel === "Module") &&
                        <ReactSelect
                            className="basic-single"
                            placeholder={
                                (filterLabel === "Module" && moduleFilterLabel) ? 
                                    <Typography sx={{color: "#000",  fontSize: 12}}>
                                        {moduleFilterLabel}
                                    </Typography>
                            :
                                "Search Module"
                            }
                            isSearchable={true}
                            isRtl={false}
                            options={moduleOptions}
                            value={moduleFilterValue}
                            onChange={selectedOption => handleSelectModuleValue(selectedOption)}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 38,
                                    borderRadius: 100,
                                    backgroundColor: 'transparent',
                                    width: 250,
                                    fontSize: 12
                                }),
                                placeholder: base => ({
                                    ...base,
                                    fontSize: 12
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 99999,
                                    fontSize: 12
                                }),
                            }}
                        />
                    } 
                </Stack>
            </Grid>

            {(isLoading || isSearchingPermission) ?
                <PermissionListSkeleton />

            :
                (isSearchPermissionToggle && !isSearchedResultsEmpty) ?
                    <Masonry columns={{xs: 1, sm: 3}} spacing={2} sx={{margin: "auto", width: "100%", padding: 0}}>
                        {searchedPermission && searchedPermission?.data.map((data, index) => ( 
                            <Grid container key={index}>
                                <Grid item xs={12}>
                                    <PermissionsComponent 
                                        data={data} 
                                        setView={setView} 
                                        setPermissionId={setPermissionId} 
                                        setModuleData={setModuleData}
                                        retrieving={isLoading}
                                        getModules={props.getModules}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Masonry>
            :        
                (isSearchPermissionToggle && isSearchedResultsEmpty) ?
                    <Typography variant="subtitle2" sx={{fontSize: 14, textAlign: "center", fontWeight: 500}}>
                        No searched permission found.
                    </Typography>

            : 
                <Masonry columns={{ xs: 1, sm: 3 }} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0 }}>
                    {modules && modules?.map((data, index) => (
                        <Grid container key={index}>
                            <Grid item xs={12}>
                                <PermissionsComponent 
                                    data={data} 
                                    setView={setView} 
                                    setPermissionId={setPermissionId} 
                                    setModuleData={setModuleData}
                                    retrieving={isLoading}
                                    getModules={props.getModules}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Masonry>
            }

            {/* pagination for search permission */}
            {(isSearchPermissionToggle && !isSearchedResultsEmpty && !isLoading) && 
                <Pagination
                    count={pagination} 
                    page={page} 
                    onChange={handleChangePaginationForSearch} 
                    style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                />
            }
        </>
    )
}

export default PermissionsScreen