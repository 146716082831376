const isDevelopment = process.env.NODE_ENV === 'development';

export function log(label, data) {
  if (isDevelopment) {
    if (data !== undefined) {
      console.log(`${label}:`, data);
    } else {
      console.log(label);
    }
  }
}