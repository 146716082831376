import { Grid, Typography, Skeleton } from "@mui/material"


function ProjectDetailsSkeletonComponent () {

    let items = [];

    for (let i = 0; i < 1; i++) {
        items.push(
            <Grid item xs={12} sm={8}>
                <Typography sx={{width:'100%', padding: "10px 0px 10px 0px", textAlign:'justify', wordBreak: 'break-word', fontSize: 14}}>
                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                </Typography>
            </Grid>
        )
    }

    return(
       <>{items}</>
    )
}
export default ProjectDetailsSkeletonComponent