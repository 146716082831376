import { Box, Typography } from "@mui/material"
import ReportsComponent from "../components/reports/reports.component"
import { useState } from "react"
import ForbiddenScreen from "./ForbiddenScreen";
import ReportsMainTabsComponent from "../components/reports/reports-main-tab.component";

const ReportsScreen = () => {

    const [forbidden, setForbidden] = useState(false);

    return(
        <Box>
            {forbidden ?
                <ForbiddenScreen />
            :
                <>
                    <Box sx={{ paddingBottom: 2, display:'flex', alignItems:'center'}}>
                        <Typography variant="h6" sx={{fontWeight: 600, marginRight: 2}}>
                            OVCODE Report 
                        </Typography>
                    </Box>

                    {/* <ReportsComponent setForbidden={setForbidden} /> */}

                    <ReportsMainTabsComponent setForbidden={setForbidden} />
                </>
            }
            

        </Box>
    )

}

export default ReportsScreen