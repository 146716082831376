import { Avatar, Card, CardContent, ClickAwayListener, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckIcon from '@mui/icons-material/Check';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UpdatePackageDialog from "./dialogs/update-package.dialog";
import DeletePackageDialog from "./dialogs/delete-package.dialog";
import AddFeaturesQuotaDialog from "./dialogs/add-features-quota.dialog";
import UpdateFeaturesQuotaDialog from "./dialogs/update-feature-quota.dialog";
import DeleteFeaturesQuotaDialog from "./dialogs/delete-feature-quota.dialog";
import OVCodeLogo from "../../icons/ovc.png";


const cardStyle = {
    margin: '8px',
    transition: 'transform 0.3s, box-shadow 0.3s, border-color 0.3s',
    border: '1px solid transparent',
    position: 'relative',
    borderRadius: "8px"
  }
  
  const cardContentStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "2px 16px 0px 16px"
  }


const PackageActionPopper = (props) => {

    const { plan, setDeleted, getPackageSummary } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdatePackage, setOpenUpdatePackage] = useState(false);
    const [openDeletePackage, setOpenDeletePackage] = useState(false);

    const handleOpenUpdatePackage = () => {
        setOpenUpdatePackage(true);
    }

    const handleClosenUpdatePackage = () => {
        setOpenUpdatePackage(false);
    }

    const handleOpenDeletePackage = () => {
        setOpenDeletePackage(true);
    }

    const handleClosenDeletePackage = () => {
        setOpenDeletePackage(false);
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreHorizIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleOpenUpdatePackage} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update Package</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeletePackage}   style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Delete Package</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

            {openUpdatePackage &&
                <UpdatePackageDialog 
                    openUpdatePackage={openUpdatePackage}
                    handleClose={handleClosenUpdatePackage}
                    plan={plan}
                    getPackageSummary={getPackageSummary}
                />
            }

            {openDeletePackage &&
                <DeletePackageDialog
                    openDeletePackage={openDeletePackage}
                    handleClose={handleClosenDeletePackage}
                    plan={plan}
                    setDeleted={setDeleted}
                />
            }

        </Stack>
    )
}


const PackageSettingCardComponent = (props) => {


        const { index, features, plan, isLoading, breakpoints, getPackageSummary  } = props;

        
        const [deleted, setDeleted] = useState(false);
        const [openAddFeatureQuota, setOpenAddFeatureQuota] = useState(false);
        const [openUpdateFeatureQuota, setOpenUpdateFeatureQuota] = useState(false);
        const [openRemoveFeatureQuota, setOpenRemoveFeatureQuota] = useState(false);
    
        const handleOpenAddFeatureQuota = () => {
            setOpenAddFeatureQuota(true);
        }
    
        const handleCloseAddFeatureQuota = () => {
            setOpenAddFeatureQuota(false);
        }
    
        const handleOpenUpdateFeatureQuota = () => {
            setOpenUpdateFeatureQuota(true);
        }
    
        const handleCloseUpdateFeatureQuota = () => {
            setOpenUpdateFeatureQuota(false);
        }
    
        const handleOpenRemoveFeatureQuota = () => {
            setOpenRemoveFeatureQuota(true);
        }
    
        const handleCloseRemoveFeatureQuota = () => {
            setOpenRemoveFeatureQuota(false);
        }
    
        return (
            <>
                {!deleted &&
                    <Card
                        key={index}
                        style={{
                            ...cardStyle,
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            textAlign: 'center',
                           
                        }}
                    >
                        <Grid container  sx={{ maxHeight: '100px', minHeight: '100px', height: '100px'}}>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <PackageActionPopper plan={plan} setDeleted={setDeleted} getPackageSummary={getPackageSummary} />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Typography variant="h6">{plan?.package_name}</Typography>
                                <Typography variant="body2">{plan?.description}</Typography> */}
                                <ListItem sx={{ 
                                    padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 16px" : "0px 16px 10px 16px"}}
                                    >
                                    <ListItemAvatar sx={{minWidth: "40px"}}>
                                        <Avatar src={OVCodeLogo} style={{height: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 30, width: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 30}} sx={{".MuiAvatar-img": {objectFit: "contain"}}} />
                                    </ListItemAvatar>
                                            
                                    <ListItemText sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 0}}
                                        primary={
                                        <Typography sx={{fontSize: 16, fontWeight: "bold"}}>
                                            {plan?.package_name}
                                        </Typography>
                                        }
                                        secondary={
                                        <Typography sx={{fontSize: 14}}>
                                            {plan?.description}
                                        </Typography>
                                        }
                                    />
                                    </ListItem>
                            </Grid>
                        </Grid>
    
                        <CardContent style={cardContentStyle} sx={{mt: 2}}>
    
                            <Grid container sx={{ padding: '1px' }}>
                                <Grid item xs={12}>
                                    <Grid container sx={{ padding: '0px' }}>
                                        <Grid item xs={7} sx={{textAlign: 'start', pl: 1, alignSelf: 'center'}}>
                                            <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>Features</Typography>
                                        </Grid>
                                        <Grid item xs={5} >
                                            <Grid container sx={{ pr: '1px' }}>
                                                <Grid item xs={4} sx={{textAlign: 'center'}} >
                                                    <IconButton sx={{ color: '#0178f2', minWidth: 0,  cursor: 'pointer', padding: '4px'}} onClick={handleOpenAddFeatureQuota}>
                                                        <AddBoxIcon fontSize="small" />
                                                    </IconButton>
                                                    
                                                </Grid>
                                                <Grid item xs={4} sx={{textAlign: 'center'}}>
                                                    <IconButton sx={{ color: '#0178f2', minWidth: 0, cursor: 'pointer', padding: '4px' }}  onClick={handleOpenUpdateFeatureQuota}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={4} sx={{textAlign: 'center'}}>
                                                    <IconButton  sx={{ color: 'red', minWidth: 0, cursor: 'pointer', padding: '4px' }} onClick={handleOpenRemoveFeatureQuota} >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            
    
                            <div style={{ marginTop: '20px' }}>
                                {features && features.length > 0 ? (
                                    <>
                                        {features?.map((feature, featureIndex) => (
                                            <List key={featureIndex}>
                                                <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <CheckIcon style={{color: "#0178f2"}} />
                                                </ListItemIcon>

                                                <ListItemText sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? -1 : "-20px"}} 
                                                    primary={
                                                    <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 14, color: "rgb(0 0 0 / 57%)"}}>
                                                        {feature?.quota} &nbsp;
                                                        {feature?.feat?.description}
                                                    </Typography>
                                                    }
                                                />
                                                </ListItem>
                                            </List>
                                        ))}

                                    </>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={12} sx={{mb: 1}}>
                                            <Typography sx={{textAlign: 'center', fontSize: 14}}>
                                                No features available.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                                   
    
                        </CardContent>
                    </Card>
                }
    
                {openAddFeatureQuota &&
                    <AddFeaturesQuotaDialog 
                        openAddFeatureQuota={openAddFeatureQuota}
                        handleClose={handleCloseAddFeatureQuota}
                        plan={plan}
                        getPackageSummary={getPackageSummary}

                    />
                }
    
                {openUpdateFeatureQuota &&
                    <UpdateFeaturesQuotaDialog 
                        openUpdateFeatureQuota={openUpdateFeatureQuota}
                        handleClose={handleCloseUpdateFeatureQuota}
                        plan={plan}
                        features={features}
                        getPackageSummary={getPackageSummary}
                    />
                }
    
                {openRemoveFeatureQuota &&
                    <DeleteFeaturesQuotaDialog 
                        openRemoveFeatureQuota={openRemoveFeatureQuota}
                        handleClose={handleCloseRemoveFeatureQuota}
                        plan={plan}
                        features={features}
                        getPackageSummary={getPackageSummary}

                    />
                }
    
            </>
    )
}

export default PackageSettingCardComponent