import environment from "../../../../environment/environment";
import routes from "../../common/api-routes";
import { HttpGet, HttpPost, HttpDelete } from "../../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getSignatoryWhitelistEmails = (projectId, page, limit, businessId) => {
    let query_params = {
        projectId: projectId,
        page: page,
        limit: limit,
        businessId: businessId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_SIGNATORY_WHITELIST_EMAILS, query_params, subscriptionKey);
    return result;
}

const viewSignatoryWhitelistEmails = (id, projectId, businessId) => {
    let query_params = {
        id: id,
        projectId: projectId,
        businessId: businessId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_SIGNATORY_WHITELIST_EMAIL, query_params, subscriptionKey);
    return result;
}

const deleteSignatoryWhitelistEmails = (id) => {
    let query_params = {
        id: id
    };
    let result = HttpDelete(routes.DELETE_SIGNATORY_WHITELIST_EMAIL, query_params);
    return result;
}

const createSignatoryWhitelistEmails = (project_id, business_id, email_addresses) => {
    let payload = {
        project_id: project_id,
        business_id: business_id,
        email_addresses: email_addresses
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_SIGNATORY_WHITELIST_EMAIL, payload, subscriptionKey);
    return result;
}

const EsignProjectWhitelistEmailsService = {
    getSignatoryWhitelistEmails: getSignatoryWhitelistEmails,
    viewSignatoryWhitelistEmails: viewSignatoryWhitelistEmails,
    deleteSignatoryWhitelistEmails: deleteSignatoryWhitelistEmails,
    createSignatoryWhitelistEmails: createSignatoryWhitelistEmails
}

export default EsignProjectWhitelistEmailsService;