import {Grid, TextField, Stack, InputAdornment, Box, ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Pagination, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useRolesManagement from "../../hooks/useRolesManagement";
import AccessControlListSkeleton from "../skeletons/access-control-list-skeleton.component";
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";

function PermissionActionPopper () {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update Access</Typography>
                                    </MenuItem>
                                    <MenuItem  style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Remove Access</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

        </Stack>
    )
}

const TableHeader = () => {

    const tablecell = [ 
        {label : 'Role Name'},
        {label : 'Description'},
        // {label : 'Action'}
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#0178f2'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const PermissionContent = (props) => {
    
    const { data, setView, setRoleId, setRoleName } = props;

    return(
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell component="th" scope="row" sx={{cursor:'pointer',color: "#0178f2"}} onClick={() => [setView(true), setRoleId(data?.id), setRoleName(data?.role)] } >
                {data?.role}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.description}
            </TableCell>
            {/* <TableCell>
                <PermissionActionPopper />
            </TableCell> */}
        </TableRow>
    )
}


const AccessControlComponent = (props) => {

    const { setView, setRoleId, setRoleName, setError } = props;

    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles, isFetchingRoles, error,
        enterpriseRoleList, searchRoles, isSearchingRoles, searchedRoles, isSearchRolesToggle, isSearchedResultsEmpty
    } = useRolesManagement();

    const [page, setPage] = useState(1);

    // const uniqueRoles = new Set(accessControl.map(access => access?.role?.role));
    // const numUniqueRoles = uniqueRoles.size;

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState(null);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [roleFilterValue, setRoleFilterValue] = useState(null);
    const [descriptionFilterValue, setDescriptionFilterValue] = useState("");

    useEffect(() => {
        getAllRoles();
    },[])

    useEffect(() => {

        if(error){
            setError(error);
        }

    }, [error])

    useEffect(() => {
        if(retrieved){
            getRoles(page);
        }
    },[retrieved, page])

    useEffect(() => {
        getRolesOptions();
    }, [JSON.stringify(enterpriseRoleList)])

    useEffect(() => {
        if (filter && roleFilterValue) {
            searchRoles(filter, roleFilterValue, page);
        } 
    }, [roleFilterValue])

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999, 
        }),
        control: (styles) => ({
            ...styles, 
            backgroundColor: "#fff", 
            minHeight: "20px", 
            width: 250, 
            fontSize: 13,
        })
    }

    const getRolesOptions = () => {
        if (enterpriseRoleList) {
            const role_options = [...enterpriseRoleList?.map((role) => ({
                value: role?.role,
                label: role?.role,
            }))];

            setRolesOptions(role_options);
        }
    }

    const filterOptions = [
        {value: "role_name", label: "Role"},
        {value: "description", label: "Description"},
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setRoleFilterValue(null);
        setDescriptionFilterValue("");

        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {    
            setFilter(null);
            setFilterLabel(null);
            
            handleClearFilter();
        }
    }

    const handleSelectRolesValue = (selectedOption) => {
        if (selectedOption) {
            setRoleFilterValue(selectedOption.value);
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        getAllRoles(page);
    }

    const handleTextfield = (e) => {
        setRoleFilterValue(null);
        setDescriptionFilterValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (filter && descriptionFilterValue) {
                searchRoles(filter, descriptionFilterValue, page);
            }  
        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && roleFilterValue) {
            searchRoles(filter, roleFilterValue, page);

        } else if (filter && descriptionFilterValue) {
            searchRoles(filter, descriptionFilterValue, page);
        }
    }

    const handleChangePagination = (event,page) => {
        setPage(page);
        getRoles(page);
    }


    return (

        <Box sx={{width: '100%', margin: 'auto'}}>
            <Grid item xs={6} sm={6} sx={{mt: "-32px"}}>
                <Stack direction="row" spacing={1} sx={{width: "100%"}}>
                    <ReactSelect
                        className="basic-single"
                        placeholder={<>
                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                Filter
                            </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                {
                                   (filterLabel === "Role") ? 
                                   <Typography sx={{color: "#000",  fontSize: 12}}>
                                       Role
                                   </Typography>
                               :
                                   (filterLabel === "Status") ? 
                                       <Typography sx={{color: "#000",  fontSize: 12}}>
                                           Status
                                       </Typography>
                               :
                                   (filterLabel === "Description") ? 
                                       <Typography sx={{color: "#000",  fontSize: 12}}>
                                           Description
                                       </Typography>
                               :
                                   (filterLabel === null) ? 
                                       "Select Filter"
                               :  
                                   null
                                                   
                                }</i>
                        </>}
                        isSearchable={true}
                        isRtl={false}
                        options={filterOptions}
                        onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 38,
                                borderRadius: 100,
                                backgroundColor: 'transparent',
                                width: 250,
                                fontSize: 12
                            }),
                            placeholder: base => ({
                                ...base,
                                fontSize: 12
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                fontSize: 12
                            }),
                        }}
                        isClearable
                    />

                    {(filterLabel === null || filterLabel === "Role") &&
                        <ReactSelect
                            className="basic-single"
                            placeholder={
                                (filterLabel === "Role" && roleFilterValue) ? 
                                    <Typography sx={{color: "#000",  fontSize: 12}}>
                                        {roleFilterValue}
                                    </Typography>
                            :
                                "Search Role"
                            }
                            isSearchable={true}
                            isRtl={false}
                            options={rolesOptions}
                            value={roleFilterValue}
                            onChange={selectedOption => handleSelectRolesValue(selectedOption)}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 38,
                                    borderRadius: 100,
                                    backgroundColor: 'transparent',
                                    width: 250,
                                    fontSize: 12
                                }),
                                placeholder: base => ({
                                    ...base,
                                    fontSize: 12
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 99999,
                                    fontSize: 12
                                }),
                            }}
                        />
                    }

                    {filterLabel === "Description" &&
                        // <TextField
                        //     value={descriptionFilterValue}
                        //     sx={{"& .MuiInputBase-root": {height: 37},
                        //         background: "#fff", width: 250
                        //     }}
                        //     variant="outlined"
                        //     autoComplete="off"
                        //     placeholder="Input description..."
                        //     onChange={handleTextfield}
                        //     onKeyDown={handleKeyPress}
                        //     inputProps={{
                        //         style: {
                        //             minHeight: "4.9px",
                        //             height: "4.9px"
                        //         }
                        //     }}
                        //     InputProps={{
                        //         endAdornment: (
                        //             <InputAdornment position="end">
                        //                 <IconButton edge="end" sx={{cursor: "default"}}>
                        //                     <SearchIcon />
                        //                 </IconButton>
                        //             </InputAdornment>
                        //         ),
                        //     }}
                        // /> 
                        <TextField
                        value={descriptionFilterValue}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 380 } }}
                        variant="outlined"
                        autoComplete="off"
                        onChange={handleTextfield}
                        onKeyDown={handleKeyPress}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                        placeholder={"Input Description..."}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{'Description'}:</p></InputAdornment>,
                        }}
                    />
                    }
                </Stack>
            </Grid>

            <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%", mt: 2}}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 650}}>
                    <TableHeader />

                    {(isLoading || isSearchingRoles) ?
                        <TableBody><AccessControlListSkeleton /></TableBody>
                    
                    :
                        (isSearchRolesToggle && !isSearchedResultsEmpty) ?
                            <TableBody sx={{width: "100%"}}> 
                                {searchedRoles?.data.map((data, index) => ( 
                                    <PermissionContent 
                                        data={data?.role} 
                                        index={index} 
                                        setView={setView} 
                                        setRoleId={setRoleId} 
                                        setRoleName={setRoleName} 
                                    />
                                ))}
                            </TableBody>
                    :        
                        (isSearchRolesToggle && isSearchedResultsEmpty) ?
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography variant="subtitle2" sx={{fontSize: 14, textAlign: "center", fontWeight: 500}}>
                                        No searched result(s) found.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    : 
                        (!isSearchRolesToggle) && 
                            <TableBody sx={{width: "100%"}}> 
                                {rolesList.map((data, index) => (
                                    <PermissionContent 
                                        data={data} 
                                        index={index} 
                                        setView={setView} 
                                        setRoleId={setRoleId} 
                                        setRoleName={setRoleName} 
                                    />
                                ))}
                            </TableBody>
                    }
                   
                </Table>

                {/* {(accessControl?.length === 0 || accessControl === undefined) &&
                    <Box sx={{margin: 5, textAlign:'center', ml: 'auto', mr: 'auto', color: 'gray'}}>
                        <Typography>No data yet.</Typography>
                    </Box>
                } */}

                { !isLoading && rolesList?.length !== 0 && !isSearchingRoles && !isSearchRolesToggle && isFetchingRoles &&
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePagination} 
                        style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                }  

                {/* pagination for search access control */}
                {(isSearchRolesToggle && !isSearchedResultsEmpty && !isLoading && !isFetchingRoles) && 
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePaginationForSearch} 
                        style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                }
            </TableContainer>
        </Box>
    )
}

export default AccessControlComponent