import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import PlansManagementComponent from "../components/plans/plans-management.component"
import usePackageManagement from "../hooks/usePackageManagement";
import useWidth from "../hooks/useWidth";
import PackageSkeletonComponent from "../components/package-management/package-skeleton.component";
import PackageCardScreenComponent from "../components/package-management/package-card-screen.component";
import useCredits from "../hooks/useCredits";
import { ThreeDots } from 'react-loader-spinner';

import PackageManagementService from "../services/api/package-management";


const SelectPlanAndAddCreditsScreen = () => {


    const breakpoints = useWidth();

    const { isFetchingBusinessPackageFeatures, getBusinessPackageFeatures,
        getBusinessPackageResponse, isRetrievingPackageSummary, packageSummaryList, getPackageSummary,
    } = usePackageManagement();
    const { addBusinessCredits, addingBusinessCredits, businessCreditsAdded, totalCreditsAdded } = useCredits();

    const [modifiedPackageList, setModifiedPackageList] = useState([]);
    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [isAddBtnClicked, setAddBtnClicked] = useState(false);

    useEffect(() => {
        getPackageSummary();
    }, [])

    useEffect(() => {
        console.log("modifiedPackageList aa", modifiedPackageList);
    }, [modifiedPackageList])


    let id = (new URLSearchParams(window.location.search)).get("id");

    useEffect(() => {
        const fetchingBusinessPackageFeatures = (id) => {
            PackageManagementService.getBusinessPackageFeatures(id)
            .then((res) => {
                const businessPackageResponse = res.data;
                if (packageSummaryList) {
                    const modifiedPackageList = packageSummaryList.map((plan) => {
                        if (plan.package.id === businessPackageResponse.data.business_package.packageRecord.id) {
                            return {
                                ...plan,
                                features: businessPackageResponse.data.feature_quota,
                                is_current_plan: true
                            }

                        } else {
                            const originalPlan = packageSummaryList.find(original => original.package.id === plan.package.id);

                            if (originalPlan) {
                                return {
                                    ...plan,
                                    features: originalPlan.features,
                                    is_current_plan: false
                                }
                            }

                            return plan;
                        }
                    });

                    setModifiedPackageList(modifiedPackageList)
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error && packageSummaryList) {
                    setModifiedPackageList(packageSummaryList);
                }
            })
        }
        fetchingBusinessPackageFeatures(id)
    }, [JSON.stringify(packageSummaryList)])

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }


    const handleAddCredits = () => {

        let payload = {
            business_id: id,
            credit: addCreditAmount
        }

        addBusinessCredits(payload);
    }


    // useEffect(() => {
    //     if (businessCreditsAdded) {
    //         window.location.reload();
    //     }

    // }, [businessCreditsAdded])

    return (
        <Box sx={{overflow: 'hidden'}}>
            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 18 }}>
                        Select Enterprise Plan
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "rgb(0 0 0 / 45%)" }}>
                        Select a plan that works best for you and your team
                    </Typography>
                </Grid>
            </Grid>


            {(isRetrievingPackageSummary || isFetchingBusinessPackageFeatures) ?
                <Grid container spacing={2} sx={{ width: "95%", margin: "auto", mt: 2, mb: 1 }}>
                    {[0, 1, 2].map((index) => (
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                            <PackageSkeletonComponent breakpoints={breakpoints} />
                        </Grid>
                    ))}
                </Grid>
            :
                <Box sx={{ width: "90%", margin: 'auto', mt: 2, mb: 1 }}>
                    <PackageCardScreenComponent breakpoints={breakpoints}
                        modifiedPackageList={modifiedPackageList} selectedBusiness={id}
                    />
                </Box>
                
            }

            <Grid container>
                <Grid item xs={12} >
                    <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 18 }}>
                        Add Credits
                    </Typography>

                </Grid>
            </Grid>
            {isAddBtnClicked ?
                <Grid container spacing={2} sx={{ width: "95%", margin: "auto", mt: 2, mb: 1 }}>
                    <Grid item xs={12} sx={{ alignSelf: 'center' }}>
                        <Typography><b>Credits :</b> &nbsp;{totalCreditsAdded?.credit}</Typography>
                    </Grid>
                </Grid>
            :

                <Grid container spacing={2} sx={{ width: "95%", margin: "auto", mt: 2, mb: 1, alignSelf: 'center' }}>
                    <Grid item xs={12} sm={2} sx={{ alignSelf: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Credits :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} sx={{ alignSelf: 'center' , textAlign: 'start'}}>
                        <TextField
                            size="small"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            InputProps={{
                                inputProps: {
                                    max: 1000000, min: 0
                                }
                            }}
                            value={addCreditAmount}
                            onChange={(e) => handleAddChange(e)}
                            sx={{ "& .MuiInputBase-root": { height: 40, width: 300 } }}
                        />
                        &nbsp; &nbsp;
                        <Button variant="contained" sx={{ alignSelf: 'center', height: 40, width: 80, backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                            onClick={() => {
                                handleAddCredits();
                                setAddBtnClicked(true);
                            }}
                            startIcon={addingBusinessCredits &&
                                <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                            }
                        >
                            {addingBusinessCredits ? "Adding" : "Add"}
                        </Button>
                    </Grid>
                </Grid>
            }
            
            <Grid container spacing={2} sx={{ width: "95%", ml: "auto", mt: 2, mb: 0, textAlign: 'end', mr: 30 }}>
                <Grid item xs={12} >
                    <Button 
                    variant="contained" 
                    sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() =>  window.location.href = "/add-enterprise-user?id=" + id }
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SelectPlanAndAddCreditsScreen