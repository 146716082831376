import React, {useEffect} from "react";
import {ListItem, ListItemText, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Skeleton} from "@mui/material";
import useUsers from "../../../hooks/useUsers";
import {formatDate} from "../../../utils/dateFormatterUtil";
import { useNavigate } from "react-router-dom";

const ViewUserDialog = (props) => {

    const {breakpoints, openViewUser, setViewUser, userId, enterpriseName} = props;

    const url = window.location.pathname;
    const navigate = useNavigate();

    const {isFetchingUser, userDetails, getUserByID, viewAdminUser} = useUsers();

    useEffect(() => {
        if (userId) {
                if(url === "/admin-settings"){
                    viewAdminUser(userId);
                } else {
                    getUserByID(userId);
                }
        }
    }, [userId])

    const handleCloseDialog = (e) => {
        setViewUser(false);
    }

    const user_info = [
        {label: "ID", value: userDetails?.user_id},
        {label: "Email", value: userDetails?.user_principal_name ? userDetails?.user_principal_name : userDetails?.email_address},
        {label: "Role", value: userDetails?.user_role},
        {label: "Enterprise", value: enterpriseName ? enterpriseName : 'N/A'},
        {label: "Status", value: userDetails?.status},
        // {label: "Date Created", value: formatDate(userDetails?.created_date)},
        {label: "Last Date Modified", value: formatDate(userDetails?.updated_date)},
    ]


    return (
        <Dialog open={openViewUser} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                User Details
            </DialogTitle>

            <Divider/>
            
            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px 24px" : "20px 45px"}}>
                <Grid container spacing={2} sx={{mb: 2, alignItems: "center"}}>
                    <Grid item xs={12} sm={8}>
                        {(user_info?.map((details) => {
                            return (
                                <>
                                    <ListItem sx={{padding: "6px 1px"}}>
                                        <ListItemText sx={{display: "flex"}}
                                            primary={
                                                <Typography sx={{textTransform: "none", fontWeight: "bold"}}>
                                                    {details.label}:
                                                </Typography>
                                            }
                                            secondary={
                                                (isFetchingUser ?
                                                    <Skeleton variant="text" sx={{ml: "5px", width: (breakpoints == "xs" || breakpoints == "sm") ? "100px" : "220px", fontSize: "1rem", backgroundColor: "rgb(108 139 222 / 30%)"}} />

                                                :
                                                    <Typography sx={{textTransform: "none", ml: "5px", 
                                                        color: (details.label == "Status" && details.value == "1") ? "green" 
                                                    : 
                                                        (details.label == "Status" && details.value == "0") ? "red" 
                                                    :  
                                                        "inherit"}}
                                                    >
                                                        {(details.label == "Status" && details.value == "1") ? 
                                                            "ACTIVE"
                                                        : 
                                                            (details.label == "Status" && details.value == "0") ? "INACTIVE" 
                                                        :
                                                            details.value
                                                        }
                                                    </Typography>
                                                )
                                            }   
                                        />
                                    </ListItem>
                                </>
                            )
                        }))}
                        <br/>
                        <Button variant="text" 
                        sx={{fontWeight: 500, fontSize: 16, margin: 0, padding: 0}}
                        onClick={(e)  => navigate('/user-signin-logs?user_id=' + userDetails?.user_id)}
                        >
                            View Signin Logs
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{textAlign: "center"}}>
                        {isFetchingUser ?
                            <>
                                <Skeleton variant="rectangular" sx={{margin: "auto", height: (breakpoints == "xs" || breakpoints == "sm") ? "100px" : "100px", width: (breakpoints == "xs" || breakpoints == "sm") ? "140px" : "150px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                            </>
                        :
                            <>
                                {userDetails?.signature ?
                                    <img alt="" src={userDetails?.signature} style={{width: 100, height: 100, objectFit: "contain"}} />
                                :
                                    <Typography sx={{color: "rgba(0,0,0, 0.4)"}}>
                                        No available signature
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>  
                </Grid> 
            </DialogContent>
   
            <DialogActions sx={{padding: "8px 22px 20px 0px"}}>
                <Button variant="contained" sx={{width: "15%", borderRadius: 1, color: "#fff", bgcolor: "#0178f2", textTransform: "none", fontSize: 14}}
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewUserDialog