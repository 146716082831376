import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpDelete, HttpGet, HttpPost, HttpPut1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getPackages = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_PACKAGES, query_params, subscriptionKey);

    return result;
}

const viewPackage = (id) => {

    let query_params = {
        id: id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.VIEW_PACKAGE, query_params, subscriptionKey);

    return result;   
}

const createPackage = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_PACKAGE, payload , subscriptionKey);
  
    return result;
}

const updatePackage = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_PACKAGE, query_params, payload , subscriptionKey);


    return result;
    
}

const deletePackage = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_PACKAGE, query_params, subscriptionKey);
    
    return result;
}

const getFeatureList = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_LIST_OF_FEATURES, query_params, subscriptionKey);

    return result;
}

const createFeature = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_FEATURE, payload , subscriptionKey);
  
    return result;
}

const addFeatureQuota = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_FEATURE_QUOTA, payload , subscriptionKey);
  
    return result;
}


const updateFeatureQuota = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_FEATURE_QUOTA, query_params, payload , subscriptionKey);


    return result;
    
}


const deleteFeatureQuota = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_FEATURE_QUOTA, query_params, subscriptionKey);
    
    return result;
}

const getPlansFeaturesList = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_PLANS_FEATURES_LIST, query_params, subscriptionKey);

    return result;
}


const updateFeature = (id, payload) => {

    let query_params = {
        id : id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_FEATURE, query_params, payload , subscriptionKey);


    return result;
    
}


const deleteFeature = (id) => {

    let query_params = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_FEATURE, query_params, subscriptionKey);
    
    return result;
}

const getPackageSummary = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.PACKAGE_SUMMARY, query_params, subscriptionKey);

    return result;
}

const getBusinessPackageFeatures = (businessId) => {

    let query_params = {
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_BUSINESS_PACKAGE_FEATURES, query_params, subscriptionKey);

    return result; 
}

const assignPackageToBusiness = (businessId, packageId) => {

    let payload = {
        business_id: businessId,
        package_id: packageId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ASSIGN_PACKAGE_TO_BUSINESS, payload, subscriptionKey);

    return result;
}

const getBusinessPackage = (businessId) => {

    let query_params = {
        businessId: businessId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_BUSINESS_PACKAGE, query_params, subscriptionKey);

    return result; 
}

const createUpdateCustomPackage = (businessId, featureId, quota) => {

    let payload = {
        business_id: businessId,
        feature_id: featureId,
        quota: quota
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.CREATE_UPDATE_CUSTOM_PACKAGE, payload, subscriptionKey);

    return result; 
}

const PackageManagementService = {
    getPackages,
    viewPackage,
    createPackage,
    updatePackage,
    deletePackage,
    getFeatureList,
    createFeature,
    updateFeature,
    deleteFeature,
    addFeatureQuota,
    updateFeatureQuota,
    deleteFeatureQuota,
    getPackageSummary,
    getPlansFeaturesList: getPlansFeaturesList,
    getBusinessPackageFeatures: getBusinessPackageFeatures,
    assignPackageToBusiness: assignPackageToBusiness,
    getBusinessPackage: getBusinessPackage,
    createUpdateCustomPackage: createUpdateCustomPackage
}

export default PackageManagementService