import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ThreeDots} from 'react-loader-spinner';
import useTemplates from "../../../hooks/useTemplates";

const UpdateTemplateNameDialog = (props) => {

    const {openEditTemplate, handleCloseEditTemplateDialog, templateDetails, setTemplateNameUpdatedToParent, setTemplateNameSendToParent, templateNameState} = props;
    const {  updateTemplateName, updatingTemplateName, isUpdated} = useTemplates();

    const [editedTemplateName, setEditedTemplateName] = useState(templateNameState);

    const handleTemplateNameChange = (e) => {
        setEditedTemplateName(e.target.value);
    }

    const handleUpdateTemplateName = () => {
        let formData = new FormData();

        formData.append('template_code', templateDetails?.template_code);
        formData.append('template_name', editedTemplateName);

        updateTemplateName(formData);
    }


    useEffect(() => {

        if (isUpdated) {
            setTemplateNameUpdatedToParent(true);
            setTemplateNameSendToParent(editedTemplateName);

            handleCloseEditTemplateDialog();
        }
    },[isUpdated])



    return(
        <Dialog open={openEditTemplate} onClose={handleCloseEditTemplateDialog} handleCloseEditTemplateDialog={handleCloseEditTemplateDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Update Template
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  sx={{alignSelf: "center", fontWeight: 550}}>
                            Template Name
                        </Grid>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                value={editedTemplateName}
                                onChange={(e) => handleTemplateNameChange(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10 }} 
                    onClick={(e) => handleCloseEditTemplateDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ width: 120, backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleUpdateTemplateName(e)}
                    startIcon={updatingTemplateName && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(updatingTemplateName) ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateTemplateNameDialog