import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from "react-loader-spinner";
import useRolesManagement from "../../../hooks/useRolesManagement";

const RemoveInheritanceDialog = (props) => {

    const { openRemoveInheritance, handleClose, dataRole, inheritanceChild } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const { removeInheritance, removeAdminInheritance, removingInheritance, removedInheritance } = useRolesManagement();
    
    const handleRemoveInheritance = () => {

        if(url === "/admin-settings"){
            removeAdminInheritance(dataRole?.id, inheritanceChild?.id);
        } else {
            removeInheritance(dataRole?.id, inheritanceChild?.id);
        }


    }

    useEffect(() => {

        if(removedInheritance){
            handleClose()
            window.location.reload();
        }

    },[removedInheritance])


    return (
        <Dialog open={openRemoveInheritance} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                Remove Inheritance
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                    Are you sure you want to remove <strong>{inheritanceChild?.role}</strong> from <strong>{dataRole?.role}</strong> ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={() => handleRemoveInheritance()}
                    startIcon={removingInheritance &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {removingInheritance ? 'Removing' :  'Remove'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default RemoveInheritanceDialog