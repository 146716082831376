import {useEffect, useState } from "react";
import {Box, Pagination, Typography, Grid} from "@mui/material"
import {Masonry} from '@mui/lab';
import AdminPermissionsContentComponent from "./admin-permission-content.component";

import PermissionListSkeleton from "../skeletons/permission-list-skeleton.component";

const AdminPermissionsComponent = (props) => {

    const { setView, setPermissionId, setModuleData, modules, isLoading } = props;


    const [page, setPage] = useState(1);

    useEffect(() => {
        props.getAdminModules(page);
    }, [])

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);
        props.getAdminModules(page);
    }

   
    return (
        <>

            {(isLoading) ?
                <PermissionListSkeleton />
            :
                <Masonry columns={{xs: 1, sm: 3}} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0}}>
                    {(modules?.length > 0) ?
                        (modules.map((data, index) => (
                            <Grid container key={index}>
                                <Grid item xs={12}>
                                    <AdminPermissionsContentComponent 
                                        data={data} 
                                        setView={setView} 
                                        setPermissionId={setPermissionId} 
                                        setModuleData={setModuleData}
                                        retrieving={isLoading}
                                        getAdminModules={props.getAdminModules}
                                    />
                                </Grid>
                            </Grid>
                        )))
                    :
                        <Grid container>
                            <Box sx={{ml: "-5px"}}>
                                <Typography sx={{fontSize: "14px"}}>
                                    No available module(s) to display yet
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Masonry>
            }

            {/* pagination for search permission */}
            {/* {(!isLoading) && 
                <Pagination
                    count={pagination} 
                    page={page} 
                    onChange={handleChangePaginationForSearch} 
                    style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                />
            } */}
        </>
    )
}

export default AdminPermissionsComponent