import React, {useState} from "react";
import {Box, List, ListItemIcon, ListItem, ListItemAvatar, Avatar, ListItemText,Button, Card, CardContent, Typography } from "@mui/material";
import OVCodeLogo from "../../icons/ovc.png";
import CheckIcon from '@mui/icons-material/Check';
import PackageSelectionOptionsDialog from "./dialogs/package-selection-options.dialog";
import usePackageManagement from "../../hooks/usePackageManagement";
import CustomizePlanDialog from "./dialogs/customize-plan.dialog";
import ChoosePlanConfirmationDialog from "./dialogs/choose-plan-confirmation.dialog";

const cardStyle = {
  margin: '8px',
  transition: 'transform 0.3s, box-shadow 0.3s, border-color 0.3s',
  border: '1px solid transparent',
  position: 'relative',
  borderRadius: "8px"
}

const cardContentStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "2px 16px 0px 16px"
}

const buttonStyle = {
  marginTop: '16px',
  opacity: 0,
  transition: 'opacity 0.3s',
  position: 'relative', 
  zIndex: 1
}

const headerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'transparent',
  color: 'transparent',
  textAlign: 'center',
  padding: '8px',
  transition: 'color 0.3s',
}

const PackageCardComponent = (props) => {

    const {breakpoints, name, description, defaultPackageFeatures, isHovered, index,
      handleHover, handleMouseLeave, selectedBusiness, isCurrentPlan, packageId
    } = props;

    const {isAssigningPackageToBusiness, assignPackageToBusiness} = usePackageManagement();

    const [openSelectionOptions, setOpenSelectionOptions] = useState(false);
    const [openCustomizedPlanDialog, setOpenCustomizedPlanDialog] = useState(false);
    const [openChoosePlanConfirmationDialog, setOpenChoosePlanConfirmationDialog] = useState(false);
  
    const handleOpenSelectionOptions = () => {
      setOpenSelectionOptions(true);
      handleMouseLeave();
    }

    const handleAssignPackageToBusiness = () => {
      setOpenChoosePlanConfirmationDialog(true);
    }

    const handleOpenCustomizedPlanDialogFromParent = (e) => {
      setOpenCustomizedPlanDialog(true);
    }


    return (
      <Card key={index}
        style={{
          ...cardStyle,
          transform: (isHovered || isCurrentPlan) ? "scale(1.05)" : "scale(0.95)",
          boxShadow: (isHovered && !isCurrentPlan) ? "#0178f261 0px 2px 10px 0px" : (isCurrentPlan) ? "#008000de 0px 2px 10px 0px" : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderTopColor: 'transparent',
          border: (isCurrentPlan) ? "0.5px solid rgba(1, 120, 242, 0.36)" : "transparent",
        }}
        onMouseOver={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <ListItem sx={{ 
          backgroundColor: "#0178f245",
          padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 16px" : "10px 16px",
          maxHeight: '100px',
          minHeight: '100px',
          height: '100px'
        }}
          
        >
          <ListItemAvatar sx={{minWidth: "40px"}}>
            <Avatar src={OVCodeLogo} style={{height: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 30, width: (breakpoints == "xs" || breakpoints == "sm") ? 40 : 30}} sx={{".MuiAvatar-img": {objectFit: "contain"}}} />
          </ListItemAvatar>
                
          <ListItemText sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 0}}
            primary={
              <Typography sx={{fontSize: 16, fontWeight: "bold"}}>
                {name}
              </Typography>
            }
            secondary={
              <Typography sx={{fontSize: 14}}>
                {description}
              </Typography>
            }
          />
         </ListItem>

        {(defaultPackageFeatures.length > 0) ?
          <CardContent style={cardContentStyle}>
            {defaultPackageFeatures?.map((planFeature, index) => (
              <List key={index}>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CheckIcon style={{color: "#0178f2"}} />
                  </ListItemIcon>

                  <ListItemText sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? -1 : "-20px"}} 
                    primary={
                      <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 14, color: "rgb(0 0 0 / 57%)"}}>
                        {planFeature?.quota} &nbsp;
                        {(isCurrentPlan) ? 
                          planFeature?.featureRecord.description 
                        : 
                          planFeature?.feat?.description
                        }
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            ))}           
          </CardContent>
        
        :
          <CardContent style={cardContentStyle}>
            <Typography sx={{margin: "18px 18px 18px 0px", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 14, color: "rgb(0 0 0 / 57%)"}}>
              No available features
            </Typography>
          </CardContent>
        }

        <br />

        {((selectedBusiness) ?
          ((isCurrentPlan && packageId > 2) ? 
            <Box sx={{textAlign: "center", mb: "20px", mt: "-20px"}}>
              <Button variant="outlined"
                style={{
                  ...buttonStyle,
                  opacity: 1,
                  width: "85%",
                  borderRadius: 2,
                  textTransform: "none",
                  background: "#0178f221",
                  cursor: "pointer",
                }}
                onClick={(e) => handleOpenCustomizedPlanDialogFromParent(e)}
              >
                Customize Plan
              </Button>
            </Box>
          :
            (isCurrentPlan && packageId < 3) ?
              <Box sx={{textAlign: "center", mb: "20px", mt: "-20px"}}>
                <Typography sx={{color: '#0178f2', fontSize: 14}}>Current Plan</Typography>
              </Box>

          :
            <Box sx={{textAlign: "center", mb: "20px", mt: "-20px"}}>
              <Button variant="outlined"
                style={{
                  ...buttonStyle,
                  opacity: 1,
                  width: "85%",
                  borderRadius: 2,
                  textTransform: "none",
                  background: "#0178f212",
                }}
                onClick={(e) => (packageId < 3) ? handleAssignPackageToBusiness(e) : handleOpenSelectionOptions(e)} 
              >
                Choose Plan
              </Button>
            </Box>    
          )

        :
          null
        )}

        {openSelectionOptions && 
          <PackageSelectionOptionsDialog breakpoints={breakpoints} 
            openSelectionOptions={openSelectionOptions}
            setOpenSelectionOptions={setOpenSelectionOptions}
            selectedBusiness={selectedBusiness} name={name}
            packageId={packageId} setOpenCustomizedPlanDialog={setOpenCustomizedPlanDialog}
          />
        }

        {openCustomizedPlanDialog && 
          <CustomizePlanDialog breakpoints={breakpoints}
            openCustomizedPlanDialog={openCustomizedPlanDialog}
            setOpenSelectionOptions={setOpenSelectionOptions}
            selectedBusiness={selectedBusiness} name={name}
            packageId={packageId} setOpenCustomizedPlanDialog={setOpenCustomizedPlanDialog}
            description={description} 
            defaultPackageFeatures={defaultPackageFeatures}
          />
        }

        {openChoosePlanConfirmationDialog && 
          <ChoosePlanConfirmationDialog breakpoints={breakpoints}
            openChoosePlanConfirmationDialog={openChoosePlanConfirmationDialog} 
            setOpenChoosePlanConfirmationDialog={setOpenChoosePlanConfirmationDialog}
            selectedBusiness={selectedBusiness} name={name} packageId={packageId}
            isAssigningPackageToBusiness={isAssigningPackageToBusiness}
            assignPackageToBusiness={assignPackageToBusiness}
            setOpenSelectionOptions={setOpenSelectionOptions}
          />
        }
    </Card>
  )
}

export default PackageCardComponent
