import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from "react-loader-spinner";
import usePermissionManagement from "../../../hooks/usePermissionManagement";

const DeleteModuleDialog = (props) => {

    const { openDeleteModule, handleClose, data } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;


    const { deleteModule, deleteAdminModule, deletedModule, deletingModule } = usePermissionManagement();

    const [page, setPage] = useState(1);


    const handleDeleteModule = () => {

        let module_id = data?.id

        if(url === "/admin-settings") {
            console.log("url matches '/admin-settings'");
            deleteAdminModule(module_id);
        } else {
            deleteModule(module_id);
        }

    }


    useEffect(() => {

        if(deletedModule){
            handleClose()
            if(url === "/admin-settings"){
                props.getAdminModules(page)
            } else {
                props.getModules(page);
            }
        }

    },[deletedModule])



    return (
        <Dialog open={openDeleteModule} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto' } }}>
            <DialogTitle>
                Delete Module
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                    Are you sure you want to delete <strong>{data?.module_name}</strong> module ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={() => handleDeleteModule() }
                    startIcon={deletingModule &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {deletingModule ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteModuleDialog