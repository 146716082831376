import {TableCell, TableRow} from "@mui/material";
import {format} from "date-fns";
import BillingActionsPopperComponent from "./billing-action-popper.component";

const ProjectBillingComponent = ({list, page, projectId, businessId, retrieveProjectSOA}) => {

    return (
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.filename}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.reference_no}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.notes}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.uploaded_time}
                {/* {format(new Date(list.uploaded_time), "PPpp")} */}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                <BillingActionsPopperComponent 
                    list={list}
                    page={page}
                    projectId={projectId}
                    businessId={businessId} 
                    retrieveProjectSOA={retrieveProjectSOA}
                />
            </TableCell>
        </TableRow>
    )
}

export default ProjectBillingComponent