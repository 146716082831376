import React, {useState} from "react";
import {ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const SignatureActionsPopperComponent = (props) => {

    const {tempSignatureElement, setOpenDeleteTempSignatureDialog, setOpenEditSignature, setCurrentSignature} = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleUpdateSignature = () => {
        handleClick()
        setOpenEditSignature(true)
        setCurrentSignature(tempSignatureElement)
    }

    const handleDeleteSignature = () => {
        handleClick()
        setOpenDeleteTempSignatureDialog(true)
        setCurrentSignature(tempSignatureElement)
    }


    return (
        <div style={{marginTop: "7px", textAlign: "end"}}>
            <IconButton sx={{color: "#000"}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={(e) => handleClick(e)} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl} sx={{zIndex: 9999}}>
                <Paper sx={{borderRadius: 1}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem style={{color: "#0178f2", fontSize: 13, fonWeight: 500}} 
                                onClick={(e) => handleUpdateSignature()}
                            >
                                <ListItemIcon style={{padding: 0, minWidth: 30, marginLeft: "-5px"}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: "#0178f2"}}>
                                        <EditIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">Edit</Typography>
                            </MenuItem>

                           
                            <MenuItem style={{color: "red", fontSize: 13, fonWeight: 500}} 
                                onClick={(e) => handleDeleteSignature()}
                            >
                                <ListItemIcon style={{padding: 0, minWidth: 30, marginLeft: "-5px"}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: "red"}}>
                                            <DeleteIcon style={{width: 16, height: 16}}/>
                                        </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">Delete</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </div>
    )
}

export default SignatureActionsPopperComponent