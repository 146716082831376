import { ListItemAvatar, Avatar, Box, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, IconButton, Stack, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import useReports from "../../../hooks/useReports";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { format } from 'date-fns';

import CachedIcon from '@mui/icons-material/Cached';
import EnterpriseProjectReportSkeletonComponent from "../../skeletons/enterprise-project-reports-skeleton.component";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useNavigate } from "react-router-dom";


function reformatString(inputString) {
    // Remove square brackets from the input string
    const cleanedString = inputString.replace(/[[\]]/g, '');

    // Convert the cleaned string to uppercase and split into an array
    const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());

    // Filter out "ENCRYPTION" from the array
    const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');

    if (filteredArray.length === 1) {
    // If there is only one element, return it as is
    return filteredArray[0];
    } else if (filteredArray.length > 1) {
    // If there are multiple elements, join them with commas and "and"
    const lastIndex = filteredArray.length - 1;
    return (
        filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
    );
    } else {
    // If no elements are left after filtering, return an empty string
    return '';
    }
}

const ReportOneTableHeader = () => {

    const tablecell = [
        { label: 'Pipeline' },
        { label: 'No. of Authentication' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportOneTableContent = (props) => {

    const { data, breakpoints } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
             <TableCell component="th" scope="row"  >
                {reformatString(data && data?.pipeline)}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data && data?.total_authenticated_count}
            </TableCell>
        </TableRow>
    )
}



const ViewProjectReportsPipelinesComponent = (props) => {

    const { project_id, project_name, onClickBackButton, passedDataFrom, passedDateTo, date } = props;

    console.log("passedDataFrom: ", passedDataFrom)
    console.log("passedDateTo: ", passedDateTo)

    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    const { getBusinessById, businessDetails } = useBusiness();
    const { loading, total_authentication, pagination, setLoading, viewGenerateReportByProject, view_enterprise_reports_pipelines} = useReports();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [dateFrom, setDateFrom] = useState(passedDataFrom);
    const [dateTo, setDateTo] = useState(passedDateTo);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);


    useEffect(() => {

        if (businessIdFromURL) {
            getBusinessById(businessIdFromURL);
        }

    }, [businessIdFromURL])

    useEffect(() => {

        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

    //     setDateFrom(dayjs(firstDayOfTheCurrentMonth));
    //     setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    useEffect(() => {
        if ((businessIdFromURL) && dateFrom && dateTo) {
            const businessToUse = businessIdFromURL;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");
            viewGenerateReportByProject(businessToUse, project_id, date, date, page);
        }
    }, [businessIdFromURL, dateFrom, dateTo, page, date]);


    const reload = () => {
        
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        viewGenerateReportByProject(businessToUse, project_id, date, date, page);
    }


    const handleChangePagination = (event, page) => {
        setPage(page);

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        viewGenerateReportByProject(businessToUse, project_id, date, date, page);
        setLoading(true);
    }

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }



    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);

        const business = project_name;
        const title = `${business} Report as of ${formattedDateRange}`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth) / 100;
        const titleY = 10;

        doc.text(title, titleX, titleY);

        const columns = ["Pipeline", "No. of Authentication"];

        const data = view_enterprise_reports_pipelines.map(item => {
            return [item.pipeline, item.total_authenticated_count];
        });

        doc.setFontSize(12);

        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20,
        });

        const overallTotal = total_authentication ? total_authentication : 0;

        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 12;
        const textX = pageWidth - textWidth - 10;
        const textY = doc.autoTable.previous.finalY + 10;

        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

        doc.save(`${project_name}-Document-Report.pdf`);
    };

    const businessToUse = businessIdFromURL;

    return (
        <Box sx={{ mt: 1 }}>

            <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight : 600  }}
                onClick={() => onClickBackButton()} >
                    Reports
            </Button>
         
            <Grid container sx={{ p: 2, pt: 1 }}>
                <Grid item xs={12} >
                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>

                                <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateFrom}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12, height: 38 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    borderRadius: 10,
                                                    fontSize: 12,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}
                                            onChange={(newValue) => { setDateFrom(newValue) }}
                                            maxDate={dateTo}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

                                &nbsp; &nbsp;

                                <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                        <DatePicker
                                            value={dateTo}
                                            required
                                            views={["year", "month", "day"]}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            sx={{
                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                "& .MuiOutlinedInput-root": {
                                                    height: 38,
                                                    fontSize: 12,
                                                    borderRadius: 10,
                                                    "& > fieldset": {
                                                    },
                                                },
                                            }}

                                            onChange={(newValue) => { setDateTo(newValue) }}
                                            minDate={dateFrom}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>


                                &nbsp; &nbsp;

                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                            </Stack>

                        </Box>


                        <Box sx={{ flexGrow: 1 }} />

                        <Button
                            variant="contained"
                            onClick={generatePDF}
                            sx={{
                                width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                                ":hover": { background: "#0178f2" }
                            }}
                            disabled={view_enterprise_reports_pipelines?.length === 0 || view_enterprise_reports_pipelines === undefined ? true : false}
                        >
                            Download
                        </Button>
                    </Stack>


                </Grid>

            </Grid>

            <Box sx={{ mt: 1 }} />

            

            <Grid container sx={{padding: 1, mt: 1}}>
                <Box sx={{ width: '100%', borderRadius: 0, overflow: 'hidden' }}>

                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                            <ReportOneTableHeader />

                            {loading ?
                                <EnterpriseProjectReportSkeletonComponent />
                            :
                                <TableBody>
                                    {(view_enterprise_reports_pipelines && view_enterprise_reports_pipelines)?.map((data, index) => (
                                        <ReportOneTableContent key={index} data={data}  />
                                    ))}


                                </TableBody>
                            }
                        </Table>

                        {(!loading && (view_enterprise_reports_pipelines?.length === 0 || view_enterprise_reports_pipelines === undefined)) ? (

                            <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                <Grid container sx={{ padding: 3 }}>
                                    <Box sx={{ textAlign: "center", margin: "auto" }}>
                                        <Typography sx={{ textAlign: "center" }}>
                                            No authentication record found for {formattedDateRange}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Box>
                        ) : (
                            null
                        )}

                    </TableContainer>
                </Grid>
                </Box>
                <br />
                <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ViewProjectReportsPipelinesComponent;
