import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useState } from "react";

const AddAccessDialog = (props) => {

    const { openAddAccess, handleClose } = props;

    const breakpoints = useWidth();

    const [permissionId, setPermissionId] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const [isAllowed, setIsAllowed] = useState(null);


    const handleChange = (event) => {
        setIsAllowed(event.target.value);
    }

    const handleChangeRole = (event) => {
        setRoleId(event.target.value);
    }

    const handleChangePermission = (event) => {
        setPermissionId(event.target.value);
    }

    return (
        <Dialog open={openAddAccess} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Add Access
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Select Roles</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={roleId}
                                onChange={handleChangeRole}
                            >
                                <MenuItem key="True" value="true">
                                    User
                                </MenuItem>
                                <MenuItem key="False" value="false">
                                    Developer
                                </MenuItem>
                            </Select>
                        
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Select Permission</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={permissionId}
                                onChange={handleChangePermission}
                            >
                                <MenuItem key="True" value="true">
                                    Project
                                </MenuItem>
                                <MenuItem key="False" value="false">
                                    Dashboard
                                </MenuItem>
                            </Select>
                        
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Allow Permission</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isAllowed}
                                onChange={handleChange}
                            >
                                <MenuItem key="True" value="true">
                                    True
                                </MenuItem>
                                <MenuItem key="False" value="false">
                                    False
                                </MenuItem>
                            </Select>
                        
                        </FormControl>
                    </Grid>



                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                // startIcon={loading && 
                //     <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                // }
                >
                    {/* {loading ? "Creating" : "Create"} */} Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAccessDialog