import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import CreateBusinessAccountComponent from "../components/account/create-business-account.component";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useWidth from "../hooks/useWidth";
import {useNavigate} from "react-router-dom";
import BackButton from "../components/common/back-button";

const AccountManagementCreateScreen = () => {

    const breakpoints = useWidth();
    const navigate = useNavigate();

    return(
        <Box>
            <Box sx={{textAlign: 'start', pb: 2, mt: -1.5}}>
                <BackButton />
            </Box>
      
            <CreateBusinessAccountComponent breakpoints={breakpoints}  />   
             
        </Box>
    )
}

export default AccountManagementCreateScreen