import { useEffect, useState } from "react";
import { Paper, Table, TableHead, FormControl, TableRow, InputLabel, Select, Stack, MenuItem, TableCell, Box, Grid, TableContainer, Typography, InputAdornment, TextField, Button, IconButton } from "@mui/material"
import AuthenticationListComponent from "../components/enterprise/authentication-list.component";
import AuthenticationEnterpriseSkeletonComponent from "../components/skeletons/authentication-enterprise-skeleton.component";
import useAnalytics from "../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CachedIcon from '@mui/icons-material/Cached';
import ReactSelect from "react-select";
import useProjects from "../hooks/useProjects";

const searchForOptions = [
    { value: "status", label: "Status" },
    // { value: "project", label: "Project" },
]

const statusOption = [
    { value: "ONGOING", label: "ONGOING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "ERROR", label: "ERROR" },
]

function Header() {
    return (
        <Box sx={{ width: '100%', borderRadius: 0 }}>
            <Typography variant='h6'>
                Authentication
            </Typography>
        </Box>
    )
}


const AuthenticationScreen = (props) => {

    const { projectId, breakpoints, project_name } = props;
    const navigate = useNavigate();

    const { loading, setLoading, transactions, getTransactionsUnderProject, filterAuthenticationUnderProjectByReference, getTransactionsByStatus } = useAnalytics();


    let id = (new URLSearchParams(window.location.search)).get("id");

 


    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [filter_status, setFilterStatus] = useState("");

    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);

        } else {
            setSearchFor(null);
            setSearchForLabel(null);
            setSearchStatusVal(null);

            // handleClearFilter();
        }
    }


    useEffect(() => {
        if (id && projectId && filter_status) {
            getTransactionsByStatus(id, projectId, filter_status,page);
        } else {
            getTransactionsUnderProject(id, projectId, page);
        }
    }, [id, projectId, filter_status, page])

    const reload = () => {
        setSearchInput('');
        setSearchFor(null);
        setSearchForLabel(null);
        setSearchStatusVal(null);
        getTransactionsUnderProject(id, projectId, page);
    }


    const searchItems = (e) => {
        setSearchInput(e.target.value);
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            filterAuthenticationUnderProjectByReference(id, projectId, e.target.value, page);
        }
    }


    const handleChangePagination = (event, page) => {

        if (searchInput) {
            setPage(page);
            filterAuthenticationUnderProjectByReference(id, projectId, searchInput, page);
            setLoading(true);
        } else {
            setPage(page);
            getTransactionsUnderProject(id, projectId, page);
            setLoading(true);
        }

    }

    return (
        <Box sx={{ mt: 1 }}>

            <Grid container sx={{ padding: 3, pt: 1, pb: 1, mt: 1 }}>
                <Grid item xs={12} sx={{ display: 'flex' }} >
                    <Header />
                </Grid>

                <Grid item xs={12} sm={12} sx={{ textAlign: 'start', mt: 1 }}>

                    <Box>
                        <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>

                            <ReactSelect
                                className="basic-single"
                                placeholder={
                                    <div>
                                        <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter:</b>&nbsp;
                                        <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                            {searchForLabel === "Status" && (
                                                <Typography sx={{ color: "#000", fontSize: 12 }}>Status</Typography>
                                            )}
                                            {/* {searchForLabel === "Project" && (
                                                <Typography sx={{ color: "#000", fontSize: 12 }}>Project</Typography>
                                            )} */}
                                            {searchForLabel === null && (
                                                "Select Filter"
                                            )}
                                        </i>
                                    </div>
                                }
                                isSearchable={true}
                                isRtl={false}
                                options={searchForOptions}
                                onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 200,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                                isClearable={true}
                            />


                            &nbsp; &nbsp;

                            {(searchForLabel === "Status") &&
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        value={filter_status}
                                        sx={{
                                            width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                            '&.Mui-focused': {
                                                border: 'none',
                                            },
                                        }}
                                        onChange={handleStatusChange}
                                        inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                    >
                                        {statusOption?.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }} >
                                                    {status.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            }


                            &nbsp;&nbsp;

                            <TextField
                                variant="outlined"
                                autoComplete="off"
                                sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 300 } }}
                                inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                placeholder="Search Reference..."
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Reference:</p></InputAdornment>,
                                }}
                                value={searchInput}
                                onChange={searchItems}
                                onKeyDown={keyPress}
                            />

                            &nbsp; &nbsp;

                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                        </Stack>
                    </Box>
                </Grid>


            </Grid>

            <Grid container sx={{ padding: 1, mt: 1 }}>
                <AuthenticationListComponent
                    page={page}
                    transactions={transactions}
                    handleChangePagination={handleChangePagination}
                    loading={loading}
                    businessId={id}
                    projectId={projectId}
                />
            </Grid>
        </Box>
    )
}

export default AuthenticationScreen
