import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import ProjectService from "../../services/api/projects";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { log } from "../../console-config.log";
import { useSnackbar } from "notistack";
import useTemplateProperties from "../../hooks/useTemplateProperties";
import TemplateService from "../../services/api/templates";
import { ThreeDots } from "react-loader-spinner";

const UpdateEsealDialogComponent = (props) => {

    const {openUpdateEseal, template, handleCloseDialog, setIsEsealLogoCreated, setHasSeal, template_code_queryParam} = props;

    const { templateProperties, getTemplateProperties } = useTemplateProperties();
    const [isLogoUpdate, setIsLogoUpdate] = useState(false);
    const [logo_image, setLogoImage] = useState(null);
    const [file, setFileLogoUpload] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    let project_id = template?.project_id;

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    let sizeFile = file?.size;
    let fileSizes = formatBytes(sizeFile);

    const validateLogoType = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "jfif" || extFile == "gif") {
            const [target_file_logo] = e.target.files;
           
            setFileLogoUpload(e.target.files[0]);
            setLogoImage(URL.createObjectURL(e.target.files[0]));

        } else {
          log("Only image files are supported as of now");
        }
    }

    const uploadLogo = () => {
        let formData = new FormData();

        formData.append("logo", file);
        formData.append("project_id", project_id);
        setIsLogoUpdate(true)
        ProjectService.uploadProjectLogo(formData).then((res) =>{
            log("uploadProjectLogo xxxx", res);
            setIsEsealLogoCreated(true)
            handleCloseDialog()
            // setHasSeal(true)
            setIsLogoUpdate(false)
            enqueueSnackbar("Logo uploaded!", { 
                variant: "success",
                autoHideDuration: 3000
            });
        }).catch((err)=>{
            setIsLogoUpdate(false)
            enqueueSnackbar("File size exceeds maximum file size limit, please choose another file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    
    useEffect(() => {
        const getSignatureUpdate = async () => {
          try {
            const signatureData = await TemplateService.getTemplateProperties(template_code_queryParam);
            // Do something with signatureData here, update state, etc.
            log('get signature data updates here', signatureData)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        if (isLogoUpdate) {
          getSignatureUpdate();
        }
      }, [isLogoUpdate, template_code_queryParam]);

    return (
        <Dialog open={openUpdateEseal} onClose={handleCloseDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
            <DialogTitle>
                Update Logo
            </DialogTitle>

            <DialogContent sx={{textAlign: 'center'}}>
                {(logo_image) ?
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{mb: "12px"}}>
                            <img alt="logo-img" src={logo_image} style={{height: 200, borderRadius: 5, width: '100%', objectFit: 'contain'}} />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{fontSize: 10, textAlign: "start", color: "#000"}}>
                                Maximum upload file size: 100KB
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography sx={{fontSize: 10, textAlign: "end", color: "#000"}}>
                                File size: {fileSizes}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{mt: "5px"}}>
                            <Button sx={{textTransform: 'none', width: '100%', borderRadius: 1}} variant="outlined">
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    id="upload-logo"
                                    onChange= {(e) => validateLogoType(e) }
                                    hidden
                                />

                                <label style={{width: '100%'}} htmlFor="upload-logo" className="upload-button-text">
                                    Browse logo
                                </label>
                            </Button>
                        </Grid>
                    </Grid>

                :

                    <div 
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100% !important",
                            maxWidth: "100% !important",
                            border: "dashed 2px #0178f2",
                            borderRadius: "8px",
                            cursor: "pointer",
                            flexGrow: 0,
                            height: "220px",
                            justifyContent: "center",
                            margin: "auto",
                            transition: "border-color .15s linear",
                        }}
                    >
                        <input 
                            accept="image/*" 
                            type="file"
                            id="upload-logo" 
                            hidden
                            onChange= {(e) => validateLogoType(e)}                  
                        />

                        <label htmlFor="upload-logo" style={{color: "#0178f2"}}>
                            <CloudUploadIcon style={{color: "#0178f2"}} />

                            <Typography sx={{color: "#0178f2"}}>
                                Browse logo 
                            </Typography> 
                        </label>
                    </div>
                }
            </DialogContent>

            <DialogActions sx={{padding: "16px 24px 16px 0px"}}>
                <Button onClick={handleCloseDialog} sx={{textTransform:'none'}}>
                    Cancel
                </Button>

                <Button variant="contained" onClick={uploadLogo} sx={{textTransform: 'none', borderRadius: 1}}
                    startIcon={isLogoUpdate && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                  {isLogoUpdate ? "Uploading" : "Upload"}  
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateEsealDialogComponent