import { Masonry } from "@mui/lab";
import { TableRow, TableCell, Skeleton, Box, Card, CardContent, Grid, Typography, Paper, Table, TableContainer, TableBody } from "@mui/material";

function ReportStorageSkeletonComponent() {

    const tableLoader = [{name: <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}}/>}, 
    {name: <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}}/>},
    {name: <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}}/>},
    {name: <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}}/>},]

    let items = [];

    for (let i = 0; i < 4; i++) {
        items.push(
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={11} sm={11}> 
                            <Typography sx={{fontWeight:'bold'}} >
                                <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} width={300} />
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={230} />
                        </Grid>
                        <Grid item xs={12} sm={5} sx={{display:'grid'}}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {tableLoader.map((row) => (
                                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell>
                                                   {row.name}
                                                </TableCell>
                                                <TableCell>
                                                   {row.name}
                                                </TableCell>
                                                <TableCell>
                                                   {row.name}
                                                </TableCell>
                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                   
                </CardContent>
            </Card>
            
        )
    }

    return <Masonry columns={{ xs: 1, sm: 2 }} spacing={4} sx={{margin:'auto', width: '100%', padding: 0}}>{items}</Masonry>
}



export default  ReportStorageSkeletonComponent;