import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import MultiplePageViewElementComponent from "./multiple-page-view-element.component";

function useViewElementTabs(props) {
  const { templateProperties } = props;
  const [viewElementTabs, setViewElementTabs] = useState([]);


  useEffect(() => {
    const pages = Array.from(
      new Set(templateProperties?.templateProperties.map((item) => item?.page_no))
    );

    // Sort page numbers in ascending order
    pages.sort((a, b) => a - b);

    const tabs = pages.map((page) => ({
      name: `Page ${page}`,
      component: (
        <Grid container spacing={2} sx={{ height: "100%", overflowY: "auto", margin:'auto', pb: 10 }}>
          {templateProperties?.templateProperties
            .filter((item) => item?.page_no === page)
            .map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MultiplePageViewElementComponent element={item} />
              </Grid>
            ))}
        </Grid>
      ),
    }));

    setViewElementTabs(tabs);
  }, [templateProperties]);

  return viewElementTabs;
}




const ViewElementTabComponent = (props) => {
  const { templateProperties } = props;

  console.log("ViewElementTabComponent aa");

  const tabs = useViewElementTabs(props);

  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box sx={{ width: '100%', margin: 'auto'}}>
      <CustomTabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        indicatorColor="transparent"
        sx={{ borderColor: 'divider' }}
      >
        {tabs?.map((tab, index) => (
          <CustomTab
            key={index}
            label={<Typography sx={{ textAlign: 'start'}}>{tab.name}</Typography>}
            disableRipple
            {...a11yProps(index)}
            sx={{ alignItems: 'flex-start !important', mb: 1, padding: '5px 1px 5px 0px !important' }}
          />
        ))}
      </CustomTabs>
      {tabs?.map((tab, index) => (
        <TabPanel key={index} value={value} index={index} style={{ padding: '0px', width: '90%', margin: 'auto' }}>
          <Grid container spacing={2} sx={{ height: '100%', overflowY: 'auto' , marginTop: '3px !important'}}>
            {tab.component}
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
};



export default ViewElementTabComponent;