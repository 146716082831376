import { Card, CardContent, CardActionArea, Box, Skeleton } from "@mui/material";

function VaultListSkeletonComponent() {

    const url = window.location.pathname;

    let items = [];

    for (let i = 0; i < 5; i++) {
        items.push(
            <Card  key={i} sx={{borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: 2}}>
                <CardActionArea>
                    <Skeleton variant="rectangular" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={110}/>
                </CardActionArea>
                <CardContent sx={{textAlign:'center'}}>
                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                </CardContent>
                <CardContent sx={{textAlign:'center', paddingTop: 0}}>
                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                </CardContent>
                <Box sx={{textAlign:'center'}}>
                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>  
                </Box>
            </Card>
        )
    }

    return <>{items}</>

}



export default  VaultListSkeletonComponent;