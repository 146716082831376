import { AppBar, Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, Grid, Paper, Toolbar, Typography } from '@mui/material';
import DrawerHeader from '../../layout/drawer-header';
import OVC from '../../icons/ovcodelogo.png';
import adminIcon from '../../icons/adminIcon.png';
import logo1 from '../../icons/logo1.jpg';
import logo2 from '../../icons/logo2.png';
import logo3 from '../../icons/logo3.png'
import logo4 from '../../icons/logo4.png'
import AuthService from '../../services/auth';
import { Container } from '@mui/system';

function LandingContent (){

    const login = () => {
        AuthService.login().then((res)=>{
          window.location.href="/dashboard"; 
        })
  
        .catch((error)=>{
          console.log("login error iii", error);
        });
      }

    return(
            <Box sx={{width: '100%', margin:'auto'}}>
                 <Paper sx={{backgroundColor: 'transparent',boxShadow:'none', height: 200}}>
                </Paper>
                {/* <Box sx={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)', margin:'auto'}}>
                    
                </Box> */}
                <Card sx={{maxWidth: 300, margin:'auto', borderRadius: 2, textAlign:'start', paddingTop: 0}}>
                    <CardContent sx={{textAlign:'center'}}>
                        <img src={OVC} style={{height: 30, margin:'auto', }}/>
                    </CardContent>
                    <CardActionArea sx={{cursor:'pointer', color:'#0178f2'}} onClick={login}>
                        <CardHeader sx={{margin:'auto', justifyContent:'center', paddingLeft: 8}} 
                        avatar={<img src={adminIcon} style={{height: 50}}/>} 
                        title={<Typography sx={{color: '#0178f2'}}>Sign In</Typography>} 
                        subheader={<Typography sx={{color:'black'}}>Administrator</Typography>}/>
                    </CardActionArea>
                </Card>
            </Box>
    )
}
export default LandingContent