import {useState} from "react";
import PackageManagementService from "../services/api/package-management";
import {useSnackbar} from "notistack";

const usePackageManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isFetchingPackages, setFetchingPackages] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [packageList, setPackageList] = useState([]);
    const [isFetchingDefaultPackageFeatures, setFetchingDefaultPackageFeatures] = useState(false);
    const [defaultFeatures, setDefaultFeatures] = useState([]);
    const [planPackage, setPlanPackage] = useState(null);
    const [planDetails, setPlanDetails] = useState(null);
    const [isViewPackage, setViewPackage] = useState(false);
    const [updatingPackage, setUpdatingPackage] = useState(false);
    const [updatedPackage, setUpdatedPackage] = useState(false);
    const [deletingPackage, setDeletingPackage] = useState(false);
    const [deletedPackage, setDeletedPackage] = useState(false);
    const [creatingPackage, setCreatingPackage] = useState(false);
    const [createdPackage, setCreatedPackage] = useState(false);
    const [isFetchingFeatures, setFetchingFeatures] = useState(false);
    const [feature_list, setFeatureList] = useState([]);
    const [creatingQuota, setCreatingQuota] = useState(false);
    const [createdQuota, setCreatedQuota] = useState(false);
    const [updatingFeatureQuota, setUpdatingFeatureQuota] = useState(false);
    const [updatedFeatureQuota, setUpdatedFeatureQuota] = useState(false);
    const [deletingFeatureQuota, setDeletingFeatureQuota] = useState(false);
    const [deletedFeatureQuota, setDeletedFeatureQuota] = useState(false);
    const [isAssigningPackageToBusiness, setAssigningPackageToBusiness] = useState(false);
    const [creatingFeature, setCreatingFeature] = useState(false);
    const [createdFeature, setCreatedFeature] = useState(false);
    const [updatingFeature, setUpdatingFeature] = useState(false);
    const [updatedFeature, setUpdatedFeature] = useState(false);
    const [deletingFeature, setDeletingFeature] = useState(false);
    const [deletedFeature, setDeletedFeature] = useState(false);
    const [packages, setPackages] = useState([]);

    const [isFetchingBusinessPackage, setFetchingBusinessPackage] = useState(false);
    const [isFetchingBusinessPackageFeatures, setFetchingBusinessPackageFeatures] = useState(false);
    const [businessPackageFeatures, setBusinessPackageFeatures] = useState(null);
    const [businessPackage, setBusinessPackage] = useState(null);
    const [getBusinessPackageResponse, setGetBusinessPackageResponse] = useState(null);
    const [assignedPackageToBusiness, setAssignedPackageToBusiness] = useState(false);
    const [isCreateUpdatingCustomPackage, setCreateUpdatingCustomPackage] = useState(false);
    const [isRetrievingPackageSummary, setRetrievingPackageSummary] = useState(false);
    const [packageSummaryList, setPackageSummaryList] = useState(null);
    const [customPlanUpdated, setCustomPlanUpdated] = useState(false);
    const [error,setError] = useState(false);

    const [isRetrievingBusinessPackageFeatures, setRetrievingBusinessPackageFeatures] = useState(false);

    const getPackages = () => {
        setFetchingPackages(true);
    
        PackageManagementService.getPackages().then((res) => {
            console.log("getPackages data xxxx", res);
    
            if (res.data.success) {
                setFetchingPackages(false);
                setPackageList(res.data.data);
            }
    
        }).catch((error) => { 
            setFetchingPackages(false);
            console.log("viewPackage error", error);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const viewPackage = (id) => {
        setFetchingDefaultPackageFeatures(true);
        setViewPackage(false);
    
        PackageManagementService.viewPackage(id).then((res) => {
            console.log("viewPackage data xxxx", res);

            if (res.data.success) {
                let planPackage = res.data.data.package;
                let packageFeature = res.data.data.feature_quota;

                setPlanPackage(planPackage);
                setDefaultFeatures(packageFeature);
                setFetchingDefaultPackageFeatures(false);
                setPlanDetails(res.data.data);
                setViewPackage(true);
            }
    
        }).catch((error) => { 
            setFetchingDefaultPackageFeatures(false);
            setViewPackage(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const getBusinessPackage = (businessId) => {
        setFetchingBusinessPackage(true);

        PackageManagementService.getBusinessPackage(businessId).then((res) => {
            console.log("getBusinessPackage data xxxx", res);
            
            if (res.data.success) {
                setFetchingBusinessPackage(false);
            }

        }).catch((error) => {
            console.log("getBusinessPackage error xxxx", error);

            setFetchingBusinessPackage(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const getBusinessPackageFeatures = (businessId) => {
        setFetchingBusinessPackageFeatures(true);
        setRetrievingBusinessPackageFeatures(false);

        PackageManagementService.getBusinessPackageFeatures(businessId).then((res) => {
            console.log("getBusinessPackageFeatures data xxxx", res);
            
            if (res.data.success) {
                setFetchingBusinessPackageFeatures(false);
                setGetBusinessPackageResponse(res.data);
                setBusinessPackage(res.data.data.business_package);
                setBusinessPackageFeatures(res.data.data.feature_quota);
                setRetrievingBusinessPackageFeatures(false);
            }

        }).catch((error) => {
            console.log("getBusinessPackageFeatures error xxxx", error);
            setFetchingBusinessPackageFeatures(false);
            setRetrievingBusinessPackageFeatures(false);
            setGetBusinessPackageResponse(null);
            setBusinessPackage(null);
            setBusinessPackageFeatures(null);

            if (error.response.data.error) {
                if(error.response.data.error === 'Forbidden.'){
                    setError(true);
                }else{
                    enqueueSnackbar(error.response.data.error, { 
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }

            } else {
                enqueueSnackbar("Error fetching business package features", { 
                    variant: "error",
                    autoHideDuration: 3000
                }); 
            }
        })
    }

    const createPackage = (payload) => {
        setCreatingPackage(true)
        PackageManagementService.createPackage(payload).then((res)=>{

            setCreatingPackage(false)

            if(res.data.success){
                setCreatedPackage(true)
                enqueueSnackbar('The package was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setCreatingPackage(false)
            setCreatedPackage(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const updatePackage = (id, payload) => {
        setUpdatingPackage(true)
        PackageManagementService.updatePackage(id, payload).then((res)=>{

            setUpdatingPackage(false)
            if(res.data.success){
                setUpdatedPackage(true);
                enqueueSnackbar('The package was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            console.log("errorxxx", error)
            setUpdatingPackage(false)
            setUpdatedPackage(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }


    const deletePackage = (id) =>{
        setDeletingPackage(true)
        PackageManagementService.deletePackage(id).then((res)=>{

            setDeletingPackage(false)

            if(res.data.success){

                setDeletedPackage(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingPackage(false)
            setDeletedPackage(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const getPackageSummary = () => {
        setFetching(true);
        setRetrievingPackageSummary(true);

        PackageManagementService.getPackageSummary().then((res) => {
            console.log("getPackageSummary data xxxx", res);

            setFetching(false);

            if (res.data.success) {
                setPackages(res.data.data);
                setRetrievingPackageSummary(false);
                setPackageSummaryList(res.data.data);
            }

        }).catch((error) => {
            setFetching(false);
            setRetrievingPackageSummary(false);
            console.log("getPackageSummary error", error);
            setError(error.response.statusText);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const getFeatureList = () => {
        setFetchingFeatures(true)
        PackageManagementService.getFeatureList().then((res)=>{

            console.log('getFeatureList', res)

            setFetchingFeatures(false)

            if(res.data.success){
                setFeatureList(res.data.data);
            }

           
        }).catch((error)=>{
            setFetchingFeatures(false)
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    };

    const createFeature = (payload) => {
        setCreatingFeature(true)
        PackageManagementService.createFeature(payload).then((res)=>{

            setCreatingFeature(false)

            if(res.data.success){
                setCreatedFeature(true)
                enqueueSnackbar('The feature was successfully created.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setCreatingFeature(false)
            setCreatedFeature(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const updateFeature = (id, payload) => {
        setUpdatingFeature(true)
        PackageManagementService.updateFeature(id, payload).then((res)=>{

            setUpdatingFeature(false)
            if(res.data.success){
                setUpdatedFeature(true);
                enqueueSnackbar('The feature was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            setUpdatingFeature(false)
            setUpdatedFeature(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }


    const deleteFeature = (id) =>{
        setDeletingFeature(true)
        PackageManagementService.deleteFeature(id).then((res)=>{

            setDeletingFeature(false)

            if(res.data.success){

                setDeletedFeature(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingFeature(false)
            setDeletedFeature(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const addFeatureQuota = (payload) => {
        setCreatingQuota(true)
        PackageManagementService.addFeatureQuota(payload).then((res)=>{

            setCreatingQuota(false)

            if(res.data.success){
                setCreatedQuota(true)
                enqueueSnackbar('The feature and quota was successfully added.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
    
            }

        }).catch((error) => {

            setCreatingQuota(false)
            setCreatedQuota(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const updateFeatureQuota = (id, payload) => {
        setUpdatingFeatureQuota(true)
        PackageManagementService.updateFeatureQuota(id, payload).then((res)=>{

            setUpdatingFeatureQuota(false)
            if(res.data.success){
                setUpdatedFeatureQuota(true);
                enqueueSnackbar('The feature quota was successfully updated.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            console.log("errorxxx", error)
            setUpdatingFeatureQuota(false)
            setUpdatedFeatureQuota(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });


        })
    }


    const deleteFeatureQuota = (id) =>{
        setDeletingFeatureQuota(true)
        PackageManagementService.deleteFeatureQuota(id).then((res)=>{

            setDeletingFeatureQuota(false)

            if(res.data.success){

                setDeletedFeatureQuota(true)
                enqueueSnackbar(res.data.data, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }


        }).catch((error)=>{

            setDeletingFeatureQuota(false)
            setDeletedFeatureQuota(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }

    const assignPackageToBusiness = (businessId, packageId, isSelectOptionsDialog, setOpenSelectionOptions, setOpenChoosePlanConfirmationDialog, isSelectAndCustomize) => {
        setAssigningPackageToBusiness(true);

        PackageManagementService.assignPackageToBusiness(businessId, packageId).then((res) => {
            console.log("assignPackageToBusiness data xxxx", res);
            
            if (res.data.success) {
                if (isSelectOptionsDialog) {
                    setOpenSelectionOptions(false);

                    setAssigningPackageToBusiness(false);
                    
                    enqueueSnackbar("Successfully assigned plan to enterprise", { 
                        variant: "success",
                        autoHideDuration: 3000
                    });

                    window.location.reload();

                } else if (isSelectAndCustomize) {
                    setAssigningPackageToBusiness(false);

                    setAssignedPackageToBusiness(true);

                } else {
                    setAssigningPackageToBusiness(false);
                    setOpenChoosePlanConfirmationDialog(false);

                    enqueueSnackbar("Successfully assigned plan to enterprise", { 
                        variant: "success",
                        autoHideDuration: 3000
                    });

                    window.location.reload();
                }  
            }

        }).catch((error) => {
            console.log("assignPackageToBusiness error xxxx", error);
            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
            setAssigningPackageToBusiness(false);
            setOpenSelectionOptions(false);
            setOpenChoosePlanConfirmationDialog(false);  
        })
    }

    const createUpdateCustomPackage = (businessId, featureId, quota, setSavingIndex) => {
        setCreateUpdatingCustomPackage(true);

        PackageManagementService.createUpdateCustomPackage(businessId, featureId, quota).then((res) => {
            console.log("createUpdateCustomPackage data xxxx", res);
            
            if (res.data.success) {
                setCreateUpdatingCustomPackage(false);
                setSavingIndex(null);
                setCustomPlanUpdated(true);
            }

        }).catch((error) => {
            console.log("createUpdateCustomPackage error xxxx", error);

            setCreateUpdatingCustomPackage(false);
            setCustomPlanUpdated(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }


    return {isFetchingPackages, packageList, isFetchingDefaultPackageFeatures, defaultFeatures, planPackage, 
        getPackages, viewPackage, planDetails, isViewPackage, createPackage, creatingPackage, createdPackage,
        updatePackage, updatingPackage, updatedPackage, deletePackage, deletingPackage, deletedPackage,
        getFeatureList, isFetchingFeatures, feature_list, addFeatureQuota, creatingQuota, createdQuota,
        updateFeatureQuota, updatingFeatureQuota, updatedFeatureQuota, deleteFeatureQuota, deletingFeatureQuota, 
        deletedFeatureQuota, isAssigningPackageToBusiness, assignPackageToBusiness, createFeature, creatingFeature,
        createdFeature, isFetchingBusinessPackage, getBusinessPackage, isFetchingBusinessPackageFeatures, 
        getBusinessPackageFeatures, businessPackage, businessPackageFeatures, getBusinessPackageResponse, assignedPackageToBusiness,
        isCreateUpdatingCustomPackage, createUpdateCustomPackage, updateFeature, updatedFeature, updatingFeature,
        deleteFeature, deletedFeature, deletingFeature, getPackageSummary, isFetching, packages, isRetrievingPackageSummary, packageSummaryList, customPlanUpdated,
        isRetrievingBusinessPackageFeatures, error
    }
}

export default usePackageManagement 