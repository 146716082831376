import { Avatar, Box, Button, Card, CardHeader, ClickAwayListener, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Masonry, TreeItem, TreeView } from "@mui/lab";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { format } from 'date-fns';
import useRolesManagement from "../../hooks/useRolesManagement";
import AddInheritanceDialog from "../rbac/dialogs/add-inheritance.dialog";
import RemoveInheritanceDialog from "../rbac/dialogs/remove-inheritance.dialog";
import InheritanceSkeleton from "../skeletons/inheritance-skeleton.component";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    const words = name.split('_'); // Split the name by underscores
    let initials = '';

    if (words.length > 0) {
        initials += words[0][0]; // First letter of the first word

        if (words.length > 1) {
            initials += words[1][0]; // First letter of the second word (after underscore)
        }
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}


const ViewAdminRolesComponent = (props) => {

    const { setView, dataRole, inheritanceChildren, inheritanceRole, isLoading, getAdminInheritance } = props;

    const [openAddInheritance, setOpenAddInheritance] = useState(false);
    const [openRemoveInheritance, setOpenRemoveInheritance] = useState(false);
    const [inheritanceChild, setInheritanceChild] = useState(null);

    const handleOpenAddInheritance = () => {
        setOpenAddInheritance(true);
    }

    const handleCloseAddInheritance = () => {
        setOpenAddInheritance(false);
    }


    const handleOpenRemoveInheritance = () => {
        setOpenRemoveInheritance(true);
    }

    const handleCloseRemoveInheritance = () => {
        setOpenRemoveInheritance(false);
    }

    useEffect(() => {

        getAdminInheritance(dataRole?.id);

    },[dataRole])

    return (
        <>

            <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "#000", fontSize: 18, textTransform: 'none', fontWeight : 600  }}
                onClick={() => setView(false) } >
                    {dataRole && dataRole?.role}
            </Button>

            <Box sx={{mt: 2}} />

            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={ <ExpandMoreIcon /> }
                defaultExpandIcon={inheritanceChildren && inheritanceChildren?.length > 0 ? <ChevronRightIcon /> : null }
                sx={{
                    height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto', '& .css-1a4gxpn-MuiTreeItem-content.Mui-selected': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                    <TreeItem nodeId="1" sx={{ padding: 0.5 }} label={
                    isLoading ?
                        <InheritanceSkeleton />
                    :
                        <Card sx={{ padding: 2, pt: 1, pb: 2, borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem alignItems="center" sx={{ cursor: 'pointer' }} > {/* Use alignItems="center" to vertically center the content */}
                                    <ListItemAvatar sx={{ minWidth: '80px !important' }}>
                                        {inheritanceRole?.role ? (
                                            <Avatar {...stringAvatar(inheritanceRole?.role)} style={{ width: 65, height: 65 }} />
                                        ) : 
                                            (<Avatar {...stringAvatar(dataRole?.role)} style={{ width: 65, height: 65 }} />)
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                        {inheritanceRole?.role ? inheritanceRole?.role : dataRole?.role }
                                                    </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={inheritanceRole?.description ? inheritanceRole?.description : dataRole?.description }
                                    />
                                </ListItem>
                            </List>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="text"
                                                sx={{
                                                    fontSize: 13,
                                                    p: '4px 5px',
                                                    borderRadius: 10,
                                                    boxShadow: 'none',
                                                    color: '#0178f2',
                                                    textTransform: 'none',
                                                }}
                                                startIcon={<AddCircleIcon sx={{ color: '#0178f2' }} />}
                                                onClick={handleOpenAddInheritance}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} sx={{ textAlign: 'end' }}>
                                    <Typography sx={{ fontSize: 9, textAlign: 'end' }}>
                                        Last Date Modified: {inheritanceRole ? format(new Date(inheritanceRole?.updated_at), 'PP') : format(new Date(dataRole?.updated_at), 'PP')}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Card>
                    }>
                        {inheritanceChildren && inheritanceChildren.map((data, index) => (
                            <TreeItem nodeId="2" sx={{ padding: 0.5 }} label={
                            isLoading ?
                                <InheritanceSkeleton />
                            :
                                <Card sx={{ padding: 2, pt: 1, pb: 2, borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        <ListItem alignItems="center" sx={{ cursor: 'pointer' }} > 
                                            <ListItemAvatar sx={{ minWidth: '80px !important' }}>
                                                {data?.role ? (
                                                    <Avatar {...stringAvatar(data?.role)} style={{ width: 65, height: 65 }} />
                                                ) : null}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Typography sx={{ fontWeight: 'bold' }}>
                                                        {data?.role}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={data?.description}
                                            />
                                        </ListItem>
                                    </List>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="text"
                                                        sx={{
                                                            fontSize: 13,
                                                            p: '4px 5px',
                                                            borderRadius: 10,
                                                            boxShadow: 'none',
                                                            color: 'red',
                                                            textTransform: 'none',
                                                        }}
                                                        startIcon={<RemoveCircleIcon sx={{ color: 'red' }} />}
                                                        onClick={() => {
                                                            handleOpenRemoveInheritance();
                                                            setInheritanceChild(data);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={7} sx={{ textAlign: 'end' }}>
                                            <Typography sx={{ fontSize: 9, textAlign: 'end' }}>
                                                Last Date Modified: { data && format(new Date(data?.updated_at), 'PP')}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Card>
                            } />
                        ))}

                    </TreeItem>
                
            </TreeView>

            {openAddInheritance &&
                <AddInheritanceDialog
                    openAddInheritance={openAddInheritance}
                    handleClose={handleCloseAddInheritance}
                    dataRole={dataRole}
                />
            }

            {openRemoveInheritance &&
                <RemoveInheritanceDialog
                    openRemoveInheritance={openRemoveInheritance}
                    handleClose={handleCloseRemoveInheritance}
                    dataRole={dataRole}
                    inheritanceChild={inheritanceChild}
                />
            }

        </>
    )
}

export default ViewAdminRolesComponent;
