import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../../common/custom-tabs.component";
import EnterpriseProjectReportsComponent from "./enterprise-project-reports.component";
import EnterpriseProjectStorageComponent from "./enterprise-project-storage.component";



function useReportsTab(props) {

    const { projectId, breakpoints, project_name } = props;

    const [reports_tab, setReportsTabs] = useState(null);


    function setupTabs() {

        var tabs_default = [
            { name: 'Authentication', component: <EnterpriseProjectReportsComponent projectId={projectId} breakpoints={breakpoints} project_name={project_name} /> },
            { name: 'Storage', component: <EnterpriseProjectStorageComponent projectId={projectId} breakpoints={breakpoints} project_name={project_name} /> },

        ]

        setReportsTabs(tabs_default);
    }

    useEffect(() => { setupTabs(); }, [])

    return reports_tab;

}


function EnterpriseProjectTabsComponent(props) {

    const { projectId, breakpoints, project_name } = props;

    const tabs = useReportsTab({ projectId, breakpoints, project_name });


    const storedTab = localStorage.getItem('selectedTab');
    const initialTabValue = storedTab ? parseInt(storedTab) : 0;
    const [value, setValue] = useState(initialTabValue);

    useEffect(() => {
        return () => {
            localStorage.removeItem('selectedTab');
        };
    }, []);

    function TabPanel(props) {

        const { children, value, index, ...other } = props;


        return (
            <div
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }


    function handleChange(event, newValue) {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue); // Store selected tab index
    }




    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ padding: 2, pb: 0 }} >
                <Grid item xs={12} sx={{ textAlign: 'start' }} >
                    <Typography variant='h6'>
                        Reports
                    </Typography>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CustomTabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ marginTop: 2 }}
                    indicatorColor="transparent"
                    centered
                >
                    {tabs?.map((tab, index) => {
                        return (
                            <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
                        )
                    })
                    }
                </CustomTabs>

            </Box>


            {tabs?.map((tab, index) => {
                return (
                    <TabPanel value={value} index={index} style={{ padding: "0px" }}>
                        {tab.component}
                    </TabPanel>
                )
            })}


        </Box>
    )
}

export default EnterpriseProjectTabsComponent;