import React, { useEffect, useMemo } from 'react';
import { Grid, Card, CardContent, Typography, Pagination, ListItemText, ListItem, Divider, List, CardActionArea, CardHeader, CardActions, Box } from '@mui/material';
import useWidth from '../../hooks/useWidth';
import ListSkeletonComponent from './skeleton/list-skeleton.component';
import { useNavigate } from 'react-router-dom';
import useBusiness from '../../hooks/useBusiness';
import { useState } from 'react';
import Avatar from 'react-avatar';
import useAnalytics from '../../hooks/useAnalytics';
import DashboardGraphComponent from './dashboard-graph.component';
import CountsSkeletonComponent from './skeleton/counts-skeleton.component';
import ForbiddenScreen from '../../screens/ForbiddenScreen';
import ForbiddenImage from "../../images/forbidden.png";



function ListItemEnterprise({ enterpriseAccount }) {

    const navigate = useNavigate();

    console.log("enterpriseAccount", enterpriseAccount)

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    if (enterpriseAccount == null) return (<ListSkeletonComponent />)


    return (

        <ListItem button sx={{ color: '#0178f2', padding: 0, borderRadius: 1, marginBottom: 1 }}>
            <Card sx={{ margin: 'auto', textAlign: 'start', width: '100%', border: '1px' }} onClick={(e) => navigate('/view?id=' + enterpriseAccount?.businessId)}>
                <CardContent>
                    <CardHeader sx={{ paddingBottom: 0, textAlign: 'left' }}
                        avatar={
                            enterpriseAccount?.logo ?
                                <img src={enterpriseAccount?.logo} style={{ height: 80, width: 80, objectFit: 'contain' }} />
                                :
                                <Avatar maxInitials={2} round={true} name={enterpriseAccount?.business_name} size="80" />}
                        title={
                            <Typography sx={{ cursor: 'pointer', fontWeight: 'bold' }} >
                                {enterpriseAccount?.business_name}
                            </Typography>}
                        subheader={<Typography sx={{ fontWeight: 'bold', fontSize: 20 }}> {enterpriseAccount?.count}</Typography>}
                        action={<Typography sx={{ color: '#d3d3d3', fontWeight: 600 }}>#{enterpriseAccount?.rank}</Typography>}

                    />
                </CardContent>
                <CardActions sx={{ fontSize: 10, justifyContent: 'end' }}>Since: {formatDate(enterpriseAccount?.registered_date)}</CardActions>
            </Card>
        </ListItem>

    )
}






function ListAccountView(props) {

    const style = {
        width: '100%',
        bgcolor: 'background.paper',
    };

    const { businessList, isLoadingBusinessList, breakpoints, businesses, errorTopBusiness } = props;

    const dateModified = businesses?.map(item => {
        return item?.registeredDate;
    });

    const [updated_business, setUpdatedBusiness] = useState([]);

    const render = dateModified.join('');

    console.log("businesses xx", businesses)
    console.log("businessList here xxx", businessList)

    useEffect(() => {
        // Create a map from businessList for efficient lookup
        const businessMap = {};
        businessList.forEach(business => {
            businessMap[business.id] = { business_name: business.business_name, logo: business.logo, registered_date: business.registered_date };
        });

        console.log("businessMap:", businessMap);

        // Log keys of businessMap for inspection
        console.log("businessMap keys:", Object.keys(businessMap));

        // Match businessId from businesses with id from businessList and add business_name and logo
        const matchedBusinesses = businesses.map(business => {
            const { business_name, logo, registered_date } = businessMap[business.businessId] || { business_name: "Unknown", logo: "", registered_date: "" };
            console.log(`Mapping businessId ${business.businessId} -> ${business_name}, ${logo}, ${registered_date}`);
            return { ...business, business_name, logo, registered_date };
        });

        // Display the final array with matched details
        console.log('matchedBusinesses:', matchedBusinesses);
        setUpdatedBusiness(matchedBusinesses);

    }, [businesses, businessList]);




    return (
        <List sx={style} component="nav" aria-label="mailbox folders">
            {isLoadingBusinessList ?
                <ListSkeletonComponent />
                :
                <>

                    <ListItem disableRipple button>
                        <ListItemText primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }} primary="Top Enterprises" />
                    </ListItem>
                    <Divider />

                    {errorTopBusiness ?

                        <Grid container spacing={0} justifyContent="center" alignItems="center" sx={{ alignSelf: 'center' }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                <Typography textAlign="center" mb={2} sx={{ fontSize: 18, color: 'black' }}>
                                    You are not authorized.
                                </Typography>
                                <Typography variant="body1" component="div" textAlign="center" fontSize={14}>
                                    You tried to access a page you did not have prior authorization for.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                <img alt="" src={ForbiddenImage} style={{ height: 120 }} />
                            </Grid>
                        </Grid>
                        :
                        <>

                            {updated_business?.map((enterpriseAccount) => {
                                return (
                                    <ListItemEnterprise key={enterpriseAccount.businessId} enterpriseAccount={enterpriseAccount} />
                                )
                            })}
                        </>
                    }


                </>
            }

        </List>
    )
}

function DashboardComponent(props) {

    const { businesses, loadingCounts, counts, loading, countePerYear, breakpoints, businessList, isLoadingBusinessList, error, errorTopBusiness } = props;

    console.log("errorTopBusiness xx", errorTopBusiness)
    console.log("error xx", error)

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                        {error === 'Forbidden' || error === 'Forbidden.'?
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer', margin: 'auto' }} >
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>

                                            <Grid container spacing={0} justifyContent="center" alignItems="center">
                                                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                                    <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                        You are not authorized.
                                                    </Typography>
                                                    <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                        You tried to access a page you did not have prior authorization for.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                                    <img alt="" src={ForbiddenImage} style={{ height: 100 }} />
                                                </Grid>
                                            </Grid>

                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Enterprise Account</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            :
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer', margin: 'auto' }} onClick={() => window.location.href = "/account-management"}>
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>

                                            {loadingCounts ?
                                                <CountsSkeletonComponent />
                                                :
                                                <Typography variant="h3" sx={{ margin: 'auto' }}>
                                                    {counts?.total_business}
                                                </Typography>
                                            }

                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Enterprise Account</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        }
                        {error === 'Forbidden' || error === 'Forbidden.' ?
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer', margin: 'auto' }} >
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>

                                            <Grid container spacing={0} justifyContent="center" alignItems="center">
                                                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                                    <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                        You are not authorized.
                                                    </Typography>
                                                    <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                        You tried to access a page you did not have prior authorization for.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                                    <img alt="" src={ForbiddenImage} style={{ height: 100 }} />
                                                </Grid>
                                            </Grid>

                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Project</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            :
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer' }}>
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>
                                            {loadingCounts ?
                                                <CountsSkeletonComponent />
                                                :
                                                <Typography variant="h3" sx={{ margin: 'auto' }}>
                                                    {counts?.total_projects}
                                                </Typography>
                                            }
                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Project</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        }
                        {error === 'Forbidden' || error === 'Forbidden.' ?
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer', margin: 'auto' }}>
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>

                                            <Grid container spacing={0} justifyContent="center" alignItems="center">
                                                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                                    <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                        You are not authorized.
                                                    </Typography>
                                                    <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                        You tried to access a page you did not have prior authorization for.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                                    <img alt="" src={ForbiddenImage} style={{ height: 100 }} />
                                                </Grid>
                                            </Grid>

                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Users</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            :
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer' }} onClick={() => window.location.href = "/user-list"}>
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>
                                            {loadingCounts ?
                                                <CountsSkeletonComponent />
                                                :
                                                <Typography variant="h3" sx={{ margin: 'auto' }}>
                                                    {counts?.total_users}
                                                </Typography>
                                            }
                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Users</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        }
                        {errorTopBusiness === 'Forbidden' || errorTopBusiness === 'Forbidden.' ?
                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer', margin: 'auto' }}>
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>

                                            <Grid container spacing={0} justifyContent="center" alignItems="center">
                                                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                                                    <Typography textAlign="center" mb={2} sx={{ fontSize: 16, color: 'black' }}>
                                                        You are not authorized.
                                                    </Typography>
                                                    <Typography variant="body1" component="div" textAlign="center" fontSize={11}>
                                                        You tried to access a page you did not have prior authorization for.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                                                    <img alt="" src={ForbiddenImage} style={{ height: 100 }} />
                                                </Grid>
                                            </Grid>

                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Users</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        :

                            <Grid item xs={12} sm={3} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center' }}>
                                <Card sx={{ cursor: 'pointer' }} >
                                    <CardActionArea sx={{ minHeight: 150, color: '#0178f2' }}>
                                        <CardContent>
                                            {loading ?
                                                <CountsSkeletonComponent />
                                            :
                                                <Typography variant="h3" sx={{ margin: 'auto' }}>
                                                    {countePerYear?.total}
                                                </Typography>
                                            }
                                            <Typography sx={{ margin: 'auto', color: 'black', fontWeight: 'bold', fontSize: 13 }}>Authentication</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </Grid>


                {/* DASHBOARD GRAPH COMPONENT */}
                <Grid item xs={12} sm={8}>
                    <DashboardGraphComponent countePerYear={countePerYear} />
                </Grid>


                <Grid item xs={12} sm={4} sx={{ marginTop: 8 }}>
                    <Card sx={{ padding: 2.3, cursor: 'pointer' }}>
                        <Box sx={{ width: '100%', height: 510, overflow: 'auto' }}>
                            <ListAccountView businesses={businesses} errorTopBusiness={errorTopBusiness} breakpoints={breakpoints} businessList={businessList} isLoadingBusinessList={isLoadingBusinessList} />
                        </Box>
                        <Divider />
                    </Card>
                </Grid>

            </Grid>
        </Box>

    )
}
export default DashboardComponent