import {useState, useEffect} from "react";
import {Autocomplete, TextField} from "@mui/material";
import { debounce } from 'lodash';
import useProjects from "../../hooks/useProjects";

const SelectBusinessProjectDropdownComponent = (props) => {

    const {hasBusinessAndProjectIds, businessId, defaultProjectId, onSelectProject} = props;
    
    const {getProjectList, projects, loadingProjects, viewProjectDetails, projectDetails} = useProjects();

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    // const [currentPage, setCurrentPage] = useState(1);
    const [currentProject, setCurrentProject] = useState(null);

    useEffect(() => {
        getProjectList(businessId)
    }, [businessId])

    useEffect(() => {
        if (defaultProjectId) {
            viewProjectDetails(defaultProjectId)
        }
    }, [defaultProjectId])

    useEffect(() => {
        if (projectDetails) {
            setCurrentProject(projectDetails)
        }
    }, [projectDetails])

    useEffect(() => {
        if (projects) {
            console.log("projects aa", projects);
            setOptions((prevOptions) => {
                const mergedArr = [...prevOptions, ...projects]
                const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
                return updatedOptions
            });
        }
    }, [JSON.stringify(projects)])

    // useEffect(() => {
    //     if (searchPrincipalNameByIdData) {
    //         setOptions((prevOptions) => {
    //             const mergedArr = [...prevOptions, ...searchPrincipalNameByIdData]
    //             const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
    //             return updatedOptions
    //         });
    //     }
    // }, [JSON.stringify(searchPrincipalNameByIdData)])

    const removeDuplicatesOptionsArr = (arr) => {
        return Array.from(
            new Set(arr.map((obj) => JSON.stringify(obj)))
        ).map((jsonString) => JSON.parse(jsonString));
    }

    // const debouncedFetchData = debounce(async (query) => {
    //     searchPrincipalNameById(query, businessId)
    //   }, 1000);
    
    //   useEffect(() => {
    //     if (searchValue !== "" && !options.some((option) => option.application_name.includes(searchValue))) {
    //         debouncedFetchData(searchValue);
    //     }
    
    //     // Cleanup debounced/throttled function on component unmount
    //     return () => {
    //       debouncedFetchData.cancel();
    //     };
    //   }, [searchValue]);

    const fetchMoreData = () => {
        getProjectList(businessId);
        // if (currentPage < pagination) {
        //     let page = currentPage + 1
        //     setCurrentPage(page)
        //     getProjectList(businessId, page);
        // }
    };

    // const handleInputChange = (event, newInputValue) => {
    //     setSearchValue(newInputValue);
    // };

    const handleOnChange = (event, selectedOption) => {
        console.log("selectedOption project aa", selectedOption);
        setCurrentProject(selectedOption)
        onSelectProject(selectedOption)
    }

    return(
        <Autocomplete
            id="infinite-scroll-autocomplete"
            options={options}
            loading={loadingProjects}
            // onInputChange={handleInputChange}
            onChange={handleOnChange}
            defaultValue={currentProject}
            value={currentProject}
            disabled={hasBusinessAndProjectIds}
            getOptionLabel={(option) => option.application_name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField {...params} label="Select Project" variant="outlined" fullWidth />
            )}
            ListboxProps={{
                onScroll : (event) => {
                    const listboxNode = event.currentTarget
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        fetchMoreData()
                    }
                },
            }}
        />
    )
}

export default SelectBusinessProjectDropdownComponent