import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';


const UpdateModuleDialog = (props) => {

    const { openUpdateModule, handleClose, data } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const { loading, updateModule, updateAdminModule, updatedModule } = usePermissionManagement();

    const [moduleName, setModuleName] = useState(data?.module_name);
    const [page, setPage] = useState(1);

    const handleTextFieldChange = (e) => {

        setModuleName(e.target.value);

    }

    const handleUpdateModule = () => {

        let module_id = data?.id

        let payload = {
            module_name: moduleName,
        }

        if(url === "/admin-settings"){
            updateAdminModule(module_id, payload)
        } else {
            updateModule(module_id, payload)
        }


    }

    useEffect(() => {

        if(updatedModule){
            handleClose()

            if(url === "/admin-settings"){
                props.getAdminModules(page)
            } else {
                props.getModules(page);
            }
    

        }

    },[updatedModule, url, page])


    return (
        <Dialog open={openUpdateModule} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Update Module
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Module Name :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={moduleName}
                            onChange={(e) => handleTextFieldChange(e)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleUpdateModule() }
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Updating" : "Update"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateModuleDialog