
export default (mode) => ({

    // add existing global styles here vvv
    typography: {
        fontFamily: 'Sans-serif !important',
    },

    // MUI COMPONENTS CUSTOMIZATION
      components: {
        MuiTypography: {
            styleOverrides:{
                // root: {
                //   fontSize: '14px'
                // },
                subtitle2: {
                    fontSize: 14,
                    fontWeight: 600
                },
                caption: {
                    fontSize: 14,
                    display: 'block',
                    textDecoration: "none",
                    textOverflow: 'ellipsis',
                    whiteSpace:  "nowrap" ,
                    overflow:  "hidden" 
                },
                h6: {
                    fontWeight: 600, 
                    fontSize: 19
                },
                
            },
        }, 
        MuiButton: {
            styleOverrides:{
                root: {
                },
                contained:{
                    borderRadius: 5,
                    textTransform: 'none',
                    backgroundColor: "#0178f2",
                    textTransform:'none'
                }
                
            },
        },
        MuiCard: {
            styleOverrides:{
                root: {
                    borderRadius: 5
                },
            },
        },  
      }
  
  });