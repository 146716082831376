import { Button, ButtonGroup, ClickAwayListener, Divider, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import ApproveConfirmationDialogComponent from "./email-authentication-dialog/approve-confirmation-dialog.component";
import RevokeConfirmationDialogComponent from "./email-authentication-dialog/revoke-confirmation-dialog.component";
import ResetStatusConfirmationDialogComponent from "./email-authentication-dialog/reset-status-confirmation-dialog.component";
import DisapproveConfirmationDialogComponent from "./email-authentication-dialog/disapprove-confirmation-dialog.component";
import RemoveConfirmationDialogComponent from "./email-authentication-dialog/remove-confirmation-dialog.component";

import useEmailSender from "../../hooks/useEmailSender";

// import CancelConfirmationDialog from "./dialog/cancel-confirmation-dialog.component";
// import RevokeConfirmationDialogComponent from "./dialog/revoke-confirmation-dialog.component";
// import RemoveConfirmationDialog from "./dialog/remove-confirmation-dialog.component";

const EmailSenderPopperComponent = (props) => {

    const { authentication } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDisapproveConfirmationDialog, setOpenDisapproveConfirmationDialog] = useState(false);
    const [openRevokeConfirmationDialog, setOpenRevokeConfirmationDialog] = useState(false);
    const [openResetConfirmationDialog, setOpenResetConfirmationDialog] = useState(false);
    const [openApproveConfirmationDialog, setOpenApproveConfirmationDialog] = useState(false);
    const [openRemoveConfirmationDialog, setOpenRemoveConfirmationDialog] = useState(false);

    const {testEmailSender} = useEmailSender()

    const handleOpenDisapproveConfirmationDialog = (e) => { setOpenDisapproveConfirmationDialog(true); }

    const handleCloseDisapproveConfirmationDialog = (e) => { setOpenDisapproveConfirmationDialog(false); }


    const handleOpenApproveConfirmationDialog = (e) => { setOpenApproveConfirmationDialog(true); }

    const handleCloseApproveConfirmationDialog = (e) => { setOpenApproveConfirmationDialog(false); }


    const handleOpenRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(true); }

    const handleCloseRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(false); }

    const handleOpenResetConfirmationDialog = (e) => { setOpenResetConfirmationDialog(true); }

    const handleCloseResetConfirmationDialog = (e) => { setOpenResetConfirmationDialog(false); }

    const handleOpenRemoveConfirmationDialog = (e) => { setOpenRemoveConfirmationDialog(true); }

    const handleCloseRemoveConfirmationDialog = (e) => { setOpenRemoveConfirmationDialog(false); }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleTestEmailSender = () => {
        testEmailSender(authentication.id, authentication.email)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    return (
        <div>
            <IconButton sx={{ color: 'black' }}>
                <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{ borderRadius: 2 }}>
                    <ClickAwayListener onClickAway={handleClick}>
                        {authentication.status_code === "PA" ?
                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'green' }} onClick={handleOpenApproveConfirmationDialog} >
                                        Approve
                                    </Typography>
                                </MenuItem>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'orange' }} onClick={handleOpenDisapproveConfirmationDialog} >
                                        Deny
                                    </Typography>
                                </MenuItem>
                                {/* <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'red' }} onClick={handleOpenResetConfirmationDialog} >
                                        Reset
                                    </Typography>
                                </MenuItem> */}
                            </MenuList>

                        : (authentication.status_code === "A") ?

                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: '#0178f2' }} onClick={handleOpenRevokeConfirmationDialog} >
                                        Revoke
                                    </Typography>
                                </MenuItem>
                                {/* <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'red' }} onClick={handleOpenResetConfirmationDialog} >
                                        Reset
                                    </Typography>
                                </MenuItem> */}
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" onClick={handleTestEmailSender} >
                                        Test Email Sender
                                    </Typography>
                                </MenuItem>
                            </MenuList>
                        : (authentication.status_code === "R") ?

                            <MenuList>
                                <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                                    <Typography variant="inherit" sx={{ color: 'red' }} onClick={handleOpenRemoveConfirmationDialog} >
                                        Remove
                                    </Typography>
                                </MenuItem>
                            </MenuList>

                        :
                        <MenuList>
                        <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                            <Typography variant="inherit" sx={{ color: 'red' }} onClick={handleOpenRemoveConfirmationDialog} >
                                Remove
                            </Typography>
                        </MenuItem>
                    </MenuList>
                            // <MenuList>
                            //     <MenuItem style={{ color: '#0178f2', fontSize: 13, fonWeight: 500 }} >
                            //         <Typography variant="inherit" sx={{ color: 'red' }} onClick={handleOpenResetConfirmationDialog} >
                            //             Reset
                            //         </Typography>
                            //     </MenuItem>
                            // </MenuList>
                        }
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openApproveConfirmationDialog &&
                <ApproveConfirmationDialogComponent
                    handleCloseApproveConfirmationDialog={handleCloseApproveConfirmationDialog}
                    authenticationDetails={authentication}
                    openApproveConfirmationDialog={openApproveConfirmationDialog}
                />
            }

            {openRevokeConfirmationDialog &&
                <RevokeConfirmationDialogComponent
                    handleCloseRevokeConfirmationDialog={handleCloseRevokeConfirmationDialog}
                    authenticationDetails={authentication}
                    openRevokeConfirmationDialog={openRevokeConfirmationDialog}
                />
            }

            {
                openRemoveConfirmationDialog &&
                <RemoveConfirmationDialogComponent
                    handleCloseRemoveConfirmationDialog={handleCloseRemoveConfirmationDialog}
                    authenticationDetails={authentication}
                    openRemoveConfirmationDialog={openRemoveConfirmationDialog}
                />
            }

            { openResetConfirmationDialog &&
                <ResetStatusConfirmationDialogComponent 
                    handleCloseResetConfirmationDialog={handleCloseResetConfirmationDialog}
                    authenticationDetails={authentication}
                    openResetConfirmationDialog={openResetConfirmationDialog}
                />        
            }

            {openDisapproveConfirmationDialog &&
                <DisapproveConfirmationDialogComponent 
                    handleCloseDisapproveConfirmationDialog={handleCloseDisapproveConfirmationDialog}
                    authenticationDetails={authentication}
                    openDisapproveConfirmationDialog={openDisapproveConfirmationDialog}
                />
            }
            
        </div>
    )
}

export default EmailSenderPopperComponent
