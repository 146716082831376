import {useState} from "react";
import BusinessService from "../services/api/business";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const useBusiness = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [businessList, setBusinessList] = useState([]);
    const [isLoadingBusinessList, setLoadingBusinessList] = useState(true);
    const [businessDetails, setBusinessDetails] = useState(null);
    const [isUpdatingBusiness, setUpdatingBusiness] = useState(false);
    const [fetchingBusinessDetails, setFetchingBusinessDetails] = useState(false);
    const [searchPrincipalNameByIdData, setSearchPrincipalNameById] = useState(null);
    const [isLoadingSearchPrincipalNameById, setIsLoadingSearchPrincipalNameById] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [searchPagination, setSearchPagination] = useState(0);
    const [business_names, setBusinessNames] = useState([]);
    const [counts, getCounts] = useState(null);
    const [searchByBusinessName, setSearchByBusinessName] = useState(null);
    const [loadingCounts, setLoadingCounts] = useState(false);
    const [isCreated, setCreated] = useState(false);
    const [isFetchingBusinessNames, setFetchingBusinessNames] = useState(true);
    const [businessId, setBusinessId] = useState(null);

    const [isFetchingBusinessOverviewStats, setFetchingBusinessOverviewStats] = useState(false);
    const [businessOverviewStats, setBusinessOverviewStats] = useState(null);

    const [isFetchingBusinessUsers, setFetchingBusinessUsers] = useState(false);
    const [businessUsers, setBusinessUsers] = useState(null);
    const [error_error, setErrorError] = useState("");


    const navigate = useNavigate();

    var limit = 10;
    var type = 1;

    const createBusiness = (formData) => {

        setLoading(true);
        BusinessService.createBusiness(formData).then(res => {
            console.log("res create business :", res);
            setLoading(false);
            setResponse(res.data);
            if(res.data.success){
                setCreated(true);
                setBusinessId(res.data.data.id);
                enqueueSnackbar("Successfully created business account", { 
                    variant: 'success',
                    autoHideDuration: 3000
                  });
              
            }
        }).catch((err) => {
            console.log("createBusiness error xxxx", err)

            setLoading(false);
            setCreated(false);
            setError(err.response.statusText);

            enqueueSnackbar(err.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });    
        })
    }

    const getAllBusinessList = (page) => {
        setLoadingBusinessList(true);
        BusinessService.getAllBusinessList(page, 1000).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                console.log("business list all bbb", res.data);
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getAllBusinessList error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
            setErrorError(error.response.statusText)
            
        })
    }


    const getBusinessList = (page) => {
        setLoadingBusinessList(true);
        BusinessService.getBusinessList(page, limit).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                console.log("business list bbb", res.data);
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListxxx error xxxx", error.response.statusText);
            setErrorError(error.response.statusText)
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }


    const getBusinessListStatus = (status, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListStatus(status, page, limit).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListStatus error xxxx", error);
            setLoadingBusinessList(false);
            setErrorError(error.response.statusText)
            setPagination(0)
        })
    }

    const getBusinessListWebsite = (website, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListWebsite(website, page, limit).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListWebsite error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }

    const getSortBusinessList = (sort, order, page) => {
        setLoadingBusinessList(true);

        BusinessService.getSortBusinessList(sort, order, page, limit).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListWebsite error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }

    const getBusinessListPlan = (plan, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListPlan(plan, page, limit).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListPlan error xxxx", error);
            setLoadingBusinessList(false);
            setErrorError(error.response.statusText)
            setPagination(0)
        })
    }

    const getBusinessListPlanWithStatus = (status, plan, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListPlanWithStatus(status, plan, page, limit, type).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListPlanWithStatus error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }

    const getBusinessListWebsiteWithStatus = (status, website, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListWebsiteWithStatus(status, website, page, limit, type).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListWebsiteWithStatus error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }

    const getBusinessListNameWithStatus = (status, businessName, page) => {
        setLoadingBusinessList(true);

        BusinessService.getBusinessListNameWithStatus(status, businessName, page, limit, type).then((res) => {

            setLoadingBusinessList(false);
            
            if (res.data.success) {
                setBusinessList(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
        }).catch((error) => {
            console.log("getBusinessListNameWithStatus error xxxx", error);
            setLoadingBusinessList(false);
            setPagination(0)
        })
    }


    const getSearchByBusinessName = (businessName, page) => {
        setLoadingBusinessList(true);
        BusinessService.getSearchByBusinessName(businessName, page, limit).then((res)=>{
            setLoadingBusinessList(false);
            if (res.data.success) {
                setSearchByBusinessName(res.data.data)
                setBusinessList(res.data.data)
                if(res.data.data.length === 0){
                    setSearchPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setSearchPagination(last_page)
                  }
            }
        }).catch((error) => {
            setLoadingBusinessList(false);
            setSearchPagination(0)
        })
    }

    const getBusinessListNames = () => {
        setFetchingBusinessNames(true);

        BusinessService.getBusinessListNames().then((res) => {
            console.log("getBusinessListNames data xxxx", res);
            setFetchingBusinessNames(false);

            if (res.data.success) {
                setBusinessNames(res.data.data);
                setFetchingBusinessNames(false);
            }
        }).catch((error) => {
            console.log("getBusinessListNames error xxxx", error);

            setFetchingBusinessNames(false);
        })
    }

    const getBusinessById = (id) => {
        setFetchingBusinessDetails(true);
        BusinessService.getBusinessByID(id).then((res) => {
            // console.log("getBusinessById data xxxx userxx", res);

            if (res.data.success) {
                setFetchingBusinessDetails(false);
                setBusinessDetails(res.data.data);
            }
            
        }).catch((error) => {
            // console.log("getBusinessById error xxxx", error);
            setFetchingBusinessDetails(false);
            setErrorError(error.response.statusText)
        })
    }

    const updateBusiness = (formData, business_id) => {
        setUpdatingBusiness(true);

        BusinessService.updateBusiness(formData).then((res) => {
            console.log("updateBusiness data xxxx", res.data);
            
            if (res.data.success) {
                setUpdatingBusiness(false);
                
                // window.location.href = "/view?id=" + business_id;
                navigate("/view?id=" + business_id);
            }

        }).catch((error) => {
            console.log("updateBusiness error xxxx", error);

            setUpdatingBusiness(false);
            if (error.response.data.error) {
                enqueueSnackbar(error.response.data.error, {
                  variant: "error",
                  autoHideDuration: 3000
                });
                return
            }
        })
    }

    const searchPrincipalNameById = (principal_name, id) => {
        setIsLoadingSearchPrincipalNameById(true)
        BusinessService.searchPrincipalNameyID(principal_name, id).then(res => {
          console.log("search for:", res)
          setIsLoadingSearchPrincipalNameById(false)
          setSearchPrincipalNameById(res.data.data)
        }).catch((err)=>{ 
          setIsLoadingSearchPrincipalNameById(false)
        })
      }

    
    const getTransactionCounts = () => {
        setLoadingCounts(true)
        BusinessService.getCounts().then((res)=>{
            console.log('get all counts here', res)
            if(res.data.success){
                getCounts(res.data.data)
                setLoadingCounts(false)
            }
            
        }).catch((error) => {
            setLoadingCounts(false)
            console.log("getTransactionCounts error xxxx", error);
            setError(error.response.statusText);

        })
    }

    const getBusinessOverviewStats = (businessId) => {
        setFetchingBusinessOverviewStats(true);

        BusinessService.getBusinessOverviewStats(businessId).then((res) => {
            console.log("getBusinessOverviewStats data xxxx", res);

            if (res.data.success) {
                setFetchingBusinessOverviewStats(false);
                setBusinessOverviewStats(res.data.data);
            }

        }).catch((error) => {
            console.log("getBusinessOverviewStats error xxxx", error);

            setFetchingBusinessOverviewStats(false);
        })
    }

    const getBusinessUsers = (businessId) => {
        setFetchingBusinessUsers(true);

        BusinessService.getBusinessUsers(businessId).then((res) => {
            console.log("getBusinessUsers data xxxx", res);

            if (res.data.success) {
                setFetchingBusinessUsers(false);
                setBusinessUsers(res.data.data);
            }

        }).catch((error) => {
            console.log("getBusinessUsers error xxxx", error);

            setFetchingBusinessUsers(false);
        })
    }


    return {response, error, loading, pagination, businessList, setLoadingBusinessList, isLoadingBusinessList, createBusiness, getBusinessList, business_names, getBusinessListNames,  getBusinessById,
        businessDetails, isUpdatingBusiness, fetchingBusinessDetails, updateBusiness, searchPrincipalNameById, isLoadingSearchPrincipalNameById, searchPrincipalNameByIdData, counts, getCounts, getTransactionCounts,
        searchByBusinessName, getSearchByBusinessName, searchPagination, loadingCounts, isCreated, isFetchingBusinessNames, businessId, getBusinessListStatus, getBusinessListPlan,
        getBusinessListPlanWithStatus, getBusinessListNameWithStatus, getBusinessListWebsite, getBusinessListWebsiteWithStatus, isFetchingBusinessOverviewStats, businessOverviewStats, getBusinessOverviewStats, 
        isFetchingBusinessUsers, businessUsers, getBusinessUsers, getAllBusinessList, error_error, getSortBusinessList
    }
}

export default useBusiness
