import { useEffect, useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Stack, Select, MenuItem, TextField, InputAdornment, FormControl, InputLabel, Button, Pagination } from "@mui/material";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import useProjects from "../hooks/useProjects";
import useActivityLogs from "../hooks/useActivityLogs";
import EnterpriseActivityLogsComponent from "../components/enterprise/activity-logs/enterprise-activity-logs.component";
import ActivityLogsSkeletonComponent from "../components/enterprise/activity-logs/activity-logs-skeleton.component";
import useBusiness from "../hooks/useBusiness";
import ForbiddenScreen from "./ForbiddenScreen";

function TableHeader() {
    const tablecell = [
        { label: "Email" },
        { label: "Project" },
        { label: "Module" },
        { label: "Actions" },
        { label: "Activity" },
        { label: "Timestamp" },
    ];

    return (
        <TableHead sx={{ borderRadius: 2 }}>
            <TableRow sx={{ position: "sticky", bgcolor: "none", borderRadius: 2 }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: "#0178f2", color: "#0178f2", bgcolor: "none" }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const EnterpriseActivityLogsScreen = () => {

    const { isFetchingActivityLogs, auditList, getBusinessAuditsLogs, businessSearchActivityLogsFilters,
        searchBusinessActivityLogsList, pagination, businessSearchEmailFilters, error
    } = useActivityLogs();

    const { projects, getProjectList } = useProjects();

    const { businessUsers, getBusinessUsers } = useBusiness();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [monthValue, setMonthValue] = useState("");
    const [page, setPage] = useState(1);
    const [action, setAction] = useState("");
    const [module, setModule] = useState("");
    const [email, setEmail] = useState("");
    const [searchAuditLogs, setSearchAudit] = useState("");
    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [userEmailOptions, setUserEmailOption] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState("");

    // const businessId = (new URLSearchParams(window.location.search)).get("id");
    const businessId = (new URLSearchParams(window.location.search)).get("id");

    console.log("auditList aaa", auditList)


    useEffect(() => {
        getBusinessUsers(businessId);
    }, [])

    console.log("businessUsersxxxx", businessUsers)

    const userEmails = () => {
        if (!businessUsers) {
            console.error("User email list is null or undefined.");
            return;
        }


        const user_email_option = [...businessUsers?.map((user) => ({
            value: user?.user_id,
            label: user?.user_principal_name
        }))];

        setUserEmailOption(user_email_option);

    }

    useEffect(() => {
        userEmails();
    }, [JSON.stringify(businessUsers)]);

    useEffect(() => {
        getProjectList(businessId);

        if (auditList?.length > 0) {
            auditList?.map((list) => {
                let project_id = list?.projectId;

                projects?.map((project) => {
                    if (project?.id == project_id) {
                        list.project_name = project?.application_name;
                    }
                })
            })
        }
    }, [JSON.stringify(auditList), JSON.stringify(projects)])

    useEffect(() => {
        if (selectedEmail) {
            businessSearchEmailFilters(businessId, selectedEmail?.value, page);

        } else {
            getBusinessAuditsLogs(businessId, page);
        }
    }, [JSON.stringify(selectedEmail)]);


    const generateArrayOfYears = () => {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };

            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    const getMonthNames = () => {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, "0");

            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]

    const monthName = monthNames[monthValue - 1];

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        const mName = monthNames[event.target.value - 1];
        const paddedMonth = selectedMonth.toString().padStart(2, "0");

        setMonth(paddedMonth);
        setMonthValue(selectedMonth);
        setIsMonthSelected(true);

        if (searchAuditLogs) {
            searchBusinessActivityLogsList(businessId, mName, year, module, action, page, searchAuditLogs);

        } else {
            businessSearchActivityLogsFilters(businessId, mName, year, module, action, page);
        }
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);

        const yearChange = event.target.value;

        if (searchAuditLogs) {
            searchBusinessActivityLogsList(businessId, monthName, yearChange, module, action, page, searchAuditLogs);

        } else {
            businessSearchActivityLogsFilters(businessId, monthName, yearChange, module, action, page);
        }
    }

    const handleChangePagination = (event, page) => {
        setPage(page);

        if (!searchAuditLogs) {
            if (module || action || year && month) {
                businessSearchActivityLogsFilters(businessId, monthName, year, module, action, page);

            } else {
                if (selectedEmail) {
                    businessSearchEmailFilters(businessId, selectedEmail?.value, page);
                }
            }

        } else {
            searchBusinessActivityLogsList(businessId, monthName, year, module, action, page, searchAuditLogs);
        }
    }

    const searchModule = (e) => {
        setModule(e.target.value);
    }

    const keyPressModule = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchBusinessActivityLogsList(businessId, monthName, year, module, action, page, searchAuditLogs);

            } else {
                businessSearchActivityLogsFilters(businessId, monthName, year, module, action, page);
            }
        }
    }

    const searchAction = (e) => {
        setAction(e.target.value);
    }

    const keyPressAction = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchBusinessActivityLogsList(businessId, monthName, year, module, action, page, searchAuditLogs);

            } else {
                businessSearchActivityLogsFilters(businessId, monthName, year, module, action, page);
            }
        }
    }

    const searchAudit = (e) => {
        setSearchAudit(e.target.value);
    }

    const keyPressAudit = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchBusinessActivityLogsList(businessId, monthName, year, module, action, page, searchAuditLogs);

            } else {
                businessSearchActivityLogsFilters(businessId, monthName, year, module, action, page);
            }
        }
    }

    const handleReload = () => {
        setYear("");
        setEmail("");
        setModule("");
        setAction("");
        setMonthValue("");
        setSearchAudit("");
        setIsMonthSelected(false);
        setSelectedEmail("");
        getBusinessAuditsLogs(businessId, page);
    }


    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = auditList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    if (isFetchingActivityLogs) {
        return (
            <Box sx={{ overflow: "hidden" }}>
                <Grid container>
                    <Grid item xs={12} sm={12} sx={{ display: "flex", alignItems: "center", alignSelf: "center", mb: 0, backgroundColor: "transparent", p: 2, borderRadius: 2 }}>
                        <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>
                            <Box>
                                <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>
                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={module}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: "italic" } }}
                                        placeholder="Search Module..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" >
                                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                    Module:
                                                </p>
                                            </InputAdornment>,
                                        }}
                                        onChange={searchModule}
                                        onKeyDown={keyPressModule}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={action}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Actions..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Actions:</p></InputAdornment>,
                                        }}
                                        onChange={searchAction}
                                        onKeyDown={keyPressAction}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={searchAuditLogs}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Activity..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Activity:</p></InputAdornment>,
                                        }}
                                        onChange={searchAudit}
                                        onKeyDown={keyPressAudit}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={email}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Email..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Email:</p></InputAdornment>,
                                        }}
                                    />
                                </Stack>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                    Month
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    value={month}
                                    sx={{ width: 150, mr: 2, height: 38, borderRadius: 10, backgroundColor: "transparent" }}
                                    onChange={handleMonthChange}
                                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}

                                >
                                    {getMonthNames().map((monthInfo, index) => (
                                        <MenuItem key={monthInfo.value} value={monthInfo.value} sx={{ fontSize: 12 }}>
                                            {monthInfo.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                    Year
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Year"
                                    value={year}
                                    sx={{
                                        width: 150, height: 38, borderRadius: 10, backgroundColor: "transparent",
                                        '&.Mui-focused': {
                                            border: 'none',
                                        },
                                        border: isMonthSelected && year === "" ? '1px solid red' : "",
                                    }}
                                    onChange={handleYearChange}
                                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                                >
                                    {years?.map((year) => {
                                        return (
                                            <MenuItem key={year.value} value={year.value} sx={{ fontSize: 12 }}>
                                                {year.value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container sx={{ pt: 0 }}>
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", mt: "12px" }}>
                        <Button sx={{ textTransform: "none", justifyContent: "end", fontSize: 12, fontWeight: "bold" }}>
                            <CachedIcon sx={{ height: 20, width: 20 }} />

                            Reload
                        </Button>

                        <Box sx={{ flexGrow: 1 }} />
                    </Box>

                    <TableContainer component={Paper} sx={{ border: 'none', borderRadius: 2, width: '100%' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHeader />

                            <TableBody>
                                <ActivityLogsSkeletonComponent />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        )
    }

    return (
        <Box sx={{ overflow: "hidden" }}>
            {error ?
                <ForbiddenScreen />
                :

                <>
                    <Grid container>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', mb: 0, backgroundColor: "transparent", p: 2, borderRadius: 2 }}>
                            <Stack direction="row" spacing={1} sx={{ width: '99%', margin: 'auto' }}>
                                <Box>
                                    <Stack direction="row" spacing={2} sx={{ width: '99%', margin: 'auto' }}>
                                        <TextField
                                            className="card-search"
                                            variant="outlined"
                                            autoComplete="off"
                                            value={module}
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder="Search Module..."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                        Module:
                                                    </p>
                                                </InputAdornment>,
                                            }}
                                            onChange={searchModule}
                                            onKeyDown={keyPressModule}
                                        />

                                        <TextField
                                            className="card-search"
                                            variant="outlined"
                                            autoComplete="off"
                                            value={action}
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder="Search Actions..."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                        Actions:
                                                    </p>
                                                </InputAdornment>,
                                            }}
                                            onChange={searchAction}
                                            onKeyDown={keyPressAction}
                                        />

                                        <TextField
                                            className="card-search"
                                            variant="outlined"
                                            autoComplete="off"
                                            value={searchAuditLogs}
                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: "transparent", width: 250 } }}
                                            inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                            placeholder="Search Activity..."
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                        Activity:
                                                    </p>
                                                </InputAdornment>,
                                            }}
                                            onChange={searchAudit}
                                            onKeyDown={keyPressAudit}
                                        />

                                        <ReactSelect
                                            options={userEmailOptions}
                                            value={selectedEmail}
                                            onChange={selectedOption => setSelectedEmail(selectedOption)}
                                            isSearchable={true}
                                            placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Email:</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Search Email...</i></>} // Set the placeholder here
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 28,
                                                    borderRadius: 100,
                                                    backgroundColor: "transparent",
                                                    width: 250,
                                                    fontSize: 12,
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    maxHeight: '200px',
                                                    fontSize: 12
                                                }),
                                                menuList: base => ({
                                                    ...base,
                                                    maxHeight: '200px',
                                                    overflowY: 'auto',
                                                    fontSize: 12
                                                })
                                            }}
                                        />

                                    </Stack>
                                </Box>

                                <Box sx={{ flexGrow: 1 }} />

                                <FormControl>
                                    <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                        Month
                                    </InputLabel>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Month"
                                        value={monthValue}
                                        sx={{ width: 150, mr: 2, height: 38, borderRadius: 10, backgroundColor: "transparent" }}
                                        onChange={handleMonthChange}
                                        inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                                    >
                                        {getMonthNames().map((monthInfo, index) => (
                                            <MenuItem key={monthInfo.value} value={monthInfo.value} sx={{ fontSize: 12 }} >
                                                {monthInfo.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                        Year
                                    </InputLabel>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Year"
                                        value={year}
                                        sx={{
                                            width: 150, height: 38, borderRadius: 10, backgroundColor: "transparent",
                                            "&.Mui-focused": { border: "none" },
                                            border: isMonthSelected && year === "" ? '1px solid red' : "",
                                        }}
                                        onChange={handleYearChange}
                                        inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                                    >
                                        {years?.map((year) => {
                                            return (
                                                <MenuItem key={year.value} value={year.value} sx={{ fontSize: 12 }} >
                                                    {year.value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ pt: 0 }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", mt: "12px" }}>
                            <Button sx={{ textTransform: "none", justifyContent: "end", fontSize: 12, fontWeight: "bold" }}
                                onClick={() => handleReload()}
                            >
                                <CachedIcon sx={{ height: 20, width: 20 }} />

                                Reload
                            </Button>

                            <Box sx={{ flexGrow: 1 }} />
                        </Box>

                        <TableContainer component={Paper} sx={{ border: "none", borderRadius: 2, width: "100%" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {isFetchingActivityLogs ?
                                    <TableBody>
                                        <ActivityLogsSkeletonComponent />
                                    </TableBody>

                                    :
                                    <TableBody>
                                        {(currentItems.length > 0) ?
                                            <>
                                                {(currentItems && currentItems)?.map((list, index) => (
                                                    <EnterpriseActivityLogsComponent list={list} />
                                                ))}
                                            </>
                                            :
                                            <>
                                                {!isFetchingActivityLogs && (
                                                    <TableRow key={0}>
                                                        <TableCell colSpan={6} align="center">
                                                            <Typography sx={{ textAlign: "center" }}>
                                                                No activity logs available.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </>
                                        }
                                    </TableBody>
                                }
                            </Table>

                            {!isFetchingActivityLogs && auditList.length > itemsPerPage && (
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: "auto", width: "fit-content", paddingTop: 3, paddingBottom: 3 }}>
                                    <Pagination count={Math.ceil(auditList.length / itemsPerPage)} page={currentPage} onChange={(event, value) => paginate(value)} />
                                </Grid>
                            )}

                            {/* <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                        /> */}
                        </TableContainer>
                    </Grid>
                </>
            }
        </Box>
    )
}

export default EnterpriseActivityLogsScreen