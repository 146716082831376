import * as React from 'react';
import {Grid, Card, CardActionArea, CardContent, Typography, Menu, MenuItem, IconButton, Box , CircularProgress, Backdrop} from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import DocxPreviewDialog from './dialog/docx-preview-dialog.component'
import {ThreeDots} from "react-loader-spinner";
import { useEffect } from 'react';
import IPFSIcon from "../../../icons/ipfs-icon.png"
import BlobIcon from "../../../icons/azure-blob-icon.png"

function VaultCardsComponent({breakpoints, list, downloadFile, previewFile, isLoading, isPreviewLoading, index, }){

    let businessId = (new URLSearchParams(window.location.search)).get("id");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openDocxPreview, setOpenDocxPreview] = React.useState(false)
    const [openBackdrop, setOpenBackdrop] = React.useState(false)

    const handleClose = () => {
        setOpenDocxPreview(false)
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleDownloadFile = () => {

        let filename = list?.document_title ? list?.document_title : list?.filename;
        downloadFile(list?.reference, filename, businessId);
      
    }

    // const handlePreviewFile = (e) => {
    //     setOpenBackdrop(true)
    //     previewFile(list?.reference, setOpenDocxPreview, setOpenBackdrop);
    // }
    const handlePreviewFile = (e) => {
        console.log('asdfhasoidfasdlhflasdhfasdf', e)
        // setOpenBackdrop(true)
        previewFile(list?.reference, setOpenDocxPreview, setOpenBackdrop, e, businessId);
    }

   

    let myString = list?.filename;
    let title = list?.document_title;
    
    let documentName = (title && title.length > 25) ? `${title.slice(0, 25)}...` : (title);
    let fileName =   (myString && myString.length > 25) ? `${myString.slice(0, 25)}...` : (myString);

    // KB converter
    const kilobytes = list?.file_size / 1024;


    const [expiration, setExpiration] = React.useState('')

    const [remainingDays, setRemainingDays] = React.useState(null);
    
    useEffect(()=>{
        const calculateRemainingDays = () => {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 30);
            const formattedCurrentDate = currentDate.toISOString();
      
            if (list?.encrypted_file_url === "" || list?.date_last_modified <= formattedCurrentDate) {
              setExpiration("Expired");
              setRemainingDays(0);
            } else {

              const createdDate = new Date(list?.date_last_modified);
              const currentDate = new Date(formattedCurrentDate);
              const timeDiff = createdDate.getTime() - currentDate.getTime();
              const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          
              setRemainingDays(remainingDays);
              setExpiration("Not expired");
            }
            
          };
      
          calculateRemainingDays();
         

    },[])

    return(
        <Box  className='card-list'>
            <Card sx={{borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: 2}}>
                <Box sx={{justifyContent:'end', marginBottom: 1, display:'inline-flex', alignItems:'center', width:'100%'}}>
                    {expiration == "Expired" ? 
                        <img alt="" src={IPFSIcon} key={index} style={{width: 20,height: 28, objectFit:"contain"}}/>
                    :
                        <>
                            <img alt="" src={BlobIcon} key={index} style={{width: 40,height: 28, objectFit:"contain"}}/>
                            <img alt="" src={IPFSIcon} key={index} style={{width: 20,height: 20, objectFit:"contain"}}/>
                        </>
                    }
                </Box>

                <CardActionArea onClick={(e) => handlePreviewFile(list?.thumbnail_uri)} sx={{cursor: "pointer"}}>
                    <img alt="" className='card-data' 
                        src={list?.thumbnail_uri} key={index} 
                        style={{width: '100%', height: 110, objectFit: "contain", cursor:"pointer" }}
                    />
                </CardActionArea>

                <CardContent sx={{textAlign:'center'}}>
                    <Typography className='card-name' sx={{fontSize: 12}}>
                        {list?.document_title ? documentName : fileName}
                    </Typography>
                </CardContent>

                <CardContent sx={{textAlign:'center', paddingTop: 0}}>
                    <Typography className='card-size' sx={{fontSize: 12}}>
                        {kilobytes + " KB"}
                    </Typography>
                </CardContent>

                    <Box sx={{textAlign:'center'}}>
                        <IconButton id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className='card-manage'
                        >
                            <MoreHorizIcon sx={{color: 'black'}}/>
                        </IconButton>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={(e) => handleDownloadFile(e)} sx={{fontSize: 12}}>
                            {isLoading ? <><ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} /> Downloading</> : <>  <DownloadIcon/> Download </>}
                            </MenuItem>
                        </Menu>
                    </Box>

                {/* LOADER */}
                <Backdrop open={openBackdrop} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <Grid sx={{display: "block", margin: "auto", textAlign: "center"}}>
                        <Typography sx={{color: "#fff", fontWeight: "bold", mb: "-5px", fontSize: "22px"}}>
                            Decrypting
                        </Typography>

                        <ThreeDots 
                            height="50" 
                            width={"80"}
                            radius="9"
                            color="#fff" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{margin: "auto", textAlign: "center", justifyContent: "center"}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </Grid>  
                </Backdrop>

                <Typography className='card-stored' sx={{fontStyle:"italic", fontSize: 10, textAlign:'end'}}>
                    {remainingDays == 0 ? 
                    <>.</>
                    :
                    <>Remaining Days: {remainingDays}</>
                    }
                    
                </Typography>
            </Card>
            {/* Dialog */}
            {openDocxPreview &&
            <DocxPreviewDialog isLoading={isLoading} openDocxPreview={openDocxPreview} handleClose={handleClose} list={list} handleDownloadFile={handleDownloadFile}/>
            }
        </Box>
    )
}
export default VaultCardsComponent