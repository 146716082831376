import { Box, Button, Card, IconButton, Grid, Container} from "@mui/material"
import Avatar from "react-avatar"
import logo from "../icons/logo1.jpg"
import EditIcon from '../icons/edit.png'
import useWidth from "../hooks/useWidth"
import AppConfigurationDetailsComponent from "../components/app-configuration/app-configuration-details.component"
import BackButton from "../components/common/back-button"
import { useNavigate } from "react-router-dom"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useAppConfig from "../hooks/useAppConfig"
import { useEffect, useState } from "react"
import UpdateAppConfigDialog from "../components/app-configuration/dialog/update-app-config-dialog.component"

function ViewAppConfigurationScreen () {

    const breakpoints = useWidth()
    const navigate = useNavigate();
    const {viewAppConfig, appConfigDetails, deleteAppConfig, isDeleting, updateAppConfig} = useAppConfig()
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenUpdateAppConfig = () => {
        setOpenDialog(true)
    }

    const handleCloseCreateDialog = () => {
        setOpenDialog(false)
    }

    const url = window.location.pathname;

    let app_code = (new URLSearchParams(window.location.search)).get("appCode");
    console.log('GETTTTTTTTTT APP CODE HERE', appConfigDetails)

    useEffect(()=>{
        viewAppConfig(app_code)
    },[])

    return(
        <Box>
            <Box sx={{textAlign: 'start', pb: 2, mt: -1.5}}>
                <Button startIcon={<ArrowBackIosIcon style={{height: 20, color: '#0178f2'}} />} 
                    style={{color: "black", fontSize: 16, textTransform: 'none' }}
                    onClick={() => navigate(-1)}
                >
                    {appConfigDetails?.app_name}
                </Button>
            </Box>

            <Card sx={{padding: 4, borderRadius: 2}}>
                <Grid container spacing={2} sx={{textAlign: "start"}}>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{textAlign: "start", paddingLeft: 0}}>
                        <Avatar variant="square" src={logo} 
                            sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 100, height: 'auto',  objectFit: 'contain' }} 
                        />
                    </Grid>

                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{textAlign: "end"}}>
                        <IconButton color="primary" component="label" onClick={handleOpenUpdateAppConfig}>
                            <img alt="edit-icon" src={EditIcon} style={{height: 24}} />
                        </IconButton>
                    </Grid>
                    <br/>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <AppConfigurationDetailsComponent updateAppConfig={updateAppConfig} appConfigDetails={appConfigDetails} 
                        deleteAppConfig={deleteAppConfig} isDeleting={isDeleting} />
                    </Grid>
                </Grid>
            </Card>

            {/* UPDATE DIALOG */}
            <UpdateAppConfigDialog openDialog={openDialog} handleClose={handleCloseCreateDialog} appConfigDetails={appConfigDetails} />
        </Box>
    )
}

export default ViewAppConfigurationScreen