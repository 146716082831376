import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import useRolesManagement from "../../../hooks/useRolesManagement"
import { ThreeDots } from 'react-loader-spinner';
import usePackageManagement from "../../../hooks/usePackageManagement";


const CreateFeatureDialog = (props) => {

    const { openAddFeature, handleClose, getPackageSummary } = props;

    const breakpoints = useWidth();
    const { createFeature, creatingFeature, createdFeature } = usePackageManagement();

    const [feature_name, setFeatureName] = useState('');
    const [feature_description, setFeatureDescription] = useState('');

    const handleTextFieldChange = (e, name) => {
        if (name == "feature_name") {
            setFeatureName(e.target.value);

        } else if (name == "feature_description") {
            setFeatureDescription(e.target.value);

        }
    }


    const handleCreateFeature = () => {

        let payload = {
            feature_title : feature_name,
            description : feature_description,
        }

        createFeature(payload);

    }

    useEffect(() => {

        if(createdFeature){
            handleClose()
            // window.location.reload();
            getPackageSummary();
        }

    },[createdFeature])
 
   
    return (
        <Dialog open={openAddFeature} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Add Feature
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature Title :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={feature_name}
                            onChange={(e) => handleTextFieldChange(e, "feature_name")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Feature Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={feature_description}
                            onChange={(e) => handleTextFieldChange(e, "feature_description")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                   onClick={() => handleCreateFeature() }
                   startIcon={creatingFeature && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {creatingFeature ? "Creating" : "Create"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFeatureDialog