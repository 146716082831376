import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
// import { useHistory } from 'react-router-dom'; // Import useHistory
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import AccountDetailsComponent from "./view-business-account-components/account-details.component";
import AuthenticationComponent from "./view-business-account-components/authentication.component";
import ViewBusinessAccountUsersComponent from "./view-business-account-users.component";
import ProjectListScreen from "../../screens/ProjectListScreen";
import EnterpriseDashboardComponent from "../enterprise/dashboard/enterprise-dashboard.component";
import EnterprisePlanComponent from "../enterprise/plan/enterprise-plan.component";
import EnterpriseReportsComponent from "../enterprise/reports/enterprise-reports.component";
import EnterpriseActivityLogsScreen from "../../screens/EnterpriseActivityLogsScreen";
import EnterpriseTransactionComponent from "../enterprise/enterprise-transactions.component";
import EnterpriseTemplatesComponent from "../enterprise/templates/enterprise-templates.component";
import TemplateManagementComponent from "../enterprise/templates/template-management.component";
import VaultScreen from "../../screens/VaultScreen";
import ProjectManagementScreen from "../../screens/ProjectManagementScreen";
import EnterpriseReportsTabsComponent from "../enterprise/reports/enterprise-reports-tabs.component";

function useTabs(props){

  const { setEnterpriseName } = props;
  

  const [ analytics_tab, setAnalyticsTab ] = useState(null);

  function setupTabs(){

    var tabs_default = [
      {name: "Dashboard", component: <EnterpriseDashboardComponent />},
      {name: 'Details', component:<AccountDetailsComponent setEnterpriseName={setEnterpriseName} /> },
      {name: 'Projects', component:  <ProjectManagementScreen />},
      {name: 'Vault', component: <VaultScreen />},
      {name: 'Authentication', component:<AuthenticationComponent/> },
      {name: 'Templates', component:   <TemplateManagementComponent />},
      {name: 'Analytics', component:   <EnterpriseTransactionComponent />},
      {name: 'Users', component:  <ViewBusinessAccountUsersComponent />},
      {name: 'Reports', component:   <EnterpriseReportsTabsComponent/>},
      {name: "Plan", component:  <EnterprisePlanComponent />},
      {name: "Activity Logs", component: <EnterpriseActivityLogsScreen />}
    ]

    setAnalyticsTab(tabs_default);
  }

  useEffect(()=>{ setupTabs(); },[])

  return analytics_tab;

}


function ViewBusinessAccountComponent(props){

   const {breakpoints, businessDetails, fetchingBusinessDetails, setEnterpriseName} = props;

  const tabs = useTabs(props);

  const [ value, setValue ] = useState(0);
  
  // const storedTab = localStorage.getItem('selectedTab');
  // const initialTabValue = storedTab ? parseInt(storedTab) : 0;
  // const [value, setValue] = useState(initialTabValue);
  

  // console.log("storedTabxxx", storedTab)

  
  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('selectedTab');
  //   };
  // }, []);

  function TabPanel(props) {
      
      const { children, value, index, ...other } = props;
      
    
      return (
        <div
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue); // Store selected tab index
  }

  
  // function handleChange(event, newValue) {
  //   setValue(newValue);
    // localStorage.setItem('selectedTab', newValue); // Store selected tab index
    // const selectedTabName = tabs[newValue].name.toLowerCase();
    // const currentUrl = new URL(window.location.href);
    // const idValue = currentUrl.searchParams.get("id");
    
    // // Remove any previous tab name from the URL
    // let newPath = currentUrl.pathname.replace(/\/[^\/]+$/, '');
  
    // // Append the id parameter to the URL
    // newPath += `view?id=${idValue}`;
  
    // // Remove the existing tab name from the URL if it exists
    // newPath = newPath.replace(/\/[^\/]+$/, '');
  
    // // Append the new tab name to the URL
    // newPath += `/${selectedTabName}`;
    
    // window.history.pushState({ path: newPath }, '', newPath);
  // }
  
  

  
  

  return(
        <Box>
            <CustomTabs
                variant= "scrollable"
                value={value} 
                onChange={handleChange} 
                sx={{marginTop: 2}} 
                indicatorColor="transparent"
                centered
            >
                { tabs?.map((tab, index)=>{
                    return(
                        <CustomTab label={tab.name} disableRipple {...a11yProps(index)}   />
                    )}) 
                }
          </CustomTabs>

          {tabs?.map((tab, index) => {
            return (
              <Box sx={{width: "98%", margin: "auto"}}>
                <TabPanel value={value} index={index} style={{padding: "0px"}}>
                  {tab.component}
                </TabPanel> 
              </Box>
            )})
          }
        </Box>
  )
}

export default ViewBusinessAccountComponent;
