import { ListItemAvatar, Avatar, Box, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, IconButton, Stack, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import ReactSelect from 'react-select';
import useReports from "../../../hooks/useReports";
import ReportOneSkeletonComponent from "../../skeletons/report-one-skeleton-component";
import { stringAvatar } from "../../../utils/stringAvatarUtil";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import CachedIcon from '@mui/icons-material/Cached';
import EnterpriseReportSkeletonComponent from "../../skeletons/enterprise-report-skeleton.component";
import ViewEnterpriseReportsComponent from "./view-reports.component";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";

const ReportOneTableHeader = () => {

    const tablecell = [
        { label: 'Logo' },
        { label: 'Project Name' },
        { label: 'Project Description' },
        { label: 'No. of Authentication' },
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ReportOneTableContent = (props) => {

    const { project, breakpoints, handleView } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer' }} onClick={() => handleView(project && project?.project?.name, project && project?.project_id)}>
                {(project && project?.project?.logo) ?
                    <Avatar variant="square" src={project?.project?.logo ? project?.project?.logo : null}
                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 50, height: 50, objectFit: "contain" }}
                        imgProps={{ sx: { objectFit: "contain" } }}
                    />
                    :
                    <ListItemAvatar>
                        <Avatar {...stringAvatar(project && project?.project.name)} style={{ height: 50, width: 50 }} />
                    </ListItemAvatar>
                }
            </TableCell>
            <TableCell component="th" scope="row" sx={{ cursor: 'pointer', color: "#0178f2" }} onClick={() => handleView(project && project?.project?.name, project && project?.project_id)} >
                {project && project?.project?.name}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {project && project?.project?.description}
            </TableCell>

            <TableCell component="th" scope="row"  >
                {project && project?.total_authenticated_count}
            </TableCell>

        </TableRow>
    )
}



const EnterpriseReportsComponent = () => {

    // const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    const { getBusinessById, businessDetails } = useBusiness();
    const { loading, generateReportByEnterprise, enterprise_reports, total_authentication, pagination, setLoading, error } = useReports();


    const [page, setPage] = useState(1);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);
    const [is_view, setIsView] = useState(false);
    const [project_name, setProjectName] = useState('');
    const [project_id, setProjectId] = useState(null);


    const handleView = (projectname, id) => {
        setIsView(true);
        setProjectName(projectname);
        setProjectId(id);
    }

    useEffect(() => {

        if (businessIdFromURL) {
            getBusinessById(businessIdFromURL);
        }

    }, [businessIdFromURL])

    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])


    useEffect(() => {
        if ((businessIdFromURL) && dateFrom && dateTo) {
            const businessToUse = businessIdFromURL;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");
            generateReportByEnterprise(businessToUse, date_from, date_to, page);
        }
    }, [businessIdFromURL, dateFrom, dateTo, page]);


    const reload = () => {
        setDateFrom(reload_dateFrom)
        setDateTo(reload_dateTo)

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        generateReportByEnterprise(businessToUse, date_from, date_to, page);
    }


    const handleChangePagination = (event, page) => {
        setPage(page);

        let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
        let date_to = dayjs(dateTo).format("YYYY-MM-DD");

        generateReportByEnterprise(businessToUse, date_from, date_to, page);
        setLoading(true);
    }

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", options);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }



    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);

        const business = businessDetails?.business_name;
        const title = `${business} Report as of ${formattedDateRange}`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth) / 100;
        const titleY = 10;

        doc.text(title, titleX, titleY);

        const columns = ["Logo", "Project Name", "No. of Authentication"];

        const data = enterprise_reports.map(item => {
            const logoDataUrl = item?.project?.logo;
            return [logoDataUrl, item?.project.name, item.total_authenticated_count];
        });

        doc.setFontSize(12);

        doc.autoTable({
            head: [columns],
            body: data,
            startY: 20,
            willDrawCell: (hookData) => {
                if (hookData.section === 'body' && columns[hookData.column.index] === 'Logo') {
                    hookData.cell.text = '';
                }
            },
            didDrawCell: (hookData) => {
                if (hookData.section === 'body' && columns[hookData.column.index] === 'Logo') {
                    const logoDataUrl = data[hookData.row.index][hookData.column.index];

                    if (logoDataUrl) {
                        const imgWidth = 10;
                        const imgHeight = 10;
                        doc.addImage(logoDataUrl, 'JPEG', hookData.cell.x + 1, hookData.cell.y + 1, imgWidth, imgHeight);
                    }
                }
            },
        });

        const overallTotal = total_authentication ? total_authentication : 0;

        const textWidth = doc.getStringUnitWidth(`Total Authentication: ${overallTotal}`) * 12;
        const textX = pageWidth - textWidth - 10;
        const textY = doc.autoTable.previous.finalY + 10;

        doc.text(`Total Authentication: ${overallTotal}`, textX, textY);

        doc.save(`${businessDetails?.business_name}-Document-Report.pdf`);
    };

    const businessToUse = businessIdFromURL;


    return (
        <Box>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    {is_view ?
                        <ViewEnterpriseReportsComponent project_name={project_name} project_id={project_id} />
                        :
                        <>
                            <Grid container >
                                <Grid item xs={12} sx={{ paddingLeft: 2}} >
                                    <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                        <Box>
                                            <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>

                                                <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                        <DatePicker
                                                            value={dateFrom}
                                                            required
                                                            views={["year", "month", "day"]}
                                                            inputProps={{ style: { fontSize: 12, height: 38 } }}
                                                            sx={{
                                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                                "& .MuiOutlinedInput-root": {
                                                                    height: 38,
                                                                    borderRadius: 10,
                                                                    fontSize: 12,
                                                                    "& > fieldset": {
                                                                    },
                                                                },
                                                            }}
                                                            onChange={(newValue) => { setDateFrom(newValue) }}
                                                            maxDate={dateTo}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                                &nbsp; &nbsp;

                                                <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                        <DatePicker
                                                            value={dateTo}
                                                            required
                                                            views={["year", "month", "day"]}
                                                            inputProps={{ style: { fontSize: 12 } }}
                                                            sx={{
                                                                fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                                "& .MuiInputLabel-root": { fontSize: 12 },
                                                                "& .MuiOutlinedInput-root": {
                                                                    height: 38,
                                                                    fontSize: 12,
                                                                    borderRadius: 10,
                                                                    "& > fieldset": {
                                                                    },
                                                                },
                                                            }}

                                                            onChange={(newValue) => { setDateTo(newValue) }}
                                                            minDate={dateFrom}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>


                                                &nbsp; &nbsp;

                                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                                            </Stack>

                                        </Box>


                                        <Box sx={{ flexGrow: 1 }} />

                                        <Button
                                            variant="contained"
                                            onClick={generatePDF}
                                            sx={{
                                                width: 120, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none",
                                                ":hover": { background: "#0178f2" }
                                            }}
                                            disabled={enterprise_reports?.length === 0 || enterprise_reports === undefined ? true : false}
                                        >
                                            Download
                                        </Button>
                                    </Stack>

                                </Grid>

                            </Grid>

                            <Box sx={{ mt: 5 }} />



                            <Grid container sx={{ position: 'relative', zIndex: 2 }}>

                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%", mt: 1 }}>
                                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                                            <ReportOneTableHeader />

                                            {loading ?
                                                <EnterpriseReportSkeletonComponent />
                                                :
                                                <TableBody>
                                                    {(enterprise_reports && enterprise_reports)?.map((project, index) => (
                                                        <ReportOneTableContent key={index} project={project} handleView={handleView} />
                                                    ))}


                                                </TableBody>
                                            }
                                        </Table>

                                        {(!loading && (enterprise_reports?.length === 0 || enterprise_reports === undefined)) ? (

                                            <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                                <Grid container sx={{ padding: 3 }}>
                                                    <Box sx={{ textAlign: "center", margin: "auto" }}>
                                                        <Typography sx={{ textAlign: "center" }}>
                                                            No authentication record found for {formattedDateRange}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        ) : (!loading &&
                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleChangePagination}
                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                            />
                                        )}

                                    </TableContainer>
                                </Grid>
                                <br />
                                <Grid item xs={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', ml: -2, mt: 1 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Total Authentication : {total_authentication ? total_authentication : 0} </Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                </>
            }

        </Box>
    );
};

export default EnterpriseReportsComponent;
