import { Box, Button, Card, CardActions, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { ThreeDots } from 'react-loader-spinner';

import useWidth from "../../hooks/useWidth";
import BackButton from "../../components/common/back-button";
import ApproveConfirmationDialogComponent from "./email-authentication-dialog/approve-confirmation-dialog.component";
import DisapproveConfirmationDialogComponent from "./email-authentication-dialog/disapprove-confirmation-dialog.component";
import RevokeConfirmationDialogComponent from "./email-authentication-dialog/revoke-confirmation-dialog.component";
import EmailAuthenticationService from "../../services/api/email-authentication";
import useEmailAuthentication from "../../hooks/useEmailAuthentication";
import useEmailSender from "../../hooks/useEmailSender";
import { format } from "date-fns";
import ResetStatusConfirmationDialogComponent from "./email-authentication-dialog/reset-status-confirmation-dialog.component";
import EmailAuthenticationDetailsSkeletonComponent from "../skeletons/email-authentication-details-skeleton.component";
import RemoveConfirmationDialogComponent from "../enterprise/email-authentication-dialog/remove-confirmation-dialog.component";

function EmailAuthenticationDetailsComponent () {

    // HOOKS
    const {breakpoints} = useWidth();
    const {emailAuthenticationDetails, emailAuthenticationRecords, viewEmailAuthentication, isDetailsLoading} = useEmailAuthentication();
    const {testEmailSender, isLoadingTestEmailSender} = useEmailSender()

    const [openApproveConfirmationDialog, setOpenApproveConfirmationDialog] = useState(false);
    const [openDisapproveConfirmationDialog, setOpenDisapproveConfirmationDialog] = useState(false);
    const [openRevokeConfirmationDialog, setOpenRevokeConfirmationDialog] = useState(false);
    const [openResetConfirmationDialog, setOpenResetConfirmationDialog] = useState(false);

    const handleOpenRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(true); }

    const handleCloseRevokeConfirmationDialog = (e) => { setOpenRevokeConfirmationDialog(false); }

    const handleOpenApproveConfirmationDialog = (e) => { setOpenApproveConfirmationDialog(true); }

    const handleCloseApproveConfirmationDialog = (e) => { setOpenApproveConfirmationDialog(false); }

    const handleOpenDisapproveConfirmationDialog = (e) => { setOpenDisapproveConfirmationDialog(true); }

    const handleCloseDisapproveConfirmationDialog = (e) => { setOpenDisapproveConfirmationDialog(false); }

    const handleOpenResetConfirmationDialog = (e) => { setOpenResetConfirmationDialog(true); }

    const handleCloseResetConfirmationDialog = (e) => { setOpenResetConfirmationDialog(false); }

    const handleTestEmailSender = () => {
        testEmailSender(emailAuthenticationRecords.id, emailAuthenticationRecords.email)
    }

    const authentication = [
        {id: 1, business_name: "NextIX", user: "business_01@ovcode.co", project_name: "First Aide", approver: 1234321, email: "info.nextix.gmail.com", status_code:"PA", date_requested:'March 14, 2023 4:10:16 PM'},
        {id: 2, business_name: "NextIX", user: "business_02@ovcode.co", project_name: "First Aide",  approver: 234523, email: "info.nextix.gmail.com", status_code:"A", date_requested:'March 14, 2023 4:10:16 PM'},
        {id: 3, business_name: "NextIX", user: "business_03@ovcode.co", project_name: "First Aide",  approver: 9853324, email: "info.nextix.gmail.com", status_code:"DA", date_requested:'March 14, 2023 4:10:16 PM'},
        {id: 4, business_name: "NextIX", user: "business_04@ovcode.co", project_name: "First Aide",  approver: 35645745, email: "info.nextix.gmail.com", status_code:"R", date_requested:'March 14, 2023 4:10:16 PM'},

    ];

    let id = (new URLSearchParams(window.location.search)).get("id");

    const authenticationID = id;

    const authenticationDetails = authentication.find(item => item.id == authenticationID);

    useEffect(()=>{
        viewEmailAuthentication(id)
    },[])

    let status_code = emailAuthenticationRecords?.status_code;

    if (status_code === "PA") {
        status_code = 'PENDING FOR APPROVAL';
        } else if (status_code === 'A') {
        status_code = "APPROVED";
        } else if (status_code === 'DA') {
        status_code = "DISAPPROVED";
        } else if (status_code === 'R') {
        status_code = "REVOKED";
    }

    const authenticationLabel = [ 
        {label : 'Business Name', value: emailAuthenticationRecords?.businessAccount?.business_name},
        {label : 'Project Name', value: emailAuthenticationRecords?.accountProject?.application_name},
        {label : 'Email Address', value:emailAuthenticationRecords?.email},
        {label : 'User', value: emailAuthenticationRecords?.user?.user_principal_name},
        {label : 'Approver', value: emailAuthenticationRecords?.approver_id ? emailAuthenticationRecords?.approver_id : "NA"},
        {label : 'Date Requested', value: emailAuthenticationRecords?.datetime_requested},
        {label : 'Decision Date', value: emailAuthenticationRecords?.datetime_decision ? emailAuthenticationRecords?.datetime_decision : "NA"},
        {label : 'Date of Reason for Disapproval', value: emailAuthenticationRecords?.disapproval_reason ? emailAuthenticationRecords?.disapproval_reason : "NA"},
    ];

    return(
        <>
             <Box sx={{textAlign: 'start', pb: 2, mt: -1.5}}>
                <BackButton />
            </Box>
        
            <Card sx={{borderRadius: 2, padding: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', }} >
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} sx={{textAlign:'left', paddingLeft: 0, paddingRight: 3}}>
                            <Typography sx={{fontWeight: 600, fontSize: 16, color: status_code == "PENDING FOR APPROVAL" ? 'orange' : status_code == "APPROVED" ? 'green' : 'red'}}>
                                {isDetailsLoading ? 
                                <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={180}/>
                                :
                                <>{status_code}</>
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            
                                {authenticationLabel.map((label)=>{
                                    return(
                                        <Grid container direction="row">
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight:'bold', fontSize: 14, padding: "10px 0px 10px 0px"}}>
                                                    {label.label}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                {isDetailsLoading ?
                                                <EmailAuthenticationDetailsSkeletonComponent/>
                                                :
                                                <Typography sx={{width:'100%', padding: "10px 0px 10px 0px", textAlign:'justify', wordBreak: 'break-word', fontSize: 14}}>
                                                    {label.value}
                                                </Typography>          
                                                }
                                                
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{justifyContent:'end'}}>
                    {emailAuthenticationRecords?.status_code === "PA" ?
                        <>
                            <Button variant="contained" color="error" sx={{textTransform:'none', backgroundColor:'red'}} onClick={handleOpenDisapproveConfirmationDialog}>Deny</Button>
                            <Button variant="contained" sx={{textTransform:'none'}} onClick={handleOpenApproveConfirmationDialog}>Approve</Button>
                        </>
                        : emailAuthenticationRecords?.status_code === "A" ?
                            <>
                                {/* <Button variant="outlined" color="error" sx={{textTransform:'none'}} onClick={handleOpenResetConfirmationDialog}>Reset</Button> */}
                                <Button variant="contained" sx={{textTransform:'none'}} onClick={handleOpenRevokeConfirmationDialog}>Revoke</Button>
                                <Button variant="contained"
                                    startIcon={isLoadingTestEmailSender ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                                    sx={{textTransform:'none'}} onClick={handleTestEmailSender}>Test Email Sender</Button>
                            </>
                        :
                        <Button variant="outlined" color="error" sx={{textTransform:'none'}} onClick={handleOpenResetConfirmationDialog}>Remove</Button>
                    }
                    
                </CardActions>
            </Card>

            {/* APPROVE CONFIRMATION DIALOG */}
            {openApproveConfirmationDialog &&
                <ApproveConfirmationDialogComponent authenticationDetails={emailAuthenticationRecords} openApproveConfirmationDialog={openApproveConfirmationDialog} handleCloseApproveConfirmationDialog={handleCloseApproveConfirmationDialog}  />
            }

            {/* DISAPPROVE CONFIRMATION DIALOG */}
            {openDisapproveConfirmationDialog &&
                <DisapproveConfirmationDialogComponent authenticationDetails={emailAuthenticationRecords} openDisapproveConfirmationDialog={openDisapproveConfirmationDialog} handleCloseDisapproveConfirmationDialog={handleCloseDisapproveConfirmationDialog}  />
            }

            {/* REVOKE CONFIRMATION DIALOG */}
            {openRevokeConfirmationDialog &&
                <RevokeConfirmationDialogComponent authenticationDetails={emailAuthenticationRecords} openRevokeConfirmationDialog={openRevokeConfirmationDialog} handleCloseRevokeConfirmationDialog={handleCloseRevokeConfirmationDialog}  />
            }

            {/* RESET CONFIRMATION DIALOG */}
            {openResetConfirmationDialog &&
                <RemoveConfirmationDialogComponent authenticationDetails={emailAuthenticationRecords} openRemoveConfirmationDialog={openResetConfirmationDialog} handleCloseRemoveConfirmationDialog={handleCloseResetConfirmationDialog} />
                // <ResetStatusConfirmationDialogComponent authenticationDetails={emailAuthenticationRecords} openResetConfirmationDialog={openResetConfirmationDialog} handleCloseResetConfirmationDialog={handleCloseResetConfirmationDialog}  />
            }
        </>
    )
}

export default EmailAuthenticationDetailsComponent