import React from "react";
import {Skeleton, Card, ListItem, ListItemAvatar, ListItemText} from "@mui/material";

const DashboardProjectCreditSkeletonComponent = (props) => {

    const {breakpoints} = props;
    
    return (
        <Card sx={{mt: 1.5, mb: 1.5, cursor: "pointer", display: "flex"}}>
            <ListItem>
                <ListItemAvatar>
                    <Skeleton variant="circular" sx={{height: "50px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                </ListItemAvatar>
    
                <ListItemText
                    primary={
                        <Skeleton variant="text" sx={{mt: "-6px !important", borderRadius: "4px", mb: "3px", height: "24px", backgroundColor: "rgb(108 139 222 / 30%)"}} />                     
                    }
                />
            </ListItem>
        </Card>
    )
}

export default DashboardProjectCreditSkeletonComponent