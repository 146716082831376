import React, {useEffect} from "react";
import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import useWidth from "../hooks/useWidth";
import BackButton from "../components/common/back-button";
import UpdateBusinessAccountComponent from "../components/account/update-business-account.component";
import UpdateBusinessAccountSkeletonComponent from "../components/skeletons/update-business-account-skeleton.component";
import useBusiness from "../hooks/useBusiness";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const UpdateBusinessAccountScreen = () => {

    const breakpoints = useWidth();
    const navigate = useNavigate();

    const {fetchingBusinessDetails, businessDetails, getBusinessById} = useBusiness();

    useEffect(()=>{
        let businessId = new URLSearchParams(window.location.search).get("business_id");

        getBusinessById(businessId);
    },[])


    return (
        <Box>
            <Box sx={{textAlign: "start", pb: 2, mt: -1.5}}>
                {/* <BackButton /> */}
                <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                    style={{color: "black", fontSize: 16, textTransform: 'none', fontWeight : 600  }}
                    onClick={() => navigate(-1)} >
                    {businessDetails?.business_name}
                </Button>
            </Box>

            <Card sx={{width: "80%", border: "none", borderRadius: 2, margin: "15px auto", mt: 0,  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                <CardContent>
                    <Typography variant="h6" sx={{textAlign: "center"}}>
                        Update Enterprise Account
                    </Typography>
                    
                    <Grid container>
                        <Grid item xs={12}>
                            {(fetchingBusinessDetails) ? 
                               <UpdateBusinessAccountSkeletonComponent breakpoints={breakpoints} />
                            :
                                <UpdateBusinessAccountComponent breakpoints={breakpoints} businessDetails={businessDetails} />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default UpdateBusinessAccountScreen