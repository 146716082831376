import React from "react";
import {Masonry} from '@mui/lab';
import {Box, Pagination, Typography} from '@mui/material';
import VaultCardsComponent from "./vault-cards.component";
import VaultListSkeletonComponent from "./skeleton/vault-list-skeleton.component";

const ProjectVaultListComponent = (props) => {

    const {fileList, project_id, loading, downloadFile, previewFile, pagination, page, 
        handleChangePagination, isLoading, isPreviewLoading, breakpoints,
    } = props;



    if (loading) {
        return (
            <Box sx={{width: "98%", margin: "auto"}}>
                <Masonry columns={{xs: 1, sm: 4}} spacing={2} sx={{margin: "auto", width: "100%", mt: "8px !important"}}>
                    <VaultListSkeletonComponent />
                </Masonry>
            </Box>
        )
    }


    if (!loading && fileList?.length == 0 && !project_id) return (
        <Box sx={{padding: 2, width:'100%', borderRadius: 0, overflow: 'hidden'}}>
            <Box sx={{textAlign:'center', margin: 'auto', mt: 5}}>
                <Typography>No available files to display.</Typography>
            </Box>
        </Box>
    )


    return(
        <Box sx={{ width: '98%', margin: 'auto' }}>

       
            <Masonry columns={{ xs: 1, sm: 4 }} spacing={2} sx={{ margin: 'auto', width: '100%' }}>
                {loading ? (
                    <VaultListSkeletonComponent />
                ) : (
                    <>
                        
                        {(fileList && fileList?.length > 0) && (
                            fileList?.map((list, index) => (
                                <VaultCardsComponent
                                    key={index}
                                    index={index}
                                    isPreviewLoading={isPreviewLoading}
                                    isLoading={isLoading}
                                    breakpoints={breakpoints}
                                    previewFile={previewFile}
                                    downloadFile={downloadFile}
                                    list={list}
                                />
                            ))
                        )}
                            
                    </>
                )}
            </Masonry>


        <Pagination
            count={pagination}
            page={page}
            onChange={handleChangePagination}
            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
            disabled={fileList?.length === 0}
            hideNextButton={fileList?.length < 10}
        />
</Box>

    )
}

export default ProjectVaultListComponent

