import { Box, Grid, Skeleton, Paper, Typography, Divider, List, ListItem, ListItemButton, ListItemText, Card } from "@mui/material";


function DashboardGraphSkeletonComponent (){

    

    return(

        <Box sx={{padding: 1}}>
            <Card sx={{borderRadius: 2,p: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant="rectangular" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={300}/>
                    </Grid>
                </Grid>
            </Card>
        </Box>
       
      
    )
}

export default DashboardGraphSkeletonComponent;