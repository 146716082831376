import { Button, ButtonGroup, Divider, FormGroup, Stack, Switch, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ThreeDots } from 'react-loader-spinner';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useCredits from "../../hooks/useCredits";

const ProjectCreditsComponent = (props) => {

    const { data, index, page, businessId } = props;

    const {  addProjectCredits, addingProjectCredits , subProjectCredits, subtructingProjectCredits, addedProjectCredits, subtructedProjectCredits } = useCredits();

    const [isAddBtnClicked, setAddBtnClicked] = useState(false);
    const [isDeductBtnClicked, setDeductBtnClicked] = useState(false);

    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [subCreditAmount, setSubCreditAmount] = useState(0);


    const handleAddClicked = (e) => {
        setAddBtnClicked(true);
    }

    const handleDeductClicked = (e) => {
        setDeductBtnClicked(true);
    }

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }

    const handleSubChange = (e) => {
        if (e.target.value < 0) {
            setSubCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setSubCreditAmount(1000000);

        } else {
            setSubCreditAmount(e.target.value);
        }
    }


    const handleAddCredits = () => {

        let payload = {
            business_id: data?.businessAccount?.id,
            project_id : data?.accountProject?.id,
            credit: addCreditAmount
        }

        addProjectCredits(payload);
    }

    const handleDeductCredits = () => {
        const subCreditAmountString = subCreditAmount.toString();
        subProjectCredits(data?.businessAccount?.id, data?.accountProject?.id, subCreditAmountString);
    };
    

    const handleCancelAddCredits = (e) => {
        setAddBtnClicked(false);
        setAddCreditAmount(0);

    }

    const handleCancelSubCredits = (e) => {
        setDeductBtnClicked(false);
        setSubCreditAmount(0);

    }


    useEffect(() => {
        if(subtructedProjectCredits){
            setDeductBtnClicked(false);
            props.getProjectCredits(businessId, page);
        }

    },[subtructedProjectCredits])

    useEffect(() => {
        if(addedProjectCredits){
            setAddBtnClicked(false);
            props.getProjectCredits(businessId, page);
        }

    },[addedProjectCredits])



    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
            <TableCell component="th" scope="row"  >
                {data?.accountProject?.application_name}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.credit}
            </TableCell>
            <TableCell component="th" scope="row">
                {(isAddBtnClicked && !isDeductBtnClicked) ?
                    <>
                        <TextField
                            size="small"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            InputProps={{
                                inputProps: {
                                    max: 1000000, min: 0
                                }
                            }}
                            value={addCreditAmount}
                            onChange={(e) => handleAddChange(e)}
                            sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                        />

                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                onClick={(e) => ((addCreditAmount == 0) || addingProjectCredits) ? null : handleAddCredits(e)}
                                startIcon={addingProjectCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                            >
                                {addingProjectCredits ? 'Saving' : 'Save'}
                            </Button>

                            <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                            <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                onClick={(e) => handleCancelAddCredits(e)}
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </>

                :
                    (!isAddBtnClicked && isDeductBtnClicked) ?
                        <>
                            <TextField
                                size="small"
                                type="number"
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                InputProps={{
                                    inputProps: {
                                        max: 1000000, min: 0
                                    }
                                }}
                                value={subCreditAmount}
                                onChange={(e) => handleSubChange(e)}
                                sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                            />

                            <ButtonGroup variant="text" aria-label="text button group">
                                <Button variant="text" sx={{ textTransform: "none", ml: "10px" }}
                                    onClick={(e) => ((subCreditAmount == 0) || subtructingProjectCredits) ? null : handleDeductCredits(e)}
                                    startIcon={subtructingProjectCredits && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    {subtructingProjectCredits ? 'Saving' : 'Save'}
                                </Button>

                                <Divider orientation="vertical" sx={{ height: "auto", visibility: "hidden" }} />

                                <Button variant="text" sx={{ textTransform: "none", color: "#000", ml: "0px" }}
                                    onClick={(e) => handleCancelSubCredits(e)}

                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </>

                :
                    <>
                        <Button variant="outlined" sx={{
                            width: "auto", borderRadius: 1, textTransform: "none",
                            backgroundColor: "transparent", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                        }}
                            onClick={(e) => handleAddClicked(e)}
                            startIcon={<AddIcon />}
                        >
                            Add Credits
                        </Button>

                        &nbsp; &nbsp; &nbsp;

                        <Button variant="outlined" sx={{
                            width: "auto", textTransform: "none", borderRadius: 1,
                            backgroundColor: "#transparent", color: "#1976d2", "&:hover": { backgroundColor: "#fff", color: "#1976d2" }
                        }}
                            onClick={(e) => handleDeductClicked(e)}
                            startIcon={<RemoveIcon />}
                        >
                            Deduct Credits
                        </Button>

                    </>
                }
            </TableCell>
        </TableRow>
    )
}

export default ProjectCreditsComponent