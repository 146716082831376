import {useState} from "react";
import {useSnackbar} from "notistack";
import AdminProjectService from "../services/api/projects";

const useProjects = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [loadingProjects , setLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [created, setCreated] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [restored, setRestored] = useState(false);
    const [updatedData, setUpdatedData] = useState();
    const [dataUpdated, setDataUpdated] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectsFilter, setProjectsFilter] = useState([]);
    const [projectDetails, setProjectDetails] = useState(null)
    const [isFetchingProjectOverviewStats, setFetchingProjectOverviewStats] = useState(false);
    const [projectOverviewStats, setProjectOverviewStats] = useState(null);
    const [updatingProject, setUpdatingProject] = useState(false);
    const [deletingProject, setDeletingProject] = useState(false);
    const [retrievingDeletedProjects, setRetrievingDeletedProjects] = useState( false );
    const [deletedProjects, setDeletedProjects] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [restoringDeletedProject, setIsRestoringDeletedProject] = useState(false);
    const [updatingProjectLogo, setUpdatingProjectLogo] = useState(false);
    const [updatedLogo, setUpdateLogo] = useState();
    const [logoUpdated, setLogoUpdated] = useState();
    const [deletingProjectPermanently, setDeletingProjectPermanently] = useState(false);
    const [isRegeneratingAPIKey, setRegeneratingAPIKey] = useState(false);
    const [regeneratedAPIKey, setRegeneratedApiKey] = useState();
    const [isUpdatingDownloadStatus, setUpdatingDownloadStatus] = useState(false);
    const [isUpdatingPolicy, setUpdatingPolicy] = useState(false);
    const [error, setError] = useState(false);
    const [allProjects, setAllProjects]= useState([]);




    const limit = 10;


    const getProjectList = (businessId) => {

        setLoading(true)

        AdminProjectService.getProjectList(businessId).then((res) => {

            // console.log("project bbb", res)

            setLoading(false)
            if(res.data.success){
                setProjects(res.data.data)
                setProjectsFilter(res.data.data)
            }

            // console.log("getProjectList res", res)

        }).catch((error) => {

            setLoading(false)
            // console.log("getProjectList error", error)
            setError(error.response.statusText);

        })

    }

    const getAllProjectList = (page) => {

        setLoading(true)

        AdminProjectService.getAllProjectList(page).then((res) => {

            setLoading(false)
            if(res.data.success){
                setProjects(res.data.data)
                setAllProjects(res.data.data)
            }

            // console.log("getProjectList res", res)

        }).catch((error) => {

            setLoading(false)
            // console.log("getProjectList error", error)

        })

    }

    const viewProjectDetails = (projectId) => {
        setIsLoadingDetails(true)
        AdminProjectService.viewProject(projectId).then((res) => {
            if(res.data.success){
                setIsLoadingDetails(false)
                setProjectDetails(res.data.data)
            }
            console.log("view project res", res)

        }).catch((error) => {
            console.log("view project error", error)
            setIsLoadingDetails(false)
        })

    }

    const getProjectOverviewStats = (projectId) => {
        setFetchingProjectOverviewStats(true);
    
        AdminProjectService.getProjectOverviewStats(projectId).then((res) => {
            console.log("getProjectOverviewStats data xxxx", res);
            
            if (res.data.success) {
                setFetchingProjectOverviewStats(false);
                setProjectOverviewStats(res.data.data);
            }
    
        }).catch((error) => { 
            console.log("getProjectOverviewStats error xxxx", error);
            
            setFetchingProjectOverviewStats(false);
            setProjectOverviewStats(null);
        })
    }


    const createProject = (data) => {
        setIsCreatingProject(true);

        AdminProjectService.createProject(data).then((res) => {    
            if (res.data.success) {
                setIsCreatingProject(false);
                setCreated(true);

                enqueueSnackbar("Successfully created project", {
                    variant: "success",
                    autoHideDuration: 3000
                });

                // handleClose();
                // window.location.reload();
            }

        }).catch((error) => {
            setIsCreatingProject(false);
            console.log("createProject error xxxx", error);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const updateProjects = (id, application_name, description, deleted) => {
        setUpdatingProject(true);

        AdminProjectService.updateProject(id, application_name, description, deleted).then((res) => {

            setUpdatingProject(false);

            if (res.data.success) {
                setUpdatingProject(false);
                setDataUpdated(true);
                setUpdatedData(res.data.data);

                enqueueSnackbar("Successfully updated project", {
                    variant: "success",
                    autoHideDuration: 3000
                });

            }

        }).catch((error) => {
            setUpdatingProject(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
            console.log("updateProject error xxxx", error);    
        })
    }
    

    const deleteProject = (id) => {
        setDeletingProject(true);

        AdminProjectService.deleteProject(id).then((res) => {
            setDeletingProject(false);

            if (res.data.success) {
                setDeleted(true);
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            setDeletingProject(false);
            console.log("deleteProject error xxxx", error);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    const getAllDeletedProjects = (businessId, page) => {

        setRetrievingDeletedProjects(true);
        
        AdminProjectService.getAllDeletedProjects(businessId, page, limit).then(res => {

            setRetrievingDeletedProjects(false);

            if (res.data.success) {
                setDeletedProjects(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    setPagination(last_page)
                  }
            }

        }).catch((err) => {
            setRetrievingDeletedProjects(false);
            setPagination(0)
            setError(err.response.statusText);
        })
    };


    const restoreDeletedProject = (id) => {

        setIsRestoringDeletedProject(true);

        AdminProjectService.restoreDeletedProject(id).then(res => {
            setIsRestoringDeletedProject(false);

            if (res.data.success) {
                setRestored(true);
                enqueueSnackbar("Successfully restored project.", {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            setIsRestoringDeletedProject(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    };


    const uploadProjectLogo = (formData) => {
        setUpdatingProjectLogo(true);

        AdminProjectService.uploadProjectLogo(formData).then((res) => {

            if (res.data.success) {

                setUpdateLogo(res.data.data.logo)
                setUpdatingProjectLogo(false);
                setLogoUpdated(true);

                enqueueSnackbar("Successfully updated project logo", {
                    variant: "success",
                    autoHideDuration: 3000
                });

            }
        }).catch((error) => {
            console.log("uploadProjectLogo error xxxx", error);
            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });
            setUpdatingProjectLogo(false);
        })
    }


    const deleteProjectPermanently = (id) => {

        setDeletingProjectPermanently(true);

        AdminProjectService.deleteProjectPermanently(id).then((res) => {

            setDeletingProjectPermanently(false);

            if (res.data.success) {
                setDeleted(true);
                enqueueSnackbar(res.data.message, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
        }).catch((error) => {

            setDeletingProjectPermanently(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })

    };


    const regenerateAPIKey = (projectId, setApiKeyRegenerated) => {
        setRegeneratingAPIKey(true);

        AdminProjectService.regenerateAPIKey(projectId).then((res) => {

            if (res.data.success) {
                setRegeneratingAPIKey(false);
                setApiKeyRegenerated(true);
                setRegeneratedApiKey(res.data.data.api_key);
            }

        }).catch((error) => {
            setRegeneratingAPIKey(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }

    const updateDownloadable = (parameters) => {
        setUpdatingDownloadStatus(true);

        AdminProjectService.updateDownloadable(parameters).then((res) => {
            if (res.data.success) {
                setUpdatingDownloadStatus(false);
            }
        }).catch((error) => {
            setUpdatingDownloadStatus(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }

    const updatePolicy = (id, payload) => {
        setUpdatingPolicy(true);

        AdminProjectService.updatePolicy(id, payload).then((res) => {
            console.log("updatePolicy data xxxx", res);
            if (res.data.success) {
                setUpdatingPolicy(false);
            }
        }).catch((error) => {
            setUpdatingPolicy(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }



    return {loadingProjects, projects, getProjectList, viewProjectDetails, projectDetails, 
        isLoadingDetails, isFetchingProjectOverviewStats, projectOverviewStats, getProjectOverviewStats,
        createProject, created, isCreatingProject, updateProjects, updatedData, dataUpdated, updatingProject ,
        deleteProject, deleted, deletingProject, retrievingDeletedProjects, deletedProjects, getAllDeletedProjects,
        pagination, restoringDeletedProject, restoreDeletedProject, restored, updatingProjectLogo, uploadProjectLogo, 
        updatedLogo, logoUpdated, setUpdatingProjectLogo, deleted, deleteProjectPermanently, deletingProjectPermanently,
        regenerateAPIKey, isRegeneratingAPIKey, regeneratedAPIKey, getAllProjectList, projectsFilter, isUpdatingDownloadStatus, 
        updateDownloadable, isUpdatingPolicy, updatePolicy, error, allProjects
    }
}

export default useProjects
