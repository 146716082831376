import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useEffect} from "react";

const RestoreDeletedTemplateDialog = (props) => {

    const {openRestoreTemplate, handleClose, template, setIsRestored, restoring, restored, restoreDeletedTemplate} = props;

    const handleRestoreTemplate = (template_code) => {

        let formData = new FormData();
        formData.append('template_code', template_code);

        restoreDeletedTemplate(formData, handleClose)
    }

    useEffect(() => {
        if (restored) {
            setIsRestored(true);
        }
    }, [restored])


    return(
        <Dialog open={openRestoreTemplate} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Restore Template Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to restore <strong>{template?.template_name}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleClose}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={() => handleRestoreTemplate(template?.template_code)} 
                    startIcon={restoring && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {restoring ? "Restoring" : "Restore"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RestoreDeletedTemplateDialog