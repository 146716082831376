import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";
import { ThreeDots } from 'react-loader-spinner';



function ResetStatusConfirmationDialogComponent (props){

    const {openResetConfirmationDialog, handleCloseResetConfirmationDialog, authenticationDetails} = props
    const breakpoints = useWidth()
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleResetStatus = (id) => {
        setLoading(true);
        EmailAuthenticationService.resetStatus(id).then((res)=>{
            console.log('reset status res', res)
            if(res.data.success){
                setLoading(false);
                enqueueSnackbar("Successfully reset email status.", { 
                    variant: 'success',
                    autoHideDuration: 3000
                }); 
                handleCloseResetConfirmationDialog()
                window.location.reload();
            }
        }).catch((err)=>{
            setLoading(false);
            setError(err.response.statusText);
            enqueueSnackbar(err.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            }); 
        })
    }

    return(
        <Dialog open={openResetConfirmationDialog} onClose={handleCloseResetConfirmationDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Reset Status
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    You are about to reset <strong>{authenticationDetails?.email}</strong> email status . Please confirm. 
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleCloseResetConfirmationDialog} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button variant="contained" 
                    onClick={()=> handleResetStatus(authenticationDetails?.id)}
                    startIcon={loading ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : null}
                >
                    { loading ? 'Resetting' : 'Confirm' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ResetStatusConfirmationDialogComponent