import {useState} from "react";
import {ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteBillingDialogComponent from "./dialog/delete-billing-dialog.component";

// import DeleteBillingDialogComponent from "./dialog/delete-billing-dialog.component";

const BillingActionsPopperComponent = (props) => {

    const {list, page, projectId, businessId, retrieveProjectSOA} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteConfirmation(true);
    }
  
    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const downloadPdf = () => {
        const pdfUrl = list.soa_file_url; // Replace this URL with your actual PDF file URL
        const fileName = list.filename; // Name of the downloaded file
    
        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading PDF:', error));
    }

  
    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick} />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={downloadPdf}>
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}} disableRipple>
                                        <FileDownloadIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">
                                    Download
                                </Typography>
                            </MenuItem>

                            <MenuItem style={{color: 'red', fontSize: 13, fonWeight: 500}} onClick={handleClickOpenDeleteDialog}>
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}} disableRipple>
                                        <DeleteIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>

                                <Typography variant="inherit">
                                    Delete
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openDeleteConfirmation &&
                <DeleteBillingDialogComponent 
                    page={page} 
                    projectId={projectId}
                    businessId={businessId} 
                    list={list} 
                    openDeleteConfirmation={openDeleteConfirmation} 
                    setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                    retrieveProjectSOA={retrieveProjectSOA}
                />
            }
        </div>
    )
}

export default BillingActionsPopperComponent
