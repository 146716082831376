import { useEffect, useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Stack, Select, MenuItem, TextField, InputAdornment, FormControl, InputLabel, Button, Pagination } from "@mui/material";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import useProjects from "../hooks/useProjects";
import useActivityLogs from "../hooks/useActivityLogs";
import EnterpriseActivityLogsComponent from "../components/enterprise/activity-logs/enterprise-activity-logs.component";
import ActivityLogsSkeletonComponent from "../components/enterprise/activity-logs/activity-logs-skeleton.component";
import useBusiness from "../hooks/useBusiness";
import ForbiddenScreen from "./ForbiddenScreen";
import FetchingActivityLogsComponent from "../components/enterprise/activity-logs/fetching-activity-logs.component";
import useUsers from "../hooks/useUsers";
import AdminActivityLogsComponent from "../components/enterprise/activity-logs/admin-activity-logs.component";
import AdminActivityLogsFilterComponent from "../components/enterprise/activity-logs/admin-activity-logs-filter.component";
import useAdminActivityLogs from "../hooks/useAdminActivityLogs";

const searchForOptions = [
    // { value: "actions", label: "Actions" },
    // { value: "activity", label: "Activity" },
    { value: "email", label: "Email" },
    { value: "enterprise", label: "Enterprise" },
    // { value: "module", label: "Module" },
]

function TableHeader() {
    const tablecell = [
        { label: "Email" },
        { label: "Project" },
        { label: "Module" },
        { label: "Actions" },
        { label: "Activity" },
        { label: "Timestamp" },
    ];

    return (
        <TableHead sx={{ borderRadius: 2 }}>
            <TableRow sx={{ position: "sticky", bgcolor: "none", borderRadius: 2 }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: "#0178f2", color: "#0178f2", bgcolor: "none" }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const AdminActivityLogsScreen = () => {


    const { isFetchingActivityLogs, auditList, getAdminActivityLogs, error, searchAdminActivityLogs, getBusinessActivityLogs, searchBusinessActivityLogs,
        getProjectActivityLogs, searchProjectActivityLogs, getUserActivityLogs, searchUserActivityLogs, isFetchingAllActivityLogs } = useAdminActivityLogs();
    const { getAllProjectList, projects, getProjectList, projectsFilter } = useProjects();
    const { getAllBusinessList, businessList } = useBusiness();
    const { users, getAllAdminUsers, users: admin_users } = useUsers();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [monthValue, setMonthValue] = useState("");
    const [page, setPage] = useState(1);
    const [action, setAction] = useState("");
    const [module, setModule] = useState("");
    const [email, setEmail] = useState("");
    const [searchAuditLogs, setSearchAudit] = useState("");
    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [userEmailOptions, setUserEmailOption] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState("");

    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [isSelectedLabel, setIsSelectedLabel] = useState(null);
    const [filter_enterprise, setFilterEnterprise] = useState("");
    const [filter_project, setFilterProject] = useState("");
    const [filter_user, setFilterUser] = useState("");
    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessFilterValue, setBusinessFilterValue] = useState(null);


    // console.log("auditList aaa", auditList)
    // console.log("selectedEmail", selectedEmail)
    // console.log("businessFilterValue", businessFilterValue)

    useEffect(() => {
        getAllBusinessList(page);
        getAllProjectList(page);
    }, [page]);

    useEffect(() => {
        if (filter_enterprise) {
            getProjectList(filter_enterprise);
        }
    }, [filter_enterprise])



    useEffect(() => {
        let business_options = [];

        businessList?.map((business) => {
            let business_options_data = {
                value: business?.id,
                label: business?.business_name
            }

            business_options.push(business_options_data);
        })

        setBusinessOptions(business_options);
    }, [JSON.stringify(businessList)])


    useEffect(() => {
        getAllAdminUsers();
    }, [])

    const userEmails = () => {
        if (!admin_users) {
            console.error("User email list is null or undefined.");
            return;
        }

        const user_email_option = [...admin_users?.map((user) => ({
            value: user?.user_id,
            label: user?.email_address
        }))];

        setUserEmailOption(user_email_option);

    }

    useEffect(() => {
        userEmails();
    }, [JSON.stringify(admin_users)]);


    const generateArrayOfYears = () => {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = { value: i };

            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    const getMonthNames = () => {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, "0");

            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]

    const monthName = monthNames[monthValue - 1];

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        const mName = monthNames[event.target.value - 1];
        const paddedMonth = selectedMonth.toString().padStart(2, "0");

        setMonth(paddedMonth);
        setMonthValue(selectedMonth);
        setIsMonthSelected(true);

        if (searchAuditLogs) {
            searchAdminActivityLogs(mName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
        } else {
            getAdminActivityLogs(mName, year, module, action, selectedEmail?.value, page);
        }
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);

        const yearChange = event.target.value;

        if (searchAuditLogs) {
            searchAdminActivityLogs(monthName, yearChange, module, action, selectedEmail?.value, page, searchAuditLogs);
        } else {
            getAdminActivityLogs(monthName, yearChange, module, action, selectedEmail?.value, page);
        }
    }

    const searchModule = (e) => {
        setModule(e.target.value);
    }

    const keyPressModule = (e) => {
        console.log('Key pressed:', e.keyCode);
        if (e.keyCode === 13) {
            e.preventDefault();
            console.log('Enter key pressed');
            if (searchAuditLogs) {
                searchAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
            } else {
                getAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page);
            }
        }
    }

    const searchAction = (e) => {
        setAction(e.target.value);
    }

    const keyPressAction = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
            } else {
                getAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page);
            }
        }
    }

    const searchAudit = (e) => {
        setSearchAudit(e.target.value);
    }

    const keyPressAudit = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
            } else {
                getAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page);
            }
        }
    }


    const handleChangeEnterpriseName = (selectedOption) => {
        if (selectedOption) {
            setBusinessFilterValue(selectedOption);
            setFilterEnterprise(selectedOption.value);
        }
    }

    const handleChangeProjectName = (event) => {
        setFilterProject(event.target.value);
    }

    const handleChangeUser = (event) => {
        setFilterUser(event.target.value);
    }



    const handleClearFilter = (e) => {
        setSearchStatusVal(null);
        setSearchFor(null);
        setSearchForLabel(null);
        setIsSelectedLabel(null);
        setFilterEnterprise(null);
        setFilterProject(null);
        setFilterUser(null);
        setBusinessFilterValue(null);
        setSelectedEmail(null);

    }


    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
            setIsSelectedLabel(selectedOption.label);

        } else {

            handleClearFilter();
        }
    }


    const handleReload = () => {
        setYear("");
        setEmail("");
        setModule("");
        setAction("");
        setMonthValue("");
        setSearchAudit("");
        setIsMonthSelected(false);
        setSelectedEmail("");
        setFilterEnterprise("");
        setFilterProject("");
        setFilterUser("");
        setBusinessFilterValue("");
        setSelectedEmail(null);
        setSearchStatusVal(null);
        setSearchFor(null);
        setSearchForLabel(null);
        setIsSelectedLabel(null);
        getAdminActivityLogs(page);

    }

    useEffect(() => {
        const fetchData = async () => {
            console.log("heeeeeeeeereeeeeeeeeeeeee")
            try {
                // console.log("filter_enterprise:", filter_enterprise);
                // console.log("filter_project:", filter_project);
                // console.log("filter_user:", filter_user);
                // console.log("page:", page);

                if (filter_enterprise && filter_project && selectedEmail) {
                    if (searchAuditLogs) {
                        await searchUserActivityLogs(filter_enterprise, page, monthName, year, module, action, selectedEmail?.value, searchAuditLogs);
                    } else {
                        await getUserActivityLogs(filter_enterprise, monthName, year, module, action, selectedEmail?.value, page);
                    }
                } else if (filter_enterprise && filter_project) {
                    if (searchAuditLogs) {
                        await searchProjectActivityLogs(filter_enterprise, filter_project, page, monthName, year, module, action, selectedEmail?.value, searchAuditLogs);
                    } else {
                        await getProjectActivityLogs(filter_enterprise, filter_project, monthName, year, module, action, selectedEmail?.value, page);
                    }
                } else if (filter_enterprise && selectedEmail) {
                    if (searchAuditLogs) {
                        await searchUserActivityLogs(filter_enterprise, page, monthName, year, module, action, selectedEmail?.value, searchAuditLogs);
                    } else {
                        await getUserActivityLogs(filter_enterprise, monthName, year, module, action, selectedEmail?.value, page);
                    }
                } else if (filter_enterprise) {
                    if (searchAuditLogs) {
                        await searchBusinessActivityLogs(filter_enterprise, monthName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
                    } else {
                        await getBusinessActivityLogs(filter_enterprise, monthName, year, module, action, selectedEmail?.value, page);
                    }
                } else if (selectedEmail) {
                    if (searchAuditLogs) {
                        await searchAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page, searchAuditLogs);
                    } else {
                        await getAdminActivityLogs(monthName, year, module, action, selectedEmail?.value, page);
                    }
                } else {
                    await getAdminActivityLogs(page);
                }
            } catch (error) {
                console.error("Error fetching activity logs:", error);
            }
        };

        fetchData();

    }, [filter_enterprise, filter_project, filter_user, selectedEmail, page, monthName, year]);


    if (isFetchingActivityLogs) {
        return (
            <FetchingActivityLogsComponent
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
                handleReload={handleReload}
                searchForOptions={searchForOptions}
                handleSelectStatusOption={handleSelectStatusOption}
                isMonthSelected={isMonthSelected}
                searchFor={searchFor}
                searchStatusVal={searchStatusVal}
                searchForLabel={searchForLabel}
                isSelectedLabel={isSelectedLabel}
                monthValue={monthValue}
                year={year}
                years={years}
                getMonthNames={getMonthNames}
                filter_enterprise={filter_enterprise}
                businessList={businessList}
                projectsFilter={projectsFilter}
                filter_project={filter_project}
                auditList={auditList}
                users={users}
                filter_user={filter_user}
                setFilterEnterprise={setFilterEnterprise}
                setFilterProject={setFilterProject}
                setFilterUser={setFilterUser}
                setBusinessFilterValue={setBusinessFilterValue}
                businessFilterValue={businessFilterValue}
                businessOptions={businessOptions}
                userEmailOptions={userEmailOptions}
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
                searchModule={searchModule}
                keyPressModule={keyPressModule}
                action={action}
                searchAction={searchAction}
                keyPressAction={keyPressAction}
                searchAuditLogs={searchAuditLogs}
                searchAudit={searchAudit}
                keyPressAudit={keyPressAudit}
                handleChangeEnterpriseName={handleChangeEnterpriseName}
                handleChangeProjectName={handleChangeProjectName}
                handleChangeUser={handleChangeUser}
                module={module}
            />
        )
    }

    return (
        <Box sx={{ overflow: 'hidden' }}>
            {error === 'Forbidden' ?
                <ForbiddenScreen />
            :
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AdminActivityLogsFilterComponent
                                handleMonthChange={handleMonthChange}
                                handleYearChange={handleYearChange}
                                handleReload={handleReload}
                                searchForOptions={searchForOptions}
                                handleSelectStatusOption={handleSelectStatusOption}
                                isMonthSelected={isMonthSelected}
                                searchFor={searchFor}
                                searchStatusVal={searchStatusVal}
                                searchForLabel={searchForLabel}
                                isSelectedLabel={isSelectedLabel}
                                monthValue={monthValue}
                                year={year}
                                years={years}
                                getMonthNames={getMonthNames}
                                filter_enterprise={filter_enterprise}
                                businessList={businessList}
                                projectsFilter={projectsFilter}
                                filter_project={filter_project}
                                auditList={auditList}
                                users={users}
                                filter_user={filter_user}
                                setFilterEnterprise={setFilterEnterprise}
                                setFilterProject={setFilterProject}
                                setFilterUser={setFilterUser}
                                setBusinessFilterValue={setBusinessFilterValue}
                                businessFilterValue={businessFilterValue}
                                businessOptions={businessOptions}
                                userEmailOptions={userEmailOptions}
                                selectedEmail={selectedEmail}
                                setSelectedEmail={setSelectedEmail}
                                searchModule={searchModule}
                                keyPressModule={keyPressModule}
                                action={action}
                                searchAction={searchAction}
                                keyPressAction={keyPressAction}
                                searchAuditLogs={searchAuditLogs}
                                searchAudit={searchAudit}
                                keyPressAudit={keyPressAudit}
                                handleChangeEnterpriseName={handleChangeEnterpriseName}
                                handleChangeProjectName={handleChangeProjectName}
                                handleChangeUser={handleChangeUser}
                                module={module}
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ pt: 2 }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", mt: "12px" }}>
                            <Button sx={{ textTransform: "none", justifyContent: "end", fontSize: 12, fontWeight: "bold" }}
                                onClick={() => handleReload()}
                            >
                                <CachedIcon sx={{ height: 20, width: 20 }} />

                                Reload
                            </Button>

                            <Box sx={{ flexGrow: 1 }} />
                        </Box>

                        <Grid container >
                            <AdminActivityLogsComponent auditList={auditList} isFetchingActivityLogs={isFetchingActivityLogs} />
                        </Grid>

                    </Grid>
                </>
            }
        </Box>
    )
}

export default AdminActivityLogsScreen