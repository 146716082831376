import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Skeleton, Typography } from "@mui/material"
import appMiddleware from '../../../icons/ovcode-app-middleware.jpg';



const LearnMoreDialog = (props) => {

    const { openDialog, handleClose, breakpoints } = props

    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'}
            PaperProps={{
                style: {
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    borderRadius: 5,
                    margin: 'auto',

                },
            }}
        >
            <DialogTitle>
                App Middleware
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints === 'xs' || breakpoints === 'sm' ? 3 : 6, paddingTop: 2 , textAlign: 'center'}}>
               <img src={appMiddleware} style={{width: '90%', objectFit: 'contain'}} />
            </DialogContent>

            <DialogActions container sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 2 }}>
                <Button variant="contained" onClick={handleClose} sx={{ color: '#fff', textTransform: 'none', textAlign: 'flex-end',  backgroundColor: '#0178f2' }}>Okay</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LearnMoreDialog