import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import AdminReportsService from "../services/api/reports";

const useReports = () => {

  const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [reportDataOne, setReportDataOne] = useState([]);
    const [total_authentication, setTotalAuthentication] = useState(0);
    const [reportDataTwo, setReportDataTwo] = useState([]);
    const [reportDataThree, setReportDataThree] = useState([]);
    const [reportDataFour, setReportDataFour] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [all_transactions, setAllTransactions] = useState([]);
    const [enterprise_reports, setEnterpriseReports] = useState([]);
    const [enterprise_all_reports, setEnterpriseAllReports] = useState([]);

    const [enterprise_project_reports, setEnterpriseProjectReports] = useState([]);
    const [view_enterprise_reports, setViewEnterpriseReports] = useState([]);
    const [view_all_enterprise_reports, setViewAllEnterpriseReports] = useState([]);
    const [view_enterprise_reports_pipelines, setViewEnterpriseReportsPipelines] = useState([]);
    const [data, setData] = useState([]);
    const [isFetchingBillingList, setFetchingBillingList] = useState(false);
    const [created, setCreated] = useState(false);
    const [uploadingSoa, setUploadingSoa] = useState(false);
    const [deletingSoa, setDeletingSoa] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(false);
    const [storageData, setStorageData] = useState([]);
    const [projectstorageData, setProjectStorageData] = useState([]);
 
    var limit = 20;
    var limits = 1000;

    var typeOne = 1;
    var typeTwo = 2;
    var typeThree = 3;
    var typeFour = 4;

    
    const generateReportOne = (business_id,date_from, date_to) => {
        setLoading(true);

        AdminReportsService.generateReportOne(business_id,date_from, date_to,typeOne).then(res => {

            console.log("generateReportOne res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setReportDataOne(res.data.data.projects)
                setTotalAuthentication(res.data.data.total_authenticated_count)
            }

        }).catch((error)=>{

            console.log("error herexxxx:", error)

            setLoading(false);
            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const generateReportTwo = (business_id,project_id,date_from, date_to) => {
        setLoading(true);

        AdminReportsService.generateReportTwo(business_id,project_id,date_from, date_to,typeTwo).then(res => {

            setLoading(false);

            console.log("generateReportTwoxxxx", res)

            if (res.data.success) {
                //set data here
                setReportDataTwo(res.data.data.authenticated_data)
                setTotalAuthentication(res.data.data.total_authenticated_count)
            }

        }).catch((error)=>{

            console.log("error herexxxx:", error)

            setLoading(false);
            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }

    const generateReportThree = (business_id,project_id,date_from, date_to,page) => {
        setLoading(true);

        AdminReportsService.generateReportThree(business_id,project_id,date_from, date_to,typeThree,page, limit).then(res => {

            setLoading(false);

            if (res.data.success) {
                //set data here
                setReportDataThree(res.data.data.authenticated_data)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res.data.data.authenticated_data.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.data.metadata.total_count / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const generateReportAllThree = (business_id,project_id,date_from, date_to) => {
        setLoading(true);

        AdminReportsService.generateReportAllThree(business_id,project_id,date_from, date_to,typeThree,limits).then(res => {

            setLoading(false);

            if (res.data.success) {
                //set data here
                setAllTransactions(res.data.data.authenticated_data)
            }

        }).catch((error)=>{

            console.log("error herexxxx:", error)

            setLoading(false);

        })
    }


    const generateReportFour = (business_id,project_id,date_from, date_to,page) => {
        setLoading(true);

        AdminReportsService.generateReportFour(business_id,project_id,date_from, date_to,typeFour,page, limit).then(res => {

            console.log("generateReportFour res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setReportDataFour(res.data.data.pipelines)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res.data.data.authenticated_data.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.data.metadata.total_count / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }



    const generateReportByEnterprise = (business_id, date_from, date_to, page) => {
        setLoading(true);

        AdminReportsService.generateReportByEnterprise(business_id, date_from, date_to, page, limit, typeOne).then(res => {

            console.log("generateReportByEnterprise res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setEnterpriseReports(res?.data?.data?.projects)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.data?.total_projects / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("generateReportByEnterprise error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const generateReportByEnterpriseAll = (business_id, date_from, date_to, page) => {
        setLoading(true);

        AdminReportsService.generateReportByEnterpriseAll(business_id, date_from, date_to, page, typeOne).then(res => {

            console.log("generateReportByEnterprise all res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setEnterpriseAllReports(res?.data?.data?.projects)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.data?.total_projects / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("generateReportByEnterprise all error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    
    const generateReportByEnterpriseProject = (business_id, project_id, date_from, date_to, page) => {
        setLoading(true);

        AdminReportsService.generateReportByEnterpriseProject(business_id, project_id, date_from, date_to, page, limit, typeTwo).then(res => {

            console.log("generateReportByEnterpriseProject res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setEnterpriseProjectReports(res?.data?.data?.authenticated_data)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.data?.total_projects / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("generateReportByEnterpriseProject error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const viewGenerateReportByEnterpriseProject = (business_id, project_id, date_from, date_to, page) => {
        setLoading(true);

        AdminReportsService.viewGenerateReportByEnterpriseProject(business_id, project_id, date_from, date_to, page, limit, typeThree).then(res => {

            console.log("viewGenerateReportByEnterpriseProject res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setViewEnterpriseReports(res?.data?.data?.authenticated_data)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data?.data?.metadata?.total_count / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
                }
            }

        }).catch((error)=>{

            console.log("viewGenerateReportByEnterpriseProject error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }

    const viewGenerateReportByEnterpriseProjectAll = (business_id, project_id, date_from, date_to) => {
        setLoading(true);

        AdminReportsService.viewGenerateReportByEnterpriseProjectAll(business_id, project_id, date_from, date_to, total_authentication, typeThree).then(res => {

            console.log("viewGenerateReportByEnterpriseProject all res here", res)

            setLoading(false);

            if (res.data.success) {
                setViewAllEnterpriseReports(res?.data?.data?.authenticated_data)
            }

        }).catch((error)=>{

            console.log("viewGenerateReportByEnterpriseProject all error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const viewGenerateReportByProject = (business_id, project_id, date_from, date_to, page) => {
        setLoading(true);

        AdminReportsService.viewGenerateReportByProject(business_id, project_id, date_from, date_to, page, limit, typeFour).then(res => {

            console.log("viewGenerateReportByProject res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setViewEnterpriseReportsPipelines(res?.data?.data?.pipelines)
                setTotalAuthentication(res.data.data.total_authenticated_count)
                // if(res?.data?.length === 0){
                //     setPagination(0)
                // }else{
                // var last_page = Math.ceil(res.data?.data?.metadata?.total_count / limit); //set it depending on ui return - 18
                // console.log("last_page", last_page);
                // setPagination(last_page)
                // }
            }

        }).catch((error)=>{

            console.log("viewGenerateReportByProject error herexxxx:", error)

            setLoading(false);
            setPagination(0);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const uploadSOA = (formData) => {
        setUploadingSoa(true);
        AdminReportsService.uploadSOA(formData).then(res => {
            setUploadingSoa(false);
            if(res.data.success){
                //data here
                setCreated(true);
                console.log("resxxx", res)
                
                enqueueSnackbar('Successfully uploaded SOA.', {
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
        }).catch((err) => {
            console.log("uploadSOA error xxxx", err)
            setCreated(false);
            setUploadingSoa(false);
        })
    }


    const retrieveProjectSOA = (projectId, businessId, filename,  page) => {
        setFetchingBillingList(true);
        AdminReportsService.retrieveProjectSOA(projectId, businessId, filename, limit, page).then(res => {
            if(res.data.success){
                //data here
                console.log("retrieveProjectSOA xxxx", res)
                setFetchingBillingList(false);
                setData(res.data.data)
                 if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                    var last_page = Math.ceil(res.data?.data?.metadata?.total_count / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                }

            }
        }).catch((err) => {
            setFetchingBillingList(false);
            console.log("retrieveProjectSOA error xxxx", err)
        })
    }

    const retrieveSOA = (businessId, filename, page) => {
        setFetchingBillingList(true);
        AdminReportsService.retrieveSOA(businessId, filename, limit, page).then(res => {
            setFetchingBillingList(false);
            if(res.data.success){
                //data here
                setData(res.data.data)
                 if(res?.data?.length === 0){
                    setPagination(0)
                }else{
                    var last_page = Math.ceil(res.data?.data?.metadata?.total_count / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                }
            }
        }).catch((err) => {
            console.log("retrieveSOA error xxxx", err)
            setFetchingBillingList(false);
        })
    }

    const deleteSOA = (soaId) => {
        setDeletingSoa(true);
        AdminReportsService.deleteProjectSOA(soaId).then(res => {
            setDeletingSoa(false)
            if(res.data.success){
                //data here
                setDeleted(true);
                enqueueSnackbar('Successfully deleted SOA.', {
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
        }).catch((err) => {
            console.log("deleteSOA error xxxx", err)
            setDeletingSoa(false);
            setDeleted(false);
        })
    }



    const getStorageUsage = (business_id, date_from, date_to) => {
        setLoading(true);

        AdminReportsService.getStorageUsage(business_id, date_from, date_to).then(res => {

            console.log("getStorageUsage res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setStorageData(res.data.data);
               
                
            }

        }).catch((error)=>{

            console.log("getStorageUsage error herexxxx:", error)

            setLoading(false);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }


    const getProjectStorageUsage = (projectId, business_id, date_from, date_to) => {
        setLoading(true);

        AdminReportsService.getProjectStorageUsage(projectId, business_id, date_from, date_to).then(res => {

            console.log("getProjectStorageUsage res here", res)

            setLoading(false);

            if (res.data.success) {
                //set data here
                setProjectStorageData(res.data.data);
               
                
            }

        }).catch((error)=>{

            console.log("getProjectStorageUsage error herexxxx:", error)

            setLoading(false);

            if (error.response?.data?.error_message) {
                enqueueSnackbar(error.response?.data?.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
        
            } else if (error.response?.data?.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                if(error.response.data.error === "Forbidden.") {
                    setError(true);
                } else {
                    enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            }

        })
    }

 
    return {loading, generateReportOne, total_authentication, reportDataOne, generateReportTwo, reportDataTwo, 
        generateReportThree, reportDataThree , pagination, generateReportFour, reportDataFour, generateReportAllThree,all_transactions, 
        generateReportByEnterprise, enterprise_reports, setLoading, generateReportByEnterpriseProject, enterprise_project_reports,
        viewGenerateReportByEnterpriseProject, view_enterprise_reports, viewGenerateReportByProject, view_enterprise_reports_pipelines,
        uploadSOA, retrieveProjectSOA, retrieveSOA, deleteSOA, data, isFetchingBillingList, pagination, created, uploadingSoa, deletingSoa, deleted, error,
        generateReportByEnterpriseAll, enterprise_all_reports, viewGenerateReportByEnterpriseProjectAll, view_all_enterprise_reports,
        getStorageUsage, storageData, getProjectStorageUsage, projectstorageData
    }

}

export default useReports;