import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import useWidth from "../../hooks/useWidth";
import ReportsThreeComponent from "./report-three.component";
import ReportsTwoComponent from "./report-two.component";
import ReportsFourComponent from "./report-four.component";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ReportsComponent from "./reports.component";
import StorageComponent from "./storage.component";



function useReportsTab(props) {

    const { setForbidden } = props;

    const [reports_tab, setReportsTabs] = useState(null);



    function setupTabs() {

        var tabs_default = [
            { name: 'Authentication', component: <ReportsComponent setForbidden={setForbidden} /> },
            { name: 'Storage', component: <StorageComponent setForbidden={setForbidden} />},
        
        ]

        setReportsTabs(tabs_default);
    }

    useEffect(() => { setupTabs(); }, [])

    return reports_tab;

}


function ReportsMainTabsComponent(props) {

    const  { setForbidden } = props;

  const tabs = useReportsTab({setForbidden});

  const breakpoints = useWidth();

  const storedTab = localStorage.getItem('selectedTab');
  const initialTabValue = storedTab ? parseInt(storedTab) : 0;
  const [value, setValue] = useState(initialTabValue);

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedTab');
    };
  }, []);

  function TabPanel(props) {

    const { children, value, index, ...other } = props;


    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }


  function handleChange(event, newValue) {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue); // Store selected tab index
  }
  



  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ marginTop: 2 }}
          indicatorColor="transparent"
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })
          }
        </CustomTabs>
       
      </Box>


      {tabs?.map((tab, index) => {
        return (
          <TabPanel value={value} index={index} style={{ padding: "0px" }}>
            {tab.component}
          </TabPanel>
        )
      })
      }

    
    </Box>
  )
}

export default ReportsMainTabsComponent;