import {TableCell, TableRow} from "@mui/material";
import {format} from "date-fns";

const EnterpriseActivityLogsComponent = (props) => {

    const {list} = props;

    return (
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.email}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.project_name}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.module}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {list?.action}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12, wordBreak: "break-word"}}> 
                {list.activity}
            </TableCell>

            <TableCell align="left" sx={{fontSize: 12}}> 
                {format(new Date(list.timestamp), "PPpp")}
            </TableCell>
        </TableRow>
    )
}

export default EnterpriseActivityLogsComponent