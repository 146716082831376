import { useState, useCallback } from "react";
import { debounce, reject } from "lodash";

export const useDebounce = (refreshRate) => {
    const [data, setData] = useState(null)

    const handleData = (value) => {
        setData(value)
    }

    const _debounce = useCallback(debounce((value) => handleData(value), refreshRate), []);

    return {_debounce, data}
}