// generate random colors with no exemption
function stringToColor (string) {
    let hash = 0;
    let i;
      
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
      
    let color = '#';
      
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;   
    
}

// generate dark random colors only
// function stringToColor(string) {
//     let hash = 0;
//     let i;

//     for (i = 0; i < string.length; i += 1) {
//         hash = string.charCodeAt(i) + ((hash << 5) - hash);
//     }

//     let color = '#';

//     for (i = 0; i < 3; i += 1) {
//         const value = (hash >> (i * 8)) & 0xff;
//         const darkValue = Math.min(128, value);
        
//         color += `00${darkValue.toString(16)}`.slice(-2);
//     }

//     return color;
// }
      
function stringAvatar(name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()}`,
    };
}
      
export { 
    stringAvatar,
    stringToColor
}