import {Box, Button, Grid, Typography } from "@mui/material"
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useEffect, useState} from "react";
import AdminRolesComponent from "./admin-roles.component";
import useWidth from "../../hooks/useWidth";
import CreateRoleDialog from "../rbac/dialogs/create-role.dialog";
import ViewAdminRolesComponent from "./view-admin-roles.component";
import useRolesManagement from "../../hooks/useRolesManagement";
import ForbiddenScreen from "../../screens/ForbiddenScreen";


const AdminRolesManagementComponent = () => {

    const breakpoints = useWidth();
    const { isLoading, getAdminRoles, rolesList, pagination, retrieved, getAllAdminRoles, isFetchingRoles,
        enterpriseRoleList, getAdminInheritance, inheritanceChildren, inheritanceRole, error
    } = useRolesManagement();



    const [isView, setView] = useState(false);
    const [openCreateRole, setOpenCreateRole] = useState(false);
    const [role_name, setRoleName] = useState('');
    const [role_id, setRoleId] = useState(null);
    const [dataRole, setDataRole] = useState('');
    const [page, setPage] = useState(1);

    const handleOpenCreateRole = () => {
        setOpenCreateRole(true);
    }

    const handleCloseCreateRole = () => {
        setOpenCreateRole(false);
    }

    useEffect(() => {
        getAllAdminRoles();
    },[])

    useEffect(() => {
        if(retrieved){
            getAdminRoles(page);
        }
    },[retrieved, page])


    return (
        <Box sx={{ width:'97%', margin: 'auto' }}>
            {error === "Forbidden" ?
                <ForbiddenScreen />
            :
                <Grid container>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: 14 }}>Process of defining, assigning, and regulating roles to control user access and permissions effectively.</Typography>
                    </Grid>

                        <Grid item xs={12} sx={{textAlign: 'end', mt: "17px"}}>
                            <Button
                                variant="text"
                                sx={{
                                    fontSize: 14,
                                    p: '4px 5px',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#0178f2',
                                    textTransform: 'none',
                                }}
                                startIcon={<AddBoxIcon sx={{color: '#0178f2' }} />}
                                onClick={handleOpenCreateRole}
                            >
                                Create Role
                            </Button>
                        </Grid>

                    <br />
                    <br />
                    
                    <Grid item xs={12}>
                        {isView ?
                            <ViewAdminRolesComponent 
                                setView={setView} 
                                dataRole={dataRole} 
                                isLoading={isLoading}
                                inheritanceChildren={inheritanceChildren}
                                inheritanceRole={inheritanceRole} getAdminInheritance={getAdminInheritance}
                            />
                        :
                            <AdminRolesComponent 
                                setView={setView} 
                                setDataRole={setDataRole}
                                isLoading={isLoading}
                                getAdminRoles={getAdminRoles}
                                rolesList={rolesList}
                                pagination={pagination}
                                isFetchingRoles={isFetchingRoles}
                            />
                        }
                    
                    </Grid>
                </Grid>
            }

            
            {openCreateRole &&
                <CreateRoleDialog 
                    openCreateRole={openCreateRole}
                    handleClose={handleCloseCreateRole}
                    getAllAdminRoles={getAllAdminRoles}
                    getAdminRoles={getAdminRoles}
                    retrieved={retrieved}
                    page={page}
                    isView={isView}
                    getAdminInheritance={getAdminInheritance}
                    dataRole={dataRole} 
                />
            }

        </Box>
    )
}

export default AdminRolesManagementComponent
