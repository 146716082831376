import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Skeleton, Typography } from "@mui/material"
import useBusiness from "../../../hooks/useBusiness";
import ReactSelect from 'react-select';
import { useEffect, useState, useRef } from "react";
import useAppConfig from "../../../hooks/useAppConfig";
import { ThreeDots } from 'react-loader-spinner';
import useProjects from "../../../hooks/useProjects";



const CreateBusinessAppDialog = (props) => {

    const { openDialog, handleClose, breakpoints } = props

    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();
    const { loading, availableBusinessApp, availableBusiness, addBusinessApp, addingBusinessApp, added } = useAppConfig();
    const {getProjectList, projects, loadingProjects} = useProjects();

    const [options, setOption] = useState([]);
    const [app_options, setAppOption] = useState([]);
    const [businessId, setBusinessId] = useState(options[0]?.value);
    const [selected_business, setSelectedBusiness] = useState('');
    const [selected_app_business, setSelectedAppBusiness] = useState('');
    const [app_name, setAppName] = useState('');
    const [projectOptions, setProjectOptions] = useState([]);
    const [project_id, setProjectId] = useState(projectOptions[0]?.value);
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedProjectName, setSelectedProjectName] = useState('');

    const menuPortalTargetRef = useRef(null);


    useEffect(() => {
        getBusinessListNames();
        availableBusinessApp();
    }, [])

    useEffect(() => {
        getProjectList(businessId);
    },[businessId])


    useEffect(() => {

        let options = []

        availableBusiness?.map((business) => {

            let options_data = { value: business, label: business }

            options.push(options_data)
        })

        setAppOption(options)

    }, [JSON.stringify(availableBusiness)])


    const handleChangeBusiness = (selectedOption) => {
        setBusinessId(selectedOption?.value);
        setSelectedBusiness(selectedOption);
    };

    const handleChangeProject = (selectedOption) => {
        setSelectedProjectName(selectedOption);
        setProjectId(selectedOption.value);
        setSelectedProject(selectedOption.label);
    };
    const getAppName = () => value => {
        setAppName(value.value)
        setSelectedAppBusiness(value.label)
    };


    const handleAddBusinessApp = () => {

        let payload = {
            business_id: businessId,
            project_id: project_id,
            app_name: app_name,
        }

        addBusinessApp(payload)
    }

    useEffect(() => {

        if (added) {
            handleClose();
            window.location.reload();
        }

    }, [added])




    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'}
            PaperProps={{
                style: {
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    borderRadius: 5,
                    margin: 'auto',

                },
            }}
        >
            <DialogTitle>
                Add Enterprise App
            </DialogTitle>

            <Divider />

            <DialogContent sx={{ padding: breakpoints === 'xs' || breakpoints === 'sm' ? 3 : 6, paddingTop: 2 }}>
                <Grid container spacing={2} sx={{ p: 1 , pb: 0 }}>
                    <Grid item xs={12}><Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select Enterprise :</Typography></Grid>
                    <Grid item xs={12} sx={{ pb: 3 }}>
                        {isFetchingBusinessNames ?
                            <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                            :
                            <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selected_business}
                                onChange={handleChangeBusiness}
                                options={business_names.map(business => ({ value: business.id, label: business.business_name }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Enterprise
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: 1, pb: 0 }}>
                    <Grid item xs={12}><Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select Project :</Typography></Grid>
                    <Grid item xs={12} sx={{ pb: 3 }}>
                        {loadingProjects ?
                            <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                            :
                            <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedProjectName}
                                onChange={handleChangeProject}
                                options={projects.map(project => ({ value: project.id, label: project.application_name }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Project
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12}><Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select App :</Typography></Grid>
                    <Grid item xs={12} sx={{ pb: 3 }}>
                        {loading ?
                            <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                            :
                            <ReactSelect
                                className="basic-single"
                                placeholder={app_options[0]?.label}
                                defaultValue={selected_app_business ? selected_app_business : undefined} // Clear the default value
                                isRtl={false}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                options={app_options}
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                                onChange={getAppName()}
                            />
                        }
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions container sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: 'black', textTransform: 'none' }}>Cancel</Button>
                <Button variant="contained"
                    onClick={handleAddBusinessApp}
                    startIcon={addingBusinessApp &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {addingBusinessApp ? 'Adding' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateBusinessAppDialog