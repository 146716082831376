import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpPut, HttpPut1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const createBusiness = (formData) => {

    let payload = formData

    console.log(" payload createBusiness :", payload)

    let result = HttpPostMultipart(routes.CREATE_BUSINESS, payload);
    
    return result;
}

const getBusinessList = (page, limit) => {

    let parameters = {
        page: page ,
        limit: limit
    };

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getAllBusinessList = (page, limit) => {

    let parameters = {
        page: page ,
        limit: limit
    };

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListStatus = (status, page, limit) => {

    let parameters = {
        status: status,
        page: page ,
        limit: limit
    };

    console.log("getBusinessListStatus parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}


const getBusinessListPlan = (plan, page, limit) => {

    let parameters = {
        plan: plan,
        page: page ,
        limit: limit
    };

    console.log("getBusinessListPlan parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListPlanWithStatus = (status, plan, page, limit, type) => {

    let parameters = {
        status: status,
        plan: plan,
        page: page ,
        limit: limit,
        type: type
    };

    console.log("getBusinessListPlanWithStatus parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListNameWithStatus = (status, businessName, page, limit, type) => {

    let parameters = {
        status: status,
        businessName: businessName,
        page: page ,
        limit: limit,
        type: type
    };

    console.log("getBusinessListNameWithStatus parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListWebsiteWithStatus = (status, website, page, limit, type) => {

    let parameters = {
        status: status,
        website: website,
        page: page ,
        limit: limit,
        type: type
    };

    console.log("getBusinessListWebsiteWithStatus parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListWebsite = (website, page, limit) => {

    let parameters = {
        website: website,
        page: page ,
        limit: limit,
    };

    console.log("getBusinessListWebsite parametersxxx:", parameters)

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}


const getSortBusinessList = (sort, order, page, limit) => {

    let parameters = {
        sort: sort,
        order: order,
        page: page ,
        limit: limit,
    };

    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessListNames = () => {

    let parameters = "";

    let result = HttpGet(routes.GET_BUSINESS_LIST_NAMES, parameters, SUBSCRIPTION_KEY);

    return result;
}

const getBusinessByID = (id) => {
    let parameters = {
        id: id 
    };
    let result = HttpGet(routes.GET_BUSINESS_BY_ID, parameters, SUBSCRIPTION_KEY);
    return result;
}

const updateBusiness = (formData) => {

    let payload = formData;

    let result = HttpPatch(routes.UPDATE_BUSINESS, payload);

    return result;
}

const searchPrincipalNameyID = (principal_name, id) => {

    let parameters = {
        principal_name: principal_name,
        id: id
    };
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_PRINCIPAL_NAME_BY_ID, parameters, subscriptionKey);
    
    return result;
}

const getCounts = () => {

    let parameters = "";
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_COUNTS, parameters, subscriptionKey);
    
    return result;
}

const getSearchByBusinessName = (businessName, page, limit) => {

    let parameters = {
        businessName: businessName,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_BUSINESS_LIST, parameters, subscriptionKey);
    
    return result;
}

const updateBusinessExpiration = (payload) => {

    console.log("update business expiration xxxx" , payload);

    let result = HttpPut(routes.UPDATE_BUSINESS_EXPIRATION, payload);
    
    return result;
}

const removeBusinessExpiration = (id) => {

    let payload = {
        id: id
    };

    console.log("remove business expiration xxxx" , payload);

    let result = HttpPut1(routes.REMOVE_BUSINESS_EXPIRATION, payload);
    
    return result;
}

const getBusinessOverviewStats = (businessId) => {
    let query_params = {
        businessId: businessId,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_BUSINESS_OVERVIEW_STATS, query_params, subscriptionKey);

    return result; 
}

const getBusinessUsers = (businessId) => {
    let query_params = {
        businessId: businessId,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_BUSINESS_USERS, query_params, subscriptionKey);

    return result; 
}



const BusinessService = {
    createBusiness: createBusiness,
    getBusinessList: getBusinessList,
    getBusinessListNames : getBusinessListNames,
    getBusinessByID: getBusinessByID,
    updateBusiness: updateBusiness,
    searchPrincipalNameyID: searchPrincipalNameyID,
    getCounts: getCounts,
    getSearchByBusinessName: getSearchByBusinessName,
    updateBusinessExpiration: updateBusinessExpiration,
    removeBusinessExpiration: removeBusinessExpiration,
    getBusinessListStatus,
    getBusinessListPlan,
    getBusinessListPlanWithStatus,
    getBusinessListNameWithStatus,
    getBusinessListWebsite,
    getBusinessListWebsiteWithStatus,
    getBusinessOverviewStats: getBusinessOverviewStats,
    getBusinessUsers: getBusinessUsers,
    getAllBusinessList, getSortBusinessList
}

export default BusinessService