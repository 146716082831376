import React from "react";
import {DialogTitle, Button, Typography, Dialog, DialogActions, DialogContent} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CancelPDFTemplateDialog = (props) => {

    const {openUnsaveTemplate, handleCloseUnsaveTemplateDialog, breakpoints} = props;

    const navigate = useNavigate();

    const handleConfirmCancel = (e) => {
        localStorage.removeItem("template_elements")
        localStorage.removeItem("deleted_elements")
        localStorage.removeItem("deletedPropertyIDs")
        
        // window.location.href='/template-list';
        navigate(-1);
    }


    return (
        <Dialog
            maxWidth={"sm"} fullWidth={"sm"} 
            open={openUnsaveTemplate}
            onClose={handleCloseUnsaveTemplateDialog}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}
        >
            <DialogTitle>
                Unsaved Changes?
            </DialogTitle>

            <DialogContent sx={{mt: "4px"}}>
                <Typography sx={{fontSize: 18}}>
                    You have unsaved changes. If you proceed, these changes will be lost. Proceed?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} autoFocus onClick={handleCloseUnsaveTemplateDialog}>
                    Cancel
                </Button>

                <Button variant="contained" autoFocus sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    onClick={(e) => handleConfirmCancel(e)}  
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelPDFTemplateDialog