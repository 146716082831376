import AccessControlService from "../services/api/access-control";
import { useState } from "react";
import { useSnackbar } from "notistack";
import CreditsManagementService from "../services/api/credits";


const useCredits = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [businessCredits, setBusinessCredits] = useState([]);
    const [addingBusinessCredits, setAddingBusinessCredits] = useState(false);
    const [businessCreditsAdded, setBusinessCreditsAdded] = useState(false);
    const [subtructingBusinessCredits, setSubBusinessCredits] = useState(false);
    const [businessCreditsSub, setBusinessCreditsSub] = useState(false);
    const [settingBusinessCreditUnlimited, setBusinessCreditUnlimited] = useState(false);
    const [projectCredits, setProjectCredits] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [addingProjectCredits, setAddingProjectCredits] = useState(false);
    const [subtructingProjectCredits, setSubProjectCredits] = useState(false);
    const [addedProjectCredits, setAddedProjectCredits] = useState(false);
    const [subtructedProjectCredits, setSubtructedProjectCredits] = useState(false);
    const[totalCreditsAdded, setTotalcreditsAdded] = useState(0);



    var limit = 10;

    const getBusinessCredits = (page) => {
        setIsLoading(true);
    
        CreditsManagementService.getBusinessCredits(page,limit).then(res => {
    
          setIsLoading(false);
    
          if (res.data.success) {
            setBusinessCredits(res.data.data);
            
          }
    
        }).catch((error)=>{ 

            setIsLoading(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
    
        })
    }

    const getAllBusinessCredits = (page) => {
        setIsLoading(true);
    
        CreditsManagementService.getBusinessCredits(page,1000).then(res => {
    
          setIsLoading(false);
    
          if (res.data.success) {
            setBusinessCredits(res.data.data);
            
          }
    
        }).catch((error)=>{ 

            setIsLoading(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
    
        })
    }
    
    const addBusinessCredits = (payload) => {
        setAddingBusinessCredits(true)

        CreditsManagementService.addBusinessCredits(payload).then((res)=>{

            setAddingBusinessCredits(false)

            if(res.data.success){
               setBusinessCreditsAdded(true)
               setTotalcreditsAdded(res.data.data)
               enqueueSnackbar('Credits successfully added.', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            }

        }).catch((error) => {

            setAddingBusinessCredits(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }

    const subBusinessCredits = (businessId, credit) => {
        setSubBusinessCredits(true)

        CreditsManagementService.subBusinessCredits(businessId, credit).then((res)=>{

            setSubBusinessCredits(false)

            if(res.data.success){
               setBusinessCreditsSub(true)
               enqueueSnackbar('Credits successfully subtructed.', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            }

        }).catch((error) => {

            setSubBusinessCredits(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }
    

    const setBusinessCreditsUnlimited = (businessId, unlimited) => {
        setBusinessCreditUnlimited(true)

        CreditsManagementService.setBusinessCreditsUnlimited(businessId, unlimited).then((res)=>{

            setBusinessCreditUnlimited(false)

            if(res.data.success){
               enqueueSnackbar('Credits successfully updated.', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            }

        }).catch((error) => {

            setBusinessCreditUnlimited(false)

            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });

        })
    }


    const getProjectCredits = (businessId, page) => {
        setIsLoading(true);
    
        CreditsManagementService.getProjectCreditList(businessId, page, limit).then(res => {
    
          setIsLoading(false);
    
          if (res.data.success) {
            setProjectCredits(res.data.data);
          }
    
        }).catch((error)=>{ 

            setIsLoading(false);
            enqueueSnackbar(error.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            });
    
        })
    }
    
    const addProjectCredits = (payload) => {
        setAddingProjectCredits(true)

        CreditsManagementService.addProjectCredits(payload).then((res)=>{

            setAddingProjectCredits(false)

            if(res.data.success){
                setAddedProjectCredits(true)
                enqueueSnackbar('Credits successfully added.', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {

            console.log("errorxxx", error)

            setAddingProjectCredits(false)

            if(error.response.data.error){
                enqueueSnackbar(error.response.data.error, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
            }else {
                enqueueSnackbar(error.response.data.message, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
            }
           
        })
    }

    const subProjectCredits = (businessId, projectId, credit) => {
        setSubProjectCredits(true)

        CreditsManagementService.subProjectCredits(businessId,projectId, credit).then((res)=>{

            setSubProjectCredits(false)

            if(res.data.success){
                setSubtructedProjectCredits(true);
               enqueueSnackbar('Credits successfully subtructed.', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            }

        }).catch((error) => {

            setSubProjectCredits(false)

            if(error.response.data.error){
                enqueueSnackbar(error.response.data.error, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
            }else {
                enqueueSnackbar(error.response.data.message, { 
                    variant: 'error',
                    autoHideDuration: 3000
                });
            }

        })
    }
    

    return{ 
        isLoading, getBusinessCredits, businessCredits, addBusinessCredits, addingBusinessCredits, businessCreditsAdded,
        subBusinessCredits, subtructingBusinessCredits, businessCreditsSub, setBusinessCreditsUnlimited, settingBusinessCreditUnlimited,
        getProjectCredits, projectCredits, addProjectCredits, addingProjectCredits , subProjectCredits, subtructingProjectCredits,
        addedProjectCredits, subtructedProjectCredits, totalCreditsAdded, getAllBusinessCredits
    };
};

export default useCredits