import {useState} from "react";
import B2CAccountService from "../services/api/b2c-account";
import {useSnackbar} from "notistack";
import { log } from "../console-config.log";
import useCacheStorage from "./useCacheStorage";

const useB2CAccount = () => {

  const {enqueueSnackbar} = useSnackbar();

  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [isUploadingSignature, setUploadingSignature] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [getB2CProfile, setGetB2CProfile] = useState(null);
  const [isFetchingProfile, setFetchingProfile] = useState(false);
  const [isSignatureUpdated, setSignatureUpdated] = useState(false);


  const {retrieveDataFromCacheStorage, storeDataInCacheStorage, deleteDataByCacheNameFromCacheStorage} = useCacheStorage();

  const getProfile = async () => {
    setFetchingProfile(true);
    
    const storedProfileData = await retrieveDataFromCacheStorage("profile-cache", "profile-data");

    if (storedProfileData) {
      setFetchingProfile(false);
      setGetB2CProfile(storedProfileData);

    } else {
      B2CAccountService.getAccountProfile().then((res) => {
        setFetchingProfile(false);
        
        if (res.data.success) {
          setGetB2CProfile(res.data.data);
          storeDataInCacheStorage("profile-cache", "profile-data", res.data.data);
        }

      }).catch((error) => {
        setFetchingProfile(false);
        setGetB2CProfile(null);
      })
    }
  }

  const updateSignature = async (file, userId) => {
    setUploadingSignature(true);
    console.log("userId here", userId)
  
    try {
      const res = await B2CAccountService.uploadSignature(file, userId);
      log("updateSignature data xxxx", res);
  
      if (res.data.success) {
        setSignatureUpdated(true);
        setUploadedSignature(res.data);

        await deleteDataByCacheNameFromCacheStorage("profile-cache");

        enqueueSnackbar("Successfully updated signature", { 
          variant: "success",
          autoHideDuration: 3000
        });
        
        setUploadingSignature(false);
      }
      
    } catch (error) {
      log("updateSignature error xxxx", error);
      setUploadingSignature(false);
      setSignatureUpdated(false);
      setUploadedSignature(null);
    }
  }

  const updateProfileSignature = async (formData, handleClose) => {
    setUploadingSignature(true);
  
    try {
      const res = await B2CAccountService.uploadSignature(formData);
      log("updateSignature data xxxx", res);
  
      if (res.data.success) {
        setSignatureUpdated(true);
        setUploadedSignature(res.data);

        // setUserSignatureUpdated({isUpdated: true, data: res.data.data});

        await deleteDataByCacheNameFromCacheStorage("profile-cache");

        enqueueSnackbar("Successfully updated signature", { 
          variant: "success",
          autoHideDuration: 3000
        });
        
        setUploadingSignature(false);
        handleClose();
      }
      
    } catch (error) {
      log("updateSignature error xxxx", error);
      setUploadingSignature(false);
      setSignatureUpdated(false);
      setUploadedSignature(null);
    }
  }

  const updateSignatureFromAndroid = async (formData, accessToken) => {
    setUploadingSignature(true);

    try {
      const res = await B2CAccountService.uploadSignatureFromAndroid(formData, accessToken);
      log("updateSignature data xxxx", res);
  
      if (res.data.success) {
        setSignatureUpdated(true);
        setUploadingSignature(false);
        setUploadedSignature(res.data);
        setHasSignature(true);
        setOpenSuccessDialog(true);

        enqueueSnackbar("Successfully updated signature", { 
          variant: "success",
          autoHideDuration: 3000
        });

        await deleteDataByCacheNameFromCacheStorage("profile-cache");
      }
      
    } catch (error) {
      log("updateSignature error xxxx", error);
      setUploadingSignature(false);
      setSignatureUpdated(false);
    }
  }
  

  const convertUrlToFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function(res) {
        return res.arrayBuffer();
      })
  
      .then(function(buf){
        return new File([buf], filename, {type:mimeType});
      })
    )
  }
 
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    
    window.location.href = "/" ;

  }

  return {
    updateSignature, isUploadingSignature, convertUrlToFile, 
    getB2CProfile, getProfile, uploadedSignature, updateSignatureFromAndroid,
    hasSignature, openSuccessDialog, handleCloseSuccessDialog, isFetchingProfile,
    isSignatureUpdated, updateProfileSignature, setUploadingSignature
  }

}

export default useB2CAccount;