import { Avatar, Box, Button, Card, CardContent, ClickAwayListener, Grid, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CreatePackageDialog from "./dialogs/create-package.dialog";
import usePackageManagement from "../../hooks/usePackageManagement";
import PackageSettingSkeletonComponent from "../skeletons/package-setting-skeleton.component";
import CreateFeatureDialog from "./dialogs/create-feature.dialog";
import useWidth from "../../hooks/useWidth";
import UpdateFeatureDialog from "./dialogs/update-feature.dialog";
import DeleteFeatureDialog from "./dialogs/delete-feature.dialog";
import PackageSettingCardComponent from "./package-setting-card.component";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForbiddenScreen from "../../screens/ForbiddenScreen";





const PackageFeatureActionPopper = (props) => {

    const { getPackageSummary } = props;


    const [anchorEl, setAnchorEl] = useState(null);
    const [openAddFeature, setOpenAddFeature] = useState(false);
    const [openUpdateFeature, setOpenUpdateFeature] = useState(false);
    const [openDeleteFeature, setOpenDeleteFeature] = useState(false);

    const handleOpenAddFeature = () => {
        setOpenAddFeature(true);
    }

    const handleCloseAddFeature = () => {
        setOpenAddFeature(false);
    }

    const handleOpenUpdateFeature = () => {
        setOpenUpdateFeature(true);
    }

    const handleClosenUpdateFeature = () => {
        setOpenUpdateFeature(false);
    }

    const handleOpenDeleteFeature = () => {
        setOpenDeleteFeature(true);
    }

    const handleClosenDeleteFeature = () => {
        setOpenDeleteFeature(false);
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton aria-describedby={id} sx={{ color: 'black', cursor: 'pointer', '&:hover': { backgroundColor: 'transparent' } }} onClick={handleClick}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <MoreVertIcon />
                            <span style={{ marginLeft: '2px', fontSize: 14, color: '#0178f2' }}>Manage Feature</span>
                        </div>
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleOpenAddFeature} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <AddBoxIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Add Feature</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenUpdateFeature} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Edit Feature</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeleteFeature} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Remove Feature</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

            {openAddFeature &&
                <CreateFeatureDialog
                    openAddFeature={openAddFeature}
                    handleClose={handleCloseAddFeature}
                    getPackageSummary={getPackageSummary}
                />
            }

            {openUpdateFeature &&
                <UpdateFeatureDialog
                    openUpdateFeature={openUpdateFeature}
                    handleClose={handleClosenUpdateFeature}
                    getPackageSummary={getPackageSummary}
                />
            }

            {openDeleteFeature &&
                <DeleteFeatureDialog
                    openDeleteFeature={openDeleteFeature}
                    handleClose={handleClosenDeleteFeature}
                    getPackageSummary={getPackageSummary}
                />
            }

        </Stack>
    )
}



const PackageSettingsComponent = () => {

    const breakpoints = useWidth();

    const { getPackageSummary, isFetching, packages, error } = usePackageManagement();

    const [openCreatePackage, setOpenCreatePacakge] = useState(false);

    const handleOpenCreatePackage = () => {
        setOpenCreatePacakge(true);
    }

    const handleCloseCreatePackage = () => {
        setOpenCreatePacakge(false);
    }


    useEffect(() => {
        getPackageSummary();
    }, [])


    return (
        <Box>
            {error === 'Forbidden' ?
                <ForbiddenScreen />
            :
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Grid container sx={{ width: "95%", margin: "auto", mt: 3 }}>
                            <Grid item xs={7} sx={{ alignSelf: 'center' }}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Package Management</Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ textAlign: 'end' }}>
                                <Grid container sx={{ alignSelf: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={5} sx={{ textAlign: 'end', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="text"
                                            sx={{
                                                fontSize: 14,
                                                p: '4px 5px',
                                                borderRadius: 10,
                                                boxShadow: 'none',
                                                color: '#0178f2',
                                                textTransform: 'none',
                                            }}
                                            startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                            onClick={handleOpenCreatePackage}
                                        >
                                            Add Package
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'end', alignSelf: 'center', alignItems: 'flex-end' }}>
                                        <PackageFeatureActionPopper getPackageSummary={getPackageSummary} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {isFetching ?
                            <Grid container spacing={2} sx={{ width: "95%", margin: "auto", mt: 3, mb: 1 }}>
                                {[0, 1, 2].map((index) => (
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                                        <PackageSettingSkeletonComponent breakpoints={breakpoints} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <Grid container sx={{ width: "95%", margin: "auto", mt: 3 }}>
                                {packages.map((plan, index) => (
                                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
                                        <PackageSettingCardComponent
                                            index={index}
                                            plan={plan.package}
                                            features={plan.features}
                                            isLoading={isFetching}
                                            breakpoints={breakpoints}
                                            getPackageSummary={getPackageSummary}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </Grid>

                    {openCreatePackage &&
                        <CreatePackageDialog
                            openCreatePackage={openCreatePackage}
                            handleClose={handleCloseCreatePackage}
                            getPackageSummary={getPackageSummary}
                        />
                    }

                </Grid>
            }
        </Box>

    )
}

export default PackageSettingsComponent;
