import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch, HttpPut1, HttpDelete, HttpPut} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;


const getAllAccessControl = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ACCESS_CONTROL_LIST, query_params , subscriptionKey);

    return result;
    
}

const getAccessControl = (page, limit, roleId) => {

    let query_params = {
        page: page,
        limit: limit,
        roleId: roleId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ACCESS_CONTROL_LIST, query_params , subscriptionKey);

    return result;
    
}


const addAccessControl = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADD_ACCESS_CONTROL, payload , subscriptionKey);

    return result;
    
}

const removeAccessControl = (roleId, permissionId) => {

    let query_params = {
        roleId : roleId,
        permissionId : permissionId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_ACCESS_CONTROL, query_params , subscriptionKey);

    return result;
    
}

const updateAccessControl = (id, isAllowed) => {

    let parameters = {
        id : id,
        isAllowed: isAllowed
    }

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ACCESS_CONTROL, parameters, payload, subscriptionKey);
    
    return result;
}



const AccessControlService = {
    getAllAccessControl,
    getAccessControl,
    addAccessControl,
    removeAccessControl,
    updateAccessControl
}

export default AccessControlService