import {Box, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import useWidth from '../hooks/useWidth';
import useAudit from '../hooks/useAudit';
import SignInLogsSkeletonComponent from '../components/skeletons/sign-in-logs-skeleton.component';
import SignInLogsComponent from '../components/sign-in-logs/sign-in-logs.component';
import useUsers from '../hooks/useUsers';




function TableHeader(){

    const tablecell = [
        // {label : 'User ID'},
        {label : 'Email'},
        {label : 'Device Details'},
        {label : 'Location'},
        {label : 'Coordinates'}
    ];

    return(
        <TableHead >
            <TableRow >
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: '#0178f2', color: 'white'}}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}



const SignInLogsScreen = () => {

    const breakpoints = useWidth();

    const [page, setPage] = useState(1);

    const {  admin, getAdmin } = useUsers();

    const { loading, retrieveSigninLogs, logs, pagination, retrieved, setLoading, retrieveAdminSigninLogsPagination, retrieveAllSigninLogs, Alllogs, retrieveAdminSigninLogs } = useAudit();


    useEffect(() => { getAdmin(); },[])

    let email = admin && admin?.identities[0]?.issuerAssignedId

    // useEffect(() => { 
    //     if(admin){
    //         retrieveAllSigninLogs(email);
    //     }
    // },[admin])

    useEffect(() => { 
        // if(admin){
            // retrieveSigninLogs(email); 
            retrieveAdminSigninLogs();
        // }
    },[])


    const handleChangePagination = (event,page) => {
        setPage(page);
        setLoading(true);
        retrieveAdminSigninLogsPagination();
    }

    console.log("paginationxxxx", pagination)

    return (
        <Box sx={{overflow: 'hidden'}}>
            <Box sx={{padding: 1, width:'100%', borderRadius: 0}}>
                <Grid container  sx={{textAlign: 'start', marginLeft: 2}}>
                    <Grid item xs={12} >
                        <Typography variant='h6'>Sign in Logs</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={{padding: 3}}>
                <TableContainer component={Paper} sx={{border: '1px solid #8080807a', borderRadius: 2,  width: '100%'}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader/>

                        {loading ?
                            <TableBody>
                                <SignInLogsSkeletonComponent />
                            </TableBody>

                        :
                            <TableBody >
                                {logs?.map((logs, index) => {
                                    return (
                                        <SignInLogsComponent data={logs} index={index} breakpoints={breakpoints} /> 
                                    )
                                })}
                            </TableBody>
                        } 
                      
                    </Table>

                    {!loading && logs?.length !== 0 && 
                         <Pagination
                            count={pagination} 
                            page={page} 
                            onChange={handleChangePagination} 
                            style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                        />
                    } 

                </TableContainer>
            </Grid>

        </Box>
    )
}

export default SignInLogsScreen