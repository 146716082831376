import { Box, Card, Grid, MenuItem, Paper, Select, Typography, InputLabel, Divider, FormControl, OutlinedInput, ListItemText, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import AdminAnalyticsService from "../../services/api/analytics";
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, } from 'recharts';
import DashboardGraphSkeletonComponent from "../skeletons/dashboard-graph-skeleton.component";
import DashboardPerMonthSkeletonComponent from "../skeletons/DashboardPerMonthSkeletonComponent";
import ForbiddenImage from "../../images/forbidden.png";
import { CheckBox } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 } } };

function PipelinePerYearComponent() {

  const [countsPipeline, setCountsPipeline] = useState(null);
  const [useKeys, setUsekeys] = useState([]);
  const [pipelineNames, setPipelineName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error_data, setErrorData] = useState(false);

  console.log('get pipelineNames hereee', pipelineNames);

  function reformatString(inputString) {
    // Remove square brackets from the input string
    const cleanedString = inputString.replace(/[[\]]/g, '');

    // Convert the cleaned string to uppercase and split into an array
    const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());

    // Filter out "ENCRYPTION" from the array
    const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');

    if (filteredArray.length === 1) {
      // If there is only one element, return it as is
      return filteredArray[0];
    } else if (filteredArray.length > 1) {
      // If there are multiple elements, join them with commas and "and"
      const lastIndex = filteredArray.length - 1;
      return (
        filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
      );
    } else {
      // If no elements are left after filtering, return an empty string
      return '';
    }
  }


  const parsePipelineNames = (pipelineString) => {
    const parsedArray = pipelineString.replace(/[\[\] ]/g, '').split(',');
    return parsedArray;
  };

  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const [isAllPipelineSelected, setAllPipelineSelected] = useState(true);
  const [checkboxDisabled, setCheckboxDisabled] = useState(true);

  const getTransactionPipelinePerYear = () => {
    setLoading(true)
    AdminAnalyticsService.getCountTransactionPipelinePerYear().then((res) => {
      console.log('get transaction pipeline count per year', res.data.data);
      if (res.data.success) {
        setLoading(false)
        var pipelinePerYear = res.data.data;
        let data = []
        let pipeline_names = []
        let pipeline_future = {}
        Object.keys(pipelinePerYear).forEach(key => {

          let year = key

          let pipelineData = {}
          pipelineData.year = year

          pipelineData.pipeline = reformatString(key.replace(/[[\]]/g, '')); // Using reformatString function

          pipelinePerYear[key].forEach((pipeline_data) => {
            pipelineData[pipeline_data.pipeline] = pipeline_data.count

            if (!pipeline_names.includes(pipeline_data.pipeline)) {
              pipeline_names.push(pipeline_data.pipeline)
            }
          })

          data.push(pipelineData)
          pipeline_future.year = parseInt(year) + 1
        })
        // data.reverse()
        data.push(pipeline_future)
        setCountsPipeline(data);
        setPipelineName(pipeline_names)
      }
    }).catch((err) => {
      setLoading(false);
      console.log("err here", err)
      setErrorData(err.response.statusText);

    })
  }
  console.log('get pipeline per year', countsPipeline);

  useEffect(() => {
    getTransactionPipelinePerYear()
  }, [])


  const handlePipelineChange = (event) => {
    setAllPipelineSelected(false);
    setCheckboxDisabled(false);

    const { value } = event.target;
    setSelectedPipelines(value);
  };

  const renderSelectedValues = (selected) => {
    return selected
      .map((value) => pipelineNames.find((option) => option.value === value)?.label)
      .join(', ');
  };

  useEffect(() => {
    if (selectedPipelines.length > 0) {
      setAllPipelineSelected(false);
      setCheckboxDisabled(false);
    }
  }, [selectedPipelines]);

  function random_color() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    return color;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      {error_data === 'Forbidden' ?

        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
            <Typography textAlign="center" mb={2} sx={{ fontSize: 18, color: 'black' }}>
              You are not authorized.
            </Typography>
            <Typography variant="body1" component="div" textAlign="center" fontSize={14}>
              You tried to access a page you did not have prior authorization for.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center">
            <img alt="" src={ForbiddenImage} style={{ height: 250 }} />
          </Grid>
        </Grid>

        :
        <div className="row">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Pipeline Per Year</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className="card-project" sx={{ minWidth: '100%', maxWidth: 200, backgroundColor: '#fff', '&:focus-within': { borderColor: 'gray' } }}>
                <InputLabel id="demo-multiple-checkbox-label">Selected Pipeline</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedPipelines}
                  onChange={(event) => setSelectedPipelines(event.target.value)}
                  input={<OutlinedInput sx={{ color: '#000' }} label="Selected Pipeline" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                >
                  {pipelineNames && pipelineNames?.map((pipelineName, index) => (
                    <MenuItem key={index} value={pipelineName}>
                      <Checkbox checked={selectedPipelines?.includes(pipelineName)} onChange={() => handlePipelineChange(pipelineName)} />
                      <ListItemText primary={pipelineName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: 2 }} />
          {loading ? <DashboardPerMonthSkeletonComponent />
            :
            <>
              <h5 className="section-title">Authentication</h5>
              <ResponsiveContainer width="100%" height={395}>
                {/* <LineChart data={countsPipeline} margin={{ top: 5, right: 20, left: 20, bottom: 5 }} style={{ margin: 'auto' }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={'year'} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {pipelineNames?.map((pipeline, index) => {
                    return (
                      <Line type="monotone" dataKey={pipeline} stroke={random_color()} activeDot={{ r: 8 }} />
                    )
                  })}
                </LineChart> */}
                <LineChart data={countsPipeline} margin={{ top: 5, right: 50, left: 20, bottom: 5 }} style={{ margin: 'auto' }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip/>
                  {/* <Legend /> */}
                  {pipelineNames && pipelineNames?.map((pipeline, index) => {
                    if (selectedPipelines?.length === 0 || selectedPipelines?.includes(pipeline)) {
                      return (
                        <Line
                          key={pipeline}
                          type="monotone"
                          dataKey={pipeline}
                          stroke={random_color()}  // Assign a random color (or use a fixed color)
                          activeDot={{ r: 8 }}
                        />
                      );
                    }
                    return null;
                  })}
                </LineChart>
              </ResponsiveContainer>
            </>
          }
        </div>
      }
    </Paper>
  )
}

export default PipelinePerYearComponent