import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useProjects from "../hooks/useProjects"
import { Masonry } from '@mui/lab';
import ProjectListComponent from "../components/project/project-list.component";
import useAnalytics from "../hooks/useAnalytics";
import ProjectListSkeletonComponent from "../components/skeletons/project-list-skeleton.component";
import ProjectTabsComponent from "../components/enterprise/project-tabs.component";
import useWidth from "../hooks/useWidth";
import useCreditsManagement from "../hooks/useCreditsManagement";
import CreateProjectDialog from "../components/project/dialog/create-project.dialog";
import useUsers from "../hooks/useUsers";
import ForbiddenScreen from "./ForbiddenScreen";

const ProjectListScreen = (props) => {

    // let businessId = (new URLSearchParams(window.location.search)).get("id");
    let businessId = (new URLSearchParams(window.location.search)).get("id");

    const projectIdsHasRecord = [];

    const breakpoints = useWidth();

    const { getProjectList, projects, loadingProjects, error } = useProjects()
    const { countTransactionByProject, transactionCount, transactionByMonthCount, transactionError } = useAnalytics();
    const { projectCreditsList, getProjectCreditList } = useCreditsManagement();
    const { pagination, getUsersByBusinessAccountID, userAccounts, loadingUserList } = useUsers();


    const [data, setData] = useState([]);
    const [viewProject, setViewProject] = useState(false)
    const [projectId, setProjectId] = useState(null)
    const [projectList, setProjectList] = useState([]);
    const [projectCredit, setProjectCredit] = useState(0);
    const [project_name, setProjectName] = useState('');
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [page, setPage] = useState(1);

    console.log('get transaction count xxx', transactionCount)

    useEffect(() => {
        if (businessId) {
            getUsersByBusinessAccountID(businessId, page);
        }
    }, [businessId, page])


    const handleClickOpen = () => {
        setOpenCreateProject(true);
    };

    const handleClose = () => {
        setOpenCreateProject(false);
    };

    const handleClickViewProject = (id, credit, application_name) => {
        setProjectId(id);
        setViewProject(true)
        setProjectCredit(credit);
        setProjectName(application_name)
    }

    useEffect(() => { countTransactionByProject(businessId) }, []);

    useEffect(() => {
        getProjectList(businessId);
    }, [])

    useEffect(() => {
        getProjectCreditList(businessId);
    }, [])

    useEffect(() => {

        if (transactionCount) {

            var result = Object.entries(transactionCount);
            setData(result);

            if (result?.length > 0) {

                result?.map((data) => {

                    let project_id = data[0];
                    projectIdsHasRecord.push(project_id);

                    projects?.map((project) => {

                        if (project?.id == project_id) {
                            data[0] = project?.application_name
                            data[2] = project?.logo
                            data[3] = project?.created_date
                        }
                    })
                    console.log("projectIdsHasRecord", projectIdsHasRecord)
                })


            }
        }
    }, [JSON.stringify(transactionCount), JSON.stringify(projects)])

    useEffect(() => {
        if (projects && projectCreditsList) {
            const updatedProjectList = projects.map(project => {
                const matchingCredit = projectCreditsList.find(credit => credit.accountProject.id === project.id);

                return matchingCredit ? { ...project, credit: matchingCredit.credit } : project;
            });

            setProjectList(updatedProjectList);
        }
    }, [JSON.stringify(projects), JSON.stringify(projectCreditsList)]);

    console.log("userAccountsxxxx", userAccounts)

    return (
        <Box>
            {error === "Forbidden" ?
                <ForbiddenScreen />
                :
                <>
                    <Box sx={{ position: 'absolute', right: '1px', left: '92%', marginTop: '-40px' }}>
                        {!viewProject && !loadingProjects && (
                            <Button
                                type="button"
                                className="card-add"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#0178f2',
                                    borderRadius: 1,
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    fontSize: 12,
                                    padding: '5px 15px 5px 15px',
                                    minHeight: '5px'
                                }}
                                onClick={handleClickOpen}
                                disabled={userAccounts?.length > 0 ? false : true}
                            >
                                Add Project
                            </Button>
                        )}
                    </Box>


                    {loadingProjects ?
                        <ProjectListSkeletonComponent />
                        :
                        <>
                            {!viewProject ?
                                <>
                                    {projectList?.length > 0 ?
                                        <Masonry defaultHeight={450} defaultColumns={3} columns={{ xs: 1, sm: 3 }} spacing={2} sx={{ margin: "auto", width: "100%", padding: 0 }}>
                                            {projectList?.map((project) => {
                                                return (
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <ProjectListComponent
                                                                handleClickViewProject={handleClickViewProject}
                                                                project={project}
                                                                transactionCount={transactionCount}
                                                                transactionByMonthCount={transactionByMonthCount}
                                                                breakpoints={breakpoints}
                                                                setProjectCredit={setProjectCredit} transactionError={transactionError}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Masonry>
                                        :
                                        <Box sx={{ margin: 10, textAlign: 'center', color: 'gray' }}>
                                            <Typography>No project has yet been created.</Typography>
                                        </Box>
                                    }
                                </>
                                :
                                <ProjectTabsComponent projectId={projectId} projectCredit={projectCredit} project_name={project_name} businessId={businessId} />
                            }

                        </>
                    }

                </>
            }

            {openCreateProject &&
                <CreateProjectDialog
                    openCreateProject={openCreateProject}
                    handleClose={handleClose}
                    businessId={businessId}
                    breakpoints={breakpoints}
                    getProjectList={getProjectList}
                />
            }
        </Box>
    )
}
export default ProjectListScreen