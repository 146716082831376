
import React from 'react';
import {Navigate} from "react-router-dom";
import AuthService from "../services/auth";

function PrivateRoute({children}) {

  var isUserLoggedIn = AuthService.isUserLoggedIn();
  
  return isUserLoggedIn ? children : <Navigate to="/" />;

}

export {PrivateRoute}