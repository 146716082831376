import {useState} from "react";
import { useSnackbar } from "notistack";
import AdminAnalyticsService from "../services/api/analytics";
import { log } from "../console-config.log";



const useAnalytics = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [transactions, setTransaction] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState(0);
    const [businesses, setBusinesses] = useState([]);
    const [business_counts, setBusinessCounts] = useState([]);
    const [reference_details, setReferenceDetails] = useState("");
    const [per_month, setPerMonth] = useState([]);
    const [pipeline_data, setDatatPipeline] = useState([]);
    const [project_pipeline, setProjectPipeline] = useState([]);
    const [transactionCount, setTransactionCount] = useState([]);
    const [transactionByMonthCount, setTransactionByMonthCount] = useState([]);
    const [searchPagination, setSearchPagination] = useState(0);
    const [isSearchReference, setIsSearchReference] = useState(null);
    const [error, setError] = useState("");
    const [deleted, setDeleted] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [transactionError, setTransactionError] = useState(null)


    var limit = 10;


    const getTransactionsByBusinessId = (businessId,page) => {

        setLoading(true)

        AdminAnalyticsService.getTransactionsByBusinessId(businessId,page,limit).then((res) => {

            setLoading(false)

            if(res.data.success){
                setTransaction(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }else{
                setTransaction([]);
                setLoading(false)
            }

            console.log("getTransactionsByBusinessId res", res)

        }).catch((error) => {

            setLoading(false)
            setPagination(0);
            setTransaction([]);
            console.log("getTransactionsByBusinessId error", error)
            setError(error?.response?.statusText);

        })

    }



    const filterAuthenticationByReference = (businessId,reference,page) => {

      setLoading(true)

      AdminAnalyticsService.filterAuthenticationByReference(businessId,reference,page,limit).then((res) => {

          setLoading(false)

          if(res.data.success){
              setTransaction(res.data.data);
              if(res.data.data.length === 0){
                  setPagination(0)
                }else{
                  var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                  console.log("last_page", last_page);
                  setPagination(last_page)
                }
          }else{
              setTransaction([]);
          }

          console.log("filterAuthenticationByReference res", res)

      }).catch((error) => {

          setLoading(false)
          setPagination(0);
          setTransaction([]);
          console.log("getTransactionsByBusinessId error", error)

      })

  }


    
    const getTopBusiness = (page) => {
        setLoading(true);
        AdminAnalyticsService.getTopBusiness(page, limit).then((res)=>{
            console.log('get top business', res)
           
            if (res.data.success) {
                setBusinesses(res.data.data)
                setLoading(false)
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }
          }).catch((error) => {

            setLoading(false)
            // setBusinessCounts([])
            console.log("getTopBusiness error", error)
            setError(error?.response?.statusText)

        })
    }

    const getCountTransactionByYearAndId = (page,businessId, date_from, date_to) => {

        setLoading(true)

        AdminAnalyticsService.getCountTransactionByYearAndId(page,businessId, date_from, date_to).then((res) => {

          console.log("getCountTransactionByYearAndId res:", res)

            setLoading(false)
            if(res.data.success){
                setBusinessCounts(res.data.data.given_year)
                setPerMonth(res?.data?.data?.per_month)
            }else{
                setBusinessCounts([])
            }

        }).catch((error) => {

            setLoading(false)
            // setBusinessCounts([])
            console.log("getCountTransactionByYearAndId error", error)

        })
    }

    const [countePerYear, getCountsPerYear] = useState(null);
    
    const getTransactionPerYear = () => {
      setLoading(true);
        AdminAnalyticsService.getCountTransactionPerYear().then((res)=>{
            console.log('get transaction per year', res)
            setLoading(false);
            getCountsPerYear(res.data.data)
          }).catch((error) => {

            setLoading(false)
            // setBusinessCounts([])
            console.log("getTransactionPerYear error", error)
            setError(error.response.data.error)

        })
    }

    const retrieveReferenceDetails = (reference,projectId) => {

        setLoading(true);
    
        AdminAnalyticsService.retrieveReferenceDetails(reference,projectId).then(res=>{
    
          setLoading(false);
        
    
          if(res.data.success){
            console.log("retrieveReferenceDetails res:", res);
            setReferenceDetails(res.data.data.record)
          }
       
        }).catch((err) => {
            
          setLoading(false);
          console.log("retrieveReferenceDetails error:", err);
           
        })
    }


    const countTransactionsByPipeline = (businessId, date_from, date_to) => {

        setLoading(true);
      
        AdminAnalyticsService.countTransactionsByPipeline(businessId, date_from, date_to).then(res=>{
      
          setLoading(false);
          console.log("countTransactionsByPipeline res:", res);
      
          if(res.data.success){
            setDatatPipeline(res.data.data.per_project)
            setProjectPipeline(res.data.data.per_pipeline)
          }
       
        }).catch((err) => {
            
          setLoading(false);
          console.log("countTransactionsByPipeline error:", err);
          setError(err.response.statusText);
           
        })
      }

      const countTransactionByProject = (businessId) => {
        AdminAnalyticsService.getCountTransactionByProject(businessId).then((res) => {
          console.log("get analytics data res", res)
          if(res.data.success){
            setTransactionCount(res?.data?.data?.all);
            setTransactionByMonthCount(res?.data?.data?.this_month);
          }
      }).catch((err)=>{
        log('get transactions by reference err', err)
        setTransactionError(err)
      })
      }

      const getTransactionsByReference = (businessId, reference, page) => {
        AdminAnalyticsService.getTransactionsByReference(businessId, reference, page, limit).then((res)=>{
          console.log('get transactions by reference', res)
          setIsSearchReference(res.data.data)
          if(res.data.data?.length === 0){
            setSearchPagination(0)
          }else{
            var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
            console.log("last_page", last_page);
            setSearchPagination(last_page)
          }
        })
      }


      const getTransactionsUnderProject = (businessId,projectId,page) => {

        setLoading(true)

        AdminAnalyticsService.getTransactionsUnderProject(businessId,projectId,page,limit).then((res) => {

            setLoading(false)

            if(res.data.success){
                setTransaction(res.data.data);
                if(res.data.data.length === 0){
                    setPagination(0)
                  }else{
                    var last_page = Math.ceil(res.data.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                  }
            }else{
                setTransaction([]);
            }

            console.log("getTransactionsUnderProject res", res)

        }).catch((error) => {

            setLoading(false)
            setPagination(0);
            setTransaction([]);
            console.log("getTransactionsByBusinessId error", error)

        })

    }

    const getTransactionsByStatus = (businessId,projectId,status,page) => {

      setLoading(true)

      AdminAnalyticsService.getTransactionsByStatus(businessId,projectId,status,page,limit).then((res) => {

          setLoading(false)

          if(res.data.success){
              setTransaction(res.data.data);
              if(res.data.data.length === 0){
                  setPagination(0)
                }else{
                  var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                  console.log("last_page", last_page);
                  setPagination(last_page)
                }
          }else{
              setTransaction([]);
          }

          console.log("getTransactionsByStatus res", res)

      }).catch((error) => {

          setLoading(false)
          setPagination(0);
          setTransaction([]);
          console.log("getTransactionsByBusinessId error", error)

      })

  }

    const filterAuthenticationUnderProjectByReference = (businessId,projectId,reference,page) => {
      setLoading(true)

      AdminAnalyticsService.filterAuthenticationUnderProjectByReference(businessId,projectId,reference,page,limit).then((res) => {

          setLoading(false)

          if(res.data.success){
              setTransaction(res.data.data);
              if(res.data.data.length === 0){
                  setPagination(0)
                }else{
                  var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                  console.log("last_page", last_page);
                  setPagination(last_page)
                }
          }else{
              setTransaction([]);
          }

          console.log("filterAuthenticationUnderProjectByReference res", res)

      }).catch((error) => {

          setLoading(false)
          setPagination(0);
          setTransaction([]);
          console.log("filterAuthenticationUnderProjectByReference error", error)

      })
    }


    const getCountPipelineByEnterpriseAndDateRange = (businessId, date_from, date_to) => {

      setLoading(true)

      AdminAnalyticsService.getCountPipelineByEnterpriseAndDateRange(businessId, date_from, date_to).then((res) => {

        console.log("getCountPipelineByEnterpriseAndDateRange res", res)


          setLoading(false)
          if(res.data.success){
              setBusinessCounts(res?.data?.data?.given_year)
              setProjectPipeline(res?.data?.data?.per_pipeline)
              setDatatPipeline(res?.data?.data?.per_project)
          }else{
              setBusinessCounts([])
          }

      }).catch((error) => {

          setLoading(false)
          console.log("getCountPipelineByEnterpriseAndDateRange error", error)
          setError(error.response.statusText);

      })
  }

  const getCountPipelineByProjectEnterpriseAndDateRange = (businessId, projectId, date_from, date_to) => {

    setLoading(true)

    AdminAnalyticsService.getCountPipelineByProjectEnterpriseAndDateRange(businessId, projectId, date_from, date_to).then((res) => {

      console.log("getCountPipelineByProjectEnterpriseAndDateRange res", res)


        setLoading(false)
        if(res.data.success){
            setBusinessCounts(res?.data?.data?.given_year)
            setProjectPipeline(res?.data?.data?.per_pipeline)
            setDatatPipeline(res?.data?.data?.per_project)
        }else{
            setBusinessCounts([])
        }

    }).catch((error) => {

        setLoading(false)
        console.log("getCountPipelineByProjectEnterpriseAndDateRange error", error)

    })
}


const deleteTransaction = (businessId, projectId, reference) => {

  setDeleting(true);

  AdminAnalyticsService.deleteTransaction(businessId, projectId, reference).then((res) => {

    setDeleting(false);

      if (res.data.success) {
          setDeleted(true);
          enqueueSnackbar(res.data.data, {
              variant: 'success',
              autoHideDuration: 3000
          });
      }
  }).catch((error) => {

      console.log("delete error here", error);
      setDeleting(false);

      if (error.response.data.error_message) {
          enqueueSnackbar(error.response.data.error_message, {
              variant: "error",
              autoHideDuration: 3000
          });

      } else if (error.response.data.message) {
          enqueueSnackbar(error.response.data.message, {
              variant: "error",
              autoHideDuration: 3000
          });
      } else {
          enqueueSnackbar(error.response.data.error, {
              variant: "error",
              autoHideDuration: 3000
          });
      }
  })

};



    return { loading, setLoading, transactions, getTransactionsByBusinessId, businesses, getTopBusiness, business_counts, 
        getCountTransactionByYearAndId, countePerYear, getTransactionPerYear, pagination, loading, setLoading, getTopBusiness, 
        setBusinesses, businesses, retrieveReferenceDetails, reference_details , per_month, countTransactionsByPipeline, 
        pipeline_data, project_pipeline, countTransactionByProject, transactionCount, transactionByMonthCount, getTransactionsByReference, 
        isSearchReference, filterAuthenticationByReference, getTransactionsUnderProject, filterAuthenticationUnderProjectByReference,
        getCountPipelineByEnterpriseAndDateRange, getCountPipelineByProjectEnterpriseAndDateRange, getTransactionsByStatus, deleteTransaction,
        deleted, deleting, error, transactionError
      }
}

export default useAnalytics
