import React, { useEffect } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";
import useSignature from "../../../../hooks/useSignature";

const RemoveAssignedTemplateSignatureDialog = (props) => {

    const {openDeleteTempSignatureDialog, setOpenDeleteTempSignatureDialog, tempSignatureDetails, setSignatureDeleted, removeTemplateSignature} = props;

    const {isRemovingSignature, removedAssignedSignature, removeTemplateSignature: removeTemplateSignatureMutate, setIsRemovingSignature} = useSignature();

    console.log("RemoveTemplateSignatureDialog abb", tempSignatureDetails);

    useEffect(() => {
        if (removedAssignedSignature) {
            console.log("removedAssignedSignature aa", removedAssignedSignature);
            setOpenDeleteTempSignatureDialog(false)
            setSignatureDeleted({isDeleted: true, id: tempSignatureDetails.signature.id})
        }
    }, [JSON.stringify(removedAssignedSignature)])

    const handleCloseDeleteDialog = (e) => {
        setOpenDeleteTempSignatureDialog(false);
    }

    const handleRemoveTemplateSignature = () => {
        removeTemplateSignature(tempSignatureDetails.template_id, tempSignatureDetails.signature.id, setIsRemovingSignature, removeTemplateSignatureMutate)
    }


    return (
        <Dialog open={openDeleteTempSignatureDialog} onClose={(e) => handleCloseDeleteDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Remove Signature Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
               <Typography sx={{fontSize: "16px"}}>
                    Are you sure you want to remove <strong>{tempSignatureDetails.signature.email}</strong> signature? this will also delete its assigned signature element(s).
               </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "#000", textTransform: "none", fontSize: 14}} onClick={(e) => handleCloseDeleteDialog(e)}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: 'none', fontSize: 14, '&:hover': {backgroundColor:'#0178f2', color: 'white'}}}
                    onClick={(e) => handleRemoveTemplateSignature(e)}
                    startIcon={isRemovingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isRemovingSignature) ? "Removing" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveAssignedTemplateSignatureDialog