import { Box, Button, Card, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import AddBoxIcon from '@mui/icons-material/AddBox';
import CachedIcon from '@mui/icons-material/Cached';
import useWidth from "../../hooks/useWidth";
import useUsers from "../../hooks/useUsers";
import AdminUserRowComponent from "./admin-user-row.component";
import AdminUserListSkeletonComponent from "../skeletons/admin-user-list-skeleton.component";
import CreateAdminUserDialog from "../users/dialogs/create-admin-user.dialog";
import ReactSelect from "react-select";
import ForbiddenScreen from "../../screens/ForbiddenScreen";



const statusOption = [
    { value: '1', label: "Active" },
    { value: '0', label: "Inactive" }
]

function TableHeader() {
    const tablecell = [
        { label: 'ID' },
        { label: 'Email' },
        { label: 'Role' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Actions' }
    ];

    return (
        <TableHead>
            <TableRow sx={{ position: "sticky" }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{
                            fontWeight: 700, backgroundColor: "#0178f2", color: "#fff",
                            textAlign: (value.label == "Signature") ? "center" : {}
                        }}
                        >
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const AdminUserManagementComponent = () => {

    const breakpoints = useWidth();

    const { loading, pagination, users, error, setLoading, getAdminUsers, getAdminRoles, adminRoles, filterAdminUserByStatus, filterAdminUserByRole, getAllAdminUsers, retrieved_user } = useUsers();


    const [page, setPage] = useState(1);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_role, setFilterRole] = useState("");
    const [openCreateUser, setOpenCreateUser] = useState(false);

    const [roleOptions, setRoleOptions] = useState([]);
    const [roleFilterValue, setRoleFilterValue] = useState(null);

    useEffect(() => {
        let role_options = [];

        adminRoles?.map((role) => {
            let role_options_data = {
                value: role?.role,
                label: role?.role
            }

            role_options.push(role_options_data);
        })

        setRoleOptions(role_options);
    }, [JSON.stringify(adminRoles)])



    useEffect(() => { getAdminRoles(); }, [])

    useEffect(() => { getAllAdminUsers(); }, [])


    useEffect(() => {
        if (retrieved_user) {
            getAdminUsers(page);
        }
    }, [retrieved_user, page])


    useEffect(() => {
        if (filter_status) {
            filterAdminUserByStatus(filter_status, page);

        } else if (filter_role) {
            filterAdminUserByRole(filter_role, page);
        } else {
            getAdminUsers(page);
        }
    }, [filter_status, filter_role, page])


    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleChangeRole = (selectedOption) => {
        // setFilterRole(event.target.value);
        if (selectedOption) {
            setRoleFilterValue(selectedOption.label);
            setFilterRole(selectedOption.value);
        }
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
        getAdminUsers(page);
        setLoading(true);
    }


    const handleStatusChangePagination = (event, page) => {
        setPage(page);
        filterAdminUserByStatus(filter_status, page);
        setLoading(true);
    }

    const handleRoleChangePagination = (event, page) => {
        setPage(page);
        filterAdminUserByRole(filter_role, page);
        setLoading(true);
    }

    const reload = () => {
        setFilterRole("");
        setFilterStatus("");
        getAdminUsers(page);
    }


    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            {error === "Forbidden" ?
                <ForbiddenScreen />
            :
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} sx={{ textAlign: 'start', justifyContent: 'flex-start', display: 'flex' }}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={filter_status}
                                    sx={{
                                        width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                        '&.Mui-focused': {
                                            border: 'none',
                                        },
                                    }}
                                    onChange={handleStatusChange}
                                    inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                >
                                    {statusOption?.map((status) => {
                                        return (
                                            <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }} >
                                                {status.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            &nbsp; &nbsp;

                            <ReactSelect
                                className="basic-single"
                                placeholder={roleFilterValue ?
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        {roleFilterValue}
                                    </Typography>
                                    :
                                    "Search Role"
                                }
                                isSearchable={true}
                                isRtl={false}
                                options={roleOptions}
                                value={filter_role}
                                onChange={selectedOption => handleChangeRole(selectedOption)}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 250,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                            />
                            

                            &nbsp;
                            <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>

                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ textAlign: 'end', justifyContent: 'flex-end' }}>
                            <Button
                                variant="text"
                                sx={{
                                    fontSize: 14,
                                    p: '4px 5px',
                                    borderRadius: 10,
                                    boxShadow: 'none',
                                    color: '#0178f2',
                                    textTransform: 'none',
                                }}
                                startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                onClick={(e) => setOpenCreateUser(true)}
                            >
                                Create User
                            </Button>
                        </Grid>

                    </Grid>

                    <br />


                    <Grid item xs={12} sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : "16px 0px 24px 0px" }}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }} className="card-list" >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(loading) ?
                                    <TableBody>
                                        <AdminUserListSkeletonComponent />
                                    </TableBody>
                                    :
                                    <TableBody>

                                        {(users && users)?.map((content, index) => (
                                            <AdminUserRowComponent
                                                index={index}
                                                user={content}
                                                breakpoints={breakpoints}
                                                getAdminUsers={getAdminUsers}
                                            />
                                        ))}

                                    </TableBody>
                                }
                            </Table>

                            {(!loading && users?.length == 0) &&
                                <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                    <Grid container sx={{ padding: 3 }}>
                                        <Box sx={{ textAlign: "center", margin: "auto" }}>
                                            <Typography>
                                                No available user(s) to display yet.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Box>
                            }

                            {!loading && users?.length !== 0 &&
                                <>
                                    {(filter_status) ?
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleStatusChangePagination}
                                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                        />
                                        :
                                        (filter_role) ?

                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleRoleChangePagination}
                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                            />
                                            :
                                            <Pagination
                                                count={pagination}
                                                page={page}
                                                onChange={handleChangePagination}
                                                style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                            />
                                    }
                                </>
                            }
                        </TableContainer>
                    </Grid>
                </>
            }


            {openCreateUser &&
                <CreateAdminUserDialog
                    openCreateUser={openCreateUser}
                    setOpenCreateUser={setOpenCreateUser}
                    getAdminUsers={getAdminUsers}
                />
            }

        </Box>
    )
}

export default AdminUserManagementComponent