import { Masonry } from "@mui/lab"
import { Box, Select, Card, InputLabel, FormControl, Grid, MenuItem, Paper, Skeleton, Typography, Button, Stack } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import useAnalytics from "../../hooks/useAnalytics";
import ReactSelect from 'react-select';
import useBusiness from "../../hooks/useBusiness";
import DashboardSkeletonComponent from "../skeletons/dashboard-skeleton.component";
import useProjects from "../../hooks/useProjects";
import DashboardGraphComponent from "./dashboard-graph.component";
import DashboardFabComponent from "./dashboard-fab.component";
import DashboardGraphSkeletonComponent from "../skeletons/dashboard-graph-skeleton.component";
import PipelinePerProjectComponent from "./pipeline-per-project.component";
import ProjectPerPipelineComponent from "./project-per-pipeline.component";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import { ThreeDots } from 'react-loader-spinner';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ForbiddenScreen from "../../screens/ForbiddenScreen";
import {useAnalyticsEnterpriseStore} from "../../zustand/useAnalyticsEnterpriseStore";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}));


function DashboardComponent({currentYear}) {

    const {setSelectedEnterprise, setSelectedEnterpriseID} = useAnalyticsEnterpriseStore();

    const { loading, setLoading, businesses, getTopBusiness, business_counts, getCountTransactionByYearAndId,
        per_month, pipeline_data, countTransactionsByPipeline, project_pipeline, error } = useAnalytics();
    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();
    const { loadingProjects, projects, getProjectList } = useProjects();

    const [options, setOption] = useState([]);
    const [businessId, setBusinessId] = useState(null);
    const [selected_business, setSelectedBusiness] = useState('');
    const [data, setData] = useState([]);
    const [monthly_data, setMonthlyData] = useState([]);
    const [app_names, setAppNames] = useState([]);
    const [data_pipeline, setDataPipeline] = useState([]);
    const [pipeline_names, setPipelineName] = useState([]);
    const [data_project, setDataProject] = useState([]);
    const [project_names, setProjectNames] = useState([]);

    const [exporting, setExporting] = useState(false);

    const [year, setYear] = useState(currentYear);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reload_dateFrom, setReloadDateFrom] = useState(null);
    const [reload_dateTo, setReloadDateTo] = useState(null);


    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const fyyyy = firstDayOfMonth.getFullYear();
        const fmm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
        const fdd = String(firstDayOfMonth.getDate()).padStart(2, '0');

        const lyyyy = lastDayOfMonth.getFullYear();
        const lmm = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
        const ldd = String(lastDayOfMonth.getDate()).padStart(2, '0');

        const firstDayOfTheCurrentMonth = `${fyyyy}-${fmm}-${fdd}`;
        const lastDayOfTheCurrentMonth = `${lyyyy}-${lmm}-${ldd}`;

        setDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setDateTo(dayjs(lastDayOfTheCurrentMonth));

        setReloadDateFrom(dayjs(firstDayOfTheCurrentMonth));
        setReloadDateTo(dayjs(lastDayOfTheCurrentMonth));

    }, [])

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    const optionsDate = {
        year: "numeric",
        month: "long",
        day: "2-digit",
    }

    const dateFormatter = new Intl.DateTimeFormat("en-US", optionsDate);

    const formattedStartDate = dateFormatter.format(startDate);
    const formattedEndDate = dateFormatter.format(endDate);

    const startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
    const currYear = startDate.getFullYear();

    let formattedDateRange = "";

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        formattedDateRange = `${startMonth} 01-${endDate.getDate()}, ${currYear}`;

    } else {
        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }

    // const handleYearChange = (event) => {
    //     setYear(event.target.value);
    //     // setLoading(true)
    //     console.log('get selected year', year)
    // };

    useEffect(() => {
        setSelectedEnterprise(null);
        setSelectedEnterpriseID(null);
    }, [])

    useEffect(() => {
        if ((businessId) && dateFrom && dateTo) {
            const businessToUse = businessId;
            let date_from = dayjs(dateFrom).format("YYYY-MM-DD");
            let date_to = dayjs(dateTo).format("YYYY-MM-DD");

            getCountTransactionByYearAndId(businessToUse, date_from, date_to);
            countTransactionsByPipeline(businessToUse, date_from, date_to)

        }
    }, [businessId, dateFrom, dateTo]);

    useEffect(() => { getBusinessListNames(); }, [])

    useEffect(() => { getTopBusiness() }, [])

    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };

    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id, label: business?.business_name }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(business_names)])

    const getBusiness = () => value => {
        setBusinessId(value.value)
        setSelectedBusiness(value.label)
        setLoading(true);
        setSelectedEnterpriseID(value.value);
        setSelectedEnterprise(value.label);
    }

    // const year = currentYear;

    // useEffect(() => {

    //     getCountTransactionByYearAndId(year, businessId);
    //     countTransactionsByPipeline(year, businessId)

    // }, [year, businessId])

    useEffect(() => { getProjectList(businessId) }, [businessId])


    useEffect(() => {

        if (business_counts) {

            var result = Object.entries(business_counts);
            console.log("resultxx", result)
            setData(result);

            if (result?.length > 0) {

                result?.map((data) => {

                    let project_id = data[0];

                    projects?.map((project) => {

                        if (project?.id == project_id) {
                            data[0] = project?.application_name
                        }

                    })

                })

            }

        }

    }, [JSON.stringify(business_counts), JSON.stringify(projects)])


    // MONTHY AUTHENTICATION PER YEAR
    useEffect(() => {
        let data = []
        let app_names = []

        const ordered = Object.keys(per_month).sort().reduce(
            (obj, key) => {
                obj[parseInt(key, 10)] = per_month[key];
                return obj;
            }, {}
        );


        Object.keys(ordered).forEach(key => {

            let month = key

            if (month == '1') {
                month = "January";
            } else if (month == '2') {
                month = "February";
            } else if (month == '3') {
                month = "March";
            } else if (month == '4') {
                month = "April";
            } else if (month == '5') {
                month = "May";
            } else if (month == '6') {
                month = "June";
            } else if (month == '7') {
                month = "July";
            } else if (month == '8') {
                month = "August";
            } else if (month == '9') {
                month = "September";
            } else if (month == '10') {
                month = "October";
            } else if (month == '11') {
                month = "November";
            } else if (month == '12') {
                month = "December";
            }

            let month_obj = {}
            month_obj.month = month;

            if (!data.includes(month_obj)) {
                data.push(month_obj)
            }
        })

        Object.keys(ordered).forEach(key => {

            let month = key

            if (month == '1') {
                month = "January";
            } else if (month == '2') {
                month = "February";
            } else if (month == '3') {
                month = "March";
            } else if (month == '4') {
                month = "April";
            } else if (month == '5') {
                month = "May";
            } else if (month == '6') {
                month = "June";
            } else if (month == '7') {
                month = "July";
            } else if (month == '8') {
                month = "August";
            } else if (month == '9') {
                month = "September";
            } else if (month == '10') {
                month = "October";
            } else if (month == '11') {
                month = "November";
            } else if (month == '12') {
                month = "December";
            }

            ordered[key].forEach((item) => {
                data.forEach((month_data) => {
                    if (month_data.month == month) {
                        let application = projects?.find((proj) => proj.id == item?.project_id)
                        let name = application?.application_name

                        if (!app_names.includes(name)) {
                            app_names.push(name)
                        }

                        month_data[name] = item?.count;
                    }
                })
            })
        })


        setMonthlyData(data)
        setAppNames(app_names)

    }, [JSON.stringify(per_month), JSON.stringify(projects)])


    // PIPELINE PER PROJECT
    useEffect(() => {
        let pipelines = []
        let pipeline_names = []

        Object.keys(pipeline_data).forEach(key => {

            let project_id = key;

            projects?.map((project) => {

                if (project?.id == project_id) {

                    let pipeline_obj = {}

                    pipeline_obj.name = project?.application_name

                    let pipeline_array = pipeline_data[key];

                    pipeline_array.map((pipeline) => {

                        let pipeline_name = pipeline?.pipeline;
                        let pipeline_count = pipeline?.count;

                        pipeline_obj[pipeline_name] = pipeline_count;

                        if (!pipeline_names.includes(pipeline_name)) {
                            pipeline_names.push(pipeline_name)
                        }

                    })

                    pipelines.push(pipeline_obj);

                }

            })

            console.log("pipelines", pipelines)
            setDataPipeline(pipelines);
            setPipelineName(pipeline_names);

        })


    }, [JSON.stringify(pipeline_data), JSON.stringify(projects)])



    //  PROJECT PER PIPELINE
    useEffect(() => {
        let project_pipelines = []
        let pipeline_names = []

        Object.keys(project_pipeline).forEach(key => {

            let pipeline_obj = {}

            pipeline_obj.pipeline = key

            let pipeline_array = project_pipeline[key]

            pipeline_array.map((pipe) => {

                let project_id = pipe?.project

                projects?.map((project) => {


                    if (project?.id == project_id) {

                        let project_name = project?.application_name

                        pipeline_obj[project_name] = pipe.count


                        if (!pipeline_names.includes(project_name)) {
                            pipeline_names.push(project_name)
                        }

                    }

                })

            })

            project_pipelines.push(pipeline_obj);

        })

        console.log("projecthereeeee", project_pipelines)

        setDataProject(project_pipelines)
        setProjectNames(pipeline_names)


    }, [JSON.stringify(project_pipeline), JSON.stringify(projects)])

    // const generatePDF = async () => {
    //     try {
    //       const doc = new jsPDF();

    //       const title = `${selected_business} Analytics as of ${formattedDateRange}`;

    //       const pageWidth = doc.internal.pageSize.getWidth();
    //       // Add section title centered at the top of the page
    //       const titleX = pageWidth / 2; // Centered horizontally
    //       const titleY = 10;

    //       doc.setFont('bold'); // Set font style to bold
    //       doc.setFontSize(18);
    //       doc.text(title, titleX, titleY, { align: 'center' });

    //       const addSectionToPDF = async (elementId, title) => {
    //         const sectionElement = document.getElementById(elementId);

    //         if (!sectionElement) {
    //           console.error(`Element with id ${elementId} not found`);
    //           return Promise.resolve();
    //         }

    //         try {
    //           setExporting(true);
    //           // Add section content
    //           const canvas = await html2canvas(sectionElement);
    //           console.log(`HTML2Canvas conversion successful for ${title}`);

    //           const sectionWidth = pageWidth - 20; // Adjust the margins as needed
    //           const sectionHeight = (canvas.height / canvas.width) * sectionWidth;

    //           // Adjust the vertical position based on the title height
    //           const titleHeight = 50; // Adjust this value based on your title font size and style
    //           doc.addImage(
    //             canvas.toDataURL('image/jpeg', 1.0),
    //             'JPEG',
    //             10,
    //             titleHeight + 20, // Adjusted the vertical position to provide space for the title
    //             sectionWidth,
    //             sectionHeight
    //           );
    //         } catch (error) {
    //           console.error(`Error generating PDF for ${title}:`, error);
    //         }
    //       };

    //       // Add the title and first section to the same page
    //       await addSectionToPDF('cardGrid');

    //       const sections = [
    //         { elementId: 'cardGridTableOne', title: '' },
    //         { elementId: 'cardGridTableTwo', title: '' },
    //         { elementId: 'cardGridTableThree', title: '' },
    //       ];

    //       // Add the rest of the sections to new pages
    //       for (const section of sections) {
    //         doc.addPage();
    //         await addSectionToPDF(section.elementId, section.title);
    //       }

    //       // Save the PDF
    //       doc.save(`${selected_business} Analytics as of ${formattedDateRange}.pdf`);
    //     } catch (error) {
    //       console.error('Error generating PDF:', error);
    //     } finally {
    //       setExporting(false); // Set loading to false after PDF generation, regardless of success or failure
    //     }
    //   };
    async function generatePDF() {
        try {
            console.log('Generating PDF...');

            setExporting(true);
            // Create a new jsPDF instance
            const pdf = new jsPDF();

            // Add title to the PDF
            const title = `${selected_business} Analytics as of ${formattedDateRange}`;
            pdf.text(title, 15, 15);

            // Use html2canvas to capture the chart container
            const chartContainer = document.getElementById('chartContainer');
            const canvas = await html2canvas(chartContainer);

            // Get the dimensions of the chart container
            const chartRect = chartContainer.getBoundingClientRect();

            // Calculate the aspect ratio to maintain proportions
            const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Adjust margin as needed
            const imgHeight = (chartRect.height * imgWidth) / chartRect.width;

            // Add the canvas content to the PDF
            pdf.addImage(canvas, 'PNG', 10, 30, imgWidth, imgHeight);

            // Save the PDF with a filename
            pdf.save(`${selected_business} Analytics as of ${formattedDateRange}.pdf`);
            console.log('PDF generated successfully');
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setExporting(false);
        }
    }



    return (
        <Box sx={{ width: '95%' }}>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    <Box sx={{ margin: 'auto' }}>
                        <Grid container sx={{ p: 2, pt: 1 }}>
                            <Grid item xs={12} sm={8} sx={{ justifyContent: 'flex-start' }}>
                                <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                    <Box>
                                        <Stack direction="row" spacing={1} sx={{ width: '100%', margin: 'auto', }}>
                                            <Typography sx={{ margin: 'auto !important' }}>From&nbsp;&nbsp;</Typography>

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                    <DatePicker
                                                        value={dateFrom}
                                                        required
                                                        views={["year", "month", "day"]}
                                                        inputProps={{ style: { fontSize: 12, height: 38 } }}
                                                        sx={{
                                                            fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                            "& .MuiInputLabel-root": { fontSize: 12 },
                                                            "& .MuiOutlinedInput-root": {
                                                                height: 38,
                                                                borderRadius: 10,
                                                                fontSize: 12,
                                                                "& > fieldset": {
                                                                },
                                                            },
                                                        }}
                                                        onChange={(newValue) => { setDateFrom(newValue) }}
                                                        maxDate={dateTo}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>

                                            &nbsp; &nbsp;

                                            <Typography sx={{ margin: 'auto !important' }}>To&nbsp;&nbsp;</Typography>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]} sx={{ paddingTop: '0px' }}>
                                                    <DatePicker
                                                        value={dateTo}
                                                        required
                                                        views={["year", "month", "day"]}
                                                        inputProps={{ style: { fontSize: 12 } }}
                                                        sx={{
                                                            fontSize: 12, overflowY: "hidden", minWidth: "0px !important", width: 180, borderRadius: 10,
                                                            "& .MuiInputLabel-root": { fontSize: 12 },
                                                            "& .MuiOutlinedInput-root": {
                                                                height: 38,
                                                                fontSize: 12,
                                                                borderRadius: 10,
                                                                "& > fieldset": {
                                                                },
                                                            },
                                                        }}

                                                        onChange={(newValue) => { setDateTo(newValue) }}
                                                        minDate={dateFrom}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>



                                            {/* &nbsp; &nbsp; */}

                                            {/* <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton> */}
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            {console.log("selected_businessxxx", selected_business)}
                            <Grid item xs={12} sm={4} sx={{ justifyContent: 'flex-end', marginLeft: 'auto', alignSelf: 'center' }}>
                                {isFetchingBusinessNames ?
                                    <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                                :
                                    <ReactSelect
                                        className="basic-single"
                                        placeholder={selected_business ? selected_business : "Select Enterprise"}
                                        isRtl={false}
                                        isSearchable={true}
                                        options={options}
                                        onChange={getBusiness()}
                                        styles={{
                                            control: base => ({
                                                ...base,
                                                height: 38,
                                                borderRadius: 100,
                                                backgroundColor: 'transparent',
                                                // width: 250,
                                                fontSize: 12
                                            }),
                                            placeholder: base => ({
                                                ...base,
                                                fontSize: 12
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 99999,
                                                fontSize: 12
                                            }),
                                        }}
                                    />

                                }
                            </Grid>

                            {!loading && data.length !== 0 &&
                                <Grid item xs={12} sx={{ mt: -14, textAlign: 'end' }} >
                                    <Button
                                        variant="contained"
                                        onClick={generatePDF}
                                        sx={{
                                            width: 'auto', backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none", fontSize: 12, lineHeight: 1.50,
                                            ":hover": { background: "#0178f2" }
                                        }}
                                        startIcon={exporting && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {exporting ? 'Exporting' : 'Export to PDF'}
                                    </Button>
                                </Grid>
                            }
                        </Grid>

                        <Box id="chartContainer">
                            <Grid item xs={12} sx={{ mt: 2 }} >

                                {loading ?
                                    <DashboardSkeletonComponent />
                                    :
                                    <Masonry id="cardGrid" columns={{ xs: 1, sm: 5 }} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0 }}>
                                        {data?.map((item) => {
                                            return (
                                                <Item sx={{ '&.MuiPaper-root': { borderRadius: 2 } }}>
                                                    <DashboardFabComponent item={item} loadingProjects={loadingProjects} />
                                                </Item>
                                            )
                                        })}
                                    </Masonry>
                                }

                                {!loading && data.length == 0 &&
                                    <Box sx={{margin: 10, textAlign: "center", ml: "auto", mr: "auto"}}>
                                        {(selected_business) ?
                                            <Typography><b>{selected_business}</b> has no available transactions as of {formattedDateRange}.</Typography>
                                        :
                                            <Typography sx={{fontWeight: "bold"}}>
                                                No analytics data to display yet.
                                            </Typography>
                                        }
                                    </Box>
                                }
                            </Grid>


                            {monthly_data.length !== 0 &&
                                <Box sx={{ margin: 'auto', mb:2 }}>
                                    {loading ?
                                        <DashboardGraphSkeletonComponent />
                                        :
                                        <div id="cardGridTableOne">
                                            <DashboardGraphComponent data={monthly_data} names={app_names} />
                                        </div>
                                    }
                                </Box>
                            }

                            {data_pipeline.length !== 0 &&
                                <Box sx={{ margin: 'auto', mb:2 }}>
                                    {loading ?
                                        <DashboardGraphSkeletonComponent />
                                        :
                                        <div id="cardGridTableTwo">
                                            <PipelinePerProjectComponent data={data_pipeline} names={pipeline_names} />
                                        </div>
                                    }
                                </Box>
                            }

                            {data_project.length !== 0 &&
                                <Box sx={{ margin: 'auto', mb:2 }}>
                                    {loading ?
                                        <DashboardGraphSkeletonComponent />
                                        :
                                        <div id="cardGridTableThree">
                                            <ProjectPerPipelineComponent data={data_project} names={project_names} />
                                        </div>
                                    }
                                </Box>
                            }

                        </Box>
                    </Box>
                </>
            }

        </Box >
    )

}

export default DashboardComponent