import React, {useState, useEffect} from "react";
import {Skeleton, Box, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import {format} from 'date-fns';
import useBusiness from "../../hooks/useBusiness";
import ActionsPopperComponent from "../users/actions-popper.component";
import EditUserEmailDialog from "../users/dialogs/edit-user-email.dialog";
import ViewUserDialog from "../users/dialogs/view-user.dialog";

const AdminUserRowComponent = (props) => {

    const {breakpoints, index, user} = props;
  
    const [openViewUser, setViewUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const [enterpriseName, setEnterpriseName] = useState(null);
    const [openEditEmail, setOpenEditEmail] = useState(false);


    const handleViewUser = (e, id) => {
        setViewUser(true);
        setUserId(id);
    }
 

    return (
        <>
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>  
                <TableCell component="th" scope="row" sx={{color: "#0178f2", cursor: "pointer"}}
                    onClick={(e) =>handleViewUser(e, user?.user_id)}
                >
                    {user?.user_id}
                </TableCell>

                <TableCell align="left" sx={{maxWidth: 200}}>
                    <Box sx={{display: "flex", alignItems: "center", fontSize: 16}}>
                        {(user?.email_address?.length > 25) ? 
                            <Tooltip arrow title={user?.email_address} placement="top">
                                <Typography sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: 300}}>
                                    {user?.email_address}
                                </Typography> 
                            </Tooltip>
                        : 
                            user?.email_address
                        }
                    </Box>
                </TableCell>

                <TableCell component="th" scope="row" sx={{color: "#0178f2", cursor: "pointer"}} onClick={()=> window.location.href='/user-list/role-permission?role='+ user?.user_role} >
                    {user?.user_role}
                </TableCell>

                <TableCell align="left" sx={{color: (user?.status == 1) ? "green" : "red"}}>
                    {user?.status == 1 ? "ACTIVE" : "INACTIVE"}
                </TableCell>

                <TableCell align="left"> 
                    {format(new Date(user?.updated_date), "PP")}
                </TableCell>

                <TableCell align="left">
                    <ActionsPopperComponent breakpoints={breakpoints}
                        userId={user?.id} status={user?.status}
                        setOpenEditEmail={setOpenEditEmail}
                        userEmail={user?.email_address}
                        userRole={user?.user_role}
                        
                    />
                </TableCell>
            </TableRow>

            {openEditEmail &&
                <EditUserEmailDialog breakpoints={breakpoints} 
                    openEditEmail={openEditEmail} setOpenEditEmail={setOpenEditEmail}
                    userId={user?.id} userEmail={user?.email_address} getAdminUsers={props.getAdminUsers}
                />
            }

            {openViewUser &&
                <ViewUserDialog breakpoints={breakpoints} userId={userId}
                    openViewUser={openViewUser} setViewUser={setViewUser}
                    enterpriseName={enterpriseName}
                />
            }
        </>
    )
}

export default AdminUserRowComponent

