import { useState, useEffect } from "react";
import { Button, Grid, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, TextField, InputAdornment, Box } from "@mui/material";
import { format } from "date-fns";
import useWidth from "../../hooks/useWidth";
import useAppConfig from "../../hooks/useAppConfig";
import BusinessAppListSkeletonComponent from "../skeletons/business-app-list-skeleton.component";
import CreateBusinessAppDialog from "./dialog/create-business-app-dialog.component";
import BusinessAppActionsPopperComponent from "./business-app-action-popper.component";
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import useBusiness from "../../hooks/useBusiness";
import useProjects from "../../hooks/useProjects";
import ForbiddenScreen from "../../screens/ForbiddenScreen";

function TableHeader() {

    const tablecell = [
        { label: 'Business Name' },
        { label: 'Project Name' },
        { label: 'Project Description' },
        { label: 'App Name' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ]

    return (
        <TableHead >
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, color: 'white', backgroundColor: '#0178f2' }}>{value.label}</TableCell>

                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TableContent(props) {
    const { list } = props;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }} >
            <TableCell component="th" scope="row" sx={{ color: '#0178f2' }} >
                {list?.businessAccount?.business_name}
            </TableCell>
            <TableCell component="th" scope="row" >
                {list?.accountProject?.application_name}
            </TableCell>
            <TableCell component="th" scope="row" >
                {list?.accountProject?.description}
            </TableCell>
            <TableCell component="th" scope="row" >
                {list?.appName}
            </TableCell>
            <TableCell component="th" scope="row">
                {format(new Date(list?.businessAccount?.updated_date), 'PPpp')}
            </TableCell>
            <TableCell component="th" scope="row">
                <BusinessAppActionsPopperComponent list={list} />
            </TableCell>
        </TableRow>
    )
}

function BusinessAppListComponent(props) {


    const breakpoints = useWidth();

    const { loading, businessAppList, businessList, pagination, availableBusinessApp, availableBusiness, error,
        searchBusinessApp, isSearchingAppMiddleware, searchedBusinessApp, isSearchFilterToggle, isSearchedResultsEmpty
    } = useAppConfig();
    const { business_names, getBusinessListNames } = useBusiness();
    const { getProjectList, projects } = useProjects();

    const [page, setPage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false)

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState(null);
    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessFilterValue, setBusinessFilterValue] = useState(null);
    const [projectOptions, setProjectOptions] = useState([]);
    const [projectFilterValue, setProjectFilterValue] = useState(null);
    const [descriptionFilterValue, setDescriptionFilterValue] = useState("");
    const [appNameOptions, setAppNameOptions] = useState([]);
    const [appNameFilterValue, setAppNameFilterValue] = useState(null);
    const [businessId, setBusinessId] = useState(businessOptions[0]?.value);

    const handleChangePagination = (event, page) => {
        setPage(page);
        businessAppList(page);
    }

    useEffect(() => {
        businessAppList(page)
    }, [])

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    console.log("businessListxxx", businessList)

    useEffect(() => {
        if (filter && businessFilterValue) {
            searchBusinessApp(filter, businessFilterValue, page);

        } else if (filter && projectFilterValue) {
            searchBusinessApp(filter, projectFilterValue, page);

        } else if (filter && descriptionFilterValue) {
            searchBusinessApp(filter, descriptionFilterValue, page);

        } else if (filter && appNameFilterValue) {
            searchBusinessApp(filter, appNameFilterValue, page);
        }
    }, [businessFilterValue, projectFilterValue, descriptionFilterValue, appNameFilterValue])

    useEffect(() => {
        getBusinessListNames();
        availableBusinessApp();
    }, [])

    useEffect(() => {
        let business_options = [];

        business_names?.map((business) => {
            let business_options_data = {
                value: business?.id,
                label: business?.business_name
            }

            business_options.push(business_options_data);
        })

        setBusinessOptions(business_options);
    }, [JSON.stringify(business_names)])

    useEffect(() => {
        getProjectList(businessId);
    }, [businessId])


    useEffect(() => {
        let project_options = [];

        projects?.map((project) => {
            let project_options_data = {
                value: project?.id,
                label: project?.application_name
            }

            project_options.push(project_options_data);
        })

        setProjectOptions(project_options);
    }, [JSON.stringify(projects)])

    useEffect(() => {
        let app_name_options = [];

        availableBusiness?.map((business) => {
            let app_name_options_data = {
                value: business,
                label: business
            }

            app_name_options.push(app_name_options_data);
        })

        setAppNameOptions(app_name_options);
    }, [JSON.stringify(availableBusiness)])

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            minHeight: "20px",
            width: 250,
            fontSize: 13,
        })
    }

    const filterOptions = [
        { value: "business_name", label: "Business" },
        { value: "project_name", label: "Project" },
        { value: "project_description", label: "Description" },
        { value: "app_name", label: "App Name" }
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setBusinessFilterValue(null);
        setProjectFilterValue(null);
        setDescriptionFilterValue("");
        setAppNameFilterValue(null);


        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {
            setFilter(null);
            setFilterLabel(null);

            handleClearFilter();
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        businessAppList();
    }

    const handleSelectBusinessValue = (selectedOption) => {
        if (selectedOption) {
            setBusinessFilterValue(selectedOption.label);
            setProjectFilterValue(null);
            setDescriptionFilterValue("");
            setAppNameFilterValue(null);
            setBusinessId(selectedOption.value);
        }
    }

    const handleSelectProjectValue = (selectedOption) => {
        if (selectedOption) {
            setProjectFilterValue(selectedOption.label);
            setDescriptionFilterValue("");
            setAppNameFilterValue(null);
            setBusinessFilterValue(null);
        }
    }

    const handleSelectAppNameValue = (selectedOption) => {
        if (selectedOption) {
            setAppNameFilterValue(selectedOption.label);
            setDescriptionFilterValue("");
            setBusinessFilterValue(null);
            setProjectFilterValue(null);
        }
    }

    const handleTextfield = (e) => {
        setDescriptionFilterValue(e.target.value);
        setAppNameFilterValue(null);
        setBusinessFilterValue(null);
        setProjectFilterValue(null);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (filter && descriptionFilterValue) {
                searchBusinessApp(filter, descriptionFilterValue, page);
            }
        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && businessFilterValue) {
            searchBusinessApp(filter, businessFilterValue, page);

        } else if (filter && projectFilterValue) {
            searchBusinessApp(filter, projectFilterValue, page);

        } else if (filter && descriptionFilterValue) {
            searchBusinessApp(filter, descriptionFilterValue, page);

        } else if (filter && appNameFilterValue) {
            searchBusinessApp(filter, appNameFilterValue, page);
        }
    }


    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            <Grid container>
                <Grid item xs={4} sm={4}>
                    <Typography sx={{ display: "flex", fontWeight: "400", fontSize: "0.875rem" }}>
                        Manage Enterprise Access to eID and eSign Applications
                    </Typography>
                </Grid>
            </Grid>

            {error ?
                <ForbiddenScreen />
                :
                <Grid container sx={{ mt: "20px" }}>
                    <Grid item xs={8} sm={8}>
                        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                            <ReactSelect
                                className="basic-single"
                                placeholder={<>
                                    <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                        Filter
                                    </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                        {
                                            (filterLabel === "Business") ?
                                                <Typography sx={{ color: "#000" ,  fontSize: 12}}>
                                                    Business
                                                </Typography>
                                                :
                                                (filterLabel === "Project") ?
                                                    <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                        Project
                                                    </Typography>
                                                    :
                                                    (filterLabel === "Description") ?
                                                        <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                            Description
                                                        </Typography>
                                                        :
                                                        (filterLabel === "App Name") ?
                                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                                App Name
                                                            </Typography>
                                                            :
                                                            (filterLabel === null) ?
                                                                "Select Filter"
                                                                :
                                                                null

                                        }</i>
                                </>}
                                isSearchable={true}
                                isRtl={false}
                                options={filterOptions}
                                onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                                styles={{
                                    control: base => ({
                                        ...base,
                                        height: 38,
                                        borderRadius: 100,
                                        backgroundColor: 'transparent',
                                        width: 250,
                                        fontSize: 12
                                    }),
                                    placeholder: base => ({
                                        ...base,
                                        fontSize: 12
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                        fontSize: 12
                                    }),
                                }}
                                isClearable
                            />

                            {(filterLabel === null || filterLabel === "Business") &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "Business" && businessFilterValue) ?
                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                {businessFilterValue}
                                            </Typography>
                                            :
                                            "Search Business"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={businessOptions}
                                    value={businessFilterValue}
                                    onChange={selectedOption => handleSelectBusinessValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }

                            {filterLabel === "Project" &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "Project" && projectFilterValue) ?
                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                {projectFilterValue}
                                            </Typography>
                                            :
                                            "Search Project"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={projectOptions}
                                    value={projectFilterValue}
                                    onChange={selectedOption => handleSelectProjectValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }

                            {filterLabel === "Description" &&
                                <TextField
                                    value={descriptionFilterValue}
                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 380 } }}
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={handleTextfield}
                                    onKeyDown={handleKeyPress}
                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                    placeholder={"Input Description..."}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{'Description'}:</p></InputAdornment>,
                                    }}
                                />
                            }

                            {filterLabel === "App Name" &&
                                <ReactSelect
                                    className="basic-single"
                                    placeholder={
                                        (filterLabel === "App Name" && appNameFilterValue) ?
                                            <Typography sx={{ color: "#000",  fontSize: 12 }}>
                                                {appNameFilterValue}
                                            </Typography>
                                            :
                                            "Search App Name"
                                    }
                                    isSearchable={true}
                                    isRtl={false}
                                    options={appNameOptions}
                                    value={appNameFilterValue}
                                    onChange={selectedOption => handleSelectAppNameValue(selectedOption)}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            height: 38,
                                            borderRadius: 100,
                                            backgroundColor: 'transparent',
                                            width: 250,
                                            fontSize: 12
                                        }),
                                        placeholder: base => ({
                                            ...base,
                                            fontSize: 12
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 99999,
                                            fontSize: 12
                                        }),
                                    }}
                                />
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={4} sm={4} sx={{ textAlign: "end", mb: 2, marginLeft: -2 }}>
                        <Button variant="contained" onClick={handleOpenDialog} sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }} >Add Business App</Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper} sx={{ border: "1px solid #8080807a", borderRadius: 2, width: "100%" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(loading || isSearchingAppMiddleware) ?
                                    <TableBody>
                                        <BusinessAppListSkeletonComponent />
                                    </TableBody>

                                    :
                                    (isSearchFilterToggle && !isSearchedResultsEmpty) ?
                                        <TableBody sx={{ width: "100%" }}>
                                            {searchedBusinessApp?.data.map((data, index) => (
                                                <TableContent list={data} />
                                            ))}
                                        </TableBody>
                                        :
                                        (isSearchFilterToggle && isSearchedResultsEmpty) ?
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" sx={{ fontSize: 14, textAlign: "center", fontWeight: 500 }}>
                                                        No searched result(s) found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            (!isSearchFilterToggle) &&
                                            <TableBody sx={{ width: "100%" }}>
                                                {(businessList && businessList)?.map((list, index) => (
                                                    <TableContent list={list} />
                                                ))}
                                            </TableBody>
                                }
                            </Table>

                            {!isSearchFilterToggle && !loading && businessList && businessList?.length > 0 &&
                                <Pagination
                                count={pagination}
                                page={page}
                                onChange={handleChangePagination}
                                hideNextButton={businessList.length < 10}
                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                            />
                            }

                            {/* pagination for search business application */}
                            {(isSearchFilterToggle && !isSearchedResultsEmpty && !loading) &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePaginationForSearch}
                                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                />
                            }
                        </TableContainer>
                    </Grid>
                </Grid>
            }


            {openDialog &&
                <CreateBusinessAppDialog openDialog={openDialog} handleClose={handleClose} breakpoints={breakpoints} />
            }

        </Box>
    )
}

export default BusinessAppListComponent;