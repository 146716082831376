import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useUsers from "../../../hooks/useUsers";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from 'react-loader-spinner';
import { useEffect } from "react";

const ActivateDeactivateUser = (props) => {

    const breakpoints = useWidth();

    const {isUpdatingUser, updated, error, updateUserStatus } = useUsers();

    const {openDialog, handleClose, status, user, userID, setInactive, setActive} = props;

    console.log("user_id", userID)

    const handleDeactivateUser = (e) => {
        e.preventDefault();

        
        let payload = {
            data: {
                accountEnabled: false
            }
        }
    
        updateUserStatus(userID,payload);

        setActive(false);

    };

    const handleActivateUser = (e) => {
        e.preventDefault();
    
        let payload = {
            data: {
                accountEnabled: true
            }
        }
    
        updateUserStatus(userID,payload);

      setInactive(true);

    };

    useEffect(()=>{
        if(updated){
            handleClose();
            window.location.reload();
        }
    },[updated])

    useEffect(()=>{
        if(error){
            handleClose();
        }
    },[error])



    return (
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                {status == 1 ? 'Deactivate User' : 'Activate User'}
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
               <Typography sx={{fontSize: 18}}>
                 Are you sure you want to  <span style={{color: status == 1 ? 'red' : '#0178f2'}}>{status == 1 ? 'deactivate' : 'activate' }</span> <strong style={{fontSize: 16}}>{user}</strong>?
               </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: 'black', textTransform: 'none', fontSize: 18}} onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 2, textTransform: 'none', fontSize: 18, '&:hover': {backgroundColor:'#0178f2', color:'white'}}} 
                    onClick={status == 1 ? handleDeactivateUser : handleActivateUser } 
                    startIcon={ isUpdatingUser ? <ThreeDots  height="20"  width="20"   radius="9" color="#ffff"  ariaLabel="three-dots-loading"  visible={true} /> : null}
                >
                   { isUpdatingUser ? 'Updating' : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActivateDeactivateUser