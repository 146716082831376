import {Box, Button, ButtonGroup, Card, Grid, Skeleton, Typography} from "@mui/material";

const view_labels = 
    {
        company_name : 'Company Name', name: 'NextIx Inc.',
        company_description : 'Description', description: 'NEXTIX is aligned around social innovation. It find practical solutions to social problems..',
        website : 'Website', website_link: 'https://nextix.co',
        contact : 'Contact Number', contact_number: '09987765543',
        business_status : 'Status',  status: 'Active',
        business_credit : 'Credits', credit: '0 Credits'
    }

const ViewBusinessAccountSkeletonComponent = () => {

    return (
        <Card sx={{padding: 4, borderRadius: 2}}>
            <Grid container spacing={2} sx={{textAlign: 'start'}}>
                <Grid item xs={12} sm={12} sx={{textAlign: 'start', paddingLeft: 0}}>
                    <Button>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 80, width: 150, borderRadius: "4px/6.7px"}} />
                    </Button>
                </Grid>
                <br/>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} sx={{textAlign: "start"}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.company_name}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: "start"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{textAlign: "start"}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.company_description}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: "start"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{textAlign: 'start'}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.website}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: 'start'}}>
                        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{textAlign: "start"}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.contact}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: "start"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{textAlign: "start"}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.business_status}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: "start"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{textAlign: "start"}}>
                            <Typography variant="subtitle2" sx={{mb: 2}}>
                                {view_labels.business_credit}:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} sx={{textAlign: "start"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{alignSelf: "center"}}>
                            <Typography variant="subtitle2">
                                Plan:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={7} sx={{alignSelf: "center"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={2} sx={{alignSelf: "center"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: 70}} />
                        </Grid>
                    
                        <Grid item xs={12}  sm={3} sx={{alignSelf: "center"}}>
                            <Typography variant="subtitle2">
                                Subscription Key:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={7} sx={{alignSelf: "center"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                        </Grid>

                        <Grid item xs={12} sm={2} sx={{alignSelf: "center"}}>
                            <ButtonGroup variant="text" aria-label="text button group">
                                <Button sx={{textTransform: "none", color: "#0178f2"}}>
                                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: 50}} />
                                </Button>

                                <Button sx={{textTransform: "none", color: "#0178f2"}}>
                                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)", width: 70}} />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Card>
    )
}

export default ViewBusinessAccountSkeletonComponent