import { Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import ActivityLogsSkeletonComponent from "./activity-logs-skeleton.component";


function TableHeader() {
    const tablecell = [
        { label: "Email" },
        { label: "Project" },
        { label: "Module" },
        { label: "Actions" },
        { label: "Activity" },
        { label: "Timestamp" },
    ];

    return (
        <TableHead sx={{ borderRadius: 2 }}>
            <TableRow sx={{ position: "sticky", bgcolor: "none", borderRadius: 2 }}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: "#0178f2", color: "#0178f2", bgcolor: "none" }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const AdminActivityContents = (props) => {

    const { list } = props;

    // console.log("list here:", list)

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" sx={{ fontSize: 12 }}>
                {list?.email}
            </TableCell>

            <TableCell align="left" sx={{ fontSize: 12 }}>
                {list?.project_name ? list?.project_name : 'N/A' }
            </TableCell>

            <TableCell align="left" sx={{ fontSize: 12 }}>
                {list?.module}
            </TableCell>

            <TableCell align="left" sx={{ fontSize: 12 }}>
                {list?.action}
            </TableCell>

            <TableCell align="left" >
                <Typography sx={{ fontSize: 12, wordBreak: "break-word"}}>
                    {list.activity}
                </Typography>
            </TableCell>

            <TableCell align="left" sx={{ fontSize: 12 }}>
                {format(new Date(list.timestamp), "PPpp")}
            </TableCell>
        </TableRow>
    )

}

const AdminActivityLogsComponent = (props) => {

    const { auditList, isFetchingActivityLogs } = props;

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = auditList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (

        <TableContainer component={Paper} sx={{ border: "none", borderRadius: 2, width: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHeader />

                {isFetchingActivityLogs ?
                    <TableBody>
                        <ActivityLogsSkeletonComponent />
                    </TableBody>

                :

                    <TableBody>
                        {(currentItems.length > 0) ?
                            <>
                                {(currentItems && currentItems)?.map((list, index) => (
                                    <AdminActivityContents list={list} />
                                ))}
                            </>
                        :
                            <>
                                {!isFetchingActivityLogs && (
                                    <TableRow key={0}>
                                        <TableCell colSpan={6} align="center">
                                            <Typography sx={{ textAlign: "center", mt: 1.5, mb: 1.5 }}>No activity logs available.</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        }

                    </TableBody>
                }
            </Table>

            {!isFetchingActivityLogs && auditList.length > itemsPerPage && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: "auto", width: "fit-content", paddingTop: 3, paddingBottom: 3 }}>
                    <Pagination count={Math.ceil(auditList.length / itemsPerPage)} page={currentPage} onChange={(event, value) => paginate(value)} />
                </Grid>
            )}

        </TableContainer>

    )
}

export default AdminActivityLogsComponent