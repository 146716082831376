import { Box, Grid, Typography } from "@mui/material"
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import { useEffect, useState } from "react";
import useWidth from "../hooks/useWidth";
import ProjectListScreen from "./ProjectListScreen";
import DeletedProjectListScreen from "./DeletedProjectListScreen";



function ProjectHeader(){

    return (
        <Box sx={{ padding: 1, width: '100%', borderRadius: 0 }}>
            <Grid container sx={{ textAlign: 'start', marginLeft: 1 }}>
                <Grid item xs={12} sm={8}>
                    <Typography variant='h6'>
                        Project Management
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


const useProjectTabs = (props) => {
    const [projectTabs, setProjectTabs] = useState([]);

    useEffect(() => {
        function setupTabs() {
            const tabsDefault = [
                { name: 'Projects', component:<ProjectListScreen /> },
                { name: 'Deleted Projects', component: <DeletedProjectListScreen /> }
            ];
            setProjectTabs(tabsDefault);
        }
        setupTabs();
    }, []); 

    return projectTabs;
}



function ProjectTabsComponent(props){

    const breakpoints = useWidth();

    const tabs = useProjectTabs(props);

    const [value, setValue] = useState(0);

    function TabPanel(props) {

        const { children, value, index, ...other } = props;


        return (
            <div
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    function handleChange(event, newValue) {
        setValue(newValue);
    }




    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' , mb: 1}} className="card-tab">
                    <CustomTabs
                        orientation="horizontal"
                        variant={(breakpoints === 'xs' || breakpoints === 'md') ? "scrollable" : ""}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="transparent"
                        sx={{ flex: 1, alignItems: 'flex-end' }} // Allow the tabs to take the available width
                    >
                        {tabs?.map((tab, index) => {
                            return (
                                <CustomTab
                                    key={tab.name}
                                    label={<Typography sx={{ textAlign: 'start', fontWeight: 500, fontSize: 15 }}>{tab.name}</Typography>}
                                    disableRipple
                                    {...a11yProps(index)}
                                    sx={{ alignItems: 'flex-end !important', m: 0 }}
                                />
                            );
                        })}
                    </CustomTabs>
                </Box>
          
            </Box>


            {tabs?.map((tab, index) => {
                return (
                    <TabPanel key={tab.name} value={value} index={index} style={{ padding: "0px", width: '100%' }}>
                        {tab.component}
                    </TabPanel>
                )
            })}

                   
        </Box>
    )
}


const ProjectManagementScreen = () => {

    return(
        <Box sx={{ overflow: 'hidden' }}>
            <Box sx={{ p: 1, width: '100%', borderRadius: 0 }}>
                <ProjectHeader />
            </Box>

            <Box sx={{ p: 1, pt: 0, width: '100%', borderRadius: 0 }}>
                <ProjectTabsComponent />
            </Box>
        </Box>
    )
}

export default ProjectManagementScreen