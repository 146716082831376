import React, {useState, useEffect} from "react";
import {Button, Box, Card, Grid, TextField, Typography} from "@mui/material";
import SignatureTextToImage from "../../account-profile/change-e-signature/signature-text-to-image.component";
import useWidth from "../../../hooks/useWidth";
import useB2CAccount from "../../../hooks/useB2cAccount";
import useUsers from "../../../hooks/useUsers";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";
import { convertUrlToFile } from "../../../utils/convertUrlToFileUtil";

const ChangeElementSignatureByTypeComponent = (props) => {

    const {handleClose, setIsSignatureUpdatedToParent, setNewSignatureSendToParent, setUserSignatureUpdated, currentSelectedUserId, currentESignatureB2CId, onSelectUser} = props;

    const {enqueueSnackbar} = useSnackbar();

    const breakpoints = useWidth();

    const {isUploadingSignature, updateSignature, uploadedSignature, setUploadingSignature} = useB2CAccount();
    const {isFetchingUser, getUserByID, userDetails} = useUsers()

    const [signatureText, setSignatureText] = useState(null);
    const [selectedType, setSelectedType] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [parentFontSize, setFontSize] = useState("");
    const [parentFontStyle, setFontStyle] = useState("");
    const [parentFontFamily, setFontFamily] = useState("");

    const [signatureTextBase64, setSignatureTextBase64] = useState(null);

    useEffect(() => {
        if (uploadedSignature) {
            console.log("uploadedSignature bb", uploadedSignature);
            setUploadingSignature(true)
            getUserByID(currentESignatureB2CId)
        }
    }, [uploadedSignature])

    useEffect(() => {
        if (userDetails) {
            setUploadingSignature(false)
            setUserSignatureUpdated({isUpdated: true, data: userDetails.signature})
            onSelectUser(userDetails)
            handleClose()
        }
    }, [userDetails])

    const handleTextFieldChange = (e) => {
        setSignatureText(e.target.value);

        if (e.target.value == "") {
            setSelectedType(false);
            setSelectedKey(null);
            setFontStyle("");
            setFontSize("");
            setFontFamily("");
        }
    }

    const handleSelectSignatureType = (e, key, fstyle, fsize, ffamily) => {
        setSelectedType(true);
        setSelectedKey(key);
        setFontSize(fsize);
        setFontStyle(fstyle);
        setFontFamily(ffamily);
    }

    const sendDataToParent = (child) => {
        setSignatureTextBase64(child);
    }

    const handleUploadSignature = (e) => {
        if(selectedType){
            convertUrlToFile(signatureTextBase64, 'signatureFile.png', 'image/png').then(function(file) { 
                // let formData = new FormData();
                
                // formData.append("signature", file);
                // updateSignature(formData, handleClose, setUserSignatureUpdated);
                updateSignature(file, currentSelectedUserId);
                // window.location.reload();
              })
        }else{
            enqueueSnackbar("Please Select Signature Type", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
        
    }


    return(
        <Box sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? "100%" : "85.5%" , margin: "auto"}}>
            <Grid container direction="column" sx={{mt: 1}}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        variant="outlined" 
                        autoComplete="off"  
                        placeholder="Type your signature here"
                        inputProps={{maxLength: 50}}
                        value={signatureText}
                        onChange={(e) => handleTextFieldChange(e)}
                    />
                </Grid>

               
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card key={1} sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 1) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                    onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 1, "italic bold", 250, "Antically") : null}
                                >
                                    <Typography variant="h6" 
                                        sx={{
                                            fontFamily: "Antically !important",
                                            fontStyle: "italic", 
                                            fontSize: 22
                                        }}
                                    >
                                        {(signatureText) ? signatureText : "Signature"}
                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={6}>
                                <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 2) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                    onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 2, "", 250, "Adinda Melia") : null}
                                >
                                    <Typography variant="h6" 
                                        sx={{
                                            fontFamily: "Adinda Melia !important",
                                            fontWeight: 500,
                                            fontSize: 22,
                                        }}
                                    >
                                        {(signatureText) ? signatureText : "Signature"}
                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={6}>
                                <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 3) ? "2px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                    onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 3, "italic", 250, "JustSignature") : null}
                                >
                                    <Typography variant="h6" 
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 20,
                                            fontStyle: "italic",
                                            fontFamily: "JustSignature !important",
                                        }}
                                    >
                                        {(signatureText) ? signatureText : "Signature"}
                                    </Typography>
                                </Card> 
                            </Grid>

                            <Grid item xs={6}>
                                <Card sx={{cursor: (signatureText) ? "pointer" : "default", border: (selectedType && selectedKey == 4) ? "1.5px solid #0178f2" : "1px solid #8080807a", borderRadius: 2, padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "8px" : "12px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", ":hover": {backgroundColor: (signatureText) ? "#0178f221" : null}}}
                                    onClick={(e) => (signatureText) ? handleSelectSignatureType(e, 4, "500", 250, "Autograf") : null}
                                >
                                    <Typography variant="h6"
                                        sx={{
                                            fontFamily: "Autograf !important",
                                            fontSize: 22,
                                            fontWeight: "500"
                                        }}
                                    >
                                        {(signatureText) ? signatureText : "Signature"}
                                    </Typography>
                                </Card>  
                            </Grid>
                        </Grid>
                    </Grid>
               

                {(selectedType && signatureText) &&
                    <Box sx={{mt: 3}}>
                        <SignatureTextToImage signatureText={signatureText} 
                            parentFontStyle={parentFontStyle} parentFontFamily={parentFontFamily}
                            parentFontSize={parentFontSize} sendDataToParent={sendDataToParent} 
                        />
                    </Box>
                }
                
                <Box sx={{paddingTop: 6, paddingBottom: 2, textAlign: "end"}}>
                    <Button onClick={handleClose} sx={{color: "#000", textTransform: "none", fontSize: 16}}>
                        Close
                    </Button>

                    <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 16, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                        onClick={(e) => handleUploadSignature(e)}
                        startIcon={ isUploadingSignature ? <ThreeDots  height="20"  width="20"   radius="9" color="#ffff"  ariaLabel="three-dots-loading"  visible={true} /> : null } 
                    >
                       {isUploadingSignature ? 'Saving' : 'Save'} 
                    </Button>
                </Box>
            </Grid>
        </Box>
    )
    
}

export default ChangeElementSignatureByTypeComponent