import { Avatar, Box, Card, CardContent, CardHeader, ClickAwayListener, Grid, IconButton, ListItem, ListItemIcon, MenuItem, MenuList, Paper, Popper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns';
import UpdateModuleDialog from "../rbac/dialogs/update-module.dialog";
import DeleteModuleDialog from "../rbac/dialogs/delete-module.dialog";
import PermissionSkeleton from "../skeletons/permisions-skeleton.component";




function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    const words = name.split('_'); // Split the name by underscores
    let initials = '';

    if (words.length > 0) {
        initials += words[0][0]; // First letter of the first word

        if (words.length > 1) {
            initials += words[1][0]; // First letter of the second word (after underscore)
        }
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}


function CardPermissionHeader(props) {

    const { data } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdateModule, setOpenUpdateModule] = useState(false);
    const [openDeleteModule, setOpenDeleteModule] = useState(false);

    const handleOpenUpdateModule = () => {
        setOpenUpdateModule(true);
    }

    const handleClosenUpdateModule = () => {
        setOpenUpdateModule(false);
    }

    const handleOpenDeleteModule = () => {
        setOpenDeleteModule(true);
    }

    const handleClosenDeleteModule = () => {
        setOpenDeleteModule(false);
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreHorizIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleOpenUpdateModule} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update Module</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeleteModule} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Delete Module</Typography>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

            {openUpdateModule &&
                <UpdateModuleDialog 
                    openUpdateModule={openUpdateModule}
                    handleClose={handleClosenUpdateModule}
                    data={data} 
                    getAdminModules={props.getAdminModules}
                />
            }

            {openDeleteModule &&
                <DeleteModuleDialog 
                    openDeleteModule={openDeleteModule}
                    handleClose={handleClosenDeleteModule}
                    data={data} 
                    getAdminModules={props.getAdminModules}
                />
            }

        </Stack>
    )
}

function CardPermissionContent(props) {

    const { data } = props;

    return (
        <Box sx={{ padding: '0px 16px', overflow: 'hidden' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 9, textAlign: 'end' }}>
                        Last Date Modified: {format(new Date(data?.updated_at), 'PPpp')}
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    )
}



const AdminPermissionsContentComponent = (props) => {

    const { setView, setPermissionId, data, setModuleData, retrieving } = props;


    return (


        <Box style={{ width: "100%" }}>
            {retrieving ?
                <PermissionSkeleton />
            :
                <Card sx={{ borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <CardHeader sx={{ paddingBottom: 0 }}
                        action={
                            <CardPermissionHeader data={data}  getAdminModules={props.getAdminModules} />
                        }
                    />
                    <CardHeader sx={{ paddingBottom: 0, textAlign: 'left' }}
                        avatar={
                            data?.module_name ? (
                                <Avatar {...stringAvatar(data?.module_name)} style={{width: 60, height: 60}} />
                            ) : null
                        }
                        title={
                            <Typography sx={{ alignSelf: 'center', cursor: 'pointer', fontWeight: 'bold', textAlign: 'start', paddingBottom: 0}}
                                onClick={() => [setView(true), setPermissionId(data?.id), setModuleData(data)]}
                            >
                                {data?.module_name}
                            </Typography>
                        }
                    />
                    <br />
                    <CardContent sx={{ '&.MuiCardContent-root': { padding: '16px 0px', pt: '0px', width: '100%' } }}>
                        <CardPermissionContent data={data} />
                    </CardContent>
                </Card>
            }
        </Box> 
    )
}

export default AdminPermissionsContentComponent