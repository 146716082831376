import {useState} from "react";
import {useSnackbar} from "notistack";
import OVCodeAutoRevokedService from "../services/api/ovcode-auto-revoked";

const useAutoRevoke = ()  => {

    const {enqueueSnackbar} = useSnackbar();

    const [revokeDuration, setRevokeDuration] = useState(0);
    const [isConfigAutoRevoke, setConfigAutoRevoke] = useState(false);
    const [isDeletingAutoRevoke, setDeletingAutoRevoke] = useState(false);
    const [isRecordNotFound, setRecordNotFound] = useState(false);
    const [isAutoRevokeConfigured, setAutoRevokeConfigured] = useState(false);
    const [configDuration, setConfigDuration] = useState(0);
    const [resetAutoRevokeConfigured, setResetAutoRevokeConfigured] = useState(false);


    const retrieveAutoRevoke = async (projectId) => {
        localStorage.removeItem("revokeDuration");
        localStorage.removeItem("autoRevokeRecord");

        try {       
            const res = await OVCodeAutoRevokedService.retrieveAutoRevoke(projectId);
            console.log("retrieveAutoRevoke data xxxx", res);

            if (res) {
                let revoke_duration_days = res.data.data.revoke_duration;
                setRevokeDuration(revoke_duration_days);

                localStorage.setItem("revokeDuration", revoke_duration_days);
                localStorage.setItem("autoRevokeRecord", true);
            }
            
        } catch (error) {
            console.log("retrieveAutoRevoke error xxxx", error);

            let has_error_message = error.response.data.error_message;
            let recordNotFound = "Error: Record not found."

            if (error.response.data.data == null) {
                if (recordNotFound) {
                    localStorage.setItem("revokeDuration", 0);
                    localStorage.setItem("autoRevokeRecord", false);

                    setRevokeDuration(0);
                    setRecordNotFound(true);

                } else {
                    localStorage.setItem("revokeDuration", 0);
                    localStorage.setItem("autoRevokeRecord", false);

                    setRevokeDuration(0);

                    enqueueSnackbar(has_error_message, { 
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }  
            } 
        } 
    }

    const configAutoRevoke = (projectId, revokeDuration) => {
        localStorage.removeItem("revokeDuration");
        localStorage.removeItem("autoRevokeRecord");

        setConfigAutoRevoke(true);

        OVCodeAutoRevokedService.configAutoRevoke(projectId, revokeDuration).then((res) => {
            console.log("configAutoRevoke data xxxx", res);

            if (res.data.success && res.data.data !== null) {
                setConfigDuration(res.data.data.revoke_duration);
                setAutoRevokeConfigured(true);
                setResetAutoRevokeConfigured(false);

                let revoke_duration = res.data.data.revoke_duration;

                localStorage.setItem("revokeDuration", revoke_duration);
                localStorage.setItem("autoRevokeRecord", true);

                setConfigDuration(res.data.data.revoke_duration);
                setRecordNotFound(false);
                setConfigAutoRevoke(false);

                enqueueSnackbar("Successfully set revoke duration", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            console.log("configAutoRevoke error xxxx", error);

            setConfigAutoRevoke(false);
            setAutoRevokeConfigured(false);

            let has_error_message = error.response.data.error_message;
            let unAuthorized = "Unauthorized."
            
            if (has_error_message == unAuthorized) {
                enqueueSnackbar("You are unauthorized to set revoke duration in this project", { 
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(has_error_message, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }    
        })
    }

    const deleteAutoRevoke = (projectId) => {
        localStorage.removeItem("revokeDuration");
        localStorage.removeItem("autoRevokeRecord");

        setDeletingAutoRevoke(true);
        setConfigDuration(0);

        OVCodeAutoRevokedService.deleteAutoRevoke(projectId).then((res) => {
            console.log("deleteAutoRevoke data xxxx", res);

            if (res.data.success) {
                localStorage.setItem("revokeDuration", 0);
                localStorage.setItem("autoRevokeRecord", false);

                setConfigDuration(0);
                setRecordNotFound(true);
                setResetAutoRevokeConfigured(true);

                enqueueSnackbar(res.data.data, { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }

            setDeletingAutoRevoke(false);

        }).catch((error) => {
            console.log("deleteAutoRevoke error xxxx", error);

            setDeletingAutoRevoke(false);
        })
    }

    return {
        revokeDuration, isConfigAutoRevoke, isDeletingAutoRevoke, isRecordNotFound,
        retrieveAutoRevoke, configAutoRevoke, deleteAutoRevoke, isAutoRevokeConfigured, configDuration, 
        resetAutoRevokeConfigured,
    }
}

export default useAutoRevoke

