import routes from "../common/api-routes";
import environment from "../../../environment/environment";
import {HttpDelete, HttpDeleteBodyRequest, HttpGet, HttpPost, HttpPostMultipart, HttpPut} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getCountAccountTemplates = (businessId, projectId) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_COUNT_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const getEnterpriseAccountTemplates = (businessId, page, limit) => {

    let query_params = {
        businessId: businessId,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const getDeletedEnterpriseAccountTemplates = (businessId, deleted, page, limit) => {

    let query_params = {
        businessId: businessId,
        deleted: deleted,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DELETED_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const getDeletedEnterpriseProjectAccountTemplates = (projectId, deleted, page, limit) => {

    let query_params = {
        projectId: projectId,
        deleted: deleted,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_DELETED_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const getEnterpriseProjectAccountTemplates  = (projectId, page, limit) => {

    let query_params = {
        projectId: projectId,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const filterEnterpriseTemplate = (searchFor, searchVal, businessId, page, limit) => {

    let query_params = {
        searchFor: searchFor,
        searchVal: searchVal,
        businessId: businessId,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.FILTER_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const filterEnterpriseDeletedTemplate = (searchFor, searchVal, businessId, deleted, page, limit) => {

    let query_params = {
        searchFor: searchFor,
        searchVal: searchVal,
        businessId: businessId,
        deleted: deleted,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.FILTER_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const filterEnterpriseProjectTemplate = (searchFor, searchVal, projectId, page, limit) => {

    let query_params = {
        searchFor: searchFor,
        searchVal: searchVal,
        projectId: projectId,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.FILTER_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const filterEnterpriseProjectDeletedTemplate = (searchFor, searchVal, projectId, deleted, page, limit) => {

    let query_params = {
        searchFor: searchFor,
        searchVal: searchVal,
        projectId: projectId,
        deleted: deleted,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.FILTER_ENTERPISE_ACCOUNT_TEMPLATES, query_params, subscriptionKey);
    
    return result;
}

const updateTemplateName = (formData) => {

    let payload = formData;
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("update template payload xxxx", payload, SUBSCRIPTION_KEY);

    let result = HttpPut(routes.UPDATE_TEMPLATE_NAME, payload, subscriptionKey);
    return result;
}

const deleteAccountTemplate = (payload) => {
    console.log("deleteAccountTemplate payload xxxx", payload);

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut(routes.DELETE_TEMPLATE, payload, subscriptionKey);
    
    return result;
}

const updateTemplateStatus = (formData) => {

    let payload = formData;
    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("update template status payload xxxx", payload, SUBSCRIPTION_KEY);

    let result = HttpPut(routes.UPDATE_TEMPLATE_STATUS, payload, subscriptionKey);
    return result;
}


const deleteTemplatePermanently = (template_code) => {

    let parameters = {
        template_code : template_code
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_TEMPLATE_PERMANENTLY, parameters, subscriptionKey);
    
    return result;
}

const restoreDeletedTemplate = (formData) => {

    let payload = formData;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut(routes.RESTORE_DELETED_TEMPLATE, payload, subscriptionKey);
    
    return result;
}

const createAccountTemplate = (formData) => {

    let payload = formData

    let result = HttpPostMultipart(routes.CREATE_ACCOUNT_TEMPLATE, payload);
    
    return result;
}

const viewAccountTemplate = (template_code) => {

    let query_params = {
        template_code: template_code
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_ACCOUNT_TEMPLATE, query_params, subscriptionKey);
    
    return result;
}

const getTemplateProperties = (template_code) => {
    var query_params = {
        template_code: template_code
    }
    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_TEMPLATE_PROPERTIES, query_params, subscriptionKey);
    return result;
}

const createUpdateTemplateProperties = (new_properties, deleted_property_ids, template_code) => {
    var payload = {
        new_properties: new_properties,
        deleted_property_ids: deleted_property_ids,
        template_code: template_code
    }
    let result = HttpPost(routes.CREATE_UPDATE_TEMPLATE_PROPERTIES, payload);
    return result;
}

const createSignature = (editedSignatureFile, email, project_id) => {

    let formData = new FormData();

    formData.append("signature", editedSignatureFile);
    formData.append("email", email);
    formData.append("project_id", project_id);

    let result = HttpPost(routes.CREATE_SIGNATURE, formData);

    return result;
}

const getSignatureList = (projectId) => {

    let parameters = {
        projectId
    };
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.GET_SIGNATURE_LIST, parameters, subscriptionKey);

    return result;
}

const updateTemplateSignature = (formData) => {

    let payload = formData;

    let result = HttpPut(routes.UPDATE_TEMPLATE_SIGNATURE, payload);

    return result;
}

const viewTemplateSignature = (id) => {

    let query_params = {
        id: id
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.VIEW_TEMPLATE_SIGNATURE, query_params, subscriptionKey);
    
    return result;
}

const deleteTemplateSignature = (id) => {

    let query_params = {
        id: id
    }
    
    let result = HttpDelete(routes.DELETE_TEMPLATE_SIGNATURE, query_params);
    
    return result;
}

const getSignatureLimit = (projectId) => {

    let query_params = {
        projectId
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TEMPLATE_SIGNATURE_LIMIT, query_params, subscriptionKey);
    
    return result;
}

const configureSignatureLimit = (project_id, limit) => {
    var payload = {
        project_id,
        limit
    }
    let result = HttpPost(routes.CONFIGURE_SIGNATURE_LIMIT, payload);
    return result;
}

const getTemplateSignatureList = (templateId) => {

    let query_params = {
        templateId
    }
    
    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.GET_TEMPLATE_SIGNATURE_LIST, query_params, subscriptionKey);
    
    return result;
}

const assignTemplateSignature = (template_id, signature_id) => {
    var payload = {
        template_id,
        signature_id
    }
    let result = HttpPost(routes.ASSIGN_TEMPLATE_SIGNATURE, payload);
    return result;
}

const removeTemplateSignature = (templateId, signatureId) => {

    let payload = {
        template_id: templateId,
        signature_id: signatureId
    }
    
    let result = HttpDeleteBodyRequest(routes.REMOVE_TEMPLATE_SIGNATURE, null, payload);
    
    return result;
}



const TemplateService = {
    getCountAccountTemplates,
    getDeletedEnterpriseAccountTemplates,
    getEnterpriseAccountTemplates,
    getEnterpriseProjectAccountTemplates,
    filterEnterpriseTemplate,
    filterEnterpriseProjectTemplate,
    updateTemplateName,
    deleteAccountTemplate,
    updateTemplateStatus,
    deleteTemplatePermanently,
    restoreDeletedTemplate,
    getDeletedEnterpriseProjectAccountTemplates,
    filterEnterpriseDeletedTemplate,
    filterEnterpriseProjectDeletedTemplate,
    createAccountTemplate,
    viewAccountTemplate,
    getTemplateProperties,
    createUpdateTemplateProperties,
    createSignature,
    getSignatureList,
    updateTemplateSignature,
    viewTemplateSignature,
    deleteTemplateSignature,
    updateTemplateStatus,
    getSignatureLimit,
    configureSignatureLimit,
    getTemplateSignatureList,
    assignTemplateSignature,
    removeTemplateSignature,
}

export default TemplateService