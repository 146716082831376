import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, Stack, Select, MenuItem, FormControl, Pagination, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { timeAgo } from "../../utils/dateFormatterUtil";
import useAnalytics from "../../hooks/useAnalytics";
import useBusiness from "../../hooks/useBusiness";
import TransactionSkeletonComponent from "../skeletons/transaction-skeleton.component";
import useProjects from "../../hooks/useProjects";
import { format } from 'date-fns';
import AuthenticationComponent from "./authentication.component";
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import TransactionPopper from "./transaction-popper.component";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import ForbiddenScreen from "../../screens/ForbiddenScreen";
import {useAnalyticsEnterpriseStore} from "../../zustand/useAnalyticsEnterpriseStore";

const searchForOptions = [
    { value: "status", label: "Status" },
    { value: "project", label: "Project" },
]

const statusOption = [
    { value: "ONGOING", label: "ONGOING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "ERROR", label: "ERROR" },
]

function TableHeader() {

    const tablecell = [
        { label: 'Reference' },
        { label: 'Pipeline' },
        { label: 'Project' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ];

    return (
        <TableHead >
            <TableRow >
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TransactionContent(props) {

    const { index, transaction, setView, setReferenceId, setProjectId, setProjectName, businessId } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleted, setDeleted] = useState(false);

    let projectId = transaction?.project_id;
    let reference = transaction?.reference;

    const viewDetails = () => {
        setView(true)
        setReferenceId(transaction?.reference)
        setProjectId(transaction?.project_id)
        setProjectName(transaction?.project_name)
    }

    const handleCopyToClipboard = (e, name, key) => {
        navigator.clipboard.writeText(key);

        if (name == "apiKey") {
            enqueueSnackbar("Successfully copied API Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar("Successfully copied Subscription Key to clipboard", {
                variant: "success",
                autoHideDuration: 3000
            });
        }
    }

    return (
        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ color: "#0178f2", fontWeight: 500, cursor: "pointer", display: "flex", alignItems: "center" }} component="th" scope="row">
                        <   Box onClick={(e) => viewDetails(transaction)}>
                            {transaction?.reference}
                        </Box>
                        <IconButton sx={{ textTransform: "none", fontSize: 10 }}
                            onClick={(e) => handleCopyToClipboard(e, "reference", transaction?.reference)}
                        >
                            <ContentCopyIcon sx={{ width: 18, height: 18, color: "#0178f2" }} />
                        </IconButton>
                    </TableCell>

                    <TableCell align="left">
                        {transaction?.pipeline}
                    </TableCell>

                    <TableCell align="left" sx={{ fontWeight: 600 }}>
                        {transaction?.project_name ? transaction?.project_name : 'N/A'}
                    </TableCell>

                    <TableCell align="left" sx={{ fontWeight: 600, color: transaction?.state == "COMPLETED" ? 'green' : transaction?.state == "ONGOING" ? '#0178f2' : transaction?.state == "PENDING" ? 'orange' : 'red' }} >
                        {transaction?.state}
                    </TableCell>

                    <TableCell align="left">
                        {format(new Date(transaction?.updated_time), 'PPpp')}
                    </TableCell>

                    <TableCell align="left">
                        <TransactionPopper
                            viewDetails={viewDetails}
                            projectId={projectId}
                            reference={reference}
                            transaction={transaction}
                            setDeleted={setDeleted}
                            businessId={businessId}
                        />
                    </TableCell>

                </TableRow>
            }
        </>
    )
}

function TransactionComponent() {

    const {selectedEnterprise, selectedEnterpriseID} = useAnalyticsEnterpriseStore();

    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();
    const { loading, pagination, error, setLoading, transactions, getTransactionsByBusinessId, getTransactionsByReference, isSearchReference, getTransactionsUnderProject, getTransactionsByStatus } = useAnalytics();
    const { loadingProjects, projects, getProjectList, getAllProjectList, allProjects } = useProjects();


    const [page, setPage] = useState(1);
    const [options, setOption] = useState([]);
    const [businessId, setBusinessId] = useState(selectedEnterpriseID);
    const [selected_business, setSelectedBusiness] = useState(selectedEnterprise);
    const [enterpriseLabel, setEnterpriseLabel] = useState(null)
    const [view, setView] = useState(false);
    const [reference_id, setReferenceId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [project_name, setProjectName] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchFor, setSearchFor] = useState(null);
    const [searchStatusVal, setSearchStatusVal] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [filter_status, setFilterStatus] = useState("");
    const [filter_project, setFilterProject] = useState("");
    const [projectLabel, setProjectLabel] = useState("")
    const [projectOptions, setProjectOptions] = useState([]);

    const business_id = options && options[0]?.value;


    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleReload = () => {
        setFilterProject(null);
        setFilterStatus(null);
        setSearchInput("");
        setReferenceId("")
        setSearchForLabel(null);
        setSearchStatusVal(null);
        setSelectedBusiness(null);
        setPage(1)
        setBusinessId(null)
    }

    const handleChangeProject = (selectedOption) => {
        setFilterProject(selectedOption.value);
        setProjectLabel(selectedOption.label)
        // setSearchInput("")
        // getTransactionsByReference(businessId, "", page,)
    }


    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            console.log('get selected status aaaa', selectedOption)
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
        } else {
            setFilterProject(null);
            setFilterStatus(null);
            setSearchInput(null);
            
            // handleClearFilter();
        }
    }

    const handleSelectEnterprise = () => (value) => {
        setBusinessId(value.value)
        console.log('get selected enterprise bbbbb', value)
        setSelectedBusiness(value.label)
        // setLoading(true)
    };

    const searchItems = (e) => {
        setSearchInput(e.target.value)
        setSearchStatusVal(null);
    }

    const keyPress = (e) => {
        if (e.keyCode == 13) {
            getTransactionsByReference(businessId, e.target.value, page,)
        }
    }

    useEffect(() => { getBusinessListNames(); }, [])


    useEffect(() => {
        if (filter_status) {
            getTransactionsByStatus(businessId, filter_project, filter_status, page);
        } else if (filter_project) {
            getTransactionsUnderProject(businessId, filter_project, page);
        } else if (businessId) {
            getTransactionsByBusinessId(businessId, page);
        } else {
            getTransactionsByBusinessId(business_id, page);

        }
    }, [filter_status, filter_project, business_id, businessId, page]);

    useEffect(() => {
        let project_options = [];

        // allProjects?.map((project) => {
        //     let project_options_data = {
        //         value: project?.id,
        //         label: project?.application_name,
        //         business_id:  project?.business_account_id
        //     }

        //     project_options.push(project_options_data);
        // })

        if(projects.length !== 0) {
            projects.map((project) => {
                let project_options_data = {
                    value: project?.id,
                    label: project?.application_name,
                    business_id:  project?.business_account_id
                }
                project_options.push(project_options_data);
            })
        }else {
            projects.map((project) => {
                let project_options_data = {
                    value: project?.id,
                    label: project?.application_name,
                    business_id:  project?.business_account_id
                }
                project_options.push(project_options_data);
            })
        }

        setProjectOptions(project_options);
    }, [JSON.stringify(allProjects)])


    const handleChangePagination = (event, page) => {
        setPage(page);
        if (businessId) {
            getTransactionsByBusinessId(businessId, page);
        }
    }

    const handleChangeReferencePagination = (e, page) => {
        setPage(page);
        getTransactionsByReference(businessId, searchInput, page);
    }

    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };


    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id, label: business?.business_name }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(business_names)])

    useEffect(() => {

        if (businessId) {
            getProjectList(businessId);
        } else {
            getAllProjectList(page);
        }

        // append project_name per transaction by project_id
        if (transactions?.length > 0) {

            transactions?.map((transaction) => {

                let project_id = transaction?.project_id;

                // projects || allProjects.map((project) => {

                //     if (project?.id == project_id) {
                //         transaction.project_name = project?.application_name
                //     }

                // })
                if(projects.length !== 0) {
                    projects.map((project) => {
                        if (project?.id == project_id) {
                            transaction.project_name = project?.application_name
                        }
    
                    })
                }else {
                    allProjects.map((project) => {
                        if (project?.id == project_id) {
                            transaction.project_name = project?.application_name
                        }
    
                    })
                }

            })

        }

        console.log("transactions herexxx:", transactions)

    }, [JSON.stringify(transactions), JSON.stringify(projects), JSON.stringify(allProjects), businessId])


    return (
        <Box sx={{ width: '95%', borderRadius: 0 }}>
            {error === 'Forbidden' ?
                <ForbiddenScreen />
            :
                <>
                    <Grid container sx={{ p: 2, pt: 1, position: 'relative', zIndex: 3 }}>
                        {view ?
                            <></>
                            :
                            <>
                                <Grid item xs={12} sm={8} sx={{ textAlign: "start" }}>
                                    <Grid item xs={12} sm={12} sx={{ textAlign: 'start', mt: 1 }}>

                                        <Box>
                                            <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>

                                                <ReactSelect
                                                    className="basic-single"
                                                    value={searchStatusVal}
                                                    placeholder={
                                                        <div style={{display:'flex'}}>
                                                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter:</b>&nbsp;
                                                            <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                                {searchForLabel === "Status" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Status</Typography>
                                                                )}
                                                                {searchForLabel === "Project" && (
                                                                    <Typography sx={{ color: "#000", fontSize: 12 }}>Project</Typography>
                                                                )}
                                                                {searchForLabel === null && (
                                                                    "Select Filter"
                                                                )}
                                                            </i>
                                                        </div>
                                                    }
                                                    isSearchable={true}
                                                    isRtl={false}
                                                    options={searchForOptions}
                                                    onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 38,
                                                            borderRadius: 100,
                                                            backgroundColor: 'transparent',
                                                            width: 200,
                                                            fontSize: 12
                                                        }),
                                                        placeholder: base => ({
                                                            ...base,
                                                            fontSize: 12
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 99999,
                                                            fontSize: 12
                                                        }),
                                                    }}
                                                    isClearable
                                                />


                                                &nbsp; &nbsp;

                                                {(searchForLabel === "Status") &&
                                                    <FormControl>
                                                        <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            value={filter_status}
                                                            sx={{
                                                                width: 200, height: 38, borderRadius: 10, backgroundColor: 'transparent',
                                                                '&.Mui-focused': {
                                                                    border: 'none',
                                                                },
                                                            }}
                                                            onChange={handleStatusChange}
                                                            inputProps={{ sx: { mr: "0px", fontSize: 12, textAlign: "center" } }}
                                                        >
                                                            {statusOption?.map((status) => {
                                                                return (
                                                                    <MenuItem key={status.value} value={status.value} sx={{ fontSize: 12 }} >
                                                                        {status.label}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                }

                                                {(searchForLabel === "Project") &&
                                                    <ReactSelect
                                                        className="basic-single"
                                                        // defaultValue={projectLabel}
                                                        placeholder={"Select Project"}
                                                        isSearchable={true}
                                                        isRtl={false}
                                                        options={projectOptions}
                                                        onChange={selectedOption => handleChangeProject(selectedOption)}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                height: 38,
                                                                borderRadius: 100,
                                                                backgroundColor: 'transparent',
                                                                width: 200,
                                                                fontSize: 12
                                                            }),
                                                            placeholder: base => ({
                                                                ...base,
                                                                fontSize: 12
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 99999,
                                                                fontSize: 12
                                                            }),
                                                        }}
                                                    />
                                                }

                                                &nbsp;&nbsp;

                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 300 } }}
                                                    inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                                    placeholder="Search Reference..."
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Reference:</p></InputAdornment>,
                                                    }}
                                                    value={searchInput}
                                                    onChange={searchItems}
                                                    onKeyDown={keyPress}
                                                />

                                                &nbsp; &nbsp;

                                                <IconButton onClick={() => handleReload()}>
                                                    <CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} />
                                                </IconButton>

                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ justifyContent: 'flex-end', marginLeft: 'auto', mt: 1 }}>
                                    {isFetchingBusinessNames ?
                                        <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                                        :
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={selected_business ? selected_business : "Select Enterprise"}
                                            isRtl={false}
                                            isClearable
                                            isSearchable={true}
                                            options={options}
                                            onChange={handleSelectEnterprise()}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    // width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                    fontSize: 12
                                                }),
                                            }}
                                        />
                                    }
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid container sx={{ p: 2, pt: 1, position: 'relative', zIndex: 2 }}>
                        {view ?
                            <AuthenticationComponent reference={reference_id} projectId={projectId} project_name={project_name} />
                            :
                            <>
                                {loading ?
                                    <TableContainer component={Paper} sx={{ border: '1px solid #8080807a', borderRadius: 2, width: '100%' }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHeader />
                                            <TransactionSkeletonComponent />
                                        </Table>
                                    </TableContainer>

                                    :
                                    <>
                                        <TableContainer component={Paper} sx={{ border: '1px solid #8080807a', borderRadius: 2, width: '100%' }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHeader />


                                                <TableBody>    
                                                    {transactions?.length !== 0 ? 
                                                        <>
                                                            {(transactions && transactions)?.map((transaction, index) => (
                                                                <TransactionContent transaction={transaction} index={index}
                                                                    setView={setView} setReferenceId={setReferenceId} projectId={projectId} setProjectId={setProjectId} setProjectName={setProjectName} businessId={businessId} />
                                                            ))}
                                                        </>
                                                    :
                                                        isSearchReference?.length > 0 ?
                                                        <>
                                                            {(isSearchReference)?.map((transaction, index) =>  (
                                                                <TransactionContent transaction={transaction} index={index}
                                                                    setView={setView} setReferenceId={setReferenceId} projectId={projectId} setProjectId={setProjectId} setProjectName={setProjectName} businessId={businessId} />
                                                            ))}
                                                        </>
                                                    :
                                                    <TableRow key={0}>
                                                        <TableCell colSpan={6} align="center">
                                                            <Box sx={{ margin: 10, textAlign: 'center', ml: 'auto', mr: 'auto' }}>
                                                                <Typography><b></b> No record found.</Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                            <Pagination
                                                count={searchInput ? isSearchReference : pagination}
                                                page={page}
                                                onChange={
                                                    searchInput ? handleChangeReferencePagination :
                                                    handleChangePagination
                                                }
                                                style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                            />
                                        </TableContainer>
                                    </>
                                }

                               
                            </>
                        }
                    </Grid>
                </>
            }
        </Box>
    )

}

export default TransactionComponent