import environment from "../../../environment/environment"

const BUSINESS_ACCOUNTS_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/business-account';
const USER_ACCOUNTS_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/user-account';
const USER_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/user';
const APIM_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/apim';
const ADMIN_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/admin';
const ADMIN_ANALYTICS_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-analytics';
const ADMIN_PROJECT_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account';
const EMAIL_AUTHENTICATION_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/email-sender'
const BUSINESS_APP_CONFIG_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/app-config'
const ENTERPRISE_SECRET_CONFIG_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/config'
const ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account'
const ADMIN_REPORTS_APP_SERVICE_BASE_URL = environment.business_account_service.url + '/ovcode-report' 
const ENTERPRISE_AUDIT_ADMIN_BASE_URL = environment.business_account_service.url + "/enterprise-audit-admin"
const ADMIN_AUDIT_BASE_URL = environment.business_account_service.url + "/admin-audit"
const ADMIN_AUTO_CONFIG_BASE_URL = environment.business_account_service.url + "/auto-revoke-admin"

const ENTERPRISE_TEMPLATE_SERVICE_BASE_URL = environment.business_account_service.url + "/admin-template"
const USER_ADMIN_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1'
const VAULT_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-business-vault' 
const WEBHOOK_SERVICE_BASE_URL = environment.business_account_service.url + '/admin-webhook'
const TEMPLATE_SERVICE_BASE_URL = 'https://ovcodedevapim.ovcode.com/admin-template'
const ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/admin/management/roles';
const ADMIN_RBAC_ROLES_MANAGEMENT_INHERITANCE_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/admin/management/roles/inheritance';
const ADMIN_RBAC_ACCESS_CONTROL_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/admin/management/access-control';

const ESIGN_PROJECT_DOMAIN_WHITELIST_AND_EMAIL_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/e-sign/signatory/whitelist';
const ESIGN_PROJECT_DOMAIN_WHITELIST_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/e-sign/domain/whitelist';
const ESIGN_PROJECT_EMAIL_WHITELIST_BASE_URL = environment.business_account_service.url + '/admin-account/api/v1/e-sign/email/whitelist';

export default {

    // business-accounts
    CREATE_BUSINESS:                                BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/create',
    GET_BUSINESS_LIST:                              BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/list',
    GET_BUSINESS_BY_ID:                             BUSINESS_ACCOUNTS_SERVICE_BASE_URL,
    UPDATE_BUSINESS:                                BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/update',
    SEARCH_PRINCIPAL_NAME_BY_ID:                    BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/search',
    GET_BUSINESS_LIST_NAMES :                       BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/list/business-names',
    GET_COUNTS:                                     BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/counts',
    UPDATE_BUSINESS_EXPIRATION:                     BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/expiration',
    REMOVE_BUSINESS_EXPIRATION:                     BUSINESS_ACCOUNTS_SERVICE_BASE_URL + '/remove/expiration',

    // user-accounts
    GET_USERS :                                     USER_ACCOUNTS_SERVICE_BASE_URL + '/list',
    GET_USERS_BY_BUSINESS :                         USER_ACCOUNTS_SERVICE_BASE_URL + '/list',
    GET_ALL_USERS_BY_BUSINESS :                     USER_ACCOUNTS_SERVICE_BASE_URL + '/list/users',
    SEARCH_PRINCIPAL_NAME:                          USER_ACCOUNTS_SERVICE_BASE_URL + '/search',
    GET_USER_BY_ID:                                 USER_ACCOUNTS_SERVICE_BASE_URL,
    UPDATE_USER_ROLE:                               USER_ACCOUNTS_SERVICE_BASE_URL + '/update/role',
    UPLOAD_SIGNATURE:                               USER_ACCOUNTS_SERVICE_BASE_URL + '/upload/signature',

    // user
    ADD_USER :                                      USER_SERVICE_BASE_URL + '/add',
    UPDATE_USER_STATUS :                            USER_SERVICE_BASE_URL + '/updateStatus',
    UPDATE_USER_PRINCIPAL_NAME :                    USER_SERVICE_BASE_URL + '/updatePrincipalName',
    GET_ADMIN :                                     USER_SERVICE_BASE_URL + '/get',

    //users-admin
    GET_ADMIN_USERS :                               USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/list',
    UPDATE_ADMIN_USER_ROLE:                         USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/update/role',
    VIEW_ADMIN_USER :                               USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/view',
    GET_ADMIN_ROLES :                               USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/roles',
    ADD_ADMIN_USER :                                USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/add',
    UPDATE_ADMIN_USER_EMAIL :                       USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/update/email',
    UPDATE_ADMIN_USER_STATUS :                      USER_ADMIN_SERVICE_BASE_URL + '/admin/management/users/update/status',

    //apim
    REGENERATE_SUB_KEY:                             APIM_SERVICE_BASE_URL + '/regenerateSubscriptionKey',
    CHANGE_PRODUCT:                                 APIM_SERVICE_BASE_URL + '/changeProduct',
    GET_PRODUCTS:                                   APIM_SERVICE_BASE_URL + '/getProducts',

    // admin
    ADD_ADMIN_ACCOUNT:                              ADMIN_SERVICE_BASE_URL + '/add',

    //authentication
    GET_AUTHENTICATION_BY_REFERENCE :               ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction',

    //analytics
    GET_TRANSACTIONS_BY_BUSINESS_ID :               ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction',
    GET_TOP_BUSINESS :                              ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/business/top',
    GET_COUNT_TRANSACTION_BY_YEAR_AND_ID :          ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/count',
    GET_COUNT_TRANSACTION_PER_YEAR       :          ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/yearly',
    GET_COUNT_TRANSACTION_PER_MONTH      :          ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/year/monthly',
    RETRIEVE_REFERENCE_DETAILS      :               ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/retrieve/reference',
    GET_COUNT_TRANSACTION_PIPELINE_PER_YEAR:        ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/pipeline/yearly',
    COUNT_TRANSACTIONS_BY_PIPELINE :                ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/count/pipeline',
    COUNT_TRANSACTION_BY_PROJECT_ALL:               ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v1/transaction/count/all',
    GET_COUNT_TRANSACTION:                          ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v2/transaction/count/pipeline',
    GET_COUNT_TRANSACTION_v2:                       ADMIN_ANALYTICS_SERVICE_BASE_URL + '/api/v2/transaction/count',
    GET_DASHBOARD_COUNT:                            ADMIN_ANALYTICS_SERVICE_BASE_URL + "/api/v2/dashboard/count",
    GET_DASHBOARD_COUNT_FILTER:                     ADMIN_ANALYTICS_SERVICE_BASE_URL + "/api/v2/dashboard/count/filter",
    GET_RECENT_ACTIVITY:                            ADMIN_ANALYTICS_SERVICE_BASE_URL + "/api/v2/dashboard/recent-activity",
    DELETE_TRANSACTION:                             ADMIN_ANALYTICS_SERVICE_BASE_URL + "/api/v1/transaction/delete",

    //projects
    CREATE_PROJECT :                                ADMIN_PROJECT_SERVICE_BASE_URL + '/project/create',
    UPDATE_PROJECT :                                ADMIN_PROJECT_SERVICE_BASE_URL + '/project/update',
    DELETE_PROJECT :                                ADMIN_PROJECT_SERVICE_BASE_URL + '/project/delete',
    GET_PROJECT_LIST :                              ADMIN_PROJECT_SERVICE_BASE_URL + '/project/list',
    VIEW_PROJECT_BY_ID:                             ADMIN_PROJECT_SERVICE_BASE_URL + '/project/view',
    GET_PROJECT_OVERVIEW_STATS:                     ADMIN_PROJECT_SERVICE_BASE_URL + "/project/overview/stats",
    UPDATE_EXPIRATION_DATE :                        ADMIN_PROJECT_SERVICE_BASE_URL + '/project/update/expiry',
    GET_DELETED_PROJECTS :                          ADMIN_PROJECT_SERVICE_BASE_URL + '/project/deleted/list',
    RESTORE_DELETED_PROJECTS :                      ADMIN_PROJECT_SERVICE_BASE_URL + '/project/restore',
    UPLOAD_PROJECT_LOGO:                            ADMIN_PROJECT_SERVICE_BASE_URL + '/project/upload-logo',
    DELETE_PROJECT_PERMANENTLY :                    ADMIN_PROJECT_SERVICE_BASE_URL + '/project/delete/permanently',
    REGENERATE_API_KEY:                             ADMIN_PROJECT_SERVICE_BASE_URL + '/project/regenerate/api-key',

    // email authentication
    RETRIEVE_EMAIL_SENDER :                       EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/list',
    CREATE_EMAIL_SENDER :                         EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/create',
    VIEW_EMAIL_SENDER :                           EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/view',
    TEST_EMAIL_SENDER :                           EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/test',
    REVOKE_EMAIL_SENDER :                         EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/revoke',
    REMOVE_EMAIL_SENDER :                         EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/delete',
    APPROVE_EMAIL_SENDER :                        EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/approve',
    DENIED_EMAIL_SENDER :                         EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/denied',
    RESET_EMAIL_SENDER :                          EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/reset-status',
    SEARCH_BY_EMAIL :                             EMAIL_AUTHENTICATION_SERVICE_BASE_URL + '/search',

    // app config
    APP_CONFIG_LIST:                                BUSINESS_APP_CONFIG_SERVICE_BASE_URL + '/list',
    CREATE_APP_CONFIG:                              BUSINESS_APP_CONFIG_SERVICE_BASE_URL + '/create',
    DELETE_APP_CONFIG:                              BUSINESS_APP_CONFIG_SERVICE_BASE_URL + '/delete',
    VIEW_APP_CONFIG:                                BUSINESS_APP_CONFIG_SERVICE_BASE_URL + '/view',
    UPDATE_APP_CONFIG:                              BUSINESS_APP_CONFIG_SERVICE_BASE_URL + '/update',
    SEARCH_APP_CONFIG:                              BUSINESS_APP_CONFIG_SERVICE_BASE_URL + "/search",


    // secret config
    LIST_CONFIG:                                    ENTERPRISE_SECRET_CONFIG_SERVICE_BASE_URL + '/list',
    CREATE_CONFIG:                                  ENTERPRISE_SECRET_CONFIG_SERVICE_BASE_URL + '/create/update',
    DELETE_CONFIG:                                  ENTERPRISE_SECRET_CONFIG_SERVICE_BASE_URL + '/delete', 
    SEARCH_SECRET_CONFIG:                           ENTERPRISE_SECRET_CONFIG_SERVICE_BASE_URL + "/search",


    //business app
    LIST_OF_ALL_BUSINESS_APP:                       ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/app-management/business/all/apps',
    AVAILABLE_BUSINESS_APP:                         ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/app-management/apps',
    ADD_BUSINESS_APP:                               ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/app-management/business/apps',
    DELETE_BUSINESS_APP:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/app-management/business/apps',
    SEARCH_BUSINESS_APP:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/app-management/business/all/apps/search",
    
    //audit
    SIGN_IN_LOGS:                                   ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/audit/sign-in/logs',
    SIGN_IN_LOGS_ADMIN:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/audit/sign-in/admin/logs',
    USER_SIGN_IN_LOGS:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/audit/sign-in/logs',
    

    //reports
    REPORT_ONE:                                     ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/advance/reports/date-range',
    GET_REPORTS:                                    ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/advance/reports/date-range',
    UPLOAD_SOA:                                     ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/billing/upload/soa',
    RETRIEVE_PROJECT_SOA:                           ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/billing/retrieve/project/soa',
    RETRIEVE_SOA:                                   ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/billing/retrieve/project/soa',
    DELETE_SOA:                                     ADMIN_REPORTS_APP_SERVICE_BASE_URL + '/api/v1/billing/delete/project/soa',

    /// RBAC ///

    //permissions
    GET_PERMISSIONS:                                ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/permissions/list',
    CREATE_PERMISSION:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/permissions/create',
    UPDATE_PERMISSION:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/permissions/update',
    DELETE_PERMISSION:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/permissions/delete',
    SEARCH_PERMISSIONS:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/management/modules/search",

    //permission-admin
    GET_ADMIN_PERMISSIONS:                          ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/permissions/list',
    CREATE_ADMIN_PERMISSION:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/permissions/create',
    UPDATE_ADMIN_PERMISSION:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/permissions/update',
    DELETE_ADMIN_PERMISSION:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/permissions/delete',
    SEARCH_ADMIN_PERMISSIONS:                       ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/modules/search",
   
    //modules
    GET_MODULES:                                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/modules/list',
    CREATE_MODULE:                                  ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/modules/create',
    UPDATE_MODULE:                                  ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/modules/update',
    DELETE_MODULE:                                  ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/modules/delete',

    //modules-admin
    GET_ADMIN_MODULES:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/modules/list',
    CREATE_ADMIN_MODULE:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/modules/create',
    UPDATE_ADMIN_MODULE:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/modules/update',
    DELETE_ADMIN_MODULE:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/modules/delete',

    //access control
    GET_ACCESS_CONTROL_LIST:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/access-control/list',
    ADD_ACCESS_CONTROL:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/access-control/create',
    REMOVE_ACCESS_CONTROL:                          ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/access-control/delete',
    UPDATE_ACCESS_CONTROL:                          ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/access-control/update',


    //roles
    GET_ROLES:                                      ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/list',
    CREATE_ROLE:                                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/create',
    UPDATE_ROLE:                                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/update',
    UPDATE_ROLE_STATUS:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/update/status',
    DELETE_ROLE:                                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/delete',
    SEARCH_ROLE:                                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/management/roles/inheritance/search",

    //roles-admin
    GET_ADMIN_ROLES:                                ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/roles/list",
    CREATE_ADMIN_ROLE:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/roles/create',
    UPDATE_ADMIN_ROLE:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/roles/update',
    UPDATE_ADMIN_ROLE_STATUS:                       ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/roles/update/status',
    DELETE_ADMIN_ROLE:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/admin/management/roles/delete',
    SEARCH_ADMIN_ROLE:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/roles/inheritance/search",

    //inheritance
    GET_INHERITANCE:                                ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/inheritance/list',
    ADD_INHERITANCE:                                ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/inheritance/add',
    REMOVE_INHERITANCE:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/roles/inheritance/delete',

    // credits //

    // business
    GET_BUSINESS_CREDITS:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/business-credit/list',
    ADD_BUSINESS_CREDITS:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/business-credit/add',
    SUB_BUSINESS_CREDITS:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/business-credit/sub',
    SET_BUSINESS_CREDITS_UNLIMITED:                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/business-credit/unlimited',
    VIEW_BUSINESS_CREDITS:                          ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/business-credit/view',
    GET_BUSINESS_USERS:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/user-account/list/users",
    SEARCH_BUSINESS_CREDITS:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/management/business-credit/search",

    // projects credits
    ADD_PROJECT_CREDITS:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/project-credit/add',
    SUB_PROJECT_CREDITS:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/project-credit/sub',
    VIEW_PROJECT_CREDITS:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/project-credit/view',
    GET_PROJECT_CREDIT_LIST:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/management/project-credit/list',

    //package
    GET_PACKAGES:                                   ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/list',
    VIEW_PACKAGE:                                   ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/view',
    CREATE_PACKAGE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/create',
    UPDATE_PACKAGE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/update',
    DELETE_PACKAGE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/delete',
    PACKAGE_SUMMARY:                                ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/packages/list/summary',

    //features
    GET_LIST_OF_FEATURES:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/features/list',
    CREATE_FEATURE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/features/create',
    UPDATE_FEATURE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/features/update',
    REMOVE_FEATURE:                                 ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/features/delete',

    //quota
    ADD_FEATURE_QUOTA:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/feature/quota/add',
    UPDATE_FEATURE_QUOTA:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/feature/quota/update',
    REMOVE_FEATURE_QUOTA:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/package/feature/quota/remove',

    //business-package
    GET_BUSINESS_PACKAGE_FEATURES:                  ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + '/api/v1/business-package/features',
    GET_PLANS_FEATURES_LIST:                        ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/package/features/list",
    ASSIGN_PACKAGE_TO_BUSINESS:                     ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/business-package/assign",
    GET_BUSINESS_PACKAGE:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/business-package/view",
    CREATE_UPDATE_CUSTOM_PACKAGE:                   ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/custom-package/create/update",

    // enterprise
    GET_BUSINESS_OVERVIEW_STATS:                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/business-account/overview/stats",

    // activity logs
    GET_BUSINESS_ACTIVITY_LOGS:                     ENTERPRISE_AUDIT_ADMIN_BASE_URL + "/api/v1/audit/business/logs",
    SEARCH_BUSINESS_ACTIVITY_LOGS:                  ENTERPRISE_AUDIT_ADMIN_BASE_URL + "/api/v1/audit/business/logs/search",
    GET_BUSINESS_USER_ACTIVITY_LOGS:                ENTERPRISE_AUDIT_ADMIN_BASE_URL + "/api/v1/audit/user/logs",
    GET_ENTERPRISE_PROJECT_ACTIVITY_LOGS:           ENTERPRISE_AUDIT_ADMIN_BASE_URL + "/api/v1/audit/project/logs",
    SEARCH_ENTERPRISE_PROJECT_ACTIVITY_LOGS:        ENTERPRISE_AUDIT_ADMIN_BASE_URL + "/api/v1/audit/project/logs/search",

    //admin activity logs
    GET_ADMIN_BUSINESS_ACTIVITY_LOGS:               ADMIN_AUDIT_BASE_URL + "/api/v1/audit/business/logs",
    SEARCH_ADMIN_BUSINESS_ACTIVITY_LOGS:            ADMIN_AUDIT_BASE_URL + "/api/v1/audit/business/logs/search",
    GET_ADMIN_USER_ACTIVITY_LOGS:                   ADMIN_AUDIT_BASE_URL + "/api/v1/audit/user/logs",
    SEARCH_ADMIN_USER_ACTIVITY_LOGS:                ADMIN_AUDIT_BASE_URL + "/api/v1/audit/user/logs/search",
    GET_ADMIN_PROJECT_ACTIVITY_LOGS:                ADMIN_AUDIT_BASE_URL + "/api/v1/audit/project/logs",
    SEARCH_ADMIN_PROJECT_ACTIVITY_LOGS:             ADMIN_AUDIT_BASE_URL + "/api/v1/audit/project/logs/search",
    GET_ADMIN_ACTIVIY_LOGS:                         ADMIN_AUDIT_BASE_URL + "/api/v1/audit/all/logs",
    SEARCH_ADMIN_ACTIVIY_LOGS:                      ADMIN_AUDIT_BASE_URL + "/api/v1/audit/all/logs/search",

    // templates
    GET_COUNT_ACCOUNT_TEMPLATES:                    ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/count",
    GET_ENTERPISE_ACCOUNT_TEMPLATES:                ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/list",
    FILTER_ENTERPISE_ACCOUNT_TEMPLATES:             ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/search",
    UPDATE_TEMPLATE_NAME:                           ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates",
    DELETE_TEMPLATE:                                ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/delete",
    UPDATE_TEMPLATE_STATUS:                         ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/status",
    GET_DELETED_ACCOUNT_TEMPLATES:                  ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/list",
    DELETE_TEMPLATE_PERMANENTLY:                    ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/delete/permanently",
    RESTORE_DELETED_TEMPLATE:                       ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates/restore",
    CREATE_ACCOUNT_TEMPLATE:                        ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates",
    VIEW_ACCOUNT_TEMPLATE:                          ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/account-templates",
    GET_TEMPLATE_PROPERTIES :                       ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/template-properties/list",
    CREATE_UPDATE_TEMPLATE_PROPERTIES:              ENTERPRISE_TEMPLATE_SERVICE_BASE_URL + "/api/v1/template-properties/create-update",

    // Template Signature
    CREATE_SIGNATURE:                               TEMPLATE_SERVICE_BASE_URL + "/api/v1/signature/create",
    GET_SIGNATURE_LIST:                             TEMPLATE_SERVICE_BASE_URL + "/api/v1/signature/list",
    UPDATE_TEMPLATE_SIGNATURE:                      TEMPLATE_SERVICE_BASE_URL + "/api/v1/signature/update",
    VIEW_TEMPLATE_SIGNATURE:                        TEMPLATE_SERVICE_BASE_URL + "/api/v1/signature/view",
    DELETE_TEMPLATE_SIGNATURE:                      TEMPLATE_SERVICE_BASE_URL + "/api/v1/signature/delete",
    GET_TEMPLATE_SIGNATURE_LIMIT:                   TEMPLATE_SERVICE_BASE_URL + "/api/v1/project/signature/retrieve/limit",
    CONFIGURE_SIGNATURE_LIMIT:                      TEMPLATE_SERVICE_BASE_URL + "/api/v1/project/signature/config/limit",
    GET_TEMPLATE_SIGNATURE_LIST:                    TEMPLATE_SERVICE_BASE_URL + "/api/v1/template/signature/retrieve",
    ASSIGN_TEMPLATE_SIGNATURE:                      TEMPLATE_SERVICE_BASE_URL + "/api/v1/template/signature/assign",
    REMOVE_TEMPLATE_SIGNATURE:                      TEMPLATE_SERVICE_BASE_URL + "/api/v1/template/signature/remove",

    // admin rbac access control
    ADMIN_GET_ACCESS_CONTROL_LIST:                  ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/access-control/list",
    ADMIN_ADD_ACCESS_CONTROL:                       ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/access-control/create",
    ADMIN_REMOVE_ACCESS_CONTROL:                    ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/api/v1/admin/management/access-control/delete",

    // vault
    GET_FILES:                                      VAULT_SERVICE_BASE_URL + '/api/v1/files',       
    GET_FILES_BY_PROJECT_ID:                        VAULT_SERVICE_BASE_URL + '/api/v1/files/project',           
    DOWNLOAD_FILE:                                  VAULT_SERVICE_BASE_URL + '/api/v1/download/file/reference',
    REPORT_STORAGE:                                 VAULT_SERVICE_BASE_URL + '/api/v1/storage/usage',


    // enterprise project email sender
    GET_LIST_EMAIL_SENDER:                          ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/list",
    SEARCH_EMAIL_SENDER_BY_EMAIL:                   ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/search",
    VIEW_EMAIL_SENDER:                              ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/view",
    REVOKE_EMAIL_SENDER:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/revoke",
    APPROVE_EMAIL_SENDER:                           ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/approve",
    DENIED_EMAIL_SENDER:                            ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/denied",
    RESET_EMAIL_SENDER:                             ENTERPRISE_BUSINESS_APP_SERVICE_BASE_URL + "/email-sender/reset-status",
    
    // webhook
    GET_WEBHOOK :                           WEBHOOK_SERVICE_BASE_URL + '/api/v1/webhook/retrieve',
    ADD_WEBHOOK :                           WEBHOOK_SERVICE_BASE_URL + '/api/v1/webhook/add',
    DELETE_WEBHOOK :                        WEBHOOK_SERVICE_BASE_URL + '/api/v1/webhook/delete',
    UPDATE_WEBHOOK :                        WEBHOOK_SERVICE_BASE_URL + '/api/v1/webhook/update',
    GET_WEBHOOK_TRIGGERS :                  WEBHOOK_SERVICE_BASE_URL + '/api/v1/webhook/triggers',

    // project verification setting
    UPDATE_DOWNLOADABLE:                    ADMIN_PROJECT_SERVICE_BASE_URL + '/project/update/is-data-downloadable',
    UPDATE_PRIVACY:                         ADMIN_PROJECT_SERVICE_BASE_URL + '/project/update/policy',

    // Auto Revoke
    RETRIEVE_AUTO_REVOKE:                   ADMIN_AUTO_CONFIG_BASE_URL + "/api/v1/retrieve/auto-revoked",
    CONFIG_AUTO_REVOKE:                     ADMIN_AUTO_CONFIG_BASE_URL + "/api/v1/config/auto-revoked",
    DELETE_AUTO_REVOKE:                     ADMIN_AUTO_CONFIG_BASE_URL + "/api/v1/delete/auto-revoked",

    // admin rbac roles management
    ADMIN_LIST_ROLE: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/list',
    ADMIN_VIEW_ROLE: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/view',
    ADMIN_DELETE_ROLE: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/delete',
    ADMIN_UPDATE_ROLE: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/update',
    ADMIN_UPDATE_ROLE_STATUS: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/update/status',
    ADMIN_CREATE_ROLE: ADMIN_RBAC_ROLES_MANAGEMENT_BASE_URL + '/create',

    // admin rbac roles management inheritance
    ADMIN_LIST_INHERITANCE: ADMIN_RBAC_ROLES_MANAGEMENT_INHERITANCE_BASE_URL + '/list',
    ADMIN_REMOVE_ROLE_INHERITANCE: ADMIN_RBAC_ROLES_MANAGEMENT_INHERITANCE_BASE_URL + '/delete',
    ADMIN_ADD_INHERITANCE: ADMIN_RBAC_ROLES_MANAGEMENT_INHERITANCE_BASE_URL + '/add',

    // admin rbac access control
    ADMIN_LIST_ACCESS_CONTROL: ADMIN_RBAC_ACCESS_CONTROL_BASE_URL + '/list',
    ADMIN_ADD_ACCESS_CONTROL: ADMIN_RBAC_ACCESS_CONTROL_BASE_URL + '/create',
    ADMIN_REMOVE_ACCESS_CONTROL: ADMIN_RBAC_ACCESS_CONTROL_BASE_URL + '/delete',
    ADMIN_UPDATE_ACCESS_CONTROL: ADMIN_RBAC_ACCESS_CONTROL_BASE_URL + '/update',
    
    // esign project
    WHITELIST_DOMAINS_AND_EMAILS_CACHE: ESIGN_PROJECT_DOMAIN_WHITELIST_AND_EMAIL_BASE_URL + '/cache',

    // esign project whitelist domains
    GET_SIGNATORY_WHITELIST_DOMAINS: ESIGN_PROJECT_DOMAIN_WHITELIST_BASE_URL + '',
    VIEW_SIGNATORY_WHITELIST_DOMAIN: ESIGN_PROJECT_DOMAIN_WHITELIST_BASE_URL + '/view',
    DELETE_SIGNATORY_WHITELIST_DOMAIN: ESIGN_PROJECT_DOMAIN_WHITELIST_BASE_URL + '/delete',
    CREATE_SIGNATORY_WHITELIST_DOMAINS: ESIGN_PROJECT_DOMAIN_WHITELIST_BASE_URL + '/',

    // esign project whitelist emails
    GET_SIGNATORY_WHITELIST_EMAILS: ESIGN_PROJECT_EMAIL_WHITELIST_BASE_URL + '',
    VIEW_SIGNATORY_WHITELIST_EMAIL: ESIGN_PROJECT_EMAIL_WHITELIST_BASE_URL + '/view',
    DELETE_SIGNATORY_WHITELIST_EMAIL: ESIGN_PROJECT_EMAIL_WHITELIST_BASE_URL + '/delete',
    CREATE_SIGNATORY_WHITELIST_EMAIL: ESIGN_PROJECT_EMAIL_WHITELIST_BASE_URL + '',

    //business-application
    RETRIEVE_BUSINESS_APPLICATION : ADMIN_PROJECT_SERVICE_BASE_URL + '/api/v1/e-sign/signatory/checker'

}   