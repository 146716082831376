import {createStore, selector} from "./helper";

const module_name = "draggedElement"

export const draggedItemStore = createStore((set) => ({
    
    // state
    draggedElement: null,

    // actions
    setDraggedElement: (draggedElement) => set({draggedElement: draggedElement}, null, module_name + "/data"),

}), module_name)

export const useDraggedItemStore = () => draggedItemStore(selector)