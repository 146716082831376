export const useReactDnDHelper = (dndRef) => {
    const getCorrectDroppedOffsetValue = (initialPosition, finalPosition) => {
        const dropTargetPosition = dndRef.current.getBoundingClientRect();
    
        const { y: finalY, x: finalX } = finalPosition;
        const { y: initialY, x: initialX } = initialPosition;
        
        const newYposition =
        finalY > initialY
            ? initialY + (finalY - initialY) - dropTargetPosition.top
            : initialY - (initialY - finalY) - dropTargetPosition.top;
    
        const newXposition =
        finalX > initialX
            ? initialX + (finalX - initialX) - dropTargetPosition.left
            : initialX - (initialX - finalX) - dropTargetPosition.left;
    
        return {x: newXposition, y: newYposition};
    };

    return {getCorrectDroppedOffsetValue}
}