import React, { useEffect, useState } from "react";
import { Link, Box, Card, CardContent, Grid, Typography} from "@mui/material"
import SignatoryDomainComponent from "../components/signatory-whitelist/signatory-domain.component";
import SignatoryEmailComponent from "../components/signatory-whitelist/signatory-email.component";
import useProjects from "../hooks/useProjects";
import { useNavigate } from "react-router-dom";

function AppConfigScreen({projectId}){

    const {projectDetails, isLoadingDetails, viewProjectDetails} = useProjects();
    const [ hasEsignApp, setHasEsignApp ] = useState(true)
    const businessId = projectDetails?.business_account_id;
    const navigate = useNavigate();

    useEffect(()=>{
        if(projectId == null) return;
        viewProjectDetails(projectId);
    }, [projectId])

    function handleOnClickAddBusinessAppLink(){
        localStorage.setItem("selectedTab", 5); // Business Application Tab
        navigate('/app-configuration');
    }

    if(isLoadingDetails) return <Typography sx={{p: 2}} textAlign='center'>Loading. . .</Typography>;

    return(
        <Box sx={{p: 3}}>
            <Typography sx={{ fontSize: 20, fontWeight: 550, pb: 1}} >Signatory Whitelist</Typography>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                <Card sx={{ borderRadius: 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: 'auto', width: '100%'}}>
                    <CardContent>

                        <Grid sx={{pb: 1}} item xs={12}>
                            <Typography>Note: By default, any email domain can sign on your eSign app. To enhance security, add domains or email addresses below to enable the whitelist feature.</Typography>
                        </Grid>
                        
                        { !hasEsignApp &&
                            <Grid sx={{pb: 1}} item xs={12}>
                                <Box sx={{p: 2, width: '100%', border: '1px solid #0178f2', borderRadius: 1}} textAlign='center'>
                                    <Typography>
                                        The enterprise account has no eSign app.
                                    </Typography >
                                    <Link onClick={()=>handleOnClickAddBusinessAppLink()} component="button">Add Business App</Link>
                                </Box>
                            </Grid>
                        }
                        
                        <Grid container>
                            <Grid item sx={{p: 1}} xs={12} sm={6}>
                                <SignatoryDomainComponent projectId={projectId} businessId={businessId} onError={()=>setHasEsignApp(false)}/>
                            </Grid>
                            <Grid item sx={{p: 1}} xs={12} sm={6}>
                                <SignatoryEmailComponent projectId={projectId} businessId={businessId} onError={()=>setHasEsignApp(false)}/>
                            </Grid>
                        </Grid>
                        
                    </CardContent>
                </Card>
            </Grid>
        </Box>
    )
}

export default AppConfigScreen;