import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from "react";
import DeleteTemplatePermanentlyDialog from "./delete-template-permanently.dialog";
import RestoreDeletedTemplateDialog from "./restore-deleted-template.dialog";



const DeletedTemplatesActionsPopperComponent = (props) => {

    const { template, deleteTemplatePermanently, deleting, deleted, setDeleted, setDeleting, setIsDeleted, restored, setIsRestored, restoring, restoreDeletedTemplate } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
    const [openRestoreTemplate, setOpenRestoreTemplate] = useState(false);

    console.log("setDeleted aa", setDeleted);

    const handleClickOpenDeleteDialog = (e) => {
        setOpenDeleteTemplate(true);
    };
  
    const handleCloseDeleteDialog = () => {
        setOpenDeleteTemplate(false);
    };

    const handleClickOpenRestoreTemplateDialog = (e) => {
        setOpenRestoreTemplate(true);
    };
  
    const handleCloseRestoreTemplateDialog = () => {
        setOpenRestoreTemplate(false);
    };

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
  
    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                                <MenuItem style={{color: 'red', fontSize: 13, fonWeight: 500}} onClick={(e) => handleClickOpenDeleteDialog(e)}>
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                            <DeleteIcon style={{width: 16, height: 16}}/>
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Delete Permanently</Typography>
                                </MenuItem>

                           
                                <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={(e) => handleClickOpenRestoreTemplateDialog(e)}>
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                            <RestoreIcon style={{width: 16, height: 16}} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Restore</Typography>
                                </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openDeleteTemplate &&
                <DeleteTemplatePermanentlyDialog 
                    openDeleteTemplate={openDeleteTemplate}
                    handleClose={handleCloseDeleteDialog}
                    template={template}
                    setIsDeleted={setIsDeleted}
                    deleteTemplatePermanently={deleteTemplatePermanently}
                    deleting={deleting}
                    deleted={deleted}
                    setDeleted={setDeleted}
                    setDeleting={setDeleting}
                />
            }

            
            {openRestoreTemplate &&
                <RestoreDeletedTemplateDialog 
                    openRestoreTemplate={openRestoreTemplate}
                    handleClose={handleCloseRestoreTemplateDialog}
                    template={template}
                    setIsRestored={setIsRestored}
                    restoring={restoring}
                    restored={restored}
                    restoreDeletedTemplate={restoreDeletedTemplate}
                />
            }
           

        </div>
    )
}

export default DeletedTemplatesActionsPopperComponent
