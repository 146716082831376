import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const addAdminUser = (email) => {

    let payload = {
        data : email
    }

    console.log("add admin user params xxxx",  payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.ADD_ADMIN_ACCOUNT, payload , subscriptionKey);
    console.log("add admin user result", result);
    return result;
    
}

const retrieveApps = (businessId, projectId) => {

    let query_params = {
        businessId: businessId,
        projectId: projectId
    }

    console.log("retrieve apps payload xxxx", query_params);

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_BUSINESS_APPLICATION, query_params, subscriptionKey);
    
    return result;
}


const AdminService = {
    addAdminUser,
    retrieveApps
}

export default AdminService