import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useEmailAuthentication from "../../../hooks/useEmailAuthentication";
import useWidth from "../../../hooks/useWidth";
import {ThreeDots} from "react-loader-spinner";
import { useSnackbar } from "notistack";

import SelectBusinessUserDropdownComponent from "../../common/select-business-user-dropdown.component";
import SelectBusinessDropdownComponent from "../../common/select-business-dropdown.component";
import SelectBusinessProjectDropdownComponent from "../../common/select-business-project-dropdown.component";

const AddEmailSenderDialogComponent = (props) => {
    
    const {openAddEmailSenderDialog, handleClose, onCreateEmailSender, businessId, projectId, hasBusinessAndProjectIds} = props
    const breakpoints = useWidth()
    const [email, setEmail] = useState("");
    const [currentBusinessId, setCurrentBusinessId] = useState(businessId);
    const [currentProjectId, setCurrentProjectId] = useState(projectId);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [page, setPage] = useState(1);

    const { enqueueSnackbar } = useSnackbar();

    const {createEmailSender, isLoadingCreateEmailSender, createEmailSenderData, email_sender_created} = useEmailAuthentication();

    useEffect(() => {
        console.log("currentBusinessId aaaa", currentBusinessId);
    }, [currentBusinessId])

    useEffect(() => {
        if (createEmailSenderData) {
            handleClose();
            props.getListEmailSender(projectId, page);
            // onCreateEmailSender()
            enqueueSnackbar("Successfully created email sender", {
                variant: 'success',
                autoHideDuration: 3000
            });
        }
    }, [JSON.stringify(createEmailSenderData)])


    const handleCreateEmailSender = () => {
        createEmailSender(email, currentProjectId, currentUserId)
    }

    const handleAddEmailSender = (event) => {
        setEmail(event.target.value);
    }

    const onSelectBusiness = (business) => {
        console.log("current business aa", business);
        setCurrentBusinessId(business.id)
    }

    const onSelectProject = (project) => {
        console.log("current project aa", project);
        setCurrentProjectId(project.id)
    }

    const onSelectUser = (user) => {
        console.log("current user aa", user);
        setCurrentUserId(user.id)
    }

    return(
        <Dialog open={openAddEmailSenderDialog} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
               Configure Email Sender
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 1, paddingTop: 1, margin: 5}}>
                <SelectBusinessDropdownComponent hasBusinessAndProjectIds={hasBusinessAndProjectIds} defaultBusinessId={currentBusinessId} onSelectBusiness={onSelectBusiness} />
                <br />
                {currentBusinessId && <><SelectBusinessProjectDropdownComponent hasBusinessAndProjectIds={hasBusinessAndProjectIds} businessId={currentBusinessId} defaultProjectId={currentProjectId} onSelectProject={onSelectProject} /><br /></>}
                {currentProjectId && <><SelectBusinessUserDropdownComponent businessId={currentBusinessId} projectId={currentProjectId} onSelectUser={onSelectUser} /><br /></>}
                <TextField fullWidth label="Email Address" id="fullWidth" 
                    value={email}
                    onChange={handleAddEmailSender}
                />
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: 'black', textTransform: 'none', fontSize: 14}}>Cancel</Button>
                {/* <Button variant="contained" sx={{textTransform:"none", backgroundColor:"#0178f2", fontSize: 14}} onClick={handleCreateEmailSender}>Submit</Button> */}
                <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2",
                    width: (isLoadingCreateEmailSender) ? "auto" : "15%", '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    startIcon={(isLoadingCreateEmailSender) && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    onClick={handleCreateEmailSender}
                    disabled={(!currentBusinessId || !currentProjectId || !currentUserId || !email)}
                >
                    {(isLoadingCreateEmailSender) ? "Creating" : "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEmailSenderDialogComponent