import {Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
// import TestApprovedEmailSenderDialog from "./dialog/test-approved-email-sender-dialog.component";

function EmailSenderDetailsComponent(props) {

    const {authenticationId, emailSenderList} = props;

    const navigate = useNavigate();

    const authenticationDetails = emailSenderList.find(item => item.id == authenticationId);

    const [openTestApprovedEmailSender, setOpenTestApprovedEmailSender] = useState(false);

    const handleOpenDialog = (e) => { setOpenTestApprovedEmailSender(true); }

    const handleClose = (e) => { setOpenTestApprovedEmailSender(false); }

    let status_code = authenticationDetails.status_code;

    if (status_code === 'PA') {
        status_code = 'PENDING FOR APPROVAL';

    } else if (status_code === 'A') {
        status_code = 'APPROVED';

    } else if (status_code === 'DA') {
        status_code = 'DISAPPROVED';

    } else if (status_code === 'R') {
        status_code = 'REVOKED';
    }

    const authenticationLabel = [ 
        {label : 'Email Address', value:authenticationDetails.email},
        {label : 'Approver', value: authenticationDetails.approver_id ? authenticationDetails.approver_id : "NA"},
        {label : 'Date Requested', value: authenticationDetails.datetime_requested},
        {label : 'Decision Date', value: authenticationDetails.datetime_decision? authenticationDetails.datetime_decision : "NA"},
        {label : 'Date of Reason for Disapproval', value: authenticationDetails.disapproval_reason ? authenticationDetails.disapproval_reason : "NA" },
    ];

    return(
        <Card sx={{boxShadow: 'none', }} >
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{textAlign:'left', paddingLeft: 0, paddingRight: 3}}>
                            <Typography sx={{fontWeight: 600, fontSize: 16, color: status_code == "PENDING FOR APPROVAL" ? 'orange' : status_code == "APPROVED" ? 'green' : 'red'}}>
                                {status_code}
                            </Typography>
                        </Grid>

                        {(authenticationDetails.status_code === "A") ? 
                            <Grid item xs={12} sm={12} sx={{textAlign:'left'}}>
                                <Button variant="outlined" onClick={handleOpenDialog} sx={{textTransform:'none'}}>Email Sender Testing</Button>
                            </Grid>
                        :
                            null
                        } 
                        

                        <Grid item xs={12} sm={12}>
                                {authenticationLabel.map((label)=>{
                                    return(
                                        <Grid container direction="row">
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight:'bold', fontSize: 14, padding: "10px 0px 10px 0px"}}>
                                                    {label.label}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography sx={{width:'100%', padding: "10px 0px 10px 0px", textAlign:'justify', wordBreak: 'break-word', fontSize: 14}}>
                                                    {label.value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions sx={{justifyContent:'end'}}>
                    <Button variant="contained" sx={{textTransform:'none', backgroundColor:"#0178f2"}} 
                        onClick={() => navigate()}
                    >
                        Back
                    </Button>
                </CardActions>

                {/* {openTestApprovedEmailSender && 
                    <TestApprovedEmailSenderDialog 
                        openTestApprovedEmailSender={openTestApprovedEmailSender} 
                        handleClose={handleClose} 
                        authentication={authenticationDetails} 
                    />
                } */}
            </Card>
    )
}

export default EmailSenderDetailsComponent