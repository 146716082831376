import React, {useEffect, useState} from "react";
import {Button, Box, Stack, Backdrop, Grid, Typography} from "@mui/material";
import {Document, pdfjs, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useNavigate} from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import useVault from "../../../hooks/useVault";
import useWidth from "../../../hooks/useWidth";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilePreviewComponent = () => {
  
  const width = useWidth();
  const navigate = useNavigate();

  const [pdfPages, setPdfPages] = useState([]);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const {previewPdfFile, vaultPdfView, isPreviewLoading} = useVault()
  const [openBackdrop, setOpenBackdrop] = useState(false)
   
  // const pdfBase64UrlData = localStorage.getItem("pdfBase64Data");

  const reference = (new URLSearchParams(window.location.search)).get("reference");
  const business_id = (new URLSearchParams(window.location.search)).get("business_id");

  console.log("referencexxx", reference)
  console.log("business_idxxx", business_id)

  useEffect(()=>{
    previewPdfFile(reference, business_id, setOpenBackdrop)
  },[reference])

  console.log('sssssssssssssgggggggggggggggggg', vaultPdfView)

  const handleOnDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages)
  }
  
  const handleOnLoadError = (error) => {
    setError(error.message);
  }

  function BackButton () {
    return <Button sx={{ml: 4}} startIcon={
      <ArrowBackIosIcon style={{width: 20, height: 20, color: "#0178f2"}} />} 
        style={{color: "#000", fontSize: 16, textTransform: "none"}}
        onClick={(e) => navigate(-1)}
      >
        Back
      </Button>
  }

  return (
    <Box sx={{margin: "auto", mt: "24px !important", width: "100%", justifyContent: "center"}}>
      <BackButton />

      {vaultPdfView &&
        <Box sx={{mt: -1}}>
          <Document file={vaultPdfView} onLoadSuccess={handleOnDocumentLoadSuccess} onLoadError={handleOnLoadError}>
            <Stack sx={{mt: 5, mb: 5}} direction="column" justifyContent="space-evenly" alignItems="center" spacing={width != 'xs' ? 10 : 4}>
              {/* <Page className={'pdf_page_custom'} pageNumber={pageNumber} /> */}
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                )
              )}
            </Stack>
          </Document>
        </Box>
      }
      <Backdrop open={isPreviewLoading} sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Grid sx={{display: "block", margin: "auto", textAlign: "center"}}>
            <Typography sx={{color: "#fff", fontWeight: "bold", mb: "-5px", fontSize: "22px"}}>
                Decrypting
            </Typography>

            <ThreeDots 
                height="50" 
                width={"80"}
                radius="9"
                color="#fff" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{margin: "auto", textAlign: "center", justifyContent: "center"}}
                wrapperClassName=""
                visible={true}
            />
        </Grid>  
    </Backdrop>
    </Box>
  )
}

export default FilePreviewComponent