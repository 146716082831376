import {createStore, selector} from "./helper";

const module_name = "selectedEnterprise"

export const analyticsEnterpriseStore = createStore((set) => ({
    
    // state
    selectedEnterprise: null,
    selectedEnterpriseID: null,

    // actions
    setSelectedEnterprise: (selectedEnterprise) => set({selectedEnterprise: selectedEnterprise}, null, module_name + "/data"),
    setSelectedEnterpriseID: (selectedEnterpriseID) => set({selectedEnterpriseID: selectedEnterpriseID}, null, module_name + "/data"),

}), module_name)

export const useAnalyticsEnterpriseStore = () => analyticsEnterpriseStore(selector)