import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useEffect} from "react";
import useProjects from "../../../hooks/useProjects";

const DeleteProjectPermanentlyDialog = (props) => {

    const {openDeleteProject, handleClose, project, setIsDeleted} = props;

    const {deleted, deleteProjectPermanently, deletingProjectPermanently} = useProjects();

    const handleDeleteProjectPermanently = (id) => {
        deleteProjectPermanently(id)
    }

    useEffect(() => {
        if (deleted){
            setIsDeleted(true);
            handleClose();
        }
    }, [deleted])


    return(
        <Dialog open={openDeleteProject} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle sx={{color: 'red'}}>
                Delete Permanently Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontSize: 16}}>
                    Are you sure you want to delete permanently <strong>{project?.application_name}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "black", textTransform: "none", fontSize: 14}} onClick={handleClose}>
                    Cancel
                </Button>

                <Button variant="contained" sx={{backgroundColor: "red", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", '&:hover': {backgroundColor: "red", color: "white"}}}
                    onClick={() => handleDeleteProjectPermanently(project?.id)} 
                    startIcon={deletingProjectPermanently && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {deletingProjectPermanently ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteProjectPermanentlyDialog