import {Box, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import EmailSenderPopperComponent from "./email-sender-popper.component";
import EmailSenderDetailsComponent from "./email-sender-details.component";
import EmailAuthenticationSkeletonComponent from "../skeletons/email-authentication-skeleton.component";
import {format} from "date-fns";
import EmailSenderEnterprisePopperComponent from "./email-sender-enterprise-popper.component";

function TableHeader () {
    const tablecell = [ 
        {label: "ID"},
        {label: "Email Address"},
        {label: "Status"},
        {label: "Date Requested"},
        {label: "Action"}
    ]

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#0178f2'}}>
                            {value.label}
                        </TableCell>  
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function TableContent (props) {
    const {emailSenderList, isFetchingEmailSender, isSearchingEmailSender, searchedEmailSender, isSearchToggle,
        handleClickViewAuthentication, projectId
    } = props;

    return(
        <>
        {(isFetchingEmailSender || isSearchingEmailSender)?
            <EmailAuthenticationSkeletonComponent />
        : 
            <TableBody>
                {isSearchToggle ? (
                    searchedEmailSender?.map((authentication) => {
                        return (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row" sx={{color: '#0178f2'}}>
                                    {authentication.id}
                                </TableCell>
        
                                <TableCell sx={{color:'#0178f2', cursor: "pointer"}} 
                                    onClick={(e) => handleClickViewAuthentication(authentication.id)}
                                > 
                                    {authentication.email}
                                </TableCell>
        
                                <TableCell sx={{fontWeight: 600, color: authentication.status_code == "PA" ? 'orange' : authentication.status_code == "A" ? 'green' : 'red'}}>
                                    {authentication.status_code === "PA" ? "Pending for Approval" : authentication.status_code == "A" ? "Approved" : authentication.status_code == "DA" ? "Disapproved": authentication.status_code == "R" ? "Revoked" : null}
                                </TableCell>
        
                                <TableCell>
                                    {authentication.datetime_requested}
                                    {/* {format(new Date(authentication.datetime_requested), 'PPpp')} */}
                                </TableCell>

                                <TableCell align="left">
                                    <EmailSenderEnterprisePopperComponent 
                                        authentication={authentication}
                                        getListEmailSender={props.getListEmailSender}
                                        projectId={projectId}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })

                ) : (
                        <>
                            {emailSenderList?.map((authentication) => {
                                return (
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row" sx={{color: '#0178f2'}}>
                                            {authentication.id}
                                        </TableCell>

                                        <TableCell sx={{color:'#0178f2', cursor: "pointer"}} onClick={(e) => handleClickViewAuthentication(authentication.id)}> 
                                            {authentication.email}
                                        </TableCell>

                                        <TableCell sx={{fontWeight: 600, color: authentication.status_code == "PA" ? 'orange' : authentication.status_code == "A" ? 'green' : 'red'}}>
                                            {authentication.status_code === "PA" ? "Pending for Approval" : authentication.status_code == "A" ? "Approved" : authentication.status_code == "DA" ? "Disapproved": authentication.status_code == "R" ? "Revoked" : null}
                                        </TableCell>

                                        <TableCell>
                                            {format(new Date(authentication.datetime_requested), 'PPpp')}
                                        </TableCell>

                                        <TableCell align="left">
                                            <EmailSenderEnterprisePopperComponent 
                                                authentication={authentication}
                                                getListEmailSender={props.getListEmailSender}
                                                projectId={projectId}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </>
                )}
            </TableBody> 
        }
        </>
    )
}

const EmailSenderListComponent = (props) => {

    const {breakpoints, isFetchingEmailSender, emailSenderList, isSearchingEmailSender, isSearchToggle, isSearchedResultsEmpty, isEmailSenderListEmpty, searchedEmailSender, 
        viewEmailAuthentication, handleClickViewAuthentication, authentication, authenticationId, isLoading,
        searchInput, isSearchEmailById, searchEmailError, projectId, handleChangePagination, page, pagination
    } = props;


    return (
        <Grid item xs={12} sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3, mt: -1}}>
            {!viewEmailAuthentication ?
                <TableContainer component={Paper} sx={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 2,  width: "100%"}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader />

                        <TableContent 
                            emailSenderList={emailSenderList}
                            isFetchingEmailSender={isFetchingEmailSender}
                            isSearchingEmailSender={isSearchingEmailSender}
                            isSearchToggle={isSearchToggle}
                            searchedEmailSender={searchedEmailSender}
                            authenticationId={authenticationId}

                            searchEmailError={searchEmailError} 
                            searchInput={searchInput} 
                            isSearchEmailById={isSearchEmailById} 
                            isLoading={isLoading} 
                            viewEmailAuthentication={viewEmailAuthentication} 
                            handleClickViewAuthentication={handleClickViewAuthentication}
                            getListEmailSender={props.getListEmailSender}
                            projectId={projectId}

                        />
                    </Table>

                    {!isFetchingEmailSender && !isSearchingEmailSender && !isSearchToggle && emailSenderList?.length === 0 &&
                        <Typography sx={{fontSize: 14, color: "gray", padding: 4, textAlign: "center"}}>
                            There is no configured email address.
                        </Typography>
                    }

                    {isSearchToggle && !isFetchingEmailSender && !isSearchingEmailSender && isSearchedResultsEmpty &&
                        <>
                            <Box sx={{textAlign: "center", padding: "30px 80px"}}>
                                No searched result(s) found.
                            </Box>
                        
                        </>
                    }

                    <Pagination
                        count={pagination}
                        page={page}
                        onChange={handleChangePagination}
                        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                </TableContainer>
                
            :
               
                <EmailSenderDetailsComponent 
                    emailSenderList={emailSenderList}
                    authenticationId={authenticationId}
                />
            }
        </Grid>
    )
}

export default EmailSenderListComponent