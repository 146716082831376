import { useState, useEffect } from "react";
import { Box, Button, Divider, Grid, Link, Skeleton, Typography } from "@mui/material";
import WebhookLogsComponent from "../components/webhooks/webhook-logs.component";
import WebhooksComponent from "../components/webhooks/webhooks.component";
import useWebhook from "../hooks/useWebhook";
import environment from "../environment/environment";
import WebhookSkeletonComponent from "../components/skeletons/webhook-skeleton.component";

function WebhookHeader(props) {

    const { loading, webhook, isAdd, setAdd, created, handleClickViewLogs, isDeleted} = props;

    useEffect(() => {
        if (created) {
            setAdd(false);
        }
    }, [created])


    return (

        <>
            {loading ?
                <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                    <Skeleton variant="rectangular" style={{ marginLeft: "40px", width: "50%", height: 25, backgroundColor: "rgb(108 139 222 / 30%)" }} />
                </Grid>

            :

                <Grid container sx={{ textAlign: 'start', ml: 2, mb: 2 }}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            {(isAdd) ?
                                <Typography variant='h6'>
                                    Webhook<span style={{ fontWeight: 500, fontSize: 16 }}> / Add Webhook</span>
                                </Typography>

                            :
                                (webhook) ?
                                    <>
                                        <Grid item xs={6}>
                                            <Typography variant='h6'>Webhook<span style={{ fontWeight: 500, fontSize: 16 }}> / Manage Webhook</span></Typography>
                                        </Grid>

                                        {/* <Grid item xs={6} sx={{ textAlign: 'end' }}>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClickViewLogs}>Logs</Button>
                                        </Grid> */}
                                    </>

                                    :
                                    <Grid item xs={6}>
                                        <Typography variant='h6'>Webhook</Typography>
                                    </Grid>
                            }
                            <>

                                {(isAdd) || webhook ?
                                    null
                                    :
                                    <Grid item xs={12} sm={6} sx={{ textAlign: 'end', alignSelf: 'center', ml: -5 }}>
                                        <Button variant="contained" sx={{ backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                                            onClick={(e) => setAdd(true) }
                                        >
                                            Add Webhook
                                        </Button>
                                    </Grid>
                                }
                            </>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}

function WebhookScreen(props) {

    const { projectId } = props;

    let businessId = (new URLSearchParams(window.location.search)).get("id");


    const { loading, webhook, getWebhook, pagination } = useWebhook();

    const [isAdd, setAdd] = useState(false);
    const [isCreated, setCreated] = useState(false);
    const [isDeleted, setDeleted] = useState(false);

    const sendDataToParent = (child) => { setCreated(child); }
    const sendDataToParentFromChild = (child) => { setDeleted(child); }
    const [viewLogs, setViewLogs] = useState(false)

    const handleClickViewLogs = () => {
        setViewLogs(true)
    }

    const apiDocumentationURL = environment.apiDocumentationURL

    useEffect(() => { getWebhook(projectId, businessId); }, [])


    useEffect(() => {
        if (isCreated) { getWebhook(projectId, businessId); }
    }, [isCreated])

    if (loading) {
        return (
            <Box sx={{ padding: 2, width: '100%', borderRadius: 0 }}>
                <Grid container sx={{ textAlign: 'start', ml: 2, mb: 2 }}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            <Typography variant='h6'>
                                Webhook
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <WebhookSkeletonComponent />
            </Box>
        )
    }


    return (
        <>
            {(!loading) &&
                <Box sx={{ padding: 2, width: '100%', borderRadius: 0 }}>

                    <WebhookHeader
                        handleClickViewLogs={handleClickViewLogs}
                        webhook={webhook}
                        setAdd={setAdd}
                        isAdd={isAdd}
                        created={isCreated}
                        loading={loading}
                        isDeleted={isDeleted}
                    />

                    <Divider />

                    <br />

                    {!isDeleted ? (
                        (loading) ? (
                            <WebhookSkeletonComponent />
                        ) : (
                            (webhook || isAdd) ? (
                                <Box sx={{ width: '90%', margin: 'auto' }}>
                                    {(!viewLogs) ? (
                                        <WebhooksComponent
                                            webhook={webhook}
                                            sendDataToParent={sendDataToParent}
                                            sendDataToParentFromChild={sendDataToParentFromChild}
                                            projectId={projectId}
                                        />
                                    ) : (
                                        <WebhookLogsComponent
                                            projectId={projectId}
                                            pagination={pagination}
                                            
                                        />
                                    )}
                                </Box>
                            ) : (
                                <Typography>
                                    Webhooks allow external services to be notified when certain events happen.
                                    When the specified events happen, we’ll send a POST request to each of the URLs you provide.
                                    <br />
                                    Learn more in our <span style={{ color: '#0178f2' }}><Link href={apiDocumentationURL + "/webhook/introduction"} underline="none" target="_blank">Webhooks Guide</Link></span>.
                                </Typography>
                            )
                        )
                    ) : (
                        // Display something else when isDeleted is true
                        <Typography>
                            Webhooks allow external services to be notified when certain events happen.
                            When the specified events happen, we’ll send a POST request to each of the URLs you provide.
                            <br />
                            Learn more in our <span style={{ color: '#0178f2' }}><Link href={apiDocumentationURL + "/webhook/introduction"} underline="none" target="_blank">Webhooks Guide</Link></span>.
                        </Typography>
                    )}

                </Box>
            }

            <Box sx={{ width: '90%', margin: 'auto' }}>
                { webhook || isAdd ?
                    null
                :
                    <>
                        {(!loading || isDeleted ) ? (
                            <Typography sx={{ textAlign: "center", padding: 10, color: 'gray' }}>
                                No Webhook being configured.
                            </Typography>
                        ) : null}
                    </>
                }
                
            </Box>

            
        </>
    )

}

export default WebhookScreen