import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useRef, useState } from "react";
import useRolesManagement from "../../../hooks/useRolesManagement"
import { ThreeDots } from 'react-loader-spinner';
import ReactSelect from "react-select";



const CreateRoleDialog = (props) => {

    const { openCreateRole, handleClose, retrieved, isView , dataRole} = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const menuPortalTargetRef = useRef(null);

    const { createRole, createAdminRole, createdRole, creatingRole, getRolesSelect, getAdminRolesSelect, rolesList } = useRolesManagement();

    const [parentId, setParentId] = useState(null);
    const [parent_role, setParentRole] = useState(null);
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        if(url === "/admin-settings"){
            getAdminRolesSelect(page);
        } else {
            getRolesSelect(page);
        }
    },[page])


    const handleTextFieldChange = (e, name) => {
        if (name == "role_name") {
            setRoleName(e.target.value);

        } else if (name == "role_description") {
            setRoleDescription(e.target.value);

        }
    }

    const handleChangeParentRole = (selectedOption) => {
        setParentId(selectedOption?.value);
        setParentRole(selectedOption);
    }

    const handleCreateRole = () => {

        let payload = {
            role : roleName,
            description : roleDescription,
            parent_role_id : parentId
        }

        if(url === "/admin-settings") {
            createAdminRole(payload);
        } else {
            createRole(payload);
        }


    }

    useEffect(() => {

      

        if(createdRole){
            handleClose()

            if (url === "/admin-settings") {
                console.log("url matches '/admin-settings'");
                if(isView){
                    props.getAdminInheritance(dataRole?.id);
                }else{
                    props.getAllAdminRoles();

                    if(retrieved){
                        props.getAdminRoles(page);
                    }
                }
            } else {
                if(isView){
                    props.getInheritance(dataRole?.id);
                }else{
                    props.getAllRoles();

                    if(retrieved){
                        props.getRoles(page);
                    }
                }
            }
        }

    },[createdRole, dataRole, page, url, isView])
 
   
    return (
        <Dialog open={openCreateRole} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Create Role
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>Role :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={roleName}
                            onChange={(e) => handleTextFieldChange(e, "role_name")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14  }}>Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={roleDescription}
                            onChange={(e) => handleTextFieldChange(e, "role_description")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14  }}>Parent Role <b style={{fontSize: 12}}>(optional)</b> :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {/* <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={parentId}
                                onChange={handleChangeParentRole}
                            >
                                {rolesList?.map((data, index) => { 
                                    return(
                                        <MenuItem value={data?.id}>
                                            {data?.role}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        
                        </FormControl> */}
                        <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={parent_role}
                                onChange={handleChangeParentRole}
                                options={rolesList.map(role => ({ value: role.id, label: role.role }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Parent Role
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                   onClick={() => handleCreateRole() }
                   startIcon={creatingRole && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {creatingRole ? "Creating" : "Create"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateRoleDialog