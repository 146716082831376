import { useState } from 'react';
import EsignProjectWhitelistEmailsService from '../services/api/esign-project/whitelist-emails';

function useEsignProjectWhitelistEmails() {
  
  const [whitelist_emails, setWhitelistEmails] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSignatoryWhitelistEmails = (projectId, businessId, page, limit) => {
    setLoading(true)
    EsignProjectWhitelistEmailsService.getSignatoryWhitelistEmails(projectId, page, limit, businessId).then(res=> {
      setLoading(false);
      console.log("res of getSignatoryWhitelistEmails: ", res)
      setWhitelistEmails(res.data.data);
    }).catch((err)=>{
      console.log("error here: ",err)
      setLoading(false)
      setError(err.response.data);
    });
  };

  const viewSignatoryWhitelistEmails = (id, projectId, businessId) => {
    setLoading(true)
    EsignProjectWhitelistEmailsService.viewSignatoryWhitelistEmails(id, projectId, businessId).then(res=> {
      setLoading(false);
      console.log("res of viewSignatoryWhitelistEmails: ", res)
      setData(res.data);
    }).catch((err)=>{
      console.log("error here: ",err)
      setLoading(false)
      setError(err.response.data);
    });;
  };

  const deleteSignatoryWhitelistEmails = (id) => {
    setLoading(true)
    EsignProjectWhitelistEmailsService.deleteSignatoryWhitelistEmails(id).then(res=> {
      setLoading(false);
      console.log("res of deleteSignatoryWhitelistEmails: ", res)
      setData(res.data);
    }).catch((err)=>{
      console.log("error here: ",err)
      setLoading(false)
      setError(err.response.data);
    });;
  };

  const createSignatoryWhitelistEmails = (project_id, business_id, email_addresses) => {
    setLoading(true)
    EsignProjectWhitelistEmailsService.createSignatoryWhitelistEmails(project_id, business_id, email_addresses).then(res=> {
      setLoading(false);
      console.log("res of createSignatoryWhitelistEmails: ", res)
      setData(res.data);
    }).catch((err)=>{
      console.log("error here: ",err)
      setLoading(false)
      setError(err.response.data);
    });;
  };

  return { data, whitelist_emails, loading, error, getSignatoryWhitelistEmails, viewSignatoryWhitelistEmails, deleteSignatoryWhitelistEmails, createSignatoryWhitelistEmails };

}

export default useEsignProjectWhitelistEmails;