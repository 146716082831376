import { useState } from 'react';
import AdminRbacAccessControlService from '../services/api/admin-rbac/access-control';

function useAdminRbacAccessControl() {
  
  const [data, setData] = useState();
  const [access_control_list, setAccessControlList] = useState([]);

  const getAccessControlList = (page, limit, roleId) => {
    AdminRbacAccessControlService.getAccessControlList(page, limit, roleId).then(res=> {
      console.log("res of getAccessControlList: ", res)
        setAccessControlList(res);
    });
  };

  const addAccessControl = (role_id, permission_id, is_allowed) => {
    AdminRbacAccessControlService.addAccessControl(role_id, permission_id, is_allowed).then(res=> {
        console.log("res of addAccessControl: ", res)
        setData(res);
    });
  };

  const removeAccessControl = (roleId, permissionId) => {
    AdminRbacAccessControlService.removeAccessControl(roleId, permissionId).then(res=> {
        console.log("res of removeAccessControl: ", res)
        setData(res);
    });
  };

  const updateIs_Allowed = (id, isAllowed) => {
    AdminRbacAccessControlService.updateIs_Allowed(id, isAllowed).then(res=> {
        console.log("res of updateIs_Allowed: ", res)
        setData(res);
    });
  };

  return {access_control_list, data, getAccessControlList, addAccessControl, removeAccessControl, updateIs_Allowed} ;

}

export default useAdminRbacAccessControl;