const ptToPx = (pt) => {
    let value = pt * 1.3333
    return value;
}

const pxToPt = (px) => {
    let value = px * 0.75
    return value;
}

export {ptToPx, pxToPt}
