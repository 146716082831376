import React, {useState, useEffect} from "react";
import {Box} from "@mui/material";
import BackButton from "../components/common/back-button";
import UpdateProfileComponent from "../components/profile/update-profile.component";
import UpdateProfileSkeletonComponent from "../components/skeletons/update-profile-skeleton.component";
import useWidth from "../hooks/useWidth";

const UpdateProfileScreen = () => {

    const breakpoints = useWidth();

    const [isLoadingSkeleton, setLoadingSkeleton] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoadingSkeleton(false);
        }, 3000);
    },[])


    return (
        <>
            <Box sx={{textAlign: 'start', pb: 2, mt: -1.5}}>
                <BackButton />
            </Box>

            {(isLoadingSkeleton) ? 
                <UpdateProfileSkeletonComponent breakpoints={breakpoints} /> 
            : 
                <UpdateProfileComponent breakpoints={breakpoints} />
            }
        </>
    )
}

export default UpdateProfileScreen