
import { AppBar, Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Container, Divider, Grid, Paper, Toolbar, Typography } from '@mui/material';

import LandingAppbarComponent from '../components/landing-component/landing-appbar';
import LandingContent from '../components/landing-component/landing-content';
import DrawerHeader from '../layout/drawer-header';
import React, { useState, useEffect, useRef } from "react";
import NET from "vanta/dist/vanta.net.min";
import * as THREE from "three";
import logo1 from '../icons/logo1.jpg';
import logo2 from '../icons/logo2.png';
import logo3 from '../icons/logo3.png'
import logo4 from '../icons/logo4.png'
import adminIcon from '../icons/adminIcon.png';
import OVC from '../icons/new-ovcode-reverse.png';
import AuthService from '../services/auth';

function LandingScreen() {
  const login = () => {
    AuthService.login().then((res)=>{
      window.location.href="/dashboard"; 
    })

    .catch((error)=>{
      console.log("login error iii", error);
    });
  }

  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET(({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          minHeight: 500.0,
          gyroControls: false,
          scale: 1.0,
          scaleMobile: 1.0,
          color: '#0178f2',
        }))
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);


  return (
    <Box style={{height: '100vh',overflowX : 'hidden', overflowY: 'hidden'}}>

    <div ref={vantaRef}>
        <Button><img src={OVC} style={{height: 40, margin: 10, }}/></Button>

        <DrawerHeader/><DrawerHeader/>
        <Box sx={{textAlign:'center'}}>
            <Typography sx={{marginBottom: 2, fontSize: '3.2rem', color:'white', fontWeight: 800}}>
             OVCODE ADMIN PORTAL
           </Typography>
           <Button variant="contained" onClick={login} sx={{width: 120, marginTop: 4, margin: 'auto', backgroundColor: '#0178f2', borderRadius: 10}}>
             Sign In
           </Button>
           </Box>
    </div>
     

    <DrawerHeader/>

    <Box sx={{alignSelf: 'center', justifyContent: 'center', textAlign: 'center',padding: '0.105em 0' , position: 'fixed', width: '100%', bottom: 0}}>
    <Grid container sx={{width: '80%', margin:'auto', textAlign:'center', justifyContent:'center'}}>
          <Grid item xs={6} sm={3}>
              <Card sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
                  <CardContent>
                      <Avatar src={logo1} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
                  </CardContent>
                  <CardContent>
                      <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>DIGITAL SIGNATURE AND SEAL</Typography>
                  </CardContent>
              </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
              <Card sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
                  <CardContent>
                      <Avatar src={logo2} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
                  </CardContent>
                  <CardContent>
                      <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>BLOCKCHAIN TECHNOLOGY</Typography>
                  </CardContent>
              </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
              <Card  sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
                  <CardContent>
                      <Avatar src={logo3} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
                  </CardContent>
                  <CardContent>
                      <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>DECENTRALIZED STORAGE</Typography>
                  </CardContent>
              </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
              <Card  sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
                  <CardContent>
                      <Avatar src={logo4} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
                  </CardContent>
                  <CardContent>
                      <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>EMAIL DELIVERY</Typography>
                  </CardContent>
              </Card>
          </Grid>
        </Grid>
    </Box>

    </Box>
    // <div style={{overflowX : 'hidden', overflowY: 'hidden'}}>
    //   <div ref={vantaRef}>
    //     <Button><img src={OVC} style={{height: 30, margin: 10, }}/></Button>
    //     <Container maxWidth="md" sx={{textAlign:'center'}}>
    //     <DrawerHeader/><DrawerHeader/><DrawerHeader/>
    //       <Typography sx={{marginBottom: 2, fontSize: '3.2rem', color:'white', fontWeight: 800}}>
    //         OVCODE ADMIN PORTAL
    //       </Typography>
    //       <Button variant="contained" onClick={login} sx={{width: 120, marginTop: 4, margin: 'auto', backgroundColor: '#0178f2', borderRadius: 10}}>
    //         Sign In
    //       </Button>
    //     </Container>
    //     </div>
    //     <DrawerHeader/><DrawerHeader/><DrawerHeader/>
    //     <Grid container sx={{width: '80%', margin:'auto', textAlign:'center', justifyContent:'center'}}>
    //       <Grid item xs={6} sm={3}>
    //           <Card sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
    //               <CardContent>
    //                   <Avatar src={logo1} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
    //               </CardContent>
    //               <CardContent>
    //                   <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>DIGITAL SIGNATURE AND SEAL</Typography>
    //               </CardContent>
    //           </Card>
    //       </Grid>
    //       <Grid item xs={6} sm={3}>
    //           <Card sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
    //               <CardContent>
    //                   <Avatar src={logo2} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
    //               </CardContent>
    //               <CardContent>
    //                   <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>BLOCKCHAIN TECHNOLOGY</Typography>
    //               </CardContent>
    //           </Card>
    //       </Grid>
    //       <Grid item xs={6} sm={3}>
    //           <Card  sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
    //               <CardContent>
    //                   <Avatar src={logo3} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
    //               </CardContent>
    //               <CardContent>
    //                   <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>DECENTRALIZED STORAGE</Typography>
    //               </CardContent>
    //           </Card>
    //       </Grid>
    //       <Grid item xs={6} sm={3}>
    //           <Card  sx={{borderRadius: 2, backgroundColor:'transparent', boxShadow:'none'}}>
    //               <CardContent>
    //                   <Avatar src={logo4} sx={{height: 100, width: 100, margin:'auto', border:'0.5px solid #0178f2'}}/>
    //               </CardContent>
    //               <CardContent>
    //                   <Typography sx={{width: '70%', margin:'auto', fontWeigth: 200, color:'black'}}>EMAIL DELIVERY</Typography>
    //               </CardContent>
    //           </Card>
    //       </Grid>
    //     </Grid>
    //   </div>
  )
}

export default LandingScreen;
