import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";
import {ThreeDots} from "react-loader-spinner";
import useAppConfig from "../../../hooks/useAppConfig";

function DeleteAppConfigDialog (props){

    const {openDeleteAppConfig, handleClose, list, setDeletedList} = props
    const breakpoints = useWidth()

    
    const {deleteAppConfig, isDeleting,  deleted } = useAppConfig();

    const handleDeleteAppConfig = () => {

        deleteAppConfig(list?.app_code, handleClose)
    }

    useEffect(() => {
        if(deleted){
            setDeletedList(true);
        }
    }, [deleted])

    return(
        <Dialog open={openDeleteAppConfig} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Delete App Config
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    You are about to delete <strong>{list?.app_name}</strong>. Please confirm. 
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button onClick={handleDeleteAppConfig} variant="contained" 
                 startIcon={isDeleting &&
                    <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                }>
                     {isDeleting ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteAppConfigDialog