import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ThreeDots} from 'react-loader-spinner';
import useUsers from "../../../hooks/useUsers";

const EditUserEmailDialog = (props) => {

    const {breakpoints, openEditEmail, setOpenEditEmail, userId, userEmail} = props;

    const url = window.location.pathname;

    const {updatePrincipalName, isUpdatingPrincipalName, updated, updateAdminPrincipalName} = useUsers();

    const [email, setEmail] = useState(userEmail);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail);
        }
    }, [])


    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleEmailChange = (e) => {
        let user_email = e.target.value;

        setEmail(user_email);
        setEmptyEmail(false);

        if (user_email) {
            setValidEmail(validateEmail(email));
        } else {
            setValidEmail(true);
        }
    }

    const handleUpdateUserEmail = (e) => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            let payload = {
                data: {email: email}
            }

            setEmptyEmail(false);
            setValidEmail(true);

            if(url === "/admin-settings"){
                updateAdminPrincipalName(userId, payload);
            } else {
                updatePrincipalName(userId, payload);
            }

        }
    }

    const handleCloseDialog = (e) => {
        setOpenEditEmail(false);
    }

    
    useEffect(() => {
        if (updated) {
            handleCloseDialog();
            
            if (url === "/admin-settings") {
                props.getAdminUsers(page);
            } else if (url === "/user-list") {
                props.getUsers(page);
            } else {
                if (props.projectId) {
                    props.getUsersUnderEnterpriseProject(props.businessId, props.projectId, page);
                } else {
                    props.getUsersByBusinessAccountID(props.businessId, page);
                }
            }
        }
    }, [updated, url, page, props.projectId, props.businessId, props.getAdminUsers, props.getUsers, props.getUsersUnderEnterpriseProject, props.getUsersByBusinessAccountID, handleCloseDialog]);
    

    return (
        <Dialog open={openEditEmail} onClose={(e) => handleCloseDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Edit Email
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px 24px" : "20px 48px 24px 48px"}}>
                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  sx={{alignSelf: "center", fontWeight: 550}}>
                            Email
                        </Grid>

                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                value={email}
                                onChange={(e) => handleEmailChange(e)}
                                helperText={
                                    <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -10px"}}>
                                        {
                                            (emptyEmail) ? "Email Address is required" 
                                        : 
                                            (!isValidEmail) ? "Email Address is invalid" 
                                        : 
                                            ""
                                        }
                                    </p>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button sx={{color: "#000", borderRadius: 1, textTransform: "none"}} 
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", backgroundColor: '#0178f2', borderRadius: 1, boxShadow: 'none'}}
                    onClick={(e) => handleUpdateUserEmail(e)}
                    startIcon={isUpdatingPrincipalName && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isUpdatingPrincipalName) ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditUserEmailDialog