import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import DeleteAppConfigDialog from "./dialog/delete-app-config-dialog.component";
import UpdateAppConfigDialog from "./dialog/update-app-config-dialog.component";
import DeleteBusinessAppConfigDialog from "./dialog/delete-business-app-dialog.component";


function BusinessAppActionsPopperComponent(props){

    const { list } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteBusinessApp, setOpenDeleteBusinessApp] = useState(false)

    const handleClose = () => {
        setOpenDeleteBusinessApp(false)
    }

    const handleOpenDialog = () =>{
        setOpenDeleteBusinessApp(true)
    }

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


  
    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem  onClick={handleOpenDialog} style={{color: 'red', fontSize: 13, fonWeight: 500}} >
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                        <DeleteIcon style={{width: 16, height: 16}}/>
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Delete</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {/* DIALOG */}
            {openDeleteBusinessApp &&
                <DeleteBusinessAppConfigDialog  
                    openDeleteBusinessApp={openDeleteBusinessApp}
                    handleClose={handleClose}
                    list={list} 
                />
            }

        </div>
    )
}

export default BusinessAppActionsPopperComponent
