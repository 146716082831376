import React, {useEffect, useRef, useState} from "react";
import {ListItemText, OutlinedInput, Select, InputLabel, FormControl, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, easing} from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";
import useUsers from "../../../hooks/useUsers";
import UserPasswordDialog from "../../account/dialogs/user-password.dialog";
import ReactSelect from "react-select";

const CreateAdminUserDialog = (props) => {

    const {breakpoints, openCreateUser, setOpenCreateUser} = props;

    const menuPortalTargetRef = useRef(null);


    const {enqueueSnackbar} = useSnackbar();
    const {  getAdminRoles, adminRoles, isCreatingUser, created, user_password, addAdminUser, retrieved, getAllAdminRoles } = useUsers();

    const [email, setEmail] = useState("");
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [selectedRole, setSelectedRole] = useState(null);
    const [role_id, setRoleId] = useState("");

    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        getAllAdminRoles();
    },[])

    useEffect(() => {
        if(retrieved){
            getAdminRoles(page);
        }
    },[retrieved, page])


 
    const handleRoleChange = (selectedOption) => {
        // setRole(event.target.value);
        setSelectedRole(selectedOption);
        setRoleId(selectedOption?.value);
    }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleEmailChange = (e) => {
        let user_email = e.target.value;

        setEmail(user_email);
        setEmptyEmail(false);

        if (user_email) {
            setValidEmail(validateEmail(email));
        } else {
            setValidEmail(true);
        }
    }

    const handleCloseDialog = (e) => {
        setOpenCreateUser(false);
    }

    const handleCreateUser = () => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            setEmptyEmail(false);
            setValidEmail(true);

            let payload = {
                data: {
                    email : email,
                    role: role_id
                }
            }


            if (role_id && email) {
                addAdminUser(payload);

            } else if (!role_id) {
                enqueueSnackbar("Role is required", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        }
    }


    const handleCloseUserPassword = (e) => {
        setOpen(false);
    }


    const handleCloseAllDialogs = (e) => {
        handleCloseDialog();
        handleCloseUserPassword();
        props.getAdminUsers(page);
    }

    useEffect(() => {
        if (created) {
            setOpen(true);
        }
    }, [created])

   
    return (
        <>
            <Dialog open={openCreateUser} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto"}}}>
                <DialogTitle>
                    Create User
                </DialogTitle>

                <Divider />

                <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? 3 : "24px 48px 28px 48px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}  sx={{alignSelf: "center", fontWeight: 550}}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Role :</Typography>
                        </Grid>

                        {/* <Grid item xs={6}  sx={{textAlign: "right", fontWeight: 550}}>
                            <Typography sx={{color: "#0178f2", cursor: "pointer"}} onClick={()=> window.open('/user-list/role-permission')}>
                            Learn More
                            </Typography>
                        </Grid> */}

                        <Grid item xs={12} sx={{pt: "4px !important"}}>
                             <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedRole}
                                onChange={handleRoleChange}
                                options={adminRoles.map(role => ({ value: role.role, label: role.role }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Role
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{alignSelf: "center", fontWeight: 550}}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Email :</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{pt: "4px !important"}}>
                            <TextField  
                                fullWidth  
                                variant="outlined"  
                                autoComplete="off"  
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange} 
                                sx={{fontSize: 12}}
                                helperText={
                                    <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                        {
                                            (emptyEmail) ? "Email is required" 
                                        : 
                                            (!validEmail) ? "Email is invalid" 
                                        : 
                                            ""
                                        }
                                    </p>
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                    <Button sx={{color: "black", textTransform: "none"}} onClick={(e) => handleCloseDialog(e)}>
                        Cancel
                    </Button>

                    <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                        onClick={(e) => handleCreateUser(e)} 
                        startIcon={isCreatingUser && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> }
                    >
                        {isCreatingUser ? "Creating" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>

            {open &&
                <UserPasswordDialog password={user_password} openDialog={open} handleClose={handleCloseAllDialogs} />
            }

        </>
    )
}

export default CreateAdminUserDialog