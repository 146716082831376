import {Avatar, Card, Link, CardContent, Grid, IconButton, InputAdornment, TextField, Box, Button, Typography, Divider, Tooltip, CardHeader} from "@mui/material";
import { format } from 'date-fns';
import {makeStyles} from '@mui/material/styles';
import useWidth from '../../hooks/useWidth';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";

const AuthenticationDetailsComponent = (props) => {

    const { reference_details, project_name } = props;

    const breakpoints = useWidth();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const handleCopyToClipboard = (e, name, key) => {
        navigator.clipboard.writeText(key);

        if (name == "apiKey") {
            enqueueSnackbar("Successfully copied API Key to clipboard", { 
                variant: "success",
                autoHideDuration: 3000
            });

        } else {
            enqueueSnackbar("Successfully copied Subscription Key to clipboard", { 
                variant: "success",
                autoHideDuration: 3000
            });
        }
    }

    const authentication_details = [
        {label : 'Reference' , value : reference_details?.reference ? 
            <Typography sx={{fontWeight: 'bold', fontSize: 14}}>
                {reference_details?.reference}
                <IconButton sx={{textTransform: "none", fontSize: 10}}
                    onClick={(e) => handleCopyToClipboard(e, "reference", reference_details?.reference)}
                >
                    <ContentCopyIcon sx={{width: 18, height: 18, color: "#0178f2"}} />
                </IconButton>
            </Typography> 
        : 'N/A' },
        {label : 'Project' , value : project_name && project_name },
        {label : 'Last Date Modified' , value : reference_details?.updated_time ? format(new Date(reference_details?.updated_time), 'PPpp') : 'N/A'},
        {label : 'Pipeline' , value : reference_details?.pipeline ? reference_details?.pipeline : 'N/A' },
        {label : 'Hash' , value : reference_details?.authentication_hash ? reference_details?.authentication_hash : 'N/A' },
        {label : 'Encrypted File', value : reference_details?.file_url ? reference_details?.file_url : 'N/A'  },
        {label : 'CID' , value : reference_details?.cid ? reference_details?.cid : 'N/A' },
        {label : 'Token ID' , value : reference_details?.token_id ? reference_details?.token_id : 'N/A'  }
    ]

   
    return(

        <Card sx={{borderRadius: 2, padding: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height:breakpoints == 'xs' || breakpoints == 'sm' || breakpoints == 'md' ? 'auto' : 580}} >
             <Button startIcon={<ArrowBackIosIcon style={{width : 20, height: 20, color: '#0178f2'}} />} 
                style={{color: "#0178f2", fontSize: 14, textTransform: 'none', fontWeight : 600  }}
                onClick={() => navigate(0)} >
                    Back
            </Button>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} sx={{textAlign:'left', paddingLeft: 0, paddingRight: 3}}>
                        <Typography sx={{fontWeight: 600, fontSize: 16, color: reference_details?.state == "COMPLETED" ? 'green' : reference_details?.state == "ONGOING" ? '#0178f2' : reference_details?.state == "PENDING" ? 'orange' : 'red'}}>
                            {reference_details?.state}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{textAlign:'last'}}>
                        {authentication_details.map((auth) => {
                            return(
                                <Grid container spacing={2} sx={{marginBottom: 2, alignItems:'center'}}>
                                    <Grid item xs={12} sm={4} >
                                        <Typography sx={{fontWeight: 600}}>{auth.label} :</Typography>
                                    </Grid>
                                
                                    <Grid item xs={12} sm={8}>
                                        {auth?.label == 'File URL' ?
                                            <Link underline="none" href={reference_details?.file_url ? reference_details?.file_url : null } target="_blank" sx={{color : auth.value !== 'N/A' ?' #0178f2' : 'inherit'}}>
                                                <Typography  sx={{width:'100%', textAlign:'justify', padding: 1, wordBreak: 'break-word', fontSize: 14 }}>
                                                    {auth.value}  
                                                </Typography>
                                            </Link>
                                        :
                                            <Typography  sx={{width:'100%', textAlign:'justify', padding: 1, wordBreak: 'break-word', fontSize: 14 }}>
                                                {auth.value}  
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid> 
                            )
                        })}
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 
        
    )
    
}

export default AuthenticationDetailsComponent