import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import ReactSelect from 'react-select';
import { useEffect } from "react";
import { useState } from "react";
import useBusiness from "../../../hooks/useBusiness";
import BusinessAppConfigService from "../../../services/api/business-app-config";
import useAppConfig from "../../../hooks/useAppConfig";
import {ThreeDots} from 'react-loader-spinner';

const CreateAppConfigDialog = (props) => {

    const {openDialog, handleClose} = props
    const dataStyles = {  control: (styles) => ({ ...styles, backgroundColor: 'transparent', padding: 10, marginLeft: 16}) };
    const breakpoints = useWidth()
    const { isFetchingBusinessNames, business_names, getBusinessListNames } = useBusiness();
    const {createAppConfig, loading} = useAppConfig()

    const [options, setOption] = useState([]);
    const [businessId, setBusinessId] = useState(options[0]?.value);
    const [selected_business, setSelectedBusiness] = useState('');
    const [app_name, setAppName] = useState("");
    const [app_code, setAppCode] = useState("");
    const [api_endpoint, setApiEndpoint] = useState("");
    const [subscription_key, setSubKey] = useState("");
    const [secret_key, setSecretKey] = useState("");

    console.log('get business id xxxxxxx', businessId)

    useEffect(() => {

        let options = []

        business_names?.map((business) => {

            let options_data = { value: business?.id , label : business?.business_name  }

            options.push(options_data)
        })

        setOption(options)

    },[JSON.stringify(business_names)])

    const getBusiness = () => value => {
        setBusinessId(value.value)
        setSelectedBusiness(value.label)
    };

    const handleCreateAppConfig = () => {
        let payload = {
            app_name: app_name,
            app_code: app_code,
            api_endpoint: api_endpoint,
            subscription_key: subscription_key,
            secret_key: secret_key,
            business_id: businessId,
        }
        createAppConfig(payload)
    }

    const handleTextFieldChange = (e, name) => {
        if (name == "app_code") {
            setAppCode(e.target.value);

        } else if (name == "app_name") {
            setAppName(e.target.value);

        } else if (name == "api_endpoint") {
            setApiEndpoint(e.target.value);
        } else if (name == "subscription_key") {
            setSubKey(e.target.value);
        } else if (name == "secret_key") {
            setSecretKey(e.target.value);
        }
    }

    useEffect(()=>{
        getBusinessListNames()
    },[])

    return(
        <Dialog open={openDialog} onClose={handleClose} maxWidth={'lg'} fullWidth={'lg'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>
                Create App Middleware
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 12}}>Select Enterprise :</Typography>
                    </Grid>
                    <Grid xs={12} sm={9}>
                        <ReactSelect
                            className="basic-single"
                            placeholder={options[0]?.label}
                            defaultValue={selected_business ? selected_business : options[0]?.label}
                            isRtl={false}
                            isSearchable={true}
                            options={options}
                            styles={dataStyles}
                            onChange={getBusiness()}
                        />
                    </Grid>
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold', fontSize: 12}}>App Code :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="App Code"
                                value={app_code}
                                onChange={(e) => handleTextFieldChange(e, "app_code")}  
                            />
                        </Grid>
                        
                    
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold', fontSize: 12}}>App Name :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="App Name"
                                value={app_name}
                                onChange={(e) => handleTextFieldChange(e, "app_name")}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold', fontSize: 12}}>API Endpoint :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="API Endpoint"
                                value={api_endpoint}
                                onChange={(e) => handleTextFieldChange(e, "api_endpoint")}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold', fontSize: 12}}>Subscription Key :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="Subscription Key"
                                value={subscription_key}
                                onChange={(e) => handleTextFieldChange(e, "subscription_key")}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={3} sx={{alignSelf:'center'}}>
                            <Typography sx={{fontWeight:'bold', fontSize: 12}}>Secret Key :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField 
                                fullWidth 
                                variant="outlined"
                                autoComplete="off" 
                                placeholder="Secret Key"
                                value={secret_key}
                                onChange={(e) => handleTextFieldChange(e, "secret_key")}
                            />
                        </Grid>
                        
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: "black", textTransform: "none", fontSize: 14}} >
                    Cancel
                </Button>

                <Button onClick={handleCreateAppConfig} variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Creating" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default CreateAppConfigDialog