import { Box, Button, Grid, Typography } from "@mui/material"
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from "react";
import CreatePermissionDialog from "../rbac/dialogs/create-permission.dialog";
import CreateModuleDialog from "../rbac/dialogs/create-module.dialog";
import useWidth from "../../hooks/useWidth";
import AdminPermissionsComponent from "./admin-permission.component";
import ViewAdminPermissionComponent from "./view-admin-permission.component";
import usePermissionManagement from "../../hooks/usePermissionManagement";
import ForbiddenScreen from "../../screens/ForbiddenScreen";


const AdminPermissionManagementComponent = () => {

    const breakpoints = useWidth();
    const { isLoading, getAdminPermissionsFunction, functions, getAdminModules, getModules, modules, pagination, error } = usePermissionManagement();

    const [openCreatePermission, setOpenCreatePermission] = useState(false);
    const [openCreateModule, setOpenCreateModule] = useState(false);
    const [isView, setView] = useState(false);
    const [permission_id, setPermissionId] = useState(false);
    const [module_data, setModuleData] = useState(false);

    const handleOpenCreatePermission = () => {
        setOpenCreatePermission(true);
    }

    const handleCloseCreatePermission = () => {
        setOpenCreatePermission(false);
    }

    const handleOpenCreateModule = () => {
        setOpenCreateModule(true);
    }

    const handleCloseCreateModule = () => {
        setOpenCreateModule(false);
    }

    console.log("errorxx", error)


    return (
        <Box sx={{ width: '97%', margin: 'auto' }}>
            {error === "Forbidden" ?
                <ForbiddenScreen />
            :
                <Grid container>
                    <Grid item xs={8}>
                        <Typography sx={{ fontSize: 14 }}>Controlling access to resources and data, ensuring security and privacy.</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: "45px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sx={{ paddingTop: '1px !important', textAlign: 'end' }}>
                                <Button
                                    variant="text"
                                    sx={{
                                        fontSize: 14,
                                        p: '4px 5px',
                                        borderRadius: 10,
                                        boxShadow: 'none',
                                        color: '#0178f2',
                                        textTransform: 'none',
                                    }}
                                    startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                    onClick={handleOpenCreatePermission}
                                >
                                    Create Permission
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: '1px !important', textAlign: 'center' }}>
                                <Button
                                    variant="text"
                                    sx={{
                                        fontSize: 14,
                                        p: '4px 5px',
                                        borderRadius: 10,
                                        boxShadow: 'none',
                                        color: '#0178f2',
                                        textTransform: 'none',
                                    }}
                                    startIcon={<AddBoxIcon sx={{ color: '#0178f2' }} />}
                                    onClick={handleOpenCreateModule}
                                >
                                    Create Module
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid item xs={12}>
                        {isView ?
                            <ViewAdminPermissionComponent
                                permissionId={permission_id}
                                setView={setView}
                                data={module_data}
                                getAdminPermissionsFunction={getAdminPermissionsFunction}
                                isLoading={isLoading}
                                functions={functions}
                            />
                            :
                            <AdminPermissionsComponent
                                setView={setView}
                                setPermissionId={setPermissionId}
                                setModuleData={setModuleData}
                                getAdminModules={getAdminModules}
                                isLoading={isLoading}
                                modules={modules}

                            />
                        }
                    </Grid>
                </Grid>
            }



            {openCreatePermission &&
                <CreatePermissionDialog
                    openCreatePermission={openCreatePermission}
                    handleClose={handleCloseCreatePermission}
                    data={module_data}
                    isView={isView}
                    permissionId={permission_id}
                    getAdminPermissionsFunction={getAdminPermissionsFunction}
                    getAdminModules={getAdminModules}
                    modules={modules}
                />
            }

            {openCreateModule &&
                <CreateModuleDialog
                    openCreateModule={openCreateModule}
                    handleClose={handleCloseCreateModule}
                    data={module_data}
                    isView={isView}
                    permissionId={permission_id}
                    getAdminPermissionsFunction={getAdminPermissionsFunction}
                    getAdminModules={getAdminModules}
                    modules={modules}
                />
            }

        </Box>
    )
}

export default AdminPermissionManagementComponent
