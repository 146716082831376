import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";
import {ThreeDots} from "react-loader-spinner";
import BusinessAppConfigService from "../../../services/api/business-app-config";

function DeleteConfigDialog (props){

    const {openDeleteConfig, handleClose, list, page} = props
    const breakpoints = useWidth()
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleting, setIsDeleting] = useState(false)


    const handleDeleteConfig = () => {

        let config_key = list?.config_key;

            setIsDeleting(true)

            BusinessAppConfigService.deleteConfig(config_key).then((res)=>{
                console.log('delete config res herexxx', res)
                
                setIsDeleting(false)
                if(res.data.success){
                    enqueueSnackbar(res.data.data, { 
                        variant: 'success',
                        autoHideDuration: 3000
                    });
                    handleClose();
                    props.getListConfig(page);
                }
            }).catch(()=>{
                setIsDeleting(false)
            })
    }

    return(
        <Dialog open={openDeleteConfig} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Delete Config
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    You are about to delete <strong>{list?.config_key}</strong>. Please confirm. 
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleClose} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button onClick={handleDeleteConfig} variant="contained" 
                 startIcon={isDeleting &&
                    <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                }>
                     {isDeleting ? 'Deleting' :  'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteConfigDialog