import { Box, Button, Typography } from "@mui/material"
import DashboardComponent from "../components/dashboard/dashboard.component"
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useNavigate } from "react-router-dom";
import useBusiness from "../hooks/useBusiness";
import useAnalytics from "../hooks/useAnalytics";
import { useEffect, useState } from "react";
import ForbiddenScreen from "./ForbiddenScreen";
import useWidth from "../hooks/useWidth";

function DashboardScreen() {

    const navigate = useNavigate();
    const breakpoints = useWidth();

    // HOOKS
    const { businessList, isLoadingBusinessList, getAllBusinessList, getTransactionCounts, counts, loadingCounts, error } = useBusiness();
    const { countePerYear, getTransactionPerYear, pagination, loading, setLoading, getTopBusiness, businesses, error: errorTopBusiness } = useAnalytics();

    console.log("error dashboard", error)

    // STATE

    const [page, setPage] = useState(1)


    useEffect(() => { getAllBusinessList(); }, [])

    useEffect(() => { getTransactionCounts() }, [])

    useEffect(() => { getTransactionPerYear() }, [])

    useEffect(() => { getTopBusiness(page) }, [page])

    const handleChangePagination = (event, page) => {
        setPage(page);
        setLoading(true);
    }


    return (
        <Box>
            
            <Box sx={{ paddingBottom: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, marginRight: 2 }}>
                    Dashboard
                </Typography>
            </Box>

            <DashboardComponent
                businesses={businesses}
                loadingCounts={loadingCounts}
                counts={counts}
                loading={loading}
                countePerYear={countePerYear}
                breakpoints={breakpoints}
                businessList={businessList}
                isLoadingBusinessList={isLoadingBusinessList}
                error={error}
                errorTopBusiness={errorTopBusiness}
            />
           
        </Box>
    )
}
export default DashboardScreen