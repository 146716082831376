import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useWidth from "../../../hooks/useWidth";
import EmailAuthenticationService from "../../../services/api/email-authentication";


function RevokeConfirmationDialogComponent (props){

    const {openRevokeConfirmationDialog, handleCloseRevokeConfirmationDialog, authenticationDetails} = props
    const breakpoints = useWidth()
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleRevokeEmail = (id) => {
        EmailAuthenticationService.revokeEmailSender(id).then((res)=>{
            console.log('revoke email res', res)
            if(res.data.success){
                enqueueSnackbar("Email has been revoked.", { 
                    variant: 'success',
                    autoHideDuration: 3000
                }); 
                handleCloseRevokeConfirmationDialog()
                window.location.reload();
            }
        }).catch((err)=>{
            setError(err.response.statusText);
            enqueueSnackbar(err.response.data.error, { 
                variant: 'error',
                autoHideDuration: 3000
            }); 
        })
    }

    return(
        <Dialog open={openRevokeConfirmationDialog} onClose={handleCloseRevokeConfirmationDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
            <DialogTitle>
                Revoke
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center', fontSize: 14}}>
                    Revoke approved email <strong>{authenticationDetails.email}</strong>?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3}}>
                <Button onClick={handleCloseRevokeConfirmationDialog} sx={{color: 'black', textTransform: 'none'}}>Cancel</Button>
                <Button variant="contained" onClick={()=> handleRevokeEmail(authenticationDetails.id)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
export default RevokeConfirmationDialogComponent