import {useEffect, useState} from  "react";
import {useSnackbar} from "notistack";
import TemplateService from "../services/api/templates";

const useSignature = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isAddingSignature, setAddingSignature] = useState(false);
    const [isFetchingSignatureList, setFetchingSignatureList] = useState(false);
    const [signatureList, setSignatureList] = useState(null);
    const [isUpdatingTemplateSignature, setUpdatingTemplateSignature] = useState(false);
    const [isFetchingTemplateSignature, setFetchingTemplateSignature] = useState(false);
    const [tempSignatureDetails, setTempSignatureDetails] = useState(null);
    const [isDeletingTemplateSignature, setDeletingTemplateSignature] = useState(false);
    const [isAssigningSignature, setIsAssigningSignature] = useState(false);
    const [newAssignedSignature, setNewAssignedSignature] = useState(null);
    const [isRemovingSignature, setIsRemovingSignature] = useState(false);
    const [removedAssignedSignature, setRemovedAssignedSignature] = useState(null);
    const [isFetchingTemplateSignatureList, setIsFetchingTemplateSignatureList] = useState(false);
    const [templateSignatureList, setTemplateSignatureList] = useState(null);
    const [isFetchingTemplateSignatureLimit, setIsFetchingTemplateSignatureLimit] = useState(false);
    const [templateSignatureLimit, setTemplateSignatureLimit] = useState(false);
    const [isConfiguringSignatureLimit, setIsConfiguringSignatureLimit] = useState(false);
    const [configuredSignatureLimit, setConfiguredSignatureLimit] = useState(null);

   
    const createSignature = (editedSignatureFile, email, project_id, setOpenAddSignature, setIsSignatureCreated) => {
        setAddingSignature(true);

        TemplateService.createSignature(editedSignatureFile, email, project_id).then((res) => {
            if (res.data.success) {
                console.log("success create signature aaaa", res.data);
                setAddingSignature(false);
                setOpenAddSignature(false);
                setIsSignatureCreated(true);
                // window.location.reload();

                enqueueSnackbar("Successfully added signature", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error) => {
            console.log("createSignature error xxxx", error);

            setAddingSignature(false);
            setOpenAddSignature(false);

            showAlertErrorMessage(error)

        })
    }

    const getSignatureList = (projectId) => {
        setFetchingSignatureList(true);

        TemplateService.getSignatureList(projectId).then((res) => {
            console.log("getSignatureList data xxxx", res);

            setSignatureList(res.data);
            setFetchingSignatureList(false);

        }).catch((error) => {
            console.log("getSignatureList error xxxx", error);

            setFetchingSignatureList(false);
        })
    }

    const updateTemplateSignature = (formData, setOpenEditSignature, setSignatureUpdated) => {
        setUpdatingTemplateSignature(true);

        TemplateService.updateTemplateSignature(formData).then((res) => {
            console.log("updateTemplateSignature data xxxx", res);
            
            if (res.data.success) {
                let updated_signature = res.data.data

                setUpdatingTemplateSignature(false);
                setOpenEditSignature(false);
                setSignatureUpdated({isUpdated: true, data: updated_signature})
                // window.location.reload();

                enqueueSnackbar("Successfully updated signature", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error) => {
            console.log("updateTemplateSignature error xxxx", error);

            setUpdatingTemplateSignature(false);
            setOpenEditSignature(false);

            showAlertErrorMessage(error)

        })
    }

    const viewTemplateSignature = (id) => {
        setFetchingTemplateSignature(true);

        TemplateService.viewTemplateSignature(id).then((res) => {
            console.log("viewTemplateSignature data xxxx", res);

            if (res.data.success) {
                setTempSignatureDetails(res.data.data);
                setFetchingTemplateSignature(false);
            }
            
        }).catch((error) => {
            console.log("viewTemplateSignature error xxxx", error);

            setFetchingTemplateSignature(false);
        })
    }

    const deleteTemplateSignature = (id, setOpenDeleteTempSignatureDialog, setSignatureDeleted) => {
        setDeletingTemplateSignature(true);

        TemplateService.deleteTemplateSignature(id).then((res) => {
            console.log("deleteTemplateSignature data xxxx", res);

            if (res.data.success) {
                setDeletingTemplateSignature(false);
                setOpenDeleteTempSignatureDialog(false);
                setSignatureDeleted({isDeleted: true, id})

                enqueueSnackbar("Successfully deleted signature", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
                // window.location.reload();
            }
            
        }).catch((error) => {
            console.log("deleteTemplateSignature error xxxx", error);

            setDeletingTemplateSignature(false);

            showAlertErrorMessage(error)

        })
    }

    const assignTemplateSignature = (template_id, signature_id) => {
        setIsAssigningSignature(true);

        TemplateService.assignTemplateSignature(template_id, signature_id).then((res) => {
            setIsAssigningSignature(false);

            if (res.data.success) {
                console.log("success assign signature aaaa", res.data);
                setNewAssignedSignature(res.data)
                enqueueSnackbar("Successfully assigned signature", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error) => {
            console.log("createSignature error xxxx", error);

            setIsAssigningSignature(false);

            showAlertErrorMessage(error)

        })
    }

    const removeTemplateSignature = (template_id, signature_id) => {
        setIsRemovingSignature(true);

        TemplateService.removeTemplateSignature(template_id, signature_id).then((res) => {
            setIsRemovingSignature(false);

            if (res.data.success) {
                console.log("success remove signature aaaa", res.data);
                setRemovedAssignedSignature(res.data)

                enqueueSnackbar("Successfully remove signature", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error) => {
            console.log("createSignature error xxxx", error);

            setIsRemovingSignature(false);

            showAlertErrorMessage(error)
        })
    }

    const getAssignedTemplateSignatureList = (template_id) => {
        setIsFetchingTemplateSignatureList(true);

        TemplateService.getTemplateSignatureList(template_id).then((res) => {
            if (res.data.success) {
                console.log("getAssignedTemplateSignature aaaa", res.data);
                setIsFetchingTemplateSignatureList(false);
                setTemplateSignatureList(res.data)
            }
            
        }).catch((error) => {
            console.log("getAssignedTemplateSignature error xxxx", error);
            setIsFetchingTemplateSignatureList(false);
        })
    }

    const getTemplateSignatureLimit = (projectId) => {
        setIsFetchingTemplateSignatureLimit(true);

        TemplateService.getSignatureLimit(projectId).then((res) => {
            if (res.data.success) {
                console.log("getTemplateSignatureLimit aaaa", res.data);
                setIsFetchingTemplateSignatureLimit(false);
                setTemplateSignatureLimit(res.data)
            }
            
        }).catch((error) => {
            console.log("getTemplateSignatureLimit error xxxx", error);
            setIsFetchingTemplateSignatureLimit(false);
        })
    }

    const configureSignatureLimit = (projectId, limit) => {
        setIsConfiguringSignatureLimit(true);

        TemplateService.configureSignatureLimit(projectId, limit).then((res) => {
            if (res.data.success) {
                console.log("configureSignatureLimit aaaa", res.data);
                setIsConfiguringSignatureLimit(false);
                setConfiguredSignatureLimit(res.data)
                enqueueSnackbar("Successfully set signature limit", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
            }
            
        }).catch((error) => {
            console.log("configureSignatureLimit error xxxx", error);
            setIsConfiguringSignatureLimit(false);
        })
    }

    const showAlertErrorMessage = (error) => {
        if (error.response.data == "") {
            enqueueSnackbar("No project selected, please select project", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.error) {
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.error_message) {
            enqueueSnackbar(error.response.data.error_message, {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (error.response.data.message) {
            enqueueSnackbar(error.response.data.message, {
                variant: "error",
                autoHideDuration: 3000
            });
       
        } else {
            enqueueSnackbar(error.response.statusText, {
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    return {isAddingSignature, isFetchingSignatureList, signatureList, isUpdatingTemplateSignature, isFetchingTemplateSignature, tempSignatureDetails, 
        isDeletingTemplateSignature,
        createSignature, getSignatureList, updateTemplateSignature, viewTemplateSignature, deleteTemplateSignature,
        isAssigningSignature, newAssignedSignature, assignTemplateSignature, isRemovingSignature, setIsRemovingSignature, removedAssignedSignature, removeTemplateSignature, isFetchingTemplateSignatureList, getAssignedTemplateSignatureList,
        templateSignatureList, isFetchingTemplateSignatureLimit, getTemplateSignatureLimit, templateSignatureLimit, configureSignatureLimit, isConfiguringSignatureLimit,
        configuredSignatureLimit, setUpdatingTemplateSignature
    }
}

export default useSignature