import * as React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";

const ProductSelectComponent = (props) => {

    const {businessDetails, product, handleChange, isUpdatePlan, breakpoints, getProduct} = props;

    return (
        <>
            {(isUpdatePlan) ?
                <Grid sx={{display: "flex", width: "100%", height: "54px", borderRadius: "4px", padding: "14px 14px 14px 0px"}}>
                    <span style={{color: "#0178f2", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "12px" : "14px"}}>
                        Updating
                    </span>

                    <div style={{paddingLeft: "3px", paddingTop: "1px"}}>
                        <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                    </div>
                </Grid>

            :
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">current: {businessDetails?.plan ? businessDetails?.plan: "Select"}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={product}
                            label="ovcode plan label"
                            onChange={handleChange}
                        >
                            {getProduct?.value?.map((product, index) => { 
                                return (
                                    <MenuItem value={product.name}>
                                        {product.properties.displayName}
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
            }
        </> 
    )
}

export default ProductSelectComponent