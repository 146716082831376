import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPostMultipart, HttpPatch, HttpPut, HttpPut1, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const listEmailSender = (page, limit) => {

    let parameters = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.RETRIEVE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const viewEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.VIEW_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const revokeEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.REVOKE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const removeEmailSender = (id) => {

    let parameters = {
        id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const approveEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.APPROVE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const deniedEmailSender = (id, payload) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.DENIED_EMAIL_SENDER, parameters, payload, subscriptionKey);
    
    return result;
}

const resetStatus = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.RESET_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const searchByEmail = (email, page, limit) => {

    let parameters = {
        email: email,
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpGet(routes.SEARCH_BY_EMAIL, parameters, subscriptionKey);
    
    return result;
}

const createEmailSender = (email_address, project_id, user_id) => {

    let payload = {
        email_address,
        project_id,
        user_id,
    }

    console.log(" payload create email sender :", payload)

    let result = HttpPost(routes.CREATE_EMAIL_SENDER, payload);
    
    return result;
}

const EmailAuthenticationService = {
    listEmailSender: listEmailSender,
    viewEmailSender: viewEmailSender,
    revokeEmailSender: revokeEmailSender,
    removeEmailSender: removeEmailSender,
    approveEmailSender: approveEmailSender,
    deniedEmailSender: deniedEmailSender,
    resetStatus: resetStatus,
    searchByEmail: searchByEmail,
    createEmailSender: createEmailSender
}

export default EmailAuthenticationService