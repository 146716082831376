import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Chart from "../layout/Chart";
import DrawerHeader from "../layout/drawer-header";
import { Box, Paper, Typography } from "@mui/material";

function PieChartView(){

    require("highcharts/modules/exporting")(Highcharts);

    const chartOptions = {
    title: {
        text: ""
    },
    series: [
        {
        data: [[1, "Day"], [1, "Month"], [3, "Year"]],
        keys: ["y", "name"],
        type: "pie"
        }
    ]
    };

    return(
        <>
        <Chart options={chartOptions} highcharts={Highcharts} />
        </>
    )
}

function ServiceMonitoringScreen(){

    const options = {
        title: {
          text: "Monitoring"
        },
        series: [
          {
            name: 'Random data',
          data: (function () {
              // generate an array of random data
              var data = [],
                  time = (new Date()).getTime(),
                  i;
  
              for (i = -999; i <= 0; i += 1) {
                  data.push([
                      time + i * 1000,
                      Math.round(Math.random() * 100)
                  ]);
              }
              return data;
          }())
          }
        ],
        time: {
            useUTC: false
        },
        chart: {
            events: {
                load: function () {
    
                    // set up the updating of the chart each second
                    var series = this.series[0];
                    setInterval(function () {
                        var x = (new Date()).getTime(), // current time
                            y = Math.round(Math.random() * 100);
                        series.addPoint([x, y], true, true);
                    }, 1000);
                }
            }
        },
        rangeSelector: {
            buttons: [{
                count: 1,
                type: 'minute',
                text: '1M'
            }, {
                count: 5,
                type: 'minute',
                text: '5M'
            }, {
                type: 'all',
                text: 'All'
            }],
            inputEnabled: false,
            selected: 0
        },
      };

    return(
        <>
        <Box sx={{padding: '0px 20px 20px 0px'}}>
            <Typography variant="h6">
                Monitor
            </Typography>
        </Box>
         {/* <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
        /> */}
        <Paper sx={{marginTop: 2, backgroundColor:'transparent', boxShadow:'none'}}>
            <Box sx={{width: '100%', margin:'auto'}}>
            <iframe style={{width: '100%', height:'75vh'}} src="https://monitoring-kiali.gemcircle.io/kiali/console/overview?duration=60&refresh=15000"/>
            </Box>
        </Paper>
       
        </>
    )
}
export default ServiceMonitoringScreen;