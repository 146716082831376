import { Button, ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from "react";

import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ThreeDots } from 'react-loader-spinner';
import RenameIcon from "../../../icons/rename.png"
import useTemplates from "../../../hooks/useTemplates";

function TemplatesActionsPopperComponent(props){

    const { template, handleOpenEditDialog, setTemplateStateStatusFromParent, handleOpenDeleteTemplateDialog, businessIdFromURL } = props;

    const { updateAccountTemplateStatus, updatingTemplateStatus, isUpdated, viewAccountTemplate, template: templateData } = useTemplates();

    const [anchorEl, setAnchorEl] = useState(null);
    const [templateStatus, setTemplateStatus] = useState(template?.status);

    console.log("templateStatusxxx", template)

   
    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const handleDeactivateStatus = async () => {
        let deactivate = 'INACTIVE';

        let formData = new FormData();

        formData.append('template_code', template?.template_code);
        formData.append('status', deactivate );
 
        await updateAccountTemplateStatus(formData);
    }


    const handleActivateStatus = async () => {
        let activate = 'ACTIVE';

        let formData = new FormData();

        formData.append('template_code', template?.template_code);
        formData.append('status', activate );

        await updateAccountTemplateStatus(formData);
    }


    useEffect(() => {
        if (isUpdated) {
            viewAccountTemplate(template.template_code)
        }
    }, [isUpdated])
    
    useEffect(() => {
        if (templateData) {
            console.log("templateData aaa", templateData);
            const status = templateData.status
            setTemplateStateStatusFromParent(status);
            setTemplateStatus(status);
            setAnchorEl(null);
        }
    }, [JSON.stringify(templateData)])
  
    return (
        <div>
            <IconButton sx={{color: 'black'}} className="card-manage">
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>

            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                                <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={() => window.location.href = '/update-template?template_code=' + template?.template_code + '&businessId=' + businessIdFromURL }
>
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                            <EditIcon style={{width: 16, height: 16}} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Edit</Typography>
                                </MenuItem>

                            <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={handleOpenEditDialog} >
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                        <img alt="" src={RenameIcon} style={{width: 16, height: 16}}/>
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Rename</Typography>
                            </MenuItem>

                            {(template?.is_system_default > 0) ?
                                null
                            :
                                <MenuItem style={{color: 'red', fontSize: 13, fonWeight: 500}} onClick={handleOpenDeleteTemplateDialog} >
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                            <DeleteIcon style={{width: 16, height: 16}}/>
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Delete</Typography>
                                </MenuItem>
                            }

                            {(templateStatus === "DRAFT") ?
                                null
                            :

                                ((templateStatus === "ACTIVE") ?
                                    <MenuItem style={{fontSize: 13, fonWeight: 500}}>
                                        <Button variant="text" sx={{textTransform: 'none', padding: '0px !important'}}
                                            onClick={handleDeactivateStatus}
                                            startIcon={updatingTemplateStatus && <ThreeDots height="20" width="20" radius="9" color="red" ariaLabel="three-dots-loading" visible={true} />}
                                        >
                                            {!updatingTemplateStatus &&
                                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                                    <BlockOutlinedIcon style={{width: 16, height: 16, color: 'red'}}/>
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            <Typography variant="inherit" sx={{color: 'red', fontSize: 13}}>{updatingTemplateStatus ? "Deactivating" : "Deactivate"}</Typography>
                                        </Button>
                                    </MenuItem>
                                 :
                                    <MenuItem style={{fontSize: 13, fonWeight: 500}}>
                                        <Button variant="text" sx={{textTransform: 'none', padding: '0px !important'}}
                                            onClick={handleActivateStatus}
                                            startIcon={updatingTemplateStatus && <ThreeDots height="20" width="20" radius="9" color="green" ariaLabel="three-dots-loading" visible={true} />}
                                        >
                                            {!updatingTemplateStatus &&
                                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                                        <CheckCircleOutlineOutlinedIcon style={{width: 16, height: 16, color: 'green'}}/> 
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            <Typography variant="inherit" sx={{color: 'green', fontSize: 13}}>{updatingTemplateStatus ? "Activating" : "Activate"}</Typography>
                                        </Button>
                                    </MenuItem> 
                                )}
                                
                            
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

        </div>
    )
}

export default TemplatesActionsPopperComponent
