import React, {useState} from "react";
import {Skeleton} from "@mui/material";
import TemplateRowContent from "./template-row.component";

const TemplateListComponent = (props) => {

    const { enterpriseAccountTemplates, getEnterpriseProjectAccountTemplates, projectId } = props;

   

    return (
        <>
            {enterpriseAccountTemplates?.map((template, index) => {
                return (
                    <>
                        <TemplateRowContent 
                            index={index} 
                            template={template}
                            getEnterpriseProjectAccountTemplates={getEnterpriseProjectAccountTemplates}
                            projectId={projectId}
                        />
                    </>
                )
            })} 
        </>
    )
}

export default TemplateListComponent