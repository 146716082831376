import { Paper, Box, Grid, List, ListItemText, Divider, ListItem, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useWidth from "../hooks/useWidth";
import AnalyticsTabComponent from "../components/analytics/analytics-tab.component";


function AnalyticsScreen(){

  const breakpoints = useWidth();
    
    return (

      <Box sx={{mb: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
        <Grid container spacing={3} sx={{textAlign: "start", mb: 2}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6">Analytics</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AnalyticsTabComponent />
          </Grid>
        </Grid>
      </Box>
        
    );
}

export default AnalyticsScreen