import { AppBar, Box, Button, Grid, Toolbar, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OVC from '../../icons/ovcodelogo.png'
import useMsalform from '../../hooks/useMsalform';
import AuthService from '../../services/auth';

function LandingAppbarComponent (){

    const login = () => {
        AuthService.login().then((res)=>{
          window.location.href="/dashboard"; 
        })
  
        .catch((error)=>{
          console.log("login error iii", error);
        });
      }

    return(
        <AppBar position="static" sx={{backgroundColor:'transparent', boxShadow:'none'}}>
            <Toolbar>
                <Button
                    size="large"
                    edge="start"
                    aria-label="menu"
                    sx={{ mr: 2}}
                >
                    <img src={OVC} style={{height: 30}}/>
                </Button>
                <Box sx={{flexGrow: 1}}/>
                <Button sx={{textTransform:'none', color:'black'}} onClick={login}>
                    Sign In<AccountCircleIcon sx={{paddingLeft: '5px'}}/>
                </Button>
            </Toolbar>
        </AppBar>
    )
}
export default LandingAppbarComponent