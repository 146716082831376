import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState, useRef } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';
import ReactSelect from "react-select";



const CreatePermissionDialog = (props) => {

    const { openCreatePermission, handleClose, data, permissionId, isView, modules } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;

    const menuPortalTargetRef = useRef(null);


    const { loading, createPermission, createAdminPermission, createdPermission } = usePermissionManagement();

    const [moduleId, setModuleId] = useState(data?.id);
    const [selected_module, setModuleName] = useState("");
    const [permissionFunction, setPermissionFunction] = useState('');
    const [permissionDescription, setPermissionDescription] = useState('');
    const [page, setPage] = useState(1);

    const handleTextFieldChange = (e, name) => {
        if (name == "permission_function") {
            setPermissionFunction(e.target.value);

        } else if (name == "permission_description") {
            setPermissionDescription(e.target.value);
        }
    }

    const handleChangeModuleName = (selectedOption) => {
        // setModuleId(event.target.value);
        setModuleId(selectedOption.value);
        setModuleName(selectedOption);
    }

    useEffect(() => {

        if (url === "/admin-settings") {
            props.getAdminModules();
        } else {
            props.getModules();
        }


    }, [url])

    const handleCreatePermission = () => {

        let payload = {
            module_id: moduleId,
            fn: permissionFunction,
            description: permissionDescription
        }


        if (url === "/admin-settings") {
            createAdminPermission(payload);
        } else {
            createPermission(payload);
        }

    }

    useEffect(() => {

        if (createdPermission) {
            handleClose();
            console.log("createdPermission is true");
            console.log("url:", url);

            if (url === "/admin-settings") {
                console.log("url matches '/admin-settings'");

                if (isView) {
                    console.log("isView is true");
                    props.getAdminPermissionsFunction(page, permissionId);
                } else {
                    console.log("isView is false");
                    props.getAdminModules(page);
                }
            } else {
                if (isView) {
                    console.log("isView is true");
                    props.getPermissionsFunction(page, permissionId);
                } else {
                    console.log("isView is false");
                    props.getModules();
                }
            }
        } else {
            console.log("createdPermission is false");
        }


    }, [createdPermission, isView, url, page, permissionId])

    return (
        <Dialog open={openCreatePermission} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Create Permission
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Module Name :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ReactSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected_module}
                            onChange={handleChangeModuleName}
                            options={modules.map(data => ({ value: data.id, label: data.module_name }))}
                            isSearchable
                            isClearable  // This enables the clearable functionality
                            menuPortalTarget={menuPortalTargetRef.current}
                            menuPosition="fixed"
                            placeholder={
                                (isView) ?
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        {data.module_name}
                                    </Typography>
                                :

                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Module
                                    </Typography>
                            }
                            styles={{
                                menuPortal: provided => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menu: provided => ({
                                    ...provided,
                                    zIndex: 9999,
                                    fontSize: 12
                                }),
                                control: base => ({
                                    ...base,
                                    height: 56,
                                    backgroundColor: 'transparent',
                                    fontSize: 12
                                }),
                            }}
                        />

                    </Grid>


                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Function :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={permissionFunction}
                            onChange={(e) => handleTextFieldChange(e, "permission_function")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={permissionDescription}
                            onChange={(e) => handleTextFieldChange(e, "permission_description")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleCreatePermission()}
                    startIcon={loading &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {loading ? "Creating" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreatePermissionDialog