import {Table, TextField, Stack, InputAdornment, Box, Button, ClickAwayListener, Grid, IconButton, ListItemIcon,Pagination, MenuItem, MenuList, Paper, Popper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Masonry } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import React, { useEffect, useState } from "react";
import UpdateRoleDialog from "../components/rbac/dialogs/update-role.dialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useRolesManagement from "../hooks/useRolesManagement";
import { format } from 'date-fns';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteRoleDialog from "../components/rbac/dialogs/delete-role.dialog";
import { ThreeDots } from "react-loader-spinner";
import RolesListSkeleton from "../components/skeletons/roles-list-skeleton.component";
import ReactSelect from "react-select";
import SearchIcon from "@mui/icons-material/Search";

function CardRolesHeader(props) {

    const { data, setStatus, retrieved } = props;

    const { updateRoleStatus,  updatingRoleStatus, updatedRoleStatus, active } = useRolesManagement();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdateRole, setOpenUpdateRole] = useState(false);
    const [openDeleteRole, setOpenDeleteRole] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState(data?.active);


    const handleOpenUpdateRole = () => {
        setOpenUpdateRole(true);
    }

    const handleClosenUpdateRole = () => {
        setOpenUpdateRole(false);
    }

    const handleOpenDeleteRole = () => {
        setOpenDeleteRole(true);
    }

    const handleCloseDeleteRole = () => {
        setOpenDeleteRole(false);
    }

 
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    const handleUpdateRoleStatus = () => {
        const newStatus = data?.active === true ? 'false' : 'true';
        updateRoleStatus(data?.id, newStatus);
    }


    useEffect(() => {
        if (updatedRoleStatus) {
            handleClick();
            if(data?.id === data?.id){
                setStatus(active);
                setUpdatedStatus(active);
            }
        }
    }, [updatedRoleStatus]);

    console.log("updatedStatusxxx",updatedStatus)
    

    

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <div>
                    <IconButton sx={{ color: 'black' }}>
                        <MoreVertIcon aria-describedby={id} style={{ cursor: "pointer" }} onClick={handleClick} />
                    </IconButton>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <Paper style={{ borderRadius: 2 }}>
                            <ClickAwayListener onClickAway={handleClick}>
                                <MenuList>
                                    <MenuItem onClick={handleUpdateRoleStatus} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        
                                        {updatingRoleStatus ?
                                            <Button variant="text"
                                                startIcon={
                                                    <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                                                }
                                                sx={{color: '#0178f2'}}
                                            >
                                                Updating
                                            </Button>
                                        :  
                                            <>
                                                <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                                    <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: updatedStatus === true ? 'red' : 'green' }}>
                                                        { updatedStatus === true ?
                                                            <NotInterestedIcon style={{ width: 16, height: 16 }} /> 
                                                        :
                                                            <CheckCircleOutlineIcon style={{ width: 16, height: 16 }} />
                                                        }
                                                    </IconButton>
                                                </ListItemIcon>
                                                <Typography variant="inherit">{updatedStatus === true ? 'DEACTIVATE' : 'ACTIVATE'}</Typography>
                                            </>
                                        }
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenUpdateRole} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: '#0178f2' }}>
                                                <EditIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Update Role</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenDeleteRole} style={{ color: '#000', fontSize: 13, fonWeight: 500 }} >
                                        <ListItemIcon style={{ padding: 0, minWidth: 30 }}>
                                            <IconButton style={{ paddingTop: 6, paddingBottom: 6, color: 'red' }}>
                                                <DeleteIcon style={{ width: 16, height: 16 }} />
                                            </IconButton>
                                        </ListItemIcon>
                                        <Typography variant="inherit">Delete Role</Typography>
                                    </MenuItem>
                                   
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </div>
            </Stack>

           {openUpdateRole &&
                <UpdateRoleDialog 
                    openUpdateRole={openUpdateRole}
                    handleClose={handleClosenUpdateRole}
                    data={data}
                    retrieved={retrieved} 
                    getAllRoles={props.getAllRoles}
                    getRoles={props.getRoles}
                />
           }

           {openDeleteRole &&
                <DeleteRoleDialog 
                    openDeleteRole={openDeleteRole}
                    handleClose={handleCloseDeleteRole}
                    data={data}
                    retrieved={retrieved} 
                    getAllRoles={props.getAllRoles}
                    getRoles={props.getRoles}
                />
           }

        </Stack>
    )
}



const TableHeader = () => {

    const tablecell = [ 
        {label : 'Role Name'},
        {label : 'Description'},
        {label : 'Status'},
        {label : 'Last Date Modified'},
        {label : 'Action'}
    ]

    return(
        <TableHead >
            <TableRow>
                {tablecell.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: '#0178f2'}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const RolesContent = (props) => {

    const { setView, data, setDataRole, retrieved } = props;

    const [status, setStatus] = useState(data?.active);

    console.log("status", status)
    console.log("data", data)

    return(
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
            <TableCell component="th" scope="row" sx={{cursor:'pointer',color: "#0178f2"}}  onClick={() => [setView(true), setDataRole(data)]} >
                {data?.role}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {data?.description}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ color: status === true ? 'green' : 'red' }}>
                {status === true ? 'ACTIVE' : 'INACTIVE'}
            </TableCell>
            <TableCell component="th" scope="row"  >
                {format(new Date(data?.updated_at), 'PP')}
            </TableCell>
            <TableCell>
                <CardRolesHeader 
                    data={data} 
                    setStatus={setStatus}
                    retrieved={retrieved} 
                    getAllRoles={props.getAllRoles}
                    getRoles={props.getRoles}
                />
            </TableCell>
        </TableRow>
    )
}

const RolesScreen = (props) => {

    const { setView, setDataRole, isLoading,  rolesList, pagination, retrieved, isFetchingRoles,
        enterpriseRoleList,  isSearchingRoles, searchedRoles, isSearchRolesToggle, isSearchedResultsEmpty } = props;
  

    const [page, setPage] = useState(1);

    const [filterLabel, setFilterLabel] = useState(null);
    const [filter, setFilter] = useState(null);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [roleFilterValue, setRoleFilterValue] = useState(null);
    const [statusFilterValue, setStatusFilterValue] = useState(null);
    const [statusFilterLabel, setStatusFilterLabel] = useState(null);
    const [descriptionFilterValue, setDescriptionFilterValue] = useState("");

    useEffect(() => {
        props.getAllRoles();
    },[])

    useEffect(() => {
        if(retrieved){
            props.getRoles(page);
        }
    },[retrieved, page])

    useEffect(() => {
        getRolesOptions();
    }, [JSON.stringify(enterpriseRoleList)])

    useEffect(() => {
        if (filter && roleFilterValue) {
            props.searchRoles(filter, roleFilterValue, page);

        } else if (filter && statusFilterValue) {
            props.searchRoles(filter, statusFilterValue, page);
        }
    }, [roleFilterValue, statusFilterValue])

    const handleChangePagination = (event,page) => {
        setPage(page);
        props.getRoles(page);
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999, 
        }),
        control: (styles) => ({
            ...styles, 
            backgroundColor: "#fff", 
            minHeight: "20px", 
            width: 250, 
            fontSize: 13,
        })
    }

    const getRolesOptions = () => {
        if (enterpriseRoleList) {
            const role_options = [...enterpriseRoleList?.map((role) => ({
                value: role?.role,
                label: role?.role,
            }))];

            setRolesOptions(role_options);
        }
    }

    const filterOptions = [
        {value: "role_name", label: "Role"},
        {value: "description", label: "Description"},
        {value: "status", label: "Status"}
    ]

    const statusOptions = [
        {value: "1", label: "ACTIVE"},
        {value: "0", label: "INACTIVE"},
    ]

    const handleSelectFilterOption = (selectedOption) => {
        setRoleFilterValue(null);
        setStatusFilterValue(null);
        setDescriptionFilterValue("");

        if (selectedOption) {
            setFilter(selectedOption.value);
            setFilterLabel(selectedOption.label);

        } else {    
            setFilter(null);
            setFilterLabel(null);
            
            handleClearFilter();
        }
    }

    const handleSelectRolesValue = (selectedOption) => {
        if (selectedOption) {
            setRoleFilterValue(selectedOption.value);
            setStatusFilterValue(null);
        }
    }

    const handleSelectStatusValue = (selectedOption) => {
        if (selectedOption) {
            setStatusFilterValue(selectedOption.value);
            setStatusFilterLabel(selectedOption.label);
            setRoleFilterValue(null);
        }
    }

    const handleClearFilter = (e) => {
        setFilter(null);
        setFilterLabel(null);
        props.getAllRoles(page);
    }

    const handleTextfield = (e) => {
        setRoleFilterValue(null);
        setStatusFilterValue(null);
        setDescriptionFilterValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (filter && descriptionFilterValue) {
                props.searchRoles(filter, descriptionFilterValue, page);
            }  
        }
    }

    const handleChangePaginationForSearch = (event, page) => {
        setPage(page);

        if (filter && roleFilterValue) {
            props.searchRoles(filter, roleFilterValue, page);

        } else if (filter && statusFilterValue) {
            props.searchRoles(filter, statusFilterValue, page);

        } else if (filter && descriptionFilterValue) {
            props.searchRoles(filter, descriptionFilterValue, page);
        }
    }

    return (

        <Box sx={{width: '100%', margin: 'auto'}}>
            <Grid item xs={6} sm={6} sx={{mt: "-32px"}}>
                <Stack direction="row" spacing={1} sx={{width: "100%"}}>
                    <ReactSelect
                        className="basic-single"
                        placeholder={<>
                            <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                Filter
                            </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                {
                                     (filterLabel === "Role") ? 
                                     <Typography sx={{color: "#000",  fontSize: 12}}>
                                         Role
                                     </Typography>
                                 :
                                     (filterLabel === "Status") ? 
                                         <Typography sx={{color: "#000",  fontSize: 12}}>
                                             Status
                                         </Typography>
                                 :
                                     (filterLabel === "Description") ? 
                                         <Typography sx={{color: "#000",  fontSize: 12}}>
                                             Description
                                         </Typography>
                                 :
                                     (filterLabel === null) ? 
                                         "Select Filter"
                                 :  
                                     null
                                                   
                                }</i>
                        </>}
                        isSearchable={true}
                        isRtl={false}
                        options={filterOptions}
                        onChange={selectedOption => handleSelectFilterOption(selectedOption)}
                        // styles={customStyles}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 38,
                                borderRadius: 100,
                                backgroundColor: 'transparent',
                                width: 250,
                                fontSize: 12
                            }),
                            placeholder: base => ({
                                ...base,
                                fontSize: 12
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                fontSize: 12
                            }),
                        }}
                        isClearable
                    />

                    {(filterLabel === null || filterLabel === "Role") &&
                        <ReactSelect
                            className="basic-single"
                            placeholder={
                                (filterLabel === "Role" && roleFilterValue) ? 
                                    <Typography sx={{color: "#000",  fontSize: 12}}>
                                        {roleFilterValue}
                                    </Typography>
                            :
                                "Search Role"
                            }
                            isSearchable={true}
                            isRtl={false}
                            options={rolesOptions}
                            value={roleFilterValue}
                            onChange={selectedOption => handleSelectRolesValue(selectedOption)}
                            // styles={customStyles}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 38,
                                    borderRadius: 100,
                                    backgroundColor: 'transparent',
                                    width: 250,
                                    fontSize: 12
                                }),
                                placeholder: base => ({
                                    ...base,
                                    fontSize: 12
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 99999,
                                    fontSize: 12
                                }),
                            }}
                        />
                    }

                    {filterLabel === "Status" &&
                        <ReactSelect
                            className="basic-single"
                            placeholder={
                                (filterLabel === "Status" && statusFilterValue) ? 
                                    <Typography sx={{color: "#000",  fontSize: 12}}>
                                        {statusFilterLabel}
                                    </Typography>
                            :
                                "Search Status"
                            }
                            isSearchable={true}
                            isRtl={false}
                            options={statusOptions}
                            value={statusFilterLabel}
                            onChange={selectedOption => handleSelectStatusValue(selectedOption)}
                            // styles={customStyles}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: 38,
                                    borderRadius: 100,
                                    backgroundColor: 'transparent',
                                    width: 250,
                                    fontSize: 12
                                }),
                                placeholder: base => ({
                                    ...base,
                                    fontSize: 12
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 99999,
                                    fontSize: 12
                                }),
                            }}
                        />
                    }

                    {filterLabel === "Description" &&
                        // <TextField
                        //     value={descriptionFilterValue}
                        //     sx={{"& .MuiInputBase-root": {height: 37},
                        //         background: "#fff", width: 250
                        //     }}
                        //     variant="outlined"
                        //     autoComplete="off"
                        //     placeholder="Input description..."
                        //     onChange={handleTextfield}
                        //     onKeyDown={handleKeyPress}
                        //     inputProps={{
                        //         style: {
                        //             minHeight: "4.9px",
                        //             height: "4.9px"
                        //         }
                        //     }}
                        //     InputProps={{
                        //         endAdornment: (
                        //             <InputAdornment position="end">
                        //                 <IconButton edge="end" sx={{cursor: "default"}}>
                        //                     <SearchIcon />
                        //                 </IconButton>
                        //             </InputAdornment>
                        //         ),
                        //     }}
                        // /> 
                        <TextField
                        value={descriptionFilterValue}
                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 380 } }}
                        variant="outlined"
                        autoComplete="off"
                        onChange={handleTextfield}
                        onKeyDown={handleKeyPress}
                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                        placeholder={"Input Description..."}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{'Description'}:</p></InputAdornment>,
                        }}
                    />
                    }
                </Stack>
            </Grid>

            <TableContainer component={Paper} sx={{border: "1px solid #8080807a", borderRadius: 2,  width: "100%", mt: 2}}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 650}}>
                    <TableHeader />
    
                    {(isLoading || isSearchingRoles) ?
                        <TableBody><RolesListSkeleton /></TableBody>
                    :
                        (isSearchRolesToggle && !isSearchedResultsEmpty) ?
                            <TableBody sx={{width: "100%"}}> 
                                {searchedRoles?.data.map((data, index) => ( 
                                    <RolesContent  
                                        setView={setView} 
                                        data={data?.role} 
                                        setDataRole={setDataRole}
                                        retrieved={retrieved} 
                                        getAllRoles={props.getAllRoles}
                                        getRoles={props.getRoles} 
                                    />
                                ))}
                            </TableBody>
                    :        
                        (isSearchRolesToggle && isSearchedResultsEmpty) ?
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography variant="subtitle2" sx={{fontSize: 14, textAlign: "center", fontWeight: 500}}>
                                        No searched result(s) found.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    : 
                        (!isSearchRolesToggle) && 
                            <TableBody sx={{width: "100%"}}> 
                                {rolesList.map((data, index) => (
                                    <RolesContent 
                                        setView={setView} 
                                        data={data} 
                                        setDataRole={setDataRole}
                                        retrieved={retrieved} 
                                        getAllRoles={props.getAllRoles}
                                        getRoles={props.getRoles} 
                                    />
                                ))}
                            </TableBody>
                    }
                </Table>

                { !isLoading && rolesList?.length !== 0 && !isSearchingRoles && !isSearchRolesToggle && isFetchingRoles &&
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePagination} 
                        style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                }  

                {/* pagination for search roles */}
                {(isSearchRolesToggle && !isSearchedResultsEmpty && !isLoading && !isFetchingRoles) && 
                    <Pagination
                        count={pagination} 
                        page={page} 
                        onChange={handleChangePaginationForSearch} 
                        style={{margin:'auto', width:'fit-content', paddingTop: 30, paddingBottom: 30 }}
                    />
                }
            </TableContainer>
        </Box>

    )
}

export default RolesScreen