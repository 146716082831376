import {useState, useEffect} from "react";
import {Autocomplete, TextField} from "@mui/material";
import { debounce } from 'lodash';
import useBusiness from "../../hooks/useBusiness";

const SelectBusinessDropdownComponent = (props) => {

    const {hasBusinessAndProjectIds, defaultBusinessId, onSelectBusiness} = props

    const {businessList, getBusinessList, pagination, isLoadingBusinessList, searchByBusinessName, getSearchByBusinessName, getBusinessById, businessDetails} = useBusiness();

    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentBusiness, setCurrentBusiness] = useState(null);

    useEffect(() => {
        getBusinessList(currentPage)
    }, [])

    useEffect(() => {
        if (defaultBusinessId) {
            getBusinessById(defaultBusinessId)
        }
    }, [defaultBusinessId])

    useEffect(() => {
        if (businessDetails) {
            setCurrentBusiness(businessDetails)
        }
    }, [businessDetails])

    useEffect(() => {
        if (businessList) {
            console.log("business list aa", businessList);
            setOptions((prevOptions) => {
                const mergedArr = [...prevOptions, ...businessList]
                const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
                return updatedOptions
            });
        }
    }, [JSON.stringify(businessList)])

    useEffect(() => {
        if (searchByBusinessName) {
            setOptions((prevOptions) => {
                const mergedArr = [...prevOptions, ...searchByBusinessName]
                const updatedOptions = removeDuplicatesOptionsArr(mergedArr)
                return updatedOptions
            });
        }
    }, [JSON.stringify(searchByBusinessName)])

    const removeDuplicatesOptionsArr = (arr) => {
        return Array.from(
            new Set(arr.map((obj) => JSON.stringify(obj)))
        ).map((jsonString) => JSON.parse(jsonString));
    }

    const debouncedFetchData = debounce(async (query) => {
        getSearchByBusinessName(query)
      }, 1000);
    
      useEffect(() => {
        if (searchValue !== "" && !options.some((option) => option.business_name.includes(searchValue))) {
            debouncedFetchData(searchValue);
        }
    
        // Cleanup debounced/throttled function on component unmount
        return () => {
          debouncedFetchData.cancel();
        };
      }, [searchValue]);

    const fetchMoreData = () => {
        if (currentPage < pagination) {
            let page = currentPage + 1
            setCurrentPage(page)
            getBusinessList(page);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setSearchValue(newInputValue);
    };

    const handleOnChange = (event, selectedOption) => {
        console.log("selectedOption business aa", selectedOption);
        setCurrentBusiness(selectedOption)
        onSelectBusiness(selectedOption)
    }

    return(
        <Autocomplete
            id="infinite-scroll-autocomplete"
            options={options}
            loading={isLoadingBusinessList}
            onInputChange={handleInputChange}
            onChange={handleOnChange}
            defaultValue={currentBusiness}
            value={currentBusiness}
            disabled={hasBusinessAndProjectIds}
            getOptionLabel={(option) => option.business_name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField {...params} label="Select Business" variant="outlined" fullWidth />
            )}
            ListboxProps={{
                onScroll : (event) => {
                    const listboxNode = event.currentTarget
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        fetchMoreData()
                    }
                },
            }}
        />
    )
}

export default SelectBusinessDropdownComponent