import React from "react";
import {Skeleton, Card, Grid, Typography} from "@mui/material";

const UpdateBusinessAccountSkeletonComponent = ({breakpoints}) => {

    return (
        <Grid container spacing={2} sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "24px 16px" : "24px 64px 16px 64px"}}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 110, borderRadius: "4px/6.7px"}} />
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
            </Grid>

            <Grid item xs={12} sx={{mb: 1}}>
                <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography sx={{fontSize: 17, mb: 3}}>
                    Company Logo
                </Typography>

                <Grid container>
                    <Grid item xs={6}>
                        <Card sx={{height: "70px", borderColor: '#8080807a', mb: 1}}>
                            <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: "100%", borderRadius: "4px/6.7px"}} />
                        </Card>        
                    </Grid>

                    <Grid item xs={6} sx={{alignSelf: 'center'}}>
                        <Skeleton variant="rectangular" style={{marginLeft: "20px", backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign: "end", mt: 2}}>
                <Skeleton variant="rectangular" style={{marginLeft: "auto", width: (breakpoints == "xs" || breakpoints == "sm") ? "42%" : "30%", backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
            </Grid>
        </Grid>
    )
}

export default UpdateBusinessAccountSkeletonComponent