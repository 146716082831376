import {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogTitle, Divider, Box, Button, InputAdornment} from "@mui/material";
import {ThreeDots} from "react-loader-spinner";

import SelectBusinessUserDropdownComponent from "../../common/select-business-user-dropdown.component";

import useUsers from "../../../hooks/useUsers";


const SelectProjectUserDialogComponent = (props) => {

    const {openSelectUser, handleClose, businessId, projectId, setOpenChangeSignature, onSelectUser} = props;

    const [currentUser, setCurrentUser] = useState(null)

    const {isFetchingUser, getUserByID, userDetails} = useUsers()

    useEffect(() => {
        if (userDetails) {
            let hasSignature = userDetails.signature == null ? false : true
            if (!hasSignature) {
                setOpenChangeSignature(true)
                onSelectUser(userDetails)
                handleClose()
                return
            }
            console.log("dont exec");
            handleClose()
            onSelectUser(userDetails)
        }
    }, [JSON.stringify(userDetails)])

    const handleOnSelectUser = (user) => {
        setCurrentUser(user)
    }

    const handleSubmit = () => {
        console.log("current user aaaa", currentUser);
        getUserByID(currentUser.id)
    }

    return(
        <Dialog open={openSelectUser} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5} }}>
            <DialogTitle style={{display: "flex"}}>
                Select User
            </DialogTitle>

            <Divider />
            <DialogContent sx={{padding: 3, paddingTop: 2}}>
                <SelectBusinessUserDropdownComponent businessId={businessId} projectId={projectId} onSelectUser={handleOnSelectUser} />
                <br />
                <Box sx={{paddingTop: 3.2, textAlign: "end"}}>
                    <Button onClick={handleClose} sx={{color: "#000", textTransform: "none", fontSize: 16, mr: 2}}>
                        Cancel
                    </Button>

                    <Button variant="contained" sx={{backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 16, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={(e) => handleSubmit(e)}
                    disabled={!currentUser || isFetchingUser}
                    startIcon={ isFetchingUser ? <ThreeDots  height="20"  width="20"   radius="9" color="#ffff"  ariaLabel="three-dots-loading"  visible={true} /> : null } 
                    >
                    Proceed
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SelectProjectUserDialogComponent