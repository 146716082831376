const mbConverter = (fileSize) => {
  // Check if fileSize is provided and valid
  if (!fileSize || isNaN(fileSize)) {
      return 0; // Return 'N/A' if fileSize is not provided or invalid
  }

  // Convert file size to kilobytes
  const fileSizeInKB = fileSize / 1024;

  // Determine display size
  let displaySize;
  if (fileSizeInKB < 1024) {
    displaySize = fileSizeInKB.toFixed(2) + ' KB';
} else {
    const fileSizeInMB = fileSizeInKB / 1024;
    displaySize = fileSizeInMB.toFixed(2) + ' MB';
}

  return displaySize; // Return the formatted display size
};

export { mbConverter };
