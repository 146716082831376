import React from "react";
import {Card, ListItem, ListItemAvatar, Avatar, ListItemText, Typography} from "@mui/material";

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        const darkValue = Math.min(128, value);
        
        color += `00${darkValue.toString(16)}`.slice(-2);
    }

    return color;
}

function stringAvatar(name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()}`,
    };
}

const DashboardProjectCreditComponent = (props) => {

    const {breakpoints, project, index, isLast} = props;

    return (
        <Card  sx={{mt: (index == 0) ? 0 : "14px", mb: (isLast) ? "1px" : "14px", cursor: "default", display: "flex"}}>
            <ListItem>
                {(project?.accountProject.logo) ? 
                    (
                        <ListItemAvatar>
                            <Avatar alt="" src={project?.accountProject.logo} sx={{height: 40, width: 40}} variant="rounded" />
                        </ListItemAvatar>
                    )

                : 
                    (
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(project && project?.accountProject.application_name)} style={{height: 50, width: 50}} />
                        </ListItemAvatar>
                    )
                }

                <ListItemText
                    primary={
                        <Typography sx={{color: "#000", fontSize: 14}}>
                            {project?.accountProject.application_name}
                        </Typography>
                    }
                />
            </ListItem>

            <Typography sx={{color: "#000", textAlign: "end", padding: "17px 22px 8px", fontSize: 16}}>
                {project?.credit}
            </Typography>
        </Card>
    )
}

export default DashboardProjectCreditComponent