import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import ListOfBusinessAccountComponent from "../components/account/list-of-business-account.component";
import useWidth from "../hooks/useWidth";
import ForbiddenScreen from "./ForbiddenScreen";

const AccountManagementListScreen = () => {

    const breakpoints = useWidth();
    const [forbidden, setForbidden] = useState(false);

    useEffect(() => {
        localStorage.removeItem("selectedTab");
    }, [])

    
    return (
        <Box sx={{mb: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
            {forbidden ?
                <>
                    <Grid container spacing={3} sx={{textAlign: "start", mb: 2}}>
                        <Grid item xs={9} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="h6">
                                Enterprise
                            </Typography>
                        </Grid>
                    </Grid>
                    
                    <ForbiddenScreen />
                </>
            :
                <>
                    <Grid container spacing={3} sx={{textAlign: "start", mb: 2}}>
                        <Grid item xs={9} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="h6">
                                Enterprise
                            </Typography>
                        </Grid>
                    </Grid>

                    <ListOfBusinessAccountComponent breakpoints={breakpoints} setForbidden={setForbidden} />
                </>
            }
        </Box>
    )
}

export default AccountManagementListScreen