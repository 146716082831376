
import React, {useEffect, useState} from "react";
import {styled, useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import {Tooltip, Typography, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Divider, List} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from "../icons/dashboard.png";
import AccountManagementIcon from "../icons/acnt-mgt.png";
import UsersIcon from '../icons/users.png';
import AnalyticsIcon from "../icons/analytics.png";
import ServiceMonitoringIcon from "../icons/srvc-mntrg.png";
import SettingIcon from "../icons/setting.png";
import EmailIcon from "../icons/email.png";
import AppLogo from '../icons/new-ovcode-logo.png';
import {tooltipClasses} from '@mui/material/Tooltip';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} placement="top" />

    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#0178f2",
            color: "#fff",
            boxShadow: theme.shadows[1],
            fontSize: 11,
            fontWeight: "bold",
            padding: 7,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#0178f2",
        },
    }
));
  

const Sidebar = (props) => {

    const {open, handleDrawerClose} = props;
    const url = window.location.pathname;

    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (url == "/dashboard") {
            setSelectedIndex(0);

        } else if (url == "/account-management") {
            setSelectedIndex(1);

        } else if (url == "/user-list") {
            setSelectedIndex(2);

        } else if (url == "/email-authentication") {
            setSelectedIndex(3);

        } else if (url == "/app-configuration") {
            setSelectedIndex(4);

        } else if (url == "/analytics") {
            setSelectedIndex(5);

        // } else if (url == "/service-monitoring") {
        //     setSelectedIndex(6);
            
        } else if (url == "/reports") {
            setSelectedIndex(6);
        }
        

    },[])
    

    const handleListMenuClick = (e, index) => {
        setSelectedIndex(index);

        let route;
        
    
        switch (index) {
            case 0:
                route = '/dashboard';
                break;
    
            case 1:
                route = '/account-management';
                break;

            case 2:
                route = '/user-list';
                break;
    
            case 3:
                route = '/email-authentication';
                break;

            case 4:
                route = '/app-configuration';
                break;
    
            case 5:
                route = '/analytics';
                break;

            // case 6:
            //     route = '/service-monitoring';
            //     break;

            case 6:
                route = '/reports';
                break;
        
            default:
                route = '/dashboard';
        }
        
        navigate(route);
    }

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Button sx={{cursor: "default", mr: "auto", ...(!open && {display: 'none'})}}>
                    <img alt="ovcode-admin-logo"src={AppLogo} style={{height: 60}} />
                </Button>

                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ?  null : <MenuIcon sx={{color: "black"}} />}
                </IconButton>
            </DrawerHeader>

            
            <List>
                {["Dashboard", "Enterprise", "Enterprise Users", "Email Sender", "Settings", "Analytics", "Reports"].map((text, index) => (
                    <LightTooltip arrow sx={{display: (open) ? "none" : "visible"}}
                        title={
                            (index == 0) ? "Dashboard" 
                        : 
                            (index == 1) ? "Enterprise" 
                        : 
                            (index == 2) ? "Enterprise Users"
                        :
                            (index == 3) ? "Email Authentication"
                        :
                            (index == 4) ? "Settings"
                        :
                            (index == 5) ? "Analytics"

                        // :   (index == 5) ? "Service Monitoring"

                        :   (index == 6) ? "Reports"

                        :
                            null
                        }
                    >
                        <ListItem key={index} disablePadding sx={{display: 'block'}}>
                            <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}
                                selected={
                                    (index == 0) ? selectedIndex === 0 
                                : 
                                    (index == 1) ? selectedIndex === 1 
                                :
                                    (index == 2) ? selectedIndex === 2 
                                :
                                    (index == 3) ? selectedIndex === 3 
                                :
                                    (index == 4) ? selectedIndex === 4 

                                :   (index == 5) ? selectedIndex === 5

                                :   (index == 6) ? selectedIndex === 6

                                // :   (index == 7) ? selectedIndex === 7

                                :
                                    null
                                }

                                onClick={(e) => handleListMenuClick(e, index)}
                            >
                                <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                    {(index == 0) ? 
                                        <img alt="dashboard-icon" src={DashboardIcon} style={{height: 25}} />
                                    : 
                                        (index == 1) ?
                                            <img alt="account-management-icon" src={AccountManagementIcon} style={{height: 25}} />
                                    :
                                        (index == 2) ?
                                            <img  alt="users-icon" src={UsersIcon} style={{height: 25}} />
                                    : 
                                        (index == 3) ?
                                            <img alt="analytics-icon" src={EmailIcon} style={{height: 25}} />
                                    : 
                                        (index == 4) ?
                                            <img alt="analytics-icon" src={SettingIcon} style={{height: 25}} />
                                    : 
                                        (index == 5) ?
                                            <img alt="service-monitoring-icon" src={AnalyticsIcon} style={{height: 25}} />
                                    // :
                                    //     (index == 6) ?
                                    //         <img alt="service-monitoring-icon" src={ServiceMonitoringIcon} style={{height: 25}} />
                                    :
                                        (index == 6) ?
                                           <AssessmentOutlinedIcon style={{height: 25, color: '#0178f2'}} />

                                    :
                                        null
                                    }
                                </ListItemIcon>

                                <ListItemText sx={{opacity: open ? 1 : 0}} primary={
                                    <Typography sx={{fontSize: 14, color: 
                                        (selectedIndex === 0 && index === 0) ? "#0178f2" 
                                    : 
                                        (selectedIndex === 1 && index === 1) ? "#0178f2" 
                                    :
                                        (selectedIndex === 2 && index === 2) ? "#0178f2"
                                    :
                                        (selectedIndex === 3 && index === 3) ? "#0178f2"
                                    :
                                        (selectedIndex === 4 && index === 4) ? "#0178f2"
                                    :
                                        (selectedIndex === 5 && index === 5) ? "#0178f2"
                                    :
                                        (selectedIndex === 6 && index === 6) ? "#0178f2"
                                    :
                                    //     (selectedIndex === 7 && index === 7) ? "#0178f2"
                                    // :
                                        null
                                    }}>
                                        {text}
                                    </Typography>
                                }  />
                            </ListItemButton>
                        </ListItem>
                    </LightTooltip>
                ))}
            </List>
        </Drawer> 
    )
}

export default Sidebar