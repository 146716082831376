import React, { useEffect, useState } from "react";
import { FormGroup, Switch, TableCell, TableRow, Typography, Box, IconButton, Tooltip, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { format } from 'date-fns';
import TemplatesActionsPopperComponent from "../enterprise/templates/templates-action-popper.component";
import UpdateTemplateNameDialog from "../enterprise/templates/update-template-name.dialog";
import DeleteTemplateDialog from "../enterprise/templates/delete-template.dialog";
import DeleteProjectTemplateDialog from "../enterprise/templates/delete-project-template.dialog";


const TemplateRowContent = (props) => {

    const { index, template, getEnterpriseProjectAccountTemplates, projectId } = props;
    const businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");


    const { enqueueSnackbar } = useSnackbar();

    const [selectedIndex] = useState(index);
    const [noCertificate, setNoCertificate] = useState(false);
    const [withCertificate, setWithCertificate] = useState(true);
    const [templateDetails, setTemplateDetails] = useState(null);
    const [openEditTemplate, setOpenEditTemplate] = useState(false);
    const [isTemplateNameUpdatedToParent, setTemplateNameUpdatedToParent] = useState(false);
    const [templateNameSendToParent, setTemplateNameSendToParent] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
    const [templateNameState, setTemplateNameState] = useState(template?.template_name);
    const [templateStatusStateFromParent, setTemplateStateStatusFromParent] = useState(template?.status);


    useEffect(() => {
        const fetchTemplateNameAfterNameUpdate = async () => {
            if (isTemplateNameUpdatedToParent) {
                setTemplateNameState(templateNameSendToParent);
            }
        }

        fetchTemplateNameAfterNameUpdate();
    }, [isTemplateNameUpdatedToParent, templateNameSendToParent]);

    const handleOpenEditDialog = () => {
        setOpenEditTemplate(true);
        setTemplateDetails(template);
    }

    const handleCloseEditTemplateDialog = () => {
        setOpenEditTemplate(false);
    }

    const handleOpenDeleteTemplateDialog = (e) => {
        setOpenDeleteTemplate(true);
        setTemplateDetails(template);
    }

    const handleCloseDeleteTemplateDialog = () => {
        setOpenDeleteTemplate(false);
    }

    const copyToClipboard = (template) => {
        copy(template.template_code);

        enqueueSnackbar("Successfully copied template code to clipboard", {
            variant: "success",
            autoHideDuration: 2000
        });
    }


    return (
        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {/* <TableCell sx={{color: "#0178f2", fontWeight: 500, cursor: "pointer"}} component="th" scope="row"
                        onClick={() => window.location.href = '/view-template?template_code=' + template?.template_code}
                    > 
                        {template?.template_name}
                    </TableCell> */}
                    <TableCell
                        className="card-view"
                        sx={{
                            color: "#0178f2",
                            fontWeight: 500,
                            cursor: "pointer",
                        }}
                        component="th"
                        scope="row"
                        onClick={() => window.location.href = '/view-template?template_code=' + template?.template_code  + '&businessId=' + businessIdFromURL }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <Typography
                                style={{ alignSelf: "flex-start", cursor: "default" }}
                            >
                                {/* {template?.template_name} */}
                                {templateNameState}
                            </Typography>
                        </div>
                    </TableCell>


                    <TableCell align="left" sx={{ fontWeight: 600, fontSize: "13px !important" }}>
                        <Box sx={{ border: "1px solid black", borderStyle: "dashed", padding: 1 }}>
                            <Grid container>
                                <Grid item xs={10} sx={{ alignSelf: "center" }}>
                                    {template?.template_code}
                                </Grid>

                                <Grid item xs={2} sx={{ alignSelf: "center" }}>
                                    <Tooltip title="Copy" placement="top" arrow>
                                        <IconButton className="card-copy" onClick={(e) => copyToClipboard(template)} sx={{ color: "#0178f2", cursor: "pointer", pl: 1, pr: 0 }}>
                                            <ContentCopyIcon sx={{ fontSize: "medium" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </TableCell>

                    <TableCell align="left" sx={{color: (templateStatusStateFromParent === "ACTIVE") ? "green" : (templateStatusStateFromParent === "INACTIVE") ? "red" : "inherit", fontWeight: 530}}>
                        {templateStatusStateFromParent}
                    </TableCell>

                    {/* <TableCell align="left" sx={{ color: template?.status === 'ACTIVE' ? 'green' : template?.status === 'INACTIVE' ? 'red' : 'inherit', fontWeight: 530 }}>
                        {template?.status}
                    </TableCell> */}

                    {/* {url !== "/view-project" ?
                        <TableCell align="left" 
                            sx={{fontWeight: 500, color: '#0178f2', 
                                cursor: (template?.project_name == null) ? "default" : "pointer"
                            }} 
                        >
                            {template?.project_name}
                        </TableCell>
                        :
                        null
                    } */}

                    <TableCell align="center">
                        {template?.total_page}
                    </TableCell>

                    <TableCell align="left">
                        {/* {isUpdatingCertificate ?
                            <Button sx={{ color: "#0178f2", textTransform: "none" }} startIcon={<ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}>
                                Updating
                            </Button>
                            : */}
                        <FormGroup key={index} className="card-update">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Yes</Typography>

                                <Switch id={index} inputProps={{ "aria-label": "ant design" }} sx={{ transform: "scaleX(-1)" }}
                                    checked={(template?.is_cert_remove && index == selectedIndex) ? noCertificate : (!template?.is_cert_remove && index == selectedIndex) ? withCertificate : null}
                                />

                                <Typography>No</Typography>
                            </Stack>
                        </FormGroup>
                        {/* } */}
                    </TableCell>

                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        {format(new Date(template?.updated_at), 'PPpp')}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        <TemplatesActionsPopperComponent
                            template={template}
                            handleOpenEditDialog={handleOpenEditDialog}
                            setTemplateStateStatusFromParent={setTemplateStateStatusFromParent}
                            handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
                            businessIdFromURL={businessIdFromURL}
                        />
                    </TableCell>
                </TableRow>
            }

            {openDeleteTemplate &&
                <DeleteProjectTemplateDialog
                    openDeleteTemplate={openDeleteTemplate}
                    templateDetails={templateDetails}
                    handleCloseDeleteTemplateDialog={handleCloseDeleteTemplateDialog}
                    setIsDeleted={setIsDeleted}
                    getEnterpriseProjectAccountTemplates={getEnterpriseProjectAccountTemplates}
                    projectId={projectId}
                    templateNameState={templateNameState}
                />
            }

            {openEditTemplate &&
                <UpdateTemplateNameDialog
                    openEditTemplate={openEditTemplate}
                    handleCloseEditTemplateDialog={handleCloseEditTemplateDialog}
                    templateDetails={templateDetails}
                    setTemplateNameUpdatedToParent={setTemplateNameUpdatedToParent}
                    setTemplateNameSendToParent={setTemplateNameSendToParent}
                    templateNameState={templateNameState}
                />
            }
        </>
    )
}

export default TemplateRowContent