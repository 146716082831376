import {Avatar, Card, CardContent, Grid, IconButton, InputAdornment, TextField, ButtonGroup, Button, Typography, Divider, Tooltip, CardHeader} from "@mui/material";
import {Link } from "react-router-dom";
import {useState} from "react";
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { useSnackbar } from "notistack";
import { ThreeDots } from 'react-loader-spinner';
import useWidth from '../../hooks/useWidth';

const AuthenticationErrorComponent = (props) => {

    const { reference_details } = props;

    const breakpoints = useWidth();

    const authentication_error = [
        {label : 'Error Type' , value : reference_details?.error_type ? reference_details?.error_type : 'N/A' },
        {label : 'Error Message' , value : reference_details?.error_message ? reference_details?.error_message : 'N/A' },
    ]

   
    return(

        <Card sx={{borderRadius: 2, padding: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height:breakpoints == 'xs' || breakpoints == 'sm' || breakpoints == 'md' ? 'auto' : 580}} >
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} sx={{textAlign:'last'}}>
                        {authentication_error.map((err) => {
                            return(
                                <Grid container spacing={2} sx={{marginBottom: 2, alignItems:'center'}}>
                                    <Grid item xs={12} sm={12} >
                                        <Typography sx={{fontWeight: 600}}>{err.label} :</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField variant="standard"  sx={{width:'100%', textAlign:'justify', fontSize: 15}}
                                         value={err.value}  multiline
                                         InputProps={{style: {fontSize: 14, }, readOnly: true, disableUnderline: true}}/>
                                    </Grid>
                                </Grid> 
                            )
                        })}
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 
        
    )
    
}

export default AuthenticationErrorComponent