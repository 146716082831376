import React, { useState, useEffect } from "react";
import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { format } from 'date-fns';
import ActionsPopperComponent from "../users/actions-popper.component";
import EditUserEmailDialog from "../users/dialogs/edit-user-email.dialog";
import ViewUserDialog from "../users/dialogs/view-user.dialog";
import useBusiness from "../../hooks/useBusiness";

const UserRowComponent = (props) => {

    const { breakpoints, index, user, projectId, businessId } = props;

    const {fetchingBusinessDetails, businessDetails, getBusinessById} = useBusiness();


    const [openViewUser, setViewUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const [enterpriseName, setEnterpriseName] = useState(null);
    const [openEditEmail, setOpenEditEmail] = useState(false);

    useEffect(() => {
        getBusinessById(user?.business_account_id);
    }, [])


    const handleViewUser = (e, id, project_name) => {
        setViewUser(true);
        setUserId(id);
        setEnterpriseName(project_name);
    }


    return (
        <>
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ color: "#0178f2", cursor: "default" }}
                 onClick={(e) =>
                    (user?.business_account_id == businessDetails?.id) ? 
                        handleViewUser(e, user?.id, (businessDetails?.business_name) ? businessDetails?.business_name : "N/A")
                    : 
                        null
                }
                >
                    {user?.user_id}
                </TableCell>

                <TableCell align="left" sx={{ maxWidth: 200 }}>
                    {(user?.user_principal_name.length > 30) ? (
                        <Tooltip arrow title={user?.user_principal_name} placement="top">
                            <Typography
                                sx={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    width: 300,
                                    fontSize: '14px'  // Setting font size to 16px
                                }}
                            >
                                {user?.user_principal_name}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography sx={{fontSize: '14px' }} >
                            {user?.user_principal_name}
                        </Typography>
                    )}
                </TableCell>

                <TableCell component="th" scope="row" sx={{ color: "#0178f2", cursor: "pointer" }}
                    className="view-user-role"
                >
                    {user?.user_role}
                </TableCell>

                <TableCell align="left" sx={{ color: (user?.status == 1) ? "green" : "red" }}>
                    {user?.status == 1 ? "ACTIVE" : "INACTIVE"}
                </TableCell>

                <TableCell align="left">
                    {format(new Date(user?.updated_date), "PP")}
                </TableCell>

                <TableCell align="left">
                    <ActionsPopperComponent breakpoints={breakpoints}
                        userId={user?.id} status={user?.status}
                        setOpenEditEmail={setOpenEditEmail}
                        userEmail={user?.user_principal_name}
                        userRole={user?.user_role}
                        projectId={projectId}
                        businessId={businessId}
                    />
                </TableCell>
            </TableRow>

            {openEditEmail &&
                <EditUserEmailDialog breakpoints={breakpoints}
                    openEditEmail={openEditEmail} setOpenEditEmail={setOpenEditEmail}
                    userId={user?.id} userEmail={user?.user_principal_name}  projectId={projectId}
                    getUsersUnderEnterpriseProject={props.getUsersUnderEnterpriseProject} businessId={businessId}
                />
            }

            {openViewUser &&
                <ViewUserDialog breakpoints={breakpoints} userId={userId}
                    openViewUser={openViewUser} setViewUser={setViewUser}
                    enterpriseName={enterpriseName}
                />
            }

        </>
    )
}

export default UserRowComponent