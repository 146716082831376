import { ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import RestoreProjectDialog from "./dialog/restore-deleted-project.dialog";
import DeleteProjectPermanentlyDialog from "./dialog/delete-project-permanently.dialog";



const ActionsPopperComponent = (props) => {

    const {project, setIsDeleted, setIsRestored} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteProject, setOpenDeleteProject] = useState(false);
    const [openRestoreProject, setOpenRestoreProject] = useState(false);

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteProject(true);
    };
  
    const handleCloseDeleteDialog = () => {
        setOpenDeleteProject(false);
    };

    const handleClickOpenRestoreProjectDialog = () => {
        setOpenRestoreProject(true);
    };
  
    const handleCloseRestoreProjectDialog = () => {
        setOpenRestoreProject(false);
    };


    
    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
  
    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreVertIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                                <MenuItem style={{color: 'red', fontSize: 13, fonWeight: 500}} onClick={handleClickOpenDeleteDialog} >
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                            <DeleteIcon style={{width: 16, height: 16}}/>
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Delete Permanently</Typography>
                                </MenuItem>
                                <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={handleClickOpenRestoreProjectDialog}>
                                    <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                        <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                            <RestoreIcon style={{width: 16, height: 16}} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <Typography variant="inherit">Restore</Typography>
                                </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>


            {openRestoreProject &&
                <RestoreProjectDialog 
                    project={project}
                    openRestoreProject={openRestoreProject}
                    handleClose={handleCloseRestoreProjectDialog}
                    setIsRestored={setIsRestored}
                />
            }

            {openDeleteProject &&
                <DeleteProjectPermanentlyDialog 
                    project={project}
                    openDeleteProject={openDeleteProject} 
                    handleClose={handleCloseDeleteDialog}
                    setIsDeleted={setIsDeleted}
                />
            }

            

        </div>
    )
}

export default ActionsPopperComponent
