import React, {useEffect, useState} from "react";
import {Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import useBusiness from "../../hooks/useBusiness";
import useApim from "../../hooks/useApim";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";
import ReactSelect from 'react-select';

function CreateBusinessAccountComponent (props) {

    const {breakpoints} = props;
    const {enqueueSnackbar} = useSnackbar();

    const {loading, createBusiness, isCreated, businessId } = useBusiness();
    const {getProducts, getProduct} = useApim();

    const uploadLogoFilename = document.querySelector('.upload-logo-filename')
    const [file, setFileLogoUpload] = useState(null);
    const [logo_image, setLogoImage] = useState(null);
    const [business_name, setBusinessName] = useState("");
    const [description, setDescription] = useState("");
    const [contact, setContact] = useState("");
    const [website, setWebsite] = useState("");
    const [plan, setPlan] = useState('enterprise');
    const [nameValidation, setNameValidation] = useState(false);
    const [isLogoRequired, setLogoRequired] = useState(false);
    const [options, setOption] = useState([]);


    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent', height: 54 }) };
 

    const validateLogoType = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            const [target_file_logo] = e.target.files;

            setFileLogoUpload(e.target.files[0]);
            setLogoImage(URL.createObjectURL(target_file_logo));
            setLogoRequired(false);

        } else {
          console.log("Only image files are supported as of now");
          setLogoRequired(false);

          enqueueSnackbar("Only image files are supported as of now.", { 
            variant: 'error',
            autoHideDuration: 3000
          });
        }
    }
   
    useEffect(() => {
        if (file) {
            uploadLogoFilename.classList.remove('inactive')
            uploadLogoFilename.innerText = file.name
        }
    },[file])

    useEffect(()=>{ getProducts() },[])


    useEffect(() => {

        let options = []

        getProduct?.value?.map((product) => {

            let options_data = { value: product?.name, label: product?.properties?.displayName }

            options.push(options_data)
        })

        setOption(options)

    }, [JSON.stringify(getProduct)])

    const handleBusinessNameChange = (e) => {
        setBusinessName(e.target.value);
        setNameValidation(false);
    }

    const handleDescriptionChange = (e) => {
        const { value } = e.target;
        setDescription(value);
    }

    const handleWebsiteChange = (e) => {
        const { value } = e.target;
        setWebsite(value);
    }

    const handleContactNumberChange = (e) => {
        const { value } = e.target;
        setContact(value);
    }


    // const handleChange = (event) => {
    //     setPlan(event.target.value);
    // }

    const handleChange = () => value => {
        setPlan(value.value);
    };



    const handleCreateBusiness = () => {

        let formData = new FormData();
        formData.append('business_name', business_name);
        formData.append('description', description);
        formData.append('file', file);
        formData.append('contact', contact);
        formData.append('website', website);
        formData.append('plan', 'enterprise');

        if (!business_name) {
            setNameValidation(true);

        } else if (!file) {
            setLogoRequired(true);

        } else {
            setNameValidation(false);
            setLogoRequired(false);

            createBusiness(formData);
        }       
    }

    useEffect(() => {
        if (isCreated && businessId) {
            window.location.href = "/add-enterprise-plan-and-credits?id=" + businessId
        }
    },[isCreated, businessId])


    return(
        <Card sx={{width: breakpoints == 'xs' ? '100%' : '80%', border: 'none', padding: breakpoints == 'xs'? 1 : 2.3, margin: 'auto', boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
            <CardContent>
                <Typography variant="h6" sx={{textAlign: 'center'}}>
                    Create Enterprise Account
                </Typography>
                <Grid container spacing={2} sx={{padding: breakpoints == 'xs' ? "24px 0px 0px 0px" : "24px 64px 16px 64px"}}>

                    <Grid item xs={12}>
                        {/* <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={plan}
                                label="ovcode plan label"
                                placeholder="Select Enterprise"
                                onChange={handleChange}
                            >
                                {getProduct?.value?.map((product, index) => { 
                                    return(
                                        <MenuItem key={product.name} value={product.name}>
                                            {product.properties.displayName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl> */}
                        {/* <ReactSelect
                            className="basic-single"
                            placeholder={'Select Enterprise'}
                            isRtl={false}
                            isSearchable={true}
                            options={options}
                            styles={dataStyles}
                            onChange={(selectedOption) => {
                                handleChange()(selectedOption); 
                            }}
                            defaultValue={{ value: 'enterprise', label: 'Enterprise' }}
                            isDisabled={true}
                        /> */}


                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Company Name"
                            value={business_name} 
                            onChange={handleBusinessNameChange}
                            helperText={
                                <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                    {(nameValidation) && "Company Name is required"}
                                </p>
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            fullWidth 
                            multiline
                            rows={5}
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Description"
                            value={description} 
                            onChange={handleDescriptionChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Website (Optional)"
                            value={website} 
                            onChange={handleWebsiteChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{mb: 1}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Contact Number (Optional)"
                            value={contact} 
                            onChange={handleContactNumberChange}
                        />
                    </Grid>
                
                    <Grid item xs={12} sx={{textAlign: 'start'}}>
                        <Typography sx={{fontSize: 17, fontWeight: 550, mb: 0.5}}>
                            Company Logo
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} sm={6} sx={{alignSelf: 'center'}}>
                                <div className="upload">
                                    <Button sx={{textTransform: "none", borderRadius: 1, fontSize: 17}}>
                                        <input 
                                            type="file" 
                                            accept="image/*" 
                                            id="upload-logo"
                                            onChange= {(e) => validateLogoType(e) }
                                        />
                                        <label htmlFor="upload-logo" className="browse-btn">Browse</label>
                                    </Button>

                                    <div style={{alignSelf: 'center', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', width: 200}}>
                                        <span className="upload-logo-filename inactive">No file selected</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{alignSelf: 'center'}}>
                                {logo_image &&
                                    <img alt="" src={logo_image} style={{width: 120, height: 120, objectFit: 'contain'}} />    
                                }
                            </Grid>

                            {isLogoRequired && 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{alignSelf: "center"}}>
                                    <p style={{fontSize: 12, color: "#bf5a4c", margin: "4px 0px -1px 8px"}}>
                                        Company Logo is required
                                    </p>
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                    
                    <Grid item xs={12} sm={11} sx={{textAlign: "end", mt: 2, ml: 10}}>
                        <Button variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1}} 
                            onClick={(e) => (loading) ? null : handleCreateBusiness(e)}  
                            startIcon={loading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                        >
                            {loading ? "Saving" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
    
}

export default CreateBusinessAccountComponent