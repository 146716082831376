import {Tab, Tabs} from '@mui/material';
import {styled} from '@mui/material/styles';

const CustomTabs = styled(Tabs)({
  '& .MuiTab-root': {
    padding: '5px 15px 5px 15px',
    minHeight: 5,
    textTransform: 'none',

    '&.Mui-selected': {
      color: "#0178f2",
    },

    ':hover': {
      color: "#9a9a9a",
    },
  },       
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
  minWidth: "auto", 
  bgcolor: "#0178f2",
  borderRadius: 2,
  marginRight: 10,
  fontWeight: "bold",
  color: "#000",
}));

export { CustomTabs, CustomTab };