import React, { useState, useEffect } from "react";
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Box, Button, Fab, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../../common/custom-tabs.component";
import { useNavigate } from "react-router-dom";
import EnterpriseTemplatesComponent from "./enterprise-templates.component";
import useWidth from "../../../hooks/useWidth";
import EnterpriseDeletedTemplatesComponent from "./enterprise-deleted-templates.component";
import TemplateListScreen from "../../../screens/TemplateListScreen";
import EnterpriseProjectDeletedTemplatesComponent from "./project-deleted-templates.component";


const useTemplateTabs = (props, value) => {
    
    const { breakpoints, projectId, project_name } = props;

    const [template_tabs, setTemplateTabs] = useState(null);


    useEffect(() => {
        function setupTabs() {

                const tabs_default = [
                    {name: 'Templates', component: 
                        <TemplateListScreen
                            breakpoints={breakpoints} 
                            projectId={projectId} 
                            project_name={project_name}
                        /> 
                    },

                    {name: 'Deleted Templates', component: 
                        <EnterpriseProjectDeletedTemplatesComponent 
                            breakpoints={breakpoints} 
                            projectId={projectId} 
                            project_name={project_name}
                        />
                    }
                ]
            
                setTemplateTabs(tabs_default);

        }
    
        setupTabs();
      }, []);

      return template_tabs;
}


const TemplateTabsComponent = (props) => {

    const { breakpoints, projectId, project_name } = props;

    const navigate = useNavigate();

    const [value, setValue] = useState(0);

    const tabs = useTemplateTabs(props, value);

    function TabPanel(props) {

        const { children, value, index, ...other } = props;


        return (
               
                    <div
                        component="div"
                        role="tabpanel"
                        hidden={value !== index}
                        id={`scrollable-auto-tabpanel-${index}`}
                        aria-labelledby={`scrollable-auto-tab-${index}`}
                        {...other}
                    >
                        {value === index && (
                            <Box>
                                <Typography>{children}</Typography>
                            </Box>
                        )}
                    </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    function handleChange(event, newValue) {
        setValue(newValue);
    }

   


    return (
        <Box sx={{ width: '100%', margin: 'auto'}}>
            
            <Box sx={{ pt: 2, width: '100%', borderRadius: 0 }}>
                <Grid container sx={{ textAlign: 'start', marginLeft: 2 }}>
                    <Grid item xs={8} sm={8}>
                        <Typography variant='h6'>
                            Templates
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
   
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' , mb: 1}} className="card-tab">
                    <CustomTabs
                        orientation="horizontal"
                        variant={(breakpoints === 'xs' || breakpoints === 'md') ? "scrollable" : ""}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="transparent"
                        sx={{ flex: 1, alignItems: 'flex-end' }} // Allow the tabs to take the available width
                    >
                        {tabs?.map((tab, index) => {
                            return (
                                <CustomTab
                                    key={tab.name}
                                    label={<Typography sx={{ textAlign: 'start', fontWeight: 500, fontSize: 15 }}>{tab.name}</Typography>}
                                    disableRipple
                                    {...a11yProps(index)}
                                    sx={{ alignItems: 'flex-end !important', m: 0 }}
                                />
                            );
                        })}
                    </CustomTabs>
                </Box>
                <Box sx={{flexGrow: 1}}/>
            </Box>

            {tabs?.map((tab, index) => {
                return (
                    <TabPanel value={value} index={index} style={{ padding: "0px", width: '100%' }}>
                        {tab.component}
                    </TabPanel>
                )
            })}
        </Box>
    )
}




const ProjectTemplateManagementComponent = (props) => {

    const {  breakpoints, projectId, project_name } = props;


    return (

        <Box sx={{}}>
            
            <Box sx={{ p: 1, pt: 0, width: '100%', borderRadius: 0 }}>
                <TemplateTabsComponent 
                    breakpoints={breakpoints} 
                    projectId={projectId} 
                    project_name={project_name}/>
                </Box>

        </Box>
    )
}


export default ProjectTemplateManagementComponent