import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {ThreeDots} from "react-loader-spinner";
import { useEffect } from "react";

function DocxPreviewDialog(props){
    const {openDocxPreview, handleClose, list, handleDownloadFile, isLoading} = props;

    return(
        <Dialog open={openDocxPreview} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, backgroundColor:'#636161'}}}>
            <DialogContent sx={{textAlign:'center'}}>
                <Typography sx={{fontSize: 14, color:'white', marginBottom: 1}}>
                    No preview available
                </Typography>
                <Button onClick={(e) => handleDownloadFile(e)} variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', '&:hover': {backgroundColor: '#0178f2', color: 'white'}}}
                startIcon={isLoading &&
                    <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                }>
                  {isLoading ? 'Downloading' : <> <FileDownloadIcon/> Download</>}
                </Button>
            </DialogContent>
        </Dialog>
    )
}

export default DocxPreviewDialog