import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, HttpPatch, HttpPut1, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;


const getRoles = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ROLES, query_params , subscriptionKey);

    return result;
    
}


const getAllRoles = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ROLES, query_params , subscriptionKey);

    return result;
    
}


const createRole = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_ROLE, payload , subscriptionKey);

    return result;
    
}

const deleteRole = (id) => {

    let query_params = {
        id : id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_ROLE, query_params , subscriptionKey);

    return result;
    
}

const updateRole = (id, payload) => {

    let parameters = {
        id : id
    }


    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ROLE, parameters, payload, subscriptionKey);
    
    return result;
}


const updateRoleStatus = (id, active) => {

    let parameters = {
        id : id,
        active : active
    }

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ROLE_STATUS, parameters, payload, subscriptionKey);
    
    return result;
}


const getInheritance = (roleId) => {

    let query_params = {
        roleId : roleId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_INHERITANCE, query_params , subscriptionKey);

    return result;
    
}


const addInheritance = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADD_INHERITANCE, payload , subscriptionKey);

    return result;
    
}

const removeInheritance = (parentRoleId, roleId) => {

    let query_params = {
        parentRoleId : parentRoleId,
        roleId : roleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_INHERITANCE, query_params , subscriptionKey);

    return result;
    
}

const searchRoles = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_ROLE, query_params, subscriptionKey);

    return result;
}

const getAdminRoles = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_ROLES, query_params , subscriptionKey);

    return result;
    
}


const getAllAdminRoles = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_ROLES, query_params , subscriptionKey);

    return result;
    
}

const createAdminRole = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.CREATE_ADMIN_ROLE, payload , subscriptionKey);

    return result;
    
}

const deleteAdminRole = (id) => {

    let query_params = {
        id : id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.DELETE_ADMIN_ROLE, query_params , subscriptionKey);

    return result;
    
}

const updateAdminRole = (id, payload) => {

    let parameters = {
        id : id
    }


    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ADMIN_ROLE, parameters, payload, subscriptionKey);
    
    return result;
}


const updateAdminRoleStatus = (id, active) => {

    let parameters = {
        id : id,
        active : active
    }

    let payload = '';

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.UPDATE_ADMIN_ROLE_STATUS, parameters, payload, subscriptionKey);
    
    return result;
}


const getAdminInheritance = (roleId) => {

    let query_params = {
        roleId : roleId
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_ADMIN_INHERITANCE, query_params , subscriptionKey);

    return result;
    
}


const addAdminInheritance = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.ADD_ADMIN_INHERITANCE, payload , subscriptionKey);

    return result;
    
}

const removeAdminInheritance = (parentRoleId, roleId) => {

    let query_params = {
        parentRoleId : parentRoleId,
        roleId : roleId
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpDelete(routes.REMOVE_ADMIN_INHERITANCE, query_params , subscriptionKey);

    return result;
    
}

const searchAdminRoles = (filter, value, page, limit) => {
    let query_params = {
        filter: filter,
        value: value,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_ADMIN_ROLE, query_params, subscriptionKey);

    return result;
}


const RolesManagementService = {
    getRoles,
    getAllRoles,
    createRole,
    deleteRole,
    updateRole,
    updateRoleStatus,
    getInheritance,
    addInheritance,
    removeInheritance,
    searchRoles: searchRoles,
    getAdminRoles,
    getAllAdminRoles,
    createAdminRole,
    deleteAdminRole,
    updateAdminRole,
    updateAdminRoleStatus,
    getAdminInheritance,
    addAdminInheritance,
    removeAdminInheritance,
    searchAdminRoles,
}

export default RolesManagementService