import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { format } from 'date-fns';
import AuthenticationEnterpriseSkeletonComponent from "../skeletons/authentication-enterprise-skeleton.component";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AuthenticationComponent from "../analytics/authentication.component";
import TransactionPopper from "../analytics/transaction-popper.component";




function reformatString(inputString) {
    // Remove square brackets from the input string
    const cleanedString = inputString.replace(/[[\]]/g, '');

    // Convert the cleaned string to uppercase and split into an array
    const inputArray = cleanedString.toUpperCase().split(',').map(item => item.trim());

    // Filter out "ENCRYPTION" from the array
    const filteredArray = inputArray.filter(item => item !== 'ENCRYPTION');

    if (filteredArray.length === 1) {
        // If there is only one element, return it as is
        return filteredArray[0];
    } else if (filteredArray.length > 1) {
        // If there are multiple elements, join them with commas and "and"
        const lastIndex = filteredArray.length - 1;
        return (
            filteredArray.slice(0, lastIndex).join(', ') + ' and ' + filteredArray[lastIndex]
        );
    } else {
        // If no elements are left after filtering, return an empty string
        return '';
    }
}



function TableHeader() {

    const tablecell = [
        { label: 'Reference' },
        { label: 'Pipeline' },
        { label: 'Status' },
        { label: 'Last Date Modified' },
        { label: 'Action' },

    ]

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

function AuthenticationContent(props) {

    const { index, transaction, setView, setReferenceId, setProjectId, setProjectName, businessId } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [isDeleted, setDeleted] = useState(false);

    let projectId = transaction?.project_id;
    let reference = transaction?.reference;


    const handleCopyToClipboard = (e, name, key) => {
        navigator.clipboard.writeText(key);

        enqueueSnackbar("Successfully copied reference to clipboard", {
            variant: "success",
            autoHideDuration: 3000
        });

    }

    const viewDetails = (transaction) => {
        console.log("clicked hereeee")
        setView(true)
        setReferenceId(transaction?.reference)
        setProjectId(transaction?.project_id)
        setProjectName(transaction?.project_name)
    }


    return (
        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ color: "#0178f2", fontWeight: 500, cursor: "pointer", display: 'flex', alignItems: 'center' }} component="th" scope="row" >
                        <Box onClick={(e) => viewDetails(transaction)}>
                            {transaction?.reference}
                        </Box>
                        <IconButton sx={{ textTransform: "none", fontSize: 10 }}
                            onClick={(e) => handleCopyToClipboard(e, "reference", transaction?.reference)}
                        >
                            <ContentCopyIcon sx={{ width: 18, height: 18, color: "#0178f2" }} />
                        </IconButton>
                    </TableCell>

                    <TableCell align="left">
                        {reformatString(transaction?.pipeline.replace(/[[\]]/g, ''))}
                    </TableCell>

                    <TableCell align="left" sx={{ textTransform: 'uppercase', fontWeight: 600, color: transaction?.state == "COMPLETED" || transaction?.state == "Completed" ? 'green' : transaction?.state == "ONGOING" || transaction?.state == "Ongoing" ? '#0178f2' : transaction?.state == "PENDING" || transaction?.state == "Pending" ? 'orange' : 'red' }} >
                        {transaction?.state}
                    </TableCell>

                    <TableCell align="left">
                        {format(new Date(transaction?.updated_time), 'PPpp')}
                    </TableCell>

                    <TableCell align="left">
                        <TransactionPopper
                            viewDetails={viewDetails}
                            projectId={projectId}
                            reference={reference}
                            transaction={transaction}
                            setDeleted={setDeleted}
                            businessId={businessId}
                        />
                    </TableCell>
                </TableRow>
            }
        </>
    )
}

const AuthenticationListComponent = (props) => {

    const { loading, transactions, pagination, handleChangePagination, page, businessId } = props;

    const [view, setView] = useState(false);
    const [reference_id, setReferenceId] = useState('');
    const [projectId, setProjectId] = useState('');
    const [project_name, setProjectName] = useState();


    return (
        <Box sx={{ width: '100%', borderRadius: 0, overflow: 'hidden' }}>
            <Grid container sx={{ p: 2, pt: 1 }}>
                {view ?
                    <AuthenticationComponent reference={reference_id} projectId={projectId} project_name={project_name} />
                    :
                    <>
                        <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 2, width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {loading ?
                                    <AuthenticationEnterpriseSkeletonComponent />

                                    :

                                    <TableBody>
                                        <>
                                            {(transactions && transactions)?.map((transaction, index) => (
                                                <AuthenticationContent
                                                    index={index}
                                                    transaction={transaction}
                                                    setView={setView}
                                                    setReferenceId={setReferenceId}
                                                    setProjectId={setProjectId}
                                                    setProjectName={setProjectName}
                                                    businessId={businessId}
                                                    projectId={projectId}
                                                />
                                            ))}
                                        </>
                                    </TableBody>
                                }
                            </Table>

                            {!loading &&
                                <>
                                    {transactions?.length === 0 ?
                                        <Box sx={{ textAlign: 'center', padding: 2 }}>
                                            No authentication record(s).
                                        </Box>
                                        :
                                        <Pagination
                                            count={pagination}
                                            page={page}
                                            onChange={handleChangePagination}
                                            style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                                        />
                                    }
                                </>
                            }
                        </TableContainer>
                    </>
                }
            </Grid>
        </Box>
    )
}

export default AuthenticationListComponent