import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip, Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {ThreeDots} from "react-loader-spinner";
import AdminService from "../../services/api/admin";
import copy from "copy-to-clipboard"; 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AddAdminDialogComponent = (props) => {

    const {openAddDialog, handleCloseAddDialog} = props;
    const {enqueueSnackbar} = useSnackbar();

    const [email, setEmail] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const [createAdminAccount, setCreateAdminAccount] = useState(null);
    const [openDefaultPasswordDialog, setOpenDefaultPasswordDialog] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const handleChangeName = (e) =>{
        setEmail(e.target.value);
        setValidEmail(true);
        setEmptyEmail(false);
    }

    const handleSubmit = () =>{
        let payload = {email: email};

        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            setEmptyEmail(false);
            setValidEmail(true);
            setIsCreating(true);

            AdminService.addAdminUser(payload).then(res=>{
                console.log("addAdminUser data xxxxx", res);

                if (res.data.success) {
                    setCreateAdminAccount(res.data.data);
                    setIsCreating(false);
                    setOpenDefaultPasswordDialog(true);
                }

            }).catch(()=>{
                setIsCreating(false);
            })
        }
    }

    const handleCloseDefaultPasswordDialog = () => {
        setOpenDefaultPasswordDialog(false);
        handleCloseAddDialog();
    }

    let copyText =  createAdminAccount?.password;

    const copyToClipboard = () => {
        console.log("copy text here:", copyText);

        copy(copyText);
        enqueueSnackbar("Successfully copied password to clipboard", { 
            variant: "success",
            autoHideDuration: 2000
        });
    }
 
    return (
        <>
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
                <DialogTitle>
                    <Typography variant="h6">
                        Create Admin User
                    </Typography>
                </DialogTitle>

                <DialogContent sx={{padding: 5, marginTop: 3}}>
                    <TextField  
                        fullWidth  
                        variant="outlined"  
                        autoComplete="off"  
                        placeholder="Email"
                        value={email}
                        onChange={(e) => handleChangeName(e)}
                        helperText={
                            <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                {
                                    (emptyEmail) ? "Email is required, please input email" 
                                : 
                                    (!validEmail) ? "Email is invalid, please input valid email" 
                                : 
                                    ""
                                }
                            </p>
                        }
                    />
                </DialogContent>

                <DialogActions sx={{padding: "8px 40px 24px 40px"}}>
                    <Button sx={{color: 'black', textTransform: 'none'}} onClick={handleCloseAddDialog}>
                        Cancel
                    </Button>

                    <Button onClick={handleSubmit} variant="contained" sx={{backgroundColor: '#0178f2', borderRadius: 1, textTransform: 'none', '&:hover': {backgroundColor: '#0178f2', color: 'white'}}}
                        startIcon={isCreating && 
                            <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                        }
                    >
                        {isCreating ? 'Creating' : 'Create'} 
                    </Button>
                </DialogActions>
            </Dialog>

            {openDefaultPasswordDialog && 
                <Dialog open={openDefaultPasswordDialog} onClose={handleCloseDefaultPasswordDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, margin: 'auto'}}}>
                    <DialogTitle>
                        Default Password
                    </DialogTitle>

                    <DialogContent sx={{padding: 5, marginTop: 3}}>
                        <TextField  
                            fullWidth  
                            variant="outlined"  
                            autoComplete="off"  
                            value={createAdminAccount?.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginLeft: -29}}>
                                        <Tooltip title="Copy" placement="top" arrow>
                                            <IconButton onClick={(e) => copyToClipboard(e)} sx={{color: '#0178f2'}} edge="end">
                                                <ContentCopyIcon /> 
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                readOnly: true
                            }} 
                        />
                    </DialogContent>
                    
                    <DialogActions sx={{padding: "18px 24px"}}>
                        <Button sx={{color: "black", textTransform: "none"}} 
                            onClick={(e)=> handleCloseDefaultPasswordDialog(e)}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default AddAdminDialogComponent