import { useState } from 'react';
import AdminRbacRolesManagementService from '../services/api/admin-rbac/roles-management';

function useAdminRbacRolesManagement() {
  
  const [role, setRole] = useState();
  const [roles, setRoles] = useState([]);

  const getList = (page, limit) => {
    AdminRbacRolesManagementService.getList(page, limit).then(res=> {
        console.log("res of getList: ", res)
        setRoles(res);
    });
  };

  const viewRole = (role) => {
    AdminRbacRolesManagementService.viewRole(role).then(res=> {
        console.log("res of viewRole: ", res)
        setRole(res);
    });
  };

  const deleteRole = (id) => {
    AdminRbacRolesManagementService.deleteRole(id).then(res=> {
        console.log("res of deleteRole: ", res)
        setRole(res);
    });
  };

  const updateRole = (id, role, description) => {
    AdminRbacRolesManagementService.updateRole(id, role, description).then(res=> {
        console.log("res of updateRole: ", res)
        setRole(res);
    });
  };

  const updateRoleStatus = (id, active) => {
    AdminRbacRolesManagementService.updateRoleStatus(id, active).then(res=> {
        console.log("res of updateRoleStatus: ", res)
        setRole(res);
    });
  };

  const createRole = (role, description, parent_role_id) => {
    AdminRbacRolesManagementService.createRole(role, description, parent_role_id).then(res=> {
        console.log("res of createRole: ", res)
        setRole(res);
    });
  };

  return {roles, role, getList, viewRole, deleteRole, updateRole, updateRoleStatus, createRole} ;

}

export default useAdminRbacRolesManagement;