import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import ReactSelect from 'react-select';
import useBusiness from "../../../hooks/useBusiness";
import useCredits from "../../../hooks/useCredits";
import { ThreeDots } from 'react-loader-spinner';
import useProjects from "../../../hooks/useProjects";


const AddProjectCreditDialog = (props) => {

    const { openAddCredit, handleClose, businessId, businessName } = props;

    const breakpoints = useWidth();
    const {getProjectList, projects, loadingProjects} = useProjects();
    const { addProjectCredits, addingProjectCredits, addedProjectCredits  } = useCredits();


    const dataStyles = { control: (styles) => ({ ...styles, backgroundColor: 'transparent' }) };

    const [addCreditAmount, setAddCreditAmount] = useState(0);
    const [projectOptions, setProjectOptions] = useState([]);
    const [project_id, setProjectId] = useState(projectOptions[0]?.value);
    const [selectedProject, setSelectedProject] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    useEffect(() => {
        getProjectList(businessId);
    },[businessId])



    useEffect(() => {

        let options = []

        projects?.map((project) => {

            let options_data = { value: project?.id, label: project?.application_name }

            options.push(options_data)
        })

        setProjectOptions(options)

    }, [JSON.stringify(projects)])



    const getProject = () => value => {
        setProjectId(value.value);
        setSelectedProject(value.label);
    };

    const handleAddChange = (e) => {
        if (e.target.value < 0) {
            setAddCreditAmount(0);

        } else if (e.target.value > 1000000) {
            setAddCreditAmount(1000000);

        } else {
            setAddCreditAmount(e.target.value);
        }
    }


    const handleAddCredits = () => {

        let payload = {
            business_id: businessId,
            project_id: project_id,
            credit: addCreditAmount
        }

        addProjectCredits(payload);
    }


    useEffect(() => {
        if(addedProjectCredits){
           handleClose()
            window.location.reload();
        }

    },[addedProjectCredits])

    return (
        <Dialog open={openAddCredit} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Add Project Credit
            </DialogTitle>

            <Divider />

            <DialogContent 
                sx={{
                    padding: (breakpoints == "xs" || breakpoints == "sm") ? {} :  8, 
                    paddingTop: 4,
                    paddingBottom: isDropdownOpen ? 0 : 8,
                    height: isDropdownOpen ? 360 : undefined
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={8} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>Project</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                {loadingProjects ?
                                    <Skeleton variant="rectangular" sx={{ backgroundColor: "rgb(108 139 222 / 30%)" }} height={35} />
                                :
                                    <ReactSelect
                                        className="basic-single"
                                        placeholder={projectOptions[0]?.label}
                                        defaultValue={selectedProject ? selectedProject : undefined} // Clear the default value
                                        isRtl={false}
                                        isSearchable={true}
                                        options={projectOptions}
                                        styles={dataStyles}
                                        onMenuOpen={() => setIsDropdownOpen(true)} 
                                        onMenuClose={() => setIsDropdownOpen(false)}
                                        onChange={getProject()}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4} sx={{ alignSelf: 'center' }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Credits</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                                <TextField
                                    size="small"
                                    type="number"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    InputProps={{
                                        inputProps: {
                                            max: 1000000, min: 0
                                        }
                                    }}
                                    value={addCreditAmount}
                                    onChange={(e) => handleAddChange(e)}
                                    sx={{ "& .MuiInputBase-root": { height: 35, width: 120 } }}
                                />
                            </Grid>
                        </Grid>  
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 3, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleAddCredits()}
                    startIcon={addingProjectCredits && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {addingProjectCredits ? "Adding" : "Add"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddProjectCreditDialog