import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';
import usePackageManagement from "../../../hooks/usePackageManagement";


const DeleteFeatureDialog = (props) => {

    const { openDeleteFeature, handleClose, getPackageSummary } = props;


    const breakpoints = useWidth();

    const {  getFeatureList, isFetchingFeatures, feature_list, deleteFeature, deletedFeature, deletingFeature} = usePackageManagement();

    useEffect(() => { getFeatureList(); },[])

    const [feature_id, setFeatureId] = useState(null);


    const handleChangeFeature = (event) => {
        const selectedFeatureId = event.target.value;
        const selectedFeature = feature_list.find(data => data.id === selectedFeatureId);
    
        if (selectedFeature) {
            setFeatureId(selectedFeatureId);
        }
    }


    const handleDeleteFeature = () => {

        deleteFeature(feature_id);
    }


    useEffect(() => {

        if(deletedFeature){
            handleClose()
            // window.location.reload();
            getPackageSummary();
        }

    },[deletedFeature])
    


    return (
        <Dialog open={openDeleteFeature} onClose={handleClose} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Delete Feature 
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Select Feature :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={feature_id}
                                onChange={handleChangeFeature}
                            >
                                {feature_list?.map((data, index) => (
                                    <MenuItem value={data?.id} key={data?.id} data-feature-title={data?.feature_title}>
                                        {data?.feature_title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleDeleteFeature() }
                     startIcon={deletingFeature && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {deletingFeature ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteFeatureDialog