import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import { useEffect, useState } from "react";
import usePermissionManagement from "../../../hooks/usePermissionManagement";
import { ThreeDots } from 'react-loader-spinner';


const UpdatePermissionDialog = (props) => {

    const { openUpdatePermission, handleClose, data } = props;

    const breakpoints = useWidth();
    const url = window.location.pathname;


    const { loading, updatePermission, updateAdminPermission, updatedPermission } = usePermissionManagement();

    const [permissionFunction, setPermissionFunction] = useState(data?.fn);
    const [permissionDescription, setPermissionDescription] = useState(data?.description);

    const handleTextFieldChange = (e, name) => {
         if (name == "permission_function") {
            setPermissionFunction(e.target.value);

        } else if (name == "permission_description") {
            setPermissionDescription(e.target.value);
        }
    }


    const handleUpdatePermission = () => {

        let permission_id = data?.id

        let payload = {
            fn: permissionFunction,
            description: permissionDescription
        }

        if(url === "/admin-settings") {
            updateAdminPermission(permission_id, payload);
        } else {
            updatePermission(permission_id, payload);
        }

    }

    useEffect(() => {

        if(updatedPermission){
            handleClose()
            window.location.reload();
        }

    },[updatedPermission])

    return (
        <Dialog open={openUpdatePermission} onClose={handleClose} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5 } }}>
            <DialogTitle>
                Update Permission
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : 6, paddingTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Function :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={permissionFunction}
                            onChange={(e) => handleTextFieldChange(e, "permission_function")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Description :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            value={permissionDescription}
                            onChange={(e) => handleTextFieldChange(e, "permission_description")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                <Button onClick={handleClose} sx={{ color: "black", textTransform: "none", fontSize: 14 }} >
                    Cancel
                </Button>

                <Button variant="contained" sx={{ backgroundColor: "#0178f2", borderRadius: 1, textTransform: "none", fontSize: 14, '&:hover': { backgroundColor: "#0178f2", color: "white" } }}
                    onClick={() => handleUpdatePermission() }
                    startIcon={loading && 
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> 
                    }
                >
                    {loading ? "Updating" : "Update"} 
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdatePermissionDialog