import { Box, Grid, Skeleton, Paper, Typography, Divider, List, ListItem, ListItemButton, ListItemText, Card } from "@mui/material";


function DashboardPerMonthSkeletonComponent (){

    

    return(

        <Box sx={{padding: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30}/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={395}/>
                </Grid>
            </Grid>
        </Box>
       
      
    )
}

export default DashboardPerMonthSkeletonComponent;