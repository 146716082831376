import React, {useState} from "react";
import {Grid, Box} from "@mui/material";
import PackageCardComponent from "./package.card.component";

const PackageCardScreenComponent = (props) => {

    const {breakpoints, modifiedPackageList, selectedBusiness} = props;

    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const handleHover = (index) => {
        setHoveredIndex(index);
    }

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    }


    return (
        <Box>
            <Grid container sx={{width: "95%", margin: "auto", mt: 3}}>
                {modifiedPackageList?.map((plan, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
                            <PackageCardComponent
                                breakpoints={breakpoints}
                                name={plan?.package.package_name}
                                description={plan?.package.description}
                                defaultPackageFeatures={plan.features}
                                index={index}
                                isHovered={hoveredIndex == index || plan.is_current_plan}
                                handleHover={() => handleHover(index)}
                                handleMouseLeave={handleMouseLeave}
                                selectedBusiness={selectedBusiness}
                                isCurrentPlan={plan.is_current_plan}
                                packageId={plan.package.id}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
};

export default PackageCardScreenComponent
