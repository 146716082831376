import React, {useEffect, useState} from "react";
import {InputLabel, Select, OutlinedInput, MenuItem, ListItemText, IconButton, Autocomplete, TextField, Paper, Skeleton, Checkbox, FormControl, Grid, Box, Card} from "@mui/material";
import {ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line} from "recharts";
import ReactSelect from "react-select";
import {stringToColor} from "../../../utils/stringAvatarUtil";
import AdminAnalyticsService from "../../../services/api/analytics";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {PaperProps: {style: {maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250}}};

const DashboardAuthenticationGraphComponent = (props) => {

    const {breakpoints, projects, businessId} = props;

    const [selectedMonth, setSelectedMonth] = useState(null);
    const [monthOptions, setMonthOptions] = useState([]);

    const [selectedYear, setSelectedYear] = useState(null);
    const [yearOptions, setYearOptions] = useState([]);

    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);

    const [isAllProjectSelected, setAllProjectSelected] = useState(true);
    const [checkboxDisabled, setCheckboxDisabled] = useState(true);

    const [countsPipeline, setCountsPipeline] = useState(null);
    const [pipelineNames, setPipelineName] = useState(null)
    const [isFetchingDashboardCountFilter, setFetchingDashboardCountFilter] = useState(false);
    const [selectedProjectIds, setSelectedProjectIds] = useState([]);


    useEffect(() => {
        if (selectedProjects.length > 0) {
            setAllProjectSelected(false);
            setCheckboxDisabled(false);
        }
    }, [selectedProjects])

    useEffect(() => {
        if ((selectedMonth && selectedYear) && (selectedProjects)) {
            getDashboardChartCountFilter();
        }
    }, [selectedMonth, selectedYear, JSON.stringify(projects), JSON.stringify(selectedProjects)])


    const getDashboardChartCountFilter = () => {
        setFetchingDashboardCountFilter(true);

        setCountsPipeline(null);
        setPipelineName(null);

        let data = [];
        let pipeline_names = [];
        let pipeline_future = {};

        let sortedDataObject = {};

        AdminAnalyticsService.getDashboardCountFilter(businessId, selectedMonth?.value, selectedYear?.value, selectedProjects.join(',')).then((res) => {
            setFetchingDashboardCountFilter(false);

            console.log("getDashboardCountFilter data xxxx", res);

            if (res.data.success) {
                const sortedData = Object.keys(res.data.data.filter).sort((a, b) => parseInt(a) - parseInt(b));

                sortedData.forEach((key) => {
                    sortedDataObject[key] = res.data.data.filter[key];
                });

                console.log("getDashboardCountFilter data xxxx", sortedDataObject);


                Object.keys(sortedDataObject).sort((a, b) => parseInt(a) - parseInt(b)).forEach(key => {
                    let days = key;
                    let pipelineData = {};

                    pipelineData.days = days;

                    sortedDataObject[key].forEach((pipeline_data) => {
                        let application = projects?.find((proj) => proj?.id === pipeline_data?.project_id);
                    
                        if (application) {
                            let name = application?.application_name;
                    
                            pipelineData[name] = pipeline_data.count;
                    
                            if (!pipeline_names.includes(name)) {
                                pipeline_names.push(name);
                            }

                        } else {
                            console.log("Application name not found for pipeline data", pipeline_data);
                        }
                    });
            
                    data.push(pipelineData);
                })

                data.push(pipeline_future);

                setCountsPipeline(data);
                setPipelineName(pipeline_names);
            }

        }).catch((error) => {
            console.log("getDashboardCountFilter error xxxx", error);

            setFetchingDashboardCountFilter(false);
        })
    }

    useEffect(() => {
        getCurrentMonth();
        getCurrentYear();
        getProjectList();
    }, [JSON.stringify(projects)]);

    const getProjectList = () => {
        const project_options = projects?.map((project) => ({
            value: project.id,
            label: project.application_name
        }))

        setProjectOptions(project_options);
    }
  
    const getCurrentMonth = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
  
        const monthOptions = [
            {value: 1, label: "January"},
            {value: 2, label: "February"},
            {value: 3, label: "March"},
            {value: 4, label: "April"},
            {value: 5, label: "May"},
            {value: 6, label: "June"},
            {value: 7, label: "July"},
            {value: 8, label: "August"},
            {value: 9, label: "September"},
            {value: 10, label: "October"},
            {value: 11, label: "November"},
            {value: 12, label: "December"},
        ]   
  
        const selectedMonthOption = monthOptions.find(option => option.value === currentMonth);
  
        setMonthOptions(monthOptions);
        setSelectedMonth(selectedMonthOption);
    }

    const getCurrentYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const yearOptions = [];

        for (let i = currentYear; i >= currentYear - 1; i--) {
            yearOptions.push({value: i, label: i.toString()});
        }

        const selectedYearOption = yearOptions.find(option => option.value === currentYear);

        setYearOptions(yearOptions);
        setSelectedYear(selectedYearOption)
    }

    const handleProjectChange = (event) =>   {
        setAllProjectSelected(false);
        setCheckboxDisabled(false);

        const {value} = event.target;

        setSelectedProjects(value);
    }

    const renderSelectedValues = (selected) => {
        return selected
            .map((value) => projectOptions.find((option) => option.value === value)?.label)
            .join(',');
    }

    const randomColor = () => {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
      
        for (let i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }

        return color;
    }

    // if using autocomplete handleChange
    // const handleChange = (event, newValues, reason) => {
    //     setSelectedProjects(newValues);

    //     if (reason === "clear") {
    //         setSelectedProjectIds([]);

    //     } else if (reason === "removeOption") {
    //         setSelectedProjectIds(newValues.map((project) => project.value));
    //     }
    // }

    // const handleFilterAuthentication = (e) => {
    //     setSelectedProjectIds(selectedProjects.map((project) => project.value)); 
    // }


    return (
        <Box sx={{margin: "auto"}} className="card-graph">
            <Grid container sx={{mb: 2, mt: 2}}>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <ReactSelect
                        options={monthOptions}
                        value={selectedMonth}
                        onChange={selectedOption => setSelectedMonth(selectedOption)}
                        isSearchable={true}
                        className="card-month"
                    />
                </Grid> &nbsp; &nbsp;

                <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                    <ReactSelect
                        options={yearOptions}
                        value={selectedYear}
                        onChange={selectedOption => setSelectedYear(selectedOption)}
                        isSearchable={true}
                        className="card-year"
                    />
                </Grid>

                {/* filter selected projects using autocomplete
                <Grid item xs={3} sm={4} md={4} lg={4} xl={3} sx={{ml: "auto", display: "flex", mr: "34px !important"}}>
                    <FormControl className="card-project" sx={{minWidth: "100%", maxWidth: 200, backgroundColor: "#fff"}}>
                        <Autocomplete
                            disableCloseOnSelect={true}  
                            multiple
                            options={projectOptions}
                            value={selectedProjects}
                            onChange={handleChange}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox checked={selected} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} 
                                        style={{marginRight: 8}} checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Selected Project" 
                                    size="small"
                                />
                            )}
                            PaperComponent={({children}) => (
                                <Paper style={{maxWidth: "100%", textAlign: "start", marginRight: "auto"}}>
                                    {children}
                                </Paper>
                            )}
                        />
                    </FormControl>

                    <IconButton sx={{"&:hover": {backgroundColor: "transparent"}}} style={{paddingTop: 6, paddingBottom: 6}} 
                        onClick={(e) => handleFilterAuthentication(e)}
                    >
                        <FilterAltIcon style={{height: "30px", width: "30px", color: "rgb(0 0 0 / 47%)"}} /> 
                    </IconButton>
                </Grid> */}

                <Grid item xs={3} sm={4} sx={{ml: "auto"}}>
                    <FormControl className="card-project" sx={{minWidth: "100%", maxWidth: 200, backgroundColor: "#fff", "&:focus-within": {borderColor: "gray"}}}>
                        <InputLabel sx={{mt: "-8px"}} id="demo-multiple-checkbox-label" shrink={(!isAllProjectSelected && !checkboxDisabled) ? true : false}>
                            Selected Project
                        </InputLabel>
                        
                        <Select
                            style={{height: 38}}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedProjects}
                            onChange={handleProjectChange}
                            input={<OutlinedInput sx={{color: "#000"}} label="Selected Project" />}
                            renderValue={(!isAllProjectSelected && !checkboxDisabled) ? renderSelectedValues : null}
                            MenuProps={MenuProps}
                        >
                            {(projectOptions?.length > 0) ?
                                projectOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox  
                                            checked={selectedProjects.includes(option.value, option.label)} 
                                        />

                                        <ListItemText primary={option.label} />
                                    </MenuItem>
                                ))
                            :
                                <MenuItem disabled>
                                    No available projects
                                </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
             
            <Card sx={{borderRadius: 2, p: "24px 16px 16px 16px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                <ResponsiveContainer width="100%" height={420}>
                    {isFetchingDashboardCountFilter && !countsPipeline ? 
                        <>
                            <Skeleton variant="rectangular" sx={{height: "280px", width: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />                     
                        </>

                    :
                        <LineChart data={countsPipeline} margin={{top: 5, right: 20, left: 20, bottom: 5}} style={{margin: "auto"}}>
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey={'days'} />

                            <YAxis />

                            <Tooltip />

                            <Legend />

                            {pipelineNames?.map((pipeline, index)=>{
                                return (
                                    <>
                                        <Line type="monotone" 
                                            dataKey={pipeline} 
                                            stroke={stringToColor(pipeline)} 
                                            activeDot={{r: 8}} 
                                        />
                                    </>
                                )
                            })}
                        </LineChart>
                    }
                </ResponsiveContainer>
            </Card>
        </Box>
    )
}

export default DashboardAuthenticationGraphComponent