import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import useUsers from "../../../hooks/useUsers";
import useWidth from "../../../hooks/useWidth";
import { ThreeDots } from 'react-loader-spinner';
import useRolesManagement from "../../../hooks/useRolesManagement";
import useProjects from "../../../hooks/useProjects";
import ReactSelect from "react-select";
import UserPasswordDialog from "../../account/dialogs/user-password.dialog";


const CreateProjectUserDialog = (props) => {

    const { openAddDialog, handleCloseAddDialog, projectId, page } = props;

    const breakpoints = useWidth();
    const { isCreatingUser, created, user_password, addUser } = useUsers();
    const { isLoading, getRoles, rolesList, pagination, retrieved, getAllRoles } = useRolesManagement();
    const { getProjectList, projects, loadingProjects } = useProjects();

    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [role_id, setRoleId] = useState("");
    const [open, setOpen] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    // let businessID = (new URLSearchParams(window.location.search)).get("id");
    const businessID = (new URLSearchParams(window.location.search)).get("id");
    const menuPortalTargetRef = useRef(null);

    useEffect(() => {
        getAllRoles();
    }, [])


    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(true);
        setEmptyEmail(false);
    }

    const handleSubmit = (e) => {

        let payload = {
            email: email,
            role: role_id,
            project_id: projectId
        }

        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        const checkValidEmail = regex.test(email);

        if (email == "" || email == " " || email == "  ") {
            setEmptyEmail(true);

        } else if (!checkValidEmail && email !== "") {
            setValidEmail(false);

        } else {
            setEmptyEmail(false);
            setValidEmail(true);
            addUser(businessID, payload);
        }
    }

    const handleChangeRole = (selectedOption) => {
        setRole(selectedOption);
        setRoleId(selectedOption?.value);
    }

    const handleCloseUserPassword = (e) => {
        setOpen(false);
    }

    const handleCloseAllDialogs = (e) => {
        handleCloseAddDialog();
        handleCloseUserPassword();
        // window.location.reload();
        props.getUsersUnderEnterpriseProject(businessID, projectId, page);
    }

    useEffect(() => {
        if (created) {
            setOpen(true);
        }
    }, [created])

    return (
        <>
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{ style: { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5, margin: "auto" } }}>
                <DialogTitle>
                    Add User
                </DialogTitle>

                <Divider />

                <DialogContent sx={{ padding: breakpoints == "xs" || breakpoints == "sm" ? 3 : 6, paddingTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Email :</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                placeholder="Email"
                                value={email}
                                sx={{fontSize: 12}}
                                onChange={handleChangeEmail}
                                helperText={
                                    <Typography sx={{ fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px" }}>
                                        {(emptyEmail) ? "Email is required, please input email" :
                                            (!validEmail) ? "Email is invalid, please input valid email" : ""}
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>Select Role :</Typography>
                        </Grid>
                        <Grid item xs={12} ref={menuPortalTargetRef}>
                            <ReactSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                onChange={handleChangeRole}
                                options={rolesList.map(role => ({ value: role.role, label: role.role }))}
                                isSearchable
                                isClearable
                                menuPortalTarget={menuPortalTargetRef.current}
                                menuPosition="fixed"
                                placeholder={
                                    <Typography sx={{ color: "#000", fontSize: 12 }}>
                                        Select Role
                                    </Typography>
                                }
                                styles={{
                                    menuPortal: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                    }),
                                    menu: provided => ({
                                        ...provided,
                                        zIndex: 9999,
                                        fontSize: 12
                                    }),
                                    control: base => ({
                                        ...base,
                                        height: 56,
                                        backgroundColor: 'transparent',
                                        fontSize: 12
                                    }),
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>


                <DialogActions sx={{ padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 0, paddingBottom: 3 }}>
                    <Button sx={{ color: "black", textTransform: "none" }} onClick={handleCloseAddDialog}>
                        Cancel
                    </Button>

                    <Button variant="contained" onClick={(e) => handleSubmit(e)}
                        sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        startIcon={isCreatingUser &&
                            <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                        }
                    >
                        {isCreatingUser ? "Adding" : "Add"}
                    </Button>
                </DialogActions>

            </Dialog>

            {open &&
                <UserPasswordDialog password={user_password} openDialog={open} handleClose={handleCloseAllDialogs} />
            }
        </>
    )
}

export default CreateProjectUserDialog