import {useEffect, useState} from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TextField, InputAdornment, Button, Card, Pagination, IconButton} from "@mui/material";
import ProjectBillingComponent from "./project-billing.component";
import ProjectBillingSkeletonComponent from "./project-billing-skeleton.component";
import useReports from "../../../hooks/useReports";
import UploadSoaDialogComponent from "./dialog/upload-soa-dialog.component";
// import SearchIcon from "@mui/icons-material/Search";
// import UploadSoaDialogComponent from "../components/billing/dialog/upload-soa-dialog.component";

const TableHeader = () => {
    const tablecell = [
        {label: "File Name"},
        {label: "Reference"},
        {label: "Notes"},
        {label: "Date Uploaded"},
        {label: "Action"},
    ];

    return (
        <TableHead sx={{borderRadius: 2}}>
            <TableRow sx={{position: "sticky", bgcolor: "none", borderRadius: 2}}>
                {tablecell.map((value) => {
                    return(
                        <TableCell sx={{fontWeight: 700, backgroundColor: "#0178f2", color: "#0178f2", bgcolor: "none"}}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )    
}

const ProjectBillingScreen = (props) => {

    const {breakpoints, project_name, projectId} = props;

    const businessId = (new URLSearchParams(window.location.search)).get("id");

    const [page, setPage] = useState(1);
    const [openUploadSoa, setOpenUploadSoa] = useState(false);
    const [searchFilename, setSearchFilename] = useState("");


    console.log("projectIdxxx three", projectId)

    const { data, uploadSOA, retrieveProjectSOA, retrieveSOA, isFetchingBillingList, pagination } = useReports();


    const searchItems = (e) => {
        setSearchFilename(e.target.value);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) { 
            // retrieveProjectSOA(projectDetails.id, e.target.value, page);

        } else if (e.keyCode === 8 || e.key === "Backspace") {
            // retrieveProjectSOA(projectDetails?.id, "", page, );
        }
    }

    const handleOpenUploadSoa = () => {
        setOpenUploadSoa(true);
    }

    const handleChangePagination = (event, page) => {
        setPage(page);

        // if (searchFilename) {
        //     // retrieveProjectSOA(projectDetails.id, searchFilename,  page);

        // } else {
        //     // retrieveProjectSOA(projectDetails.id, "", page);
        // }
        retrieveProjectSOA(projectId, businessId, '', page);
    }

    useEffect(() => { 
        retrieveProjectSOA(projectId, businessId, '', page);
    }, [])


    return (
        <Box sx={{overflow: "hidden", mt: 1, p: 3, pt: 0}}>
            <Grid container spacing={2} sx={{pt: 1, pb: 1}}>
                <Grid item xs={6} sx={{textAlign: "start"}}>
                    <Typography variant="h6">
                        Billing
                    </Typography>
                </Grid>
            </Grid>
            
            <Card sx={{width: "100%", border: "1px solid #80808040", p: 2}}>
                <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
                    <Typography sx={{fontWeight: "bold"}}>
                        {project_name + " " + "SOA"}
                    </Typography>

                    {/* <Box sx={{flexGrow: 1}} />

                    <Button
                        className="card-add"
                        variant="contained"
                        onClick={handleOpenUploadSoa}
                        sx={{
                            backgroundColor: '#0178f2',
                            borderRadius: 1,
                            textTransform: 'none',
                            boxShadow: 'none',
                            fontSize: 12,
                            padding: '5px 15px 5px 15px',
                            minHeight: '5px'     
                        }}
                    >
                        Upload SOA
                    </Button> */}
                </Box>

                {/* <Box sx={{mt: 2, mb: 2, textAlign: 'start'}}>
                    <TextField
                        className="card-search"
                        variant="outlined" 
                        autoComplete="off"
                        sx={{"& .MuiInputBase-root": {height: 38, fontSize: 12}}}
                        inputProps={{style: {padding: "10px" }}}
                        placeholder="Search Filename..."
                        defaultValue={searchFilename}
                        onChange={searchItems}
                        onKeyDown={keyPress}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end"> 
                                    <IconButton aria-label="toggle password visibility" edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment> 
                        }}
                    />
                </Box> */}

                <TableContainer component={Paper} sx={{border: "none", borderRadius: 2, width: "100%", margin: "auto", mt: "4px"}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader />

                            {isFetchingBillingList ? 
                                <TableBody>
                                    <ProjectBillingSkeletonComponent />
                                </TableBody>
                            :
                                <TableBody>
                                    {data.map((list) => {
                                        return(
                                            <ProjectBillingComponent 
                                                list={list} 
                                                page={page} 
                                                projectId={projectId}
                                                businessId={businessId} 
                                                retrieveProjectSOA={retrieveProjectSOA}
                                            />
                                        )
                                    })}
                                    {data.length === 0 &&
                                        <TableRow key={0}>
                                            <TableCell colSpan={5} align="center">
                                                <Typography sx={{textAlign: "center", color: 'gray'}}>
                                                    No SOA uploaded yet.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            }
                    </Table>

                    {/* {!isFetchingBillingList && data.length !== 0 &&
                        <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30}}
                        />
                    } */}
                </TableContainer>
            </Card>

            {openUploadSoa && 
                <UploadSoaDialogComponent 
                    page={page} 
                    projectId={projectId} 
                    openUploadSoa={openUploadSoa} 
                    setOpenUploadSoa={setOpenUploadSoa} 
                    retrieveProjectSOA={retrieveProjectSOA}
                    businessId={businessId}
                />
            }
        </Box>
    )
}

export default ProjectBillingScreen