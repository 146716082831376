import { useState } from 'react';
import EsignProjecWhitelistDomainsService from '../services/api/esign-project/whitelist-domains';
import { useSnackbar } from 'notistack';

function useEsignProjectWhitelistDomains() {

  const { enqueueSnackbar } = useSnackbar();

  const [whitelist_domains, setWhitelistDomains] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSignatoryWhitelistDomains = (projectId, businessId, page, limit) => {
    setLoading(true)
    EsignProjecWhitelistDomainsService.getSignatoryWhitelistDomains(projectId, page, limit, businessId).then(res => {
      setLoading(false)
      console.log("res of getSignatoryWhitelistDomains: ", res)
      setWhitelistDomains(res.data.data);
    }).catch((err) => {
      console.log("error here: ", err)
      setLoading(false)
      setError(err.response.data);
      if (err.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (err.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });
      } else {
        //nothing
      }


    });
  };

  const viewSignatoryWhitelistDomain = (id, projectId, businessId) => {
    setLoading(true)
    EsignProjecWhitelistDomainsService.viewSignatoryWhitelistDomain(id, projectId, businessId).then(res => {
      setLoading(false)
      console.log("res of viewSignatoryWhitelistDomain: ", res)
      setData(res.data);
    }).catch((err) => {
      console.log("error here: ", err)
      setLoading(false)
      setError(err.response.data);
      if (err.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (err.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });
      } else {
        //nothing
      }

    });
  };

  const deleteSignatoryWhitelistDomain = (id) => {
    setLoading(true)
    EsignProjecWhitelistDomainsService.deleteSignatoryWhitelistDomain(id).then(res => {
      console.log("res of deleteSignatoryWhitelistDomain: ", res)
      setLoading(false)
      setData(res.data);
    }).catch((err) => {
      console.log("error here: ", err)
      setLoading(false)
      setError(err.response.data);
      if (err.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (err.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });
      } else {
        //nothing
      }

    });
  };

  const createSignatoryWhitelistDomains = (project_id, business_id, domains) => {
    setLoading(true)
    EsignProjecWhitelistDomainsService.createSignatoryWhitelistDomains(project_id, business_id, domains).then(res => {
      console.log("res of createSignatoryWhitelistDomains: ", res)
      setLoading(false)
      setData(res.data);
    }).catch((err) => {
      console.log("error here: ", err)
      setLoading(false)
      setError(err.response.data);
      if (err.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else if (err.response.data.message) {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000
        });
      } else {
        //nothing
      }

    });
  };

  return { data, whitelist_domains, loading, error, getSignatoryWhitelistDomains, viewSignatoryWhitelistDomain, deleteSignatoryWhitelistDomain, createSignatoryWhitelistDomains };

}

export default useEsignProjectWhitelistDomains;