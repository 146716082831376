import React from "react";
import {Skeleton, List, ListItem, ListItemText,Box, DialogContent} from "@mui/material";

const CustomizePlanSkeletonComponent = (props) => {

    const {breakpoints} = props;
   
    return (
        <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "20px 48px 28px"}}>
            <Box>
                <ListItem sx={{mt: "-8px", padding: "0px !important", textAlign: "center"}}>
                    <ListItemText
                        primary={
                            <Skeleton variant="text" sx={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={"50%"} />
                        }
                        secondary={
                            <Skeleton variant="text" sx={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={"70%"}  />
                        }
                    />
                </ListItem>   
            </Box>
                
            {[0, 1, 2, 3, 4]?.map((index) => (
                <List key={index} sx={{ml: 1}}>
                    <>
                        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={"100%"} />

                        <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={"100%"}/>
                    </>
                </List>
            ))}           
        </DialogContent>
    )
}

export default CustomizePlanSkeletonComponent