import React, {useState, useEffect} from "react";
import {TextField, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {FileUploader } from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import {ThreeDots} from "react-loader-spinner";
import AddImgIcon from "../../../../icons/add-img.png";
import useSignature from "../../../../hooks/useSignature";
import EditTemplateSignatureSkeleton from "../../../skeletons/edit-template-signature-skeleton.component";
import { log } from "../../../../console-config.log";
import { convertUrlToFile } from "../../../../utils/convertUrlToFileUtil";
import { isValidURL } from "../../../../utils/StringUtil";
// import {convertUrlToFile} from "../../utils/convertUrlToFileUtil";

const EditTemplateSignatureDialog = (props) => {

    const {breakpoints, openEditSignature, setOpenEditSignature, tempSignatureElement, setSignatureUpdated} = props;

    const {enqueueSnackbar} = useSnackbar();

    const {isUpdatingTemplateSignature, isFetchingTemplateSignature, viewTemplateSignature, updateTemplateSignature, setUpdatingTemplateSignature, tempSignatureDetails} = useSignature();

    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [isFullnameRequired, setFullnameRequired] = useState(false);
    const [isEmailRequired, setEmailRequired] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);

    const [imageURL, setImageURL] = useState(null);
    const [resultURL, setResultURL] = useState(null);
    const [uploadedSignature, setUploadedSignature] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    console.log("tempSignatureElement 1111", tempSignatureElement);

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
      
              // Draw the image on the canvas
              ctx.drawImage(image, 0, 0);
      
              // Get the image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
      
              // Remove the background (assuming light background and dark signature lines)
              const tolerance = 245; // Adjust this value to set the tolerance for background removal
              for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];
                const alpha = data[i + 3];
      
                // Check if the pixel color is close to the background color
                if (Math.abs(red - 255) <= tolerance &&
                    Math.abs(green - 255) <= tolerance &&
                    Math.abs(blue - 255) <= tolerance &&
                    alpha > 0) {
                  data[i + 3] = 0; // Set alpha to 0 (transparent)
                }
              }
      
              // Put the modified image data back to the canvas
              ctx.putImageData(imageData, 0, 0);
      
              // Convert the canvas content back to base64 string
              const modifiedImage = canvas.toDataURL();
      
              resolve(modifiedImage);
            };
      
            image.src = event.target.result;
          };
      
          reader.readAsDataURL(imageFile);
        });
    };

    useEffect(() => {
        if (tempSignatureElement?.id) {
            viewTemplateSignature(tempSignatureElement?.id);
        }
    }, [])

    useEffect(() => {
        if (tempSignatureDetails) {
            setEmail(tempSignatureDetails.signature.email);
            setFullname(tempSignatureDetails.signature.full_name);
            setSignatureFile(tempSignatureDetails.signature.signature_url);
            console.log("tempSignatureDetails aaa", tempSignatureDetails);
        }
    }, [JSON.stringify(tempSignatureDetails)])

    const handleChange = (file) => {
        setHasChanges(true)

        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "svg" || mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            if (file.size <= 1 * 1024 * 1024) {
                // const reader = new FileReader();

                // reader.onload = (e) => {
                //     setImageURL(e.target.result);
                // }

                // reader.readAsDataURL(file);

                setUploadedSignature(file);
                setSignaturePreview(URL.createObjectURL(file));

                // removeWhiteBackground();

            } else {
                enqueueSnackbar("File exceeds 1MB, please choose another image file", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
            
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    // const removeWhiteBackground = () => {
        
    //     const canvas = document.createElement("canvas");
    //     const ctx = canvas.getContext("2d");
    //     const image = document.createElement("img");
        
    //     image.crossOrigin = "anonymous";
    //     image.src = imageURL;
    
    //     image.onload = () => {
    //         canvas.width = image.width;
    //         canvas.height = image.height;
    
    //         ctx.drawImage(image, 0, 0);
    
    //         const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //         const data = imageData.data;
    
    //         for (let i = 0; i < data.length; i += 4) {
    //             const red = data[i];
    //             const green = data[i + 1];
    //             const blue = data[i + 2];
    //             const alpha = data[i + 3];

    //             if (red > 200 && green > 200 && blue > 200 && alpha > 200) {
    //                 data[i + 3] = 0;
    //             }
    //         }
    
    //         ctx.putImageData(imageData, 0, 0);
    
    //         setResultURL(canvas.toDataURL('image/png'));
    //     }
    // }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/;

        return re.test(email);
    }

    const handleTextFieldChange = (e, name) => {
        setHasChanges(true)
        if (name == "fullname") {
            setFullname(e.target.value);
            setFullnameRequired(false);

        } else if (name == "email") {
            let user_email = e.target.value;

            setEmail(user_email);
            setEmailRequired(false);

            if (user_email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }
        }
    }

    // const removeBackground = (imageUrl, callback) => {
    //     const image = new Image();

    //     image.crossOrigin = 'anonymous';
    //     image.src = imageUrl;
      
    //     image.onload = function () {
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');
    //         canvas.width = image.width;
    //         canvas.height = image.height;
      
    //         ctx.drawImage(image, 0, 0);
      
    //         const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //         const data = imageData.data;
      
    //         for (let i = 0; i < data.length; i += 4) {
    //             const red = data[i];
    //             const green = data[i + 1];
    //             const blue = data[i + 2];
      
    //             // check if the pixel is close to white
    //             if (red >= 200 && green >= 200 && blue >= 200) {
    //                 data[i + 3] = 0;
    //             }
    //         }
      
    //         ctx.putImageData(imageData, 0, 0);
      
    //         // convert canvas image to data URL
    //         const whitebgImageUrl = canvas.toDataURL('image/png');
      
    //         // invoke the callback function with the edited image URL
    //         callback(whitebgImageUrl);
    //     }

    //     image.onerror = function () {
    //         throw new Error("failed to load image");
    //     }
    // }

    const handleResetFile = (error) => {      
        setImageURL(null);
        setResultURL(null);
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const handleCloseDialog = (e) => {
        setOpenEditSignature(false);
        setSignatureFile(null);
        setImageURL(null);
        setResultURL(null);
    }

    const handleCancel = (e) => {
        handleCloseDialog();
    }

    const handleEditSignature = async (e) => {
        if (!email) {
            enqueueSnackbar("Email is required", { 
                variant: "error",
                autoHideDuration: 3000
            });
            return
        } 
        
        if (!validateEmail(email)) {
            enqueueSnackbar("Invalid email address format", { 
                variant: "error",
                autoHideDuration: 3000
            });
            return
        }

        let signatureImg;

        setUpdatingTemplateSignature(true)
        if (!uploadedSignature) {
            if (signatureFile) {
                if (typeof signatureFile === 'string') {
                    console.log("to be converted aa");
                    if (isValidURL(signatureFile)) {
                        signatureImg = await convertUrlToFile(signatureFile, "signature.png", "image/png")
                    }
                }
            }
        }
        else {
            signatureImg = uploadedSignature
        }

        if (!signatureImg) {
            enqueueSnackbar("Signature File is required", {
                variant: "error",
                autoHideDuration: 3000
            });
            setUpdatingTemplateSignature(false)
            return
        }

        const removedWhiteBackgroundImg = await removeWhiteBackground(signatureImg)
        const payloadFile = await convertUrlToFile(removedWhiteBackgroundImg, "payload_file.png", "image/png")

        let formData = new FormData();

        formData.append("id", tempSignatureElement?.id);
        formData.append("signature", payloadFile);
        formData.append("email", email);
        updateTemplateSignature(formData, setOpenEditSignature, setSignatureUpdated);
    }


    return (
        <Dialog open={openEditSignature} onClose={(e) => handleCloseDialog(e)} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
               Edit Signature
            </DialogTitle>

            <Divider/>
            
            {(isFetchingTemplateSignature) ?
                <EditTemplateSignatureSkeleton />

            :
                <>
                    <DialogContent sx={{padding: (signaturePreview || signatureFile) ? "1px 24px 10px" : "24px 24px 10px"}}>
                        {(signaturePreview || signatureFile) &&
                            <Grid sx={{textAlign: "end"}}>
                                <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                                    Clear
                                </Button>
                            </Grid>
                        }

                        <FileUploader
                            fileOrFiles={signatureFile}
                            multiple={false}
                            handleChange={handleChange}
                            name="file"
                            label="Select an image file to upload or drag and drop it here"
                            children={
                                <>
                                    {(signatureFile || signaturePreview) ?
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2.2px #0178f2",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "210px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear"
                                            }}
                                        >
                                            <img alt="" src={(signaturePreview) ? signaturePreview : signatureFile}
                                                style={{
                                                    height: (breakpoints == 'xs' || breakpoints == 'sm') ? 130 : 160,
                                                    width: "75%", objectFit: "contain"
                                                }}
                                            />
                                        </div>
                                    :

                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2.2px #0178f2",
                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "210px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear",
                                            }}
                                        >
                                            <Grid>
                                                <Grid sx={{ textAlign: "center" }}>
                                                    <img alt="" src={AddImgIcon} style={{width: 50, height: 50, objectFit: "contain"}} />
                                                </Grid>

                                                <Typography variant="subtitle1" style={{color: "#0178f2", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 16}}>
                                                    Select an image file to upload
                                                </Typography>

                                                <Typography variant="subtitle2" style={{textAlign: "center", color: "rgb(0 0 0 / 33%)", fontWeight: 500, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 14}}>
                                                    or drag and drop it here
                                                </Typography>
                                            </Grid>
                                        </div>
                                    }
                                </>
                            }        
                        />

                        <Grid container spacing={2} sx={{width: "100%", display: "flex", mt: "16px !important", margin: "auto"}}>
                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{pl: "0px !important"}}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    placeholder="Full Name"
                                    value={fullname}
                                    onChange={(e) => handleTextFieldChange(e, "fullname")}
                                    inputProps={{style: {padding: "10px", fontSize: 14}}}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": {borderColor: "#0178f2 !important"}
                                        }
                                    }}
                                    helperText={
                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -12px"}}>
                                            {(isFullnameRequired) ? "Full Name is required" : ""}
                                        </p>
                                    }
                                />
                            </Grid> */}
                            
                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{pl: (breakpoints == "xs" || breakpoints == "sm") ? "0px !important" : {}}}> */}
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => handleTextFieldChange(e, "email")}
                                    inputProps={{style: {padding: "10px", fontSize: 14}}}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": {borderColor: "#0178f2 !important"}
                                        }
                                    }}
                                    helperText={
                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -12px"}}>
                                            {(isEmailRequired) ? "Email is required" : (!isValidEmail) ? "Email Address is invalid" : ""}
                                        </p>
                                    }
                                />
                            {/* </Grid> */}
                        </Grid>
                    </DialogContent>
        
                    <DialogActions sx={{padding: "8px 22px 20px 0px"}}>
                        <Button sx={{width: "15%", borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14}} variant="outlined"
                            onClick={(e) => handleCancel(e)}
                        >
                            Cancel
                        </Button>

                        <Button variant="contained" sx={{borderRadius: 10, textTransform: "none", fontSize: 14, backgroundColor: "#0178f2",
                            width: (isUpdatingTemplateSignature) ? "auto" : "15%", '&:hover': {backgroundColor: "#0178f2", color: "white"}}} 
                            startIcon={(isUpdatingTemplateSignature) && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                            onClick={(e) => handleEditSignature(e)}
                            disabled={!hasChanges || isUpdatingTemplateSignature}
                        >
                            {(isUpdatingTemplateSignature) ? "Saving" : "Save"}
                        </Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default EditTemplateSignatureDialog