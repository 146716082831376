import {useSnackbar} from "notistack";
import {useState} from "react";
import APIMService from "../services/api/product";

const useApim = () => {

  const {enqueueSnackbar} = useSnackbar();
  const [isUpdatePlan, setUpdatePlan] = useState(false);
  const [getProduct, setGetProducts] = useState(null);
    
  const changeProduct = (id, payload) => {
    setUpdatePlan(true);

    APIMService.changeProduct(id, payload).then(res => {
      if (res.data.success) {
        setUpdatePlan(false);

        enqueueSnackbar("Successfully changed plan", { 
          variant: "success",
          autoHideDuration: 3000
        });
      }

    }).catch((error) => {
      setUpdatePlan(false);

      enqueueSnackbar(error.response.data.error, { 
        variant: 'error',
        autoHideDuration: 3000
      });      
    })
  }

  const getProducts = () => {
    APIMService.getProducts().then(res => {
      console.log("getProducts data xxxx", res);
      setGetProducts(res.data.data);

    }).catch((error) => {
      console.log("getProducts error xxxx", error);
    })
  }

  return {changeProduct, getProducts, getProduct, isUpdatePlan}

}

export default useApim