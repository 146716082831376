import {useEffect, useState} from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Stack, Select, MenuItem, TextField, InputAdornment, FormControl, InputLabel, Button, Pagination} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import useProjects from "../../hooks/useProjects";
import useActivityLogs from "../../hooks/useActivityLogs";
import EnterpriseActivityLogsComponent from "../../components/enterprise/activity-logs/enterprise-activity-logs.component";
import ActivityLogsSkeletonComponent from "../../components/enterprise/activity-logs/activity-logs-skeleton.component";

function TableHeader() {
    const tablecell = [
        {label: "Email"},
        {label: "Project"},
        {label: "Module"},
        {label: "Actions"},
        {label: "Activity"},
        {label: "Timestamp"},
    ];

    return (
        <TableHead sx={{borderRadius: 2}}>
            <TableRow sx={{ position: "sticky", bgcolor: "none", borderRadius: 2}}>
                {tablecell.map((value) => {
                    return (
                        <TableCell sx={{fontWeight: 700, backgroundColor: "#0178f2", color: "#0178f2", bgcolor: "none"}}>
                            {value.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

const EnterpriseProjectActivityLogsScreen = (props) => {

    const {projectId, breakpoints, project_name} = props;

    const {isFetchingActivityLogs, auditList, pagination, getEnterpriseProjectAuditLogs, 
        enterpriseProjectSearchActivityLogsFilters, searchEnterpriseProjectActivityLogsList
    } = useActivityLogs();

    const {projects, getProjectList} = useProjects();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [monthValue, setMonthValue] = useState("");
    const [page, setPage] = useState(1);
    const [action, setAction] = useState("");
    const [module, setModule] = useState("");
    const [searchAuditLogs, setSearchAudit] = useState("");
    const [isMonthSelected, setIsMonthSelected] = useState(false);

    const businessId = (new URLSearchParams(window.location.search)).get("id");

    useEffect(() => {
        getProjectList(businessId);

        if (auditList?.length > 0) {
            auditList?.map((list) => {
                let project_id = list?.projectId;

                projects?.map((project) => {
                    if (project?.id == project_id) {
                        list.project_name = project?.application_name;
                    }
                })
            })
        }
    }, [JSON.stringify(auditList), JSON.stringify(projects)])

    useEffect(() => {
        getEnterpriseProjectAuditLogs(businessId, projectId, page);
    }, []);


    const generateArrayOfYears = () => {
        var current_year = new Date().getFullYear();
        var years = [];

        for (var i = 2022; i <= current_year; i++) {
            let year = {value: i};

            years.push(year);
        }

        return years;
    }

    var years = generateArrayOfYears();

    years.sort(function (a, b) {
        return b.value - a.value;
    });

    const getMonthNames = () => {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        return monthNames.map((monthName, index) => {
            const monthValue = (index + 1).toString().padStart(2, "0");

            return {
                name: monthName,
                value: monthValue,
            };
        });
    }

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]

    const monthName = monthNames[monthValue - 1];

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        const mName = monthNames[event.target.value - 1];
        const paddedMonth = selectedMonth.toString().padStart(2, "0");

        setMonth(paddedMonth);
        setMonthValue(selectedMonth);
        setIsMonthSelected(true);

        if (searchAuditLogs) {
            searchEnterpriseProjectActivityLogsList(businessId, projectId, mName, year, module, action, page, searchAuditLogs);

        } else {
            enterpriseProjectSearchActivityLogsFilters(businessId, projectId, mName, year, module, action, page);
        }
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);

        const yearChange = event.target.value;

        if (searchAuditLogs) {
            searchEnterpriseProjectActivityLogsList(businessId, projectId, monthName, yearChange, module, action, page, searchAuditLogs);

        } else {
            enterpriseProjectSearchActivityLogsFilters(businessId, projectId, monthName, yearChange, module, action, page);
        }
    }

    const handleChangePagination = (event, page) => {
        setPage(page);
    
        if (!searchAuditLogs) {
            if (module || action || year || month) {
                enterpriseProjectSearchActivityLogsFilters(businessId, projectId, monthName, year, module, action, page);
            }

        } else {
            searchEnterpriseProjectActivityLogsList(businessId, projectId, monthName, year, module, action, page, searchAuditLogs);
        }
    }
    
    const searchModule = (e) => {
        setModule(e.target.value);
    }

    const keyPressModule = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchEnterpriseProjectActivityLogsList(businessId, projectId, monthName, year, module, action, page, searchAuditLogs);

            } else {
                enterpriseProjectSearchActivityLogsFilters(businessId, projectId, monthName, year, module, action, page);
            }
        }
    }

    const searchAction = (e) => {
        setAction(e.target.value);
    }

    const keyPressAction = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchEnterpriseProjectActivityLogsList(businessId, projectId, monthName, year, module, action, page, searchAuditLogs);
            
            } else {
                enterpriseProjectSearchActivityLogsFilters(businessId, projectId, monthName, year, module, action, page);
            }
        }
    }

    const searchAudit = (e) => {
        setSearchAudit(e.target.value);
    }

    const keyPressAudit = (e) => {
        if (e.keyCode === 13) {
            if (searchAuditLogs) {
                searchEnterpriseProjectActivityLogsList(businessId, projectId, monthName, year, module, action, page, searchAuditLogs);
            
            } else {
                enterpriseProjectSearchActivityLogsFilters(businessId, projectId, monthName, year, module, action, page);
            }
        }
    }

    const handleReload = () => {
        setYear("");
        setModule("");
        setAction("");
        setMonthValue("");
        setSearchAudit("");
        setIsMonthSelected(false);
        getEnterpriseProjectAuditLogs(businessId, projectId, page);
    }

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = auditList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);


    if (isFetchingActivityLogs) {
        return (
            <Box sx={{overflow: "hidden"}}>
                <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
                    <Grid container sx={{textAlign: "start", margin: "8px 0px -12px 2px"}}>
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                {project_name} Activity Logs
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container>
                    <Grid item xs={12} sm={12} sx={{display: "flex", alignItems: "center", alignSelf: "center", mb: 0, backgroundColor: "white", p: "16px 0px 16px 0px", borderRadius: 2}}>
                        <Stack direction="row" spacing={1} sx={{width: "99%", margin: "auto"}}>
                            <Box>
                                <Stack direction="row" spacing={1} sx={{width: "99%", margin: "auto"}}>
                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={module}
                                        sx={{"& .MuiInputBase-root": {height: 38, borderRadius: 100, backgroundColor: "white", width: 200}}}
                                        inputProps={{style: {padding: "10px", fontSize: 12, fontStyle: "italic"}}}
                                        placeholder="Search Module..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" >
                                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                    Module:
                                                </p>
                                            </InputAdornment>,
                                        }}
                                        onChange={searchModule}
                                        onKeyDown={keyPressModule}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={action}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'white', width: 200 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Actions..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><p style={{ fontWeight: 'bold', fontSize: 12 }}>Actions:</p></InputAdornment>,
                                        }}
                                        onChange={searchAction}
                                        onKeyDown={keyPressAction}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={searchAuditLogs}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'white', width: 200 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Activity..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>Activity:</p></InputAdornment>,
                                        }}
                                        onChange={searchAudit}
                                        onKeyDown={keyPressAudit}
                                    />
                                </Stack>
                            </Box>

                            <Box sx={{flexGrow: 1}} />

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{mt: -.8, fontSize: 12, fontWeight: 'bold'}}>
                                    Month
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    value={month}
                                    sx={{ width: 150, mr: 2, height: 38, borderRadius: 10, backgroundColor: 'white' }}
                                    onChange={handleMonthChange}
                                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}

                                >
                                    {getMonthNames().map((monthInfo, index) => (
                                        <MenuItem key={monthInfo.value} value={monthInfo.value}>
                                            {monthInfo.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                    Year
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Year"
                                    value={year}
                                    sx={{
                                        width: 150, height: 38, borderRadius: 10, backgroundColor: 'white',
                                        '&.Mui-focused': {
                                            border: 'none',
                                        },
                                        border: isMonthSelected ? '1px solid red' : "",
                                    }}
                                    onChange={handleYearChange}
                                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                                >
                                    {years?.map((year) => {
                                        return (
                                            <MenuItem key={year.value} value={year.value}>
                                                {year.value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container sx={{pt: 0}}>
                    <Box sx={{width: "100%", display: "flex", alignItems: "center", mt: "12px"}}>
                        <Button sx={{textTransform: "none", justifyContent: "end", fontSize: 12, fontWeight: "bold"}}> 
                            <CachedIcon sx={{ height: 20, width: 20 }} />

                            Reload
                        </Button>

                        <Box sx={{flexGrow: 1}} />
                    </Box>

                    <TableContainer component={Paper} sx={{border: 'none', borderRadius: 2, width: '100%'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHeader />

                            <TableBody>
                                <ActivityLogsSkeletonComponent />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        )
    }

    return (
        <Box sx={{overflow: "hidden"}}>
            <>
                <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
                    <Grid container sx={{textAlign: "start", margin: "8px 0px -12px 2px"}}>
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                {project_name} Activity Logs
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container>
                    <Grid item xs={12} sm={12} sx={{display: "flex", alignItems: "center", alignSelf: "center", mb: 0, backgroundColor: "white", p: "16px 0px 16px 0px", borderRadius: 2}}>
                        <Stack direction="row" spacing={1} sx={{width: '99%', margin: 'auto'}}>
                            <Box>
                                <Stack direction="row" spacing={2} sx={{width: "99%", margin: "auto"}}>
                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={module}
                                        sx={{"& .MuiInputBase-root": {height: 38, borderRadius: 100, backgroundColor: 'white', width: 200}}}
                                        inputProps={{style: {padding: "10px", fontSize: 12, fontStyle: 'italic'}}}
                                        placeholder="Search Module..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                    Module:
                                                </p>
                                            </InputAdornment>,
                                        }}
                                        onChange={searchModule}
                                        onKeyDown={keyPressModule}
                                    />

                                    <TextField
                                         className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={action}
                                        sx={{"& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'white', width: 200 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Actions..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <p style={{fontWeight: 'bold', fontSize: 12}}>
                                                    Actions:
                                                </p>
                                            </InputAdornment>,
                                        }}
                                        onChange={searchAction}
                                        onKeyDown={keyPressAction}
                                    />

                                    <TextField
                                        className="card-search"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={searchAuditLogs}
                                        sx={{"& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'white', width: 200 } }}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder="Search Activity..."
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <p style={{fontWeight: 'bold', fontSize: 12}}>
                                                    Activity:
                                                </p>
                                            </InputAdornment>,
                                        }}
                                        onChange={searchAudit}
                                        onKeyDown={keyPressAudit}
                                    />
                                </Stack>
                            </Box>

                            <Box sx={{flexGrow: 1}} />

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                    Month
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Month"
                                    value={monthValue}
                                    sx={{ width: 150, mr: 2, height: 38, borderRadius: 10, backgroundColor: 'white' }}
                                    onChange={handleMonthChange}
                                    inputProps={{ sx: { mr: "28px", fontSize: 12, textAlign: "center" } }}
                                >
                                    {getMonthNames().map((monthInfo, index) => (
                                        <MenuItem key={monthInfo.value} value={monthInfo.value}>
                                            {monthInfo.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel id="demo-simple-select-label" sx={{ mt: -.8, fontSize: 12, fontWeight: 'bold' }}>
                                    Year
                                </InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Year"
                                    value={year}
                                    sx={{
                                        width: 150, height: 38, borderRadius: 10, backgroundColor: "white",
                                        "&.Mui-focused": {border: "none"},
                                        border: isMonthSelected ? "1px solid red" : "",
                                    }}
                                    onChange={handleYearChange}
                                    inputProps={{sx: {mr: "28px", fontSize: 12, textAlign: "center"}}}
                                >
                                    {years?.map((year) => {
                                        return (
                                            <MenuItem key={year.value} value={year.value}>
                                                {year.value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container sx={{pt: 0}}>
                    <Box sx={{width: "100%", display: "flex", alignItems: "center", mt: "12px"}}>
                        <Button sx={{textTransform: "none", justifyContent: "end", fontSize: 12, fontWeight: "bold"}}
                            onClick={() => handleReload()}
                        > 
                            <CachedIcon sx={{ height: 20, width: 20 }} />

                            Reload
                        </Button>

                        <Box sx={{flexGrow: 1}} />
                    </Box>

                    <TableContainer component={Paper} sx={{border: "none", borderRadius: 2, width: "100%"}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHeader />

                            {isFetchingActivityLogs ?
                                <TableBody>
                                    <ActivityLogsSkeletonComponent />
                                </TableBody>

                            :
                                <TableBody>
                                    {(currentItems.length > 0) ?
                                        <>
                                            {(currentItems && currentItems)?.map((list, index) => (
                                                // reuse enterprise activity logs component for enterprise project activity logs
                                                <EnterpriseActivityLogsComponent list={list} />
                                            ))}
                                        </>
                                    :
                                        <>
                                            {!isFetchingActivityLogs && (
                                                <TableRow key={0}>
                                                    <TableCell colSpan={6} align="center">
                                                        <Typography sx={{textAlign: "center"}}>
                                                            No activity logs available.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </>
                                    }
                                </TableBody>
                            }
                        </Table>

                        {!isFetchingActivityLogs && auditList.length > itemsPerPage && (
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: "auto", width: "fit-content", paddingTop: 3, paddingBottom: 3 }}>
                                <Pagination count={Math.ceil(auditList.length / itemsPerPage)} page={currentPage} onChange={(event, value) => paginate(value)} />
                            </Grid>
                        )}

                        {/* <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30}}
                        /> */}
                    </TableContainer>
                </Grid>
            </>
        </Box>
    )
}

export default EnterpriseProjectActivityLogsScreen