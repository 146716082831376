import {Skeleton, Typography, Card, CardContent, Grid} from "@mui/material";

const UpdateProfileSkeletonComponent = ({breakpoints}) => {
    
    return (
        <Card sx={{borderRadius: 0, padding: "8px 24px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: "25px auto", width: "95%"}}>
            <CardContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "16px 0px 0px 0px" : "32px 60px 24px 60px"}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography sx={{fontSize: 19, fontWeight: "bold"}}>
                        Update Account
                    </Typography>
                </Grid>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{mt: 1}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Skeleton variant="circular" style={{margin: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}, backgroundColor: "rgb(108 139 222 / 30%)", width: 100, height: 100}} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}, display: "flex"}}>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, width: "30%", borderRadius: "4px/6.7px", marginLeft: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}}} />
                        
                        &nbsp; &nbsp; &nbsp;
                        
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, width: "30%", borderRadius: "4px/6.7px", marginRight: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : {}}} />
                    </Grid>   
                </Grid>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{mt: 2}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                           Email:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Description:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />                   
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Contact Number:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", height: 30, borderRadius: "4px/6.7px"}} />
                    </Grid>

                    <Grid item xs={12} sx={{textAlign: "end", mt: 1}}>
                        <Skeleton variant="rectangular" style={{backgroundColor: "rgb(108 139 222 / 30%)", borderRadius: "4px/6.7px", height: 30, width: "30%", marginLeft: "auto"}} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UpdateProfileSkeletonComponent
