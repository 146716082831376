import {useState} from "react";
import {useSnackbar} from "notistack";
import EmailSenderService from "../services/api/email-sender";

const useEmailSender = () => {

    const [isFetchingEmailSender, setFetchingEmailSender] = useState(false);
    const [isSearchingEmailSender, setSearchingEmailSender] = useState(false);
    const [emailSenderList, setEmailSenderList] = useState([]);
    const [isSearchToggle, setSearchToggle] = useState(false);
    const [isSearchedResultsEmpty, setSearchedResultsEmpty] = useState(false);
    const [isEmailSenderListEmpty, setEmailSenderListEmpty] = useState(false);
    const [searchedEmailSender, setSearchedEmailSender] = useState([]);
    const [isLoadingTestEmailSender, setIsLoadingTestEmailSender] = useState(false);
    const [pagination, setPagination] = useState(0)

    var limit = 10;

    const { enqueueSnackbar } = useSnackbar();
    
    const getListEmailSender = (projectId, page) => {
        setFetchingEmailSender(true);
        setSearchToggle(false);
        setSearchedResultsEmpty(false);

        EmailSenderService.getListEmailSender(projectId, page).then((res) => {
            console.log("getListEmailSender data xxxx", res);

            if (res.data.success) {
                setFetchingEmailSender(false);
                setEmailSenderList(res.data.data);
                if (res.data.data.length === 0) {
                    setPagination(0)
                  } else {
                    var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                    console.log("last_page", last_page);
                    setPagination(last_page)
                }

                if (res.data.data.length === 0) {
                    setEmailSenderListEmpty(true);

                } else {
                    setEmailSenderListEmpty(false);
                }
            }

        }).catch((error) => {
            setFetchingEmailSender(false);
            console.log("email sender error", error)

        })
    }

    const viewEmailSender = (id) => {
        setFetchingEmailSender(true);

        EmailSenderService.viewEmailSender(id).then((res) => {
            console.log("viewEmailSender data xxxx", res);

            if (res.data.success) {
                setFetchingEmailSender(false);
            }

        }).catch((error) => {
            setFetchingEmailSender(false);
        })
    }

    const searchEmailSenderByEmail = (email) => {
        setSearchingEmailSender(true);
        setSearchToggle(true);

        EmailSenderService.searchEmailSenderByEmail(email).then((res) => {
            console.log("searchEmailSenderByEmail data xxxx", res);
            setSearchingEmailSender(false);

            if (res.data.data.length === 0) {
                setPagination(0)
              } else {
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                console.log("last_page", last_page);
                setPagination(last_page)
            }

        }).catch((error) => {
            setFetchingEmailSender(false);
            setSearchToggle(false);
            setSearchedResultsEmpty(false);
        })
    }

    const testEmailSender = (id, email) => {
        setIsLoadingTestEmailSender(true);
        EmailSenderService.testEmailSender(id, email).then((res) => {
            console.log("testEmailSender data xxxx", res);
            setIsLoadingTestEmailSender(false);
            if (res.data.success) {
                enqueueSnackbar(res.data.message, {
                variant: "success",
                autoHideDuration: 3000
                });
            }
        }).catch((error) => {
            setIsLoadingTestEmailSender(false);
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    return {
        isFetchingEmailSender, emailSenderList, getListEmailSender,
        viewEmailSender, isSearchedResultsEmpty, isEmailSenderListEmpty,
        isSearchingEmailSender, isSearchToggle, searchEmailSenderByEmail, searchedEmailSender,
        testEmailSender, isLoadingTestEmailSender, pagination
    }
}

export default useEmailSender