import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPut1} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.business_account_service.subscriptionKey;

const getListEmailSender = (projectId, page) => {

    let parameters = {
        projectId: projectId,
        page: page,
    };

    console.log("email sender parameters", parameters)

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GET_LIST_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const searchEmailSenderByEmail = (email) => {

    let parameters = {
        email: email,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.SEARCH_EMAIL_SENDER_BY_EMAIL, parameters, subscriptionKey);
    
    return result;
}

const viewEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.VIEW_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const revokeEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.REVOKE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const approveEmailSender = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.APPROVE_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const deniedEmailSender = (id, payload) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.DENIED_EMAIL_SENDER, parameters, payload, subscriptionKey);
    
    return result;
}

const resetStatus = (id) => {

    let parameters = {
        id: id,
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPut1(routes.RESET_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}

const testEmailSender = (id, to) => {

    let parameters = {
        id,
        to
    };

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.TEST_EMAIL_SENDER, parameters, subscriptionKey);
    
    return result;
}


const EmailSenderService = {
    getListEmailSender: getListEmailSender,
    searchEmailSenderByEmail: searchEmailSenderByEmail,
    viewEmailSender: viewEmailSender,
    revokeEmailSender: revokeEmailSender,
    approveEmailSender: approveEmailSender,
    deniedEmailSender: deniedEmailSender,
    resetStatus: resetStatus,
    testEmailSender: testEmailSender
}

export default EmailSenderService