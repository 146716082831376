import {Skeleton, Grid, Card, CardContent, Typography, CardActionArea} from "@mui/material";

const EnterpriseOverviewComponent = (props) => {

    const {businessOverviewStats, isFetchingBusinessOverviewStats} = props;

    const business_overview_stats = [
        {label: "Active Projects", value: businessOverviewStats?.active_projects},
        {label: "Inactive Projects", value: businessOverviewStats?.inactive_projects},
        {label: "Total Projects", value: businessOverviewStats?.total_projects},
        {label: "Active Users", value: businessOverviewStats?.active_users},
        {label: "Inactive Users", value: businessOverviewStats?.inactive_users},
        {label: "Total Users", value: businessOverviewStats?.total_users},
    ]

    return (
        <Grid container spacing={2}>
            {business_overview_stats.map((stats) => {
                return (
                    <Grid item xs={4} sm={2}>
                        <Card sx={{cursor: "default", margin: "auto"}}>
                            <CardActionArea sx={{cursor: "default", minHeight: 126, color: "#0178f2"}}>
                                <CardContent sx={{textAlign: "center"}}>
                                    {isFetchingBusinessOverviewStats ?
                                        <Skeleton variant="rectangular" 
                                            sx={{margin: "auto", borderRadius: "4px", mb: "3px", 
                                                height: "35px", width: "50px", backgroundColor: "rgb(108 139 222 / 30%)"
                                            }} 
                                        />                     
                                    :                                                     
                                        <Typography variant="h2" sx={{margin: "auto", fontSize: 20}}>
                                            {stats.value}
                                        </Typography>
                                    }
                                                                    
                                    <Typography sx={{margin: "auto", color: "rgba(0,0,0,.3)", fontSize: 12}}>
                                        {stats.label}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default EnterpriseOverviewComponent
