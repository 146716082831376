import { Avatar, Box, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ViewPermissionComponent from "../components/rbac/view-permission.component";
import usePermissionManagement from "../hooks/usePermissionManagement";
import { format } from 'date-fns';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import useAccessControl from "../hooks/useAccessControl";
import AccessControlService from "../services/api/access-control";
import { useSnackbar } from "notistack";

const ViewAccessControlScreen = (props) => {
    const { setView, roleId, roleName } = props;

    const {enqueueSnackbar} = useSnackbar();


    const { loading, getModules, modules, getAllPermissionsFunction, functions } = usePermissionManagement();
    const { getAccessControl, accessControl, isLoading, setIsLoading,setUpdated, updated, setAdded, added } = useAccessControl();


    const [updateFlag, setUpdateFlag] = useState(false);
    const [permissionId, setPermissionId] = useState(null);

    useEffect(() => {
        getAccessControl(1, roleId);
    }, [roleId])

    useEffect(() => {
        getModules();
    }, [])

    useEffect(() => {
        getAllPermissionsFunction(modules?.id);
    }, [modules])

    const handlePermissionChange = async (moduleId, functionId, newIsAllowed, currentIsAllowed, accessControlId) => {
        const existingAccess = accessControl.find(
            (ac) =>
                ac.permission.module.id === moduleId &&
                ac.permission.id === functionId 
        );

        setPermissionId(existingAccess?.permission?.id);
        
        try {
            if (existingAccess) {
                removeAccessControl(roleId, existingAccess?.permission?.id);
            } else {
                let payload = {
                    role_id: roleId,
                    permission_id: functionId,
                    is_allowed: 'true' // Convert to boolean
                };
                await addAccessControl(payload);
            }

            // Set the updateFlag to trigger re-render
            setUpdateFlag(prevFlag => !prevFlag);

        } catch (error) {
            console.error('Error managing access control:', error);
        }
    };
    

    const addAccessControl = (payload) => {
        setIsLoading(true);
        AccessControlService.addAccessControl(payload)
            .then((res) => {
                setIsLoading(false);

                if (res.data.success) {
                    enqueueSnackbar('Permission successfully added.', {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });

                    // Fetch updated access control data
                    getAccessControl(1, roleId);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            });
    };


    
    const removeAccessControl = (roleId, permissionId) => {
        setIsLoading(true);
        AccessControlService.removeAccessControl(roleId, permissionId)
            .then((res) => {
                setIsLoading(false);

                if (res.data.success) {
                    enqueueSnackbar(res.data.data, { 
                        variant: 'success',
                        autoHideDuration: 3000
                    });

                    // Fetch updated access control data
                    getAccessControl(1, roleId);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.response.data.error, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            });
    };

    // useEffect(() => {
    //     // Fetch access control data when updateFlag changes
    //     getAccessControl(1, roleId);
    // }, [updateFlag]);
    
    // useEffect(() => {
    //     if (updated || added) {
    //         getAccessControl(1, roleId);
    //     }
    // }, [updated, added]);
    

    
    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <Button
                startIcon={<ArrowBackIosIcon style={{ width: 20, height: 20, color: '#0178f2' }} />}
                style={{ color: '#000', fontSize: 18, textTransform: 'none', fontWeight: 600 }}
                onClick={() => setView(false)}
            >
                {roleName && roleName}
            </Button>

            <Box sx={{ mt: 2 }} />

            <Grid container>
                <Grid item xs={12}>
                        {modules.map((moduleData, moduleIndex) => {
                            const moduleId = moduleData.id;
                            const moduleFunctions = functions.filter(functionData => functionData.module.id === moduleId);

                            return (
                                <div key={moduleIndex} style={{padding: 2}}>
                                    <Card sx={{ padding: 3, borderRadius: 2, mb: 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                        <CardHeader
                                            title={loading ?
                                                <Skeleton variant="text" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={28}/>
                                            :
                                                <Typography sx={{ fontWeight: 'bold' }}>
                                                    {moduleData?.module_name}
                                                </Typography>
                                            }
                                        />
                                        
                                        <CardContent>
                                            {moduleFunctions.map((functionData) => (
                                                <FormControlLabel
                                                    key={functionData.id}
                                                    control={
                                                        isLoading ?
                                                            <Skeleton variant="rectangular" sx={{backgroundColor: "rgb(108 139 222 / 30%)"}} height={30} width={30} />
                                                        :
                                                            <Checkbox
                                                                checked={
                                                                    accessControl.some(
                                                                        (ac) =>
                                                                            ac.permission.module.id === moduleId &&
                                                                            ac.permission.id === functionData.id &&
                                                                            ac.is_allowed
                                                                    )
                                                                }
                                                                onChange={(event) => {
                                                                    const newIsAllowed = !event.target.checked;
                                                                    const existingAccess = accessControl.find(
                                                                        (ac) =>
                                                                            ac.permission.module.id === moduleId &&
                                                                            ac.permission.id === functionData.id &&
                                                                            ac.is_allowed === !newIsAllowed
                                                                    );
                                                                    // Call the function to handle permission change, no need for async/await here
                                                                    handlePermissionChange(moduleId, functionData.id, newIsAllowed, existingAccess?.is_allowed, existingAccess?.id);
                                                                }}
                                                            />

                                                    }
                                                    label={functionData?.fn}
                                                />
                                            ))}
                                        </CardContent>
                                    </Card>
                                </div>
                            );
                        })}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewAccessControlScreen;
