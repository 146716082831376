import { useEffect, useState } from "react";
import useTemplates from "../../../hooks/useTemplates"
import { Box, FormGroup, Grid, IconButton, InputAdornment, Pagination, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import TemplateListSkeletonComponent from "../../skeletons/template-list-skeleton.component";
import TemplateRowContent from "../../template-management/template-row.component";
import { useSnackbar } from "notistack";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { format } from 'date-fns';
import useProjects from "../../../hooks/useProjects";
import ReactSelect from "react-select";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import TemplatesActionsPopperComponent from "./templates-action-popper.component";
import UpdateTemplateNameDialog from "./update-template-name.dialog";
import DeleteTemplateDialog from "./delete-template.dialog";
import DeletedTemplatesActionsPopperComponent from "./deleted-templates-action-popper.component";
import ForbiddenScreen from "../../../screens/ForbiddenScreen";

const searchForOptions = [
    { value: "title", label: "Title" },
    { value: "templateCode", label: "Template Code" }
]



function TableHeader() {
    const tablecell = [
        { label: 'Title' },
        { label: 'Template Code' },
        { label: 'Project Name' },
        { label: 'Total Page' },
        { label: 'Last Date Modified' },
        { label: 'Action' }
    ];

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value, index) => (
                    <TableCell key={index} sx={{ fontWeight: 700, backgroundColor: '#0178f2', color: 'white' }}>{value.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const TemplateTableContent = (props) => {

    const {
        index, template, setFetchingEnterpriseAccountTemplate, setIsDeleted, deleteTemplatePermanently, deleting, deleted, setDeleted,
        setDeleting, getDeletedEnterpriseAccountTemplates, restored, setIsRestored, restoring, restoreDeletedTemplate, isDeleted
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const copyToClipboard = (template) => {
        copy(template.template_code);

        enqueueSnackbar("Successfully copied template code to clipboard", {
            variant: "success",
            autoHideDuration: 2000
        });
    }

    return (

        <>
            {!isDeleted &&
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                        className="card-view"
                        sx={{
                            color: "#0178f2",
                            fontWeight: 500,
                            cursor: "pointer",
                        }}
                        component="th"
                        scope="row"
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <Typography
                                style={{ alignSelf: "flex-start", cursor: "default" }}
                            >
                                {template?.template_name}
                            </Typography>
                        </div>
                    </TableCell>


                    <TableCell align="left" sx={{ fontWeight: 600, fontSize: "13px !important" }}>
                        <Box sx={{ border: "1px solid black", borderStyle: "dashed", padding: 1 }}>
                            <Grid container>
                                <Grid item xs={10} sx={{ alignSelf: "center" }}>
                                    {template?.template_code}
                                </Grid>

                                <Grid item xs={2} sx={{ alignSelf: "center" }}>
                                    <Tooltip title="Copy" placement="top" arrow>
                                        <IconButton className="card-copy" onClick={(e) => copyToClipboard(template)} sx={{ color: "#0178f2", cursor: "pointer", pl: 1, pr: 0 }}>
                                            <ContentCopyIcon sx={{ fontSize: "medium" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </TableCell>

                    <TableCell align="left"
                        sx={{
                            fontWeight: 500, color: '#0178f2',
                            cursor: (template?.project_name == null) ? "default" : "pointer"
                        }}
                    >
                        {template?.project_name}
                    </TableCell>

                    <TableCell align="center">
                        {template?.total_page}
                    </TableCell>

                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        {format(new Date(template?.updated_at), 'PPpp')}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "13px !important" }}>
                        <DeletedTemplatesActionsPopperComponent
                            template={template}
                            setIsDeleted={setIsDeleted}
                            deleteTemplatePermanently={deleteTemplatePermanently}
                            deleting={deleting}
                            deleted={deleted}
                            setDeleted={setDeleted}
                            setDeleting={setDeleting}
                            getDeletedEnterpriseAccountTemplates={getDeletedEnterpriseAccountTemplates}
                            restored={restored}
                            setIsRestored={setIsRestored}
                            restoring={restoring}
                            restoreDeletedTemplate={restoreDeletedTemplate}
                        />
                    </TableCell>
                </TableRow>
            }



        </>
    )
}



const EnterpriseDeletedTemplatesComponent = () => {

    const { isFetchingEnterpiseAccountTemplate, error, pagination, getDeletedEnterpriseAccountTemplates, deleteTemplatePermanently, deleting, deleted, setDeleted, setDeleting, setRestoring, enterpriseAccountTemplates, setFetchingEnterpriseAccountTemplate, restored, restoreDeletedTemplate, restoring, filterEnterpriseDeletedTemplate } = useTemplates();

    const { getProjectList, projects } = useProjects();

    // let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");
    let businessIdFromURL = (new URLSearchParams(window.location.search)).get("id");

    let deleted_value = 1;

    const [page, setPage] = useState(1);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isRestoredState, setRestoredState] = useState(false);
    const [isRestored, setIsRestored] = useState(false);
    const [searchFor, setSearchFor] = useState(null);
    const [searchForLabel, setSearchForLabel] = useState(null);
    const [searchTemplateVal, setSearchTemplateVal] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isStatusDropdownCleared, setStatusDropdownCleared] = useState(false);


    useEffect(() => {
        if ((deleted) ||
            restored
        ) {
            getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
            setRestoredState(false);
        }
    }, [deleted, isRestoredState, restored, isRestored])

    useEffect(() => {
        if (restored) {
            setRestoredState(true);
        }
    }, [restored])



    useEffect(() => {
        if (businessIdFromURL) {
            getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
        }
    }, [businessIdFromURL])




    useEffect(() => {

        getProjectList(businessIdFromURL);


        if (enterpriseAccountTemplates?.length > 0) {

            enterpriseAccountTemplates?.map((template) => {

                let project_id = template?.project_id;

                projects?.map((project) => {

                    if (project?.id == project_id) {
                        template.project_name = project?.application_name
                    }

                })

            })

        }

    }, [JSON.stringify(enterpriseAccountTemplates), JSON.stringify(projects)])

    const handleChangePagination = (event, page) => {
        setPage(page);
        getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
        setFetchingEnterpriseAccountTemplate(true);
    }



    const reload = () => {
        setSearchFor('title')
        setSearchForLabel(null)
        getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
        setSearchTemplateVal('')
        setSelectedProject(null)
        setStatusDropdownCleared(true);
    }

    const handleSelectStatusOption = (selectedOption) => {
        if (selectedOption) {
            setSearchFor(selectedOption.value);
            setSearchForLabel(selectedOption.label);
            setStatusDropdownCleared(false);

        }
        else {
            setSearchForLabel(null);
            setSearchFor(null);
            setSelectedProject(null);
            setSearchTemplateVal(null);
            setStatusDropdownCleared(true);
            getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);

        }
    }

    const handleTextfield = (e) => {
        setSearchTemplateVal(e.target.value);
    }


    const handleKeyPress = (e) => {
        console.log('keeeeeeeeeeeey', e)
        if (e.keyCode === 13) {
            if (selectedProject) {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseDeletedTemplate(searchFor, searchVal, businessIdFromURL, deleted_value, page);
                }

            } else {
                let searchVal = searchTemplateVal;

                if (searchFor && searchTemplateVal) {
                    filterEnterpriseDeletedTemplate(searchFor, searchVal, businessIdFromURL, deleted_value, page);
                } else {
                    getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
                }
            }
        } else if (e.key === 'Backspace' && e.target.value === ' ') {
            getDeletedEnterpriseAccountTemplates(businessIdFromURL, deleted_value, page);
        }
    }



    return (

        <Box sx={{ pt: "8px !important", width: '100%', borderRadius: 0, overflow: 'hidden', pb: "8px !important" }}>
            {error ?
                <ForbiddenScreen />
                :
                <>
                    <Grid className="card-search" item sx={{ textAlign: "flex-start", paddingLeft: 1, alignSelf: "center", mr: "6px" }}>
                        <Box>
                            <Stack direction="row" spacing={1} sx={{ width: "99%", margin: "auto" }}>
                                {(isStatusDropdownCleared) ?
                                    <>
                                        <ReactSelect
                                            className="basic-single"
                                            value={"Select Filter"}
                                            placeholder={<><b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Filter</b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Select Filter</i></>}
                                            isSearchable={true}
                                            isRtl={false}
                                            options={searchForOptions}
                                            onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                }),
                                            }}
                                            isClearable
                                        />
                                    </>

                                    :
                                    <>
                                        <ReactSelect
                                            className="basic-single"
                                            placeholder={<>
                                                <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                    Filter
                                                </b> &nbsp; <i style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                                    {
                                                        (searchForLabel === "Template Code" && !isStatusDropdownCleared) ?
                                                            <Typography sx={{ color: "#000" }}>
                                                                Template Code
                                                            </Typography>
                                                            :
                                                            (searchForLabel === "Title" && !isStatusDropdownCleared) ?
                                                                <Typography sx={{ color: "#000" }}>
                                                                    Title
                                                                </Typography>
                                                                :
                                                                (searchForLabel === null && !isStatusDropdownCleared) ?
                                                                    "Select Filter"
                                                                    :
                                                                    null
                                                    }</i>
                                            </>}

                                            isSearchable={true}
                                            isRtl={false}
                                            options={searchForOptions}
                                            onChange={selectedOption => handleSelectStatusOption(selectedOption)}
                                            styles={{
                                                control: base => ({
                                                    ...base,
                                                    height: 38,
                                                    borderRadius: 100,
                                                    backgroundColor: 'transparent',
                                                    width: 250,
                                                    fontSize: 12
                                                }),
                                                placeholder: base => ({
                                                    ...base,
                                                    fontSize: 12
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                }),
                                            }}
                                            isClearable
                                        />
                                    </>
                                }

                                {(searchForLabel === "Title" || searchForLabel === "Template Code") &&
                                    <TextField
                                        value={searchTemplateVal}
                                        sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 100, backgroundColor: 'transparent', width: 250 } }}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={handleTextfield}
                                        onKeyDown={handleKeyPress}
                                        inputProps={{ style: { padding: "10px", fontSize: 12, fontStyle: 'italic' } }}
                                        placeholder={searchForLabel === "Title" ? "Search Title..." : "Template Code"}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" ><p style={{ fontWeight: 'bold', fontSize: 12 }}>{searchForLabel === "Title" ? 'Title' : 'Template Code'}:</p></InputAdornment>,
                                        }}
                                    />
                                }
                                <IconButton onClick={reload}><CachedIcon sx={{ height: 20, width: 20, color: 'rgb(25, 118, 210)' }} /></IconButton>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid container sx={{ padding: 2, pt: "8px !important" }}>
                        <TableContainer component={Paper} sx={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: "1px solid #80808030", borderRadius: 1, width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader />

                                {(isFetchingEnterpiseAccountTemplate) ?
                                    <TableBody>
                                        <TemplateListSkeletonComponent />
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <>
                                            {(enterpriseAccountTemplates && enterpriseAccountTemplates)?.map((content, index) => (
                                                <TemplateTableContent
                                                    index={index}
                                                    template={content}
                                                    setFetchingEnterpriseAccountTemplate={setFetchingEnterpriseAccountTemplate}
                                                    setIsDeleted={setIsDeleted}
                                                    deleteTemplatePermanently={deleteTemplatePermanently}
                                                    deleting={deleting}
                                                    deleted={deleted}
                                                    setDeleted={setDeleted}
                                                    setDeleting={setDeleting}
                                                    getDeletedEnterpriseAccountTemplates={getDeletedEnterpriseAccountTemplates}
                                                    restored={restored}
                                                    setIsRestored={setIsRestored}
                                                    restoring={restoring}
                                                    restoreDeletedTemplate={restoreDeletedTemplate}
                                                    isDeleted={isDeleted}
                                                />
                                            ))}
                                        </>
                                    </TableBody>
                                }
                            </Table>

                            {(!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length == 0) &&
                                <Box sx={{ padding: 2, width: "100%", borderRadius: 0, overflow: "hidden" }}>
                                    <Grid container sx={{ padding: 3 }}>
                                        <Box sx={{ textAlign: "center", margin: "auto" }}>
                                            <Typography>
                                                No available template(s) to display yet.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Box>
                            }

                            {!isFetchingEnterpiseAccountTemplate && enterpriseAccountTemplates?.length !== 0 &&
                                <Pagination
                                    count={pagination}
                                    page={page}
                                    onChange={handleChangePagination}
                                    style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                                />
                            }
                        </TableContainer>
                    </Grid>
                </>
            }
        </Box>
    )
}

export default EnterpriseDeletedTemplatesComponent