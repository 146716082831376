import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, } from 'recharts';
import useAnalytics from "../../hooks/useAnalytics";
import DashboardGraphSkeletonComponent from "../skeletons/dashboard-graph-skeleton.component";
import DashboardPerMonthSkeletonComponent from "../skeletons/DashboardPerMonthSkeletonComponent";
import ForbiddenImage from "../../images/forbidden.png";


function AuthenticationPerYear({ }) {

  const { countePerYear, getTransactionPerYear, loading, error } = useAnalytics();

  function random_color() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }

    return color;
  }

  useEffect(() => { getTransactionPerYear() }, [])

  return (
    <Paper sx={{ padding: 2 }}>
      {error === 'Forbidden.' ?
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
            <Typography textAlign="center" mb={2} sx={{ fontSize: 18, color: 'black' }}>
              You are not authorized.
            </Typography>
            <Typography variant="body1" component="div" textAlign="center" fontSize={14}>
              You tried to access a page you did not have prior authorization for.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center">
            <img alt="" src={ForbiddenImage} style={{ height: 250 }} />
          </Grid>
        </Grid>

        :

        <div className="row">
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Total Per Year</Typography>
          </Grid>

          <Divider sx={{ marginTop: 3 }} />

          {loading ?
            <DashboardPerMonthSkeletonComponent />
          :
            <div className="section col-md-6">
              <h5 className="section-title" >Authentication</h5>
              <div className="section-content">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={countePerYear?.data.sort((a, b) => a.year - b.year)} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
                    <XAxis dataKey="year" />
                    <YAxis />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill={'rgb(59, 130, 230)'} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        </div>
      }
    </Paper>
  )
}

export default AuthenticationPerYear;
